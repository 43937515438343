import React, { useCallback, useMemo } from "react";

import { Editable, withReact, Slate } from "slate-react";
import { createEditor, Descendant } from "slate";

import "./TextEditor.scss";

import { ITextEditorProps } from "./constans";
import Toolbar from "./Toolbar";

export const TextEditor: React.FC<ITextEditorProps> = ({ addText, text }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  let editor = useMemo(() => withReact(createEditor()), []);

  const Element = ({ attributes, children, element }: any) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case "block-quote":
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case "bulleted-list":
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case "heading-one":
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case "heading-two":
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case "list-item":
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };

  const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  return (
      <Slate editor={editor} initialValue={text || initialValue}>
        <Toolbar editor={editor} addText={addText} />
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus
          className="text-editor"
          onKeyUp={() => {
            addText(editor.children);
          }}
        />
      </Slate>
  );
};

const initialValue: Descendant[] = [
  {
    children: [
      {
        text: "",
      },
    ],
  },
];
