import { PaginationMeta, TotalRecords, Paginable } from '~/store/types'

export interface DistrigoWarehouse {
    id: string
    key: string
    name: string
    plprDeliveryRrdi: string
    ddsDeliveryRrdi: string
    city: string
    address: string
    street: string
    zipCode: string
    label: string
}

export interface StockDetails {
    distributionPlatformID: string
    sapId: string
    name: string
    vatId: string
    warehouseID: string
    warehouseName: string
    ddsId: string
    ddsDeliveryId: string
    priority: string
    deliveryPlatform: string
    deliveryRoute: string
    deliveryZone: string
    deliveryGroup: string
    street: string
    buildingNumber: string
    city: string
    zipCode: string
    wmsCode: string
    stockDeliveryId: string
    urgentDeliveryId: string
    sequenceNumber: string
}

export interface SparePartStockBase {
    rrdi: string
    stockMin: number
    stockMax: number
    thresholdStop: number
}

export type TInternalConsumptionPayload = {
    parts: TInternalConsumptionPart[]
    additionalInformation?: string
}

export type TInternalConsumptionPart = {
    partID: string,
    available?: number,
    qualityControl?: number
}

export type TRejectToSupplier = {
    parts: TRejectedPart[]
    notes?: string
}

export type TRejectedPart = {
    quantity: number
    referenceId: string
    codes?: {
        cleCode?: string
        nreCode?: string
        tiresCode?: string
        transmitterCode?: string
        vin?: string
        wheelCode?: string
    }
}

export interface StockWarehouse {
    allocated: number
    available: number
    blocked: number
    crossDockConfirmed: number
    crossDockNotConfirmed: number
    crossDockRequested: number
    internalConsumption: number
    overstock: number
    packingQuantity: number
    referenceWarehouseId: {
        referenceId: string
        warehouseId: string
        vin?: string
    },
    id: string,
    designationPolish: string,
    packingIsSent100: boolean
    replenishmentConfirmed: number
    replenishmentNotConfirmed: number
    replenishmentReception: number
    replenishmentReceived: number
    replenishmentRequested: number
    stockMax: number
    stockMin: number
    thresholdStop: number
    stockable: boolean
    total: number
    toReturn: number | null
    updateTime: string
    warehouseId: string
    wmsAvailable: number
    wmsBlocked: number
    wmsInternalConsumption: number
    qualityControl: number
    wmsQualityControl?: number
}

export type NewReceiptWareLine = {
    invoiceDate: Date | null,
    invoiceNumber?: string,
    parts: { price?: string, quantity?: number, referenceID?: string, supplier?: string }[],
    rrdi?: string,
    rrid?: string,
    warehouse?: string,
}

export type TWareCreate = {
    invoiceDate: Date | null,
    invoiceNumber?: string,
    parts: { price?: string, quantity?: number, referenceID?: string, supplier?: string }[],
    price?: string,
    quantity?: number,
    rrid?: string,
    rrdi?: string,
    warehouse?: string,
}

export type TWareLine = {
    designationPolish?: string,
    item: {
        referenceWarehouseId: {
            referenceId?: string
        }
    }
    lineNumber: number,
    partID?: string,
    quantity?: number,
    referenceWarehouseId: {
        referenceId?: string
    }
}

export interface TWareLocation {
    rrdi?: string
    displayAddress?: string,
    displayName?: string,
    returningPointView?: {
        blocked?: boolean,
        city?: string,
        company?: string,
        country?: string,
        ddsClientId?: string,
        number?: string,
        region?: null,
        rrdi?: string,
        sapClientId?: string,
        street?: string,
        type?: string,
        warehouse?: string,
        zipcode?: string,
    },
    city?: string,
    number?: string
    street?: string,
    zipcode?: string,
}

export type TLine = {
    invoiceDate: Date | null,
    invoiceNumber?: string,
    lineNumber: number,
    referenceId?: string,
    quantity?: number,
    price?: string,
    rrid?: string,
    supplier?: string,
    ware?: {
        invoiceDate: null, invoiceNumber?: string, quantity?: string, parts: { price?: string, quantity?: number, referenceID?: string, },
        returningPointView: {
            blocked?: boolean,
            city?: string,
            company?: string,
            country?: string,
            ddsClientId?: string,
            number?: string,
            region?: null,
            rrdi?: string,
            sapClientId?: string,
            street?: string,
            type?: string,
            warehouse?: string,
            zipcode?: string,
        },
        replacements: [],
        referenceId?: string, rrid?: string
    },
    warehouseId?: string,
    displayAddress?: string,
    displayName?: string,
    returningPointView?: {
        blocked?: boolean,
        city?: string,
        company?: string,
        country?: string,
        ddsClientId?: string,
        number?: string,
        region?: null,
        rrdi?: string,
        sapClientId?: string,
        street?: string,
        type?: string,
        warehouse?: string,
        zipcode?: string,
    }
}

export type TImportedFile = { referenceId?: string; quantity?: number; price?: number, supplier?: string };

export enum DistrigoWarehousesActionTypes {
    CREATE_WARE = '@@distrigoWarehouse/CREATE_WARE',
    CREATE_WARE_SUCCESS = '@@distrigoWarehouse/CREATE_WARE_SUCCESS',
    LINES_OPERATION_FINISHED = '@@distrigoWarehouse/LINES_OPERATION_FINISHED',
    FETCH_REQUEST = '@@distrigoWarehouse/FETCH_REQUEST',
    FETCH_STOCK_DETAILS = '@@distrigoWarehouse/FETCH_STOCK_DETAILS',
    FETCH_STOCK_DETAILS_SUCCESS = '@@distrigoWarehouse/FETCH_STOCK_DETAILS_SUCCESS',
    FETCH_STOCK_PRODUCTS_REQUEST = '@@distrigoWarehouse/FETCH_STOCK_PRODUCTS_REQUEST',
    FETCH_SUCCESS = '@@distrigoWarehouse/FETCH_SUCCESS',
    FETCH_STOCK_PRODUCTS_SUCCESS = '@@distrigoWarehouse/FETCH_STOCK_PRODUCTS_SUCCESS',
    SELECT = '@@distrigoWarehouse/SELECT',
    SELECTED = '@@distrigoWarehouse/SELECTED',
    CLEAR_CREATED = '@@distrigoWarehouse/CLEAR_CREATED',
    ADD_SPARE_PART_STOCK = '@@distrigoWarehouse/ADD_SPARE_PART_STOCK',
    ADD_SPARE_PART_STOCK_SUCCESS = '@@distrigoWarehouse/ADD_SPARE_PART_STOCK_SUCCESS',
    UPLOAD_XLS = '@@distrigoWarehouse/UPLOAD_XLS',
    UPLOAD_XLS_SUCCESS = '@@distrigoWarehouse/UPLOAD_XLS_SUCCESS',
    CLEAR_UPLOAD = '@@distrigoWarehouse/CLEAR_UPLOAD',
    ADD_SPARE_PART_CONFLICT = '@@distrigoWarehouse/ADD_SPARE_PART_CONFLICT',
    REJECT_TO_SUPPLIER = '@@distrigoWarehouse/REJECT_TO_SUPPLIER',
    INTERNAL_CONSUMPTION = '@@distrigoWarehouse/INTERNAL_CONSUMPTION',
    REJECT_SUCCESS = '@@distrigoWarehouse/REJECT_SUCCESS',
    INTERNAL_CONSUMPTION_SUCCESS = '@@distrigoWarehouse/INTERNAL_CONSUMPTION_SUCCESS',
    UPDATE_MIN_MAX_STOCK_ITEM = '@@distrigoWarehouse/UPDATE_MIN_MAX_STOCK_ITEM',
    HANDLE_REMOVE_STOCK_LINE = '@@distrigoWarehouse/HANDLE_REMOVE_STOCK_LINE',
    IMPORT_REFERENCES = '@@types/IMPORT_REFERENCES',
    IMPORT_FINISHED = '@@types/IMPORT_FINISHED',
    CONFIRM_FINISHING_OF_IMPORT = '@@types/CONFIRM_FINISHING_OF_IMPORT',
    STOP_CONFIRM_FINISHING_OF_IMPORT = '@@types/TOP_CONFIRM_FINISHING_OF_IMPORT',
    CLEAR_IMPORTED_ARRAY = '@@types/CLEAR_IMPORTED_ARRAY',
    ERROR_OF_IMPORTING_REFERENCE = '@@types/ERROR_OF_IMPORTING_REFERENCE',
    ERROR_OF_DUPLICATE_REFERENCE = '@@types/ERROR_OF_DUPLICATE_REFERENCE',
    CONFIRM_MOVING_WARES = '@@types/CONFIRM_MOVING_WARES',
}

export interface StocksWarehousesState {
    readonly list: StockWarehouse[]
    readonly meta: PaginationMeta
    readonly totalRecords: TotalRecords
}

export interface DistrigoWarehousesState {
    readonly list: DistrigoWarehouse[]
    readonly stockItemsList: StockWarehouse[]
    readonly distrigoWarehouse?: StockDetails
    readonly uploadingStock: boolean
    readonly uploadedStock: boolean
    readonly rejecting: boolean
    readonly rejected: boolean
    readonly meta: PaginationMeta
    readonly loadingDistrigoWarehouses: boolean
    readonly loadingDistrigoWarehouse: boolean
    readonly loadingLines: boolean
    readonly loadingStockItems: boolean
    readonly totalRecords: TotalRecords
    readonly creating: boolean
    readonly created?: string
    readonly error: string,
    readonly importing: boolean
    readonly imported?: [{ referenceId: string, quantity: number, price: number }]
    readonly finishedImportingToLine: boolean
    readonly operationSuccess: boolean
    readonly selectedWarehouse?: StocksWarehousesState
    readonly destroying: boolean
    readonly destroyed: boolean
    readonly deliveryWareValue?: string,
    readonly receiveWareValue?: string,
    readonly parts?: TWareLine[],
    readonly additionalInfo?: string,
    readonly movedWares: [{}]
}

export type PaginableStocksWarehouse = Paginable<StockWarehouse>