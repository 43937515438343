import React from 'react';
import { Table } from 'semantic-ui-react';
import { DescriptionTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';

const DescriptionTableCell = ({ line }: DescriptionTableCellProps) => (
  <Table.Cell>
    {line.orderLineType === 'REPLACED' || line.orderLineType === 'REPLACED_TO_ORIGINAL' ? (
      <span style={{ color: 'red' }}>{line.description}</span>
    ) : (
      line.description
    )}
  </Table.Cell>
);

export default DescriptionTableCell;
