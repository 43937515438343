import { Label } from 'semantic-ui-react';
import React from 'react';
import { OrderCategoryCellProps } from '~/pages/Customers/constants';

const OrderCategoryCell = ({ deliveryType }: OrderCategoryCellProps) => {
  const deliveryTypeMap: { [key: string]: JSX.Element } = {
    STOCK: (
      <Label basic size="tiny" title="Stock Delivery">
        S
      </Label>
    ),
    URGENT: (
      <Label size="tiny" title="Urgent Delivery" color="grey">
        P
      </Label>
    ),
    TURBO_PRO: (
      <Label size="tiny" title="Turbo Pro&trade; Delivery" color="orange">
        T
      </Label>
    ),
  };

  return deliveryTypeMap[deliveryType] || <Label title="Unknown delivery">?</Label>;
};

export default OrderCategoryCell;
