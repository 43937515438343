import React, { useCallback, useMemo } from "react";
import { Icon, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { Backorder } from "~/store/backorder/types";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";

export const useBackordersColumns = () => {
  const methods: DictionaryItem[] = useMemo(
    () => [
      { key: "CROSS_DOCK", value: "CROSS_DOCK", text: "Cross-dock do klienta" },
      {
        key: "REPLENISHMENT",
        value: "REPLENISHMENT",
        text: "Uzupełnienie stocku",
      },
    ],
    []
  );

  const getMethodCell = useCallback((method: string): JSX.Element => {
    switch (method) {
      case "CROSS_DOCK":
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="map signs" size="large" color="grey"></Icon>
          </Table.Cell>
        );
      case "REPLENISHMENT":
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="cubes" size="large" color="grey"></Icon>
          </Table.Cell>
        );
      default:
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="question circle" size="large" color="grey"></Icon>
          </Table.Cell>
        );
    }
  }, []);

  const getSupplierSystemCell = useMemo(
    () =>
      (
        document: Backorder,
        column: TColumn,
        fieldName: string
      ): JSX.Element => {
        return (
          <Table.Cell key={column.name}>
            {document.deliveryCustomer.supplierSystem
              ? document.deliveryCustomer.supplierSystem[0][fieldName]
              : ""}
          </Table.Cell>
        );
      },
    []
  );

  const typeDictionary: DictionaryItem[] = useMemo(
    () => [
      { key: "URGENT", value: "URGENT", text: "Pilne" },
      { key: "STOCK", value: "STOCK", text: "Stock" },
    ],
    []
  );

  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "method",
        dictionary: methods,
        label: "Typ",
        projection: true,
        noKey: true,
        getCell: (document) => getMethodCell(document.method),
      },
      {
        name: "backorderNumber",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "creationTime",
        label: "Utworzone",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "customer.rrdi",
        label: "Zamawiający",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "deliveryCustomer.rrdi",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "supplierSystem.businessSector",
        label: "Typ dostawcy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "businessSector"),
      },
      {
        name: "supplierSystem.clientId",
        label: "Sincom",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "clientId"),
      },
      {
        name: "supplierSystem.deliveryId",
        label: "Punkt dostawy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "deliveryId"),
      },
      {
        name: "type",
        dictionary: typeDictionary,
        label: "Dostawa",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "supplier",
        label: "Dostawca",
        projection: true,
        dictionary: dictionariesStatic.SPARE_PART_SUPPLIER,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "priority",
        label: "Priorytet",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "status",
        dictionaryName: DictionaryName.backorderState,
        label: "Status",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "linesQuantity",
        label: "Linie",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "realisation",
        label: "Realizacja",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "batchId",
        label: "Batch",
        projection: true,
        getCell: (document, column) =>
          getTextCell(document, column, document.batchId || "n/d"),
      },
    ],
    [
      typeDictionary,
      getSupplierSystemCell,
      getMethodCell,
      methods,
      getDateCell,
      getDictionaryCell,
      getTextCell,
    ]
  );

  return { columnsConfig };
};
