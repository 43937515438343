import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Container, Dimmer } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import { selectCustomer } from '~/store/customer/actions';
import { fetchDictionary } from '~/store/dictionaries/actions';
import { DictionaryItem, DictionaryName } from '~/store/dictionaries/types';
import { changeStatusOrder, clearSelected, selectOrder, clearCreated } from '~/store/orders/actions';
import { Order, OrderStatusEnum } from '~/store/orders/types';

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import CommonLoader from '~/components/Loaders/CommonLoader';
import ModalConfirm from '~/components/Modals/ModalConfirm';
import PageHeader from '~/components/PageHeader/PageHeader';
import SmartWrapper from '~/components/SmartField/SmartWrapper';
import { TOrderDetailsProps, TReduxActions, TReduxState } from '~/pages/Orders/Details/constants';
import OrderChangeStatusDropdown from '~/pages/Orders/Details/lib/OrderChangeStatusDropdown';
import Cards from '~/pages/Orders/Details/lib/Cards';
import translations from '~/utils/translations';

const OrderDetails: React.FC<TOrderDetailsProps> = ({
  match,
  orderIn,
  dictionaries,
  loading,
  created,
  selectOrder,
  clearSelected,
  fetchDictionary,
  changeStatusOrder,
  clearCreated,
}) => {
  const [order, setOrder] = useState<Order>();
  const [editMode, setEditMode] = useState<boolean>(!!match.params.edit);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [statusToChange, setStatusToChange] = useState<string>('');

  useEffect(() => {
    if (!order || order.id !== match.params.id) {
      fetchDictionary(DictionaryName.orderType, DictionaryName.orderStatus, DictionaryName.orderDeliveryType);
      selectOrder(match.params.id);
      return () => {
        clearSelected();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, clearSelected, fetchDictionary, selectOrder]);

  useEffect(() => {
    if (created) {
      clearCreated();
    }
  }, [clearCreated, created]);

  useEffect(() => {
    document.title = translations.format('app.orders');
    setOrder(orderIn);
    if (orderIn && orderIn.customer) {
      selectCustomer(orderIn.customer.rrid);
    }
  }, [orderIn]);

  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };

  const orderChangeStatusDropdown = (): JSX.Element => (
    <OrderChangeStatusDropdown setStatusToChange={setStatusToChange} setModalOpen={setModalOpen} order={order} />
  );

  const handleOrderStatusChangeOnConfirmClick = (): void => {
    if (!orderIn) {
      return;
    }

    const orderStatus = {
      status: statusToChange,
      onList: false,
    };
    changeStatusOrder(orderIn.id, orderStatus);
    setModalOpen(false);
  };

  const handeStatusChangeOnCancelClick = (): void => {
    setModalOpen(false);
  };

  const handleOrderUpdate = (updatedModel: Order): void => {
    if (order && order.customer.rrid !== updatedModel.customer.rrid) {
      selectOrder(order.id);
      selectCustomer(order.customer.rrid);
    } else if (order && order.deliveryCustomer.rrid !== updatedModel.deliveryCustomer.rrid) {
      selectOrder(order.id);
    } else {
      setOrder(updatedModel);
    }
  };

  const getDictionaryText = (dictionary: DictionaryItem[], value: any): JSX.Element | string => {
    const found = dictionary.find((dict) => dict.value === value);

    return found ? found.text : '';
  };

  const cardsRendered = useMemo(() => <Cards editMode={editMode} order={order} />, [editMode, order]);

  const renderedOrderTitle = useMemo(() => (!order ? 'Wczytywanie...' : order.orderNumber), [order]);

  return (
    <Dimmer.Dimmable style={{ minHeight: '80%' }}>
      <SmartWrapper endpoint={order ? `/orders/${order.id}` : ''} model={order} onUpdate={handleOrderUpdate}>
        <PageHeader
          icon="shop"
          title={
            <>
              <FormattedMessage id="app.order" />{' '}
              {order ? getDictionaryText(dictionaries[DictionaryName.orderType], order.type) : ''}: {renderedOrderTitle}
            </>
          }
          breadcrumb={[
            { text: <FormattedMessage id="app.list" />, link: '/orders' },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[
            {
              icon: editMode ? 'lock' : 'edit',
              content: editMode ? 'Wył. edycję' : <FormattedMessage id="app.button.edit" />,
              onClick: toggleEditMode,
              primary: true,
              visible: !(
                (order && order.status === OrderStatusEnum.COMPLETED) ||
                (order && order.status === OrderStatusEnum.ACCEPTED)
              ),
            },
          ]}
          refreshAction={() => {
            clearSelected();
            selectOrder(match.params.id);
          }}
          loading={loading}
        />
        <Container fluid>{orderChangeStatusDropdown()}</Container>
        {cardsRendered}
      </SmartWrapper>
      <CommonLoader loading={loading || !order} />
      <Fragment>
        <ModalConfirm
          modalOpen={modalOpen}
          contentText="app.order.confirmStatusUpdateOuestion"
          headerIcon="question circle"
          headerText="app.confirmOperation"
          onCancelClick={() => handeStatusChangeOnCancelClick()}
          onConfirmClick={() => handleOrderStatusChangeOnConfirmClick()}
        />
      </Fragment>
    </Dimmer.Dimmable>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ orders, dictionaries }: ApplicationState) => ({
  orderIn: orders.selected,
  loading: orders.loadingOrder || dictionaries.loading,
  created: orders.created,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  selectOrder,
  clearSelected,
  fetchDictionary,
  changeStatusOrder,
  clearCreated,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
