import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { SpeederActionTypes } from './types'
import {
    generateLIGFATCQRaport, generateSTKVTEIRaport, generateSTKVTHRaport, generateVENTESQRaport, speederRaportGenerateFailed, speederRaportGenerateSuccess
} from './actions'
import { callApi } from '~/utils/api'
import { toastError, toastSuccess, toastWarn } from '~/utils/toast'

function* handleGenerateLIGFACTQSpeederRaport(action: ReturnType<typeof generateLIGFATCQRaport>) {
    try {
        const res:Response = yield call(callApi, 'POST', `/service/sap/speeder/invoice-lines-generate-request?from=${new Date(action.payload.from).toISOString()}&to=${new Date(action.payload.to).toISOString()}`,)
        yield put(speederRaportGenerateSuccess(res))
        toastSuccess("Generowanie raportu", `Wygenerowano raport LIGFACTQ`)
    } catch (error) {
        if (error.status !== 409) {
            toastError("Generowanie raportu", "Błąd generowania raportu LIGFATCQ")
        } else {
            toastWarn("Konflikt", "Zadanie zostało już wcześniej dodane do kolejki")
        }
        yield put(speederRaportGenerateFailed(error))
    }
}

function* handleGenerateVENTESQSpeederRaport(action: ReturnType<typeof generateVENTESQRaport>) {
    try {
        const res:Response = yield call(callApi, 'POST', `/service/sap/speeder/sales-generate-request?from=${new Date(action.payload.from).toISOString()}&to=${new Date(action.payload.to).toISOString()}`)
        yield put(speederRaportGenerateSuccess(res))
        toastSuccess("Generowanie raportu", `Wygenerowano raport VENTESQ`)
    } catch (error) {
        toastError("Generowanie raportu", "Błąd generowania raportu VENTESQ")
        yield put(speederRaportGenerateFailed(error))
    }
}

function* handleGenerateSTKVTEISpeederRaport(action: ReturnType<typeof generateSTKVTEIRaport>) {
    try {
        const res:Response = yield call(callApi, 'POST', '/service/sap/speeder/stock-status-initial-generate-request', action.payload)
        yield put(speederRaportGenerateSuccess(res))
        toastSuccess("Generowanie raportu", `Wygenerowano raport STKVTEI`)
    } catch (error) {
        if (error.status !== 409) {
            toastError("Generowanie raportu", "Błąd generowania raportu STKVTEI")
        } else {
            toastWarn("Konflikt", "Zadanie zostało już wcześniej dodane do kolejki")
        }
        yield put(speederRaportGenerateFailed(error))
    }
}

function* handleGenerateSTKVTHSpeederRaport(action: ReturnType<typeof generateSTKVTHRaport>) {
    try {
        const res:Response = yield call(callApi, 'POST', '/service/sap/speeder/stock-status-weekly-generate-request', action.payload)
        yield put(speederRaportGenerateSuccess(res))
        toastSuccess("Generowanie raportu STKVTEH", `Zadanie zostało dodane do kolejki1`)
    } catch (error) {
        toastError("Generowanie raportu", "Błąd generowania raportu STKVTEH")
        yield put(speederRaportGenerateFailed(error));
    }
}

function* watchGenerateLIGFACTQSpeederRaport() {
    yield takeEvery(SpeederActionTypes.SPEEDER_GENERATE_LIGFACTQ_RAPORT, handleGenerateLIGFACTQSpeederRaport)
}

function* watchGenerateSTKVTEISpeederRaport() {
    yield takeEvery(SpeederActionTypes.SPEEDER_GENERATE_STKVTEI_RAPORT, handleGenerateSTKVTEISpeederRaport)
}

function* watchGenerateSTKVTHSpeederRaport() {
    yield takeEvery(SpeederActionTypes.SPEEDER_GENERATE_STKVTH_RAPORT, handleGenerateSTKVTHSpeederRaport)
}

function* watchGenerateVENTESQSpeederRaport() {
    yield takeEvery(SpeederActionTypes.SPEEDER_GENERATE_VENTESQ_RAPORT, handleGenerateVENTESQSpeederRaport)
}

function* speederSaga() {
    yield all([
        fork(watchGenerateLIGFACTQSpeederRaport),
        fork(watchGenerateVENTESQSpeederRaport),
        fork(watchGenerateSTKVTEISpeederRaport),
        fork(watchGenerateSTKVTHSpeederRaport)
    ])
}

export default speederSaga