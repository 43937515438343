import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Dropdown, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";
import { TPromotion } from "~/store/promotions/types";
import { usePromotionsRolesRights } from "./hooks/usePromotionsRolesRights";
import TableNoResults from "~/components/TableNoResults/TableNoResults";

type Props = {
  list: TPromotion[];
  columns: TColumn[];
};

const PromotionsList: React.FC<Props> = ({ list, columns }) => {
  const { userHaveLdcUiPromotionProcessRole } = usePromotionsRolesRights();

  const promotionListDropdown = useCallback(
    (promotion: TPromotion): JSX.Element => {
      return promotion.status === "DRAFT" ||
        promotion.status === "AWAITING_ACTIVATION" ? (
        <Dropdown icon={"ellipsis horizontal"}>
          <Dropdown.Menu direction="left">
            <Dropdown.Item
              as={Link}
              to={`/promotion/${promotion.promoID}`}
              text={<FormattedMessage id="app.dropdown.viewPromotion" />}
            />
            {userHaveLdcUiPromotionProcessRole() && (
              <Dropdown.Item
                as={Link}
                to={`/promotion/${promotion.promoID}/edit`}
                text={<FormattedMessage id="app.dropdown.editPromotion" />}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <TablePreviewDetails path={`/promotion/${promotion.promoID}`} />
        </>
      );
    },
    [userHaveLdcUiPromotionProcessRole]
  );

  if (!list || list.length === 0) {
    return <TableNoResults />;
  }

  return (
    <>
      {list.map((item) => (
        <Table.Row key={item.promoID}>
          {columns.map(
            (column) =>
              column.projection &&
              column.getCell &&
              column.getCell(item, column)
          )}
          <Table.Cell className="col-dropdown-menu-sticky">
            {promotionListDropdown(item)}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default PromotionsList;
