import { TLine } from '~/pages/Orders/lib/Add/constants';

export const updateIndexing = (lines: TLine[]): void => {
  lines.forEach((line, index) => (line.lineNumber = index + 1));
};

export const validNumber = (value: any): boolean => {
  return isNaN(value) ? false : typeof value === 'number' && String(value) !== '0';
};

export const roundToTwoDecimalPlacesUp = (value: number): number => {
  return Math.round(value * 100) / 100;
};

export const calculateVat = (pnrPrice: number, quantity: number, priceTaxCode: string): string => {
  switch (priceTaxCode) {
    case '0':
      return 'ZW';
    case '1':
      return String(roundToTwoDecimalPlacesUp(pnrPrice * quantity * 0.23));
    case '3':
      return String(roundToTwoDecimalPlacesUp(pnrPrice * quantity * 0.08));
    default:
      return '';
  }
};

export const calculateGross = (line: TLine): number => {
  return (
    Number(line.sellPrice) * line.quantity +
    Number(calculateVat(validNumber(line.sellPrice) ? line.sellPrice : 0, line.quantity, line.priceTaxCode))
  );
};
