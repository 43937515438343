import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";

export const sourceColorMap: { [key: string]: SemanticCOLORS } = {
  LDC: "orange",
  SERVICE_BOX: "grey",
  CUSTOMER_PANEL: "blue",
};

export const sourceNameMap: { [key: string]: string } = {
  LDC: "LDC",
  SERVICE_BOX: "SB",
  BP: "BP",
  CLAIM: "RK",
  CUSTOMER_PANEL: "PK",
};

export const getValueFromOrder = (order: any, name: string) => {
  if (name.lastIndexOf(".") === -1) {
    return order[name];
  }

  const nameArray = name.split(".");
  const first = nameArray[0];
  const sec = nameArray[1];

  return order[first][sec];
};
