import { ProductCodes } from '~/store/orders/types'
import { REQUIRED } from '~/components/SmartField/lib/validator'
import { Button, GridColumn, Header, Modal } from 'semantic-ui-react'
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper'
import { ModelInput } from '~/components/ModelForm/ModelInput'
import { SubmitButton } from '~/components/ModelForm/SubmitButton'
import React, { useMemo } from 'react'
import { fields } from "~/components/Modals/lib/utils";
import { ModalContent } from "~/components/Modals/ModalContent";

export type TCodableProductLine = {
    referenceId: string
    description: string
    codes?: ProductCodes | null
}

const useCodesModal = <T extends TCodableProductLine,>(
    open: boolean,
    closeHandler: () => void,
    submitHandler: (modifiedLine: T) => void,
    loading: boolean,
    selectedLine?: T
) => {
    return useMemo(() => {
        if (!selectedLine || !selectedLine.codes) {
            return <></>
        }

        const fieldsRendered = Object.entries(selectedLine.codes)
            .filter(([code, entry]) => !!entry && !!fields[code])
            .map(([code, entry], index, all) => {
                const validators = [...fields[code].validators]
                if (entry && entry.required) {
                    validators.push(REQUIRED)
                }
                return <GridColumn key={code} style={{ width: all.length < 3 ? '50%' : '33.3%' }}>
                    <ModelInput
                        name={`${code}.value`}
                        label={fields[code].label}
                        validators={validators}
                        style={{ maxWidth: 350 }} />
                </GridColumn>
            })

        return (
            <Modal
                open={open}
                onClose={closeHandler}
                size='small'
                closeOnDimmerClick={false}
            >
                <ModelFormWrapper<ProductCodes>
                    model={selectedLine.codes}
                    onSubmit={(codes: ProductCodes) => {
                        submitHandler({ ...selectedLine, codes })
                    }}>
                    <Header icon="barcode" content="Parametry kodowania referencji"/>
                    <ModalContent selectedLine={selectedLine} elements={fieldsRendered}/>
                    <Modal.Actions>
                        <SubmitButton primary content="Zapisz" icon="save" labelPosition="right" loading={loading}
                                      disabled={loading || !selectedLine}/>
                        <Button content="Anuluj" disabled={loading} onClick={closeHandler}/>
                    </Modal.Actions>
                </ModelFormWrapper>
            </Modal>
        )
    }, [open, loading, selectedLine, closeHandler, submitHandler])
}

export default useCodesModal
