import React, { SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Popup,
  Segment,
  Table,
  TextArea,
} from "semantic-ui-react";
import {
  clearCollectiveData,
  uploadCollectiveTask,
} from "~/store/claims/actions";
import { ApplicationState } from "~/store";
import {
  CollectiveTaskData,
  CollectiveTaskUploaded,
} from "~/store/claims/types";
import { DeliveryLocation, PaginableCustomer } from "~/store/customer/types";
import { DictionariesState, DictionaryItem } from "~/store/dictionaries/types";
import { FormattedMessage } from "react-intl";
import { selectCustomer } from "~/store/customer/actions";

import UberSearch from "~/components/UberSearch/UberSearch";
import ModalMessageHeader from "~/components/Modals/ModalMessageHeader";

type TReduxActions = {
  uploadCollectiveTask: typeof uploadCollectiveTask;
  clearCollectiveData: typeof clearCollectiveData;
  selectCustomer: typeof selectCustomer;
};

interface PropsFromState {
  uploading: boolean;
  uploadedCollectiveTask: boolean;
}

type TReduxState = {
  loadingOrders: boolean;
  loadingCustomers: boolean;
  deliveryAddresses: DeliveryLocation[];
  dictionaries: DictionariesState;
};

type TProps = {
  triggerOpen: boolean;
  openHandler: React.Dispatch<React.SetStateAction<boolean>>;
  collectiveTaskData: CollectiveTaskData;
  collectiveTaskUploadedData: CollectiveTaskUploaded;
};

type TAddAttachemntModalProps = TReduxActions &
  PropsFromState &
  TProps &
  TReduxState;

const CollectiveTask: React.FC<TAddAttachemntModalProps> = ({
  children,
  triggerOpen,
  uploadedCollectiveTask,
  uploading,
  collectiveTaskUploadedData,
  deliveryAddresses,
  dictionaries,
  clearCollectiveData,
  openHandler,
  uploadCollectiveTask,
  selectCustomer,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [category, setCategory] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [customer, setCustomer] = useState<string>("");
  const [delivery, setDelivery] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File>();

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    openHandler(open);
  }, [open, openHandler]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  const validateFile = (fileName?: string): boolean => {
    if (fileName) {
      const extension = fileName.split(".").pop();
      return extension === "xls" || extension === "xlsx" || extension === "csv";
    }
    return false;
  };

  const handleUpload = (): void => {
    const collectiveTaskData = {
      accountingCustomerID: customer,
      orderCustomerID: delivery,
      claimCategory: category,
      claimType: type,
      description: description,
    };

    uploadCollectiveTask(collectiveTaskData, file as File);
  };

  const showUploadedCollectiveTask = (): JSX.Element[] => {
    return collectiveTaskUploadedData.claimsCreated.map((invoice, index) => (
      <Table.Row key={index}>
        <Table.Cell>{invoice}</Table.Cell>
      </Table.Row>
    ));
  };

  const showErrorsCollectiveTask = (): JSX.Element[] => {
    return collectiveTaskUploadedData.errors.map((error, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <span style={{ color: "red" }}>{error.message}</span>
        </Table.Cell>
        <Table.Cell>{error.lines.toString()}</Table.Cell>
      </Table.Row>
    ));
  };

  const changeCustomer = (data: any): string => {
    setCustomer(data.value);
    setDelivery("");
    selectCustomer(data.value);
    return data.title;
  };

  const changeDelivery = (event: SyntheticEvent, data: any): void => {
    setDelivery(data.value);
  };

  const changeCategory = (event: SyntheticEvent, data: any): void => {
    setCategory(data.value);
    setType("");
  };

  const mapCustomers = (response: PaginableCustomer): any[] => {
    return response.content.map((c) => ({
      key: c.rrdi,
      title: `${c.rrdi} ${c.name}`,
      description: `${c.informationAddressCity}, ${c.informationAddressStreet} ${c.informationAddressNumber}`,
      value: c.rrdi,
    }));
  };

  const mapDelivery = (
    deliveryAddresses: DeliveryLocation[]
  ): DictionaryItem[] => {
    return deliveryAddresses.map((deliveryAddress) => ({
      key: deliveryAddress.rrdi,
      text: `${deliveryAddress.rrdi} ${deliveryAddress.company || ""}, ${deliveryAddress.city || ""}`,
      value: deliveryAddress.rrdi,
    }));
  };

  const filterCategory = (
    categories: DictionaryItem[],
    group: string
  ): DictionaryItem[] => {
    return categories.filter((category) => {
      const categoryKeyArray = category.key.split("_");
      return categoryKeyArray[0] === group;
    });
  };

  const changeType = (event: SyntheticEvent, data: any): void => {
    setType(data.value);
  };

  const inputDescription = (event: SyntheticEvent, data: any): void => {
    setDescription(data.value);
  };

  const filterType = (
    types: DictionaryItem[],
    category: string
  ): DictionaryItem[] => {
    return types.filter((type) => {
      const typeKeyArray = type.key.split("_");
      return typeKeyArray[0] === category.split("_")[1];
    });
  };

  const validMessage = (): string | undefined => {
    if (customer === "") {
      return "Wybranie płatnika jest wymagane";
    } else if (delivery === "") {
      return "Wybranie dostawy jest wymagane";
    } else if (category === "") {
      return "Wymagane wybranie kategorii";
    } else if (type === "") {
      return "Wymagane wybranie typu";
    } else if (description === "") {
      return "Opis nie może być pusty";
    } else if (file === undefined) {
      return "WYmagane wybranie pliku";
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    setFile(undefined);
    setCustomer("");
    setDelivery("");
    setCategory("");
    setType("");
    setDescription("");
    clearCollectiveData();
  };

  const isValidForm = () => {
    return !(
      customer === "" ||
      delivery === "" ||
      description === "" ||
      category === "" ||
      type === ""
    );
  };

  const errorMessage = validMessage();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      size="small"
      closeOnDimmerClick={false}
    >
      <Header icon="file alternate" content="Zbiorowe zadanie" />
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Kod RRDI płatnika za zamówienie*</label>
              <UberSearch
                fluid
                placeholder="Wybierz płatnika"
                endpoint="/customers"
                mapper={mapCustomers}
                queryParams={[
                  {
                    name: "onlyPayers",
                    value: "true",
                  },
                ]}
                onResultSelect={changeCustomer}
                debounce={700}
              />
            </Form.Field>
            <Form.Field disabled={customer === ""}>
              <label>Kod RRDI klienta do którego nastąpiła dostawa*</label>
              <Dropdown
                placeholder="Wybierz klienta"
                fluid
                selection
                value={delivery}
                style={{ display: "inline-grid" }}
                onChange={changeDelivery}
                options={mapDelivery(deliveryAddresses)}
                noResultsMessage={
                  <FormattedMessage id="app.noResultsMessage" />
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Kategoria składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              value={category}
              onChange={changeCategory}
              options={filterCategory(dictionaries["claim-category"], "RK1")}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field disabled={category === ""}>
            <label>Typ składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz typ"
              fluid
              selection
              value={type}
              onChange={changeType}
              options={filterType(dictionaries["claim-type"], category)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Opis reklamacji na podstawie informacji od klienta*</label>
            <TextArea
              placeholder="Wypełnij opis"
              value={description}
              onInput={inputDescription}
            />
          </Form.Field>
        </Form>
        <ModalMessageHeader
          format={false}
          differentFiles="CSV"
          additionalMessage="numer linii faktury;numer faktury;referencja;ilość do zareklamowania"
        />
        <Segment placeholder>
          <div
            className="bonus-order-import-table-wrapper"
            style={{
              display:
                collectiveTaskUploadedData.claimsCreated.length > 0
                  ? "block"
                  : "none",
            }}
          >
            <span>
              Ilość wgranych reklamacji:{" "}
              <strong>{collectiveTaskUploadedData.claimsCreated.length}</strong>
            </span>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nr faktury</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{showUploadedCollectiveTask()}</Table.Body>
            </Table>
          </div>
          <div
            className="bonus-order-import-table-wrapper"
            style={{
              display:
                collectiveTaskUploadedData.errors.length > 0 ? "block" : "none",
            }}
          >
            <span style={{ color: "red" }}>
              Ilość błędów:{" "}
              <strong>{collectiveTaskUploadedData.errors.length}</strong>
            </span>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Treść błędu</Table.HeaderCell>
                  <Table.HeaderCell>Linie</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{showErrorsCollectiveTask()}</Table.Body>
            </Table>
          </div>
          <Header icon>
            <Icon name="file archive outline" />
            {file ? file.name : "Załaduj plik"}
          </Header>
          <Input type="file" onChange={handleFileChange} />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          trigger={
            <span>
              <Button
                primary
                content="Wczytaj"
                icon="upload"
                loading={uploading}
                disabled={
                  file === undefined ||
                  !validateFile(file.name) ||
                  !isValidForm()
                }
                labelPosition="right"
                onClick={() => handleUpload()}
              />
            </span>
          }
          content={errorMessage}
          disabled={isValidForm()}
        />
        <Button content="Zamknij" onClick={() => handleClose()} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({
  claims,
  customers,
  dictionaries,
}: ApplicationState) => ({
  uploading: claims.uploadingCollectiveTask,
  uploadedCollectiveTask: claims.uploadedCollectiveTask,
  collectiveTaskUploadedData: claims.collectiveTaskUploadedData,
  deliveryAddresses: customers.selected
    ? customers.selected.deliveryAddresses
    : [],
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  uploadCollectiveTask,
  clearCollectiveData,
  selectCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectiveTask);
