import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Divider } from "semantic-ui-react";
import {
  DictionariesState,
  DictionaryItem,
  DictionaryName,
} from "~/store/dictionaries/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";

import React, { Fragment, useEffect, useRef, useState } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import FetchBackInTheBoxReport from "~/pages/Claims/lib/BackInTheBoxReportModal/BackInTheBoxReportModal";
import AddClaim from "./lib/Add/Add";
import CollectiveTask from "~/pages/Claims/Details/lib/Task";
import translations from "~/utils/translations";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { IClaim } from "~/store/claims/types";
import { fetchClaims } from "~/store/claims/actions";
import { useClaimsButtons } from "./hooks/useClaimsButtons";
import { useHandleDownloadModal } from "./hooks/useHandleDownloadModal";
import { acceptedValue, claimedValue } from "./Details/lib/AcceptValues";
import ClaimsList from "./List";
import { useClaimsAdvancedSearch } from "./hooks/useClaimsAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useClaimsColumns } from "./hooks/useClaimsColumns";
import { useCells } from "~/services/useCells";
import { useClaimsRolesRights } from "./hooks/useClaimsRights";

type PropsFromState = {
  loading: boolean;
  dictionariesLoading: boolean;
  claims: IClaim[];
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  totalRecords: TotalRecords;
  fetchBackInTheBoxReportPending: boolean;
};

interface PropsFromDispatch {
  fetchClaims: typeof fetchClaims;
  fetchDictionary: typeof fetchDictionary;
}

type ClaimsProps = PropsFromState &
  PropsFromDispatch &
  ConnectedReduxProps &
  RouteComponentProps;

export const Claims: React.FC<ClaimsProps> = ({
  totalRecords,
  dictionaries,
  meta,
  claims,
  loading,
  dictionariesLoading,
  fetchBackInTheBoxReportPending,
  fetchClaims,
  fetchDictionary,
}) => {
  const {
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  } = useClaimsRolesRights();
  const { columnsConfig } = useClaimsColumns({
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  });
  const [isOpen, setIsOpen] = useState(false);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("claims");
  const [sortColumn] = useState<any>("createDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [openCollectiveTaskModal, setOpenCollectiveTaskModal] =
    useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openBackInTheBoxModal, setOpenBackInTheBoxModal] =
    useState<boolean>(false);
  const [columns, setColumns] = useState(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const [initiated, setInitiated] = useState(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    columns,
  });

  const {
    downloadButton,
    addButton,
    collectiveTaskButton,
    backInTheBoxButton,
  } = useClaimsButtons({
    disableButton,
    exportPercent,
    setOpenCollectiveTaskModal,
    setOpenCreateModal,
    fetchBackInTheBoxReportPending,
    setOpenBackInTheBoxModal,
    handleOpenDownloadModal,
  });

  const getClaims = (items?: Object): void => {
    const paramsObj = {
      page: meta.page,
      size: meta.size,
      filters: filterLabelsRef.current,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };
    setParams(paramsObj);
    fetchClaims(Object.assign(paramsObj, items));
  };

  useEffect(() => {
    filterLabelsRef.current = params.filters;
    setTimeout(() => setInitiated(true), 100);
  }, [params]);

  useEffect(() => {
    document.title = translations.format("LDC_CLAIMS");
    fetchDictionary(
      DictionaryName.claimType,
      DictionaryName.claimCategory,
      DictionaryName.claimGroup,
      DictionaryName.claimStatus,
      DictionaryName.claimTransportStatus,
      DictionaryName.claimWarehouseStatus
    );
  }, [fetchDictionary]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  const replaceDictionaryValue = (
    props: any,
    dictionary: DictionaryItem[]
  ): string => {
    const value = dictionary.find((d: any) => d.value === props);
    return value
      ? value.text
      : props === ""
        ? ""
        : props === null
          ? ""
          : `${props} !!!`;
  };

  const mapClaims = (claims: IClaim[]): IClaim[] => {
    return claims.map((claim: any) => ({
      ...claim,
      claimGroup: replaceDictionaryValue(
        claim.claimGroup,
        dictionaries["claim-group"]
      ),
      claimCategory: replaceDictionaryValue(
        claim.claimCategory,
        dictionaries["claim-category"]
      ),
      claimType: replaceDictionaryValue(
        claim.claimType,
        dictionaries["claim-type"]
      ),
      status: replaceDictionaryValue(
        claim.status,
        dictionaries["claim-status"]
      ),
      createDate: claim.createDate,
      claimedValue: claimedValue(claim),
      value: acceptedValue(claim),
    }));
  };

  const { formFields, handleSubmit } = useClaimsAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getClaims,
    filterLabelsRef,
    tableKey
  );

  return initiated ? (
    <Fragment>
      <PageHeader
        icon="exclamation triangle"
        title={<FormattedMessage id="app.claims" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={[
          backInTheBoxButton,
          downloadButton,
          addButton,
          collectiveTaskButton,
        ]}
        refreshAction={() => {
          getClaims({ ...params, page: 1 });
        }}
        loading={loading || dictionariesLoading}
      />
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getClaims}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        <ClaimsList claims={mapClaims(claims)} columns={columns} />
      </DataGridTable>
      <AddClaim
        triggerOpen={openCreateModal}
        openHandler={setOpenCreateModal}
        createdSuccess={() => getClaims()}
      />
      {/* @ts-ignore: missing props */}
      <CollectiveTask
        triggerOpen={openCollectiveTaskModal}
        openHandler={setOpenCollectiveTaskModal}
      />
      <FetchBackInTheBoxReport
        openBackInTheBoxModal={openBackInTheBoxModal}
        closeModal={() => setOpenBackInTheBoxModal(false)}
      />
    </Fragment>
  ) : (
    <></>
  );
};

const mapStateToProps = ({
  claims,
  dictionaries,
}: ApplicationState): PropsFromState => ({
  claims: claims.list,
  meta: claims.meta,
  loading: claims.loadingClaims,
  totalRecords: claims.totalRecords,
  dictionaries,
  fetchBackInTheBoxReportPending: claims.fetchBackInTheBoxReportPending,
  dictionariesLoading: dictionaries.loading,
});

const mapDispatchToProps: PropsFromDispatch = {
  fetchClaims,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Claims));
