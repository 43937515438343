import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApplicationState } from "~/store";
import { fetchAnomalies } from "~/store/anomalies/actions";
import { Anomaly } from "~/store/anomalies/types";
import { fetchBackorders } from "~/store/backorder/actions";
import { Backorder } from "~/store/backorder/types";
import { fetchClaims } from "~/store/claims/actions";
import { IClaim } from "~/store/claims/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { fetchOrders } from "~/store/orders/actions";
import { Order } from "~/store/orders/types";
import { useDashboardRoles } from "./hooks/useDashboardRoles";
import translations from "~/utils/translations";
import { useOrdersTable } from "./hooks/useOrdersTable";
import { useBackordersTable } from "./hooks/useBackordersTable";
import { useClaimsTable } from "./hooks/useClaimsTable";
import { useAnomalyTable } from "./hooks/useAnomalyTable";
import { Grid } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { NotPermissionInfo } from "./NotPermissionInfo";

type TReduxState = {
  orders: Order[];
  loadingOrders: boolean;
  backorders: Backorder[];
  loadingBackorders: boolean;
  anomalies: Anomaly[];
  loadingAnomalies: boolean;
  claims: IClaim[];
  loadingClaims: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchOrders: typeof fetchOrders;
  fetchBackorders: typeof fetchBackorders;
  fetchAnomalies: typeof fetchAnomalies;
  fetchClaims: typeof fetchClaims;
  fetchDictionary: typeof fetchDictionary;
};

type TProps = TReduxState & TReduxActions & RouteComponentProps;

const DashboardDistrigo: React.FC<TProps> = ({
  orders,
  loadingOrders,
  backorders,
  loadingBackorders,
  anomalies,
  loadingAnomalies,
  claims,
  loadingClaims,
  dictionaries,
  fetchOrders,
  fetchBackorders,
  fetchAnomalies,
  fetchClaims,
  fetchDictionary,
}) => {
  const {
    userHaveOrderViewRole,
    userHaveBackorderViewRole,
    userHaveAnomalyViewRole,
    userHaveClaimsViewRole,
  } = useDashboardRoles();

  const getOrders = useCallback(
    (params?: Object) => {
      const paramsObj = {
        page: 1,
        size: 7,
        filters: [],
        sortColumn: "orderDate",
        sortDirection: "DESC",
      };
      fetchOrders(Object.assign(paramsObj, params));
    },
    [fetchOrders]
  );

  const getBackorders = useCallback(
    (params?: Object) => {
      const paramsObj = {
        page: 1,
        size: 7,
        filters: [],
        sortColumn: "creationTime",
        sortDirection: "DESC",
      };
      fetchBackorders(Object.assign(paramsObj, params));
    },
    [fetchBackorders]
  );

  const getAnomalies = useCallback(
    (params?: Object) => {
      const paramsObj = {
        page: 1,
        size: 7,
        filters: [],
        sortColumn: null,
        sortDirection: null,
      };
      fetchAnomalies(Object.assign(paramsObj, params));
    },
    [fetchAnomalies]
  );

  const getClaims = useCallback(
    (params?: Object) => {
      const paramsObj = {
        page: 1,
        size: 7,
        filters: [],
        sortColumn: "createDate",
        sortDirection: "DESC",
      };
      fetchClaims(Object.assign(paramsObj, params));
    },
    [fetchClaims]
  );

  const getData = useCallback(() => {
    userHaveOrderViewRole && getOrders();
    userHaveBackorderViewRole && getBackorders();
    userHaveAnomalyViewRole && getAnomalies();
    userHaveClaimsViewRole && getClaims();
  }, [
    getOrders,
    getBackorders,
    getAnomalies,
    getClaims,
    userHaveAnomalyViewRole,
    userHaveBackorderViewRole,
    userHaveClaimsViewRole,
    userHaveOrderViewRole,
  ]);

  useEffect(() => {
    document.title = translations.format("app.dashboard");
    fetchDictionary(
      DictionaryName.orderDeliveryType,
      DictionaryName.paymentDue,
      DictionaryName.paymentMethod,
      DictionaryName.paymentFactor,
      DictionaryName.orderType,
      DictionaryName.orderStatus,
      DictionaryName.backorderState,
      DictionaryName.anomalyType,
      DictionaryName.anomalyStatus
    );
    getData();
  }, [fetchDictionary, getData]);

  const { renderOrdersTable } = useOrdersTable(orders);
  const { renderBackordersTable } = useBackordersTable(backorders);
  const { renderClaimsTable } = useClaimsTable(claims);
  const { renderAnomalyTable } = useAnomalyTable(anomalies, dictionaries);
  return (
    <>
      <PageHeader
        icon="home"
        title="Panel"
        breadcrumb={[{ text: "Distrigo Portal" }]}
        buttons={[]}
        loading={false}
        refreshAction={getData}
      />
      <Grid
        style={{ marginBottom: "unset" }}
        className="dashboard-tables"
        stretched
      >
        <DetailCard
          title="app.clientOrders"
          id="orders"
          key="orders"
          minHeight={40}
          width={8}
        >
          {userHaveOrderViewRole ? (
            <div className="dashboard-table-wrapper">{renderOrdersTable()}</div>
          ) : (
            <NotPermissionInfo name="app.orders" />
          )}
          <CommonLoader loading={loadingOrders} />
        </DetailCard>
        <DetailCard
          title="app.backorder.list.title"
          id="backorders"
          key="backorders"
          minHeight={40}
          width={8}
        >
          {userHaveBackorderViewRole ? (
            <div className="dashboard-table-wrapper">
              {renderBackordersTable()}
            </div>
          ) : (
            <NotPermissionInfo name="app.backorders" />
          )}
          <CommonLoader loading={loadingBackorders} />
        </DetailCard>
        <DetailCard
          title="app.anomalies"
          id="anomalies"
          key="anomalies"
          minHeight={40}
          width={8}
        >
          {userHaveAnomalyViewRole ? (
            <div className="dashboard-table-wrapper">
              {renderAnomalyTable()}
            </div>
          ) : (
            <NotPermissionInfo name="app.anomalies" />
          )}
          <CommonLoader loading={loadingAnomalies} />
        </DetailCard>
        <DetailCard
          title="app.reclamation"
          id="claims"
          key="claims"
          minHeight={40}
          width={8}
        >
          {userHaveClaimsViewRole ? (
            <div className="dashboard-table-wrapper">{renderClaimsTable()}</div>
          ) : (
            <NotPermissionInfo name="app.claims" />
          )}
          <CommonLoader loading={loadingClaims} />
        </DetailCard>
      </Grid>
    </>
  );
};

const mapStateToProps = ({
  dictionaries,
  orders,
  backorders,
  anomalies,
  claims,
}: ApplicationState) => ({
  orders: orders.list,
  anomalies: anomalies.list,
  backorders: backorders.list,
  claims: claims.list,
  loadingOrders: orders.loadingOrders || dictionaries.loading,
  loadingBackorders: backorders.loadingBackorders || dictionaries.loading,
  loadingAnomalies: anomalies.loadingAnomalies || dictionaries.loading,
  loadingClaims: claims.loadingClaims || dictionaries.loading,
  dictionaries,
});

const mapDispatchToProps = {
  fetchDictionary,
  fetchOrders,
  fetchBackorders,
  fetchAnomalies,
  fetchClaims,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardDistrigo));
