import { Popup, Ref, Table, TableCellProps } from 'semantic-ui-react'

import React, { useEffect, useMemo, useRef, useState } from 'react'

export type TProps = {} & TableCellProps

const TableCellPopup: React.FC<TProps> = (props) => {

    const ref = useRef<HTMLTableCellElement>(null)
    const [popup, setPopup] = useState<boolean>(false)

    useEffect(() => {
        if (ref.current) {
            setPopup(ref.current.scrollWidth > ref.current.clientWidth)
        }
    },[])

    return useMemo(() => {
        return <Ref innerRef={ref}>
            {popup ?
                <>
                    <Popup trigger={<Table.Cell {...props} />} content={<em>{props.children}</em>} size="tiny"
                         position="top center" mouseEnterDelay={500}/>
                </>
                :
                <Table.Cell {...props} />
            }
        </Ref>
    }, [popup, props])
}

export default TableCellPopup
