import { roundQuantity } from '~/utils/roundQuantity';
import { MIN_VALUE, NUMBER_VALUES, REQUIRED } from '~/components/SmartField/lib/validator';
import { Table } from 'semantic-ui-react';
import React from 'react';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { QuantityTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/QuantityTableCell/constants';

export const QuantityTableCell = ({ column, line, editMode }: QuantityTableCellProps) => {
  const { renderNumber } = useRenderingFunctions(line, editMode);

  return (
    <Table.Cell key={column.name}>
      {line.type !== 'PROVISION' && line.type !== 'REPLACEMENT' ? (
        renderNumber('quantity', {
          style: { width: 95 },
          step: line.packagingQuantity,
          saveMapper: (value) => roundQuantity(value, line.packagingQuantity),
          validators: [REQUIRED, NUMBER_VALUES, MIN_VALUE(line.packagingQuantity ? line.packagingQuantity : 1)],
        })
      ) : (
        <div style={{ width: 100 }}>{line.quantity}</div>
      )}
    </Table.Cell>
  );
};
