import { Table } from 'semantic-ui-react';
import { parsePrice } from '~/utils/parsePrice';
import React from 'react';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { ORDER_TYPE_ENUM } from '~/store/orders/types';
import { NetPriceTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/NetPriceTableCell/constants';

export const NetPriceTableCell = ({ column, orderType, line, editMode }: NetPriceTableCellProps) => {
  const { renderNumber } = useRenderingFunctions(line, editMode);

  return (
    <Table.Cell key={column.name} style={{ textAlign: 'right' }}>
      {orderType === ORDER_TYPE_ENUM.SPECIAL
        ? renderNumber('netPrice', {
            style: {
              width: 95,
              textAlign: 'right',
            },
            precision: 2,
            min: 0,
          })
        : parsePrice(line[column.name])}
    </Table.Cell>
  );
};
