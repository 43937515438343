import { PaginationMeta, TotalRecords, Paginable } from '~/store/types'
import { SupplierSystem } from '../customer/types'

export type BackorderCustomer = {
    key?: string
    address: string
    city: string
    name: string
    postCode: string
    rrdi: string
    ddsDeliveryId?: string
    sapDeliveryId?: string
    supplierSystemClientId?: null | string
    supplierSystemDeliveryId?: null | string
    supplierSystem?: SupplierSystem[] | null
}

export type ProductCodes = {
    vin?: ProductCodeField
    cleCode?: ProductCodeField
    transmitterCode?: ProductCodeField
    wheelCode?: ProductCodeField
    nreCode?: ProductCodeField
    tiresCode?: ProductCodeField
}

export type ProductCodeField = {
    required: boolean
    value?: string
}

export type BackorderLine = {
    number: number
    referenceId: string
    description: string
    packagingQuantity: number
    quantity: number
    price: number
    supplier: 'PLPR' | 'PLOV'
    confirmedQuantity: number
    confirmedDocuments: string[]
    receptionQuantity: number
    receptionDocuments: string[]
    receivedQuantity: number
    receivedDocuments: string[]
    codes?: ProductCodes
    shippedReferenceId?: string
}

export interface Backorder {
    id: string
    creationTime: string
    customer: BackorderCustomer
    deliveryCustomer: BackorderCustomer
    lines: BackorderLine[]
    mainBackorderID: string
    method: "CROSS_DOCK" | "REPLENISHMENT"
    orderId: string
    realisation: number
    status: TBackorderStatus
    updateTime: string
    type: "URGENT" | "STOCK"
    linesQuantity: number
    backorderNumber: string
    priority: number
    batchId: string
    sentTime: string
    comments: string
    supplier: string
}

export type TBackorderStatus = "NEW" | "QUEUED" | "SENT" | "INVOICED" | "AWAITING RECEPTION" | "RECEPTED" | "INVOICED CROSS-DOC" | "CANCELED" | "CLOSED" | "ANOMALY"

export type TBackorderImportLine = {
    codes: ProductCodes
    description: string
    forcePackagingQuantity: number
    orderPartType: "NORMAL" | "REPLACED" | "REPLACEMENT"
    pnrPrice: number
    pvpPrice: number
    priceTax: string
    quantity: number
    referenceID: string
    stock: number
    success?: boolean
    error?:boolean
}

export interface IBackorderCreate {
    customerRrdi: string
    deliveryCustomerRrdi: string
}

export enum BackordersActionTypes {
    FETCH_REQUEST = '@@backorder/FETCH_REQUEST',
    FETCH_SEARCH_REQUEST = '@@backorder/FETCH_SEARCH_REQUEST',
    FETCH_SUCCESS = '@@backorder/FETCH_SUCCESS',
    FETCH_SEARCH_SUCCESS = '@@backorder/FETCH_SEARCH_SUCCESS',
    SELECT = '@@backorder/SELECT',
    SELECTED = '@@backorder/SELECTED',
    CLEAR_SELECTED = '@@backorder/CLEAR_SELECTED',
    FETCH_LINES = '@@backorder/FETCH_LINES',
    FETCH_LINES_SUCCESS = '@@backorder/FETCH_LINES_SUCCESS',
    ADD_LINE = '@@backorder/ADD_LINE',
    UPDATE_LINE = '@@backorder/UPDATE_LINE',
    DELETE_LINE = '@@backorder/DELETE_LINE',
    LOAD_CSV = '@@backorder/LOAD_CSV',
    LOAD_XLS = '@@backorder/LOAD_XLS',
    LOAD_LINES_LOADED = '@@backorder/LOAD_LINES_LOADED',
    IMPORT_LINES = '@@backorder/IMPORT_LINES',
    UPDATE_PROGRESS = '@@backorder/UPDATE_PROGRESS',
    LINES_OPERATION_FINISHED = '@@backorder/LINES_OPERATION_SUCCESS',
    ADD_BACKORDER = '@@backorder/ADD_BACKORDER',
    ADD_BACKORDER_SUCCESS = '@@backorder/ADD_BACKORDER_SUCCESS',
    CLEAR_CREATED = '@@backorder/CLEAR_CREATED'
}

export interface BackordersState {
    readonly list: Backorder[]
    readonly operationSuccess: boolean
    readonly searchList: Backorder[]
    readonly filteredCustomers: any[]
    readonly filteredAddresses: any[]
    readonly meta: PaginationMeta
    readonly linesMeta: PaginationMeta
    readonly created: boolean
    readonly creating: boolean
    readonly createdId: string
    readonly parsedLines: BackorderLine[]
    readonly progress: number
    readonly loadingBackorders: boolean
    readonly loadingBackorder: boolean
    readonly loadingLines: boolean
    readonly totalRecords: TotalRecords
    readonly selectedLines?: BackorderLine[]
    readonly selected?: Backorder
}

export type UpdateCustomerPayload = {
    backorderId: string
    rrdi: string
}

export type ModifyLinePayload = {
    backorderId: string
    line: BackorderLine
}

export type UploadLinesPayload = {
    textLines?: string
    excelFile?: File
}

export type PaginableBackorder = Paginable<Backorder>

export type PaginableBackorderLine = Paginable<BackorderLine>

export type PaginationParams = {
    page: number
    size: number
}