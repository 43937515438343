import { TModelInputProps } from "~/components/ModelForm/lib/types";
import React, { useMemo } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { useFormElement } from "~/components/ModelForm/hooks/useFormElement";
import UberSearch from "../UberSearch/UberSearch";

import "./lib/styles.scss";
import { FormattedMessage } from "react-intl";

export const ModelInput = <T,>(props: TModelInputProps<T>) => {
  const { validators, searchUrl, multiselectList, required } = props;

  const { value, validation, handleChange, handleBlur, formatPopupContent } =
    useFormElement(props);

  const hasRequiredValidator = useMemo(
    () => validators?.indexOf(REQUIRED) !== -1,
    [validators]
  );

  const mapItems = (payers: any[]): any[] => {
    return payers.map((payer, index) => {
      const key = `${payer.displayName}-${index}`;
      return {
        key,
        title: payer.displayName,
        description: payer.displayAddress,
        payer: { ...payer },
      };
    });
  };

  const renderNormal = () => (
    <SmartPopup description={formatPopupContent()}>
      <Form.Input
        required={hasRequiredValidator}
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!validation[props.name]}
      />
    </SmartPopup>
  );

  const renderSearch = () => (
    <>
      <label
        style={{
          fontWeight: "bold",
          marginBottom: 4,
          color: !!validation[props.name] ? "#9F3A38" : "unset",
        }}
      >
        {props.label}
        {required ? <span>*</span> : null}
      </label>
      <UberSearch
        className={`search-control ${!!validation[props.name] ? "error" : ""}`}
        onBlur={handleBlur}
        placeholder="Wyszukaj..."
        fluid
        endpoint={searchUrl || ""}
        onResultSelect={(selected) => {
          handleChange(null, { value: selected.payer.rrdi });
          return selected.payer.displayName;
        }}
        mapper={(response) => mapItems(response)}
        debounce={700}
      />
    </>
  );

  const renderMultiSelect = () => (
    <>
      <label
        style={{
          fontSize: 12,
          fontWeight: "bold",
          marginBottom: 4,
          color: !!validation[props.name] ? "#9F3A38" : "unset",
        }}
      >
        {props.label}
        {required ? <span>*</span> : null}
      </label>
      <Dropdown
        className={`search-control ${!!validation[props.name] ? "error" : ""}`}
        placeholder="Wybierz..."
        multiple
        fluid
        selection
        onChange={(e, data) => {
          handleChange(null, { value: data.value });
        }}
        onBlur={handleBlur}
        value={value || []}
        options={multiselectList}
        noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
      />
    </>
  );

  if (searchUrl) {
    return renderSearch();
  } else if (multiselectList) {
    return renderMultiSelect();
  } else {
    return renderNormal();
  }
};
