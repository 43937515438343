import { Table } from 'semantic-ui-react';
import React, { useMemo } from 'react';
import dictionariesStatic from '~/store/dictionaries/static';
import { TableCellType } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';

export const RejectionReasonTableCell = ({ line, column }: TableCellType) => {
  const reasonArray = useMemo(() => {
    const reasons = line[column.name] ? line[column.name] : [];

    if (line.type === 'REPLACED_TO_ORIGINAL') {
      reasons.push({
        type: 'REPLACED_TO_ORIGINAL',
        referenceID: line.referenceID,
      });
    }

    if (line.type === 'REPLACED') {
      reasons.push({
        type: 'REPLACEMENTS_FOUND',
        referenceID: line.referenceID,
      });
    }

    return reasons;
  }, [column.name, line]);

  const getRejectionLabel = (type: string): JSX.Element => {
    const item = dictionariesStatic.REJECTION_REASON.find((item) => item.value === type);

    return (
      <div key={type} style={{ whiteSpace: 'pre', color: 'red' }} title={type}>
        {item ? item.text : type}
      </div>
    );
  };

  return <Table.Cell key={column.name}>{reasonArray.map((reason: any) => getRejectionLabel(reason.type))}</Table.Cell>;
};
