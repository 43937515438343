import { SyntheticEvent } from "react";
import { DictionaryItem } from "~/store/dictionaries/types";

export interface HandleChange {
  [key: string]: string | any;
}

export enum FIELD_TYPE {
  INPUT = "INPUT",
  SELECT = "SELECT",
  DATE_PICKER = "DATE_PICKER",
  CHECKBOX = "CHECKBOX",
  SEARCH = "SEARCH",
}

export type FormField = {
  type: FIELD_TYPE;
  name?: string;
  label: string;
  placeholder?: string;
  id?: string;
  value?: string | Date | boolean | (string | number | boolean)[];
  minDate?: Date;
  options?: DictionaryItem[];
  maxLength?: number;
  multiple?: boolean;
  searchSelect?: boolean;
  uberSearch?:
    | {
        endpoint: string;
        debounce: number;
        mapper: (response: any) => any[];
        onResultSelect: (selected: any) => string;
      }
    | undefined;
  onChange: (
    e: SyntheticEvent<Element, Event> | undefined,
    { name, value }: HandleChange
  ) => void;
};
