export interface IVersion extends ApiResponse {
    version: string
}

export type ApiResponse = Record<string, any>

export enum VersionActionTypes {
    FETCH_VERSION = '@@version/FETCH_VERSION',
    FETCH_VERSION_SUCCESS = '@@version/FETCH_VERSION_SUCCESS',
}

export interface VersionState {
    readonly loading: boolean
    readonly version: string
}