import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import ClaimsList from "~/pages/Claims/List";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { IClaim } from "~/store/claims/types";

export const useClaimsTable = (claims: IClaim[]) => {
  const { getTextCell, getDateCell, getLinkCell } = useCellsRenderer();

  const columns: TColumn[] = useMemo(
    () => [
      {
        name: "claimNumber",
        label: "Numer",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "createDate",
        label: "Utworzone",
        projection: true,
        getCell: (d, c) => getDateCell(d, c, true),
      },
      {
        name: "invoiceDocumentMask",
        label: "Faktura",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "orderNumber",
        label: "Zamówienie",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "orderCustomerID",
        label: "Klient",
        projection: true,
        getCell: (d, c) => getLinkCell(d, c, `/customer/${d.orderCustomerID}`),
      },
      {
        name: "accountingCustomerID",
        label: "Płatnik",
        projection: true,
        getCell: (d, c) =>
          getLinkCell(d, c, `/customer/${d.accountingCustomerID}`),
      },
    ],
    [getDateCell, getLinkCell, getTextCell]
  );

  const renderClaimsTable = () => (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {columns.map((column) => (
            <Table.HeaderCell key={column.name}>
              {column.label}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <ClaimsList claims={claims} columns={columns} />
      </Table.Body>
    </Table>
  );

  return { renderClaimsTable };
};
