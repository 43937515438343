import { put } from "redux-saga/effects";
import { ApiError } from "~/store/error/types";
import { handleError } from "~/store/error/actions";

export const handleFileUploadError = (error: ApiError) => {
  console.log(error.message);
  if (error?.message?.includes("Duplicated references")) {
    const lines = error.message.split(":")[1];
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przesyłania pliku",
        `Zduplikowane linie: ${lines}`
      )
    );
  } else if (error?.message?.includes("must be in ACCEPTED status to cancel spare part")) {
    const { message = "Nieznany błąd" } = error;
    const [orderId] = message.match(/ (\S+)/) || ["nieznane"];
    const [partId] = message.match(/ (\d+)/) || ["nieznany"];
    const finalMessage = `Zamówienie: ${orderId} musi być w statusie 'Przyjęte', aby anulować referencję: ${partId}`;

    return put(
        handleError(
            error,
            false,
            "Błąd podczas przesyłania pliku",
            finalMessage
        )
    );
  } else if (error.status === 413) {
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przesyłania pliku",
        "Plik przekracza maksymalny rozmiar 15MB."
      )
    );
  } else if (error.status === 400) {
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przesyłania pliku",
        error.message ? error.message : "Plik ma nie prawidłową strukturę."
      )
    );
  } else if (error.status === 422) {
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przetwarzania pliku",
        "Plik posiada niewłaściwy zestaw danych."
      )
    );
  } else if (error.status === 405) {
    const reference = error.message.split(" ")[3];
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przetwarzania pliku",
        `Kaucja dla referencji ${reference} jest wymagana, lecz nie została dostarczona`
      )
    );
  } else if (error.status === 404) {
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przetwarzania pliku",
        error.message || ""
      )
    );
  } else {
    return put(
      handleError(
        error,
        false,
        "Błąd podczas przesyłania pliku",
        error.message || ""
      )
    );
  }
};
