import React from "react";
import { Form, Table, TextArea } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import {
  actionTypes,
  claimCategoriesToDisplayInputs,
  editFormColumns,
  FormCategory,
} from "./transitionColumns.conf";

interface AwaitingDecisionEditLineProps {
  transitionLineIndex: number;
  transitionLines: any[];
  changeLine: any;
  claimCategory: string;
  claimGroup: string;
  formCategory: FormCategory;
  claimedQuantity: number;
}

const AwaitingDecisionEditLine = ({
  transitionLineIndex,
  transitionLines,
  changeLine,
  claimCategory,
  claimGroup,
  formCategory,
  claimedQuantity,
}: AwaitingDecisionEditLineProps) => {
  if (
    transitionLineIndex === null ||
    claimedQuantity === 0 ||
    claimedQuantity === null
  )
    return null;

  let line = transitionLines[transitionLineIndex][`${formCategory}`];

  const { referenceName, referenceId } = transitionLines[transitionLineIndex];

  return (
    <DetailCard
      title={`${formCategory === "RECEIVED_FULL_VALUE" ? "Pełnowartościowe" : "Niepełnowartościowy"}:  ${referenceName} (${referenceId})`}
      id="status"
      key="status"
      minHeight={100}
      width={16}
      titleFormat={true}
    >
      <Table>
        <Table.Header>
          <Table.Row>
            {editFormColumns.map((column) =>
              column.name !== "costCoveringGroup" ? (
                <Table.HeaderCell
                  key={column.name}
                  style={{ width: column.width }}
                >
                  {column.label}
                </Table.HeaderCell>
              ) : null
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {claimCategoriesToDisplayInputs[formCategory][
            "DECISION_ACCEPTED"
          ].includes(claimCategory) ? (
            <Table.Row>
              <Table.Cell>{actionTypes[claimGroup === "RK5" ? "DECISION_ACCEPTED_NO_INVOICE" : "DECISION_ACCEPTED"]}</Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={line && line.isNotValidQuantity}
                  value={
                    line && line.acceptedQuantity !== undefined
                      ? line.acceptedQuantity || 0
                      : claimedQuantity
                  }
                  onChange={(e, d) =>
                    changeLine(e, d, `acceptedQuantity`, formCategory)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ) : null}

          {claimCategoriesToDisplayInputs[formCategory][
            "DECISION_ACCEPTED_WITHOUT_CORRECTION"
          ].includes(claimCategory) ? (
            <Table.Row>
              <Table.Cell>
                {actionTypes["DECISION_ACCEPTED_WITHOUT_CORRECTION"]}
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={line && line.isNotValidQuantity}
                  value={
                    line ? line.decisionAcceptedWithoutCorrectionQuantity : 0
                  }
                  onChange={(e, d) =>
                    changeLine(
                      e,
                      d,
                      `decisionAcceptedWithoutCorrectionQuantity`,
                      formCategory
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          ) : null}

          {claimCategoriesToDisplayInputs[formCategory][
            "DECISION_TO_DESTROY"
          ].includes(claimCategory) ? (
            <Table.Row>
              <Table.Cell>{actionTypes["DECISION_TO_DESTROY"]}</Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={line && line.isNotValidQuantity}
                  value={line ? line.decisionToDestroyQuantity : 0}
                  onChange={(e, d) =>
                    changeLine(e, d, "decisionToDestroyQuantity", formCategory)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ) : null}

          {claimCategoriesToDisplayInputs[formCategory][
            "DECISION_TO_DESTROY_WITHOUT_CORRECTION"
          ].includes(claimCategory) ? (
            <Table.Row>
              <Table.Cell>
                {actionTypes["DECISION_TO_DESTROY_WITHOUT_CORRECTION"]}
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={line && line.isNotValidQuantity}
                  value={
                    line ? line.decisionToDestroyWithoutCorrectionQuantity : 0
                  }
                  onChange={(e, d) =>
                    changeLine(
                      e,
                      d,
                      "decisionToDestroyWithoutCorrectionQuantity",
                      formCategory
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          ) : null}

          {claimCategoriesToDisplayInputs[formCategory][
            "DECISION_REJECTED_TO_CLIENT"
          ].includes(claimCategory) ? (
            <Table.Row>
              <Table.Cell>
                {actionTypes["DECISION_REJECTED_TO_CLIENT"]}
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  min="0"
                  error={line && line.isNotValidQuantity}
                  value={line ? line.decisionRejectedToClientQuantity : 0}
                  onChange={(e, d) =>
                    changeLine(
                      e,
                      d,
                      "decisionRejectedToClientQuantity",
                      formCategory
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
      <Form>
        <Form.Field>
          <label>Komentarz do pozycji</label>
          <TextArea
            className="textarea-field"
            placeholder={transitionLines[transitionLineIndex].statusNotes}
            value={transitionLines[transitionLineIndex].statusNotes || ""}
            onChange={(e, d) => changeLine(e, d, "statusNotes")}
          />
        </Form.Field>
      </Form>
    </DetailCard>
  );
};

export default AwaitingDecisionEditLine;
