import { callApi } from '~/utils/api'


export async function downloadData(nameHTTPRequest: string, page: number, state: any, percent: void): Promise<any> {
    let filterLabelsToExport = ''
    state.filterLabels.forEach((result: { key?: string, name?: string, value?: string, text?: string }) => {
        filterLabelsToExport += result.name + '=' + result.value + '&'
    })
    return await callApi('get', `/` + nameHTTPRequest + `?page=` + page + `&size=` + 100 + '&' + filterLabelsToExport + 'sort=' + state.sortColumn + ',' + state.sortDirection)
        .then(result => {
            return result.content
        })
}
