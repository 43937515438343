import { useCallback, useState } from "react";
import { TNewAnnouncement } from "~/pages/Administration/lib/Announcements/AddAnnouncement/AddAnnouncement";
import { Announcement } from "~/store/annouoncements/types";
import { callApiImageThumbnail } from "~/utils/api";

type TProps = {
  imgId: string | null | undefined;
  announcement?: Announcement | TNewAnnouncement;
};

export const useGetAnnouncementImg = ({ imgId, announcement }: TProps) => {
  const [img, setImg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const getImageData = useCallback(async () => {
    if (announcement && "id" in announcement && !imgId?.includes("blob")) {
      setLoading(true);
      setNotFound(false);
      callApiImageThumbnail(`/customer-panel/images/${imgId}`)
        .then((imgItem) => {
          if (imgItem) {
            setImg(imgItem);
          } else {
            setNotFound(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [imgId, announcement]);

  return { getImageData, img, file, setImg, setFile, loading, notFound };
};
