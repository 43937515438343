import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { callApi, callApiUploadFile } from "~/utils/api";
import { handleError } from "~/store/error/actions";
import { handleFileUploadError } from "~/utils/fileUploadErrorHandler";
import {
  fetchSparePartForCP,
  fetchSparePartForCPError,
  fetchSparePartForCPSuccess,
  fetchSpareParts,
  fetchSparePartsSearchSuccess,
  fetchSparePartsSuccess,
  importFinished,
  importReferences,
  selectSparePart,
  sparePartSelected,
} from "./actions";
import { SparePart, SparePartCP, SparePartsActionTypes } from "./types";

import translations from "~/utils/translations";
import { Paginable } from "../types";

function* handleFetchSpareParts(action: ReturnType<typeof fetchSpareParts>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const spareParts: SparePart[] = yield call(
      callApi,
      "get",
      `/spare-parts?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );
    const sparePartsSearchSuccess: SparePart[] = yield put(
      fetchSparePartsSearchSuccess(spareParts)
    );
    const sparePartsSuccess: SparePart[] = yield put(
      fetchSparePartsSuccess(spareParts)
    );
    return action.type.toString() ===
      SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_REQUEST
      ? sparePartsSearchSuccess
      : sparePartsSuccess;
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.spare-parts.load")
      )
    );
  }
}

function* handleSelectSparePart(action: ReturnType<typeof selectSparePart>) {
  try {
    const sparePart: SparePart = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/spare-parts/${action.payload}`
    );
    yield put(sparePartSelected(sparePart));
  } catch (error) {
    yield put(
      handleError(
        error,
        true,
        translations.format("app.error.spare-parts.select")
      )
    );
  }
}

function* handleImportReferences(action: ReturnType<typeof importReferences>) {
  try {
    yield call(callApiUploadFile, `/spare-parts/import`, action.payload);
    yield put(importFinished());
  } catch (error) {
    yield handleFileUploadError(error);
  }
}

function* handleFetchSparePartsCP(
  action: ReturnType<typeof fetchSparePartForCP>
) {
  try {
    const { referenceId, clearForm, setErrorInfo } = action.payload;
    const spareParts: Paginable<SparePartCP> = yield call(
      callApi,
      "get",
      `/customer-panel/spare-parts?referenceId=${referenceId}`
    );
    if (spareParts && spareParts.content.length > 0) {
      yield clearForm();
    } else {
      yield setErrorInfo("Nie znaleziono podanej referencji");
    }
    yield put(fetchSparePartForCPSuccess(spareParts.content[0], setErrorInfo));
  } catch (error) {
    yield action.payload.setErrorInfo &&
      action.payload.setErrorInfo(
        "Wystąpił błąd podczas wyszukiwania referencji"
      );
    yield put(fetchSparePartForCPError(action.payload.setErrorInfo));
  }
}

function* watchFetchSparePartsRequest() {
  yield takeEvery(
    SparePartsActionTypes.FETCH_SPAREPARTS_REQUEST,
    handleFetchSpareParts
  );
}

function* watchFetchSparePartsSearchRequest() {
  yield takeEvery(
    SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_REQUEST,
    handleFetchSpareParts
  );
}

function* watchSelectSparePart() {
  yield takeLatest(
    SparePartsActionTypes.SELECT_SPAREPART,
    handleSelectSparePart
  );
}

function* watchImportReferences() {
  yield takeLatest(
    SparePartsActionTypes.IMPORT_REFERENCES,
    handleImportReferences
  );
}

function* watchFetchReferenceForCP() {
  yield takeLatest(
    SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP,
    handleFetchSparePartsCP
  );
}

function* sparePartsSaga() {
  yield all([
    fork(watchFetchSparePartsRequest),
    fork(watchSelectSparePart),
    fork(watchFetchSparePartsSearchRequest),
    fork(watchImportReferences),
    fork(watchFetchReferenceForCP),
  ]);
}

export default sparePartsSaga;
