import dictionariesStatic from "~/store/dictionaries/static";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import TableFilterSession from "~/utils/tableFilterSession";
import { TNotifactionsProps } from "~/components/Notifications/Notifications";

export const useNotification = ({ count, lasts, history, fetchNotifications, fetchCount, fetchLasts }: TNotifactionsProps) => {

    const NOTIFICATIONS_FILTERS = dictionariesStatic.NOTIFICATION_NAME

    const [notificationCount, setNotificationCount] = useState<number>(count)
    const [showMessagesPopUp, setShowMessagesPopUp] = useState<boolean>(false)
    const [lastsNotificationFilter, setLastsNotificationFilter] = useState<string>('')

    const togglePopUp = (): void => {
        getCount()
        setShowMessagesPopUp(false)
        getLastsNotifications(false)
    }

    const getCount = useCallback((): void => {
        fetchCount()
        setNotificationCount(count)
    }, [count, fetchCount, setNotificationCount]);

    const getLastsNotifications = useCallback((filterParam: boolean): void => {
        fetchLasts({
            page: 0,
            size: 3,
            sort: 'time',
            filters: filterParam ? [{
                name: 'name',
                value: lastsNotificationFilter,
                key: lastsNotificationFilter
            }] : [],
            lasts: true
        })
    }, [lastsNotificationFilter, fetchLasts]);

    const changeGroup = (event: SyntheticEvent, data: any): void => {
        setLastsNotificationFilter(data.value)
    }

    useEffect(() => {
        getCount()
        const interval = setInterval(() => {
            getCount()
        }, 30000)
        return () => clearInterval(interval)
    }, [count, lasts, getCount])

    useEffect(() => {
        const shouldFilter = !!lastsNotificationFilter
        getLastsNotifications(shouldFilter)
    }, [lastsNotificationFilter, getLastsNotifications])

    useEffect(() => {
        setLastsNotificationFilter('')
        NOTIFICATIONS_FILTERS.unshift({ value: '', key: 'name', text: 'Najnowsze' })
    }, [NOTIFICATIONS_FILTERS])

    const seeAllMessages = (): void => {
        setShowMessagesPopUp(true)
        const filter = NOTIFICATIONS_FILTERS.find(dict => dict.value === lastsNotificationFilter)
        const text = filter ? filter.text : ''
        history.push('/notifications', {
            notifications: {
                name: 'name',
                value: lastsNotificationFilter,
                text,
                key: lastsNotificationFilter
            }
        });
        if (filter && filter.value !== '') {
            TableFilterSession.setFilters({
                name: 'name',
                value: lastsNotificationFilter,
                text,
                key: lastsNotificationFilter,
                table: 'notifications'
            })
            fetchNotifications({
                page: 1,
                size: 15,
                sort: 'time',
                filters: [{ name: 'name', value: lastsNotificationFilter, key: lastsNotificationFilter, text }]
            })
        } else {
            TableFilterSession.setFilters({
                name: 'type',
                value: 'ERROR',
                text: 'Błąd',
                key: 'ERROR',
                table: 'notifications'
            })
            fetchNotifications({ 
                page: 1, 
                size: 15, 
                sort: 'time', 
                filters: [{ name: 'type', value: 'ERROR', key: 'ERROR', text: 'Błąd' }]
            })
        }
    }
    return {
        NOTIFICATIONS_FILTERS,
        notificationCount,
        showMessagesPopUp,
        lastsNotificationFilter,
        setLastsNotificationFilter,
        togglePopUp,
        changeGroup,
        seeAllMessages
    };
}