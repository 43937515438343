import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { Customer, CustomersActionTypes } from '~/store/customer/types'
import { BackordersActionTypes, BackordersState, PaginableBackorder, PaginableBackorderLine } from './types'

const nonNullStr = (v: any) => {
    return (v && v !== 'null') ? v : ''
}

export const initialBackorderState: BackordersState = {
    loadingBackorders: false,
    loadingBackorder: false,
    loadingLines: false,
    operationSuccess: false,
    list: [],
    searchList: [],
    filteredCustomers: [],
    filteredAddresses: [],
    created: false,
    creating: false,
    meta: {
        page: 1,
        pages: 1,
        size: 15,
        total: 0
    },
    selected: undefined,
    linesMeta: {
        page: 1,
        pages: 2,
        size: 15,
        total: 0
    },
    selectedLines: [],
    parsedLines: [],
    progress: 0,
    createdId: '',
    totalRecords: {
        total: 0,
        filtered: 0
    }
}

const reducer: Reducer<BackordersState> = (state = initialBackorderState, action): BackordersState => {
    switch (action.type) {
        case BackordersActionTypes.FETCH_REQUEST: {
            return { ...state, loadingBackorders: true, list: [] }
        }
        case BackordersActionTypes.FETCH_SUCCESS: {
            const payload = action.payload as PaginableBackorder
            return {
                ...state,
                loadingBackorders: false,
                list: payload.content,
                searchList: payload.content,
                meta: { page: payload.number + 1, pages: payload.totalPages, size: payload.size, total: payload.totalElements },
                totalRecords: { total: payload.totalElements, filtered: payload.filteredElements }
            }
        }
        case BackordersActionTypes.FETCH_SEARCH_SUCCESS: {
            return { ...state, searchList: action.payload.content }
        }
        case BackordersActionTypes.SELECT: {
            return { ...state, loadingBackorder: true }
        }
        case BackordersActionTypes.SELECTED: {
            return { ...state, loadingBackorder: false, selected: action.payload }
        }
        case BackordersActionTypes.CLEAR_SELECTED: {
            return { ...state, selected: undefined }
        }
        case BackordersActionTypes.ADD_BACKORDER: {
            return { ...state, created: false, creating: true }
        }
        case BackordersActionTypes.ADD_BACKORDER_SUCCESS: {
            return { ...state, created: true, creating: false, createdId: action.payload }
        }
        case BackordersActionTypes.CLEAR_CREATED: {
            return { ...state, created: false, creating: false, createdId: '' }
        }
        case CustomersActionTypes.FETCH_SUCCESS: {
            return {
                ...state, loadingBackorder: false, filteredCustomers: action.payload.content.map((c: Customer) => {
                    return {
                        key: c.rrdi,
                        title: `${c.informationAddressCompany} ${nonNullStr(c.informationContactSurname)} ${nonNullStr(c.informationContactName)}`,
                        description: `${c.informationAddressStreet},
                        ${c.informationAddressNumber}, ${c.informationAddressZipcode},
                        ${c.informationAddressCity}, ${c.informationAddressCountry}`,
                        price: c.rrdi
                    }
                })
            }
        }
        case CustomersActionTypes.SELECTED: {
            return {
                ...state, loadingBackorder: false, filteredAddresses: action.payload.deliveryAddresses.map((deliveryAddress: any) => ({
                    key: deliveryAddress.rrdi,
                    text: `${deliveryAddress.rrdi} ${deliveryAddress.company}, ${deliveryAddress.city}`,
                    value: deliveryAddress.rrdi
                }))
            }
        }
        case BackordersActionTypes.FETCH_LINES:
        case BackordersActionTypes.ADD_LINE:
        case BackordersActionTypes.UPDATE_LINE:
        case BackordersActionTypes.DELETE_LINE:
        case BackordersActionTypes.LOAD_XLS:
        case BackordersActionTypes.LOAD_CSV:
            return { ...state, loadingLines: true, operationSuccess: false }
        case BackordersActionTypes.LOAD_LINES_LOADED:
            return { ...state, loadingLines: false, progress: 0, parsedLines: action.payload }
        case BackordersActionTypes.IMPORT_LINES:
            return { ...state, loadingLines: true, operationSuccess: false }
        case BackordersActionTypes.UPDATE_PROGRESS:
            return { ...state, progress: action.payload }
        case BackordersActionTypes.FETCH_LINES_SUCCESS: {
            const payload = action.payload as PaginableBackorderLine
            return {
                ...state,
                loadingLines: false,
                selectedLines: payload.content,
                linesMeta: { page: payload.number + 1, pages: payload.totalPages, size: payload.size, total: payload.totalElements }
            }
        }
        case BackordersActionTypes.LINES_OPERATION_FINISHED: {
            return { ...state, loadingLines: false, operationSuccess: action.payload }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, creating: false, loadingBackorder: false, loadingBackorders: false }
        }
        default: {
            return state
        }
    }
}

export { reducer as backordersReducer }
