import { DATE, MAX, REQUIRED } from "~/components/SmartField/lib/validator";
import { Form } from "semantic-ui-react";
import React from "react";
import { getRequired } from "~/pages/Administration/lib/Dictionaries/utils";
import { renderInput } from "~/pages/Administration/lib/Dictionaries/renderInput";
import {
  FieldNameEnum,
  InputWrapperProps,
} from "~/pages/Administration/lib/Dictionaries/constants";
import { DictionaryName } from "~/store/dictionaries/types";

export const InputWrapper = ({
  name,
  type,
  dictionaryName,
}: InputWrapperProps) => {
  let validators = [
    MAX(
      (name === FieldNameEnum.defaultFooter &&
        dictionaryName === DictionaryName.paymentMethod) ||
        (name === FieldNameEnum.footer &&
          dictionaryName === DictionaryName.paymentFactor) ||
        (dictionaryName === DictionaryName.contact &&
          name === FieldNameEnum.description)
        ? 3500
        : name === FieldNameEnum.formula
          ? 200
          : 100
    ),
  ];

  if (getRequired(name)) {
    validators.push(REQUIRED);
  }

  if (type.toUpperCase() === "DATE") {
    validators.push(DATE);
  }

  return (
    <Form.Group widths="equal" key={name}>
      {renderInput(type, name, validators)}
    </Form.Group>
  );
};
