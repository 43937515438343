import { Checkbox, List, ListItem } from "semantic-ui-react";

import React, { useCallback } from "react";
import { RoleListProps } from "~/pages/Administration/lib/MenageUserRole/RoleList/constants";
import {
  ExtendedRole,
  VIEW,
} from "~/pages/Administration/lib/MenageUserRole/constants";
import { AVAILABLE_ROLE_ENUM, AvailableRole } from "~/store/users/types";

const RoleList: React.FC<RoleListProps> = ({
  change,
  roles,
  groupName,
  mainRoleIsChecked,
  extendedRoleGroups,
}) => {
  const checkIsAvailableRole = useCallback(
    (role: ExtendedRole) => {
      if (role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_PROCESS) {
        const transportRole = extendedRoleGroups[
          AVAILABLE_ROLE_ENUM.LDC_CLAIMS
        ]?.find(
          (role: AvailableRole) =>
            role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_TRANSPORT_PROCESS
        );

        const warehouseRole = extendedRoleGroups[
          AVAILABLE_ROLE_ENUM.LDC_CLAIMS
        ]?.find(
          (role: AvailableRole) =>
            role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_WAREHOUSE_PROCESS
        );

        return transportRole?.isSelected || warehouseRole?.isSelected;
      }

      if (
        role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_TRANSPORT_PROCESS ||
        role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_WAREHOUSE_PROCESS
      ) {
        const bokRole = extendedRoleGroups[
          AVAILABLE_ROLE_ENUM.LDC_CLAIMS
        ]?.find(
          (role: AvailableRole) =>
            role.name === AVAILABLE_ROLE_ENUM.LDC_CLAIMS_PROCESS
        );

        return bokRole?.isSelected;
      }

      if (role.name === AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_MANAGEMENT) {
        const foundRole = extendedRoleGroups[
          AVAILABLE_ROLE_ENUM.LDC_CUSTOMER
        ]?.find(
          (role: AvailableRole) =>
            AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_EDIT === role.name
        );

        return !foundRole?.isSelected;
      }

      return false;
    },
    [extendedRoleGroups]
  );

  return (
    <List relaxed>
      {roles
        .filter((item) => !item.name.includes(VIEW))
        .map((role) => (
          <ListItem key={`${groupName}-${role.id}`}>
            <Checkbox
              disabled={checkIsAvailableRole(role) || !mainRoleIsChecked}
              size="small"
              label={{ children: role.description }}
              checked={role.isSelected}
              onChange={(e, d) => change(e, d, [role])}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default RoleList;
