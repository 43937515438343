import { Table } from 'semantic-ui-react';
import { parseNumber } from '~/utils/parseNumber';
import React, { useCallback, useEffect, useMemo } from 'react';
import { BonusTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/BonusTableCell/constants';
import { ORDER_TYPE_ENUM, OrderLine } from '~/store/orders/types';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import '../../../../styles.scss';
import { getExceededLimitToast } from '~/components/ExceededLimitToast';

export const BonusTableCell = ({ orderType, column, line, limit, editMode }: BonusTableCellProps) => {
  const { renderNumber } = useRenderingFunctions(line, editMode);

  const isBonusOrSpecialOrder = useMemo(
    () => orderType === ORDER_TYPE_ENUM.SPECIAL || orderType === ORDER_TYPE_ENUM.BONUS,
    [orderType]
  );

  const checkIsBiggerThanLimit = useCallback((line: OrderLine) => (line?.bonus ? limit < line.bonus : false), [limit]);

  const checkIsOrangeCell = useCallback(
    (line: OrderLine) => isBonusOrSpecialOrder && checkIsBiggerThanLimit(line),
    [checkIsBiggerThanLimit, isBonusOrSpecialOrder]
  );
  const isOrangeCell = useMemo(() => checkIsOrangeCell(line), [checkIsOrangeCell, line]);

  useEffect(() => {
    if (isOrangeCell) {
      getExceededLimitToast();
    }
  }, [isOrangeCell]);

  return (
    <Table.Cell key={column.name} style={{ textAlign: 'right' }} className={isOrangeCell ? 'orangeTableCell' : ''}>
      {orderType === ORDER_TYPE_ENUM.BONUS || orderType === ORDER_TYPE_ENUM.BONUS_PLUS
        ? renderNumber('bonus', {
            style: {
              width: 95,
              textAlign: 'right',
            },
            precision: 2,
          })
        : parseNumber(line[column.name]) + ' %'}
    </Table.Cell>
  );
};
