import React, {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { Icon, Image, Menu, FormFieldProps, Popup } from "semantic-ui-react";
import { fetchVersion } from "~/store/version/actions";
import { AppContext } from "~/context/AuthContext";
import { ApplicationState } from "~/store";
import LogoImage from "~/assets/images/distrigo.png";
import "./header.scss";
import { PsaHeaderProps } from "./lib/types";
import { MenuUserProfile } from "~/components/PsaHeader/MenuUserProfile";
import { MenuSearchField } from "~/components/PsaHeader/MenuSearchField";
import { getSearchOptions } from "~/components/PsaHeader/lib/utils";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";

const PsaHeader: React.FC<PsaHeaderProps> = ({
  version,
  loading,
  fetchVersion,
}) => {
  const context = useContext(AppContext);

  const [user, setUser] = useState<any>();
  const [selectValue, setSelectValue] = useState<any>(
    getSearchOptions(context)?.[0]?.key
  );
  const [isPreprodEnv] = useState<boolean>(
    window.location.href.includes("ldc-preprod.distrigo")
  );

  const clearSessionFilters = useCallback((): void => {
    localStorage.removeItem("tableFilterSession");
    localStorage.removeItem("tableSortSession");
  }, []);

  useEffect(() => {
    if (context && context.keycloak !== null) {
      context.keycloak.loadUserInfo().then((userInfo: any) => {
        clearSessionFilters();
        setUser({
          name: userInfo.name,
          email: userInfo.email,
          id: userInfo.sub,
        });
      });
    }

    fetchVersion();
  }, [context, fetchVersion, clearSessionFilters]);

  const handleMenuVisibilityChange = useCallback((): void => {
    if (!context) return;
    context.toggleSidebar();
  }, [context]);

  const handleSelectChange = useCallback((e: any, el: FormFieldProps): void => {
    setSelectValue(el.value);
  }, []);

  const getVersion = useCallback((): React.ReactNode => {
    return createElement(
      "p",
      {},
      loading ? "Ładowanie wersji..." : `Wersja ${version}`
    );
  }, [loading, version]);

  const getEnvironment = useCallback((): React.ReactNode => {
    const url = window.location.href;
    let name = "Środowisko ";

    if (url.includes("ldc-preprod.distrigo")) {
      name += "PREPROD";
    } else if (url.includes("10.84.1.6")) {
      name += "DEV";
    } else if (url.includes("10.84.3.106")) {
      name += "QA";
    } else if (url.includes("ldc.distrigo")) {
      name += "PROD";
    } else {
      name += "---";
    }
    return createElement("p", {}, name);
  }, []);

  const createVersionPopup = useCallback((): React.ReactNode => {
    return createElement("div", {}, getVersion(), getEnvironment());
  }, [getEnvironment, getVersion]);

  const handleLogout = useCallback((): void => {
    clearSessionFilters();
    context && context.keycloak && context.keycloak.logout();
  }, [clearSessionFilters, context]);

  const { isClientPanelUser } = useClientPanelRoles();

  return (
    <header>
      <Menu
        secondary
        inverted
        style={{
          backgroundColor: "rgba(77, 77, 77, 1)",
          height: 50,
        }}
      >
        <Menu.Item name="menu" onClick={handleMenuVisibilityChange}>
          <Icon inverted name="bars" color="grey" size="large" />
        </Menu.Item>
        <Popup
          children={createVersionPopup()}
          trigger={<Image className="logo" src={LogoImage} />}
        />
        {!isClientPanelUser ? (
          <MenuSearchField
            selectValue={selectValue}
            onChange={handleSelectChange}
          />
        ) : (
          <h5 className="customer-panel-info">Panel Klienta</h5>
        )}
        {isPreprodEnv && (
          <h3 style={{ margin: "auto", color: "#fa4f4f", fontSize: 30 }}>
            PREPROD
          </h3>
        )}
        <MenuUserProfile
          trigger={user ? user.name : ""}
          context={context}
          onClick={() => handleLogout()}
          isClientPanelUser={isClientPanelUser}
        />
      </Menu>
    </header>
  );
};

const mapStateToProps = ({ version }: ApplicationState) => ({
  version: version.version,
  loading: version.loading,
});

const mapDispatchToProps = {
  fetchVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(PsaHeader);
