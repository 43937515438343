import { BaseEditor, Descendant } from "slate";
import { ReactEditor } from "slate-react";

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
};

export const LIST_TYPES = ["numbered-list", "bulleted-list"];
export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export interface ToolbarProps {
  editor: BaseEditor & ReactEditor;
  addText: (text: any) => void;
}

export interface ITextEditorProps {
  addText: (text: any) => void;
  text?: Descendant[];
}
