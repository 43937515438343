import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchInvoicesCorrections,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
  retrySent,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { IInvoice } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import AddInvoiceCorrection from "../../lib/AddInvoiceCorrection/AddInvoiceCorrection";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { useCorrectionsColumns } from "./useCorrectionsColumns";
import { CorrectionsDropdown } from "./CorrectionsDropdown";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useCorrectionsAdvancedSearch } from "./useCorrectionsAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  openAddCorrectionModal: boolean;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
  setOpenAddCorrectionModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type TReduxState = {
  corrections: IInvoice[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
  selectedTypeOfDataToExport?: string;
};

interface TReduxActions {
  fetchInvoicesCorrections: typeof fetchInvoicesCorrections;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  retrySent: typeof retrySent;
}

type CorrectionsProps = TComponentProps & TReduxState & TReduxActions;

const Corrections: React.FC<CorrectionsProps> = ({
  fetchInvoicesCorrections,
  getInvoicePdf,
  corrections,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport,
  openAddCorrectionModal,
  setOpenAddCorrectionModal,
  setDisableExportButton,
  setPercenExportButton,
  retrySent,
}) => {
  const { columnsConfig, columnsCorrectionsWithInvoiceLine } =
    useCorrectionsColumns();
  const [tableKey] = useState<string>("accounting/corrections");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("invoiceDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [columnsWithInvoiceLine, setColumnsWithInvoiceLine] = useState<
    TColumn[]
  >(columnsCorrectionsWithInvoiceLine);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport,
    columns,
    columnsWithInvoiceLine,
    fileName: "Korekty_faktur",
  });

  const getCorrections = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInvoicesCorrections(Object.assign(paramsObj, params));
    },
    [
      fetchInvoicesCorrections,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );
  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
    setColumnsWithInvoiceLine((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getCorrections({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getCorrections]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  const handleCreateSuccess = () => {
    getCorrections({ ...params });
    setOpenAddCorrectionModal(false);
  };

  const { formFields, handleSubmit } = useCorrectionsAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getCorrections,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getCorrections}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {corrections.map((document: IInvoice, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <CorrectionsDropdown
                document={document}
                getInvoice={getInvoicePdf}
                retrySent={retrySent}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
      <AddInvoiceCorrection
        triggerOpen={openAddCorrectionModal}
        isDebitNote={false}
        createdSuccess={handleCreateSuccess}
        setOpenAddCorrectionModal={setOpenAddCorrectionModal}
      />
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  corrections: invoices.corrections.list,
  loading: invoices.corrections.loading,
  totalRecords: invoices.corrections.totalRecords,
  meta: invoices.corrections.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchInvoicesCorrections,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
  retrySent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Corrections);
