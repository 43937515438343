import { isManualStatus } from '~/pages/Orders/Details/utils';
import { Divider, Dropdown } from 'semantic-ui-react';
import React, { useContext, useMemo } from 'react';
import OrderStatus from '~/pages/Orders/Details/lib/OrderStatus';
import { ApplicationState } from '~/store';
import { OrderChangeStatusDropdownProps, TOrderStatusReduxState } from '~/pages/Orders/Details/lib/constants';
import { connect } from 'react-redux';
import { OrderStatusEnum } from '~/store/orders/types';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';
import { AppContext } from '~/context/AuthContext';

const OrderChangeStatusDropdown = ({
  orderIn,
  order,
  setModalOpen,
  setStatusToChange,
}: OrderChangeStatusDropdownProps) => {
  const appContext = useContext(AppContext);

  const userHaveLdcUiOrderAcceptOrRejectRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_ACCEPT_REJECT),
    [appContext]
  );

  const getOrderStatuses = (): JSX.Element => (
    <OrderStatus setModalOpen={setModalOpen} setStatusToChange={setStatusToChange} />
  );

  return (
    <>
      {userHaveLdcUiOrderAcceptOrRejectRole &&
        isManualStatus(orderIn) &&
        order &&
        order.status === OrderStatusEnum.ACCEPTED &&
        order.cancelable && (
          <>
            <Dropdown trigger={'Zmiana statusu zamówienia'} button simple basic>
              <Dropdown.Menu>{getOrderStatuses()}</Dropdown.Menu>
            </Dropdown>
            <Divider />
          </>
        )}
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TOrderStatusReduxState = ({ orders }: ApplicationState) => ({
  orderIn: orders.selected,
});

export default connect(mapStateToProps)(OrderChangeStatusDropdown);
