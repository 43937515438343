import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { BREADCRUMB_ID, BREADCRUMB_ID_CLIENT_PANEL } from "../constants";
import { useAdministrationRights } from "./useAdministrationRights";

type Props = {
  setActiveTab: (
    value: React.SetStateAction<BREADCRUMB_ID | BREADCRUMB_ID_CLIENT_PANEL>
  ) => void;
};

export const useAdministrationBreadcrubms = ({ setActiveTab }: Props) => {
  const {
    userHaveLdcUiAdministrationViewRole,
    userHaveLdcUiAdministrationSpeederRole,
    userHaveLdcUiAdministrationImportFactorLimitRole,
    userHaveCustomerPanelUiAdvertisingViewRole,
  } = useAdministrationRights();

  const { isClientPanelUser, isSuperAdmin } = useClientPanelRoles();

  const users = {
    id: BREADCRUMB_ID.users,
    text: "Użytkownicy",
    clickAction: () => setActiveTab(BREADCRUMB_ID.users),
    visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
  };
  const dictionaries = {
    id: BREADCRUMB_ID.dict,
    text: "Słowniki",
    clickAction: () => setActiveTab(BREADCRUMB_ID.dict),
    visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
  };
  const speeder = {
    id: BREADCRUMB_ID.speeder,
    text: "Speeder",
    clickAction: () => setActiveTab(BREADCRUMB_ID.speeder),
    visible: !isClientPanelUser && userHaveLdcUiAdministrationSpeederRole,
  };
  const factorLimitTransport = {
    id: BREADCRUMB_ID.import,
    text: "Import limitów faktora",
    clickAction: () => setActiveTab(BREADCRUMB_ID.import),
    visible:
      !isClientPanelUser && userHaveLdcUiAdministrationImportFactorLimitRole,
  };
  const hazardousGoods = {
    id: BREADCRUMB_ID.hazardousGoods,
    text: "Części niebezpieczne",
    clickAction: () => setActiveTab(BREADCRUMB_ID.hazardousGoods),
    visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
  };
  const usersClientPanel = {
    id: BREADCRUMB_ID_CLIENT_PANEL.usersClientPanel,
    text: "Użytkownicy Panelu Klienta",
    clickAction: () =>
      setActiveTab(BREADCRUMB_ID_CLIENT_PANEL.usersClientPanel),
    visible: isClientPanelUser,
  };
  const announcements = {
    id: BREADCRUMB_ID_CLIENT_PANEL.announcements,
    text: "Ogłoszenia",
    clickAction: () => setActiveTab(BREADCRUMB_ID_CLIENT_PANEL.announcements),
    visible:
      isClientPanelUser &&
      isSuperAdmin &&
      userHaveCustomerPanelUiAdvertisingViewRole,
  };
  return [
    users,
    dictionaries,
    speeder,
    factorLimitTransport,
    hazardousGoods,
    announcements,
    usersClientPanel,
  ];
};
