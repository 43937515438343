import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Store } from "redux";
import { AppContext } from "~/context/AuthContext";
import { ApplicationState } from "~/store";
import { keycloakInstance } from "~/utils/keycloak";

import React, { useEffect, useState } from "react";
import translations from "~/utils/translations";
import messages_en from "~/translations/en.json";
import messages_pl from "~/translations/pl.json";
import InitialLoader from "~/components/Loaders/InitialLoader";
import ReduxToastr from "react-redux-toastr";
import Keycloak from "keycloak-js";

import "./App.scss";
import Routes from "./routes";

export interface IMessages {
  [key: string]: {};
}

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

export const messages: IMessages = {
  pl: messages_pl,
  en: messages_en,
};

const Main: React.FC<MainProps> = ({ store, history }): JSX.Element => {
  const [lang, setLang] = useState(
    navigator.language.toLowerCase().split(/[_-]+/)[0]
  );
  const [menuVisibility, setMenuVisibility] = useState(true);
  const [isClientPanelUser, setIsClientPanelUser] = useState<boolean>();
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const [authError, setAuthError] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  const changeLanguage = (): void => {
    setLang("pl");
  };

  const toggleSidebar = (): void => {
    setMenuVisibility(!menuVisibility);
  };

  const changeKeycloak = (): void => {
    if (keycloakInstance.sessionId) return;
    keycloakInstance
      .init({ onLoad: "login-required", checkLoginIframe: false })
      .then((authenticated) => {
        const data = async () => await keycloakInstance.loadUserInfo();
        data().then((value: any) => {
          setUserInfo(value);
          setKeycloak(keycloakInstance);
          setAuthError(!authenticated);
          setIsClientPanelUser(value.origin === "customer-panel");
        });
      })
      .catch(() => {
        setAuthError(true);
      });
  };

  useEffect(() => {
    changeKeycloak();
  }, []);

  useEffect(() => {
    translations.set(
      new IntlProvider({ locale: "en", messages: messages["pl"] })
    );
  }, [lang]);

  if (keycloak && !authError && typeof isClientPanelUser === "boolean") {
    return (
      <AppContext.Provider
        value={{
          lang: lang,
          keycloak: keycloak,
          authenticated: true,
          changeLanguage: changeLanguage,
          changeKeycloak: changeKeycloak,
          toggleSidebar: toggleSidebar,
          menuVisibility: menuVisibility,
          userInfo: userInfo,
          clientPanelUser: isClientPanelUser,
        }}
      >
        <Provider store={store}>
          <ReduxToastr
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            position="bottom-right"
            closeOnToastrClick
            preventDuplicates
            progressBar
          />
          <ConnectedRouter history={history}>
            <IntlProvider locale="en" messages={messages["pl"]}>
              <Routes />
            </IntlProvider>
          </ConnectedRouter>
        </Provider>
      </AppContext.Provider>
    );
  }

  if (authError) {
    return <InitialLoader error />;
  }

  return <InitialLoader />;
};

export default Main;
