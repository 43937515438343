import React from "react";
import { useCustomersRolesRights } from "./useCustomersRights";

type Props = {
  exportPercent: number;
  disableButton: boolean;
  setOpenUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddCustomer: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDownloadModal: () => Promise<void>;
};

export const useCustomersButtons = (props: Props) => {
  const { userHaveLdcUiCustomerEditRole } = useCustomersRolesRights();

  const addButton = {
    icon: "user plus",
    content: "Dodaj",
    onClick: () => props.setOpenAddCustomer(true),
    primary: true,
    visible: userHaveLdcUiCustomerEditRole,
  };

  const downloadButton = {
    icon: "download",
    content: "Eksport kontrahenta",
    primary: true,
    onClick: () => props.handleOpenDownloadModal(),
    window: props.exportPercent,
    disabled: props.disableButton,
  };

  const uploadButton = {
    icon: "upload",
    content: "Import z pliku",
    primary: true,
    onClick: () => props.setOpenUploadModal(true),
  };

  return { downloadButton, addButton, uploadButton };
};
