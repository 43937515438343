import React, { Fragment } from 'react'
import { Popup, StrictPopupProps } from "semantic-ui-react"

type TProps = {
    description?: string | JSX.Element
    verticalOffset?: number
} & StrictPopupProps

/**
 * A popup that doesn't render at all if its content is empty.
 */
export const SmartPopup: React.FC<TProps> = (props) => {
    return (
        props.description ? (
            <Popup
                trigger={props.children}
                position="bottom center"
                size="tiny"
                {...props}
            >
                {props.description}
            </Popup>
        ) : (
            <Fragment>
                {props.children}
            </Fragment>
        )
    )
}
