import React, { useCallback, useMemo } from "react";
import { Icon, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import BackordersList from "~/pages/Backorders/List";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { Backorder } from "~/store/backorder/types";
import { DictionaryItem } from "~/store/dictionaries/types";

export const useBackordersTable = (orders: Backorder[]) => {
  const { getTextCell, getDateCell, getDictionaryCell } = useCellsRenderer();

  const backorderTypeDictionary: DictionaryItem[] = useMemo(
    () => [
      { key: "URGENT", value: "URGENT", text: "Pilne" },
      { key: "STOCK", value: "STOCK", text: "Stock" },
    ],
    []
  );

  const backorderMethodsDictionary: DictionaryItem[] = useMemo(
    () => [
      { key: "CROSS_DOCK", value: "CROSS_DOCK", text: "Cross-dock do klienta" },
      {
        key: "REPLENISHMENT",
        value: "REPLENISHMENT",
        text: "Uzupełnienie stocku",
      },
    ],
    []
  );

  const getMethodCell = useCallback((method: string): JSX.Element => {
    switch (method) {
      case "CROSS_DOCK":
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="map signs" size="large" color="grey"></Icon>
          </Table.Cell>
        );
      case "REPLENISHMENT":
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="cubes" size="large" color="grey"></Icon>
          </Table.Cell>
        );
      default:
        return (
          <Table.Cell key={method + Math.random()}>
            <Icon name="question circle" size="large" color="grey"></Icon>
          </Table.Cell>
        );
    }
  }, []);

  const columns: TColumn[] = useMemo(
    () => [
      {
        name: "backorderNumber",
        label: "Numer",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "creationTime",
        label: "Utworzone",
        projection: true,
        getCell: (d, c) => getDateCell(d, c, true),
      },
      {
        name: "customer.rrdi",
        label: "Zamawiający",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "deliveryCustomer.rrdi",
        label: "Odbiorca",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "type",
        dictionary: backorderTypeDictionary,
        label: "Dostawa",
        projection: true,
        noKey: true,
        getCell: (d, c) => getDictionaryCell(d, c),
      },
      { name: "priority", label: "Priorytet", projection: true },
      {
        name: "method",
        dictionary: backorderMethodsDictionary,
        label: "Typ",
        projection: true,
        getCell: (d, c) => getMethodCell(d.method),
      },
    ],
    [
      getTextCell,
      backorderMethodsDictionary,
      backorderTypeDictionary,
      getMethodCell,
      getDateCell,
      getDictionaryCell,
    ]
  );

  const renderBackordersTable = () => (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {columns.map((column) => (
            <Table.HeaderCell key={column.name}>
              {column.label}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <BackordersList backorders={orders} columns={columns} />
      </Table.Body>
    </Table>
  );

  return { renderBackordersTable };
};
