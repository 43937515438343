import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { AnomaliesActionTypes, AnomaliesState, PaginableAnomaly, Anomaly } from "./types"

export const initialAnomaliesState: AnomaliesState = {
    loadingAnomalies: false,
    loadingAnomaly: false,
    list: [],
    meta: {
        page: 1,
        size: 15,
        pages: 1
    },
    selected: undefined,
    totalRecords: {
        total: 0,
        filtered: 0
    },
    loadingDestoryedParts: false
}

const reducer: Reducer<AnomaliesState> = (state = initialAnomaliesState, action): AnomaliesState => {
    switch (action.type) {
        case AnomaliesActionTypes.FETCH_REQUEST: {
            return { ...state, loadingAnomalies: true, list: [] }
        }
        case AnomaliesActionTypes.FETCH_SUCCESS: {
            const payload = action.payload as PaginableAnomaly
            return {
                ...state,
                loadingAnomalies: false,
                list: payload.content,
                meta: { page: payload.number + 1, pages: payload.totalPages, size: payload.size },
                totalRecords: { total: payload.totalElements, filtered: payload.filteredElements }
            }
        }
        case AnomaliesActionTypes.SELECT_ANOMALY: {
            return { ...state, loadingAnomaly: true }
        }
        case AnomaliesActionTypes.SELECTED: {
            return { ...state, loadingAnomaly: false, selected: action.payload }
        }
        case AnomaliesActionTypes.CLOSE_ANOMALY:
        case AnomaliesActionTypes.RESOLVE_ANOMALY: {
            return { ...state, loadingAnomaly: true }
        }
        case AnomaliesActionTypes.CLOSE_RESOLVE_SUCCESS: {
            return { ...state, loadingAnomaly: false }
        }
        case AnomaliesActionTypes.RESOLVE_DESTROYED_PARTS: {
            return { ...state, loadingDestoryedParts: false }
        }
        case AnomaliesActionTypes.RESOLVE_DESTROYED_PARTS_SUCCESS:
        case AnomaliesActionTypes.REJECT_RW_SUCCESS: {
            return { ...state, loadingDestoryedParts: true, selected: {
                ...state.selected as Anomaly,
                anomalyStatus: 'RESOLVED',
                affectedParts: {
                        partsExpected: [],
                        partsReceived: [],
                        missingParts: []
                    }
                } 
            }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loadingAnomalies: false, loadingAnomaly: false }
        }
    }
    return state
}

export { reducer as anomaliesReducer }
