import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';

import { DetailCardLinesProps } from '~/pages/Orders/Details/lib/constants';
import OrderLines from '~/pages/Orders/Details/lib/Lines';
import { OrderStatusEnum } from '~/store/orders/types';

const DetailCardLines = ({ editMode, order, isStatus }: DetailCardLinesProps) => (
  <DetailCard title="app.order.lines" id="orderLines" key="orderLines" width={16} minHeight={100}>
    <OrderLines
      limit={order?.bonusSpecialOrderDiscountLimit}
      editMode={
        editMode &&
        isStatus(
          order.status,
          OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
          OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
        )
      }
      orderType={order.type}
      orderStatus={order.status}
      totalValue={order.priceGrossTotal}
      creditLimit={order.customer.paymentCreditLimit}
      editModeOrigin={
        (order && order.status === OrderStatusEnum.COMPLETED) || (order && order.status === OrderStatusEnum.ACCEPTED)
      }
    />
  </DetailCard>
);

export default DetailCardLines;
