import { Reducer } from "redux";
import {
  NotificationActionTypes,
  NotificationsState,
  PaginableNotification,
} from "./types";

export const initialNotificationState: NotificationsState = {
  loadingNotification: false,
  loadingNotifications: false,
  loadingLasts: false,
  list: [],
  meta: {
    page: 1,
    size: 15,
    pages: 1,
  },
  lasts: [],
  loadingCount: false,
  count: 0,
  manualOrdersCount: 0,
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  uniqueNotification: { id: "", params: [] },
  id: "",
  params: [],
};

const reducer: Reducer<NotificationsState> = (
  state = initialNotificationState,
  action
): NotificationsState => {
  switch (action.type) {
    case NotificationActionTypes.FETCH_REQUEST: {
      return { ...state, loadingNotifications: true };
    }
    case NotificationActionTypes.FETCH_INDIVIDUAL_REQUEST: {
      return { ...state, loadingNotification: true };
    }
    case NotificationActionTypes.FETCH_SUCCESS: {
      const payload = action.payload as PaginableNotification;
      return {
        ...state,
        loadingNotifications: false,
        list: payload.content,
        meta: {
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case NotificationActionTypes.FETCH_INDIVIDUAL_REQUEST_SUCCESS: {
      return {
        ...state,
        loadingNotification: false,
        id: action.payload.id,
        uniqueNotification: action.payload,
        params: action.payload.params,
      };
    }
    case NotificationActionTypes.FETCH_LAST_REQUEST: {
      return {
        ...state,
        loadingLasts: true,
      };
    }
    case NotificationActionTypes.FETCH_LASTS_SUCCESS: {
      return {
        ...state,
        loadingLasts: false,
        lasts: action.payload.content,
      };
    }
    case NotificationActionTypes.FETCH_COUNT: {
      return { ...state, loadingCount: true };
    }
    case NotificationActionTypes.FETCH_COUNT_SUCCESS: {
      return {
        ...state,
        count: action.payload.count.count,
        loadingCount: false,
      };
    }
    case NotificationActionTypes.FETCH_MANUAL_ORDERS_COUNT_SUCCESS: {
      return { ...state, manualOrdersCount: action.payload.count };
    }
    case NotificationActionTypes.MAKE_READ: {
      const list = [...state.list].map((notification) => {
        return notification.id === action.payload
          ? (notification = { ...notification, read: true })
          : notification;
      });
      const lasts = [...state.lasts].map((notification) => {
        return notification.id === action.payload
          ? (notification = { ...notification, read: true })
          : notification;
      });
      return { ...state, list, lasts, count: state.count - 1 };
    }
    case NotificationActionTypes.MAKE_ALL_READ: {
      const toRead = action.payload as string[];
      const list =
        toRead.length > 0
          ? state.list.map((notification) => {
              const read = toRead.some((x) => x === notification.id);
              if (read) {
                return { ...notification, read: true };
              }
              return { ...notification };
            })
          : state.list.map((notification) => ({ ...notification, read: true }));
      return { ...state, list, count: 0 };
    }
  }
  return state;
};

export { reducer as notificationsReducer };
