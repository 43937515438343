import { SelectedTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/SelectedTableCell';
import { ReferenceIDTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/ReferenceIsTableCell';
import { CodesTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/CodesTableCell';
import { QuantityTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/QuantityTableCell';
import { NetPriceTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/NetPriceTableCell';
import { BonusTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/BonusTableCell';
import { PriceTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/PriceTableCell';
import { TaxTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/TaxTableCell';
import { RejectionReasonTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/RejectionReasonTableCell';
import { ConfirmedQuantityTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/ConfirmedQuantityTableCell';
import { SapInvoicesTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/SapInvoicesTableCell';
import { CrossDockTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/CrossDockTableCell';
import { InvoiceIdNumbersTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/InvoiceIdNumbersTableCell';
import { BackorderReplenishmentDocumentsTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/BackorderReplenishmentDocumentsTableCell';
import { DescriptionTableCell } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/DescriptionTableCell';
import TableCellPopup from '~/components/TableCellPopup/TableCellPopup';
import React from 'react';
import { LinesTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';
import { ManualOrdersTableCell } from './ManualOrdersTableCell';
import { Table } from 'semantic-ui-react';
import { PackageNumbersTableCell } from './PackageNumbersTableCell';

export const LinesTableCell = ({
  column,
  line,
  orderStatus,
  selectLine,
  loading,
  lines,
  editMode,
  addCodingHandler,
  orderType,
  limit,
  ldcOrderId,
  documentPreviewHandler,
  invoiceErpPreviewHandler,
  getInvoice,
}: LinesTableCellProps) => {
  switch (column.name) {
    case 'selected':
      return <SelectedTableCell orderStatus={orderStatus} selectLine={selectLine} column={column} line={line} />;
    case 'referenceID':
      return <ReferenceIDTableCell lines={lines} column={column} line={line} />;
    case 'codes':
      return (
        <CodesTableCell
          loading={loading}
          editMode={editMode}
          addCodingHandler={addCodingHandler}
          column={column}
          line={line}
        />
      );
    case 'quantity':
      return <QuantityTableCell editMode={editMode} column={column} line={line} />;
    case 'netPrice':
      return <NetPriceTableCell orderType={orderType} editMode={editMode} column={column} line={line} />;
    case 'bonus':
      return <BonusTableCell line={line} column={column} orderType={orderType} limit={limit} editMode={editMode} />;
    case 'netPriceListPrice':
    case 'totalNetPrice':
    case 'totalGrossPrice':
    case 'totalTaxValue':
    case 'pricePNR':
    case 'priceDRZ':
      return <PriceTableCell column={column} line={line} />;
    case 'taxValue':
      return <TaxTableCell column={column} line={line} />;
    case 'rejectionReasons':
      return <RejectionReasonTableCell column={column} line={line} />;
    case 'confirmedQuantity':
      return <ConfirmedQuantityTableCell column={column} line={line} documentPreviewHandler={documentPreviewHandler} />;
    case 'sapInvoices':
      return <SapInvoicesTableCell invoiceErpPreviewHandler={invoiceErpPreviewHandler} column={column} line={line} />;
    case 'orderedCrossDock':
    case 'confirmedCrossDock':
      return <CrossDockTableCell column={column} line={line} />;
    case 'invoiceIdNumbers':
      return <InvoiceIdNumbersTableCell column={column} getInvoice={getInvoice} line={line} />;
    case 'manualOrderDocument':
      return <ManualOrdersTableCell column={column} line={line} ldcOrderId={ldcOrderId} />;
    case 'backorderReplenishmentDocuments':
      return <BackorderReplenishmentDocumentsTableCell column={column} line={line} />;
    case 'packageNumbers':
      return <PackageNumbersTableCell column={column} line={line} />;
    case 'description':
      return <DescriptionTableCell column={column} line={line} />;
    case 'stockLevel':
      return <Table.Cell textAlign="center">{line.stockLevel || 0}</Table.Cell>;
  }

  return (
    <TableCellPopup
      key={column.name}
      style={{
        textAlign: column.name === 'rejectedQuantity' ? 'right' : 'center',
      }}
    >
      {line[column.name]}
    </TableCellPopup>
  );
};
