import { Link } from 'react-router-dom';
import React from 'react';
import { AnomaliesListProps } from '~/pages/Orders/Details/lib/constants';

const AnomaliesList = ({ order }: AnomaliesListProps) => (
  <>
    {order &&
      order.anomalies &&
      order.anomalies.map((anomaly) => {
        return (
          <p>
            <Link target="_blank" to={`/anomaly/${anomaly.anomalyID}`}>
              {anomaly.anomalyNo}
            </Link>
          </p>
        );
      })}
  </>
);

export default AnomaliesList;
