import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { callApi, callApiUploadFile, callApiWithRetry } from '~/utils/api'
import { handleError } from '~/store/error/actions'
import { ApplicationState } from '~/store/index'
import { addBackorder, addBackorderLine, addBackorderSuccess, backorderSelected, deleteBackorderLine, fetchBackorderLines, fetchBackorders, fetchLinesSuccess, fetchSearchSuccess, fetchSuccess, importBackorderLines, linesOperationFinished, loadLinesCsv, loadLinesExcel, loadLinesLoaded, selectBackorder, updateBackorderLine, updateProgress } from "./actions"
import { Backorder, BackorderLine, BackordersActionTypes, PaginableBackorder, PaginableBackorderLine, TBackorderImportLine } from './types'
import { PaginationMeta } from '~/store/types'

import translations from '~/utils/translations'
import flatten from 'flat'

function* getPaginationConfig() {
    const meta: PaginationMeta = yield select((state: ApplicationState) => state.backorders.linesMeta)
    const page = meta.page > (meta.pages ? meta.pages : 1) ? meta.pages : meta.page
    return { size: meta.size, page }
}

function* handleFetchBackorders(action: ReturnType<typeof fetchBackorders>) {
    try {
        let filters = ''
        let sort = ''
        action.payload.filters.forEach((filter: any) => {
            filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
        })
        if (action.payload.sortColumn && action.payload.sortDirection) {
            sort = `&sort=${action.payload.sortColumn.replace('.', '_')},${action.payload.sortDirection}`
        }
        const backorders:PaginableBackorder = yield call(callApi, 'get', `/stock/backorders?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`)
        const resSearchSuccess:PaginableBackorder = yield put(fetchSearchSuccess(backorders))
        const resSuccess:PaginableBackorder = yield put(fetchSuccess(backorders))
        return action.type.toString() === BackordersActionTypes.FETCH_SEARCH_REQUEST ? resSearchSuccess : resSuccess
    } catch (error) {
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.load")))
    }
}

function* handleSelectBackorder(action: ReturnType<typeof selectBackorder>) {
    try {
        const backorder:Backorder = yield call(callApiWithRetry, 3, 'get', `/stock/backorders/${action.payload}`)
        yield put(backorderSelected(backorder))
    } catch (error) {
        yield put(handleError(error as Error, true, translations.format("app.error.backorders.select-backorder")))
    }
}

function* handleAddBackorder(action: ReturnType<typeof addBackorder>) {
    try {
        const res:Backorder = yield call(callApi, 'POST', '/stock/backorders', { warehouseID: action.payload.warehouseId, supplier: action.payload.supplier })
        yield put(addBackorderSuccess(res.id))
    } catch (e) {
        const error = e as {message: string}
        if (error.message.includes('already exists')) {
            const orderNr = error.message.split(' ')[17]
            yield put(
                handleError(error as Error, false, "Nie można utworzyć zamówienia hurtowego.", translations.format("app.error.backorders.BACKORDER_CREATE_ERROR", {orderNr}))
                )  
        } else {
            yield put(handleError(error as Error, false, "Nie można utworzyć zamówienia hurtowego."))
        }


    }
}

function* handleFetchBackorderLines(action: ReturnType<typeof fetchBackorderLines>) {
    try {

        let filters = ''
        if(action.payload.filters){
            action.payload.filters.forEach((filter: any) => {
                filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
            })
        }

        const res:PaginableBackorderLine = yield call(callApi, 'get', `/stock/backorders/${action.payload.backorderId}/lines?page=${action.payload.params.page - 1}&size=${action.payload.params.size}${filters}`)
        yield put(fetchLinesSuccess(res))
    } catch (error) {
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.select-backorder")))
    }
}

function* handleAddBackorderLine(action: ReturnType<typeof addBackorderLine>) {
    try {
        yield call(callApi, 'post', `/stock/backorders/${action.payload.backorderId}/lines`, action.payload.line)
        yield put(linesOperationFinished(true))
        yield put(fetchBackorderLines(action.payload.backorderId, yield getPaginationConfig()))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.save")))
    }
}

function* handleUpdateBackorderLine(action: ReturnType<typeof updateBackorderLine>) {
    try {
        yield call(callApi, 'PATCH', `/stock/backorders/${action.payload.backorderId}/lines/${action.payload.line.number}`, flatten(action.payload.line))
        yield put(linesOperationFinished(true))
        yield put(fetchBackorderLines(action.payload.backorderId, yield getPaginationConfig()))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.save")))
    }
}

function* handleDeleteBackorderLine(action: ReturnType<typeof deleteBackorderLine>) {
    try {
        yield call(callApi, 'delete', `/stock/backorders/${action.payload.backorderId}/lines/${action.payload.line.number}`)
        yield put(linesOperationFinished(true))
        yield put(fetchBackorderLines(action.payload.backorderId, yield getPaginationConfig()))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.delete")))
    }
}

function* handleUploadLinesExcel(action: ReturnType<typeof loadLinesExcel>) {
    try {
        const parsedLines:BackorderLine[] = yield call(callApiUploadFile, `/stock/backorders/import`, action.payload)
        yield put(loadLinesLoaded(parsedLines))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.save")))
    }
}

function* handleUploadLinesCsv(action: ReturnType<typeof loadLinesCsv>) {
    try {
        const parsedLines:BackorderLine[] = yield call(callApi, 'post', `/stock/backorders/import/text`, { text: action.payload })
        yield put(loadLinesLoaded(parsedLines))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.save")))
    }
}

function* handleImportLines(action: ReturnType<typeof importBackorderLines>) {
    try {
        let processed = 0
        const lines: TBackorderImportLine[] = yield select((state: ApplicationState) => state.backorders.parsedLines)
        for (const line of lines) {
            try {
                if (line.orderPartType !== 'REPLACED' && !line.success) {
                    const { codes, error, ...lineToAdd } = line
                    yield call(callApi, 'post', `/stock/backorders/${action.payload}/lines`, lineToAdd)
                }
                processed++
                line.success = true
            } catch (error) {
                line.error = true
            }
            yield put(updateProgress(processed / lines.length * 100))
        }
        yield put(fetchBackorderLines(action.payload, yield getPaginationConfig()))
        yield put(linesOperationFinished(processed === lines.length))
    } catch (error) {
        yield put(linesOperationFinished(false))
        yield put(handleError(error as Error, false, translations.format("app.error.backorders.save")))
    }
}

function* watchFetchBackordersRequest() {
    yield takeEvery(BackordersActionTypes.FETCH_REQUEST, handleFetchBackorders)
}

function* watchFetchBackordersSearchRequest() {
    yield takeEvery(BackordersActionTypes.FETCH_SEARCH_REQUEST, handleFetchBackorders)
}

function* watchSelectBackorder() {
    yield takeEvery(BackordersActionTypes.SELECT, handleSelectBackorder)
}

function* watchFetchBackorderLines() {
    yield takeEvery(BackordersActionTypes.FETCH_LINES, handleFetchBackorderLines)
}
function* watchAddBackorderLine() {
    yield takeEvery(BackordersActionTypes.ADD_LINE, handleAddBackorderLine)
}
function* watchAddMultipleLines() {
    yield takeEvery(BackordersActionTypes.IMPORT_LINES, handleImportLines)
}
function* watchUpdateBackorderLine() {
    yield takeEvery(BackordersActionTypes.UPDATE_LINE, handleUpdateBackorderLine)
}
function* watchDeleteBackorderLine() {
    yield takeEvery(BackordersActionTypes.DELETE_LINE, handleDeleteBackorderLine)
}
function* watchUploadLinesCsv() {
    yield takeEvery(BackordersActionTypes.LOAD_CSV, handleUploadLinesCsv)
}
function* watchUploadLinesExcel() {
    yield takeEvery(BackordersActionTypes.LOAD_XLS, handleUploadLinesExcel)
}

function* watchAddBackorder() {
    yield takeEvery(BackordersActionTypes.ADD_BACKORDER, handleAddBackorder)
}

function* backordersSaga() {
    yield all([
        fork(watchFetchBackordersRequest),
        fork(watchSelectBackorder),
        fork(watchFetchBackorderLines),
        fork(watchAddBackorderLine),
        fork(watchAddMultipleLines),
        fork(watchUpdateBackorderLine),
        fork(watchDeleteBackorderLine),
        fork(watchUploadLinesCsv),
        fork(watchUploadLinesExcel),
        fork(watchFetchBackordersSearchRequest),
        fork(watchAddBackorder)
    ])
}

export default backordersSaga