import { Label, Menu } from 'semantic-ui-react'

import React, { useEffect, useState } from 'react'

export type TCard = "identification" | "prices" | "status" | "characteristic" | "others" |
    "limits" | "deposit" | "hierarchy" | "logistics" | "dimensions" | "replacement_solution" | "packing-dimensions" | "unit-dimensions" |
    "replacements" | "replacements_history" | "tires" | "adnotation" | "system" | "sent100" | "speeder" | "packingIsLargeSize"

type TCardsMapping = {
    main: TCard[]
    limits: TCard[]
    deposit: TCard[]
    hierarchy: TCard[]
    logistics: TCard[]
    replacements: TCard[]
    tires: TCard[]
    other: TCard[]
    all: TCard[]
}

const mapping: TCardsMapping = {
    main: ["identification", "prices", "status", "characteristic", "others"],
    limits: ["limits"],
    deposit: ["deposit"],
    hierarchy: ["hierarchy"],
    logistics: ["logistics", "packing-dimensions", "unit-dimensions", "sent100", "packingIsLargeSize"],
    replacements: ["replacement_solution", "replacements", "replacements_history"],
    tires: ["tires"],
    other: ["adnotation", "system", "speeder"],
    all: []
}
mapping.all = [
    ...mapping.main, ...mapping.limits, ...mapping.deposit, ...mapping.hierarchy,
    ...mapping.logistics, ...mapping.replacements, ...mapping.tires, ...mapping.other
]

type TTabName = keyof TCardsMapping

type TProps = {
    tabs: {
        name: TTabName
        title: JSX.Element | string
    }[]
    onTabChange: (cards: TCard[]) => void
    priceListType?: string
}

const TabSwitcher: React.FC<TProps> = ({
    tabs,
    onTabChange,
    priceListType
}) => {

    const [activeItem, setActiveItem] = useState<TTabName>("main")

    useEffect(() => {
        onTabChange(mapping[activeItem])
    }, [activeItem, onTabChange])

    const iconColor: { [key: string]: string } = {
        "PCD": "#C34711",
        "IAM": "#CC8700",
        "OV": "#4D4D4D"
    }

    const renderIcon = (): JSX.Element => {
        const color = iconColor[priceListType || ''] || 'black'
        return (
            <Label basic size='large' style={{ borderColor: color, color, backgroundColor: 'white', marginLeft: 0 }} >{priceListType}</Label>
        )
    }

    const renderTab = (name: TTabName, title: JSX.Element | string): JSX.Element => {
        const handleClick = () => {
            setActiveItem(name)
        }
        return (
            <Menu.Item
                name={name}
                key={name}
                active={activeItem === name}
                onClick={handleClick}>
                {title}
            </Menu.Item>
        )
    }

    const renderTabs = (): JSX.Element[] => {
        return tabs.map((tab) => {
            return renderTab(tab.name, tab.title)
        })
    }

    return (
        <Menu>
            <Menu.Item>
                {renderIcon()}
            </Menu.Item>
            {renderTabs()}
        </Menu>
    )

}

export { TabSwitcher }
