import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  callApi,
  callApiWithRetry,
  callApiUploadFile,
  callApiDownload,
} from "~/utils/api";
import { handleError } from "~/store/error/actions";
import {
  addSuccess,
  customerSelected,
  fetchCustomers,
  fetchDeliveryPoints,
  fetchDeliveryPointsSuccess,
  fetchSearchSuccess,
  fetchSuccess,
  filterCustomers,
  selectCustomer,
  preImportCustomers,
  preImportCustomersSuccess,
  importCustomersAdd,
  importCustomersUpdate,
  importCustomersUpdateSuccess,
  importCustomersAddSuccess,
  fetchCurrentPayer,
  fetchCurrentPayerFailed,
  fetchCurrentPayerSuccess,
  addNewPayer,
  addNewPayerActionStop,
  fetchPayerHistoryList,
  fetchPayerHistoryListFailed,
  fetchPayerHistoryListSuccess,
  downloadProductGroupSuccess,
  downloadProductGroupError,
  saveAdditionalDiscountSuccess,
  saveAdditionalDiscountError,
  fetchClassesAndBrands,
  fetchClassesAndBrandsSuccess,
  updateCustomer,
  updateCustomerSuccess,
  fetchCustomerClasifications,
  fetchCustomerClasificationsSuccess,
} from "./actions";
import {
  AdditionalDiscountHistory,
  ClassAndBrands,
  CurrentAccountingCustomer,
  Customer,
  CustomersActionTypes,
  DeliveryLocation,
  PaginableCustomer,
  TAddSuccess,
  TPreImport,
} from "./types";

import translations from "~/utils/translations";
import { handleFileUploadError } from "~/utils/fileUploadErrorHandler";
import { encodeRequestFilters } from "~/utils/encodeRequestFilters";
import { toastSuccess } from "~/utils/toast";
import { ApiError } from "../error/types";
import { downloadFile } from "~/utils/downloadFile";

function* handleFetch(action: ReturnType<typeof fetchCustomers>) {
  try {
    let searchQuery = "";

    if (action.payload.page) {
      searchQuery = `page=${action.payload.page - 1}`;
    }

    if (action.payload.size) {
      searchQuery += `&size=${action.payload.size}`;
    }

    encodeRequestFilters(action.payload.filters).forEach((filter: any) => {
      const currentFilter = `${filter.name}=${
        filter.key ? filter.key : filter.value
      }`;

      searchQuery += searchQuery ? `&${currentFilter}` : currentFilter;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      searchQuery += `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const customers: PaginableCustomer = yield call(
      callApi,
      "get",
      `/customers?${searchQuery}`
    );
    const searchSuccess: PaginableCustomer = yield put(
      fetchSearchSuccess(customers)
    );
    const success: PaginableCustomer = yield put(fetchSuccess(customers));
    return action.type.toString() === CustomersActionTypes.FETCH_SEARCH_REQUEST
      ? searchSuccess
      : success;
  } catch (e) {
    const error = e as ApiError;
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.contractor.load")
      )
    );
  }
}

function* handleFetchDeliveryPoints(
  action: ReturnType<typeof fetchDeliveryPoints>
) {
  try {
    if (action.payload.query) {
      const params = Object.entries(action.payload)
        .map((entity) => (!!entity[1] ? `${entity[0]}=${entity[1]}` : ""))
        .join("&");
      const deliveryPoints: DeliveryLocation[] = yield call(
        callApi,
        "get",
        `/customers/delivery-points?${params}`
      );
      yield put(fetchDeliveryPointsSuccess(deliveryPoints));
    } else {
      yield put(fetchDeliveryPointsSuccess([]));
    }
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.error.deliverypoints.load")
      )
    );
  }
}

function* handleFetchCurrentPayer(
  action: ReturnType<typeof fetchCurrentPayer>
) {
  try {
    const currentPayer: CurrentAccountingCustomer = yield call(
      callApi,
      "get",
      `/customers/${action.payload}/accounting-customer/current`
    );
    yield put(fetchCurrentPayerSuccess(currentPayer));
  } catch (e) {
    const error = e as ApiError;
    yield put(fetchCurrentPayerFailed());
    yield put(
      handleError(
        error,
        false,
        error.message
          ? error.message
          : translations.format("app.error.common.load"),
        translations.format("app.error.customer.fetch-current-payer-error")
      )
    );
  }
}

function* handleFetchPayerHistoryList(
  action: ReturnType<typeof fetchPayerHistoryList>
) {
  try {
    const payerHistoryList: CurrentAccountingCustomer[] = yield call(
      callApi,
      "get",
      `/customers/${action.payload}/accounting-customer`
    );
    yield put(fetchPayerHistoryListSuccess(payerHistoryList));
  } catch (e) {
    const error = e as ApiError;
    yield put(fetchPayerHistoryListFailed());

    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.common.load"),
        error.message
          ? error.message
          : translations.format(
              "app.error.customer.fetch-payer-history-list-error"
            )
      )
    );
  }
}

function* handleAddNewPayer(action: ReturnType<typeof addNewPayer>) {
  try {
    const customerId = action.payload.customerId;

    yield call(
      callApi,
      "post",
      `/customers/${customerId}/accounting-customer`,
      { ...action.payload.data }
    );
    action.payload.cb();
    yield toastSuccess(
      "app.info.changePayer.success",
      "app.info.changePayer.info.success"
    );

    yield put(addNewPayerActionStop());
    yield put(fetchCurrentPayer(customerId));
    yield put(fetchPayerHistoryList(customerId));
  } catch (e) {
    const error = e as ApiError;
    yield put(addNewPayerActionStop());

    yield put(
      handleError(
        error as ApiError,
        false,
        translations.format("app.error.common.load"),
        error.message
          ? error.message
          : translations.format("app.error.customer.add-new-payer-error")
      )
    );
  }
}

function* handleSelect(action: ReturnType<typeof selectCustomer>) {
  try {
    const detail: Customer = yield call(
      callApiWithRetry,
      3,
      "get",
      `${action.meta ? "/customer-panel" : ""}/customers/${action.payload}`
    );
    const deliveryAddresses: DeliveryLocation[] = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/customers/${action.payload}/delivery-addresses`
    );
    yield put(customerSelected({ detail, deliveryAddresses }));
  } catch (error) {
    yield put(
      handleError(
        error as ApiError,
        true,
        translations.format("app.error.order.select-customer")
      )
    );
  }
}

function* handlePreImport(action: ReturnType<typeof preImportCustomers>) {
  try {
    const preImportResult: TPreImport = yield call(
      callApiUploadFile,
      `/customers/preimport`,
      action.payload,
      "file"
    );

    yield put(preImportCustomersSuccess(preImportResult));
  } catch (error) {
    yield handleFileUploadError(error as ApiError);
  }
}

function* handleImportAdd(action: ReturnType<typeof importCustomersAdd>) {
  try {
    yield call(
      callApiUploadFile,
      `/customers/import-create`,
      action.payload,
      "file"
    );

    yield put(importCustomersAddSuccess());
  } catch (error) {
    yield handleFileUploadError(error as ApiError);
  }
}

function* handleImportUpdate(action: ReturnType<typeof importCustomersUpdate>) {
  try {
    yield call(
      callApiUploadFile,
      `/customers/import-update`,
      action.payload,
      "file"
    );

    yield put(importCustomersUpdateSuccess());
  } catch (error) {
    yield handleFileUploadError(error as ApiError);
  }
}

function* handleFilterCustomers(action: ReturnType<typeof filterCustomers>) {
  try {
    if (action.payload) {
      const res: PaginableCustomer = yield call(
        callApi,
        "get",
        `/customers?query=${action.payload}`
      );
      yield put(fetchSuccess(res));
    } else {
      yield put(
        fetchSuccess({ content: [], number: 0, totalPages: 0, size: 15 })
      );
    }
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.error.common.load"),
        translations.format("app.error.contractor.filter")
      )
    );
  }
}

function* handleAddCustomer(action: any) {
  try {
    const res: TAddSuccess = yield call(
      callApi,
      "post",
      `/customers`,
      action.payload
    );
    yield put(addSuccess(res.id));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.error.common.update"),
        translations.format("app.error.contractor.add")
      )
    );
  }
}

function* handleSaveAdditionalDiscount(action: any) {
  try {
    const { customerId, DTO } = action.payload;
    const resp: AdditionalDiscountHistory = yield call(
      callApi,
      "post",
      `/customers/${customerId}/discount`,
      DTO
    );

    yield put(saveAdditionalDiscountSuccess(resp));
    yield toastSuccess(
      "app.info.saveAdditionalDiscount.success",
      "app.info.saveAdditionalDiscount.info.success"
    );
    yield put(selectCustomer(customerId));
  } catch (error) {
    yield put(saveAdditionalDiscountError());
    yield put(
      handleError(
        error as Error,
        false,
        "Wystąpił błąd",
        "Nie udało się zapisać dodatkowego rabatu zakupowego."
      )
    );
  }
}

function* handleRemoveCustomerWarehouse(action: any) {
  try {
    const { rrdi, warehouseId } = action.payload;
    const res: TAddSuccess = yield call(
      callApi,
      "delete",
      `/customers/${rrdi}/remove/${warehouseId}`
    );
    yield put(addSuccess(res.id));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.error.common.update")
      )
    );
  }
}

function* handleDownloadPorductsGroup(action: any) {
  try {
    const { customerId, groupId } = action.payload;
    const fileName = `DRZ - groupy produktów - ${customerId}`;
    const res: Response = yield call(
      callApiDownload,
      "get",
      `/customers/${customerId}/discount/${groupId}/generate `,
      fileName
    );

    if (res) {
      downloadFile(
        res,
        fileName,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
      );
      yield put(downloadProductGroupSuccess());
    } else {
      yield put(downloadProductGroupError());
    }
  } catch (error) {
    yield put(downloadProductGroupError());
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("app.error.common.update")
      )
    );
  }
}

function* handleFetchClassesAndBrands(
  action: ReturnType<typeof fetchClassesAndBrands>
) {
  try {
    const res: ClassAndBrands = yield call(
      callApi,
      "get",
      `/customer/classes-and-brands`
    );
    yield put(fetchClassesAndBrandsSuccess(res));
  } catch (error) {
    yield put(
      handleError(error as ApiError, false, "Nie udało się pobrać klas i marek")
    );
  }
}

function* handleUpdateCustomer(action: ReturnType<typeof updateCustomer>) {
  try {
    const { rrdi, customerClasses, customerBrands } = action.payload;
    yield call(callApi, "PATCH", `/customers/${rrdi}`, {
      customerClasses,
      customerBrands,
    });
    yield put(updateCustomerSuccess(action.payload));
  } catch (error) {
    yield put(
      handleError(error as ApiError, true, "Nie udało się zapisać klas i marek")
    );
  }
}

function* handleFetchCustomerClassifications(
  action: ReturnType<typeof fetchCustomerClasifications>
) {
  try {
    const classifications: string[] = yield call(
      callApi,
      "get",
      "/customers/classifications"
    );
    yield put(fetchCustomerClasificationsSuccess(classifications));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało sie pobrać klas Falcon")
    );
  }
}

function* watchFetchRequest() {
  yield takeEvery(CustomersActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchFetchCurrentPayer() {
  yield takeEvery(
    CustomersActionTypes.FETCH_CURRENT_PAYER,
    handleFetchCurrentPayer
  );
}

function* watchFetchPayerHistoryList() {
  yield takeEvery(
    CustomersActionTypes.FETCH_PAYER_HISTORY_LIST,
    handleFetchPayerHistoryList
  );
}

function* watchAddPayer() {
  yield takeEvery(CustomersActionTypes.ADD_NEW_PAYER, handleAddNewPayer);
}

function* watchFetchSearchRequest() {
  yield takeEvery(CustomersActionTypes.FETCH_SEARCH_REQUEST, handleFetch);
}

function* watchFetchDeliveryPoints() {
  yield takeEvery(
    CustomersActionTypes.FETCH_DELIVERY_POINTS,
    handleFetchDeliveryPoints
  );
}

function* watchSelectCustomer() {
  yield takeLatest(CustomersActionTypes.SELECT_CUSTOMER, handleSelect);
}

function* watchFilterCustomers() {
  yield takeEvery(CustomersActionTypes.FILTER_CUSTOMERS, handleFilterCustomers);
}

function* watchAddCustomer() {
  yield takeEvery(CustomersActionTypes.ADD_CUSTOMER, handleAddCustomer);
}

function* watchPreImport() {
  yield takeEvery(CustomersActionTypes.PRE_IMPORT, handlePreImport);
}

function* watchImportAdd() {
  yield takeEvery(CustomersActionTypes.IMPORT_ADD, handleImportAdd);
}

function* watchImportUpdate() {
  yield takeEvery(CustomersActionTypes.IMPORT_UPDATE, handleImportUpdate);
}

function* watchRemoveCustomerWarehouse() {
  yield takeEvery(
    CustomersActionTypes.DELETE_CUSTOMER_WAREHOUSE,
    handleRemoveCustomerWarehouse
  );
}

function* watchDownloadPRoductsGroup() {
  yield takeEvery(
    CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP,
    handleDownloadPorductsGroup
  );
}

function* watchSaveAdditionalDiscount() {
  yield takeEvery(
    CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT,
    handleSaveAdditionalDiscount
  );
}

function* watchFetchClassesAndBrands() {
  yield takeEvery(
    CustomersActionTypes.FETCH_CLASSES_AND_BRANDS,
    handleFetchClassesAndBrands
  );
}

function* watchUpdateCustomer() {
  yield takeEvery(CustomersActionTypes.UPDATE_CUSOMER, handleUpdateCustomer);
}

function* watcwatchFetchCustomerClassifications() {
  yield takeEvery(
    CustomersActionTypes.FETCH_CUSTOMER_CLASSIFICATIONS,
    handleFetchCustomerClassifications
  );
}

function* customersSaga() {
  yield all([
    fork(watchFetchPayerHistoryList),
    fork(watchAddPayer),
    fork(watchFetchCurrentPayer),
    fork(watchFetchRequest),
    fork(watchFetchDeliveryPoints),
    fork(watchSelectCustomer),
    fork(watchFilterCustomers),
    fork(watchAddCustomer),
    fork(watchFetchSearchRequest),
    fork(watchPreImport),
    fork(watchImportAdd),
    fork(watchImportUpdate),
    fork(watchRemoveCustomerWarehouse),
    fork(watchDownloadPRoductsGroup),
    fork(watchSaveAdditionalDiscount),
    fork(watchFetchClassesAndBrands),
    fork(watchUpdateCustomer),
    fork(watcwatchFetchCustomerClassifications),
  ]);
}

export default customersSaga;
