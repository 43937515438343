import React from 'react'
import { Form, FormInputProps, Ref } from 'semantic-ui-react'
import './styles.scss'
import { useInputNumber } from "~/components/InputNumber/hooks/useInputNumber";

export type TActionData = {
    oldValue: string
    selectionStart: number
    key?: string
}

export interface InputNumberOnChangeData extends InputNumberProps {
    value: number
}

export interface InputNumberProps extends Omit<FormInputProps, 'onChange'> {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputNumberOnChangeData) => void
    value?: number
    precision?: number
    min?: number
    max?: number
    step?: number
    type?: 'number',
    label?: string | JSX.Element
}

const FormInputNumber: React.FC<InputNumberProps> = (props) => {
    const {
        value, min, max, label, ...bypassProps
    } = props

    const { ref, changeHandler, keyDownHandler } = useInputNumber(props)

    return <Ref innerRef={ref}>
        <Form.Input
            {...bypassProps}
            className={`input-number ${props.className || ''}`}
            input={{ type: 'text' }}
            onChange={changeHandler}
            onKeyDown={keyDownHandler}
            label={label ? label : null}
        />
    </Ref>
}
export { FormInputNumber }
