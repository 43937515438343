import { connect } from 'react-redux'
import { Button, Header, Icon, Input, Message, Modal, Segment } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { importFinished, importReferences } from '~/store/promotions/actions'
import { TReferenceRuleData } from '~/store/promotions/types'
import { toastInfo, toastWarn } from '~/utils/toast'

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ExampleXls from '~/assets/images/examples/promo-references-xls.png'
import PreviewImage from '~/components/PreviewImage/PreviewImage'

type TReduxActions = {
    importReferences: typeof importReferences
    importFinished: typeof importFinished
}

type TReduxState = {
    importing: boolean
    imported?: TReferenceRuleData[]
}

type TProps = TReduxActions & TReduxState & {
    triggerOpen: boolean
    overwriteWarn: boolean
    importSuccess: (imported: TReferenceRuleData[]) => void
}

const ImportReferences: React.FC<TProps> = ({
    triggerOpen,
    overwriteWarn,
    importing,
    imported,
    importSuccess,
    importReferences,
    importFinished,
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const [file, setFile] = useState<File>()

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    const handleClose = useCallback((): void => {
        if (!importing) {
            setOpen(false)
        }
    }, [importing, setOpen]);

    const handleFileChange = (ev: any, el: any): void => {
        setFile(ev.target.files[0])
    }

    const handleLoad = useCallback((): void => {
        if (file) {
            importReferences(file)
        }
    }, [importReferences, file]);

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        return;
    }
  });

    useEffect(() => {
        if (open && firstUpdate.current && imported && imported.length === 0) {
            toastInfo("Brak referencji", "Podany plik nie zawiera żadnych referencji")
        }
    }, [imported, open])

    useEffect(() => {
        if (imported) {
            if (imported.length > 0)  {
                const nulls = imported.filter(data => !data).length
                if (nulls !== 0) {
                    toastWarn("Pominięto referencje", `Pominięto ${nulls} referencji z ${imported.length}`)
                }
                importSuccess(imported.filter(data => !!data))
                importFinished()
                handleClose()
            }
        }
    }, [handleClose, importFinished, importSuccess, imported])

    return useMemo(() => (
        <Modal
            open={open}
            size='small'
            closeOnDimmerClick={false}
        >
            <Header icon="file archive" content="Importowanie referencji" />
            <Modal.Content>
                <Message>
                    <Message.Content>
                        Załaduj plik XLSX, XLS, CSV według wzorcowego formatu.<br />
                        {overwriteWarn && <div style={{ color: "red" }}>Aktualnie wybrane hierarchie zostaną nadpisane!</div>}
                    </Message.Content>
                </Message>
                <PreviewImage src={ExampleXls} />
                <Segment placeholder>
                    <Header icon>
                        <Icon name="file archive outline" />
                        {file ? file.name : "Załaduj plik"}
                    </Header>
                    <Input type="file" onChange={handleFileChange} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Wczytaj" icon="upload" labelPosition="right" loading={importing} disabled={importing || !file} onClick={handleLoad} />
                <Button content="Zamknij" disabled={importing} onClick={handleClose} />
            </Modal.Actions>
        </Modal>
    ), [open, importing, file, handleClose, handleLoad, overwriteWarn])
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ promotions }: ApplicationState) => {
    return {
        importing: promotions.importing,
        imported: promotions.importedList
    }
}

const mapDispatchToProps: TReduxActions = {
    importReferences,
    importFinished
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportReferences)
