import { OrderStatusEnum } from '~/store/orders/types';
import { Dropdown } from 'semantic-ui-react';
import React from 'react';

interface DropdownItemOrderStatusOptionProps {
  id: string;
  status: string;
  cancelable: boolean;
  enabledStatuses: string[];
  className: string;
  text: string;
  statusToChange: string;
  statusChangeHandler: (id: string, status: string) => void;
}

const DropdownItemOrderStatusOption = (props: DropdownItemOrderStatusOptionProps): JSX.Element => {
  const disabled = props.enabledStatuses.indexOf(props.status.toUpperCase()) === -1;
  const isCancelable =
    props.cancelable == null ? false : !props.cancelable && props.statusToChange === OrderStatusEnum.CANCELLED;

  return disabled || isCancelable ? (
    <></>
  ) : (
    <Dropdown.Item
      disabled={disabled || isCancelable}
      onClick={() => !disabled && !isCancelable && props.statusChangeHandler(props.id, props.statusToChange)}
      className={props.className}
      text={props.text}
    />
  );
};

export default DropdownItemOrderStatusOption;
