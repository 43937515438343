import { action } from 'typesafe-actions'
import { ApiError } from '../error/types'
import { SpeederActionTypes } from './types'

export const speederRaportGenerateSuccess = (result?: object) => action(SpeederActionTypes.SPEEDER_GENERATE_RAPORT_SUCCESS, result)
export const speederRaportGenerateFailed = (error: ApiError | null) => action(SpeederActionTypes.SPEEDER_GENERATE_RAPORT_FAILED, error)
export const generateLIGFATCQRaport = (data?: object) => action(SpeederActionTypes.SPEEDER_GENERATE_LIGFACTQ_RAPORT, data)
export const generateSTKVTHRaport = () => action(SpeederActionTypes.SPEEDER_GENERATE_STKVTH_RAPORT)
export const generateVENTESQRaport = (data?: object) => action(SpeederActionTypes.SPEEDER_GENERATE_VENTESQ_RAPORT, data)
export const generateSTKVTEIRaport = () => action(SpeederActionTypes.SPEEDER_GENERATE_STKVTEI_RAPORT)
export const saveDataFromIntoSpeederDateFrom = (dateFrom: Date | null) => action<SpeederActionTypes, Date | null>(SpeederActionTypes.SAVE_DATA_FROM, dateFrom);
export const saveDataToIntoSpeederDateToInput = (dateTo: Date | null) => action<SpeederActionTypes, Date | null>(SpeederActionTypes.SAVE_DATA_TO, dateTo);
export const saveExactDateFromIntoSpeederDateFromInput = (exactDateFrom: Date | null) => action<SpeederActionTypes, Date | null>(SpeederActionTypes.SAVE_EXACT_DATE_FROM, exactDateFrom);
export const saveExactDateToIntoSpeederDateToInput = (exactDateTo: Date | null) => action<SpeederActionTypes, Date | null>(SpeederActionTypes.SAVE_EXACT_DATE_TO, exactDateTo);