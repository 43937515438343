export type TTableFilterSession = {
    value: string
    name: string
    table: string
    text?: string
    key?: string
}

const TableFilterSession = (() => {

    const getFromLS = (): any => {
        const data = localStorage.getItem('tableFilterSession')
        return data ? JSON.parse(data) : []
    }

    let tableFilter: TTableFilterSession[] = getFromLS()
  
    const getFilters = (tableName: string): TTableFilterSession[] => {
        const filters = tableFilter.filter(s => s.table === tableName)
        return filters.length > 0 ? filters : []
    }
  
    const setFilters = (tfilter: TTableFilterSession): void => {
        const found = tableFilter.find(s => s.name === tfilter.name && s.table === tfilter.table)
        if(!found){
            tableFilter.push(tfilter)
        }
        else {
            tableFilter = tableFilter.map((filter) => (filter.name === tfilter.name ? { ...tfilter } : { ...filter } ))
        }
        localStorage.setItem('tableFilterSession', JSON.stringify(tableFilter))
    }

    const clearFilter = (tfilter?: TTableFilterSession, tableName?: string): void => {
        if(tfilter){
            tableFilter = tableFilter.filter(s => s.name !== tfilter.name && s.table === tfilter.table)
        }
        else {
            tableFilter = tableFilter.filter(s => s.table !== tableName)
        }
        localStorage.setItem('tableFilterSession', JSON.stringify(tableFilter))
    }
  
    return {
        getFilters,
        setFilters,
        clearFilter
    }
  
  })()
  
  export default TableFilterSession