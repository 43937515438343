import DetailCard from "~/components/DetailCard/DetailCard";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { Button, Divider } from "semantic-ui-react";
import React from "react";
import DeliveryStocks from "~/pages/Orders/lib/Add/Delivery/DeliveryStocks";
import { CardDetailsDeliveryProps } from "~/pages/Orders/lib/Add/Delivery/constants";

const CardDetailsDelivery = ({
  shrink,
  setDeliveryType,
  summary,
  dictionaries,
  deliveryType,
  deliveryPoint,
  getDeliveryLocation,
}: CardDetailsDeliveryProps) => {
  const renderDelieveryStocks = () => {
    const warehouses =
      deliveryType === "STOCK"
        ? deliveryPoint?.stock?.warehouses
        : deliveryType === "TURBO_PRO"
          ? deliveryPoint?.turboPro?.warehouses
          : deliveryType === "URGENT"
            ? deliveryPoint?.urgent?.warehouses
            : [];
    return !!warehouses?.length && <DeliveryStocks warehouses={warehouses} />;
  };

  return (
    <DetailCard
      title="app.delivery"
      id="delivery"
      key="delivery"
      minHeight={40}
      width={shrink ? 2.5 : 3}
      smallWidth={5.33}
    >
      {dictionaries["order-delivery-type"].map((deliveryDict) => {
        const deliveryPoint = getDeliveryLocation(deliveryDict.value);
        const blocked =
          deliveryPoint &&
          (deliveryPoint.blocked || deliveryPoint.payer.blocked);
        return (
          <SmartPopup
            key={deliveryDict.key}
            description={blocked ? "Punkt dostawy zablokowany" : undefined}
          >
            <Button
              key={deliveryDict.key}
              fluid
              basic={deliveryType !== deliveryDict.value}
              compact
              disabled={summary || !deliveryPoint}
              style={{ marginBottom: 5, textAlign: "left" }}
              onClick={() => {
                setDeliveryType(deliveryDict.value);
              }}
              content={deliveryDict.text}
              labelPosition={blocked ? "right" : undefined}
              icon={blocked ? "warning sign" : undefined}
            />
          </SmartPopup>
        );
      })}
      <Divider />
      {renderDelieveryStocks()}
    </DetailCard>
  );
};

export default CardDetailsDelivery;
