import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import HistoryModal from '~/pages/Customers/Details/lib/HistoryModal';
import { CurrentPayerCardProps } from '~/pages/Customers/Details/lib/card/CurrentPayerCard/constants';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { parseDate } from '~/utils/dateUtils';
import { HistoryList } from '~/pages/Customers/Details/lib/card/CurrentPayerCard/HistoryList';

export const CurrentPayerCard = ({ id, currentPayer, payerHistoryList }: CurrentPayerCardProps) => {
  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} defaultMessage={id} />;
  };

  return (
    <DetailCard title="app.customer.current.payer.card.title" id={id} key={id}>
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.authorUsername')}
        value={currentPayer?.authorUsername}
        align="left"
      />
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.creationTime')}
        value={parseDate(currentPayer?.creationTime)}
        align="left"
      />
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.creationComment')}
        value={currentPayer?.creationComment}
        align="left"
      />
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.currentAccountingCustomerID')}
        value={currentPayer?.currentAccountingCustomerID}
        align="left"
      />
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.oldAccountingCustomerID')}
        value={currentPayer?.oldAccountingCustomerID}
        align="left"
      />
      <SmartLabel
        label={formatMessage('app.customer.currentPayer.validFrom')}
        value={parseDate(currentPayer?.validFrom)}
        align="left"
      />
      <HistoryModal loading={false} list={payerHistoryList}>
        <HistoryList historyList={payerHistoryList} formatMessage={formatMessage} />
      </HistoryModal>
    </DetailCard>
  );
};
