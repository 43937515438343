import { useCallback } from "react";
import { localStorageService } from "./LocalStorage";
import { LocalStorageColumn } from "~/components/ColumnToggler/ColumnToggler";
import { TColumn } from "~/components/Table/lib/types";
import { DictionariesState } from "~/store/dictionaries/types";

type TProps = { tableKey: string; dictionaries: DictionariesState };

export const useCells = ({ tableKey, dictionaries }: TProps) => {
  const hideColumn = useCallback(
    (name: string, projection?: boolean): boolean => {
      const columnsToHide = localStorageService.get(
        `${tableKey}Columns`
      ) as LocalStorageColumn[];
      if (columnsToHide) {
        const findColumn = columnsToHide.find((x) => x.name === name);
        return findColumn ? findColumn.shouldDisplay : !!projection;
      }
      return !!projection;
    },
    [tableKey]
  );

  const completeColumnDictionaries = useCallback(
    (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap
        .map((column) =>
          column.dictionaryName
            ? {
                ...column,
                dictionary: dictionaries[column.dictionaryName],
              }
            : { ...column }
        )
        .map((column) => ({
          ...column,
          projection: hideColumn(column.name, column.projection),
        })),
    [hideColumn, dictionaries]
  );

  return { hideColumn, completeColumnDictionaries };
};
