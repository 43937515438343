import React, { SyntheticEvent, useCallback, useContext } from "react";
import { connect } from "react-redux";

import { DictionariesState, DictionaryItem } from "~/store/dictionaries/types";
import { IInvoice, TInvoicePreviousLine } from "~/store/invoices/types";
import { ApplicationState } from "~/store";
import { TLine } from "../AddInvoiceCorrection";
import { saveQuantityToRedux } from "~/store/invoices/actions";
import AddInvoiceCorrectionReferenceLine from "./AddInvoiceCorrectionReferenceLine/AddInvoiceCorrectionReferenceLine";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

enum ShownModal {
  NONE,
  CONFIRM_CORRECTION,
  CONFIRM_RESET_CORRECTION,
}

type AddInvoiceCorrectionContentProps = {
  changeInvoice: (event: SyntheticEvent | null, data: any) => void;
  children: React.ReactNode;
  description: string;
  getPercentageChange: (
    oldNumber: number,
    newNumber: number
  ) => number | undefined;
  handleCancel: () => void;
  handleConfirm: () => void;
  handleCreateRestCorrection: () => void;
  handleOpen: () => void;
  handleSearchChange: (e: SyntheticEvent, data: any) => void;
  inputDescription: (event: SyntheticEvent, data: any) => void;
  invoice: string;
  invoiceIn?: string;
  invoiceMask?: string;
  isDebitNote?: boolean;
  isResetCorrection?: boolean;
  lines: TLine[];
  mapInvoices: (invoices: IInvoice[]) => DictionaryItem[];
  mapVat: (vatDicts: DictionaryItem[]) => DictionaryItem[];
  open: boolean;
  previousLines: TInvoicePreviousLine[];
  searchInvoice: (event: SyntheticEvent<Element, Event>, data: any) => void;
  setLines: (key: TLine[]) => void;
  selectAll: (event: SyntheticEvent, data: any) => void;
  selectLine: (lineNumber: number, event: SyntheticEvent, data: any) => void;
  setTableData: (key: {
    type?: string | undefined;
    column?: string | undefined;
  }) => void;
  setShownModal: (key: ShownModal) => void;
  shownModal: ShownModal;
  tableData: {
    data: TLine[];
    direction: any;
    loading: boolean;
    checkedLine: boolean;
    selected: boolean;
    column: string;
  };
  changed: boolean;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

type TReduxState = {
  invoices: IInvoice[];
  loadingInvoices: boolean;
  loadingDictionaries: boolean;
  creating?: boolean;
  dictionaries: DictionariesState;
  finalInvoice?: IInvoice;
  debitNotes: IInvoice[];
  quantityRedux?: number;
};

type TReduxActions = {
  saveQuantityToRedux: typeof saveQuantityToRedux;
};

type TAddInvoiceCorrectionProps = AddInvoiceCorrectionContentProps &
  TReduxActions &
  TReduxState;

const AddInvoiceCorrectionContent = ({
  changeInvoice,
  children,
  description,
  handleCancel,
  handleConfirm,
  handleCreateRestCorrection,
  invoices,
  debitNotes,
  loadingDictionaries,
  loadingInvoices,
  handleOpen,
  handleSearchChange,
  isDebitNote,
  invoice,
  isResetCorrection,
  creating,
  inputDescription,
  invoiceIn,
  invoiceMask,
  quantityRedux,
  dictionaries,
  finalInvoice,
  getPercentageChange,
  lines,
  mapInvoices,
  mapVat,
  open,
  previousLines,
  saveQuantityToRedux,
  searchInvoice,
  setLines,
  selectLine,
  setTableData,
  setShownModal,
  shownModal,
  tableData,
  changed,
  setChanged,
}: TAddInvoiceCorrectionProps) => {
  const context = useContext(AppContext)
  const userHaveLdcUiAccountingCorrectionPositive = useCallback(() =>
    context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_UI_ACCOUNTING_INVOICE_CORRECTION_POSITIVE), [context])
      
  const initLineValue = useCallback(
    (lineNo: number, key: string): string | undefined => {
      if (previousLines && previousLines.length) {
        const foundLine = previousLines.find(
          (line: TInvoicePreviousLine) => line.lineNumber === lineNo
        );
        return foundLine && !!foundLine[key]
          ? `Poprzednia wartość: ${foundLine[key]}`
          : "0";
      }
    },
    [previousLines]
  );

  const changeQuantity = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines(
        lines.map((line) =>
          line.lineNumber === lineNumber
            ? { ...line, quantity: parseInt(data.value), selected: true }
            : { ...line }
        )
      );
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          setChanged(true);
          if (
            lines &&
            lines.length &&
            item.lineNumber === lineNumber &&
            data.value !==
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              )
          ) {
            item.quantity = parseInt(data.value);
            if (
              data.value ===
              Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
            } else {
              saveQuantityToRedux(data.value);
              item.selected = true;
            }
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            (item.unitPriceNetBeforeDiscount !==
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              ) ||
              item.taxRate !==
                Number(
                  String(initLineValue(item.lineNumber, "vat")).substring(20)
                ) ||
              String(item.unitPriceNetAfterDiscount) !==
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20))
          ) {
            item.selected = true;
            item.quantity = parseInt(data.value);
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            item.unitPriceNetBeforeDiscount ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              ) &&
            item.taxRate ===
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            String(item.unitPriceNetAfterDiscount) ===
              String(
                initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
              ).substring(20)
          ) {
            item.selected = false;
            item.quantity = parseInt(data.value);
          }
          return item;
        });
      }
    },
    [
      setLines,
      lines,
      finalInvoice,
      setChanged,
      initLineValue,
      saveQuantityToRedux,
    ]
  );

  const changeBeforeDiscount = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines(
        lines.map((line) =>
          line.lineNumber === lineNumber
            ? {
                ...line,
                unitPriceNetBeforeDiscount: parseFloat(data.value),
                previewDiscount: getPercentageChange(
                  parseFloat(data.value),
                  line.unitPriceNetAfterDiscount
                ),
                selected: true,
              }
            : { ...line }
        )
      );
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          setChanged(true);
          if (item.lineNumber === lineNumber) {
            saveQuantityToRedux(item.quantity);
          }
          if (
            lines &&
            lines.length &&
            item.lineNumber === lineNumber &&
            item.quantity <=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            data.value !==
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              )
          ) {
            if (
              data.value <
                Number(
                  initLineValue(
                    item.lineNumber,
                    "unitPriceNetAfterDiscount"
                  ).substring(20)
                ) ||
              Number(quantityRedux) >
                Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
            } else if (
              data.value >
                Number(
                  initLineValue(
                    item.lineNumber,
                    "unitPriceNetBeforeDiscount"
                  ).substring(20)
                ) &&
              item.quantity >
                Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
            } else {
              item.selected = true;
            }
            item.unitPriceNetBeforeDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              parseFloat(data.value),
              item.unitPriceNetAfterDiscount
            );
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              )
          ) {
            item.selected = false;
            item.unitPriceNetBeforeDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              parseFloat(data.value),
              item.unitPriceNetAfterDiscount
            );
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              ) &&
            item.quantity >=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            item.taxRate ===
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            item.unitPriceNetAfterDiscount ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20)
              )
          ) {
            item.selected = false;
            item.unitPriceNetBeforeDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              parseFloat(data.value),
              item.unitPriceNetAfterDiscount
            );
          }
          return item;
        });
      }
    },
    // eslint-disable-next-line
    [
      finalInvoice,
      getPercentageChange,
      initLineValue,
      lines,
      quantityRedux,
      saveQuantityToRedux,
      setLines,
    ]
  );

  const changeAfterDiscount = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines(
        lines.map((line) =>
          line.lineNumber === lineNumber
            ? {
                ...line,
                unitPriceNetAfterDiscount: parseFloat(data.value),
                previewDiscount: getPercentageChange(
                  line.unitPriceNetBeforeDiscount,
                  parseFloat(data.value)
                ),
                selected: true,
              }
            : { ...line }
        )
      );
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          setChanged(true);
          if (item.lineNumber === lineNumber) {
            saveQuantityToRedux(item.quantity);
          }
          if (
            lines &&
            lines.length &&
            item.lineNumber === lineNumber &&
            item.quantity <=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            data.value !==
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20)
              )
          ) {
            if (
              data.value >
                Number(
                  initLineValue(
                    item.lineNumber,
                    "unitPriceNetBeforeDiscount"
                  ).substring(20)
                ) ||
              Number(quantityRedux) >
                Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
            } else if (
              data.value >
                Number(
                  initLineValue(
                    item.lineNumber,
                    "unitPriceNetAfterDiscount"
                  ).substring(20)
                ) &&
              item.quantity >
                Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
            } else {
              item.selected = true;
            }
            item.unitPriceNetAfterDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              item.unitPriceNetBeforeDiscount,
              parseFloat(data.value)
            );
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20)
              )
          ) {
            item.selected = false;
            item.unitPriceNetAfterDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              item.unitPriceNetBeforeDiscount,
              parseFloat(data.value)
            );
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20)
              ) &&
            item.quantity >=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            item.unitPriceNetBeforeDiscount ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              ) &&
            item.taxRate ===
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              )
          ) {
            item.selected = false;
            item.unitPriceNetAfterDiscount = parseFloat(data.value);
            item.previewDiscount = getPercentageChange(
              item.unitPriceNetBeforeDiscount,
              parseFloat(data.value)
            );
          }
          return item;
        });
      }
    },
    // eslint-disable-next-line
    [
      finalInvoice,
      getPercentageChange,
      initLineValue,
      lines,
      quantityRedux,
      saveQuantityToRedux,
      setLines,
    ]
  );

  const changeVat = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines(
        lines.map((line) =>
          line.lineNumber === lineNumber
            ? { ...line, vat: data.value, selected: true }
            : { ...line }
        )
      );
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          setChanged(true);
          if (item.lineNumber === lineNumber) {
            saveQuantityToRedux(item.quantity);
          }
          if (
            lines &&
            lines.length &&
            item.lineNumber === lineNumber &&
            item.quantity <=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            data.value !==
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            (Number(quantityRedux) <=
              Number(
                initLineValue(item.lineNumber, "quantity").substring(20)
              ) ||
              Number(item.quantity) <=
                Number(
                  initLineValue(item.lineNumber, "quantity").substring(20)
                ))
          ) {
            if (
              data.value !==
                Number(
                  initLineValue(
                    item.lineNumber,
                    "unitPriceNetAfterDiscount"
                  ).substring(20)
                ) &&
              item.quantity >
                Number(initLineValue(item.lineNumber, "quantity").substring(20))
            ) {
              item.selected = false;
              item.taxRate = data.value;
            } else {
              item.selected = true;
              item.taxRate = data.value;
            }
          } else if (
            item.lineNumber === lineNumber &&
            data.value !==
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            (Number(quantityRedux) <=
              Number(
                initLineValue(item.lineNumber, "quantity").substring(20)
              ) ||
              Number(item.quantity) <=
                Number(
                  initLineValue(item.lineNumber, "quantity").substring(20)
                ))
          ) {
            item.selected = true;
            item.taxRate = data.value;
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              )
          ) {
            item.selected = false;
            item.taxRate = data.value;
          } else if (
            item.lineNumber === lineNumber &&
            data.value !==
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            (Number(quantityRedux) >=
              Number(
                initLineValue(item.lineNumber, "quantity").substring(20)
              ) ||
              Number(item.quantity) >=
                Number(
                  initLineValue(item.lineNumber, "quantity").substring(20)
                ))
          ) {
            item.selected = false;
            item.taxRate = data.value;
          } else if (
            item.lineNumber === lineNumber &&
            data.value ===
              Number(
                String(initLineValue(item.lineNumber, "vat")).substring(20)
              ) &&
            item.quantity >=
              Number(
                String(initLineValue(item.lineNumber, "quantity")).substring(20)
              ) &&
            item.unitPriceNetBeforeDiscount ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
              ) &&
            item.unitPriceNetAfterDiscount ===
              Number(
                String(
                  initLineValue(item.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20)
              )
          ) {
            item.selected = false;
            item.taxRate = data.value;
          }
          return item;
        });
      }
    },
    [
      setLines,
      lines,
      finalInvoice,
      setChanged,
      initLineValue,
      quantityRedux,
      saveQuantityToRedux,
    ]
  );

  const isInvalidQuantity = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          if (userHaveLdcUiAccountingCorrectionPositive()) {
            return item.lineNumber === lineNumber
            ? isNaN(item.quantity) || item.quantity < 0
            : item;

          } else {
            return item.lineNumber === lineNumber
              ? isNaN(item.quantity)
              || item.quantity < 0
              || String(item.quantity) >=
                String(initLineValue(line.lineNumber, "quantity")).substring(
                  20
                )
              : item;
          }
        });
      }
    },
    [finalInvoice, initLineValue, userHaveLdcUiAccountingCorrectionPositive]
  );

  const isInvalidCorrectionBlockadeStatus = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      return finalInvoice &&
        finalInvoice.correctionBlockade === "EXPIRED_INVOICE"
        ? true
        : false;
    },
    [finalInvoice]
  );

  const isInvalidUnitPriceNetAfterDiscount = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          return item.lineNumber === lineNumber
            ? String(item.unitPriceNetAfterDiscount) !==
                String(
                  initLineValue(line.lineNumber, "unitPriceNetAfterDiscount")
                ).substring(20) ||
                item.unitPriceNetAfterDiscount > item.unitPriceNetBeforeDiscount
            : item;
        });
      }
    },
    [finalInvoice, initLineValue]
  );

  const isPriceBeforeDiscountInvalid = useCallback(
    (lineNumber: number): boolean => {
      let isInvalid: boolean = false;
      lines.map((line) => {
        if (line.lineNumber === lineNumber) {
          if (
            line.unitPriceNetBeforeDiscount < line.unitPriceNetAfterDiscount
          ) {
            isInvalid = true;
          }
        }
        return line;
      });
      return isInvalid;
    },
    [lines]
  );

  const isPriceBeforeDiscountInvalidChanged = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          return item.lineNumber === lineNumber
            ? String(line.unitPriceNetBeforeDiscount) !==
                String(
                  initLineValue(line.lineNumber, "unitPriceNetBeforeDiscount")
                ).substring(20)
            : item;
        });
      }
    },
    [finalInvoice, initLineValue]
  );

  const isInvalidPrice = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          return item.lineNumber === lineNumber ? isNaN(item.price) : item;
        });
      }
    },
    [finalInvoice]
  );

  const isInvalidVat = useCallback(
    (line: TLine, lineNumber: number): boolean | any => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          return item.lineNumber === lineNumber
            ? !dictionaries["spare-part-tax-category"].find(
                (vat) => vat.valueOrgin === line.vat
              )
            : item;
        });
      }
    },
    [dictionaries, finalInvoice]
  );

  const isVatChanged = useCallback(
    (line: TLine, lineNumber: number): boolean | void => {
      if (finalInvoice) {
        finalInvoice.finalInvoiceLines.map((item: TLine) => {
          return item.lineNumber === lineNumber
            ? String(item.vat) !==
                String(initLineValue(line.lineNumber, "vat")).substring(20)
            : item;
        });
      }
    },
    [finalInvoice, initLineValue]
  );

  const isChecked = useCallback(
    (line: TLine) => {
      return line.quantity ===
        Number(
          String(initLineValue(line.lineNumber, "quantity")).substring(20)
        ) &&
        line.taxRate ===
          Number(String(initLineValue(line.lineNumber, "vat")).substring(20)) &&
        line.unitPriceNetBeforeDiscount ===
          Number(
            String(
              initLineValue(line.lineNumber, "unitPriceNetBeforeDiscount")
            ).substring(20)
          ) &&
        line.unitPriceNetAfterDiscount ===
          Number(
            String(
              initLineValue(line.lineNumber, "unitPriceNetAfterDiscount")
            ).substring(20)
          )
        ? true
        : false;
    },
    [initLineValue]
  );

  const isSelected = useCallback(
    (line: TLine): boolean => {
      return line.quantity ===
        Number(
          String(initLineValue(line.lineNumber, "quantity")).substring(20)
        ) &&
        line.taxRate ===
          Number(String(initLineValue(line.lineNumber, "vat")).substring(20)) &&
        line.unitPriceNetBeforeDiscount ===
          Number(
            String(
              initLineValue(line.lineNumber, "unitPriceNetBeforeDiscount")
            ).substring(20)
          ) &&
        line.unitPriceNetAfterDiscount ===
          Number(
            String(
              initLineValue(line.lineNumber, "unitPriceNetAfterDiscount")
            ).substring(20)
          )
        ? false
        : line.selected;
    },
    [initLineValue]
  );

  const validLines = useCallback(
    (lineNumber: number): boolean | void => {
      if (finalInvoice && finalInvoice.finalInvoiceLines.length === 0) {
        return false;
      } else if (finalInvoice && finalInvoice.finalInvoiceLines) {
        const selectedItems = finalInvoice.finalInvoiceLines.filter(
          (line: TLine) => isSelected(line)
        );

        return !selectedItems.some(
          (line: TLine) =>
            isInvalidQuantity(line, lineNumber) ||
            isInvalidCorrectionBlockadeStatus(line, lineNumber) ||
            isInvalidUnitPriceNetAfterDiscount(line, lineNumber) ||
            isPriceBeforeDiscountInvalidChanged(line, line.lineNumber) ||
            isInvalidPrice(line, lineNumber) ||
            isInvalidVat(line, lineNumber) ||
            isVatChanged(line, lineNumber)
        );
      }
    },
    [
      finalInvoice,
      isInvalidCorrectionBlockadeStatus,
      isInvalidPrice,
      isInvalidQuantity,
      isInvalidUnitPriceNetAfterDiscount,
      isPriceBeforeDiscountInvalidChanged,
      isInvalidVat,
      isVatChanged,
      isSelected,
    ]
  );

  const isOnlyWhiteSpaceDescription = useCallback(
    (): boolean => !description.replace(/\s/g, "").length,
    [description]
  );

  const isAnyLineSelected = (): boolean =>
    lines.filter((line) => line.selected).length > 0;

  const isNotValidFormForInvoiceReset = (): boolean =>
    invoice === "" ||
    isOnlyWhiteSpaceDescription() ||
    !!creating ||
    isAnyLineSelected();

  const isValidFormForCreate = useCallback(
    (): boolean =>
      invoice !== "" || !isOnlyWhiteSpaceDescription() || !!creating,
    [creating, invoice, isOnlyWhiteSpaceDescription]
  );

  return (
    <AddInvoiceCorrectionReferenceLine
      validLines={validLines}
      setChanged={setChanged}
      changeAfterDiscount={changeAfterDiscount}
      changeBeforeDiscount={changeBeforeDiscount}
      changeQuantity={changeQuantity}
      changeInvoice={changeInvoice}
      changeVat={changeVat}
      children={children}
      debitNotes={debitNotes}
      description={description}
      dictionaries={dictionaries}
      getPercentageChange={getPercentageChange}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      handleCreateRestCorrection={handleCreateRestCorrection}
      handleOpen={handleOpen}
      handleSearchChange={handleSearchChange}
      initLineValue={initLineValue}
      inputDescription={inputDescription}
      invoice={invoice}
      invoiceIn={invoiceIn}
      invoiceMask={invoiceMask}
      invoices={invoices}
      loadingDictionaries={loadingDictionaries}
      loadingInvoices={loadingInvoices}
      isChecked={isChecked}
      isInvalidQuantity={isInvalidQuantity}
      isInvalidUnitPriceNetAfterDiscount={isInvalidUnitPriceNetAfterDiscount}
      isPriceBeforeDiscountInvalid={isPriceBeforeDiscountInvalid}
      isInvalidVat={isInvalidVat}
      isNotValidFormForInvoiceReset={isNotValidFormForInvoiceReset}
      isValidFormForCreate={isValidFormForCreate}
      isDebitNote={isDebitNote}
      isResetCorrection={isResetCorrection}
      mapInvoices={mapInvoices}
      mapVat={mapVat}
      open={open}
      previousLines={previousLines}
      searchInvoice={searchInvoice}
      selectLine={selectLine}
      setTableData={setTableData}
      setShownModal={setShownModal}
      shownModal={shownModal}
      tableData={tableData}
      changed={changed}
    />
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  invoices,
  dictionaries,
}: ApplicationState) => ({
  invoices: invoices.list,
  debitNotes: invoices.debitNotes.list,
  loadingDictionaries: dictionaries.loading,
  loadingInvoices: invoices.loadingInvoices,
  creating: invoices.creating,
  finalInvoice: invoices.selectedFinalInvoice,
  quantityRedux: invoices.quantityRedux,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = { saveQuantityToRedux };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInvoiceCorrectionContent);
