import { Table, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Backorder } from "~/store/backorder/types";

import React from "react";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { TColumn } from "~/components/Table/lib/types";

interface Props {
  backorders: Backorder[];
  columns: TColumn[];
}

const BackordersList: React.FC<Props> = ({ backorders, columns }) => {
  const backorderListDropdown = (backorder: any): JSX.Element => {
    return !backorder.orderID ? (
      <TablePreviewDetails path={`/backorder/${backorder.id}`} />
    ) : (
      <TableMoreDropdown>
        <Dropdown.Item
          as={Link}
          to={`/backorder/${backorder.id}`}
          text="Podgląd zamówienia"
        />
        {backorder.orderID && (
          <Dropdown.Item
            as={Link}
            to={`/order/${backorder.orderID}`}
            text="Zamówienie źródłowe"
          />
        )}
      </TableMoreDropdown>
    );
  };

  if (backorders.length === 0) {
    return <TableNoResults />;
  }

  return (
    <>
      {backorders.map((backorder) => (
        <Table.Row key={backorder.id + Math.random()}>
          {columns.map(
            (column) =>
              column.projection &&
              column.getCell &&
              column.getCell(backorder, column)
          )}
          <Table.Cell className="col-dropdown-menu-sticky">
            {backorderListDropdown(backorder)}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default BackordersList;
