import { Table } from "semantic-ui-react";
import { TDetailsField } from "~/store/dictionaries/types";
import React from "react";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { TableHeadersProps } from "~/pages/Administration/lib/Dictionaries/Table/constants";

const TableHeaders = ({
  dictionaries,
  filterDictionaryHeader,
}: TableHeadersProps) => (
  <Table.Header>
    <Table.Row>
      {dictionaries.details.fields
        .filter((field: TDetailsField) => filterDictionaryHeader(field))
        .map((detail: TDetailsField) => (
          <Table.HeaderCell key={detail.name}>{detail.name}</Table.HeaderCell>
        ))}
      <Table.HeaderCell collapsing />
    </Table.Row>
  </Table.Header>
);

const mapStateToProps = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(TableHeaders);
