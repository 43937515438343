import { TSearchProps } from "~/components/SmartField/lib/types";
import { Icon, Loader } from "semantic-ui-react";
import React from "react";

export const getSearchIcon = (error: string, success: boolean, props: TSearchProps): JSX.Element | 'search' | undefined => {
    if (error) {
        return <Icon name="warning sign" className="float-right"/>;
    }
    if (success) {
        return <Icon name="checkmark" className="float-right"/>;
    }
    if (!props.readOnly) {
        return "search"
    }
}

export const getTextAreaIcon = (loading: boolean, error: string, modified: boolean, success: boolean): JSX.Element | undefined => {
    if (loading) {
        return <Loader active inline size="tiny"/>
    }
    if (error) {
        return <Icon name="warning sign"/>
    }
    if (modified) {
        return <Icon name="pencil"/>
    }
    if (success) {
        return <Icon name="checkmark"/>
    }
}

export const getFieldIcon = (error: string, modified: boolean, success: boolean) => {
    if (error) {
        return "warning sign"
    }
    if (modified) {
        return "pencil"
    }
    if (success) {
        return "checkmark"
    }
}

export const getHiddenIcon = (loading: boolean, error: string, modified: boolean, success: boolean) => {
    if (loading) {
        return <Loader active inline size="tiny"/>
    }
    if (error) {
        return <Icon name="warning sign"/>
    }
    if (modified) {
        return <Icon name="pencil"/>
    }
    if (success) {
        return <Icon name="checkmark"/>
    }
}

export const getSelectIcon = (error: string, success: boolean) => {
    if (error) {
        return <Icon name="warning sign" className="float-right"/>
    }
    if (success) {
        return <Icon name="checkmark" className="float-right"/>
    }
}

export const getToggleIcon = (loading: boolean, error: string, success: boolean) => {
    if (loading) {
        return <Loader active inline size="tiny"/>
    }
    if (error) {
        return <Icon name="warning sign"/>
    }
    if (success) {
        return <Icon name="checkmark"/>
    }
}