import { TColumn } from "~/components/Table/lib/types";
import { Customer } from "~/store/customer/types";

export interface Props {
  customers: Customer[];
  columns: TColumn[];
}

export type CustomerListDropdownProps = {
  customer: Customer;
  userHaveLdcUiCustomerEditRole: boolean;
};

export type CategoryCompanyCellProps = {
  categoryCompany: boolean;
};

export type BlockedCellProps = {
  blocked: boolean;
  paymentCreditBlocked: boolean;
};

export type ClassCellProps = {
  categoryType: string;
};

export type OrderCategoryCellProps = {
  deliveryType: "STOCK" | "URGENT" | "TURBO_PRO";
};

export enum TableCellName {
  categoryType = "categoryType",
  categoryCompany = "categoryCompany",
  deliveryType = "deliveryType",
  blocked = "blocked",
  customerType = "customerType",
  currentPaymentMethodType = "currentPaymentMethod.type",
  currentTotalLimit = "currentTotalLimit",
  availableFactorTotalLimit = "availableFactorTotalLimit",
  rrdi = "rrdi",
  clientId = "clientId",
  ddsDeliveryId = "ddsDeliveryId",
  informationAddressCompany = "informationAddressCompany",
  informationAddressCity = "informationAddressCity",
  address = "address",
  accountingVatId = "accountingVatId",
  payerChange = "currentAccountingCustomer",
}
