import React from "react";

import { Icon, Table } from "semantic-ui-react";
import { TClaimAttachment } from "~/store/claims/types";
import { getAttachment } from "~/store/claims/actions";
import { connect } from "react-redux";
import { parseDate } from "~/utils/dateUtils";

type TProps = {
  claimNumber: string;
  attachment: TClaimAttachment;
  viewInCard?: boolean;
};

type TReduxActions = {
  getAttachment: typeof getAttachment;
};

type TClaimAttachmentProps = TProps & TReduxActions;

const ClaimAttachment: React.FC<TClaimAttachmentProps> = ({
  claimNumber,
  attachment,
  getAttachment,
  viewInCard,
}) => {
  const downloadAttachment = (storedFileName: string): void => {
    getAttachment(claimNumber, storedFileName);
  };

  const claimAttachmentIcon = (): JSX.Element => {
    return (
      <Icon
        name="file"
        size="big"
        title={"Pobierz"}
        style={{ cursor: "pointer" }}
        onClick={() => downloadAttachment(attachment.storedFileName)}
      />
    );
  };

  return !viewInCard ? (
    <Table.Row style={{ width: "100%" }}>
      <Table.Cell
        width={9}
        style={{
          maxWidth: "200px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {attachment.originalFileName}
      </Table.Cell>
      <Table.Cell width={3}>{parseDate(attachment.storeTimestamp)}</Table.Cell>
      <Table.Cell width={2}>{attachment.author}</Table.Cell>
      <Table.Cell width={2} textAlign="center">
        {claimAttachmentIcon()}
      </Table.Cell>
    </Table.Row>
  ) : (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "5px",
      }}
    >
      <div
        style={{
          maxWidth: window.innerWidth / 4,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {attachment.originalFileName}
      </div>
      <div>{claimAttachmentIcon()}</div>
    </div>
  );
};

const mapDispatchToProps: TReduxActions = { getAttachment };

export default connect(null, mapDispatchToProps)(ClaimAttachment);
