import { Grid, Icon } from "semantic-ui-react";
import React from "react";

export const DropdownPopupContent = (props: { error: any }) => {
    return (
        <Grid columns="2">
            <Grid.Row verticalAlign="middle">
                <Grid.Column width="1"><Icon color="red" name="exclamation"/></Grid.Column>
                <Grid.Column width="13">{props.error}</Grid.Column>
            </Grid.Row>
        </Grid>
    )
}