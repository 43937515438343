import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { fetchDeliveryPoints, selectCustomer } from "~/store/customer/actions";
import { DeliveryLocation, TDeliveryPoint } from "~/store/customer/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import {
  applyPromotion,
  clearPromotion,
  loadLinesLoaded,
  placeOrder,
} from "~/store/orders/actions";
import { fetchPriceListSparePart } from "~/store/price-list/actions";
import {
  ORDER_TYPE_ENUM,
  TDeliveryType,
  TOrderCreate,
  TOrderImportLine,
} from "~/store/orders/types";
import { toastInfo } from "~/utils/toast";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../orders.scss";
import {
  TAddOrderProps,
  TLine,
  TReduxActions,
  TReduxState,
} from "~/pages/Orders/lib/Add/constants";
import PageMainHeader from "~/pages/Orders/lib/Add/PageMainHeader";
import DetailCardType from "~/pages/Orders/lib/Add/Type";
import CardDetailCustomer from "~/pages/Orders/lib/Add/Customer";
import CardDetailsDelivery from "~/pages/Orders/lib/Add/Delivery";
import CardDetailsAdnotation from "~/pages/Orders/lib/Add/Adnotation";
import CardDetailsHandling from "~/pages/Orders/lib/Add/Handling";
import CardDetailsLimit from "~/pages/Orders/lib/Add/Limit";
import CardDetailsWarranty from "~/pages/Orders/lib/Add/Warranty";
import { updateIndexing } from "~/pages/Orders/lib/Add/utility";
import CardDetailsLines from "~/pages/Orders/lib/Add/Lines";
import CardDetailsBonusAndSpecialLimits from "~/pages/Orders/lib/Add/BonusAndSpecialLimits";
import { DictionaryName } from "~/store/dictionaries/types";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import translations from "~/utils/translations";
import { fetchUserById } from "~/store/users/actions";

const AddOrder: React.FC<TAddOrderProps> = ({
  created,
  creating,
  promoApplied,
  promoOrder,
  applyPromotion,
  clearPromotion,
  placeOrder,
  fetchDictionary,
  dictionaries,
  loadLinesLoaded,
  parsedLines,
  fetchDeliveryPoints,
  deliveryPoints,
  selectCustomer,
  selectedCustomer,
}) => {
  const [order, setOrder] = useState<TOrderCreate>({} as TOrderCreate);
  const [recentOrder, setRecentOrder] = useState<string>("");
  const [currentOrder, setCurrentOrder] = useState<string>("");
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deliveryType, setDeliveryType] = useState<TDeliveryType>();
  const [deliveryPoint, setDeliveryPoint] = useState<TDeliveryPoint>();
  const [deliveryDetails, setDeliveryDetails] = useState<DeliveryLocation>();
  const [lines, setLines] = useState<TLine[]>([]);
  const [summary, setSummary] = useState<boolean>(false);
  const [maximized, setMaximized] = useState<boolean>(false);
  const [lineAdded, setLineAdded] = useState<boolean>(false);
  const [initClientId, setInitClientId] = useState<string>();
  const [initDeliveryId, setInitDeliveryId] = useState<string>();
  const [cancel, setCancel] = useState<boolean>(false);
  const [serviceNotesError, setServiceNotesError] = useState<boolean>(false);
  const [currLimit, setCurrLimit] = useState<number>(0);
  const [isForced, setForced] = useState<boolean>(false);
  const [limit, setLimit] = useState(0);
  const [userName, setUserName] = useState("");

  const appContext = useContext(AppContext);

  useEffect(() => {
    document.title = translations.format("app.orders");
    appContext?.keycloak.loadUserInfo().then((info: any) => {
      setLimit(info.bonusSpecialOrderDiscountLimit);
      setUserName(info.name);
    });
  }, [appContext]);

  const tableEl = useRef<HTMLDivElement>(null);
  const promoApplicable =
    order.type === ORDER_TYPE_ENUM.NORMAL ||
    order.type === ORDER_TYPE_ENUM.WARRANTY;

  const userHaveLdcUiOrderBonusRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS
      ),
    [appContext]
  );

  const userHaveLdcUiOrderBonusPLUSRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS_PLUS
      ),
    [appContext]
  );

  const userHaveLdcUiOrderSpecialRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_SPECIAL
      ),
    [appContext]
  );

  useEffect(() => {
    fetchDictionary(DictionaryName.orderType, DictionaryName.orderDeliveryType);
    // eslint-disable-next-line no-restricted-globals
    const initParams = new URLSearchParams(location.search);
    const clientId = initParams.get("cId");
    const deliveryId = initParams.get("dId");
    if (clientId) {
      setInitClientId(clientId);
      fetchDeliveryPoints({ query: clientId });
    }
    if (deliveryId) {
      setInitDeliveryId(deliveryId);
    }
  }, [fetchDictionary, fetchDeliveryPoints, setInitClientId]);

  const resetOrder = useCallback((): void => {
    clearPromotion();
    setLines((lines) =>
      lines.map((line) => ({
        ...line,
        sellPrice: line.pnrPrice,
        promoCode: undefined,
        bonus: undefined,
      }))
    );
    setSummary(false);
  }, [clearPromotion, setLines, setSummary]);

  useEffect(() => {
    resetOrder();
  }, [order.type, resetOrder]);

  useEffect(() => {
    if (initClientId && deliveryPoints.length > 0) {
      const point = deliveryPoints[0];
      setDeliveryPoint(point);
    }
  }, [deliveryPoints, initClientId]);

  const updateOrder = useCallback(
    (field: keyof TOrderCreate, value?: any): void => {
      if (field === "serviceNotes" && value.length > 500) {
        setServiceNotesError(true);
        return;
      } else {
        serviceNotesError && setServiceNotesError(false);
      }
      if (value) {
        setCurrentOrder(value);
        if (recentOrder) {
          if (
            recentOrder === "SPECIAL" &&
            lines.length &&
            value === "SPECIAL"
          ) {
            setOpenConfirmModal(true);
            return;
          }
          if (
            ["NORMAL", "WARRANTY", "BONUS", "SPECIAL"].includes(value) &&
            lines.length
          ) {
            setOpenConfirmModal(true);
            return;
          }
          setRecentOrder(value);
        } else {
          setRecentOrder(value);
        }
        if (value === "SPECIAL" && lines.length) {
          setOpenConfirmModal(true);
          return;
        }
        setOrder((order) => ({ ...order, [field]: value }));
      } else {
        setOrder((order) => {
          let updated = { ...order };
          delete updated[field];
          return updated;
        });
      }
    },
    [lines.length, recentOrder, serviceNotesError]
  );

  const linesCopy = useCallback((): TLine[] => {
    return lines.map((line) => ({ ...line }));
  }, [lines]);

  const calculateSellPrice = useCallback(
    (line: TOrderImportLine): number => {
      if (order.type === "SPECIAL") {
        return line.sellPrice as number;
      } else return line.pnrPrice as number;
    },
    [order]
  );

  const calculateUploadSellPrice = (line: TOrderImportLine): number => {
    if (
      order.type === ORDER_TYPE_ENUM.BONUS ||
      order.type === ORDER_TYPE_ENUM.BONUS_PLUS
    ) {
      return line.pnrPrice * (1 - (line.bonus || 0) / 100);
    } else if (order.type === ORDER_TYPE_ENUM.SPECIAL) {
      return line.sellPrice as number;
    } else {
      return line.pnrPrice as number;
    }
  };

  const calculateUploadBonusValue = (line: TOrderImportLine): number => {
    if (order.type === ORDER_TYPE_ENUM.SPECIAL) {
      return line.sellPrice
        ? ((line.pnrPrice - line.sellPrice) / line.pnrPrice) * 100
        : 0;
    } else {
      return line.bonus as number;
    }
  };

  useEffect(() => {
    if (parsedLines.length > 0) {
      const newLines: TLine[] = [];
      let relatedRefId: string | undefined = undefined;
      let relatedRefQt: number | undefined = undefined;
      const blacklisted: string[] = [];

      parsedLines.forEach((line: TOrderImportLine) => {
        if (line.orderPartType === "BLOCKED") {
          blacklisted.push(line.referenceID);
          return;
        }
        if (
          line.orderPartType !== "REPLACEMENT" &&
          line.orderPartType !== "PROVISION"
        ) {
          relatedRefId = line.referenceID;
          relatedRefQt = line.quantity;
        }
        newLines.push({
          lineNumber: 0,
          referenceId: line.referenceID,
          description: line.description,
          packingQuantity: line.packagingQuantity,
          packingForceQuantity: line.forcePackagingQuantity,
          stock: line.stock,
          pnrPrice: line.pnrPrice,
          pvpPrice: line.pvpPrice,
          sellPrice: calculateUploadSellPrice(line),
          quantity: line.quantity,
          codes: line.codes ? { ...line.codes } : null,
          priceTaxCode: line.priceTax,
          orderLineType: line.orderPartType,
          replacementReferenceId:
            line.orderPartType === "REPLACEMENT" ? relatedRefId : undefined,
          replacementBaseQuantity:
            line.replacementBaseQuantity ||
            (line.orderPartType === "REPLACEMENT" ? relatedRefQt : undefined),
          provisionReferenceId:
            line.orderPartType === "PROVISION" ? relatedRefId : undefined,

          bonus:
            line.orderPartType !== "REPLACED"
              ? calculateUploadBonusValue(line)
              : 0,

          priceReductionDiscountCode: line.priceReductionDiscountCode,
          stockLevel: line.stockLevel,
          promoCode: line.promoCode,
          priceDRZ: line.priceDRZ,
        });
      });
      updateIndexing(newLines);
      setLines(newLines);
      closeModal();
      loadLinesLoaded([]);
      if (blacklisted.length > 0) {
        // TODO requires prettier notification
        toastInfo(
          "Pominięto referencje",
          "Pominięto zablokowane referencje: " + blacklisted.join(", ")
        );
      }
    }
    // eslint-disable-next-line
  }, [parsedLines, calculateSellPrice, loadLinesLoaded]);

  const getDeliveryLocation = useCallback(
    (type: string): DeliveryLocation | undefined => {
      if (deliveryPoint) {
        const propertyName = type
          .toLowerCase()
          .replace(/_([a-z])/g, (g) =>
            g[1].toUpperCase()
          ) as keyof TDeliveryPoint;
        return deliveryPoint[propertyName] as DeliveryLocation;
      }
    },
    [deliveryPoint]
  );

  useEffect(() => {
    if (deliveryType && deliveryPoint) {
      switch (deliveryType) {
        case "URGENT":
          deliveryPoint.urgent?.payer &&
            selectCustomer(deliveryPoint.urgent.payer.rrdi);
          break;
        case "STOCK":
          deliveryPoint.stock?.payer &&
            selectCustomer(deliveryPoint.stock.payer.rrdi);
          break;
        case "TURBO_PRO":
          deliveryPoint.turboPro?.payer &&
            selectCustomer(deliveryPoint.turboPro.payer.rrdi);
          break;
      }
    }
    // eslint-disable-next-line
  }, [deliveryType]);

  useEffect(() => {
    if (isForced) {
      selectedCustomer &&
        setCurrLimit(
          selectedCustomer.detail.paymentDistrigoCredits.currentLimitTotal
        );
    } else {
      selectedCustomer &&
        setCurrLimit(selectedCustomer.detail.currentPaymentMethodLimit);
    }
  }, [order, selectedCustomer, isForced]);

  useEffect(() => {
    if (promoOrder) {
      const newLines = linesCopy();
      promoOrder.parts.forEach((promoLine: any) => {
        const updateLine = newLines.find(
          (line) => line.referenceId === promoLine.referenceID
        );

        if (updateLine && promoLine.promoCode) {
          updateLine.promoCode = promoLine.promoCode;
          updateLine.pnrPrice = promoLine.pricePNR;
          updateLine.sellPrice = promoLine.netPrice;
        }
        if (promoLine.priceDRZ && updateLine) {
          updateLine.priceDRZ = promoLine.priceDRZ;
        }
        if (updateLine && promoLine.bonus) {
          updateLine.bonus = promoLine.bonus;
          updateLine.sellPrice = promoLine.netPrice;
        }
      });

      setLines(newLines);
    }
    // eslint-disable-next-line
  }, [promoOrder]);

  const selectDeliveryPoint = (selected: any): string => {
    if (
      selected &&
      (selected.point.stock || selected.point.urgent || selected.point.turboPro)
    ) {
      setDeliveryType(undefined);
      setDeliveryPoint(selected && selected.point);
    } else {
      setDeliveryType(undefined);
      setDeliveryPoint(undefined);
      toastInfo(
        "Błąd konfiguracyjny",
        "Wybrany punkt dostawy skonfigurowany jest nieprawidłowo."
      );
    }
    return "";
  };

  const getDefaultDeliveryType = useCallback((): TDeliveryType | undefined => {
    if (deliveryType) return deliveryType;
    if (deliveryPoint) {
      if (initDeliveryId) {
        if (
          deliveryPoint.stock &&
          deliveryPoint.stock.rrdi === initDeliveryId
        ) {
          return "STOCK";
        }
        if (
          deliveryPoint.urgent &&
          deliveryPoint.urgent.rrdi === initDeliveryId
        ) {
          return "URGENT";
        }
        if (
          deliveryPoint.turboPro &&
          deliveryPoint.turboPro.rrdi === initDeliveryId
        ) {
          return "TURBO_PRO";
        }
      }
      return deliveryPoint.stock
        ? "STOCK"
        : deliveryPoint.urgent
          ? "URGENT"
          : deliveryPoint.turboPro && "TURBO_PRO";
    }
  }, [deliveryPoint, initDeliveryId, deliveryType]);

  useEffect(() => {
    if (deliveryPoint) {
      setDeliveryType(getDefaultDeliveryType());
    } else {
      setDeliveryDetails(undefined);
      updateOrder("customerId");
      updateOrder("deliveryCustomerId");
    }
  }, [deliveryPoint, getDefaultDeliveryType, updateOrder]);

  useEffect(() => {
    if (deliveryType && deliveryPoint) {
      const delivery = getDeliveryLocation(deliveryType);
      setDeliveryDetails(delivery);
      updateOrder("customerId", delivery!.payer.rrdi);
      updateOrder("deliveryCustomerId", delivery!.rrdi);
    }
  }, [deliveryType, deliveryPoint, getDeliveryLocation, updateOrder]);

  const mapDelivery = (deliveryPoints: TDeliveryPoint[]): any[] => {
    return deliveryPoints.map((point, index) => {
      const key = `${point.displayName}-${index}`;
      return {
        key,
        title: point.displayName,
        description: point.displayAddress,
        point,
        discountDRZPercentage:
          point.stock?.discountDRZPercentage ||
          point.turboPro?.discountDRZPercentage ||
          point.urgent?.discountDRZPercentage,
      };
    });
  };

  const getCodes = (codes: any): Object => {
    let obj: any = {};
    for (let key in codes) {
      if (codes[key] !== null) {
        obj[key] = codes[key].value;
      }
    }
    return obj;
  };

  const handleSummaryOrder = (): void => {
    const orderData = { ...order };
    orderData.parts = lines.map((line) => ({
      referenceID: line.referenceId,
      netPriceListPrice: Number(line.pvpPrice),
      pricePNR: Number(line.pnrPrice),
      netPrice: Number(line.sellPrice),
      priceBeforeDiscount: Number(line.pnrPrice),
      quantity: Number(line.quantity),
      promoCode: line.promoCode || "",
      bonus: Number(line.bonus),
      codes: line.codes ? getCodes(line.codes) : undefined,
      type: line.orderLineType,
      priceDRZ: line.priceDRZ,
    }));
    if (promoApplicable) {
      applyPromotion(orderData);
    } else {
      setSummary(true);
    }
  };

  useEffect(() => {
    if (promoApplied) {
      setSummary(true);
    }
  }, [promoApplied]);

  const handlePlaceOrder = (): void => {
    const orderData = { ...order };
    orderData.parts = lines.map((line) => ({
      referenceID: line.referenceId,
      netPrice: Number(line.sellPrice),
      pricePVP: Number(line.pvpPrice),
      quantity: Number(line.quantity),
      promoCode: line.promoCode || "",
      bonus: Number(line.bonus) || undefined,
      codes: line.codes ? getCodes(line.codes) : undefined,
      type: line.orderLineType,
      priceDRZ: line.priceDRZ,
    }));
    placeOrder(orderData);
    setOrder({} as TOrderCreate);
    setSummary(false);
    resetOrder();
  };

  const handleBack = (): void => {
    setSummary(false);
    clearPromotion();
  };

  const handleCancel = (): void => {
    handleBack();
    setCancel(true);
  };

  useEffect(() => {
    if (tableEl.current && lineAdded) {
      tableEl.current.scrollTop = 9999;
      setLineAdded(false);
    }
  }, [lineAdded]);

  const isValidForm = (): string | undefined => {
    if (!order.type || !order.customerId || !order.deliveryCustomerId) {
      return "Nie wybrano typu zamówienia lub odbiorcy.";
    }

    if (
      deliveryDetails &&
      (deliveryDetails.blocked || deliveryDetails.payer.blocked)
    ) {
      return "Wybrany kontrahent jest zablokowany.";
    }

    if (lines.length === 0) {
      return "Zamówienie musi mieć co najmniej jedną pozycję.";
    }

    const someInvalid = lines.some((line) => {
      return line.referenceId === "";
    });

    if (someInvalid) {
      return "Zamówienie nie może posiadać pustej pozycji.";
    }

    const requireCoding = lines.some((line) => {
      if (line.codes) {
        return !!Object.values(line.codes).find(
          (code) => code && code.required && !code.value
        );
      }
      return line.codes;
    });

    if (requireCoding) {
      return "Jedna lub więcej referencji wymaga podania parametrów kodowania.";
    }
  };

  const closeModal = (): void => {
    setOpenModal(false);
  };

  const confirmChangeOrder = (value?: any): void => {
    setRecentOrder(value);
    setOrder((order) => ({ ...order, type: value }));
    setLines([]);
    setOpenConfirmModal(false);
  };

  const discardChangeOrder = (): void => {
    setRecentOrder(String(order.type));
    setOpenConfirmModal(false);
  };

  const formatDeliveryAddress = (details: DeliveryLocation): JSX.Element => {
    return (
      <>
        {details.street} {details.number}
        <br />
        {details.zipcode} {details.city}
      </>
    );
  };

  if (created) {
    return <Redirect to={`/order/${created}`} />;
  }

  const shrink: boolean = order.type === "WARRANTY";

  const errorMessage = isValidForm();

  // eslint-disable-next-line no-restricted-globals
  if (location.search && initClientId) {
    return <Redirect to="/order/create" />;
  }

  if (cancel) {
    return <Redirect to="/orders" />;
  }

  return (
    <>
      <PageMainHeader
        handleBack={handleBack}
        handleCancel={handleCancel}
        handlePlaceOrder={handlePlaceOrder}
        handleSummaryOrder={handleSummaryOrder}
        summary={summary}
        creating={creating}
        errorMessage={errorMessage}
      />
      <Grid
        style={{ marginBottom: "unset" }}
        className="uber-content"
        stretched
      >
        <DetailCardType
          dictionaries={dictionaries}
          shrink={shrink}
          summary={summary}
          openConfirmModal={openConfirmModal}
          order={order}
          updateOrder={updateOrder}
          discardChangeOrder={discardChangeOrder}
          confirmChangeOrder={confirmChangeOrder}
          currentOrder={currentOrder}
          setForced={setForced}
          deliveryPoint={deliveryPoint}
          selectedCustomer={selectedCustomer}
        />
        <CardDetailCustomer
          mapDelivery={mapDelivery}
          selectDeliveryPoint={selectDeliveryPoint}
          formatDeliveryAddress={formatDeliveryAddress}
          summary={summary}
          shrink={shrink}
          order={order}
          deliveryDetails={deliveryDetails}
        />
        <CardDetailsDelivery
          shrink={shrink}
          getDeliveryLocation={getDeliveryLocation}
          setDeliveryType={setDeliveryType}
          summary={summary}
          dictionaries={dictionaries}
          deliveryType={deliveryType}
          deliveryPoint={deliveryPoint}
        />
        <CardDetailsAdnotation
          updateOrder={updateOrder}
          summary={summary}
          shrink={shrink}
          order={order}
        />
        <CardDetailsHandling
          updateOrder={updateOrder}
          serviceNotesError={serviceNotesError}
          summary={summary}
          shrink={shrink}
          order={order}
        />
        <CardDetailsLimit
          selectedCustomer={selectedCustomer}
          deliveryPoint={deliveryPoint}
        />
        {(userHaveLdcUiOrderBonusRole ||
          userHaveLdcUiOrderSpecialRole ||
          userHaveLdcUiOrderBonusPLUSRole) &&
          (order.type === ORDER_TYPE_ENUM.BONUS ||
            order.type === ORDER_TYPE_ENUM.SPECIAL ||
            order.type === ORDER_TYPE_ENUM.BONUS_PLUS) && (
            <CardDetailsBonusAndSpecialLimits
              limit={limit}
              userName={userName}
            />
          )}
        {order.type === ORDER_TYPE_ENUM.WARRANTY && (
          <CardDetailsWarranty
            order={order}
            summary={summary}
            updateOrder={updateOrder}
          />
        )}
        <CardDetailsLines
          limit={limit}
          setMaximized={setMaximized}
          setOpenModal={setOpenModal}
          tableEl={tableEl}
          deliveryPoint={deliveryPoint}
          summary={summary}
          calculateSellPrice={calculateSellPrice}
          closeModal={closeModal}
          currLimit={currLimit}
          lines={lines}
          maximized={maximized}
          openModal={openModal}
          order={order}
          setLineAdded={setLineAdded}
          setLines={setLines}
        />
      </Grid>
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  customers,
  pricing,
  orders,
  dictionaries,
  users,
}: ApplicationState) => ({
  created: orders.created,
  creating: orders.creating,
  promoApplied: orders.promoApplied,
  promoOrder: orders.promoOrder,
  loadingLines: orders.loadingLines,
  loadingDelivery: customers.loadingDeliveryPoints,
  parsedLines: orders.parsedLines,
  deliveryPoints: customers.deliveryPoints,
  dictionaries,
  sparePart: pricing.sparePart,
  selectedCustomer: customers.selected,
  loadingUser: users.loadingUser,
});

const mapDispatchToProps: TReduxActions = {
  placeOrder,
  applyPromotion,
  clearPromotion,
  fetchDictionary,
  fetchDeliveryPoints,
  fetchPriceListSparePart,
  selectCustomer,
  loadLinesLoaded,
  fetchUserById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);
