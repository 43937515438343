import { Table } from 'semantic-ui-react';
import { parseDate } from '~/utils/dateUtils';
import React from 'react';
import { HistoryListProps } from '~/pages/Customers/Details/lib/card/CurrentPayerCard/HistoryList/constants';

export const HistoryList = ({ historyList, formatMessage }: HistoryListProps) => {
  return (
    <Table basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.oldAccountingCustomerID')}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.currentAccountingCustomerID')}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.validFrom')}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.creationComment')}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.creationTime')}</Table.HeaderCell>
          <Table.HeaderCell>{formatMessage('app.customer.currentPayer.authorUsername')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {historyList.map((historyItem) => (
          <Table.Row key={historyItem.oldAccountingCustomerID}>
            <Table.Cell>{historyItem.oldAccountingCustomerID}</Table.Cell>
            <Table.Cell>{historyItem.currentAccountingCustomerID}</Table.Cell>
            <Table.Cell>{parseDate(historyItem.validFrom)}</Table.Cell>
            <Table.Cell>{historyItem.creationComment}</Table.Cell>
            <Table.Cell>{parseDate(historyItem.creationTime)}</Table.Cell>
            <Table.Cell>{historyItem.authorUsername}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
