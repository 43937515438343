import { TBaseOptProps } from "~/components/SmartField/lib/types";
import React from "react";

export const useLabelFormatter = (props: TBaseOptProps) => {

    const formatLabel = (): JSX.Element | string | undefined => {
        if (props.label) {
            return props.light ? props.label : <label>{props.label}</label>
        }
    }

    return { formatLabel }
}