import React from "react";
import { Grid } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import translations from "~/utils/translations";

type NotPermissionInfoProps = {
  name: string;
};

export const NotPermissionInfo = ({ name }: NotPermissionInfoProps) => (
  <Grid>
    <FormattedMessage
      id="app.not.permission.info"
      defaultMessage="app.not.permission.info"
      values={{ name: <strong>{translations.format(name)}</strong> }}
    />
  </Grid>
);
