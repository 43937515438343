import { useContext } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useSparePartsRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiProductEditRole: boolean =
    context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_PRODUCT_EDIT);

  return { userHaveLdcUiProductEditRole };
};
