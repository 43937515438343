
export const textInformation = (total: number): string => {
    if(total === 0 || total > 5){
        return 'rekordów'
    }
    else if(total === 1){
        return 'rekord'
    }
    else{
        return 'rekordy'
    }
}