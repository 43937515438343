import React, { useEffect, useState } from "react";

import { Button, Grid, Header, Input, Modal, Popup } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CodingCell from "~/pages/Backorders/Details/lib/CodingCell";
import { MappedCodedPart } from "~/store/coded-parts/types";
import { DictionaryItem } from "~/store/dictionaries/types";
import { parseDate } from "~/utils/dateUtils";
import RenderLabel from "./RenderLabel";
import translations from "~/utils/translations";
import { Link } from "react-router-dom";

type DetailsModalProps = {
  selectedCodedPart: MappedCodedPart;
  isModalOpen: boolean;
  closeModal: () => void;
  statusDictionary: DictionaryItem[];
  modalWithProcess: boolean;
  loadingCompleteCodedPart: boolean;
  ldcOrderId?: string;
  handleCompleteCodedPart: (params: {
    orderId: string;
    lineNr: string;
    CSPSOrderNr: string;
  }) => void;
};

type Codes = {
  vin: string;
  cleCode: string;
  transmitterCode: string;
  nreCode: string;
  wheelCode: string;
  tiresCode: string;
};

const DetailsModal: React.FC<DetailsModalProps> = ({
  selectedCodedPart,
  isModalOpen,
  closeModal,
  statusDictionary,
  modalWithProcess,
  loadingCompleteCodedPart,
  ldcOrderId,
  handleCompleteCodedPart,
}) => {
  useEffect(() => {
    setNewCSPSORderNr("");
  }, [isModalOpen]);

  const CSPSOrderNrValidationValues = {
    maxLength: 10,
    minLength: 1,
  };

  const checkEmptyCodes = (codes: Codes) => {
    return (
      codes.vin ||
      codes.cleCode ||
      codes.nreCode ||
      codes.tiresCode ||
      codes.transmitterCode ||
      codes.wheelCode
    );
  };
  const validateCSPSOrderNr = (): boolean => {
    return !(
      newCSPSOrderNr.length >= CSPSOrderNrValidationValues.minLength &&
      newCSPSOrderNr.length <= CSPSOrderNrValidationValues.maxLength
    );
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const noWhiteSpacesValue = data.value.replaceAll(/\s/g, "");
    if (noWhiteSpacesValue.length <= CSPSOrderNrValidationValues.maxLength) {
      setNewCSPSORderNr(noWhiteSpacesValue);
    } else {
      const cuttedValue = noWhiteSpacesValue.slice(
        0,
        CSPSOrderNrValidationValues.maxLength
      );
      setNewCSPSORderNr(cuttedValue);
    }
  };

  const saveRealisation = () => {
    const params = {
      orderId: selectedCodedPart.id,
      lineNr: selectedCodedPart.lineNumber,
      CSPSOrderNr: newCSPSOrderNr,
    };
    handleCompleteCodedPart(params);
  };

  const renderRealisationButton = (): JSX.Element => (
    <Button
      primary
      content={translations.format("app.manual-orders.complete")}
      icon="checkmark"
      labelPosition="right"
      loading={loadingCompleteCodedPart}
      disabled={validateCSPSOrderNr()}
      onClick={() => {
        saveRealisation();
      }}
    />
  );

  const [newCSPSOrderNr, setNewCSPSORderNr] = useState("");

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      size={modalWithProcess ? "small" : "large"}
      closeOnDimmerClick={false}
    >
      <Header
        icon="shop"
        content={
          translations.format("app.manual-orders.coded-part") +
          " " +
          (selectedCodedPart && selectedCodedPart.referenceID)
        }
      />
      <Modal.Content>
        {selectedCodedPart && !modalWithProcess && (
          <>
            <DetailCard
              title={translations.format("app.manual-orders.details")}
              width={16}
              id={selectedCodedPart.id + "card1"}
            >
              <Grid columns={2}>
                <Grid.Column>
                  <RenderLabel
                    name={translations.format("app.manual-orders.reference")}
                    value={selectedCodedPart.referenceID}
                  />
                  {selectedCodedPart.businessSector &&
                    selectedCodedPart.supplierSystemClientId && (
                      <RenderLabel
                        name={
                          selectedCodedPart.businessSector +
                          " " +
                          translations.format("app.paymentPerson").toLowerCase()
                        }
                        value={selectedCodedPart.supplierSystemClientId}
                      />
                    )}
                  {selectedCodedPart.businessSector &&
                    selectedCodedPart.supplierSystemDeliveryId && (
                      <RenderLabel
                        name={
                          selectedCodedPart.businessSector +
                          " " +
                          translations
                            .format("app.customer.delivery")
                            .toLowerCase()
                        }
                        value={selectedCodedPart.supplierSystemDeliveryId}
                      />
                    )}
                  <RenderLabel
                    name={translations.format("app.manual-orders.LDC-order")}
                    value={selectedCodedPart.orderNumber}
                  />
                </Grid.Column>
                <Grid.Column>
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.LDC-order-line-nr"
                    )}
                    value={selectedCodedPart.lineNumber}
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.LDC-order-date"
                    )}
                    value={parseDate(selectedCodedPart.ldcOrderDate) || "-"}
                  />
                  <RenderLabel
                    name={translations.format("app.manual-orders.coding-type")}
                    value={selectedCodedPart.categoryIdentifier}
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.realisation-status"
                    )}
                    value={
                      statusDictionary && statusDictionary.length
                        ? statusDictionary.find(
                            (item) => item.key === selectedCodedPart.status
                          )?.text
                        : ""
                    }
                  />
                  <RenderLabel
                    name={translations.format("app.manual-orders.codes")}
                    value={
                      selectedCodedPart.codes &&
                      checkEmptyCodes(selectedCodedPart.codes) ? (
                        <CodingCell
                          line={selectedCodedPart}
                          loading={false}
                          addCodingHandler={() => {}}
                          editMode={false}
                          disablePopup
                        />
                      ) : (
                        ""
                      )
                    }
                  />
                </Grid.Column>
              </Grid>
            </DetailCard>
            <DetailCard
              title="CSPS"
              width={16}
              style={{ minHeight: "auto", height: "auto" }}
              id={selectedCodedPart.orderedDate + "card2"}
            >
              <Grid columns={2}>
                <Grid.Column>
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.CSPS-order-nr"
                    )}
                    value={selectedCodedPart.supplierOrderNumber || "-"}
                    copyButton
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.CSPS-order-date"
                    )}
                    value={
                      selectedCodedPart.orderedDate
                        ? parseDate(selectedCodedPart.orderedDate)
                        : ""
                    }
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.CSPS-order-user"
                    )}
                    value={selectedCodedPart.orderedBy || "-"}
                  />
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid>
            </DetailCard>
          </>
        )}
        {selectedCodedPart && modalWithProcess && (
          <>
            <DetailCard
              title={translations.format(
                "app.manual-orders.CSPS-complete-line"
              )}
              id={"app.manual-orders.CSPS-complete-line"}
              width={16}
            >
              <Grid columns={1}>
                <Grid.Column>
                  <RenderLabel
                    name={translations.format("app.manual-orders.reference")}
                    value={selectedCodedPart.referenceID}
                  />
                  {selectedCodedPart.businessSector &&
                    selectedCodedPart.supplierSystemClientId && (
                      <RenderLabel
                        name={
                          selectedCodedPart.businessSector +
                          " " +
                          translations.format("app.paymentPerson").toLowerCase()
                        }
                        value={selectedCodedPart.supplierSystemClientId}
                      />
                    )}
                  {selectedCodedPart.businessSector &&
                    selectedCodedPart.supplierSystemDeliveryId && (
                      <RenderLabel
                        name={
                          selectedCodedPart.businessSector +
                          " " +
                          translations
                            .format("app.customer.delivery")
                            .toLowerCase()
                        }
                        value={selectedCodedPart.supplierSystemDeliveryId}
                      />
                    )}
                  <RenderLabel
                    name={translations.format("app.manual-orders.LDC-order")}
                    value={selectedCodedPart.orderNumber}
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.LDC-order-line-nr"
                    )}
                    value={selectedCodedPart.lineNumber}
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.LDC-order-date"
                    )}
                    value={parseDate(selectedCodedPart.ldcOrderDate)}
                  />
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.realisation-status"
                    )}
                    value={
                      statusDictionary && statusDictionary.length
                        ? statusDictionary.find(
                            (item) => item.key === selectedCodedPart.status
                          )?.text
                        : ""
                    }
                  />
                  <RenderLabel
                    name={translations.format("app.manual-orders.coding-type")}
                    value={selectedCodedPart.categoryIdentifier}
                  />
                  <RenderLabel
                    name={translations.format("app.manual-orders.codes")}
                    value={
                      selectedCodedPart.codes &&
                      checkEmptyCodes(selectedCodedPart.codes) ? (
                        <CodingCell
                          line={selectedCodedPart}
                          loading={false}
                          addCodingHandler={() => {}}
                          editMode={false}
                          disablePopup
                        />
                      ) : (
                        ""
                      )
                    }
                  />
                  <div
                    style={{
                      width: "70%",
                      fontSize: 14,
                      fontWeight: 800,
                      marginTop: 24,
                      marginBottom: 24,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <p>
                      {translations.format(
                        "app.manual-orders.complete-CSPS-message-1"
                      )}
                    </p>
                  </div>
                  <RenderLabel
                    name={translations.format(
                      "app.manual-orders.CSPS-order-nr"
                    )}
                    value={
                      <div style={{ position: "relative" }}>
                        <Input
                          type="text"
                          onChange={(e, data) => onInputChange(e, data)}
                          value={newCSPSOrderNr}
                          focus
                        />
                        {newCSPSOrderNr.length > 0 && (
                          <Button
                            size="tiny"
                            style={{ position: "absolute", top: 4, right: 0 }}
                            icon="x"
                            onClick={() => setNewCSPSORderNr("")}
                          />
                        )}
                      </div>
                    }
                    editable
                  />
                </Grid.Column>
              </Grid>
            </DetailCard>
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        {modalWithProcess &&
          (validateCSPSOrderNr() ? (
            <Popup
              position="bottom right"
              trigger={
                <div style={{ display: "inline-block" }}>
                  {renderRealisationButton()}
                </div>
              }
            >
              {validateCSPSOrderNr() &&
                translations.format(
                  "app.manual-orders.complete-validation-info",
                  {
                    minCharQuantity: CSPSOrderNrValidationValues.minLength,
                    maxCharQuantity: CSPSOrderNrValidationValues.maxLength,
                  }
                )}
            </Popup>
          ) : (
            renderRealisationButton()
          ))}
        {ldcOrderId ? (
          <Button
            content={translations.format("app.manual-orders.back")}
            onClick={() => closeModal()}
            as={Link}
            to={`/order/${ldcOrderId}`}
          />
        ) : (
          <Button
            content={translations.format("app.manual-orders.close")}
            onClick={() => closeModal()}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default DetailsModal;
