import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { callApi } from "~/utils/api";
import translations from "~/utils/translations";
import { SearchProps } from "semantic-ui-react";
import { TProps } from "~/components/UberSearch/lib/types";
import { debounce } from "lodash";
import { toastError } from "~/utils/toast";

export const useUberSearch = (props: TProps) => {
  const [query, setQuery] = useState(props.value || "");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>([]);
  const lastQuery = useRef<string>(props.value || "");
  const [debouncedPending, setDebouncedPending] = useState(false);

  const {
    endpoint,
    searchParameterName = "query",
    queryParams = [],
    mapper,
    onResultSelect,
  } = props;

  const searchChangeHandler = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setQuery(data.value || "");
  };

  const showError = useCallback((error: any, info?: string) => {
    toastError(
      "Błąd",
      error.message ||
        info ||
        translations.format("app.error.order.select-spare-part")
    );
  }, []);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (queryValue: string) => {
      try {
        const res = await callApi(
          "GET",
          `${endpoint}?${searchParameterName}=${queryValue}${queryParams
            .map(
              (param: { name: any; value: any }) =>
                `&${param.name}=${param.value}`
            )
            .join("")}`
        );
        setResults(mapper(res));
        setLoading(false);
        setDebouncedPending(false);
      } catch (e) {
        showError(e);
        setResults([]);
        setLoading(false);
        setDebouncedPending(false);
      }
    }, props.debounce),
    []
  );

  const search = useCallback(
    async (queryValue) => {
      try {
        const res = await callApi(
          "GET",
          `${endpoint}?${searchParameterName}=${queryValue}${queryParams
            .map(
              (param: { name: any; value: any }) =>
                `&${param.name}=${param.value}`
            )
            .join("")}`
        );
        setResults(mapper(res));
        setLoading(false);
        setDebouncedPending(false);
      } catch (e) {
        showError(e);
        setResults([]);
        setLoading(false);
      }
    },
    [endpoint, mapper, queryParams, searchParameterName, showError]
  );

  const call = useCallback(async (): Promise<void> => {
    try {
      if (debouncedPending) return;
      if (props.debounce) {
        setDebouncedPending(true);
        debouncedSearch(query);
      } else {
        search(query);
      }
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  }, [
    query,
    debouncedSearch,
    props.debounce,
    search,
    debouncedPending,
    showError,
  ]);

  const resultSelectHandler = (
    event: SyntheticEvent,
    data: SearchProps
  ): void => {
    setQuery(onResultSelect(data.result));
  };

  useEffect(() => {
    if (query.length) {
      lastQuery.current = query;
      setLoading(true);
      if (!loading && query.length > 0 && query !== lastQuery.current) {
        setLoading(true);
      }
    } else {
      lastQuery.current = query;
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (loading && query.length > 0) {
      (async () => call())();
    }
    // eslint-disable-next-line
  }, [loading]);

  return {
    searchChangeHandler,
    resultSelectHandler,
    loading,
    results,
    query,
  };
};
