import { Reducer } from "redux";
import { ClaimsActionTypes, ClaimsState, PaginableClaim } from "./types";
import { ErrorActionTypes } from "~/store/error/types";

export const initialClaimsState: ClaimsState = {
  loadingClaims: false,
  loadingClaim: false,
  list: [],
  searchList: [],
  meta: {
    page: 1,
    size: 15,
    pages: 1,
  },
  selected: undefined,
  created: false,
  creating: false,
  uploadedAttachment: false,
  uploadingAttachment: false,
  transitionChanged: false,
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  uploadingCollectiveTask: false,
  uploadedCollectiveTask: false,
  collectiveTaskUploadedData: {
    claimsCreated: [],
    errors: [],
  },
  fetchBackInTheBoxReportPending: false,
  chatTargetGroups: undefined,
  chatTargetGroupsLoading: false,
  innerMessageSending: false,
  innerTransitionPending: false,
};

const reducer: Reducer<ClaimsState> = (
  state = initialClaimsState,
  action
): ClaimsState => {
  switch (action.type) {
    case ClaimsActionTypes.FETCH_CLAIMS: {
      return { ...state, loadingClaims: true, list: [] };
    }
    case ClaimsActionTypes.FETCH_CLAIMS_SUCCESS: {
      const payload = action.payload as PaginableClaim;
      return {
        ...state,
        loadingClaims: false,
        list: payload.content,
        searchList: payload.content,
        meta: {
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case ClaimsActionTypes.FETCH_CLAIMS_SEARCH_SUCCESS: {
      return { ...state, searchList: action.payload.content };
    }
    case ClaimsActionTypes.CREATE_CLAIM: {
      return { ...state, created: false, creating: true };
    }
    case ClaimsActionTypes.SELECT_CLAIM: {
      return { ...state, loadingClaim: true, selected: undefined };
    }
    case ClaimsActionTypes.SELECTED_CLAIM: {
      return { ...state, loadingClaim: false, selected: action.payload };
    }
    case ClaimsActionTypes.REFRESH_CLAIM_SUCCESS: {
      return { ...state, selected: action.payload };
    }
    case ClaimsActionTypes.CLEAR_SELECTED_CLAIM: {
      return { ...state, selected: undefined };
    }
    case ClaimsActionTypes.CREATE_CLAIM_SUCCESS: {
      return { ...state, created: true, creating: false };
    }
    case ClaimsActionTypes.CLEAR_CREATED: {
      return { ...state, created: false, creating: false };
    }
    case ClaimsActionTypes.ADD_ATTACHMENT: {
      return { ...state, uploadingAttachment: true };
    }
    case ClaimsActionTypes.ADD_ATTACHMENT_SUCCESS: {
      return { ...state, uploadingAttachment: false, uploadedAttachment: true };
    }
    case ClaimsActionTypes.GET_ATTACHMENT: {
      return { ...state };
    }
    case ClaimsActionTypes.CREATE_CLAIM_TRANSITION_SUCCESS: {
      return { ...state, transitionChanged: true };
    }
    case ClaimsActionTypes.CLEAR_TRANSITION_CLAIM: {
      return { ...state, transitionChanged: false };
    }
    case ClaimsActionTypes.CLEAR_ATTACHMENT: {
      return { ...state, uploadedAttachment: false };
    }
    case ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK: {
      return { ...state, uploadingCollectiveTask: true };
    }
    case ClaimsActionTypes.FETCH_BACK_BOX_REPORT: {
      return { ...state, fetchBackInTheBoxReportPending: true };
    }
    case ClaimsActionTypes.FETCH_BACK_BOX_REPORT_SUCCESS: {
      return { ...state, fetchBackInTheBoxReportPending: false };
    }
    case ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK_SUCCESS: {
      return {
        ...state,
        uploadingCollectiveTask: false,
        uploadedCollectiveTask: true,
        collectiveTaskUploadedData: {
          claimsCreated: action.payload.claimsCreated,
          errors: action.payload.errors,
        },
      };
    }
    case ClaimsActionTypes.CLEAR_COLLECTIVE_DATA: {
      return {
        ...state,
        uploadingCollectiveTask: false,
        uploadedCollectiveTask: false,
        collectiveTaskUploadedData: {
          claimsCreated: [],
          errors: [],
        },
      };
    }
    case ClaimsActionTypes.FETCH_CHAT_TARGET_GROUPS: {
      return {
        ...state,
        chatTargetGroupsLoading: true,
      };
    }
    case ClaimsActionTypes.FETCH_CHAT_TARGET_GROUPS_SUCCESS: {
      return {
        ...state,
        chatTargetGroupsLoading: false,
        chatTargetGroups: action.payload.chatTargetGroups,
      };
    }
    case ClaimsActionTypes.SEND_INNER_MESSAGE: {
      return { ...state, innerMessageSending: true };
    }
    case ClaimsActionTypes.SEND_INNER_MESSAGE_SUCCESS: {
      return { ...state, innerMessageSending: false };
    }
    case ClaimsActionTypes.INNER_TRANSITION_PROCESSING:
    case ClaimsActionTypes.COST_COVERING_GROUP_PROCESS: {
      return { ...state, innerTransitionPending: true };
    }
    case ClaimsActionTypes.INNER_TRANSITION_PROCESSING_SUCCESS:
    case ClaimsActionTypes.COST_COVERING_GROUP_PROCESS_SUCCESS: {
      return { ...state, innerTransitionPending: false };
    }
    case ClaimsActionTypes.SET_AS_UNREAD_SUCCESS: {
      return {
        ...state,
        selected: state.selected
          ? { ...state.selected, readStatus: true }
          : undefined,
      };
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        loadingClaim: false,
        loadingClaims: false,
        uploadedCollectiveTask: false,
        uploadingCollectiveTask: false,
        uploadedAttachment: false,
        uploadingAttachment: false,
        creating: false,
      };
    }
  }
  return state;
};

export { reducer as claimsReducer };
