export enum CardKey {
  identification = 'identification',
  personality = 'personality',
  characteristic = 'characteristic',
  contact = 'contact',
  docsDistribution = 'docsDistribution',
  address = 'address',
  activePaymentForm = 'activePaymentForm',
  addPaymentForm = 'addPaymentForm',
  warehouse0 = 'warehouse-0',
  warehouse1 = 'warehouse-1',
  warehouse2 = 'warehouse-2',
  warehouse3 = 'warehouse-3',
  addWarehouse = 'addWarehouse',
  paymentPerson = 'paymentPerson',
  bank = 'bank',
  permissions = 'permissions',
  adnotation = 'adnotation',
  userAccount = 'userAccount',
  userStat = 'userStat',
  factor0 = 'factor-0',
  factor1 = 'factor-1',
  factor2 = 'factor-2',
  factor3 = 'factor-3',
  addFactor = 'addFactor',
  distrigoLoanLimit = 'distrigoLoanLimit',
  addNewLoan = 'addNewLoan',
  creditRules = 'creditRules',
  changePayer = 'changePayer',
  payerDetails = 'payerDetails',
  classesAndBrands = 'classesAndBRands',
  brandsCodes = 'brandsCodes',
  additionalDiscountCode = 'additionalDiscountCode',
}
