import { connect } from "react-redux";
import { Button, Form, FormGroup, Header, Modal } from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { ModelInput } from "~/components/ModelForm/ModelInput";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import {
  EMAIL,
  MAX,
  MIN,
  REQUIRED,
} from "~/components/SmartField/lib/validator";
import { ApplicationState } from "~/store";
import {
  addUser,
  clearCreated,
  fetchClientPanelDeliveryPoints,
  fetchPasswordPolicies,
} from "~/store/users/actions";
import { PasswordPolicy, UserBase } from "~/store/users/types";

import React, { useEffect, useState } from "react";
import { ModelMessage } from "~/components/ModelForm/ModelMessage";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";

type TReduxState = {
  created?: boolean;
  creating?: boolean;
  passwordPolicies: PasswordPolicy[];
  deliveryPoints: any[];
  loadingDeliveryPoints: boolean;
};

type TReduxActions = {
  addUser: typeof addUser;
  clearCreated: typeof clearCreated;
  fetchPasswordPolicies: typeof fetchPasswordPolicies;
  fetchClientPanelDeliveryPoints: typeof fetchClientPanelDeliveryPoints;
};

type TProps = {
  triggerOpen: boolean;
  createdSuccess: () => void;
  handleClose: () => void;
};

type TDTO = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  enabled: boolean;
  credentials: { type: "password"; value: string }[];
};

type TSuperAdminDTO = TDTO & { payerId: string };
type TLocalAdminDTO = TDTO & { rrdis: string[] };

type TAddContractorProps = TReduxState & TReduxActions & TProps;

const AddUser: React.FC<TAddContractorProps> = ({
  children,
  triggerOpen,
  created,
  creating,
  passwordPolicies,
  // deliveryPoints,
  addUser,
  createdSuccess,
  clearCreated,
  fetchPasswordPolicies,
  handleClose,
  fetchClientPanelDeliveryPoints,
}) => {
  const [open, setOpen] = useState(false);

  const { isSuperAdmin, isLocalAdmin, isClientPanelUser } =
    useClientPanelRoles();

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  useEffect(() => {
    if (open) {
      fetchPasswordPolicies(isClientPanelUser);
      if (isLocalAdmin) {
        fetchClientPanelDeliveryPoints();
      }
    }
    // eslint-disable-next-line
  }, [open, isClientPanelUser]);

  const handleConfirm = (user: UserBase): void => {
    if (!creating) {
      let DTO: TDTO = {
        email: user.email,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        emailVerified: true,
        enabled: true,
        credentials: [
          { type: "password", value: user.password ? user.password : "" },
        ],
      };
      if (isSuperAdmin) {
        DTO = { ...DTO, payerId: user.payerId } as TSuperAdminDTO;
      }
      if (isLocalAdmin) {
        DTO = { ...DTO, rrdis: user.rrdis } as TLocalAdminDTO;
      }
      addUser(DTO, isClientPanelUser);
    }
  };

  const handleCancel = (): void => {
    if (!creating) {
      setOpen(false);
      handleClose();
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  /* hidden on customers request */
  // const mapDeliveryPointsAddress = useCallback(() => {
  //   return deliveryPoints.map((item) => ({
  //     text: `${item.name}; ${item.city || ""} ${item.street || ""} ${item.number || ""}`,
  //     value: item.rrdi,
  //   }));
  // }, [deliveryPoints]);

  if (created && open) {
    clearCreated();
    createdSuccess();
    handleCancel();
  }

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper<UserBase> onSubmit={handleConfirm}>
        <Header icon="user add" content="Dodaj użytkownika" />
        <Modal.Content>
          <Form style={{ padding: 10 }}>
            <Form.Group widths="equal">
              <ModelInput<UserBase>
                label="E-mail"
                type="email"
                placeholder="Adres e-mail użytkownika"
                name="email"
                validators={[REQUIRED, MAX(100), EMAIL]}
              />
            </Form.Group>
            <FormGroup widths="equal">
              <ModelInput<UserBase>
                label="Imię"
                placeholder="Imię użytkownika"
                name="firstName"
                validators={[REQUIRED, MAX(30)]}
              />
              <ModelInput<UserBase>
                label="Nazwisko"
                placeholder="Nazwisko użytkownika"
                name="lastName"
                validators={[REQUIRED, MAX(30)]}
              />
            </FormGroup>
            <ModelInput<UserBase>
              label="Nazwa"
              placeholder="Nazwa użytkownika"
              name="username"
              validators={[REQUIRED, MAX(30)]}
            />
            <ModelInput<UserBase>
              label="Hasło"
              type="password"
              placeholder="Hasło użytkownika"
              name="password"
              validators={[REQUIRED, MIN(8)]}
            />
            {isSuperAdmin ? (
              <ModelInput<UserBase>
                label="Płatnik"
                placeholder="Płatnik"
                name="payerId"
                validators={[REQUIRED]}
                searchUrl="/customers/payers"
                required={true}
              />
            ) : null}
            {/* hidden on customers request */}
            {/* {isLocalAdmin ? (
              <ModelInput<UserBase>
                label="Punkty odbioru"
                placeholder="Punkty odbioru"
                name="rrdis"
                multiselectList={mapDeliveryPointsAddress()}
              />
            ) : null} */}
            <ModelMessage
              headline="Hasło:"
              passwordPolicies={passwordPolicies}
              name="message"
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            content="Dodaj"
            icon="add"
            labelPosition="right"
            primary
            loading={creating}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  users,
}: ApplicationState) => {
  return {
    created: users.created,
    creating: users.creating,
    passwordPolicies: users.passwordPolicies,
    deliveryPoints: users.deliveryPoints,
    loadingDeliveryPoints: users.loadingDeliveryPoints,
  };
};

const mapDispatchToProps: TReduxActions = {
  addUser,
  clearCreated,
  fetchPasswordPolicies,
  fetchClientPanelDeliveryPoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
