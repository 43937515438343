import { action } from 'typesafe-actions'
import { Backorder, BackorderLine, BackordersActionTypes, ModifyLinePayload, PaginableBackorder, PaginableBackorderLine, PaginationParams } from './types'

export const fetchBackorders = (params: any) => action<BackordersActionTypes, any>(BackordersActionTypes.FETCH_REQUEST, params)
export const fetchBackordersSearch = (params: any) => action<BackordersActionTypes, any>(BackordersActionTypes.FETCH_SEARCH_REQUEST, params)
export const fetchSuccess = (backorders: PaginableBackorder) => action<BackordersActionTypes, PaginableBackorder>(BackordersActionTypes.FETCH_SUCCESS, backorders)
export const fetchSearchSuccess = (backorders: PaginableBackorder) => action<BackordersActionTypes, PaginableBackorder>(BackordersActionTypes.FETCH_SEARCH_SUCCESS, backorders)

export const selectBackorder = (id: string) => action(BackordersActionTypes.SELECT, id)
export const backorderSelected = (backorder: Backorder) => action(BackordersActionTypes.SELECTED, backorder)
export const clearSelected = () => action<BackordersActionTypes>(BackordersActionTypes.CLEAR_SELECTED)

// export const addBackorder = (backorder: IBackorderCreate) => action(BackordersActionTypes.ADD_BACKORDER, backorder)
export const addBackorder = (warehouseId: string, supplier: string) => action(BackordersActionTypes.ADD_BACKORDER, {warehouseId: warehouseId, supplier: supplier})
export const addBackorderSuccess = (id: string) => action(BackordersActionTypes.ADD_BACKORDER_SUCCESS, id)
export const clearCreated = () => action(BackordersActionTypes.CLEAR_CREATED)

export const fetchBackorderLines = (backorderId: string, params: PaginationParams, filters?: any) => action<BackordersActionTypes, { backorderId: string, params: PaginationParams, filters?: any }>(BackordersActionTypes.FETCH_LINES, { backorderId, params, filters })
export const fetchLinesSuccess = (lines: PaginableBackorderLine) => action<BackordersActionTypes, PaginableBackorderLine>(BackordersActionTypes.FETCH_LINES_SUCCESS, lines)

export const addBackorderLine = (backorderId: string, line: BackorderLine) => action<BackordersActionTypes, ModifyLinePayload>(BackordersActionTypes.ADD_LINE, { backorderId, line })
export const updateBackorderLine = (backorderId: string, line: BackorderLine) => action<BackordersActionTypes, ModifyLinePayload>(BackordersActionTypes.UPDATE_LINE, { backorderId, line })
export const deleteBackorderLine = (backorderId: string, line: BackorderLine) => action<BackordersActionTypes, ModifyLinePayload>(BackordersActionTypes.DELETE_LINE, { backorderId, line })
export const loadLinesCsv = (textLines: string) => action<BackordersActionTypes, string>(BackordersActionTypes.LOAD_CSV, textLines)
export const loadLinesExcel = (excelFile: File) => action<BackordersActionTypes, File>(BackordersActionTypes.LOAD_XLS, excelFile)
export const loadLinesLoaded = (lines: BackorderLine[]) => action<BackordersActionTypes, BackorderLine[]>(BackordersActionTypes.LOAD_LINES_LOADED, lines)
export const importBackorderLines = (backorderId: string) => action<BackordersActionTypes, string>(BackordersActionTypes.IMPORT_LINES, backorderId)
export const updateProgress = (progress: number) => action<BackordersActionTypes, number>(BackordersActionTypes.UPDATE_PROGRESS, progress)
export const linesOperationFinished = (success: boolean) => action<BackordersActionTypes, boolean>(BackordersActionTypes.LINES_OPERATION_FINISHED, success)

