import { DictionaryItem } from "~/store/dictionaries/types"

import React from 'react'
import './map-dictionary.scss'
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";


export const mapDictionary = (value: string, dictionary: DictionaryItem[], light?: boolean, hideKey?: boolean, regular?: boolean): JSX.Element => {
    return <DictionaryLabel value={value} dictionary={dictionary} light={light} hideKey={hideKey} regular={regular}/>
}

export default mapDictionary
