import {
  DictionariesState,
  DictionaryName,
  TDetails,
  TDetailsFieldType,
} from "~/store/dictionaries/types";
import {
  addDictionaryItem,
  clearCreated,
  fetchDictionariesNames,
  updateDictionaryItem,
} from "~/store/dictionaries/actions";
import { ComponentProps, SyntheticEvent } from "react";

export type TReduxState = {
  created?: boolean;
  creating?: boolean;
};

export type TReduxAddActions = {
  addDictionaryItem: typeof addDictionaryItem;
  clearCreated: typeof clearCreated;
};

export type TAddProps = {
  createdSuccess: () => void;
  name: DictionaryName;
  details: TDetails;
  triggerOpen: boolean;
  handleClose: () => void;
};

export type TReduxUpdateActions = {
  updateDictionaryItem: typeof updateDictionaryItem;
  clearCreated: typeof clearCreated;
};

interface TUpdateProps extends ComponentProps<any> {
  updatedSuccess: () => void;
  name: DictionaryName;
  itemKey: string;
  details: TDetails;
  itemData: any[] | any;
}

export type TAddDictionaryItemProps = TReduxState &
  TReduxAddActions &
  TAddProps;

export type TUpdateDictionaryItemProps = TReduxState &
  TReduxUpdateActions &
  TUpdateProps;

export enum FieldNameEnum {
  id = "id",
  key = "key",
  label = "label",
  value = "value",
  gui = "gui",
  start = "start",
  end = "end",
  sell = "sell",
  buy = "buy",
  gtu = "gtu",
  formula = "formula",
  type = "type",
  factoring = "factoring",
  dfs = "dfs",
  distrigo = "distrigo",
  days = "days",
  stock = "stock",
  urgent = "urgent",
  turboPro = "turboPro",
  agreementNo = "agreementNo",
  footer = "footer",
  sapCode = "sapCode",
  defaultFooter = "defaultFooter",
  defaultSapCode = "defaultSapCode",
  description = "description",
}

export type InputWrapperProps = {
  name: string;
  type: TDetailsFieldType;
  dictionaryName: DictionaryName;
};

export type CustomDropdownProps = {
  name: string;
  placeholder: string;
  text: string;
};

export type DictionariesT = {
  name: string;
  changeName: (event: SyntheticEvent<Element, Event>, data: any) => void;
  refresh: () => void;
  fetchDictionariesNames: typeof fetchDictionariesNames;
};

export type DictionariesTReduxState = {
  dictionaries: DictionariesState;
};

export type DictionariesProps = DictionariesT & DictionariesTReduxState;
