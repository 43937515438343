import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi } from "~/utils/api";
import { handleError } from "~/store/error/actions";
import {
  createPaymentMethod,
  createPaymentMethodSuccess,
  cancelPaymentMethod,
  cancelPaymentMethodSuccess,
  fetchPaymentMethodById,
  fetchPaymentMethodByIdSuccess,
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  fetchCurrentPaymentMethod,
  fetchCurrentPaymentMethodSuccess,
} from "./actions";
import { CustomerPaymentMethod, PaymentMethodActionTypes } from "./types";
import { toastSuccess } from "~/utils/toast";

function* handleFetchPaymentMethods(
  action: ReturnType<typeof fetchPaymentMethods>
) {
  try {
    const { customerId } = action.payload;
    const response: CustomerPaymentMethod[] = yield call(
      callApi,
      "get",
      `/customers/${customerId}/payment-methods`
    );
    yield put(fetchPaymentMethodsSuccess(response));
  } catch (error) {
    yield put(handleError(error, false));
  }
}

function* handleFetchPaymentMethodById(
  action: ReturnType<typeof fetchPaymentMethodById>
) {
  try {
    const { customerId, paymentMethodId } = action.payload;
    const response: CustomerPaymentMethod = yield call(
      callApi,
      "get",
      `/customers/${customerId}/payment-methods/${paymentMethodId}`
    );

    yield put(fetchPaymentMethodByIdSuccess(response));
  } catch (error) {
    yield put(handleError(error, false));
  }
}

function* handleFetchCurrentPaymentMethod(
  action: ReturnType<typeof fetchCurrentPaymentMethod>
) {
  try {
    const { customerId } = action.payload;
    const response: CustomerPaymentMethod = yield call(
      callApi,
      "get",
      `/customers/${customerId}/payment-methods/current`
    );
    yield put(fetchCurrentPaymentMethodSuccess(response));
  } catch (error) {
    yield put(handleError(error, false));
  }
}

function* handleCreatePaymentMethod(
  action: ReturnType<typeof createPaymentMethod>
) {
  try {
    const { customerPaymentMethodDto, customerId } = action.payload;
    const paymentMethodId = yield call(
      callApi,
      "post",
      `/customers/${customerId}/payment-methods`,
      customerPaymentMethodDto
    );
    yield toastSuccess(
      "app.info.paymentMethod.create.success",
      "app.info.common.success"
    );
    yield put(createPaymentMethodSuccess(paymentMethodId));
    action.payload.refreshAction();
  } catch (error) {
    yield put(handleError(error, false, "Wystąpił błąd", error.message));
  }
}

function* handlePaymentMethodDelete(
  action: ReturnType<typeof cancelPaymentMethod>
) {
  try {
    const { customerId, paymentMethodId } = action.payload;
    yield call(
      callApi,
      "delete",
      `/customers/${customerId}/payment-methods/${paymentMethodId}`
    );
    yield toastSuccess(
      "app.info.paymentMethod.cancel.success",
      "app.info.common.success"
    );
    yield put(cancelPaymentMethodSuccess());
  } catch (error) {
    yield put(handleError(error, false, "Wystąpił błąd", error.message));
  }
}

function* watchFetchPaymentMethodsRequest() {
  yield takeEvery(
    PaymentMethodActionTypes.FETCH_PAYMENT_METHODS,
    handleFetchPaymentMethods
  );
}

function* watchFetchCurrentPaymentMethodRequest() {
  yield takeEvery(
    PaymentMethodActionTypes.FETCH_CURRENT_PAYMENT_METHOD,
    handleFetchCurrentPaymentMethod
  );
}

function* watchFetchPaymentMethodByIdRequest() {
  yield takeEvery(
    PaymentMethodActionTypes.FETCH_PAYMENT_METHOD_BY_ID,
    handleFetchPaymentMethodById
  );
}

function* watchCreatePaymentMethod() {
  yield takeEvery(
    PaymentMethodActionTypes.CREATE_PAYMENT_METHOD,
    handleCreatePaymentMethod
  );
}

function* watchDeletePaymentMethod() {
  yield takeEvery(
    PaymentMethodActionTypes.CANCEL_PAYMENT_METHOD,
    handlePaymentMethodDelete
  );
}

function* paymentMethodsSaga() {
  yield all([
    fork(watchFetchPaymentMethodsRequest),
    fork(watchFetchCurrentPaymentMethodRequest),
    fork(watchFetchPaymentMethodByIdRequest),
    fork(watchCreatePaymentMethod),
    fork(watchDeletePaymentMethod),
  ]);
}

export default paymentMethodsSaga;
