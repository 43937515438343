import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Input,
  Modal,
  Popup,
  Search,
  Table,
  TextArea,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";

import { IClaim, IClaimCreate } from "~/store/claims/types";

import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import {
  ClaimCategory,
  ClaimGroup,
  SearchType,
} from "~/pages/Claims/lib/claimTypes";
import { useClaimCloningState } from "./useClaimCloningState";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { cloneClaim, fetchChatTargetGroups } from "~/store/claims/actions";
import translations from "~/utils/translations";
import { useClaimCloningValidation } from "./useClaimCloningValidation";
import { FormInputNumber } from "~/components/InputNumber/InputNumber";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import { toastSuccess } from "~/utils/toast";
import { useClaimCloning } from "./useClaimCloning";

type TReduxActions = {
  fetchDictionary: typeof fetchDictionary;
  fetchChatTargetGroups: typeof fetchChatTargetGroups;
  cloneClaim: typeof cloneClaim;
};

type TReduxState = {
  chatTargetGroups: string[] | undefined;
  creating?: boolean;
  dictionaries: DictionariesState;
};

type TProps = TReduxActions &
  TReduxState & {
    claim: IClaim;
    open: boolean;
    openModal: React.Dispatch<React.SetStateAction<boolean>>;
    redirectAction: (claimNr: string) => void;
  };

const ClaimCloning: React.FC<TProps> = ({
  open,
  creating,
  openModal,
  claim,
  dictionaries,
  fetchDictionary,
  fetchChatTargetGroups,
  chatTargetGroups,
  cloneClaim,
  redirectAction,
}) => {
  const handleCancel = useCallback(() => {
    openModal(false);
  }, [openModal]);

  const {
    processAutomatically,
    category,
    group,
    actors,
    type,
    description,
    tableData,
    lines,
    setSearchValue,
    setSearchPackageNr,
    inputDescription,
    changeProcessAutomatically,
    changeCategory,
    filterCategory,
    changeActors,
    changeType,
    filterType,
    setLines,
    setTableData,
    changeInvoice,
  } = useClaimCloningState({ claim, dictionaries });

  const { column, direction, loading } = tableData;
  const getTableData = (group: string): any => {
    if (
      group === ClaimGroup.notRealtedToInvoice ||
      group === ClaimGroup.withoutInvoice
    ) {
      return lines;
    } else {
      return tableData.data;
    }
  };

  const {
    mapChatTargetGroups,
    handleInputChange,
    isCheckedAll,
    selectAll,
    changeQuantity,
    selectLine,
  } = useClaimCloning({
    chatTargetGroups,
    setSearchPackageNr,
    setSearchValue,
    setLines,
    lines,
    setTableData,
    tableData,
  });

  const { isInvalidLine, validFormMessage, isValidForm, validNumber } =
    useClaimCloningValidation({
      lines: lines as any,
      group: group as ClaimGroup,
      category,
      description,
      type,
      invoice: claim.invoiceDocumentMask,
    });

  useEffect(() => {
    if (open) {
      fetchDictionary(
        DictionaryName.claimType,
        DictionaryName.claimCategory,
        DictionaryName.claimGroup
      );
      fetchChatTargetGroups();
      changeInvoice();
    }
  }, [open, fetchDictionary, fetchChatTargetGroups, changeInvoice]);

  const copyToClipboard = (text: string): void => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.top = "-50px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      toastSuccess("Numer paczki został skopiowany", text);
    } catch (err) {
      console.error("Nie udało się skopiować numeru paczki");
    }
    document.body.removeChild(textArea);
  };

  const errorMessage = validFormMessage();

  const handleRedirect = useCallback(
    (clonedClaimNr: string) => {
      setTimeout(() => {
        redirectAction(clonedClaimNr);
        handleCancel();
      }, 500);
    },
    [redirectAction, handleCancel]
  );

  const handelErrorAction = useCallback(() => {
    console.log("handelErrorAction not implemented");
  }, []);

  const handleConfirm = useCallback(() => {
    const cloningClaim: IClaimCreate = {
      claimGroup: group,
      claimCategory: category,
      processAutomatically: processAutomatically,
      description: description,
      chatTargetGroups: actors,
      invoiceNumber: claim.invoiceNumber,
      claimType: type,
      claimLines: lines
        .filter((line) => line.selected)
        .map((line) => ({
          lineNumber: line.lineNumber,
          claimedQuantity: line.quantity,
        })),
    };
    cloneClaim(
      cloningClaim,
      claim.claimNumber,
      handleRedirect,
      handelErrorAction
    );
  }, [
    group,
    category,
    processAutomatically,
    description,
    actors,
    type,
    lines,
    claim.claimNumber,
    cloneClaim,
    handelErrorAction,
    handleRedirect,
    claim.invoiceNumber,
  ]);

  return (
    <Modal open={open} onClose={handleCancel} closeOnDimmerClick={false}>
      <Header
        icon="exclamation triangle"
        content={`Klonowanie reklamacji ${claim.claimNumber}`}
      />
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Rodzaj składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz rodzaj"
              fluid
              selection
              value={claim.claimGroup}
              options={dictionaries["claim-group"].filter(
                (group) => group.key !== ClaimGroup.RK3
              )}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
              disabled
            />
          </Form.Field>
          <Form.Field
            disabled={claim.claimGroup === ClaimGroup.notRealtedToInvoice}
          >
            <Checkbox
              label="Procesuj automatyczne"
              checked={processAutomatically}
              onChange={changeProcessAutomatically}
            />
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field
              disabled={
                !(
                  group === ClaimGroup.realtedToInvoice ||
                  group === ClaimGroup.provision
                )
              }
            >
              <label>Wybierz rodzaj wyszukiwania</label>
              <Dropdown
                placeholder="Wybierz rodzaj wyszukiwania"
                fluid
                selection
                value={SearchType.documentMask}
                options={[
                  { text: "Numer faktury", value: SearchType.documentMask },
                  { text: "Numer paczki", value: SearchType.packageNumber },
                ]}
                disabled={true}
              />
            </Form.Field>
            <Form.Field>
              <label>
                Numer faktury sprzedaży, do której jest składana reklamacja lub
                zwrot
              </label>
              <Input value={claim.invoiceDocumentMask} disabled />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Kategoria składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              value={category}
              onChange={changeCategory}
              options={filterCategory(
                dictionaries["claim-category"],
                claim.claimGroup
              )}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Wybierz aktorów dla reklamacji</label>
            <Dropdown
              placeholder="Wybierz aktorów"
              multiple
              fluid
              selection
              value={actors}
              onChange={changeActors}
              options={mapChatTargetGroups()}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field
            disabled={
              category === "" ||
              category === ClaimCategory.lackClaim ||
              category === ClaimCategory.orderIntention ||
              group === ClaimGroup.provision ||
              group === ClaimGroup.withoutInvoice
            }
          >
            <label>Typ składanej reklamacji lub zwrotu*</label>
            <Dropdown
              placeholder="Wybierz typ"
              fluid
              selection
              value={type}
              onChange={changeType}
              options={filterType(dictionaries["claim-type"], category)}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Opis reklamacji na podstawie informacji od klienta*</label>
            <TextArea
              placeholder="Wypełnij opis"
              value={description}
              onInput={inputDescription}
            />
          </Form.Field>

          <div style={{ display: "flex", gap: 20 }}>
            <div>
              <Form.Field>
                <label>Lista linii do reklamacji</label>
              </Form.Field>
              <Search
                loading={loading}
                open={false}
                onSearchChange={(e, d) => handleInputChange(e, d, "search")}
                placeholder={"Referencja | Nr zamówienia"}
              />
            </div>
            <div>
              <Form.Field>
                <label style={{ visibility: "hidden" }}>Numer paczki</label>
              </Form.Field>
              <Search
                loading={loading}
                open={false}
                onSearchChange={(e, d) =>
                  handleInputChange(e, d, "searchPackageNr")
                }
                placeholder={"Numer paczki"}
              />
            </div>
          </div>
          <Table sortable={true} celled basic>
            <Table.Header key={"line"}>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  <Checkbox
                    checked={isCheckedAll(tableData)}
                    disabled={lines.length === 0}
                    onChange={selectAll}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  sorted={column === "lineNumber" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "lineNumber" })
                  }
                >
                  {translations.format("app.claim.line.number")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={column === "referenceId" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "referenceId" })
                  }
                >
                  {translations.format("app.reference")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    column ===
                    (group === ClaimGroup.realtedToInvoice
                      ? "orderedQuantity"
                      : "quantityOrgin")
                      ? direction
                      : null
                  }
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column:
                        group === ClaimGroup.realtedToInvoice
                          ? "orderedQuantity"
                          : "quantityOrgin",
                    })
                  }
                >
                  {translations.format("app.claim.ordered-quantity")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "quantity" ? direction : null}
                  onClick={() =>
                    setTableData({ type: "CHANGE_SORT", column: "quantity" })
                  }
                >
                  {translations.format("app.claim.quantity")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "orderNumber" ? direction : null}
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column: "orderNumber",
                    })
                  }
                >
                  {translations.format("app.claim.order.number")}
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === "packageNumber" ? direction : null}
                  onClick={() =>
                    setTableData({
                      type: "CHANGE_SORT",
                      column: "packageNumber",
                    })
                  }
                >
                  {translations.format("app.claim.order.packageNumber")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getTableData(group).map((line: any) => (
                <Table.Row key={line.lineNumber}>
                  <Table.Cell>
                    <Checkbox
                      checked={line.selected}
                      onChange={(e, d) => selectLine(line.lineNumber, e, d)}
                    />
                  </Table.Cell>
                  <Table.Cell>{line.lineNumber}</Table.Cell>
                  <Table.Cell>{line.referenceId}</Table.Cell>
                  <Table.Cell>
                    {line.quantityOrgin
                      ? line.quantityOrgin
                      : line.orderedQuantity}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Popup
                      content={
                        !isInvalidLine(line)
                          ? `${translations.format("app.claim.quantity")}: ${line.quantity}`
                          : "Wartość powinna być większa od zera"
                      }
                      trigger={
                        <FormInputNumber
                          min={0}
                          placeholder="..."
                          fluid
                          value={
                            validNumber(line.quantity) ? line.quantity : ""
                          }
                          error={isInvalidLine(line)}
                          style={{ width: 100, opacity: 1 }}
                          max={group === ClaimGroup.provision ? 1 : undefined}
                          onChange={(e, d) =>
                            changeQuantity(line.lineNumber, e, d)
                          }
                          disabled={group === ClaimGroup.withoutInvoice}
                        />
                      }
                    ></Popup>
                  </Table.Cell>
                  {group === ClaimGroup.realtedToInvoice && (
                    <Table.Cell>{line.orderNumber}</Table.Cell>
                  )}
                  {group === ClaimGroup.realtedToInvoice ? (
                    <Table.Cell textAlign="center">
                      {!line.packageNumber && "-"}
                      {line.packageNumber && (
                        <InfoIcon
                          documents={[line.packageNumber]}
                          position="top right"
                          clickableItem={(document) => (
                            <Button
                              style={{ display: "block", width: "100%" }}
                              compact
                              basic
                              onClick={() => {
                                copyToClipboard(document);
                              }}
                            >
                              {document}
                            </Button>
                          )}
                          noResultsMessage="Brak numeru paczki"
                        />
                      )}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          trigger={
            <span>
              <Button
                content="Zatwierdź"
                icon="add"
                labelPosition="left"
                primary
                loading={creating}
                onClick={() => handleConfirm()}
                disabled={!isValidForm() || creating}
              />{" "}
            </span>
          }
          content={errorMessage}
          disabled={isValidForm()}
        />
        <Button content="Anuluj" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  claims,
  dictionaries,
  invoices,
}: ApplicationState) => ({
  creating: claims.creating,
  dictionaries: dictionaries,
  chatTargetGroups: claims.chatTargetGroups,
});

const mapDispatchToProps: TReduxActions = {
  fetchDictionary,
  fetchChatTargetGroups,
  cloneClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimCloning);
