import { Accordion, Form, Icon, Segment } from "semantic-ui-react";
import { useAdvancedSearch } from "./hooks/useAdvancedSearch";
import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { FIELD_TYPE, FormField } from "./types/advancedSearchType";

import "./advancedSearch.scss";

type AdvancedSearchProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  formFields: FormField[][];
};

const AdvancedSearch: FC<AdvancedSearchProps> = ({
  handleSubmit,
  formFields,
  isOpen,
  setIsOpen,
}): JSX.Element => {
  const { renderFormFields } = useAdvancedSearch();
  const handleAccordionClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const flatten = (arr: any[]): any[] =>
    arr.reduce(
      (flat, toFlatten) =>
        flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
      []
    );

  const unwindFields = flatten(formFields).filter(
    (item) => item.type === FIELD_TYPE.CHECKBOX
  );

  return (
    <Accordion key="invoices-ac" styled style={{ width: "100%" }}>
      <Accordion.Title
        key="invoices-ac0"
        active={isOpen}
        onClick={handleAccordionClick}
      >
        <Icon name="dropdown" />
        Wyszukiwanie zaawansowane
      </Accordion.Title>
      <Accordion.Content active={isOpen}>
        <Form onSubmit={handleSubmit}>
          <Form.Group unstackable={false} widths={5}>
            {renderFormFields(formFields[0])}
          </Form.Group>
          <Form.Group unstackable={false} widths={5}>
            {formFields[1] &&
              renderFormFields(
                formFields[1].filter(
                  (item) => item.type !== FIELD_TYPE.CHECKBOX
                )
              )}
          </Form.Group>
          <Form.Group>
            <Form.Button icon="search" content="Szukaj" />
            {unwindFields && unwindFields.length ? (
              <Segment sizes="mini" className="toggle-checkbox">
                {renderFormFields(unwindFields)}
              </Segment>
            ) : null}
          </Form.Group>
        </Form>
      </Accordion.Content>
    </Accordion>
  );
};

export default AdvancedSearch;
