import React, { useCallback, useEffect, useState } from "react";
import { Divider, Grid } from "semantic-ui-react";
import "./styles.scss";
import { TClaimMessege } from "~/store/claims/types";
import { useComments } from "./hooks/useComments";

type props = {
  innerMessages: TClaimMessege[] | null;
  clientMessages: TClaimMessege[] | null;
  messagesAvailable: boolean;
};

export const CommentsCard: React.FC<props> = ({
  innerMessages,
  clientMessages,
  messagesAvailable,
}) => {
  const [innerMassagesToShow, setInnerMessagesToShow] = useState<
    TClaimMessege[]
  >([]);
  const [clientMassagesToShow, setClientMessagesToShow] = useState<
    TClaimMessege[]
  >([]);

  useEffect(() => {
    if (!innerMessages) {
      setInnerMessagesToShow([]);
    } else if (innerMessages.length < 2) {
      setInnerMessagesToShow(innerMessages);
    } else {
      setInnerMessagesToShow(innerMessages.slice(-2));
    }
  }, [innerMessages]);

  useEffect(() => {
    if (!clientMessages) {
      setClientMessagesToShow([]);
    } else if (clientMessages.length < 2) {
      setClientMessagesToShow(clientMessages);
    } else {
      setClientMessagesToShow(clientMessages.slice(-2));
    }
  }, [clientMessages]);

  const {
    isDistrigoView,
    addStyleClassByRole,
    renderInfoBadge,
    renderCommentContent,
    setLeftRightClass,
    messagesAmountLabel,
    canShowClientComments,
    canShowInnerComments,
  } = useComments();

  const NoCommentsInfo = useCallback(() => {
    if (!messagesAvailable) {
      return <span>Czat nie jest dostępny.</span>;
    } else {
      return <span>Brak komentarzy</span>;
    }
  }, [messagesAvailable]);

  return (
    <>
      <Grid key="comments-card">
        {canShowClientComments() ? (
          <>
            <Grid.Row style={{ justifyContent: "space-between" }}>
              <div>{canShowClientComments() ? "Rozmowa z klientem" : ""}</div>
              <div>{messagesAmountLabel(clientMessages)}</div>
            </Grid.Row>
            {clientMassagesToShow.length ? (
              clientMassagesToShow.map((comment) => (
                <Grid.Row
                  key={comment.timestamp}
                  className={setLeftRightClass(comment)}
                >
                  <div className="comment-wrapper">
                    {renderCommentContent(comment, 40)}
                  </div>
                  <div className={"info " + addStyleClassByRole(comment)}>
                    {renderInfoBadge(comment)}
                  </div>
                </Grid.Row>
              ))
            ) : (
              <NoCommentsInfo></NoCommentsInfo>
            )}

            <Grid.Row>
              <Divider style={{ width: "100%" }} />
            </Grid.Row>
          </>
        ) : null}

        {canShowInnerComments() ? (
          <>
            <Grid.Row style={{ justifyContent: "space-between" }}>
              <div>{"Rozmowa z obsługą"}</div>
              <div>{messagesAmountLabel(innerMessages)}</div>
            </Grid.Row>
            {isDistrigoView() && innerMassagesToShow.length ? (
              innerMassagesToShow.map((comment) => (
                <Grid.Row
                  key={comment.timestamp}
                  className={setLeftRightClass(comment)}
                >
                  <div className="comment-wrapper">
                    {renderCommentContent(comment, 40)}
                  </div>
                  <div className={"info " + addStyleClassByRole(comment)}>
                    {renderInfoBadge(comment)}
                  </div>
                </Grid.Row>
              ))
            ) : (
              <NoCommentsInfo></NoCommentsInfo>
            )}
          </>
        ) : null}
      </Grid>
    </>
  );
};
