import React from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import { IClaim } from "~/store/claims/types";
import ClaimAttachment from "./Single";
import "../../claim-details.scss";

type Props = {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  claim: IClaim;
};

export const AttachmentsModal: React.FC<Props> = ({
  open,
  handleClose,
  claim,
}) => {
  return (
    <>
      <Modal open={open} size="small">
        <Modal.Header icon="file">Załączniki</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nazwa załączika</Table.HeaderCell>
                <Table.HeaderCell>Data dodania</Table.HeaderCell>
                <Table.HeaderCell>Autor</Table.HeaderCell>
                <Table.HeaderCell>Do pobrania</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {claim.attachments.length ? (
                claim.attachments.map((attachment, index) => {
                  return (
                    <ClaimAttachment
                      key={index}
                      claimNumber={claim.claimNumber}
                      attachment={attachment}
                    />
                  );
                })
              ) : (
                <span>Brak załączników</span>
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Zamknij" onClick={() => handleClose(false)} />
        </Modal.Actions>
      </Modal>
    </>
  );
};
