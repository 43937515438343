import React, { SyntheticEvent } from 'react'
import { Input, Label } from 'semantic-ui-react'

export type TProps = {
    onSearchChange: (event: SyntheticEvent, data: any) => void
    onSearchAction: () => void
}

const EnterSearch: React.FC<TProps> = ({
    onSearchChange,
    onSearchAction
}) => {

    return (
        <Input
            onChange={onSearchChange}
            onKeyDown={(event: any) => event.key === 'Enter' ? onSearchAction() : null}
            action={{
            onClick: () => onSearchAction(),
            labelPosition: 'right',
            color: 'orange',
            icon: 'search',
            content: (
                <span>
                    <Label
                        style={{
                        position: 'absolute',
                        left: '-50px',
                        padding: '2px 5px',
                        opacity: .5,
                        pointerEvents: 'none',
                        borderRadius: '0',
                        fontSize: '10px'
                    }}
                    basic color='grey' size='tiny' floated='left'>enter</Label>
                    Szukaj
                </span>
            ),
            }}
        />
    )
}

export default EnterSearch
