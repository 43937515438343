import React, { useCallback, useEffect, useMemo } from "react";
import { Icon, Loader } from "semantic-ui-react";
import useScreenSize from "~/services/useScreenSize";
import { Announcement } from "~/store/annouoncements/types";
import { useGetAnnouncementImg } from "./useGetAnnouncementImg";

type TProps = {
  anouncement?: Announcement;
  announcementsAmount?: number;
  activeIndex?: number;
  middle?: boolean;
  inputImage?: string;
  addPreview?: boolean;
};
export const AnnouncementImage: React.FC<TProps> = ({
  anouncement,
  middle,
  inputImage,
  addPreview,
  announcementsAmount,
  activeIndex,
}) => {
  const { getImageData, img, loading, notFound, setImg } =
    useGetAnnouncementImg({
      imgId: anouncement?.image,
      announcement: anouncement,
    });

  const screenSize = useScreenSize();
  const scalledView = useMemo(() => screenSize.height < 1000, [screenSize]);

  useEffect(() => {
    getImageData();
    return () => {
      setImg(null);
    };
  }, [anouncement, getImageData, setImg]);

  const width = useCallback(() => {
    let width = scalledView ? "30%" : "35%";
    if (middle) {
      width = scalledView ? "40%" : "40%";
    }
    if (addPreview) {
      width = "90%";
    }
    return width;
  }, [middle, scalledView, addPreview]);

  const Wrapper: React.FC<any> = ({ children }) => {
    return (
      <div
        style={{
          width: width(),
          background: "#FAFAFA",
          height: !middle ? "80%" : "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "8px 8px 24px 0px rgba(66, 68, 90, 1)",
          position: "relative",
        }}
      >
        {middle ? (
          <div
            style={{
              display: "block",
              position: "absolute",
              top: -25,
              opacity: 1,
              borderRadius: 5,
              fontSize: 12,
            }}
          >
            {activeIndex ? (
              <>
                <strong>{activeIndex}</strong>
                {` / ${announcementsAmount}`}
              </>
            ) : null}
          </div>
        ) : null}
        {children}
      </div>
    );
  };

  const LoadingCard = () => (
    <Wrapper>
      <Loader active inline="centered" />
    </Wrapper>
  );

  const ImageCard = () => {
    return (
      <Wrapper>
        <img
          src={inputImage || img || undefined}
          style={{
            height: "100%",
            maxHeight: "35vh",
            maxWidth: "100%",
          }}
          alt="announcement thumbnail"
        />
      </Wrapper>
    );
  };

  const NotFoundCard = () => (
    <Wrapper>
      <div>
        <h3>{anouncement?.title}</h3>
        <div style={{ textAlign: "center", marginTop: 15 }}>
          <Icon name="times rectangle" size="big" />
          <p style={{ marginTop: 15 }}>Nie udało się pobrać obrazu</p>
        </div>
      </div>
    </Wrapper>
  );

  return (
    <>
      {loading ? <LoadingCard /> : null}
      {notFound ? <NotFoundCard /> : null}
      {!loading && !notFound ? <ImageCard /> : null}
    </>
  );
};
