import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import { getEndecodedClaimNumber } from "~/store/claims/sagas";
import { IClaim } from "~/store/claims/types";
type TProps = {
  claim: IClaim;
};

const InvoiceCorrectionNotes: React.FC<TProps> = ({ claim }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { renderTextArea } = useRenderingFunctions(claim, editMode);
  const { userHaveLdcUiClaimsProcessRole } = useClaimsRolesRights();

  const controlLabel = (
    <>
      <>Komentarz do korekty faktury</>
      {userHaveLdcUiClaimsProcessRole ? (
        <Button
          icon="pencil"
          primary={editMode}
          style={{
            marginLeft: 8,
            padding: 10,
          }}
          onClick={() => setEditMode((state) => !state)}
        />
      ) : null}
    </>
  );
  return (
    <>
      <SmartWrapper
        key={claim.claimNumber}
        endpoint={`/claims/${getEndecodedClaimNumber(claim.claimNumber)}/correction-notes`}
        model={claim}
        method="POST"
      >
        {renderTextArea("invoiceCorrectionNotes", {
          label: controlLabel,
          rows: 3,
          maxlength: 512,
        })}
      </SmartWrapper>
    </>
  );
};

export default InvoiceCorrectionNotes;
