import { useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useClaimsRolesRights = () => {
  const context = useContext(AppContext);

  const isClientPanelView = useMemo(() => false, []);

  const userHaveLdcUiClaimsView = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW),
    [context]
  );

  const userHaveLdcUiClaimsProcessRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CLAIMS_PROCESS
      ),
    [context]
  );

  const userHaveLdcUiWarehouseClaimsProcessRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CLAIMS_WAREHOUSE_PROCESS
      ),
    [context]
  );

  const userHaveLdcUiTransportClaimsProcessRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CLAIMS_TRANSPORT_PROCESS
      ),
    [context]
  );

  return {
    isClientPanelView,
    userHaveLdcUiClaimsView,
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  };
};
