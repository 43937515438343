import { useContext } from 'react';
import { AppContext } from '~/context/AuthContext';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';

export const useOrdersRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiOrderCloseUnrealizedRole = context?.keycloak?.hasResourceRole(
    AVAILABLE_ROLE_ENUM.LDC_ORDER_CLOSE_UNREALIZED
  );

  const userHaveLdcUiOrderBonusRole = context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS);

  const userHaveLdcUiOrderSpecialRole = context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_SPECIAL);

  const userHaveLdcUiOrderNormalRole = context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_NORMAL);

  const userHaveLdcUiOrderWarrantyRole = context?.keycloak?.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_WARRANTY);

  return {
    userHaveLdcUiOrderCloseUnrealizedRole,
    userHaveLdcUiOrderBonusRole,
    userHaveLdcUiOrderSpecialRole,
    userHaveLdcUiOrderNormalRole,
    userHaveLdcUiOrderWarrantyRole,
  };
};
