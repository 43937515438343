import { Button, Icon } from "semantic-ui-react"

import React from 'react'

export type Props = {
    refreshAction: (...params: any[]) => void
    loading: boolean
}

const RefreshPageButton: React.FC<Props> = ({ refreshAction, loading }) => {
    return (
        <Button icon onClick={refreshAction} disabled={loading} labelPosition='left' style={{ minWidth: 135 }} floated='right'>Odśwież<Icon name='refresh' /></Button>
    )
}

export default RefreshPageButton