import { connect } from "react-redux";
import {
  Button,
  Container,
  Grid,
  Header,
  Modal,
  Popup,
  Table,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { selectInternalConsumptionNote } from "~/store/invoices/actions";
import { IInternalConsumptionNote } from "~/store/invoices/types";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { parseDate } from "~/utils/dateUtils";

import React, { useEffect, useState } from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";

type TReduxState = {
  internalConsumptionNote?: IInternalConsumptionNote;
  loading: boolean;
};

type TReduxActions = {
  selectInternalConsumptionNote: typeof selectInternalConsumptionNote;
};

type TProps = {
  noteId: string;
};

type TInternalConsumptionNotesDetailsProps = TReduxState &
  TReduxActions &
  TProps;

const statusMap: any = {
  REQUIRES_CONFIRMATION: "Wymaga potwierdzenia",
  PRICING_IN_PROGRESS: "Wymaga potwierdzenia",
  PRICING_IN_PROGRESS_WITH_ANOMALY: " Wymaga potwierdzenia",
  CONFIRMED: "Potwierdzony",
  CONFIRMED_WITH_ANOMALY: "Potwierdzony",
  REJECTED: "Odrzucony",
};

const InternalConsumptionNotesDetails: React.FC<
  TInternalConsumptionNotesDetailsProps
> = ({
  children,
  noteId,
  selectInternalConsumptionNote,
  internalConsumptionNote,
  loading,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { renderLabel } = useRenderingFunctions(internalConsumptionNote, false);

  useEffect(() => {
    if (open) {
      selectInternalConsumptionNote(noteId);
    }
  }, [open, noteId, selectInternalConsumptionNote]);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const renderLines = (lines: any[]): JSX.Element => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Referencja</Table.HeaderCell>
            <Table.HeaderCell>Do zniszczenia</Table.HeaderCell>
            <Table.HeaderCell>Zniszczone</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lines.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="14">
                <Container fluid textAlign="center">
                  Brak elementów do wyświetlenia.
                </Container>
              </Table.Cell>
            </Table.Row>
          )}
          {lines.map(
            ({
              referenceId,
              partID,
              availableReserved,
              availableRemoved,
              qualityControlReserved,
              qualityControlRemoved,
            }) => (
              <Table.Row key={referenceId}>
                <Table.Cell>{partID}</Table.Cell>
                <Table.Cell>
                  <Popup
                    content="Pełnowartościowe / Niepełnowartościowe"
                    trigger={
                      <span>
                        {" "}
                        {`${
                          availableReserved === null ? 0 : availableReserved
                        } / ${
                          qualityControlReserved === null
                            ? 0
                            : qualityControlReserved
                        }`}
                      </span>
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    content="Pełnowartościowe / Niepełnowartościowe"
                    trigger={
                      <span>
                        {`${
                          availableRemoved === null ? 0 : availableRemoved
                        } / ${
                          qualityControlRemoved === null
                            ? 0
                            : qualityControlRemoved
                        }`}
                      </span>
                    }
                  />
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Modal
      size="large"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header
        icon="briefcase"
        content={
          internalConsumptionNote
            ? internalConsumptionNote.number
            : "Wczytywanie..."
        }
      />
      <Modal.Content>
        <Grid style={{ marginBottom: "unset" }} stretched>
          <DetailCard
            title="app.details"
            id="details"
            key="details"
            width={12}
            smallWidth={12}
            minHeight={100}
          >
            {internalConsumptionNote &&
              renderLabel("Numer", internalConsumptionNote.number)}
            {internalConsumptionNote &&
              renderLabel("Status", statusMap[internalConsumptionNote.state])}
            {internalConsumptionNote &&
              renderLabel(
                "Data wystawiania",
                parseDate(internalConsumptionNote.issueDate)
              )}
            {internalConsumptionNote &&
              renderLabel(
                "Data potwierdzenia",
                internalConsumptionNote.confirmationDate
                  ? parseDate(internalConsumptionNote.confirmationDate)
                  : "-"
              )}
            {internalConsumptionNote &&
              renderLabel(
                "Magazyn",
                internalConsumptionNote.warehouseName
                  ? internalConsumptionNote.warehouseName
                  : "-"
              )}
            {internalConsumptionNote &&
              renderLabel(
                "Powód",
                internalConsumptionNote.additionalInformation
                  ? internalConsumptionNote.additionalInformation
                  : "-"
              )}
          </DetailCard>
          <DetailCard
            title="app.consumed.parts"
            id="consumedParts"
            key="consumedParts"
            width={16}
            smallWidth={16}
            minHeight={100}
          >
            {internalConsumptionNote &&
              renderLines(internalConsumptionNote.destroyed || [])}
          </DetailCard>
        </Grid>
        <CommonLoader loading={loading} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  invoices,
}: ApplicationState) => ({
  internalConsumptionNote: invoices.internalConsumptionNote.selected,
  loading: invoices.internalConsumptionNote.loading,
});

const mapDispatchToProps: TReduxActions = { selectInternalConsumptionNote };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalConsumptionNotesDetails);
