import { ApiError } from "~/store/error/types";
import { keycloakInstance } from "./keycloak";
import {
  TPromotionActivateCollisionInfo,
  TPromotionActivateErrorResponse,
} from "~/store/promotions/types";
import {
  ActivatePromoAcceptanceErrorsMap,
  formatPromoImportErrorMessage,
  PROMO_IMPORT_CODE_ERROR,
} from "~/utils/promoImportErrorMessageFormatter";
import translations from "~/utils/translations";

export enum ORDER_ERRORS {
  INSUFFICIENT_USERS_DISCOUNT_LIMIT = "INSUFFICIENT_USERS_DISCOUNT_LIMIT",
  ACCESS_DENIED = "access_denied",
  REQUIRED_PROVISION_NOT_PROVIDED = "REQUIRED_PROVISION_NOT_PROVIDED",
  ORDER_EXPORT_LIMIT_EXCEEDED = "ORDER_EXPORT_LIMIT_EXCEEDED",
  MAX_EXPORT_SIZE_EXCEEDED = "MAX_EXPORT_SIZE_EXCEEDED",
  NOTHING_TO_EXPORT = "NOTHING_TO_EXPORT",
}

export enum IMPORT_FACTOR_LIMITS_ERRORS {
  ACCOUNTING_VAT_ID_NOT_VALID = "Brak płatnika z podanym numerem NIP",
  INVALID_FACTOR_LIMIT_FILE = "Podane wartości w pliku są nieprawidłowe",
  INVALID_AGREEMENT_NO = "Brak w systemie podanego numeru umowy",
}

export enum ADMINISTRATION_USERS_ERROR_CODES {
  REMOVING_ITSELF_ERROR = "REMOVING_ITSELF_ERROR",
  REMOVING_LAST_ADMIN_ERROR = "REMOVING_LAST_ADMIN_ERROR",
}

const delay = (time: number) => new Promise((res) => setTimeout(res, time));

const validateToken = () =>
  new Promise<void>((resolve, reject) => {
    keycloakInstance.isTokenExpired(25 * 60)
      ? keycloakInstance
          .updateToken(25 * 60)
          .then(() => resolve())
          .catch(() => reject())
      : resolve();
  });

const MAX_SIZE = 15 * 1024 * 1024;

export async function callApi(
  method: string,
  path: string,
  data?: any
): Promise<any> {
  try {
    await validateToken();
    const res = await fetch(window.config.apiUrl + path, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(data),
    });
    return await parseResponse(method, res);
  } catch (e) {
    const error = e as ApiError;

    if (error.message && (!("code" in error) || !("error" in error))) {
      throw new Error(error.message);
    } else {
      throw new ApiError(
        method,
        error.status || 0,
        error.message,
        error.errorInfoObject,
        error.code,
        error.error
      );
    }
  }
}

export async function callApiWithRetry(
  count: number,
  method: string,
  path: string,
  data?: any
): Promise<any> {
  let error;
  let i = 0;
  do {
    i++;
    error = undefined;
    try {
      return await callApi(method, path, data);
    } catch (err) {
      error = err;
      await delay(1000 * i);
    }
  } while (error && i < count);
  if (error) {
    throw error;
  }
}

export async function callApiDownload(
  method: string,
  path: string,
  fileName: string,
  data?: any
): Promise<any> {
  try {
    await validateToken();

    const res = await fetch(window.config.apiUrl + path, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
      },
      body: JSON.stringify(data),
    });

    return await parseResponse(method, res, "blob");
  } catch (error) {
    throw new ApiError(method, error.status || 0);
  }
}

export async function callApiUploadFile(
  path: string,
  file: File,
  requestKey: string = "file",
  additionalData?: any
): Promise<any> {
  try {
    if (file.size > MAX_SIZE) {
      throw new ApiError("POST", 413);
    }

    await validateToken();
    const fd = new FormData();

    fd.append(requestKey, file);

    if (additionalData) {
      fd.append("body", JSON.stringify(additionalData));
    }

    const res = await fetch(window.config.apiUrl + path, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
      },
      body: fd,
    });

    return await parseResponse("POST", res);
  } catch (error) {
    throw new ApiError("POST", error.status || 0, error.message);
  }
}

export async function callApiPatchUploadFile(
  path: string,
  file: File,
  requestKey: string = "file",
  additionalData?: any
): Promise<any> {
  try {
    if (file.size > MAX_SIZE) {
      throw new ApiError("PATCH", 413);
    }
    await validateToken();
    const fd = new FormData();
    fd.append(requestKey, file);
    if (additionalData) {
      fd.append("body", JSON.stringify(additionalData));
    }
    const res = await fetch(window.config.apiUrl + path, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
      },
      body: fd,
    });
    await parseResponse("PATCH", res);
    return res.status;
  } catch (error) {
    throw new ApiError("PATCH", error.status || 0, error.message);
  }
}

export async function callApiUploadFiles(
  path: string,
  files: File[],
  requestKey: string = "attachments"
): Promise<any> {
  try {
    const attachments = [...files];

    const requestSize = attachments
      .map((file) => file.size)
      .reduce((size1, size2) => size1 + size2);

    if (
      requestSize > MAX_SIZE ||
      !!attachments.find((file) => file.size > MAX_SIZE)
    ) {
      throw new ApiError("POST", 413);
    }

    await validateToken();

    const fd = new FormData();

    for (const file of attachments) {
      fd.append(requestKey, file);
    }

    const res = await fetch(window.config.apiUrl + path, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
      },
      body: fd,
    });

    return await parseResponse("POST", res);
  } catch (error) {
    throw new ApiError("POST", error.status || 0);
  }
}

export async function callApiImageThumbnail(path: string): Promise<any> {
  try {
    await validateToken();
    const res = await fetch(window.config.apiUrl + path, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloakInstance.token}`,
      },
    });
    if (res && res.ok) {
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL;
    } else {
      return null;
    }
  } catch (e) {
    const error = e as ApiError;
    if (error.message) throw new Error(error.message);
    else throw new ApiError("GET", error.status || 0);
  }
}

export async function callApiUploadFileMultipart(
  path: string,
  file: File,
  requestKey: string = "file",
  additionalData?: { name: string; value: string | string[] }[]
): Promise<any> {
  try {
    if (file.size > MAX_SIZE) {
      throw new ApiError("POST", 413);
    }

    await validateToken();
    const fd = new FormData();

    fd.append(requestKey, file);

    if (additionalData) {
      additionalData.forEach((item) => {
        if (typeof item.value === "string") {
          fd.append(item.name, item.value);
        } else {
          item.value.forEach((value) => fd.append(item.name, value));
        }
      });
    }

    const res = await fetch(window.config.apiUrl + path, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${keycloakInstance.token}`,
        Accept: "application/json",
      },
      body: fd,
    });

    return await parseResponse("POST", res);
  } catch (error) {
    throw new ApiError("POST", error.status || 0, error.message);
  }
}

function collisionOccured(err: any) {
  return (
    err.code === PROMO_IMPORT_CODE_ERROR.PROMO_COLLISION ||
    err.code === PROMO_IMPORT_CODE_ERROR.IMPORTED_PROMO_COLLISION
  );
}

async function parseResponse(
  method: string,
  res: Response,
  type: string = "json"
): Promise<any> {
  if (res.status === 403) {
    const err = await res.json();
    if (err.code === ORDER_ERRORS.INSUFFICIENT_USERS_DISCOUNT_LIMIT) {
      const message = translations.format(
        "app.error.order.status-order.details",
        { value: err.additionalData.usersDiscountLimit }
      );

      throw new ApiError(method, res.status, message);
    }

    if (err.error === ORDER_ERRORS.ACCESS_DENIED) {
      const message = translations.format(
        "app.error.order.status-order.access-denied"
      );

      throw new ApiError(method, res.status, message);
    }

    if (err.code === ORDER_ERRORS.ORDER_EXPORT_LIMIT_EXCEEDED) {
      const message = "Przekroczono dzienny limit 4 eksportów";

      throw new ApiError(method, res.status, message);
    }

    if (err.code === ORDER_ERRORS.MAX_EXPORT_SIZE_EXCEEDED) {
      const message =
        "Przekroczono limit 4000 pozycji. Zmniejsz liczbę odfiltrowanych wyników.";

      throw new ApiError(method, res.status, message);
    }

    if (err.code === ADMINISTRATION_USERS_ERROR_CODES.REMOVING_ITSELF_ERROR) {
      const message = "Nie można usunąć swojego konta";

      throw new ApiError(method, res.status, message);
    }

    if (
      err.code === ADMINISTRATION_USERS_ERROR_CODES.REMOVING_LAST_ADMIN_ERROR
    ) {
      const message =
        "Wybrane konto jest ostatnim administratorem lokalnym dla tego płatnika";

      throw new ApiError(method, res.status, message);
    }

    if (err.code === ORDER_ERRORS.NOTHING_TO_EXPORT) {
      const message = "Lista dokumentów do eksportu jest pusta.";

      throw new ApiError(method, res.status, message);
    }

    if (err.message.includes("forbidden current payment method type")) {
      const message = translations.format(
        "app.error.payment-method-not-allowed"
      );

      throw new ApiError(method, res.status, message);
    }

    throw new ApiError(method, res.status);
  }
  if (!res.ok && res.url.includes("/cost/accept")) {
    const response = await res.json();
    throw new ApiError(
      method.toUpperCase(),
      res.status,
      response.error,
      undefined,
      response.code,
      response.error
    );
  }
  //temporary error handler for collective corrections import upload listing errors
  if (res.status === 400) {
    const response = await res.json();
    if (response.code === "CUSTOMER_NOT_VALID_ERROR") {
      throw new ApiError(
        method.toUpperCase(),
        400,
        customerErrorMessageMap[response.error]
      );
    } else if (dictionaryErrorCodes[response.code]) {
      throw new ApiError(
        method.toUpperCase(),
        400,
        dictionaryErrorMessage[response.message]
      );
    } else if (response.code in IMPORT_FACTOR_LIMITS_ERRORS) {
      throw new ApiError(
        method.toUpperCase(),
        400,
        IMPORT_FACTOR_LIMITS_ERRORS[
          response.code as keyof typeof IMPORT_FACTOR_LIMITS_ERRORS
        ]
      );
    } else if (response.code === "CLAIM_INVALID_REQUEST") {
      throw new ApiError("POST", 400, response.message);
    } else if (response.code === "CLAIM_ACCESS_NOT_AVAILABLE") {
      throw new ApiError("GET", 403, "CLAIM_ACCESS_NOT_AVAILABLE");
    } else if (response.code === "REMOVING_USER_ERROR") {
      throw new ApiError("DELETE", 400, response.message);
    } else if (
      response.code === "IMPORT_ORDER_CSPS_CODED_PART" ||
      response.code === "IMPORT_ORDER_INVALID_DELIVERY_POINT" ||
      response.code === "IMPORT_ORDER_MISSING_PART "
    ) {
      throw new ApiError("POST", 400, response.message);
    }
    if ("errorMessage" in response) {
      throw new ApiError("POST", res.status, response.errorMessage);
    }
    throw new Error(response.message);
  }
  if (method.toUpperCase() === "POST" && res.status === 250) {
    return await res.json();
  } else if (
    method.toUpperCase() === "POST" &&
    (res.status === 400 || res.status === 409)
  ) {
    const err = await res.json();
    if (collisionOccured(err)) {
      return err;
    }
    if (err.code === "BACKORDER_CREATE_ERROR") {
      throw new ApiError("POST", 409, err.message);
    }
    if (err.code in PROMO_IMPORT_CODE_ERROR) {
      throw new ApiError(
        "POST",
        400,
        formatPromoImportErrorMessage(err.additionalData, err.code)
      );
    }
    if ("errorMessage" in err) {
      throw new ApiError("POST", res.status, err.errorMessage);
    }
    throw new Error(err.message);
  } else if (method.toUpperCase() === "PATCH" && res.status === 409) {
    const err: TPromotionActivateCollisionInfo = await res.json();
    if (err.code === "PROMO_COLLISION") {
      return err;
    } else {
      throw new Error(err.message);
    }
  } else if (method.toUpperCase() === "PATCH" && res.status === 400) {
    const err: TPromotionActivateErrorResponse = await res.json();
    if (err.code === "IMPORTED_PROMO_ACCEPTANCE_ERROR") {
      throw new ApiError(
        "PATCH",
        400,
        ActivatePromoAcceptanceErrorsMap[err.additionalData]
      );
    }
    throw new Error(err.message);
  } else if (res.status === 405) {
    const err: TPromotionActivateErrorResponse = await res.json();
    if (err.code === ORDER_ERRORS.REQUIRED_PROVISION_NOT_PROVIDED) {
      throw new ApiError("POST", 405, err.message, {
        code: err.code,
        message: err.message,
        timestamp: err.timestamp,
      });
    }
  } else if (res.status === 409 && method.toUpperCase() === "PUT") {
    const err: any = await res.json();
    if (err.code === "ORDER_CANCEL_ERROR") {
      throw new ApiError("POST", 409, err.message, {
        code: err.code,
        message: err.message,
        timestamp: err.timestamp,
      });
    }
  } else if (res.status === 409 && method.toUpperCase() === "GET") {
    const err: any = await res.json();
    if (err.code === "USER_CONFIGURATION_ERROR") {
      throw new ApiError("GET", 409, err.message, {
        code: err.code,
        message: err.message,
        timestamp: err.timestamp,
      });
    }
  } else if (res.ok) {
    try {
      switch (type) {
        case "json":
          return await res.json();
        case "blob":
          return await res.blob();
        default:
          return await res.json();
      }
    } catch (error) {
      console.warn(error);
      return {};
    }
  } else if (res.status === 404) {
    const err = await res.json();
    if (err.code === "CUSTOMER_NOT_FOUND") {
      throw new ApiError(method, res.status, err.message || "");
    }
  } else if (res.status === 500) {
    const response = await res.json();
    throw new Error(response.message || "");
  } else {
    res.json().then((res) => res);
    throw new ApiError(method, res.status);
  }
}

const customerErrorMessageMap: { [key: string]: string } = {
  CUSTOMER_TYPE_RRDI_CONTRADICTION:
    "Typ klienta niekompatybilny z numerem RRDI",
  CUSTOMER_TYPE_CLIENT_ID_CONTRADICTION: "Numer ID klienta niepoprawny",
  PAYMENT_METHOD_CANCEL_PAST_OR_ACTUAL_ILLEGAL:
    "Nie można anulować aktualnej lub przeszłej metody płatności",
  PAYMENT_FACTOR_NUMBER_PER_CUSTOMER_EXCEEDED:
    "Można utworzyć maksymalnie 3 faktorów",
  PAYMENT_FACTOR_ID_NOT_UNIQUE: "Numer ID faktora musi być unikalne",
  PAYMENT_FACTOR_PRIORITY_NOT_UNIQUE: "Numer piorytetu musi być unikalny",
  PAYMENT_FACTOR_INVALID_FIELD: "Podano niewłaściwe wartości w faktorze",
  PAYMENT_DUE_ID_INVALID: "Niepoprawny okres płatności",
  PAYMENT_FACTOR_ID_INVALID: "Niepoprawny numer ID faktora",
  PAYMENT_FACTOR_REMOVE_ILLEGAL:
    "Aktualna lub planowana metoda płatności to faktor. Użytkownik musi posiadać co najmniej jedną metodą płatności",
  PAYMENT_DISTRIGO_CREDIT_INVALID:
    "Podano niepoprawne wartości w kredycie Distrigo",
  PAYMENT_METHOD_FACTOR_TYPE_CREATE_WHEN_NO_FACTORS:
    "Nie można utworzyć metody płatności faktor jeżeli nie ma zdefiniowanego żadnego faktora.",
};

const dictionaryErrorMessage: { [key: string]: string } = {
  UNCHANGEABLE_FIELD_UPDATE_ILLEGAL:
    "Brak możliwości aktualizacji niektórych z pól",
  DEFAULT_FOOTER_INVALID_FORMAT: "Nieprawidłowy format domyślnej stopki",
  SAP_CODE_INVALID_FORMAT: "Nieprawdiłowy format kodu SAP",
  FACTOR_UPDATE_ATTEMPT_ILLEGAL: "Nie można aktualizować faktora",
  DUPLICATE_FACTOR_CREATION_ATTEMPT:
    "Faktor o podanych wartościach pól już istnieje",
  DUPLICATE_AGREEMENT_NO: "Podany numer umowy już jest w użyciu",
  FACTOR_CURRENTLY_IN_USAGE: "Obecnie faktor jest w użyciu",
  FACTOR_DATA_INCOMPLETE: "Niekomplenty zestaw danych.",
};

const dictionaryErrorCodes: { [key: string]: string } = {
  FACTOR_CREATION_ERROR: "Błąd podczas tworzenia faktora",
  FACTOR_UPDATE_ERROR: "Błąd podczas aktualizacji faktora",
  DICTIONARY_ACTION_ERROR: "Wystąpił błąd podczas zmian w słowniku",
  PAYMENT_METHOD_UPDATE_ERROR:
    "Wystąpił błąd podczas aktualizacji metody płatności",
};
