import { connect } from 'react-redux'
import { Button, Header, Modal, Table, Grid, Container } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { selectGoodsReceivedNote } from '~/store/invoices/actions'
import { IGoodsReceivedNote, TGoodsReceivedNotePart, TCodes } from '~/store/invoices/types'
import { DictionariesState, DictionaryName } from '~/store/dictionaries/types'
import { fetchDictionary } from '~/store/dictionaries/actions'
import { parseDate } from '~/utils/dateUtils'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { Link } from 'react-router-dom'

import React, { useState, useEffect } from 'react'
import DetailCard from '~/components/DetailCard/DetailCard'
import CommonLoader from '~/components/Loaders/CommonLoader'
import mapDictionary from '~/components/MapDictionary/mapDictionary'
import { encodeUrlClaimNumber } from "~/utils/claimNumberUrlFormatter";

type TReduxState = {
    goodsReceivedNotes?: IGoodsReceivedNote
    loading: boolean
    dictionaries: DictionariesState
}

type TReduxActions = {
    selectGoodsReceivedNote: typeof selectGoodsReceivedNote
    fetchDictionary: typeof fetchDictionary
}

type TProps = {
    noteId: string
}

type TGoodsReceivedNotesDetailsProps = TReduxState & TReduxActions & TProps

const GoodsReceivedNotesDetails: React.FC<TGoodsReceivedNotesDetailsProps> = ({
    children, noteId, selectGoodsReceivedNote, fetchDictionary, goodsReceivedNotes, dictionaries, loading
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const { renderLabel } = useRenderingFunctions(goodsReceivedNotes, false)

    useEffect(() => {
        if (open) {
            fetchDictionary(DictionaryName.grnType)
            fetchDictionary(DictionaryName.warehouseMovementStatus)
            selectGoodsReceivedNote(noteId)
        }
    }, [open, fetchDictionary, noteId, selectGoodsReceivedNote])

    const handleCancel = (): void => {
        setOpen(false)
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    const labels: { [key: string]: string } = {
        'vin': "VIN",
        'cleCode': "CLE",
        'transmitterCode': "EMETTEUR",
        'nreCode': "NRE",
        'wheelCode': "ROUE",
        'tiresCode': "PNEUMATIQUE"
    }

    const renderCodes = (codes: TCodes): JSX.Element => {
        if (!codes) {
            return <></>
        }
        const description = Object.entries(codes)
            .filter(([code, entry]) => entry)
            .map(([code, entry]) => <small key={code} style={{ display: 'block' }}>{labels[code]}: {entry}</small>)
        return (
            <div style={{ display: 'inline-block', textAlign: 'left', verticalAlign: 'middle', lineHeight: '1em' }}>
                {description}
            </div>
        )
    }

    const renderLines = (lines: TGoodsReceivedNotePart[]): JSX.Element => {
        return <Table basic>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Referencja</Table.HeaderCell>
                    <Table.HeaderCell>Ilość</Table.HeaderCell>
                    <Table.HeaderCell>Kodowanie</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {lines.length === 0 && <Table.Row>
                    <Table.Cell colSpan="14">
                        <Container fluid textAlign="center">
                            Brak elementów do wyświetlenia.
                        </Container>
                    </Table.Cell>
                </Table.Row>}
                {lines.sort((a, b) => (a.referenceId > b.referenceId) ? 1 : -1).map(line =>
                    <Table.Row key={line.referenceId}>
                        <Table.Cell>{line.referenceId}</Table.Cell>
                        <Table.Cell>{line.quantity}</Table.Cell>
                        <Table.Cell>{renderCodes(line.codes)}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    }

    const renderGoodsLines = (lines: TGoodsReceivedNotePart[]): JSX.Element => {
        return <Table basic>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Referencja</Table.HeaderCell>
                    <Table.HeaderCell>Pełnowartościowe</Table.HeaderCell>
                    <Table.HeaderCell>Niepełnowartościowe</Table.HeaderCell>
                    <Table.HeaderCell>Kodowanie</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {lines.length === 0 && <Table.Row>
                    <Table.Cell colSpan="14">
                        <Container fluid textAlign="center">
                            Brak elementów do wyświetlenia.
                        </Container>
                    </Table.Cell>
                </Table.Row>}
                {lines.sort((a, b) => (a.referenceId > b.referenceId) ? 1 : -1).map(line =>
                    <Table.Row key={line.referenceId}>
                        <Table.Cell>{line.referenceId}</Table.Cell>
                        <Table.Cell>{line.available === null ? 0 : line.available}</Table.Cell>
                        <Table.Cell>{line.qualityControl === null ? 0 : line.qualityControl}</Table.Cell>
                        <Table.Cell>{renderCodes(line.codes)}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    }

    return (
        <Modal
            size="large"
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}>
            <Header icon='briefcase' content={goodsReceivedNotes ? goodsReceivedNotes.goodsReceivedNoteID : 'Wczytywanie...'} />
            <Modal.Content>
                <Grid style={{ marginBottom: 'unset' }} stretched>
                    <DetailCard title="app.details" id="details" key="details" width={16} minHeight={100}>
                        {goodsReceivedNotes && <Grid columns={2} divided stretched>
                            <Grid.Row>
                                <Grid.Column >
                                    {renderLabel("Numer", goodsReceivedNotes.goodsReceivedNoteID)}
                                    {renderLabel("Data wystawiania", parseDate(goodsReceivedNotes.issueDate))}
                                    {renderLabel("Zamówienie LDC", goodsReceivedNotes.orderNo ? goodsReceivedNotes.orderNo : '-')}
                                    {renderLabel("Typ", mapDictionary(goodsReceivedNotes.type, dictionaries["grn-type"], true, true, true))}
                                    {renderLabel("Status", mapDictionary(goodsReceivedNotes.status, dictionaries["warehouse-movement-status"], true, true, true))}
                                    {goodsReceivedNotes.claimNumber ? renderLabel("Reklamacja", <Link to={`/claim/${encodeUrlClaimNumber(goodsReceivedNotes.claimNumber)}`}>{goodsReceivedNotes.claimNumber}</Link>) : null}
                                    {
                                        goodsReceivedNotes.backorderID ?
                                        renderLabel("Zamówienie hurtowe", <Link target="_blank" to={`/backorder/${goodsReceivedNotes.backorderID}`}>{goodsReceivedNotes.backorderNo}</Link>)
                                            : null
                                    }
                                </Grid.Column>
                                <Grid.Column >
                                    {renderLabel("Magazyn", goodsReceivedNotes.recipientWarehouseName ? goodsReceivedNotes.recipientWarehouseName : '-')}
                                    {renderLabel("Płatnik", goodsReceivedNotes.recipientID ? goodsReceivedNotes.recipientID : '-')}
                                    {renderLabel("Odbiorca", goodsReceivedNotes.recipientWarehouseID ? goodsReceivedNotes.recipientWarehouseID : '-')}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>}
                    </DetailCard>
                    <DetailCard title="app.lines.expected" id="partsExpected" key="partsExpected" width={8} smallWidth={8} minHeight={100}>
                        <div style={goodsReceivedNotes && goodsReceivedNotes.expectedParts && goodsReceivedNotes.expectedParts.length > 6 ? { maxHeight: '250px', overflowY: 'scroll' } : {}}>
                            {goodsReceivedNotes && renderLines(goodsReceivedNotes.expectedParts || [])}
                        </div>
                    </DetailCard>
                    <DetailCard title="app.lines.received" id="partsReceived" key="partsReceived" width={8} smallWidth={8} minHeight={100}>
                        <div style={goodsReceivedNotes && goodsReceivedNotes.receivedParts && goodsReceivedNotes.receivedParts.length > 6 ? { maxHeight: '250px', overflowY: 'scroll' } : {}}>
                            {goodsReceivedNotes && renderGoodsLines(goodsReceivedNotes.receivedParts || [])}
                        </div>
                    </DetailCard>
                </Grid >
                <CommonLoader loading={loading} />
            </Modal.Content>
            <Modal.Actions>
                <Button content="Zamknij" onClick={handleCancel} />
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ invoices, dictionaries }: ApplicationState) => ({
    goodsReceivedNotes: invoices.goodsReceivedNotes.selected,
    loading: invoices.goodsReceivedNotes.loading || dictionaries.loading,
    dictionaries
})

const mapDispatchToProps: TReduxActions = { fetchDictionary, selectGoodsReceivedNote }

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceivedNotesDetails)