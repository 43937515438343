import { IntlProvider, InjectedIntl, MessageValue } from 'react-intl'

class Translations {
    private intl?: InjectedIntl

    set(intlProvider: IntlProvider) {
        this.intl = intlProvider.getChildContext().intl
    }

    format(id: string, args?: { [key: string]: MessageValue }) {
        return this.intl && this.intl.messages[id] ? this.intl.formatMessage({ id }, args) : id
    }
}

const translations = new Translations()

export default translations;
