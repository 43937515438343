import { Button, Table } from 'semantic-ui-react';
import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import React from 'react';
import { ConfirmedQuantityTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/ConfirmedQuantityTableCell/constants';

export const ConfirmedQuantityTableCell = ({
  column,
  line,
  documentPreviewHandler,
}: ConfirmedQuantityTableCellProps) => (
  <Table.Cell key={column.name} textAlign="right">
    {line[column.name]}
    <InfoIcon
      documents={line.gdnDocuments}
      clickableItem={(document) => (
        <Button
          style={{ display: 'block' }}
          compact
          basic
          onClick={() => {
            documentPreviewHandler(document);
          }}
        >
          {document}
        </Button>
      )}
      noResultsMessage="Brak dokumentów"
    />
  </Table.Cell>
);
