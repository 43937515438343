import React, { useEffect, useMemo, useState } from 'react'

import { FormattedMessage } from "react-intl"
import { Card, Grid, Header, SemanticWIDTHSNUMBER } from "semantic-ui-react"
import './style.scss'

type TWidth = SemanticWIDTHSNUMBER | number

export type TDetailCardProps = {
    id: string
    title: string
    width?: TWidth
    smallWidth?: TWidth
    minHeight?: number
    style?: React.CSSProperties
    titleFormat?: boolean
    maxHeight?: string
}

const DetailCard: React.FC<TDetailCardProps> = ({
    id,
    title,
    children,
    width = 4,
    smallWidth = width >= 8 ? 16 : 8,
    minHeight = 200,
    style,
    titleFormat,
    maxHeight
}) => {

    // eslint-disable-next-line no-restricted-globals
    const [viewWidth, setViewWidth] = useState(innerWidth) 

    const updateWindowDimensions = (): void => {
        // eslint-disable-next-line no-restricted-globals
        setViewWidth(innerWidth)
    }

    useEffect(() => {
        updateWindowDimensions()
        window.addEventListener('resize', updateWindowDimensions)
    }, [])

    const renderedCard: JSX.Element = useMemo(() => {
        return (
            <Card fluid style={style}>
                <div id={id} className="ui form" style={{ margin: 20, minHeight, maxHeight: maxHeight ? maxHeight : 'auto' }}>
                    <Header style={{ fontSize: 18 }}>
                        { titleFormat ? <span>{title}</span> : <FormattedMessage id={title} /> }
                    </Header>
                    {children}
                </div>
            </Card>
        )
    }, [title, children, maxHeight, minHeight, style, titleFormat, id])

    const getWidth = (width: TWidth): string => width * 6.25 + '%'

    const wrapperWidth = viewWidth > 1300 ? getWidth(width) : viewWidth > 1050 ? getWidth(smallWidth) : '100%'

    return (
        <Grid.Column className="detail-card" style={{ width: wrapperWidth }}>
            {renderedCard}
        </Grid.Column>
    )
}

export default DetailCard
