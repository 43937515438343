import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Modal,
  Segment,
} from "semantic-ui-react";
import { TNewAnnouncement } from "~/pages/Administration/lib/Announcements/AddAnnouncement/AddAnnouncement";
import AnnouncementRolesCard from "~/pages/Administration/lib/Announcements/AnnouncementDetails/lib/AnnouncementRolesCard";
import { AnnouncementType } from "~/pages/Dashboard/lib/constans";
import { addLibraryItem } from "~/store/library/actions";
import { NewLibraryItem } from "~/store/library/types";
import { toastError } from "~/utils/toast";

type TProps = {
  openAddModal: boolean;
  handleCloseAddModal: () => void;
  addLibraryItem: typeof addLibraryItem;
  setAddActionPending: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddLibraryItem: React.FC<TProps> = ({
  openAddModal,
  handleCloseAddModal,
  addLibraryItem,
  setAddActionPending,
}) => {
  const [file, setFile] = useState<File>();
  const [description, setDescription] = useState<string>("");
  const [rolesItem, setRolesItem] = useState<TNewAnnouncement>({
    content: null,
    startDate: "",
    endDate: "",
    intendedFor: [],
    title: "",
    hidden: false,
    image: null,
    priority: 1,
    type: AnnouncementType.TEXT,
  });
  const [availableFileExtensions] = useState(["pdf", "docx", "xlsx", "csv"]);

  useEffect(() => {
    if (!openAddModal) {
      setFile(undefined);
      setDescription("");
      setRolesItem((item) => ({ ...item, intendedFor: [] }));
    }
  }, [openAddModal]);

  const checkIsValidExtension = useCallback(
    (file: File) => {
      const nameParts = file.name.split(".");
      return availableFileExtensions.includes(nameParts[nameParts.length - 1]);
    },
    [availableFileExtensions]
  );

  const handleFileChange = useMemo(
    () =>
      (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (event?.target?.files?.length !== 0) {
          const file = event.target.files?.[0] as File;
          if (checkIsValidExtension(file)) {
            setFile(file);
          } else {
            toastError("Niewłaściwy format pliku", "Wybierz inny format.");
          }
        }
      },
    [checkIsValidExtension]
  );

  const onChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      setDescription(data.value);
    },
    []
  );

  const mapData = useCallback(() => {
    if (!file) return;
    setAddActionPending(true);
    const DTO: NewLibraryItem = {
      file,
      description,
      customerClasses: rolesItem.intendedFor,
      name: file.name,
    };
    addLibraryItem(DTO);
    handleCloseAddModal();
  }, [
    file,
    description,
    rolesItem.intendedFor,
    addLibraryItem,
    handleCloseAddModal,
    setAddActionPending,
  ]);

  const isValid = useCallback(() => {
    if (!file || !rolesItem.intendedFor.length) {
      return false;
    } else {
      return true;
    }
  }, [file, rolesItem]);

  return (
    <Modal
      open={openAddModal}
      onClose={handleCloseAddModal}
      size="large"
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Icon name="add" />
        Dodaj do biblioteki
      </Modal.Header>
      <Modal.Content>
        <Segment placeholder>
          <Header icon>
            <Icon name="file archive outline" />
            {file ? file.name : "Załaduj plik"}
          </Header>
          <Input
            type="file"
            onChange={handleFileChange}
            style={{ maxWidth: "18rem" }}
          />
          <p style={{ textAlign: "center", marginTop: 5 }}>
            Dopuszczalne rozszerzenia plików:{" "}
            <strong>
              {availableFileExtensions.map((item, index) =>
                index === 0 ? item : `, ${item}`
              )}
            </strong>
          </p>
        </Segment>
        <div style={{ display: "flex", alignItems: "flex-start", gap: 10 }}>
          <Input
            type="text"
            style={{ width: "60%", marginTop: 12 }}
            placeholder="Opis..."
            value={description}
            onChange={onChangeDescription}
          />
          <div style={{ width: "40%" }}>
            <AnnouncementRolesCard
              edit={true}
              announcement={rolesItem}
              forLibrary
              setAnnouncementForEdit={setRolesItem}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Anuluj" onClick={handleCloseAddModal} />
        <Button
          content="Dodaj"
          primary
          onClick={mapData}
          disabled={!isValid()}
        />
      </Modal.Actions>
    </Modal>
  );
};
