import { connect } from 'react-redux'
import { Button, Form, Header, Modal, Search, FormGroup } from 'semantic-ui-react'
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper'
import { ModelInput } from '~/components/ModelForm/ModelInput'
import { SubmitButton } from '~/components/ModelForm/SubmitButton'
import { REQUIRED, MIN_VALUE, NUMBER_VALUES } from '~/components/SmartField/lib/validator'
import { ApplicationState } from '~/store'
import { addSparePartStock, clearCreated } from '~/store/distrigo-warehouses/actions'
import { fetchPriceList } from '~/store/price-list/actions'
import { StockWarehouse, SparePartStockBase } from '~/store/distrigo-warehouses/types'
import { SparePartPricing } from '~/store/price-list/types'
import { toastWarn } from '~/utils/toast'
import { FormattedMessage } from 'react-intl'

import React, { useState, SyntheticEvent, Fragment, useEffect } from 'react'

import './Add.scss';

type TReduxState = {
    created?: string
    creating?: boolean
    loadingProducts: boolean
    products: SparePartPricing[]
}

type TReduxActions = {
    addSparePartStock: typeof addSparePartStock
    fetchPriceList: typeof fetchPriceList
    clearCreated: typeof clearCreated
}

type TAddSparePartStockProps = TReduxState & TReduxActions & {
    triggerOpen: boolean
    warehouseId: string
    createdSuccess: () => void
}

type TSparePart = {
    key: string
    price: string
    title: string
    description: string
}

const AddSparePartStock: React.FC<TAddSparePartStockProps> = ({
    children,
    triggerOpen,
    warehouseId,
    created,
    creating,
    loadingProducts,
    products,
    createdSuccess,
    addSparePartStock,
    fetchPriceList,
    clearCreated
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const [rrdi, setRrdi] = useState<string>('')
    const [checked, setChecked] = useState<boolean>(false)
    const [labelProductName, setLabelProductName] = useState<string>('')

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    const handleConfirm = (stock: StockWarehouse): void => {
        if (Number(stock.stockMin) > Number(stock.stockMax)) {
            return toastWarn('Dodanie części niemożliwe', 'Minimalny stan magazynowy musi być mniejszy niż jego stan maksymalny.')
        }
        if (!creating) {
            stock = {
                ...stock,
                referenceWarehouseId: {
                    warehouseId,
                    referenceId: rrdi
                }
            }
            addSparePartStock(stock)
        }
    }

    const handleCancel = (): void => {
        if (!creating) {
            setOpen(false)
        }
    }

    const handleOpen = (): void => setOpen(true)

    const mapProducts = (products: SparePartPricing[]): TSparePart[] => {
        return products.map(product => ({
            key: product.referenceId,
            price: `${product.pvpPrice.toFixed(2)} PLN`,
            title: product.referenceId,
            description: product.description,
            packingIsSent100: product.packingIsSent100,
        }))
    }

    const searchProduct = (event: SyntheticEvent, data: any): void => {
        fetchPriceList(data.value)
        setRrdi(data.value)
    }

    const setProductRRDI = (event: SyntheticEvent, data: any): void => {
        setRrdi(data.result.key)
        const product = products.filter(x => x.referenceId === data.result.key)

        if (product) {
            setLabelProductName(product.map(x => x.description)[0])
            setChecked(product.map(x => x.packingIsSent100)[0])
        }
    }

    if (created && open) {
        clearCreated()
        setOpen(false)
        setRrdi('')
        setLabelProductName('')
        createdSuccess()
    }

    return (
        <Modal
            size="tiny"
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}
        >
            <ModelFormWrapper
                onSubmit={handleConfirm} >
                <Header icon='settings' content='Dodaj część magazynu' />
                <Modal.Content>
                    <Form style={{ padding: 10 }}>
                        <Form.Group widths="equal">
                            <Search
                                className="SearchInput"
                                placeholder="Wyszukaj referencje"
                                fluid
                                aligned="left"
                                loading={loadingProducts}
                                noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
                                onResultSelect={(e, d) => setProductRRDI(e, d)}
                                onSearchChange={(e, d) => searchProduct(e, d)}
                                results={mapProducts(products)}
                                value={rrdi}
                            />
                            <Fragment>
                                <div className="LabelProductName">{labelProductName}</div>
                            </Fragment>
                        </Form.Group>
                        <FormGroup widths="equal">
                            <ModelInput<SparePartStockBase>
                                label="Stock MIN"
                                placeholder="Minimalna wartość stocku"
                                name="stockMin"
                                type="number"
                                validators={[REQUIRED, MIN_VALUE(0), NUMBER_VALUES]} />
                            <ModelInput<SparePartStockBase>
                                label="Stock MAX"
                                placeholder="Maksymalna wartość stocku"
                                name="stockMax"
                                type="number"
                                validators={[REQUIRED, MIN_VALUE(0), NUMBER_VALUES]} />
                        </FormGroup>
                        <div className="CheckobxContainer">
                            {checked && "Dotyczy SENT 100"}
                        </div>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <SubmitButton content="Dodaj" disabled={rrdi === ''} icon="add" labelPosition="right" primary loading={creating} />
                    <Button content="Zamknij" onClick={handleCancel} />
                </Modal.Actions>
            </ModelFormWrapper>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ distrigoWarehouses, pricing }: ApplicationState) => {
    return {
        products: pricing.list,
        created: distrigoWarehouses.created,
        creating: distrigoWarehouses.creating,
        loadingProducts: pricing.loading,
    }
}

const mapDispatchToProps: TReduxActions = { addSparePartStock, fetchPriceList, clearCreated }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSparePartStock)