import {
    Breadcrumb,
    BreadcrumbSectionProps,
    Button,
    Divider,
    Header,
    Modal,
    Segment,
    SemanticShorthandItem
} from 'semantic-ui-react'
import { AdditionalData, TCollision } from '~/store/promotions/types'

import React, { useMemo } from 'react'

export const usePromoCollisionModal = (
    open: boolean,
    validationData: AdditionalData[] | undefined,
    closeHandler: () => void
): JSX.Element => {

    const toSection = (data: TCollision): SemanticShorthandItem<BreadcrumbSectionProps>[] => {
        const sections = [{ key: data.index, content: data.index }]
        if (data.segment && data.type !== "INDEX") sections.push({ key: data.segment, content: data.segment })
        if (data.slot && data.type !== "INDEX" && data.type !== "SEGMENT") sections.push({
            key: data.slot,
            content: data.slot
        })
        if (data.family && (data.type === "FAMILY" || data.type === "PRODUCT")) sections.push({
            key: data.family,
            content: data.family
        })
        if (data.referenceId && data.type === "PRODUCT") sections.push({
            key: data.referenceId,
            content: data.referenceId
        })
        return sections
    }

    return useMemo(() => (
        <Modal
            open={open}
            onClose={closeHandler}
            size='tiny'
            closeOnDimmerClick={false}
        >
            <Header icon="clone" content="Kolizja promocji" />
            {validationData && validationData.length === 0 ?
                <Modal.Content>
                    Wybrane hierarchie produktów nie występują w żadnej innej aktywnej promocji.
                </Modal.Content>
                :
                <Modal.Content>
                    Wybrane hierarchie produktów kolidują z istniejącymi już promocjami.
                    <Divider/>
                    <div style={{ maxHeight: 400, overflow: 'auto', paddingLeft: 15, paddingRight: 15 }}>
                        {validationData && validationData.map((data, index) => (
                            <Segment key={data.code}>
                                <div><b>Promocja: {data.code}</b>
                                    <div style={{ float: 'right' }}>Priorytet: {data.priority || 0}</div>
                                </div>
                                <div key={index}><Breadcrumb icon="right angle"
                                                             sections={toSection(data.collision)}></Breadcrumb></div>
                            </Segment>
                        ))}
                    </div>
                </Modal.Content>}
            <Modal.Actions>
                <Button content="Zamknij" onClick={closeHandler} />
            </Modal.Actions>
        </Modal>
    ), [closeHandler, open, validationData])
}
