import React, { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import { FormInputNumber, InputNumberProps } from '~/components/InputNumber/InputNumber';
import { BonusTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';
import { validNumber } from '~/pages/Orders/lib/Add/utility';
import { DELIVERY_TYPE_ENUM } from '~/store/orders/types';
import { parseNumber } from '~/utils/parseNumber';
import { calculateBonusValue } from '~/pages/Orders/lib/Add/Table/TableBody/utils';
import { TLine } from '~/pages/Orders/lib/Add/constants';
import './styles.scss';

import { getExceededLimitToast } from '~/components/ExceededLimitToast';

const BonusTableCell = ({ order, line, summary, setLines, limit }: BonusTableCellProps) => {
  const isSpecialOrBonusOrder = useMemo(
    () =>
      order.type === DELIVERY_TYPE_ENUM.SPECIAL ||
      order.type === DELIVERY_TYPE_ENUM.BONUS ||
      order.type === DELIVERY_TYPE_ENUM.BONUS_PLUS,
    [order]
  );

  const changeBonusValue = useCallback(() => {
    if (order.type === DELIVERY_TYPE_ENUM.NORMAL) {
      return calculateBonusValue(line.sellPrice, line.pvpPrice);
    } else {
      return calculateBonusValue(line.sellPrice, line.pnrPrice);
    }
  }, [line.pnrPrice, line.pvpPrice, line.sellPrice, order.type]);

  const checkIsBiggerThanLimit = useCallback(
    (line: TLine) => limit < (line?.bonus || changeBonusValue()),
    [changeBonusValue, limit]
  );

  const changeBonus = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines((lines) =>
        lines.map((line) => {
          if (line.lineNumber === lineNumber) {
            let bonus = data.value || 0;

            bonus = Math.min(100, Math.max(0, bonus));

            return {
              ...line,
              bonus,
              sellPrice: parseFloat((line.pnrPrice * (1 - bonus / 100)).toFixed(2)),
            };
          }
          return { ...line };
        })
      );
    },
    [setLines]
  );

  const isOrangeCell = useMemo(
    () => checkIsBiggerThanLimit(line) && isSpecialOrBonusOrder,
    [checkIsBiggerThanLimit, isSpecialOrBonusOrder, line]
  );

  useEffect(() => {
    if (isOrangeCell) {
      getExceededLimitToast();
    }
  }, [isOrangeCell]);

  return (
    <Table.Cell className={isOrangeCell ? 'orangeTableCell' : ''}>
      {order.type === DELIVERY_TYPE_ENUM.BONUS || order.type === DELIVERY_TYPE_ENUM.BONUS_PLUS ? (
        line.orderLineType === 'REPLACED' ? (
          'n/d'
        ) : (
          <FormInputNumber
            min={0}
            max={100}
            precision={2}
            value={validNumber(line.bonus) ? line.bonus : 0}
            readOnly={summary}
            style={{ width: 100, opacity: 1 }}
            onChange={(e: any, d: InputNumberProps) => changeBonus(line.lineNumber, e, d)}
          />
        )
      ) : (
        <div style={{ width: 100 }}>{parseNumber(line.bonus || 0)} %</div>
      )}
    </Table.Cell>
  );
};

export default BonusTableCell;
