import { useContext } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";


export const useAccountingRolesRights = () => {

    const context = useContext(AppContext)

    const userHaveLdcUiAccountingInvoiceCorrectionRole = context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_INVOICE_CORRECTION
    )

    const userHaveLdcUiAccountingCollectiveInvoiceCorrectionRole = context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_COLLECTIVE_INVOICE_CORRECTION
    )


    return {
        userHaveLdcUiAccountingInvoiceCorrectionRole,
        userHaveLdcUiAccountingCollectiveInvoiceCorrectionRole
    }
}    
