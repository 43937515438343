import {
  Button,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import { parsePrice } from "~/utils/parsePrice";
import { ICollectiveCorrectionsList } from "~/store/invoices/types";

import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ExampleCorrectiveCollections from "~/assets/images/examples/collective-corrections-xls.png";
import PreviewImage from "~/components/PreviewImage/PreviewImage";

type TUploadingError = {
  additionalData: {
    relatedIdentifiers: string[];
    type: string;
  }[];
  code: string;
  error: string;
  message: string;
  timestamp: string;
};

type TProps = {
  open: boolean;
  correctionLines: ICollectiveCorrectionsList[];
  loadingCollectiveCorrections: boolean;
  loadingConfirmCollectiveCorrections: boolean;
  created: boolean;
  uploadingError: TUploadingError;
  closeHandler: () => void;
  confirmCollectiveCorrectionsLines: (
    correctionLines: ICollectiveCorrectionsList[]
  ) => void;
  previewCollectiveCorrectionPdf: (
    correctionLines: ICollectiveCorrectionsList
  ) => void;
  uploadCollectiveCorrections: (file: File) => void;
  clearCollectiveCorrections: () => void;
};

type TCollectiveCorrectionsModal = TProps;

export const CollectiveCorrectionsModal: React.FC<
  TCollectiveCorrectionsModal
> = ({
  open,
  correctionLines,
  loadingCollectiveCorrections,
  loadingConfirmCollectiveCorrections,
  uploadingError,
  created,
  uploadCollectiveCorrections,
  confirmCollectiveCorrectionsLines,
  previewCollectiveCorrectionPdf,
  clearCollectiveCorrections,
  closeHandler,
}) => {
  const [file, setFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState<string>();
  const [showListOfErrors, setShowListOfErrors] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  const handleClose = useCallback((): void => {
    setFile(undefined);
    setFileUploaded("");
    clearCollectiveCorrections();
    closeHandler();
  }, [clearCollectiveCorrections, closeHandler]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") handleClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleClose]);

  const handleLoad = useCallback((): void => {
    if (file) {
      uploadCollectiveCorrections(file);
      setFileUploaded(file.name);
    }
  }, [file, uploadCollectiveCorrections]);

  const handleConfirm = useCallback((): void => {
    if (correctionLines && correctionLines.length) {
      confirmCollectiveCorrectionsLines(correctionLines);
    }
  }, [confirmCollectiveCorrectionsLines, correctionLines]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  const sumUpBillingLines = (billingLines: any[]): number =>
    billingLines.reduce((sum, { priceNet }) => sum + priceNet, 0);

  const renderPreviewPDFFile = useCallback(
    (correctionLine: ICollectiveCorrectionsList): JSX.Element => {
      return (
        <Button
          content="PDF"
          icon="eye"
          labelPosition="left"
          onClick={() => previewCollectiveCorrectionPdf(correctionLine)}
          style={{ marginRight: 0 }}
        />
      );
    },
    [previewCollectiveCorrectionPdf]
  );

  const renderCollectiveCorrectionsLines = useCallback((): ReactNode => {
    if (correctionLines && correctionLines.length) {
      return correctionLines.map((line, index) => (
        <Table.Row key={index}>
          <Table.Cell>
            {line.paymentCustomer.id} {line.paymentCustomer.name}
          </Table.Cell>
          <Table.Cell>{line.sourceLines.length}</Table.Cell>
          <Table.Cell>
            {line.type === "COLLECTIVE_INVOICE_CORRECTION" ? "Faktura" : "Nota"}
          </Table.Cell>
          <Table.Cell>
            {parsePrice(sumUpBillingLines(line.billingLines))}
          </Table.Cell>
          <Table.Cell>{line.bonusName}</Table.Cell>
          <Table.Cell>{renderPreviewPDFFile(line)}</Table.Cell>
        </Table.Row>
      ));
    } else {
      return <Fragment />;
    }
  }, [correctionLines, renderPreviewPDFFile]);

  const renderUploadFile = useCallback((): JSX.Element => {
    return (
      <>
        <Header icon>
          <Icon name="file archive outline" />
          {file ? file.name : "Załaduj plik"}
        </Header>
        <Input type="file" onChange={handleFileChange} />
      </>
    );
  }, [file]);

  const renderCorrectionTable = useCallback((): JSX.Element => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kontrahent</Table.HeaderCell>
            <Table.HeaderCell>Liczba dokumentów korygowanych</Table.HeaderCell>
            <Table.HeaderCell>Typ dokumentu wynikowego</Table.HeaderCell>
            <Table.HeaderCell>Kwota netto korekty</Table.HeaderCell>
            <Table.HeaderCell>Nazwa bonusu</Table.HeaderCell>
            <Table.HeaderCell>Podgląd</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderCollectiveCorrectionsLines()}</Table.Body>
      </Table>
    );
  }, [renderCollectiveCorrectionsLines]);

  const handleBack = useCallback((): void => {
    clearCollectiveCorrections();
    setFile(undefined);
    setFileUploaded("");
  }, [clearCollectiveCorrections]);

  const transformMessage = (message: string = ""): string => {
    if (message.includes("Invalid number off bonuses found")) {
      return message
        .replace(
          "Invalid number off bonuses found",
          "Znaleziono niepoprawną ilość bonusów"
        )
        .replace("for payment customer", "dla płatnika");
    } else return message;
  };

  const renderSingleError = useCallback(
    (
      errorGroup: string,
      message?: string,
      relatedIdentifiersList?: string
    ): string => {
      switch (errorGroup) {
        case "CUSTOMER_NOT_EXISTS":
          return "Kontrahent nie istnieje" + relatedIdentifiersList;
        case "PRICE_TOO_LOW":
          return "Błędna kwota sumaryczna" + relatedIdentifiersList;
        case "COLLECTIVE_CORRECTION_FILE_PARSE_BONUS_ERROR":
          return transformMessage(message);
        case "COLLECTIVE_CORRECTION_FILE_PARSE_EOTP_ERROR":
          return "Niepoprawna ilość numerów EOTP" + relatedIdentifiersList;
        case "COLLECTIVE_CORRECTION_FILE_PARSE_ERROR":
          return "Błąd parsowania pliku" + relatedIdentifiersList;
        case "NO_SOURCE_DOCUMENTS":
          return "Brak dokumentów źródłowych" + relatedIdentifiersList;
        case "CUSTOMER_BLOCKED":
          return "Zablokowany klient" + relatedIdentifiersList;
        case "BILLING_LINE_DEFICIENT":
          return "Niekompletny rekord księgowy" + relatedIdentifiersList;
        case "SOURCE_DOCUMENT_DEFICIENT":
          return (
            "Niekompletny rekord dokumentu źródłowego" + relatedIdentifiersList
          );
        case "CUSTOMER_HAS_UNDEFINED_PAYMENT_METHOD":
          return (
            "Klient ma nieokreśloną metodę płatności" + relatedIdentifiersList
          );
        default:
          return "";
      }
    },
    []
  );

  const renderError = useCallback(
    (): JSX.Element => (
      <>
        <p style={{ color: "red" }}>
          Wybrany plik zawiera błędy! Skoryguj plik i spróbuj ponownie.
        </p>

        <p
          style={{
            color: "red",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => setShowListOfErrors(!showListOfErrors)}
        >
          Lista błędów
        </p>

        <ul style={{ display: showListOfErrors ? "block" : "none" }}>
          {uploadingError && uploadingError.additionalData ? (
            uploadingError.additionalData.map((err, index: number) => {
              return (
                <li
                  key={index}
                  style={{
                    display: "block",
                    color: "red",
                  }}
                >
                  {renderSingleError(
                    err.type,
                    "",
                    ` (dla płatników: ${err.relatedIdentifiers.join(", ")})`
                  )}
                </li>
              );
            })
          ) : (
            <li
              style={{
                display: "block",
                color: "red",
              }}
            >
              {renderSingleError(
                uploadingError.code,
                uploadingError.message,
                ""
              )}
            </li>
          )}
        </ul>
      </>
    ),
    [renderSingleError, showListOfErrors, uploadingError]
  );

  const renderFileHeader = useCallback(
    (): JSX.Element => (
      <>
        {correctionLines && correctionLines.length ? (
          <p>
            Przejrzyj poniższą listę. Na jej podstawie zostaną wygenerowane
            dokumenty korekt zbiorczych. <br />
            Aby zmienić dane, wróć do poprzedniego kroku i załaduj nowy plik
            źródłowy
          </p>
        ) : (
          <p>
            {" "}
            Załaduj plik XLSX, XLS, CSV według wzorcowego formatu.
            <br />
            <span style={{ color: "gray", fontSize: "10px" }}>
              *Maksymalny rozmiar pliku 15MB.
            </span>
          </p>
        )}
      </>
    ),
    [correctionLines]
  );

  return useMemo(
    () => (
      <Modal
        open={open}
        onClose={closeHandler}
        size="large"
        closeOnDimmerClick={false}
      >
        <Header
          icon="file archive"
          className={"collective-corrections-modal-header"}
          content={
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
              }}
            >
              <p style={{ marginBottom: 0 }}>Korekty zbiorcze faktur/not</p>
              {!created && (
                <p style={{ textAlign: "right" }}>
                  Krok {correctionLines.length === 0 ? "1" : "2"}/2
                </p>
              )}
            </div>
          }
        />
        <Modal.Content>
          <Message size="small">
            <Message.Content>
              {uploadingError ? renderError() : renderFileHeader()}
            </Message.Content>
          </Message>
          <PreviewImage src={ExampleCorrectiveCollections} />
          <Segment placeholder>
            {correctionLines.length && file && !created
              ? renderCorrectionTable()
              : null}
            {!correctionLines.length ? renderUploadFile() : null}
            {created && (
              <>
                {" "}
                <p style={{ textAlign: "center" }}>
                  <Icon name="check circle outline" size="huge" />
                  <span style={{ marginTop: "15px", display: "block" }}>
                    Pomyślnie wgrano plik.
                  </span>
                </p>
              </>
            )}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          {correctionLines && correctionLines.length && file && !created ? (
            <Button content="Wróć" onClick={() => handleBack()} />
          ) : null}
          {!correctionLines.length ? (
            <Button
              primary
              content="Wczytaj"
              icon="upload"
              labelPosition="right"
              loading={loadingCollectiveCorrections}
              disabled={
                loadingCollectiveCorrections ||
                !file ||
                fileUploaded === file.name
              }
              onClick={handleLoad}
            />
          ) : (
            !created && (
              <Button
                primary
                content="Utwórz dokumenty"
                loading={loadingConfirmCollectiveCorrections}
                disabled={loadingConfirmCollectiveCorrections}
                icon="plus"
                labelPosition="left"
                onClick={handleConfirm}
              />
            )
          )}
          <Button content="Zamknij" onClick={() => handleClose()} />
        </Modal.Actions>
      </Modal>
    ),
    [
      open,
      loadingCollectiveCorrections,
      loadingConfirmCollectiveCorrections,
      file,
      correctionLines,
      created,
      uploadingError,
      closeHandler,
      fileUploaded,
      handleBack,
      handleClose,
      handleConfirm,
      handleLoad,
      renderCorrectionTable,
      renderError,
      renderFileHeader,
      renderUploadFile,
    ]
  );
};
