import { TNode } from "~/components/Tree/lib/types"

export type TNodeData = TNode & {
    group: TGroup
}

export type TGroup = 'INDEX' | 'SEGMENT' | 'SLOT' | 'FAMILY' | 'REFERENCE'

export enum HierarchyActionTypes {
    FETCH_SEGMENTS = '@@promotions/FETCH_SEGMENTS',
    FETCH_SLOTS = '@@promotions/FETCH_SLOTS',
    FETCH_FAMILIES = '@@promotions/FETCH_FAMILIES',
    FETCH_REFERENCES = '@@promotions/FETCH_REFERENCES',
    FETCH_SUCCESS = '@@promotions/FETCH_SUCCESS',
    ADVANCED_SEARCH_FETCH = '@@promotions/ADVANCED_SEARCH_FETCH',
    ADVANCED_SEARCH_FETCH_GROUP_1_SUCCESS = '@@promotions/ADVANCED_SEARCH_FETCH_GROUP_1_SUCCESS',
    ADVANCED_SEARCH_FETCH_GROUP_2_SUCCESS = '@@promotions/ADVANCED_SEARCH_FETCH_GROUP_2_SUCCESS'
}

export interface HierarchyState {
    readonly fetched: TNodeData[]
    readonly failed: boolean
    readonly parent?: TNodeData
    readonly advancedSearchResponseGroup1: any[]
    readonly advancedSearchResponseGroup2: any[]
}
