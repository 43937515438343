import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Header, Modal } from "semantic-ui-react";

import { fetchBackBoxReport } from "~/store/claims/actions";

type TReduxActions = {
  fetchBackBoxReport: typeof fetchBackBoxReport;
};

type TCancelOrderProps = {
  closeModal: () => void;
  openBackInTheBoxModal: boolean;
};

const FetchBackInTheBoxReport = ({
  fetchBackBoxReport,
  closeModal,
  openBackInTheBoxModal,
}: TCancelOrderProps & TReduxActions) => {
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [dateFromError, setDateFromError] = useState<string | null>(null);
  const [dateToError, setDateToError] = useState<string | null>(null);

  useEffect(() => {
    setDateToError(null);
    setDateFromError(null);
  }, [dateFrom, dateTo]);

  const handleCloseModal = useCallback(() => {
    setDateFrom(null);
    setDateTo(null);
    closeModal();
  }, [closeModal]);

  const handleSubmit = useCallback(() => {
    const validate = (): boolean => {
      const requiredFieldMessage = "To pole jest wymagane";
      const badFiledsValues = "Niepoprawny zakres dat";
      if (!dateFrom) {
        setDateFromError(requiredFieldMessage);
        return false;
      } else if (!dateTo) {
        setDateToError(requiredFieldMessage);
        return false;
      } else if (new Date(dateTo) < new Date(dateFrom)) {
        setDateFromError(badFiledsValues);
        setDateToError(badFiledsValues);
        return false;
      } else {
        return true;
      }
    };
    const isValid = validate();
    if (!isValid || !dateFrom || !dateTo) return;
    fetchBackBoxReport({ dateFrom, dateTo });
    handleCloseModal();
  }, [fetchBackBoxReport, dateFrom, dateTo, handleCloseModal]);

  return (
    <Fragment>
      <Modal open={openBackInTheBoxModal} size="small" onClose={closeModal}>
        <Modal.Header>
          <Header icon="download" content="Raport Back in the Box" />
        </Modal.Header>
        <Modal.Content>
          <p>
            W celu wygenerowania raportu .csv Back in the Box wskaż przedział
            czasowy dotyczący utworzenia reklamacji:
          </p>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                label="Data od"
                autoComplete="off"
                error={dateFromError}
                id="dateTo"
                locale="pl-PL"
                max={dateTo}
                onChange={(e) => setDateFrom(e.target.value)}
                type="date"
                value={dateFrom}
              />
              <Form.Input
                label="Data do"
                autoComplete="off"
                className="DatePicker"
                error={dateToError}
                id="dateTo"
                locale="pl-PL"
                min={dateFrom}
                onChange={(e) => setDateTo(e.target.value)}
                type="date"
                value={dateTo}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Eksportuj" onClick={handleSubmit} />
          <Button content="Anuluj" onClick={handleCloseModal} />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

const mapDispatchToProps: TReduxActions = {
  fetchBackBoxReport,
};

export default connect(null, mapDispatchToProps)(FetchBackInTheBoxReport);
