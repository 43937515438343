import { Reducer } from 'redux'
import { VersionActionTypes, VersionState } from './types'

export const initialVersionState: VersionState = {
    loading: false,
    version: ''
}

const reducer: Reducer<VersionState> = (state = initialVersionState, action) => {
    switch (action.type) {
        case VersionActionTypes.FETCH_VERSION: {
            return { ...state, loading: true }
        }
        case VersionActionTypes.FETCH_VERSION_SUCCESS: {
            return { ...state, loading: false, version: action.payload.version.version }
        }
    }

    return state
}

export {reducer as versionReducer}