import React, { SyntheticEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Button, Dropdown, Form } from "semantic-ui-react";
import { createPaymentMethod } from "~/store/payment-methods/actions";
import { HandleChange } from "~/store/warehouses-in-calendar/types";
import { ApplicationState } from "~/store";
import CommonLoader from "~/components/Loaders/CommonLoader";
import { isDatePast } from "~/utils/dateUtils";
import { DictionariesState } from "~/store/dictionaries/types";
import moment from "moment";

type TAddPaymentMethodProps = {
  editMode: boolean;
  customerId: string;
  dictionaries: DictionariesState;
  refreshResources: () => void;
};

const AddPaymentMethod = ({
  editMode = true,
  customerId,
  dictionaries,
  refreshResources,
}: TAddPaymentMethodProps) => {
  const [validFrom, setValidFrom] = useState("");
  const [type, setType] = useState<any>("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { creating } = useSelector(
    (state: ApplicationState) => state.paymentMethods
  );

  useEffect(() => {
    if (validFrom && type && validFrom !== "" && type !== "") {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [validFrom, type]);

  const handleChange = (
    e: SyntheticEvent<Element, Event> | undefined,
    { value }: HandleChange
  ) => {
    setValidFrom(value);
  };

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const handleType = (event: SyntheticEvent, data: any): void => {
    setType(data.value);
  };

  const handleAdd = (): void => {
    dispatch(
      createPaymentMethod(
        customerId,
        {
          validFrom: moment(validFrom).format("YYYY-MM-DDT00:00:00"),
          type: type,
        },
        refreshResources
      )
    );
    setValidFrom("");
    setType("");
    // setTimeout(() => refreshResources(), 500);
  };

  return (
    <>
      <Form.Field required>
        <label>
          {formatMessage("app.customer.addPaymentForm.paymentMethod")}
        </label>
        <Dropdown
          placeholder="Wybierz kategorię"
          fluid
          selection
          value={type}
          onChange={handleType}
          options={dictionaries["payment-method"].filter(
            (item) => item.key !== "CREDIT_CARD"
          )}
          disabled={!editMode}
          noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
        />
      </Form.Field>
      <Form.Field required>
        <label>Data od:</label>
        <SemanticDatepicker
          id="validFrom"
          filterDate={isDatePast}
          name="dateTo"
          value={validFrom}
          placeholder="RRRR-MM-DD"
          locale="pl-PL"
          onChange={handleChange}
          autoComplete="off"
          disabled={!editMode}
        />
      </Form.Field>
      <div>
        <Button
          icon="add"
          size="medium"
          circular
          basic
          style={{ marginTop: "20px" }}
          className="primary"
          disabled={!editMode || !formValid}
          content="Zatwierdź"
          onClick={() => handleAdd()}
        />
      </div>
      <CommonLoader loading={creating} />
    </>
  );
};

export default AddPaymentMethod;
