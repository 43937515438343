import { connect } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import { ApplicationState } from "~/store";
import {
  removeDictionaryItem,
  clearCreated,
} from "~/store/dictionaries/actions";
import { DictionaryName } from "~/store/dictionaries/types";

import React, { ComponentProps, useState } from "react";

type TReduxState = {
  created?: boolean;
  creating?: boolean;
};

type TReduxActions = {
  removeDictionaryItem: typeof removeDictionaryItem;
  clearCreated: typeof clearCreated;
};

interface TProps extends ComponentProps<any> {
  removedSuccess: () => void;
  name: DictionaryName;
  itemKey: string;
}

type TRemoveDictionaryItemProps = TReduxState & TReduxActions & TProps;

const RemoveDictionaryItem: React.FC<TRemoveDictionaryItemProps> = ({
  children,
  created,
  creating,
  removeDictionaryItem,
  removedSuccess,
  clearCreated,
  name,
  itemKey,
}) => {
  const [open, setOpen] = useState(false);

  const handleConfirm = (): void => {
    if (!creating) {
      removeDictionaryItem(name, itemKey);
    }
  };

  const handleCancel = (): void => {
    clearCreated();
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  if (created && open) {
    clearCreated();
    setOpen(false);
    removedSuccess();
  }

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper onSubmit={handleConfirm}>
        <Header icon="remove" content={`Usuń element słownika: ${name}`} />
        <Modal.Content>
          <p>
            Czy jesteś pewien że chcesz usunąć element o kluczu <b>{itemKey}</b>{" "}
            z słownika <b>{name}</b> ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            content="Usuń"
            icon="save"
            labelPosition="right"
            primary
            loading={creating}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  dictionaries,
}: ApplicationState) => ({
  created: dictionaries.created,
  creating: dictionaries.creating,
});

const mapDispatchToProps: TReduxActions = {
  removeDictionaryItem,
  clearCreated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveDictionaryItem);
