import { TPromotion, TReferenceRule } from '~/store/promotions/types'

const isReferenceRuleValid = (refRule?: TReferenceRule): boolean => {
    if (refRule && refRule.references) {
        return refRule.references.length > 0
    }
    return false
}

const isDefinitionValid = (promotion: TPromotion): boolean => {
    const status = promotion.type
    const discount = promotion.firstDiscount
    const secondDiscount = promotion.secondDiscount

    if (discount) {
        switch (status) {
            case 'CASCADE':
                let secondDiscountValid = true
                if (secondDiscount && (secondDiscount.priceThreshold || secondDiscount.quantityThreshold)) {
                    secondDiscountValid = !!secondDiscount.percentageDiscount;
                }
                return !!(discount.priceThreshold || discount.quantityThreshold) && !!discount.percentageDiscount && secondDiscountValid
            case 'ACTUAL':
                return !!(discount.priceThreshold || discount.quantityThreshold) &&
                    !!((discount.type === 'PERCENTAGE' || discount.type === 'PERCENTAGE_AND_PRICING') ? discount.percentageDiscount : true) &&
                    !!((discount.type === 'PRICING' || discount.type === 'PERCENTAGE_AND_PRICING') ? (discount.amountDiscount && discount.multiple) : true)
            case 'CURRENCY':
                return !!((discount.priceThreshold || discount.quantityThreshold) && discount.amountDiscount)
            case 'ASSOCIATIVE':
                return !!(promotion.associativeDiscountGroup && promotion.associativeDiscountMode && discount.percentageDiscount &&
                    isResultSetCardinalityValid(promotion) && isRuleSetCardinalityValid(promotion))
            case 'GROUP':
                return !!((promotion.firstDiscount && promotion.firstDiscount.type && promotion.firstDiscount.percentageDiscount) ||
                    !!(promotion.firstDiscount && promotion.firstDiscount.type && promotion.firstDiscount.amountDiscount))
        }
    }
    return false
}

function isResultSetCardinalityValid(promo: TPromotion) {
    return promo.resultSet?.referenceRule?.cardinality !== undefined && promo.resultSet.referenceRule.cardinality >= 1;
}

function isRuleSetCardinalityValid(promo: TPromotion) {
    return promo.ruleSet.referenceRule?.cardinality !== undefined && promo.ruleSet.referenceRule.cardinality >= 1;
}



export const isPromotionImportValid = (promotion: TPromotion, file: any): boolean => {
    const validType = promotion.type
    return !!(validType && promotion.dateFrom && promotion.dateFrom !== "" && promotion.dateTo && promotion.dateTo !== "" && file)

}

export const isPromotionValid = (promotion: TPromotion): boolean => {
    const validType = promotion.type
    const validRuleSet = isReferenceRuleValid(promotion && promotion.ruleSet && promotion.ruleSet.referenceRule)
    const validResultSet = promotion.type === 'ASSOCIATIVE' ? isReferenceRuleValid(promotion && promotion.resultSet && promotion.resultSet.referenceRule) : true
    const validDiscount = isDefinitionValid(promotion)
    const validDates: boolean = (promotion.dateFrom != null && promotion.dateTo != null)
    return (!!validType && validRuleSet && validResultSet && validDiscount && validDates)
}