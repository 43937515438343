import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { CategoryCompanyCellProps } from '~/pages/Customers/constants';

const CategoryCompanyCell = ({ categoryCompany }: CategoryCompanyCellProps) =>
  categoryCompany ? (
    <span>
      <Icon name="building outline" /> <FormattedMessage id="app.customer.informationAddressCompany" />
    </span>
  ) : (
    <span>
      <Icon name="user outline" /> <FormattedMessage id="app.customer.person" />
    </span>
  );

export default CategoryCompanyCell;
