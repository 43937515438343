import { connect } from 'react-redux'
import { Modal, Header, Segment, Icon, Input, Button } from 'semantic-ui-react'
import { uploadStockFileExcel, selectDistrigoStocksWarehouse, clearUpload } from '~/store/distrigo-warehouses/actions'
import { ApplicationState } from '~/store'
import ExampleXls from '~/assets/images/examples/import_stock_from_file.png'

import React, { useState, useEffect } from 'react'
import PreviewImage from '~/components/PreviewImage/PreviewImage'
import ModalMessageHeader from '~/components/Modals/ModalMessageHeader'

type TReduxActions = {
    uploadStockFileExcel: typeof uploadStockFileExcel
    selectDistrigoStocksWarehouse: typeof selectDistrigoStocksWarehouse
    clearUpload: typeof clearUpload
}

interface PropsFromState {
    uploading: boolean
    uploaded: boolean
}

type TProps = {
    triggerOpen: boolean
    createdSuccess: () => void
    warehouseId: string,
}

type TUploadXlsModalProps = TReduxActions & PropsFromState & TProps

const UploadStockXls: React.FC<TUploadXlsModalProps> = ({
    children,
    triggerOpen,
    uploaded,
    uploading,
    warehouseId,
    uploadStockFileExcel,
    createdSuccess,
    clearUpload
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const [file, setFile] = useState<File>()

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    const validateFile = (fileName?: string): boolean => {
        if (fileName) {
            const extension = fileName.split('.').pop()
            return extension === 'xls' || extension === 'xlsx' || extension === 'csv'
        }
        return false
    }

    const handleFileChange = (ev: any, el: any): void => {
        const file = ev.target.files[0]
        if (validateFile(file.name)) {
            setFile(file)
        }
    }

    const handleCancel = (): void => setOpen(false)

    const handleOpen = (): void => setOpen(true)

    const handleSaveClick = (file: File | undefined, warehouseId: string): void => {
        if (file && warehouseId) {
            uploadStockFileExcel(file, warehouseId)
        }
    }

    if (uploaded && open) {
        clearUpload()
        createdSuccess()
        handleCancel()
        setFile(undefined)
    }

    return (
        <Modal
            size='small'
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}
        >
            <Header icon="file archive" content="Wgrywanie części stocku" />
            <Modal.Content>
                <ModalMessageHeader format />
                <PreviewImage src={ExampleXls} />
                <Segment placeholder>
                    <Header icon>
                        <Icon name="file archive outline" />
                        {file ? file.name : "Załaduj plik"}
                    </Header>
                    <Input type="file" onChange={handleFileChange} />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Wgraj" disabled={file === undefined || !validateFile(file.name)} loading={uploading} icon="upload" labelPosition="right" onClick={() => handleSaveClick(file, warehouseId)} />
                <Button content="Zamknij" onClick={handleCancel} />
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps = ({ distrigoWarehouses }: ApplicationState) => ({
    uploading: distrigoWarehouses.uploadingStock,
    uploaded: distrigoWarehouses.uploadedStock,
})

const mapDispatchToProps: TReduxActions = { uploadStockFileExcel, selectDistrigoStocksWarehouse, clearUpload }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadStockXls)