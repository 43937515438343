import React, { SyntheticEvent } from 'react';
import { FormInputNumber, InputNumberOnChangeData } from '~/components/InputNumber/InputNumber';
import { parsePrice } from '~/utils/parsePrice';
import { Table } from 'semantic-ui-react';
import { SellPriceCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';
import { validNumber } from '~/pages/Orders/lib/Add/utility';
import { calculateBonusValue } from '~/pages/Orders/lib/Add/Table/TableBody/utils';

const SellPriceCell = ({ order, summary, line, setLines }: SellPriceCellProps) => {
  const changePnrPrice = (lineNumber: number, event: SyntheticEvent, data: any): void => {
    setLines((lines) =>
      lines.map((line) => {
        if (line.lineNumber === lineNumber) {
          const sellPrice = parseFloat(data.value);
          const bonus = sellPrice ? calculateBonusValue(sellPrice, line.pnrPrice) : 0;
          return {
            ...line,
            sellPrice,
            bonus: bonus,
          };
        }
        return { ...line };
      })
    );
  };

  return (
    <Table.Cell>
      {order.type === 'SPECIAL' ? (
        <FormInputNumber
          min={0}
          precision={2}
          value={validNumber(line.sellPrice) ? line.sellPrice : undefined}
          readOnly={summary}
          style={{ width: 100, opacity: 1 }}
          disabled={line.orderLineType === 'REPLACED'}
          onChange={(e: any, d: InputNumberOnChangeData) => changePnrPrice(line.lineNumber, e, d)}
        />
      ) : (
        <div style={{ width: 100 }}>{parsePrice(line.sellPrice)}</div>
      )}
    </Table.Cell>
  );
};

export default SellPriceCell;
