import React, { useCallback, useEffect, useState } from "react";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import { TClaimMessege, TClaimUser } from "~/store/claims/types";

export const useComments = () => {
  const [activeUserRoles, setActiveRoles] = useState<TClaimUser[]>([]);

  const {
    userHaveLdcUiClaimsView,
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  } = useClaimsRolesRights();

  useEffect(() => {
    const roles: TClaimUser[] = [];
    if (userHaveLdcUiClaimsProcessRole) {
      roles.push("DISTRIGO_BOK");
    }
    if (userHaveLdcUiTransportClaimsProcessRole) {
      roles.push("TRANSPORT");
    }
    if (userHaveLdcUiWarehouseClaimsProcessRole) {
      roles.push("WAREHOUSE");
    }
    setActiveRoles(roles);
  }, [
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  ]);

  // mocked isDistrigoView until Client Panel
  const isDistrigoView = (): boolean => {
    return true;
  };

  const renderDate = (date: string): string => {
    return new Date(date).toLocaleString();
  };

  const addStyleClassByRole = useCallback((comment: TClaimMessege): string => {
    let className = "";
    if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "DISTRIGO_BOK")
    ) {
      className += "distrigo";
    } else if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "WAREHOUSE")
    ) {
      className += "warehouse";
    } else if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "TRANSPORT")
    ) {
      className += "supplier";
    } else if (
      comment.userGroups.find((item) => item === "WAREHOUSE") &&
      comment.userGroups.find((item) => item === "TRANSPORT")
    ) {
      className += "warehouse_supplier";
    }
    return className;
  }, []);

  const renderInfoBadge = useCallback((comment: TClaimMessege) => {
    let roleInfo = "";
    if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "DISTRIGO_BOK")
    ) {
      roleInfo = "(D) ";
    } else if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "WAREHOUSE")
    ) {
      roleInfo = "(M) ";
    } else if (
      comment.userGroups.length < 2 &&
      comment.userGroups.find((item) => item === "TRANSPORT")
    ) {
      roleInfo = "(T) ";
    } else if (
      comment.userGroups.find((item) => item === "WAREHOUSE") &&
      comment.userGroups.find((item) => item === "TRANSPORT")
    ) {
      roleInfo = "(M/T) ";
    } else {
      roleInfo = "(K) ";
    }
    return (
      <span>
        <span className="name">{comment.author + roleInfo}</span>
        <span>{renderDate(comment.timestamp)}</span>
      </span>
    );
  }, []);

  const renderCommentContent = useCallback(
    (comment: TClaimMessege, maxLength?: number) => {
      let textToShow = "";
      if (maxLength && comment.content.length > maxLength) {
        textToShow = comment.content.substring(0, maxLength) + " ...";
      } else {
        const splitStringWithSpaces = (
          input: string,
          chunkSize: number
        ): string => {
          let result = [];

          for (let i = 0; i < input.length; i += chunkSize) {
            result.push(input.substring(i, i + chunkSize));
          }

          return result.join(" ");
        };
        textToShow = splitStringWithSpaces(comment.content, 85);
      }

      return (
        <>
          {textToShow.split("\n").map((item) => (
            <span key={Math.random()}>
              {item}
              <br />
            </span>
          ))}
        </>
      );
    },
    []
  );

  const setLeftRightClass = useCallback(
    (comment: TClaimMessege): string => {
      const containsAnyString = (
        arr1: TClaimUser[],
        arr2: TClaimUser[]
      ): boolean => {
        const setInArr2 = new Set(arr2);
        return arr1.some((str) => setInArr2.has(str));
      };
      return containsAnyString(comment.userGroups, activeUserRoles)
        ? "right"
        : "left";
    },
    [activeUserRoles]
  );

  const isBokUserView = useCallback(() => {
    return !!activeUserRoles.find((item) => item === "DISTRIGO_BOK");
  }, [activeUserRoles]);

  const messagesAmountLabel = useCallback((messages: any[] | null) => {
    return (
      <span>
        Liczba komentarzy: <strong>{messages ? messages.length : 0}</strong>
      </span>
    );
  }, []);

  const canShowClientComments = useCallback(() => {
    // returning false until Client Panel
    return false;
    // return !(
    //   activeUserRoles.find((item) => item === "TRANSPORT") ||
    //   activeUserRoles.find((item) => item === "WAREHOUSE")
    // );
  }, []);

  const canShowInnerComments = useCallback(() => {
    return userHaveLdcUiClaimsView;
  }, [userHaveLdcUiClaimsView]);

  const canSendMessages = useCallback(() => {
    return (
      userHaveLdcUiClaimsView &&
      (userHaveLdcUiClaimsProcessRole ||
        userHaveLdcUiTransportClaimsProcessRole ||
        userHaveLdcUiWarehouseClaimsProcessRole)
    );
  }, [
    userHaveLdcUiClaimsView,
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  ]);

  return {
    activeUserRoles,
    isBokUserView,
    isDistrigoView,
    addStyleClassByRole,
    renderInfoBadge,
    renderCommentContent,
    setLeftRightClass,
    messagesAmountLabel,
    canShowClientComments,
    canShowInnerComments,
    canSendMessages,
  };
};
