export const parseNumber = (number: string | number): JSX.Element | string => {
    if ((number || number === 0) && !Number.isNaN(Number(number))) {
        const parts = String(Number(number).toFixed(2)).split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        let formattedNumber = parts.join(',')
        const additionalZeroCheck = formattedNumber.split(',').pop() as string

        if (additionalZeroCheck.length === 0 || !formattedNumber.includes(',')) {
            formattedNumber += ',00'
        }
        else if (additionalZeroCheck.length === 1) {
            formattedNumber += '0'
        }

        return formattedNumber
    }
    console.warn("Skip formatting value as number:", number, typeof number)
    return "0,00"
}