import { action } from 'typesafe-actions'
import { CodedPart, CodedPartsActionTypes, PaginableCodedPart } from "./types"

export const fetchCodedParts = (params?: any) => action(CodedPartsActionTypes.FETCH_CODED_PARTS, params)
export const fetchCodedPartsSuccess = (parts: PaginableCodedPart) => action(CodedPartsActionTypes.FETCH_CODED_PARTS_SUCCESS, parts)
export const fetchCodedPartsError = (error: any) => action(CodedPartsActionTypes.FETCH_CODED_PARTS_ERROR, error)


export const completeCodedPart = (params: {orderId: string, lineNr: string, CSPSOrderNr: string}) =>  action(CodedPartsActionTypes.COMPLETE_CODED_PART, params)
export const completeCodedPartSuccess = (res: CodedPart) => action(CodedPartsActionTypes.COMPLETE_CODED_PART_SUCCESS, res)
export const completeCodedPartError = (error: any) => action(CodedPartsActionTypes.COMPLETE_CODED_PART_ERROR, error)
export const completeCodedPartRefresh = () => action(CodedPartsActionTypes.COMPLETE_CODED_PART_REFRESH)