import { TotalRecords } from "~/store/types";
import { DropdownProps, Select } from "semantic-ui-react";
import TotalRecordsInfo from "~/components/TotalRecords/TotalRecordsInfo";
import dictionariesStatic from "~/store/dictionaries/static";
import React from "react";

export type PaginationInfoSelectProps = {
    totalRecords: TotalRecords | undefined,
    onPageSizeChange: ((size: number) => void) | undefined,
    pageSizeVisible: boolean | undefined,
    value: number,
    onChange: (ev: any, props: DropdownProps) => void
}

export const PaginationInfoSelect: React.FC<PaginationInfoSelectProps> = ({
    totalRecords,
    pageSizeVisible,
    onPageSizeChange,
    onChange,
    value
}) => {
    return (
        <div style={{ float: "right", position: "sticky", right: 6 }}>
            {totalRecords ? <TotalRecordsInfo totalRecords={totalRecords}/> : null}
            {
                (onPageSizeChange && pageSizeVisible) ? (
                    <Select
                        size="mini"
                        options={dictionariesStatic.PAGE_SIZE}
                        value={value}
                        onChange={onChange}
                    />
                ) : null
            }
        </div>
    );
}