import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  clearCollectiveCorrections,
  confirmCollectiveCorrectionsLines,
  fetchCollectiveDebitNoteCorrections,
  getCollectiveDebitCorrectionPdf,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
  previewCollectiveCorrectionPdf,
  uploadCollectiveCorrections,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { ICollectiveDebitNoteCorrections } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { CollectiveCorrectionsModal } from "../../lib/Corrections/lib/CollectiveCorrectionsModal";
import IconHover from "~/components/IconHover/IconHover";
import { useCollectiveDebitNoteCorrectionsColumns } from "./useCollectiveDebitNoteCorrectionsColumns";
import { useCollectiveDebitNotesCorrectionsAdvancedSearch } from "./useCollectiveDebitNotesCorrectionsAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  openBulkCorrectionsModal: boolean;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
  setOpenBulkCorrectionsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type TReduxState = {
  collectiveDebitNoteCorrections: ICollectiveDebitNoteCorrections[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
  selectedTypeOfDataToExport?: string;
  createdCollectiveCorretion?: boolean;
  loadingConfirmCollectiveCorrections: boolean;
  loadingCollectiveCorrections: boolean;
  collectiveCorrections?: any[];
  collectiveCorrectionsErrorBody: any;
};

interface TReduxActions {
  fetchCollectiveDebitNoteCorrections: typeof fetchCollectiveDebitNoteCorrections;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  previewCollectiveCorrectionPdf: typeof previewCollectiveCorrectionPdf;
  confirmCollectiveCorrectionsLines: typeof confirmCollectiveCorrectionsLines;
  clearCollectiveCorrections: typeof clearCollectiveCorrections;
  uploadCollectiveCorrections: typeof uploadCollectiveCorrections;
  getCollectiveDebitCorrectionPdf: typeof getCollectiveDebitCorrectionPdf;
}

type CollectiveDebitNotesCorrectionsProps = TComponentProps &
  TReduxState &
  TReduxActions;

const CollectiveDebitNotesCorrections: React.FC<
  CollectiveDebitNotesCorrectionsProps
> = ({
  fetchCollectiveDebitNoteCorrections,
  collectiveDebitNoteCorrections,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport,
  loadingConfirmCollectiveCorrections,
  createdCollectiveCorretion,
  loadingCollectiveCorrections,
  collectiveCorrections,
  openBulkCorrectionsModal,
  collectiveCorrectionsErrorBody,
  setOpenBulkCorrectionsModal,
  setDisableExportButton,
  setPercenExportButton,
  previewCollectiveCorrectionPdf,
  confirmCollectiveCorrectionsLines,
  clearCollectiveCorrections,
  uploadCollectiveCorrections,
  getCollectiveDebitCorrectionPdf,
}) => {
  const { columnsConfig } = useCollectiveDebitNoteCorrectionsColumns();
  const [tableKey] = useState<string>(
    "accounting/collective-corrections/collective-debit-note-corrections"
  );
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("createDateTime");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "Korekty_zbiorcze_obciazen",
  });

  const getDebitNotesCorretions = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchCollectiveDebitNoteCorrections(Object.assign(paramsObj, params));
    },
    [
      fetchCollectiveDebitNoteCorrections,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getDebitNotesCorretions({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getDebitNotesCorretions]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  const handleCreateSuccess = () => {
    getDebitNotesCorretions({ ...params });
    setOpenBulkCorrectionsModal(false);
  };

  const { formFields, handleSubmit } =
    useCollectiveDebitNotesCorrectionsAdvancedSearch(
      columns,
      setColumns,
      sortColumn,
      sortDirection,
      getDebitNotesCorretions,
      filterLabelsRef,
      tableKey
    );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getDebitNotesCorretions}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {collectiveDebitNoteCorrections.map(
          (document: ICollectiveDebitNoteCorrections, index: number) => (
            <Table.Row disabled={!!document.cancellationReason} key={index}>
              {columns &&
                columns.length &&
                columns.map(
                  (column) =>
                    column.getCell &&
                    column.projection &&
                    column.getCell(document, column)
                )}
              <Table.Cell className="col-dropdown-menu-sticky">
                <IconHover
                  name="file"
                  title="Pobierz"
                  onClick={() =>
                    getCollectiveDebitCorrectionPdf(
                      document.id,
                      `${document.documentMask}.pdf`
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        )}
      </DataGridTable>
      <CollectiveCorrectionsModal
        created={!!createdCollectiveCorretion}
        previewCollectiveCorrectionPdf={previewCollectiveCorrectionPdf}
        loadingConfirmCollectiveCorrections={
          loadingConfirmCollectiveCorrections
        }
        loadingCollectiveCorrections={loadingCollectiveCorrections}
        confirmCollectiveCorrectionsLines={confirmCollectiveCorrectionsLines}
        clearCollectiveCorrections={clearCollectiveCorrections}
        uploadCollectiveCorrections={uploadCollectiveCorrections}
        correctionLines={collectiveCorrections || []}
        open={openBulkCorrectionsModal}
        uploadingError={collectiveCorrectionsErrorBody}
        closeHandler={handleCreateSuccess}
      />
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  collectiveDebitNoteCorrections: invoices.collectiveDebitNoteCorrections.list,
  loading: invoices.loadingCollectiveDebitNoteCorrections,
  loadingConfirmCollectiveCorrections:
    invoices.loadingConfirmCollectiveCorrections,
  totalRecords: invoices.collectiveDebitNoteCorrections.totalRecords,
  meta: invoices.collectiveDebitNoteCorrections.meta,
  dictionaries: dictionaries,

  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
  createdCollectiveCorretion: invoices.createdCollectiveCorretion,
  loadingCollectiveCorrections: invoices.loadingCollectiveCorrections,
  collectiveCorrections: invoices.collectiveCorrections,
  collectiveCorrectionsErrorBody: invoices.collectiveCorrectionsErrorBody,
});

const mapDispatchToProps = {
  fetchCollectiveDebitNoteCorrections,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
  previewCollectiveCorrectionPdf,
  confirmCollectiveCorrectionsLines,
  clearCollectiveCorrections,
  uploadCollectiveCorrections,
  getCollectiveDebitCorrectionPdf,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectiveDebitNotesCorrections);
