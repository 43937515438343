import React from "react";
import { Button, GridColumn, Icon, Popup } from "semantic-ui-react";
import { AddNewLineProps } from "~/pages/Orders/lib/Add/Lines/constants";

const AddNewLine = ({ order, summary, addLine }: AddNewLineProps) => (
  <GridColumn>
    <Popup
      trigger={
        <Button disabled={!order.type || summary} basic onClick={addLine}>
          <Icon name="add" />
          Dodaj kolejną linię
        </Button>
      }
      content="Dodaj kolejną linię do zamówienia"
    />
  </GridColumn>
);

export default AddNewLine;
