import { useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useAdministrationRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiAdministrationViewRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_VIEW
      ),
    [context]
  );

  const userHaveLdcUiAdministrationUsersRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_USERS
      ),
    [context]
  );

  const userHaveLdcUiAdministrationDictRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_DICT
      ),
    [context]
  );

  const userHaveLdcUiAdministrationSpeederRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_SPEEDER
      ),
    [context]
  );

  const userHaveLdcUiAdministrationImportFactorLimitRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_IMPORT_FACTOR_LIMIT
      ),
    [context]
  );

  const userHaveCustomerPanelUiAdvertisingViewRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ADVERTISING_VIEW
      ),
    [context]
  );

  const userHaveCustomerPanelUiAdvertisingEditRole = useMemo(
    () =>
      context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ADVERTISING_EDIT
      ),
    [context]
  );

  return {
    userHaveLdcUiAdministrationViewRole,
    userHaveLdcUiAdministrationUsersRole,
    userHaveLdcUiAdministrationDictRole,
    userHaveLdcUiAdministrationSpeederRole,
    userHaveLdcUiAdministrationImportFactorLimitRole,
    userHaveCustomerPanelUiAdvertisingViewRole,
    userHaveCustomerPanelUiAdvertisingEditRole,
  };
};
