import { action } from 'typesafe-actions'
import {SparePartPricing, PriceListActionTypes} from './types'

export const fetchPriceList = (query?: string) => action(PriceListActionTypes.FETCH_REQUEST, query)
export const fetchSuccess = (data: SparePartPricing[]) => action(PriceListActionTypes.FETCH_SUCCESS, data)
export const fetchError = (message: string) => action(PriceListActionTypes.FETCH_ERROR, message)

export const fetchPriceListSparePart = (referenceId?: string) => action(PriceListActionTypes.FETCH_REQUEST_SPARE_PART, referenceId)
export const fetchSuccessSparePart = (data: SparePartPricing) => action(PriceListActionTypes.FETCH_SUCCESS_SPARE_PART, data)
export const fetchErrorSparePart = (message: string) => action(PriceListActionTypes.FETCH_ERROR_SPARE_PART, message)
