import { Order } from "~/store/orders/types";
import { SparePart } from "~/store/spare-parts/types";
import { Customer } from "~/store/customer/types";
import { IClaim } from "~/store/claims/types";
import { Backorder } from "~/store/backorder/types";
import { DictionaryItem } from "~/store/dictionaries/types";

export const validateNullText = (text: string): string => !text || text.includes('null') ? '' : text;

export const getMapSearching = (searchingGroup: "orders" | "products" | "customers" | "claims" | "backorders", orders: Order[], spareParts: SparePart[], customers: Customer[], claims: IClaim[], backorders: Backorder[]) => {
    return (): DictionaryItem[] => {
        switch (searchingGroup) {
            case 'orders':
                return orders.map(order => ({
                    key: order.orderNumber + Math.random(),
                    text: order.orderNumber,
                    value: order.id
                }))
            case 'products':
                return spareParts.map(product => ({
                    key: product.referenceId,
                    text: `${product.referenceId} ${product['designationPolish']}`,
                    value: product.referenceId
                }))
            case 'customers':
                return customers.map(customer => ({
                    key: customer.rrdi,
                    text: `${customer.rrdi} ${validateNullText(customer.informationContactSurname)} ${validateNullText(customer.informationContactName)} ${validateNullText(customer.informationAddressCompany)}`,
                    value: customer.rrdi
                }))
            case 'claims':
                return claims.map(claim => ({
                    key: claim.claimNumber,
                    text: claim.claimNumber,
                    value: claim.claimNumber
                }))
            case 'backorders':
                return backorders.map(backorder => ({
                    key: backorder.backorderNumber,
                    text: backorder.backorderNumber,
                    value: backorder.id
                }))
            default:
                return []
        }
    };
}