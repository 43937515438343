import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Header, Modal } from "semantic-ui-react";
import { addAttachments, clearUpload } from "~/store/claims/actions";
import { ApplicationState } from "~/store";

import FileUploader from "~/components/DropdownFileArea/FileUploader";

type TReduxActions = {
  addAttachments: typeof addAttachments;
  clearUpload: typeof clearUpload;
};

interface PropsFromState {
  uploading: boolean;
  uploaded: boolean;
}

type TProps = {
  triggerOpen: boolean;
  claimNumber: string;
  createdSuccess: () => void;
};

type TAddAttachemntModalProps = TReduxActions & PropsFromState & TProps;

const AddAttachment: React.FC<TAddAttachemntModalProps> = ({
  children,
  triggerOpen,
  uploaded,
  uploading,
  claimNumber,
  addAttachments,
  createdSuccess,
  clearUpload,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const handleCancel = (): void => setOpen(false);

  const handleOpen = (): void => setOpen(true);

  if (uploaded && open) {
    clearUpload();
    createdSuccess();
    handleCancel();
  }

  return (
    <Modal
      size="small"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      setState
      <Header icon="file archive" content="Dodawanie załącznika" />
      <Modal.Content>
        <FileUploader
          onSaveAfter={() => setSave(false)}
          onSaveClick={addAttachments}
          additionalParam={claimNumber}
          save={save}
          fileMaxSize={15}
          reqestMaxSize={15}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Wgraj"
          loading={uploading}
          icon="upload"
          labelPosition="right"
          onClick={() => setSave(true)}
        />
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ claims }: ApplicationState) => ({
  uploading: claims.uploadingAttachment,
  uploaded: claims.uploadedAttachment,
});

const mapDispatchToProps: TReduxActions = { addAttachments, clearUpload };

export default connect(mapStateToProps, mapDispatchToProps)(AddAttachment);
