import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import CommonLoader from "~/components/Loaders/CommonLoader";
import translations from "~/utils/translations";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { DictionariesProps } from "~/pages/Administration/lib/Dictionaries/constants";
import DictionariesTable from "~/pages/Administration/lib/Dictionaries/Table";
import ContactTile from "~/pages/Contact/ContactTile";

const Dictionaries = ({
  dictionaries,
  name,
  refresh,
  changeName,
  fetchDictionariesNames,
}: DictionariesProps) => {
  const mapNames = (names: string[]): DictionaryItem[] => {
    return names.map((name) => ({
      key: name,
      text: translations.format("app.dictionary.name." + name),
      value: name,
    }));
  };

  useEffect(() => {
    fetchDictionariesNames();
  }, [fetchDictionariesNames]);

  return (
    <>
      <Form.Dropdown
        placeholder="Wybierz słownik"
        search
        fluid
        selection
        style={{ width: "500px", marginBottom: "10px" }}
        value={name}
        onChange={changeName}
        loading={dictionaries.loading}
        options={mapNames(dictionaries.names)}
      />
      {name && (
        <div style={{ overflowX: "auto", maxHeight: "calc(100vh - 250px)" }}>
          <DictionariesTable refresh={refresh} name={name} />
        </div>
      )}
      {name && name === DictionaryName.contact ? (
        <div style={{ marginTop: 20 }}>
          <span>Podgląd zakładki kontakt:</span>
          <ContactTile />
        </div>
      ) : null}
      <CommonLoader loading={dictionaries.loading} />
    </>
  );
};

const mapStateToProps = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(Dictionaries);
