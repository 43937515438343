import { PaginationMeta, TotalRecords, Paginable } from "~/store/types";
import { CustomerPaymentMethod } from "~/store/payment-methods/types";
import { CustomerPaymentFactor } from "~/store/factors/types";
import { CustomerDistrigoCredit } from "~/store/distrigo-credit/types";
import { CustomerType } from "~/pages/Customers/lib/AddContractor";

export interface CustomerBase {
  rrdi: string;
  clientId: string;
  customerType: CustomerType;
  informationContactName: string;
  informationContactSurname: string;
  orderType: "STOCK" | "URGENT" | "TURBO_PRO";
  categoryCompany?: boolean;
  informationAddressCompany?: string;
}

export interface OperatingWarehouse {
  warehouseId: string;
  deliveryType: string;
  deliveryNote: string;
  deliveryPlatform: string;
  deliveryRoute: string;
  deliveryZone: string;
  deliveryGroup: string;
  deliveryTransportContract: string;
}

export type CurrentAccountingCustomer = {
  authorUsername: string;
  creationTime: Date;
  creationComment: string;
  currentAccountingCustomerID: string;
  oldAccountingCustomerID: null | string;
  validFrom: Date;
};

export type AdditionalDiscountHistory = {
  id: string;
  percentage: number;
  creationTime: string;
  comment: string;
  authorUsername: string;
};

export type AdditionalDiscount = AdditionalDiscountHistory & {
  isDiscountDRZActive: boolean;
  ruleSet: {
    referenceRule: {
      references: any[];
    };
  };
};

export type AdditionalDiscountSaveDTO = {
  percentage: number;
  comment: string;
  references: any[];
};

export interface Customer {
  paymentCreditBlocked: any;
  accountingInvoiceSigner: string;
  accountingTransmissionDms: string;
  accountingTransmissionDmsActive: boolean;
  accountingTransmissionEmail: string;
  accountingTransmissionEmailActive: boolean;
  accountingTransmissionGuiActive: boolean;
  accountingVatActive: boolean;
  accountingVatId: string;
  address: string;
  blocked: boolean;
  categoryCompany: boolean;
  categoryOrganisation: string;
  categoryPp: boolean;
  categorySector: string;
  categoryType: string;
  categoryZone: string;
  clientId: string;
  deliveryGroup: string;
  deliveryNote: string;
  deliveryPlatform: string;
  deliveryRoute: string;
  warehouseId: string;
  deliveryZone: string;
  deliveryTransportContract: string;
  gdprMessageAccepted: boolean;
  gdprMessageDate: string;
  gdprCallAccepted: boolean;
  gdprCallDate: string;
  informationAddressCity: string;
  informationAddressCompany: string;
  informationAddressCountry: string;
  informationAddressNumber: string;
  informationAddressRegion: string;
  informationAddressStreet: string;
  informationAddressZipcode: string;
  informationContactEmail: string;
  informationContactHomePhone: string;
  informationContactMobilePhone: string;
  informationContactName: string;
  informationContactSurname: string;
  informationContactWorkPhone: string;
  informationGender: string;
  name: string;
  notes: string;
  orderType: "STOCK" | "URGENT" | "TURBO_PRO";
  rrdi: string;
  customerType: CustomerType;
  sapClientId: string;
  sapDeliveryId: string;
  ddsClientId: string;
  ddsDeliveryId: string;
  paymentCreditPendingForInvoiceLimit: number;
  paymentCreditBankLimit: number;
  paymentCreditUsedLimit: number;
  paymentFactor?: string;
  paymentBankAccount: string;
  sapCustomerClass?: string;
  sapRrdiCode?: string;
  operatingWarehouses?: OperatingWarehouse[];
  currentPaymentMethod?: CustomerPaymentMethod;
  paymentMethods: CustomerPaymentMethod[];
  paymentFactors: CustomerPaymentFactor[];
  paymentDistrigoCredits: CustomerDistrigoCredit;
  currentPaymentMethodLimit: number;
  currentTotalLimit: number;
  availableFactorTotalLimit?: number;
  effectiveDateOfTheChange: null | Date;
  currentAccountingCustomer: null | CurrentAccountingCustomer;
  supplierSystemClientId: null | string;
  supplierSystemDeliveryId: null | string;
  supplierSystem: SupplierSystem[];
  isDiscountDRZActive?: boolean;
  discountDRZ?: {
    history: AdditionalDiscountHistory[];
    current?: AdditionalDiscountHistory;
  };
  customerClasses?: string[];
  customerBrands?: { brand: string; code: string }[];
  classification?: string;
}

export type SupplierSystem = {
  clientId: string;
  deliveryId: string;
  name: "CSPS";
  businessSector: string;
  [key: string]: string;
};

export interface CustomerSelected {
  detail: Customer;
  deliveryAddresses: DeliveryLocation[];
}

export interface DeliveryLocation {
  rrdi: string;
  ddsClientId: string;
  warehouse: string;
  type: string;
  blocked: boolean;
  payer: Customer;
  company?: string;
  street?: string;
  number?: string;
  city?: string;
  zipcode?: string;
  region?: string;
  country?: string;
  sapClientId?: string;
  warehouses?: {
    warehouseId: string;
    warehouseName: string;
  }[];
  supplierSystem: SupplierSystem[];
  discountDRZPercentage: number | null;
}

export type TDeliveryPoint = {
  displayName: string;
  displayAddress: string;
  stock?: DeliveryLocation;
  urgent?: DeliveryLocation;
  turboPro?: DeliveryLocation;
};

export type TPreImport = {
  recordsCount: number;
  toCreate: number;
  toUpdate: number;
};

export type TAddSuccess = {
  id: string;
};

export enum CustomerClasses {
  AUTHORIZED_SERVICE = "AUTHORIZED_SERVICE",
  INDEPENDENT_SERVICE = "INDEPENDENT_SERVICE",
  INDEPENDENT_SERVICE_ERCS = "INDEPENDENT_SERVICE_ERCS",
  DISTRIGO_RELAY = "DISTRIGO_RELAY",
  DISTRIGO_MARKET = "DISTRIGO_MARKET",
  DISTRIGO_HUB = "DISTRIGO_HUB",
  OTHER = "OTHER",
}

export enum CustomerBrands {
  PEUGEOT = "PEUGEOT",
  CITROEN = "CITROEN",
  OPEL = "OPEL",
  DS = "DS",
  FIAT = "FIAT",
  JEEP = "JEEP",
  DISTRIGO_RELAY = "DISTRIGO_RELAY",
  DISTRIGO_MARKET = "DISTRIGO_MARKET",
  DISTRIGO_HUB = "DISTRIGO_HUB",
}

export type ClassAndBrands = {
  name: CustomerClasses;
  brands: CustomerBrands[];
  brandRequired: boolean;
};

export type ClassAndBrandsExpanded = {
  name: CustomerClasses;
  brands: {
    brandRequired: boolean;
    checked: boolean;
    code: string;
    name: string;
  }[];
  brandRequired: boolean;
  checked: boolean;
};

export enum CustomersActionTypes {
  FETCH_PAYER_HISTORY_LIST = "@@customers/FETCH_PAYER_HISTORY_LIST",
  FETCH_PAYER_HISTORY_LIST_SUCCESS = "@@customers/FETCH_PAYER_HISTORY_LIST_SUCCESS",
  FETCH_PAYER_HISTORY_LIST_FAILED = "@@customers/FETCH_PAYER_HISTORY_LIST_FAILED",
  ADD_NEW_PAYER = "@@customers/ADD_NEW_PAYER",
  ADD_NEW_PAYER_ACTION_STOP = "@@customers/ADD_NEW_PAYER_ACTION_STOP",
  FETCH_CURRENT_PAYER = "@@customers/FETCH_CURRENT_PAYER",
  FETCH_CURRENT_PAYER_SUCCESS = "@@customers/FETCH_CURRENT_PAYER_SUCCESS",
  FETCH_CURRENT_PAYER_FAILED = "@@customers/FETCH_CURRENT_PAYER_FAILED",
  FETCH_REQUEST = "@@customers/FETCH_REQUEST",
  FETCH_SEARCH_REQUEST = "@@customers/FETCH_SEARCH_REQUEST",
  FETCH_SUCCESS = "@@customers/FETCH_SUCCESS",
  FETCH_SEARCH_SUCCESS = "@@customers/FETCH_SEARCH_SUCCESS",
  FILTER_CUSTOMERS = "@@customers/FILTER_CUSTOMERS",
  FETCH_DELIVERY_POINTS = "@@customers/FETCH_DELIVERY_POINTS",
  FETCH_DELIVERY_POINTS_SUCCESS = "@@customers/FETCH_DELIVERY_POINTS_SUCCESS",
  SELECT_CUSTOMER = "@@customers/SELECT_CUSTOMER",
  SELECTED = "@@customers/SELECTED",
  CLEAR_SELECTED = "@@customers/CLEAR_SELECTED",
  ADD_CUSTOMER = "@@customers/ADD_CUSTOMER",
  ADD_SUCCESS = "@@customers/ADD_SUCCESS",
  PRE_IMPORT = "@@customers/PRE_IMPORT",
  PRE_IMPORT_SUCCESS = "@@customers/PRE_IMPORT_SUCCESS",
  IMPORT_ADD = "@@customers/IMPORT_ADD",
  IMPORT_ADD_SUCCESS = "@@customers/IMPORT_ADD_SUCCESS",
  IMPORT_UPDATE = "@@customers/IMPORT_UPDATE",
  IMPORT_UPDATE_SUCCESS = "@@customers/IMPORT_UPDATE_SUCCESS",
  CLEAR_IMPORT = "@@customers/CLEAR_IMPORT",
  DELETE_CUSTOMER_WAREHOUSE = "@@customers/DELETE_CUSTOMER_WAREHOUSE",
  FETCH_PAYER_LIST = "@@customers/FETCH_PAYER_LIST",
  FETCH_PAYER_LIST_SUCCESS = "@@customers/FETCH_PAYER_LIST_SUCCESS",
  FETCH_PAYER_LIST_FAILED = "@@customers/FETCH_PAYER_LIST_FAILED",
  DOWNLOAD_PRODUCTS_GRUP = "@@customers/DOWNLOAD_PRODUCTS_GRUP",
  DOWNLOAD_PRODUCTS_GRUP_SUCCESS = "@@customers/DOWNLOAD_PRODUCTS_GRUP_SUCCESS",
  DOWNLOAD_PRODUCTS_GRUP_ERROR = "@@customers/DOWNLOAD_PRODUCTS_GRUP_ERROR",
  SAVE_ADDITIONAL_DISCOUNT = "@@customers/SAVE_ADDITIONAL_DISCOUNT",
  SAVE_ADDITIONAL_DISCOUNT_SUCCESS = "@@customers/SAVE_ADDITIONAL_DISCOUNT_SUCCESS",
  SAVE_ADDITIONAL_DISCOUNT_ERROR = "@@customers/SAVE_ADDITIONAL_DISCOUNT_ERROR",
  FETCH_CLASSES_AND_BRANDS = "@@customers/FETCH_CLASSES_AND_BRANDS",
  FETCH_CLASSES_AND_BRANDS_SUCCESS = "@@customers/FETCH_CLASSES_AND_BRANDS_SUCCESS",
  FETCH_CLASSES_AND_BRANDS_ERROR = "@@customers/FETCH_CLASSES_AND_BRANDS_ERROR",
  UPDATE_CUSOMER = "@@customers/UPDATE_CUSOMER",
  UPDATE_CUSOMER_SUCCESS = "@@customers/UPDATE_CUSOMER_SUCCESS",
  UPDATE_CUSOMER_ERROR = "@@customers/UPDATE_CUSOMER_ERROR",
  FETCH_CUSTOMER_CLASSIFICATIONS = "@@customers/FETCH_CUSTOMER_CLASSIFICATIONS",
  FETCH_CUSTOMER_CLASSIFICATIONS_SUCCESS = "@@customers/FETCH_CUSTOMER_CLASSIFICATIONS_SUCCESS",
}

export interface CustomersState {
  readonly list: Customer[];
  readonly searchList: Customer[];
  readonly meta: PaginationMeta;
  readonly deliveryPoints: TDeliveryPoint[];
  readonly creating: boolean;
  readonly importedAdd: boolean;
  readonly importedUpdate: boolean;
  readonly loading: boolean;
  readonly loadingDeliveryPoints: boolean;
  readonly loadingPreImport: boolean;
  readonly loadingImportAdd: boolean;
  readonly loadingImportUpdate: boolean;
  readonly totalRecords: TotalRecords;
  readonly selected?: CustomerSelected;
  readonly preImportResults?: TPreImport;
  readonly created?: string;
  readonly currentPayer: null | any;
  readonly loadingCurrentPayer: boolean;
  readonly loadingAddNewPayer: boolean;
  readonly loadingPayerHistoryList: boolean;
  readonly payerHistoryList: CurrentAccountingCustomer[];
  readonly downloadProductGroupPending: boolean;
  readonly saveAdditionalDiscountPending: boolean;
  readonly classesAndBrands?: ClassAndBrands[];
  readonly classesAndBrandsLoading: boolean;
  readonly updateCustomerPending: boolean;
  readonly classifications: string[];
}

export type PaginableCustomer = Paginable<Customer>;
