import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'

export type TProps = {
    loading: boolean
    extraUp?: boolean
    messageId?: string
}

const CommonLoader: React.FC<TProps> = ({
    loading,
    extraUp,
    messageId
}) => {

    return (
        <Dimmer active={loading} inverted style={ extraUp ? { zIndex: '125' } : {} }>
            <Loader inverted><FormattedMessage id={messageId || "app.loading"} /></Loader>
        </Dimmer>
    )
}

export default CommonLoader
