import React, { useCallback, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import { retrySent } from "~/store/invoices/actions";
import { IInvoice } from "~/store/invoices/types";

type Props = {
  document: IInvoice;
  getInvoice: (invoiceNr: string, documentMask: string) => void;
  retrySent: typeof retrySent;
};

export const DebitNotesCorrectionsDropdown: React.FC<Props> = ({
  document,
  getInvoice,
  retrySent,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = useCallback(() => {
    setOpenModal(false);
    retrySent(document.invoiceNo);
  }, [document.invoiceNo, retrySent]);

  return (
    <TableMoreDropdown>
      <Dropdown.Item
        text="Pobierz dokument"
        onClick={() =>
          getInvoice(document.invoiceNo, `${document.documentMask}.pdf`)
        }
      />
      <Dropdown.Item
        text="Ponowne wysłanie korekty"
        onClick={() => setOpenModal(true)}
      />
      <ModalConfirm
        modalOpen={openModal}
        headerIcon="paper plane"
        headerText="app.resent"
        contentText="app.resent.question"
        onCancelClick={() => setOpenModal(false)}
        onConfirmClick={handleConfirm}
      />
    </TableMoreDropdown>
  );
};
