import { FormattedMessage } from 'react-intl'
import { SemanticICONS } from 'semantic-ui-react'
import { Anomaly, TAnomalyType } from '~/store/anomalies/types'

import React from 'react'

export type TActionType = 'RESOLVE' | 'RESOLVE_SELECTED' | 'CLOSE'

export type TActionData = {
    label: string | JSX.Element
    message: string | JSX.Element
    icon: SemanticICONS
    type: TActionType
}

export type TAnomalyActions = {
    resolve?: TActionData
    resolveSelected?: TActionData
    close?: TActionData
}

const matchAnomalyAction = (anomaly?: Anomaly): TAnomalyActions => {

    const isType = (...type: TAnomalyType[]) => {
        return anomaly && type.indexOf(anomaly.anomalyType) !== -1
    }

    if (!anomaly || !isType(
        'GDN_SUPPLIER_CLAIM',
        'GDN_CLIENT_ORDER',
        'GDN_CLIENT_CLAIM',
        'GDN_INTERNAL',
        'GRN_BACKORDER',
        'GRN_CLIENT_CLAIM',
        'GRN_CLIENT_PROVISION',
        'GRN_INTERNAL',
        'CROSS_DOCK_PCD',
        'CROSS_DOCK_OV',
        'STOCK_AVAILABLE',
        'STOCK_QUALITY_CONTROL',
        'STOCK_INTERNAL_CONSUMPTION',
        'STOCK_BLOCKED',
        'REQUIRES_CONFIRMATION'
    )) {
        return {}
    }

    const resolve: TActionData = {
        label: <FormattedMessage id={`app.anomaly.resolve.${anomaly.anomalyType}`} />,
        message: <FormattedMessage id={`app.anomaly.resolve.${anomaly.anomalyType}.message`} />,
        icon: isType('STOCK_AVAILABLE', 'STOCK_QUALITY_CONTROL', 'STOCK_INTERNAL_CONSUMPTION', 'STOCK_BLOCKED', 'REQUIRES_CONFIRMATION') ? 'check'
            : isType('GRN_BACKORDER') ? 'box' : 'refresh',
        type: "RESOLVE"
    }

    const resolveSelected: TActionData | undefined =
        !isType('STOCK_AVAILABLE', 'STOCK_QUALITY_CONTROL', 'STOCK_INTERNAL_CONSUMPTION', 'STOCK_BLOCKED', 'REQUIRES_CONFIRMATION') ? {
            label: <FormattedMessage id={`app.anomaly.resolveSelected.${anomaly.anomalyType}`} />,
            message: <FormattedMessage id={`app.anomaly.resolveSelected.${anomaly.anomalyType}.message`} />,
            icon: 'check square outline',
            type: 'RESOLVE_SELECTED'
        } : undefined

    const close: TActionData = {
        label: <FormattedMessage id={`app.anomaly.close.${anomaly.anomalyType}`} />,
        message: <FormattedMessage id={`app.anomaly.close.${anomaly.anomalyType}.message`} />,
        icon: 'ban',
        type: 'CLOSE'
    }

    return {
        resolve,
        resolveSelected,
        close
    }
}

export default matchAnomalyAction
