import { Reducer } from "redux";
import { Paginable } from "~/store/types";
import { CodedPart, CodedPartsActionTypes, CodedPartsState } from "./types";

export const initialCodedPartsState: CodedPartsState = {
  codedPartsList: [],
  loadingCodedParts: false,
  meta: {
    page: 1,
    pages: 1,
    size: 15,
    total: 0
  },
  totalRecords: {
    total: 0,
    filtered: 0
  },
  loadingCompleteCodedPart: false
};

const reducer: Reducer<CodedPartsState> = (
  state = initialCodedPartsState,
  action
): CodedPartsState => {
  switch (action.type) {
    case CodedPartsActionTypes.FETCH_CODED_PARTS: {
      return {
        ...state,
        loadingCodedParts: true
      };
    }
    case CodedPartsActionTypes.FETCH_CODED_PARTS_SUCCESS: {
      const payload = action.payload as Paginable<CodedPart>
      return {
        ...state,
        loadingCodedParts: false,
        codedPartsList: payload.content,
        meta: { ...state.meta, page: payload.number + 1, pages: payload.totalPages, size: payload.size },
        totalRecords: { total: payload.totalElements, filtered: payload.filteredElements }
      };
    }
    case CodedPartsActionTypes.FETCH_CODED_PARTS_ERROR: {
      return {
        ...state,
        
      };
    }
    case CodedPartsActionTypes.COMPLETE_CODED_PART: {
      return {
        ...state,
        loadingCompleteCodedPart: true
      }
    }
    case CodedPartsActionTypes.COMPLETE_CODED_PART_SUCCESS: {
      const payload = action.payload as CodedPart
      const partToUpdateIndex = state.codedPartsList.findIndex(part => part.id === payload.id)
      const partsList = state.codedPartsList
      partsList[partToUpdateIndex] = payload
      return {
        ...state,
        codedPartsList: partsList,
        completeCodedPartSuccess: true,
        loadingCompleteCodedPart: false
      }
    }
    case CodedPartsActionTypes.COMPLETE_CODED_PART_REFRESH: {
      return {
        ...state,
        completeCodedPartSuccess: undefined,
        loadingCompleteCodedPart: false
      }
    }
    default: {
      return state;
    }
  }
};

export { reducer as codedPartsReducer };
