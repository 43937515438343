import { Table } from 'semantic-ui-react';
import { IClaim } from '~/store/claims/types';

import React from 'react';
import TableNoResults from '~/components/TableNoResults/TableNoResults';
import TablePreviewDetails from '~/components/TablePreviewDetails/TablePreviewDetails';
import { encodeUrlClaimNumber } from '~/utils/claimNumberUrlFormatter';
import { TColumn } from '~/components/Table/lib/types';

interface Props {
  claims: IClaim[];
  columns: TColumn[];
}

const ClaimsList: React.FC<Props> = ({ claims, columns }) => {
  const claimsListDropdown = (claim: IClaim): JSX.Element => {
    return <TablePreviewDetails key={Math.random()} path={`/claim/${encodeUrlClaimNumber(claim.claimNumber)}`} />;
  };

  return (
    <>
      {!claims.length ? (
        <TableNoResults key={Math.random()} />
      ) : (
        claims.map((claim) => (
          <Table.Row key={Math.random()}>
            {columns.map((column) => column.projection && column.getCell && column.getCell(claim, column))}
            <Table.Cell className="col-dropdown-menu-sticky" key={Math.random()}>
              {claimsListDropdown(claim)}
            </Table.Cell>
          </Table.Row>
        ))
      )}
    </>
  );
};

export default ClaimsList;
