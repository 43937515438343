import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Dimmer } from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { selectCustomer } from "~/store/customer/actions";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import {
  changeStatusOrder,
  clearSelected,
  selectOrder,
  clearCreated,
} from "~/store/orders/actions";
import { Order } from "~/store/orders/types";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import {
  TOrderDetailsProps,
  TReduxActions,
  TReduxState,
} from "~/pages/Orders/Details/constants";
import translations from "~/utils/translations";
import CardsClientPanel from "./lib/CardsClientPanel";

const OrderDetails: React.FC<TOrderDetailsProps> = ({
  match,
  orderIn,
  dictionaries,
  loading,
  created,
  selectOrder,
  clearSelected,
  fetchDictionary,
  clearCreated,
}) => {
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    if (!order || order.id !== match.params.id) {
      fetchDictionary(
        DictionaryName.orderType,
        DictionaryName.orderStatus,
        DictionaryName.orderDeliveryType
      );
      selectOrder(match.params.id, true);
      return () => {
        clearSelected();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, clearSelected, fetchDictionary, selectOrder]);

  useEffect(() => {
    if (created) {
      clearCreated();
    }
  }, [clearCreated, created]);

  useEffect(() => {
    document.title = translations.format("app.orders");
    setOrder(orderIn);
    if (orderIn && orderIn.customer) {
      selectCustomer(orderIn.customer.rrid);
    }
  }, [orderIn]);

  const handleOrderUpdate = (updatedModel: Order): void => {
    if (order && order.customer.rrid !== updatedModel.customer.rrid) {
      selectOrder(order.id);
      selectCustomer(order.customer.rrid);
    } else if (
      order &&
      order.deliveryCustomer.rrid !== updatedModel.deliveryCustomer.rrid
    ) {
      selectOrder(order.id);
    } else {
      setOrder(updatedModel);
    }
  };

  const getDictionaryText = (
    dictionary: DictionaryItem[],
    value: any
  ): JSX.Element | string => {
    const found = dictionary.find((dict) => dict.value === value);

    return found ? found.text : "";
  };

  const cardsRendered = useMemo(
    () => <CardsClientPanel editMode={false} order={order} />,
    [order]
  );

  const renderedOrderTitle = useMemo(
    () => (!order ? "Wczytywanie..." : order.orderNumber),
    [order]
  );

  return (
    <Dimmer.Dimmable style={{ minHeight: "80%" }}>
      <SmartWrapper
        endpoint={order ? `/orders/${order.id}` : ""}
        model={order}
        onUpdate={handleOrderUpdate}
      >
        <PageHeader
          icon="shop"
          title={
            <>
              <FormattedMessage id="app.order" />{" "}
              {order
                ? getDictionaryText(
                    dictionaries[DictionaryName.orderType],
                    order.type
                  )
                : ""}
              : {renderedOrderTitle}
            </>
          }
          breadcrumb={[
            { text: <FormattedMessage id="app.list" />, link: "/orders" },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[]}
          refreshAction={() => {
            clearSelected();
            selectOrder(match.params.id, true);
          }}
          loading={loading}
        />
        {cardsRendered}
      </SmartWrapper>
      <CommonLoader loading={loading || !order} />
      <Fragment></Fragment>
    </Dimmer.Dimmable>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  orders,
  dictionaries,
}: ApplicationState) => ({
  orderIn: orders.selected,
  loading: orders.loadingOrder || dictionaries.loading,
  created: orders.created,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  selectOrder,
  clearSelected,
  fetchDictionary,
  changeStatusOrder,
  clearCreated,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
