import React, { Fragment, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Table } from "semantic-ui-react"
import mapDictionary from "~/components/MapDictionary/mapDictionary"


import { THeaderButton } from "~/components/PageHeader/lib/types"
import PageHeader from "~/components/PageHeader/PageHeader"
import { ApplicationState } from "~/store"
import dictionariesStatic from "~/store/dictionaries/static"

const columns = [
    { name: 'code', label: 'Kod promocji', projection: true },
    { name: 'status', label: 'Status', projection: true, dictionary: dictionariesStatic.PROMO_STATUS },
]

const PromotionImportList = () => {

    const { importedList } = useSelector((store: ApplicationState) => store.promotions)

    const getCellContent = (promotion: any, column: any): JSX.Element => {
        switch (column.name) {
            case 'status':
                return mapDictionary(String(promotion[column.name]), column.dictionary || [], true)
            case 'code':
                return <Link  to={'/promotion/' + promotion.id}>{promotion.code}</Link>
            default:
                return <>{promotion[column.name]}</>
        }
    }

    const headerButtons: THeaderButton[] = useMemo(() => {
        return [

        ]
    }, [])

    return <>
        <PageHeader
            icon='star outline'
            title={<>
                <FormattedMessage id="app.promotions.importList" />
            </>}
            breadcrumb={[
                { text: <FormattedMessage id="app.list" />, link: "/promotions" },
                { text: <FormattedMessage id="app.promo.ImportPageTitle" />, link: "import-promotion" },
                { text: <FormattedMessage id="app.promo.ImportListPageTitle" /> }
            ]}
            buttons={headerButtons}
            loading={false}
        />
        <div className="uber-table no-wrap">
            <Table selectable >
                <Table.Header>
                    <Table.Row>
                        {columns.map(column =>
                            column.projection &&
                            <Fragment key={column.name}>
                                <Table.HeaderCell key={column.name} style={{ width: column.width }}>{column.label}</Table.HeaderCell>
                            </Fragment>
                        )}
                        <Table.HeaderCell collapsing />
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {
                        importedList && importedList.map((item, i) =>
                            <Table.Row key={item.promoID}>
                                {columns.map(column => <Table.Cell key={item.promoID}>{column.projection && getCellContent(item, column)} </Table.Cell>)}

                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </div>

    </>
}

export default PromotionImportList