import {
    TModelFormContext,
    TModelFormProps,
    TModelValidation,
    TModelValidators
} from "~/components/ModelForm/lib/types";
import { useCallback, useEffect, useState } from "react";
import { SmartValidator } from "~/components/SmartField/lib/validator";
import { extractValue, mergeValue } from "~/utils/model";

export const useFormWrapper = <T, >(props: TModelFormProps<T>) => {
    const [model, setModel] = useState<T>(props.model || {} as T)
    const [validation, setValidation] = useState<TModelValidation<T>>({} as TModelValidation<T>)
    const [validators, setValidators] = useState<TModelValidators<T>>({} as TModelValidators<T>)
    const [doSubmit, setDoSubmit] = useState(false);

    useEffect(() => {
        if (doSubmit && model && props.onSubmit && validation) {
            for (let key in validation) {
                if (validation[key] !== undefined) {
                    setDoSubmit(false)
                    return
                }
            }

            props.onSubmit(model)
            setDoSubmit(false)
        }
    }, [doSubmit, model, props, validation])

    const updateValidators=useCallback(()=>{
        for (let name in validators) {
            const fieldError = validators[name].map((validator: SmartValidator) =>
                validator(extractValue(model, name), model)
            ).find((message) =>
                !!message
            )

            setValidation(validation => ({...validation, [name]: fieldError}))
        }
    },[validators, model]);

    const addValidators = useCallback((fieldName: keyof T, fieldValidators: SmartValidator[]): void => {
        setValidators((prevValidators) => ({...prevValidators, [fieldName]: fieldValidators}))
    }, []);

    const updateModel = useCallback((fieldName: keyof T, value: any): void => {
        setModel(prevModel => (mergeValue(prevModel, String(fieldName), value)))
    }, []);

    const submit = useCallback((): void => {
        updateValidators();

        setDoSubmit(true)
        // eslint-disable-next-line
    }, [validators, model, updateValidators]);

    const context: TModelFormContext = {
        model: model,
        updateModel,
        validation,
        addValidators,
        submit
    }
    return {context}
}