import { TPromotion, TPromotionType } from '~/store/promotions/types'
import ActualPromotion from './Actual'
import CascadePromotion from './Cascade'
import CurrencyPromotion from './Currency'
import AssociativePromotion from './Associative'

import React from 'react'
import GroupPromotion from './Group'

type TProps = {
    promotion: TPromotion
    editMode: boolean
    type: TPromotionType
}

const EffectDefinition: React.FC<TProps> = ({
    promotion,
    editMode,
    type
}) => {

    const renderTypeDefinition = (): JSX.Element => {
        switch (type) {
            case 'CASCADE':
                return <CascadePromotion editMode={editMode} promotion={promotion} />
            case 'ACTUAL':
                return <ActualPromotion editMode={editMode} promotion={promotion} />
            case 'CURRENCY':
                return <CurrencyPromotion editMode={editMode} promotion={promotion} />
            case 'ASSOCIATIVE':
                return <AssociativePromotion editMode={editMode} promotion={promotion} />
            case 'GROUP':
                return <GroupPromotion editMode={editMode} promotion={promotion} />
            default:
                return <em>Wybierz typ promocji aby zdefiniować jej skutek</em>
        }
    }

    return renderTypeDefinition()
}

export default EffectDefinition
