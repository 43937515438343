import { Button, Label, Divider } from 'semantic-ui-react'
import { SmartFieldContext } from '~/components/SmartField/lib/smartField.config'
import { fetchDictionary } from '~/store/dictionaries/actions'
import { TPromotion } from '~/store/promotions/types'
import CustomerItem, { TCustomerRuleMulti } from './CustomerItem'

import React, { useContext, useEffect, useState } from 'react'
import { DictionaryName } from '~/store/dictionaries/types'

type TProps =  {
    editMode: boolean,
    promotion: TPromotion
}

const CustomersPromo: React.FC<TProps> = ({
    editMode,
    promotion,
}) => {
    useEffect(() => {
        fetchDictionary(DictionaryName.customerCategoryOrganization)
    }, [])

    const [newRow, setNewRow] = useState(false)
    const [rules, setRules] = useState<TCustomerRuleMulti[]>(promotion?.ruleSet?.customerRuleList || [])

    const [loadingIndex, setLoadingIndex] = useState<number>()

    const { callPatch } = useContext(SmartFieldContext)

    const handleAdd = (): void => {
        const last = rules[rules.length - 1]
        if (!last || (last.rrdiList && last.rrdiList.length > 0) || last.classList || last.brandList) {
            setNewRow(true)
        }
    }

    const handleEdit = (index: number, updated: TCustomerRuleMulti): void => {
        const updatedRules = [...rules]
        updatedRules[index] = updated
        setLoadingIndex(index)
        setRules(updatedRules)
    }

    const handleRemove = (indexToRemove: number): void => {
        const updatedRules = [...rules]
        updatedRules.splice(indexToRemove, 1)
        setLoadingIndex(indexToRemove)
        setRules(updatedRules)
    }
    const [error, setError] = useState(false)
    useEffect(() => {
        if (!error && JSON.stringify(rules) !== JSON.stringify(promotion?.ruleSet?.customerRuleList)) {
            callPatch('ruleSet.customerRuleList', rules)
            .catch(() => {
                if (loadingIndex !== undefined) {
                    setError(true)
                    setRules(rules => {
                        const rulesToSet = [...rules]
                        rulesToSet[loadingIndex] = {
                            rrdiList: [],
                            classList: null,
                            brandList: null
                        }
                        return rulesToSet
                    })
                }
            })
            .finally(() => {
                setLoadingIndex(undefined)
            })
        } else {
            setError(false)
            setLoadingIndex(undefined)
        }
        // eslint-disable-next-line
    }, [callPatch, promotion?.ruleSet?.customerRuleList, rules,])

    useEffect(() => {
        setRules(promotion?.ruleSet?.customerRuleList || [])
    }, [promotion?.ruleSet?.customerRuleList])

    const renderGroup = (customerRule: TCustomerRuleMulti, index: number): JSX.Element => {
        return <CustomerItem
            key={index + JSON.stringify(customerRule)}
            index={index}
            loading={loadingIndex === index}
            rule={{
                rrdiList: customerRule.rrdiList,
                classList: customerRule.classList,
                brandList: customerRule.brandList
            }}
            editMode={editMode}
            onEdit={handleEdit}
            onRemove={handleRemove}
        />
    }

    const renderRules = (): JSX.Element[] | JSX.Element => {
        if (newRow) {
            setRules([...rules, { rrdiList: [], brandList: [], classList: [] }])
            setNewRow(false)
        }
        return rules.length > 0
            ? rules.map(renderGroup)
            : <i style={{ display: 'block', marginBottom: '1em' }}>Nie określono warunków. Uwzględniani są wszyscy kontrahenci.</i>
    }

    return (
        <>
            <Label pointing="below" style={{ lineHeight: '18px' }}>
                Określ, którzy kontrahenci mają być objęci promocją. Jeżeli nie zdefiniujesz żadnego warunku, uwzględnieni będą wszyscy kontrahenci.
                W przypadku wielu warunków co najmniej jeden z nich musi zostać spełniony, aby promocja została zastosowana podczas składania zamówienia.
            </Label>
            <Divider />
            {renderRules()}
            <Button icon="add" size="medium" circular basic
                style={{ display: editMode ? 'block' : 'none' }}
                content="Dodaj warunek"
                onClick={handleAdd} />
        </>
    )
}

export default CustomersPromo
