import { FormattedMessage } from "react-intl";
import React from "react";

type TProps = {
  id: string;
  badge?: number;
};

const MenuTitle = ({ id, badge }: TProps) => (
  <span>
    <FormattedMessage id={id} />
    {badge ? (
      <span
        className="notification-count count-text-sm"
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          right: "10px",
          color: "#fff",
        }}
      >
        {badge}
      </span>
    ) : (
      ""
    )}
  </span>
);

export default MenuTitle;
