import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { IVersion, VersionActionTypes } from './types'
import { fetchVersionSuccess } from './actions'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'

function* handleFetchVersion() {
    try {
        const version:IVersion = yield call(callApi, 'get', '/version')
        yield put(fetchVersionSuccess(version))
    } catch (error) {
        yield put(handleError(error, false, 'Nie udało sie pobrać wersji'))
    }
}

function* watchFetchVersion() {
    yield takeEvery(VersionActionTypes.FETCH_VERSION, handleFetchVersion)
}

function* versionSaga() {
    yield all([
        fork(watchFetchVersion)
    ])
}

export default versionSaga