import { DictionaryItem } from '~/store/dictionaries/types';

import translations from '~/utils/translations';
import { DropdownItemProps } from 'semantic-ui-react';

export const useSelectOptions = () => {
  const gender: DictionaryItem[] = [
    { key: 'm', text: 'Mężczyzna', value: 'male' },
    { key: 'f', text: 'Kobieta', value: 'female' },
    { key: 'o', text: 'Inna', value: 'other' },
  ];

  const company: DictionaryItem[] = [
    { key: 'person', text: translations.format('app.customer.person'), value: false },
    { key: 'company', text: translations.format('app.customer.informationAddressCompany'), value: true },
  ];

  const unity: DictionaryItem[] = [
    { key: 'no', text: 'NIE', value: false },
    { key: 'yes', text: 'TAK', value: true },
  ];

  const roles: DictionaryItem[] = [
    { key: 'R1', text: 'Użytkownik zwykły', value: 'R1' },
    { key: 'R2', text: 'Płatnik', value: 'R2' },
    { key: 'R3', text: 'Administrator', value: 'R3' },
  ];

  const orderType: DictionaryItem[] = [
    { key: 'NORMAL', value: 'NORMAL', text: 'Normal' },
    { key: 'WARRANTY', value: 'WARRANTY', text: 'Warranty' },
    { key: 'BONUS', value: 'BONUS', text: 'Bonus' },
    { key: 'SPECIAL', value: 'SPECIAL', text: 'Special' },
  ];

  const customerTypes: DropdownItemProps[] = [
    {
      text: 'Płatnik',
      value: 'PAYER',
    },
    {
      text: 'Punkt odbioru',
      value: 'DELIVERY',
    },
  ];

  return {
    gender,
    company,
    unity,
    roles,
    orderType,
    customerTypes,
  };
};
