import { handleFileUploadError } from '~/utils/fileUploadErrorHandler'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { callApi, callApiUploadFile, callApiWithRetry } from '~/utils/api'
import { handleError } from '../error/actions'
import {
    activatePromotion,
    activatePromotionCollision,
    activatePromotionFailure,
    activatePromotionSuccess,
    clonePromotion,
    cloneSuccess,
    closePromotion,
    closePromotionSuccess,
    createPromotion,
    createSuccess,
    deletePromotion,
    deleteSuccess,
    fetchPromotions,
    fetchSuccess,
    importFinished,
    importPromotion,
    importReferences,
    promotionSelected,
    redirectToPromoViewList,
    selectPromotion,
    updatePromo,
    updatePromoSuccess,
    validateFinished,
    validatePromotion
} from './actions'
import {
    AdditionalData,
    PaginablePromotion,
    PromotionsActionTypes,
    TPromotion,
    TPromotionActivateCollisionInfo,
    TReferenceRuleData
} from './types'
import { toastError, toastSuccess } from "~/utils/toast";
import { formatPromoImportErrorMessage, PROMO_IMPORT_CODE_ERROR } from "~/utils/promoImportErrorMessageFormatter";

function* handleFetch(action: ReturnType<typeof fetchPromotions>) {
    try {
        let filters = ''
        let sort = ''
        action.payload.filters.forEach((filter: any) => {
            filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
        })
        if (action.payload.sortColumn && action.payload.sortDirection) {
            sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`
        }
        const promotions: PaginablePromotion = yield call(callApi, 'get', `/promo?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`)
        yield put(fetchSuccess(promotions))
    } catch (error) {
        yield put(handleError(error, false, "Nie można wczytać promocji"))
    }
}

function* handleUpdatePromo(action: ReturnType<typeof updatePromo>) {
    try {
        yield call(callApi, 'post', `/promo/${action.payload.id}`, action.payload.promotion)
        yield put(updatePromoSuccess())
    }catch (error){
        yield put(handleError(error, false, "Nie udało się zaaktualizować promocji"))
    }
}

function* handleSelect(action: ReturnType<typeof selectPromotion>) {
    try {
        const promotion: TPromotion = yield call(callApiWithRetry, 3, 'get', `/promo/${action.payload}`)
        let promoToSet = {...promotion}
        if (!promotion.ruleSet) {
            promoToSet = {...promotion, ruleSet: {customerRuleList: [], referenceRule: {references: []}}}
        } else if (promotion.ruleSet && !promotion.ruleSet.referenceRule) {
            promoToSet = {...promotion, ruleSet: {...promotion.ruleSet, referenceRule: {references: []}}}
        }
        yield put(promotionSelected(promoToSet))
    } catch (error) {
        yield put(handleError(error, true, "Nie można wczytać promocji"))
    }
}

function* handleCreatePromotion(action: ReturnType<typeof createPromotion>) {
    try {
        const promoId: string = yield call(callApi, 'post', `/promo`, action.payload)
        yield put(createSuccess(promoId))
    } catch (error) {
        yield put(handleError(error, false, "Nie można utworzyć promocji"))
    }
}

function* handleActivatePromotion(action: ReturnType<typeof activatePromotion>) {
    try {
        const { promoId } = action.payload
        const response: TPromotionActivateCollisionInfo = yield call(callApi, 'PATCH', `/promo/${promoId}/activate`)
        if(response.code){
            yield put(activatePromotionCollision(response))
            yield toastError("app.info.promo.activate.collision.error", "app.error.common.update")
        }else {
            yield toastSuccess("app.info.promo.activate.success","app.info.common.success")
            yield put(activatePromotionSuccess())
        }
    } catch (error) {
        yield toastError("app.info.promo.activate.error", error.message ? error.message : "app.error.common.update")
        yield put(activatePromotionFailure())
    }
}

function* handleClonePromotion(action: ReturnType<typeof clonePromotion>) {
    try {
        const promoId: string = yield call(callApi, 'post', `/promo/clone`, action.payload)
        yield put(cloneSuccess(promoId))
    } catch (error) {
        yield put(handleError(error, false, "Nie można sklonować promocji"))
    }
}

function* handleDelete(action: ReturnType<typeof deletePromotion>) {
    try {
        yield call(callApi, 'delete', `/promo/${action.payload}`)
        yield put(deleteSuccess())
        toastSuccess("app.info.promo.delete.success","app.info.common.success")
    } catch (error) {
        yield put(handleError(error, false, "Nie udało się aktywować promocji"))
    }
}

function* handleClosePromo(action: ReturnType<typeof closePromotion>) {
    try{
        yield call(callApi, 'PATCH', `/promo/${action.payload}/close`)
        yield put(closePromotionSuccess())
        toastSuccess("app.info.promo.close.success", "app.info.common.success")
    }catch (error){
        yield put(handleError(error, false, "Nie udało się zakończyć promocji"))
    }
}

function* handleImportReferences(action: ReturnType<typeof importReferences>) {
    try {
        const parsed: TReferenceRuleData[] = yield call(callApiUploadFile, `/promo/spare-parts/hierarchies`, action.payload)
        yield put(importFinished(parsed))
    } catch (error) {
        yield put(importFinished())
        yield handleFileUploadError(error)
    }
}

function* handleImportPromotion(action: ReturnType<typeof importPromotion>) {
    try {
        const { file, additionalData } = action.payload
        const response: TReferenceRuleData[] | TPromotionActivateCollisionInfo = yield call(callApiUploadFile, `/promo?import`, file, 'file', additionalData)
        if(response instanceof Array){
            yield put(importFinished(response))
            toastSuccess("app.info.promo.import.success", "app.info.common.success")
            yield put(redirectToPromoViewList())
        }else if(response.code === PROMO_IMPORT_CODE_ERROR.PROMO_COLLISION) {
            yield put(activatePromotionCollision(response))
            toastError("app.info.promo.import.failure", (response.additionalData && response.code) ? formatPromoImportErrorMessage(response.additionalData, response.code) : response.message)
        }else if(response.code === PROMO_IMPORT_CODE_ERROR.IMPORTED_PROMO_COLLISION){
            toastError("app.info.promo.import.failure", (response.additionalData && response.code) ? formatPromoImportErrorMessage(response.additionalData, response.code) : response.message)
        }
    } catch (error) {
        yield handleFileUploadError(error)
    }
}

function* handleValidationPromotion(action: ReturnType<typeof validatePromotion>) {
    try {
        const result: AdditionalData[] = yield call(callApi, 'post', `/promo/validate`, action.payload)
        yield put(validateFinished(result))
    } catch (error) {
        yield put(handleError(error, false, "Błąd walidacji promocji"))
    }
}

function* watchFetchRequest() {
    yield takeEvery(PromotionsActionTypes.FETCH_REQUEST, handleFetch)
}

function* watchSelectPromotion() {
    yield takeLatest(PromotionsActionTypes.SELECT_PROMOTION, handleSelect)
}

function* watchUpdatePromotion() {
    yield takeLatest(PromotionsActionTypes.UPDATE_PROMO, handleUpdatePromo)
}

function* watchCreatePromotion() {
    yield takeEvery(PromotionsActionTypes.CREATE_PROMOTION, handleCreatePromotion)
}

function* watchActivatePromotion() {
    yield takeEvery(PromotionsActionTypes.ACTIVATE_PROMOTION, handleActivatePromotion)
}

function* watchClonePromotion() {
    yield takeEvery(PromotionsActionTypes.CLONE_PROMOTION, handleClonePromotion)
}

function* watchDeletePromotion() {
    yield takeLatest(PromotionsActionTypes.DELETE_PROMOTION, handleDelete)
}

function* watchClosePromotion() {
    yield takeLatest(PromotionsActionTypes.CLOSE_PROMOTION, handleClosePromo)
}

function* watchImportReferences() {
    yield takeLatest(PromotionsActionTypes.IMPORT_REFERENCES, handleImportReferences)
}

function* watchImportPromotion() {
    yield takeLatest(PromotionsActionTypes.IMPORT_PROMOTION, handleImportPromotion)
}

function* watchValidationPromotion() {
    yield takeEvery(PromotionsActionTypes.VALIDATE_PROMO, handleValidationPromotion)
}

function* promotionsSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchSelectPromotion),
        fork(watchDeletePromotion),
        fork(watchUpdatePromotion),
        fork(watchClosePromotion),
        fork(watchCreatePromotion),
        fork(watchActivatePromotion),
        fork(watchClonePromotion),
        fork(watchImportReferences),
        fork(watchImportPromotion),
        fork(watchValidationPromotion)
    ])
}

export default promotionsSaga
