import { IClaim } from '~/store/claims/types';

export const claimedValue = (claim: IClaim): number => {
  let value = 0;
  claim.claimLines.forEach((claimLine) => (value += claimLine.unitValue * claimLine.claimedQuantity));
  return parseFloat(value.toFixed(2));
};

export const acceptedValue = (claim: IClaim): number => {
  let value = 0;
  claim.claimLines.forEach((claimLine) => (value += claimLine.unitValue * claimLine.acceptedQuantity));
  return parseFloat(value.toFixed(2));
};
