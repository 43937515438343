import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import { Button, Table } from 'semantic-ui-react';
import React, { useMemo } from 'react';
import { InvoiceIdNumbersTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/InvoiceIdNumbersTableCell/constants';

export const InvoiceIdNumbersTableCell = ({ column, line, getInvoice }: InvoiceIdNumbersTableCellProps) => {
  const invoice = useMemo(() => (line[column.name] ? line[column.name] : []), [column.name, line]);

  return (
    <Table.Cell key={column.name} textAlign="right">
      {invoice.length}
      <InfoIcon
        documents={invoice}
        clickableItem={(document) => (
          <Button
            style={{ display: 'block' }}
            compact
            basic
            onClick={() => {
              getInvoice(document.invoiceNo, document.documentMask);
            }}
          >
            {document.documentMask || document.invoiceNo}
          </Button>
        )}
        noResultsMessage="Brak faktur"
      />
    </Table.Cell>
  );
};
