import { PasswordPolicyCheck } from "~/components/ModelForm/ModelMessage";

export const formatPluralSingularPolicyText = (policy: PasswordPolicyCheck) => {
    if (policy.type === 'LENGTH') {
        return 'znaków'
    } else if (policy.type === 'SPECIAL_CHARACTERS') {
        if (policy.min === 0 || policy.min >= 5) {
            return 'znaków specjalnych'
        } else {
            return 'znaki specjalne'
        }
    } else if (policy.type === 'DIGITS') {
        if (policy.min === 0 || policy.min >= 5) {
            return 'cyfr'
        } else {
            return 'cyfry'
        }
    } else if (policy.type === 'UPPERCASE') {
        if (policy.min === 0 || policy.min >= 5) {
            return 'dużych liter'
        } else if (policy.min === 1) {
            return 'dużą literę'
        } else if (policy.min > 1 && policy.min < 5) {
            return 'duże litery'
        }
    } else if (policy.type === 'LOWERCASE') {
        if (policy.min === 0 || policy.min >= 5) {
            return 'małych liter'
        } else if (policy.min === 1) {
            return 'małą literę'
        } else if (policy.min > 1 && policy.min < 5) {
            return 'małe litery'
        }
    }
}

export const getPasswordPolicyMessage = (policy: PasswordPolicyCheck) => {
    const policyFormattedText = formatPluralSingularPolicyText(policy);
    switch (policy.type) {
        case 'LENGTH': {
            return `musi składać się z min. ${policy.min} ${policyFormattedText}`
        }
        case 'SPECIAL_CHARACTERS': {
            return `musi zawierać min. ${policy.min} ${policyFormattedText}`
        }
        case 'DIGITS': {
            return `musi zawierać min. ${policy.min} ${policyFormattedText} (0-9)`
        }
        case 'LOWERCASE': {
            return `musi zawierać min. ${policy.min} ${policyFormattedText} (a-z)`
        }
        case 'UPPERCASE': {
            return `musi zawierać min. ${policy.min} ${policyFormattedText} (A-Z)`
        }
        case 'NOT_USERNAME': {
            return `nie może zawierać w sobie nazwy użytkownika`
        }
    }
}