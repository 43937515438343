import { action } from 'typesafe-actions'
import { Anomaly, AnomaliesActionTypes, TPart, PaginableAnomaly, TAnomalyType, TPartDestroyed } from "./types"

export const fetchAnomalies = (params: any) => action(AnomaliesActionTypes.FETCH_REQUEST, params)
export const fetchSuccess = (anomalies: PaginableAnomaly) => action(AnomaliesActionTypes.FETCH_SUCCESS, anomalies)

export const resolveDestroyedParts = (partsToDestroy: TPartDestroyed[], anomalyId: string) => action(AnomaliesActionTypes.RESOLVE_DESTROYED_PARTS, { partsToDestroy, anomalyId })

export const selectAnomaly = (id: string) => action(AnomaliesActionTypes.SELECT_ANOMALY, id)
export const anomalySelected = (anomaly: Anomaly) => action(AnomaliesActionTypes.SELECTED, anomaly)

export const resolveAnomaly = (anomalyId: string, selectedGDNOption: string, type: TAnomalyType, parts?: TPart[]) => action(AnomaliesActionTypes.RESOLVE_ANOMALY, { anomalyId, type, parts, selectedGDNOption })
export const closeAnomaly = (anomalyId: string, type: TAnomalyType, selectedGDNOption: string) => action(AnomaliesActionTypes.CLOSE_ANOMALY, { anomalyId, type, selectedGDNOption })

export const closeResolveSuccess = () => action(AnomaliesActionTypes.CLOSE_RESOLVE_SUCCESS)
export const resolveDestroyedLinesSuccess = () => action(AnomaliesActionTypes.RESOLVE_DESTROYED_PARTS_SUCCESS)

export const rejectRW = (anomalyId: string) => action(AnomaliesActionTypes.REJECT_RW, anomalyId)
export const rejectRWSuccess = () => action(AnomaliesActionTypes.REJECT_RW_SUCCESS)
