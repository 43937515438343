import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useAdvancedSearch } from "~/components/AdvancedSearch/hooks/useAdvancedSearch";

import {
  FIELD_TYPE,
  FormField,
} from "~/components/AdvancedSearch/types/advancedSearchType";
import { TColumn } from "~/components/Table/lib/types";
import { IRefObject } from "~/components/TableFilter/TableFilter";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryItem } from "~/store/dictionaries/types";
import TableFilterSession, {
  TTableFilterSession,
} from "~/utils/tableFilterSession";
import translations from "~/utils/translations";

export const useBackordersAdvancedSearch = (
  columns: TColumn[],
  setColumns: Dispatch<SetStateAction<TColumn[]>>,
  sortColumn: string,
  sortDirection: string,
  getOrders: (params?: Object) => void,
  filterLabelsRef: MutableRefObject<TTableFilterSession[]>,
  tableKey: string
) => {
  const tableFilter = React.createRef<IRefObject>();
  const [unwind, setUnwind] = useState<boolean>(false);
  const [selectedBusinessSector, setSelectedBusinessSector] =
    useState<DictionaryItem>();
  const [clientIdValue, setClientIdValue] = useState<string>("");
  const [deliveryIdValue, setDeliveryIdValue] = useState<string>("");

  const { search } = useLocation();
  const {
    toggleProductColumn,
    turnOffUnwidProcess,
    addUpdateFilterLabel,
    setColumnsFromLocalStorage,
    getTableFilterSession,
  } = useAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    tableKey,
    filterLabelsRef,
    "backordersColumns"
  );

  const removeFilterLabel = useCallback(
    (name: string) => {
      if (name === "unwind") {
        setUnwind(false);

        toggleProductColumn(
          [
            "supplierSystem.businessSector",
            "supplierSystem.clientId",
            "supplierSystem.deliveryId",
          ],
          [false, false, false]
        );

        turnOffUnwidProcess();
      }

      if (name === "supplierSystem") {
        setSelectedBusinessSector(undefined);
        setClientIdValue("");
        setDeliveryIdValue("");
      }

      const newFilterLabels = filterLabelsRef.current.filter(
        (label) => label.name !== name
      );

      filterLabelsRef.current = newFilterLabels;

      TableFilterSession.clearFilter({ name, table: tableKey, value: "" });

      if (tableFilter.current) {
        tableFilter.current.clearFilter(name);
      }
    },
    [
      filterLabelsRef,
      tableFilter,
      toggleProductColumn,
      turnOffUnwidProcess,
      tableKey,
    ]
  );

  const handleAddFilter = useCallback(
    (name: string, value: string, text?: string, key?: string) => {
      let checkIsInFilterTable = false;

      filterLabelsRef.current.forEach((filter) => {
        if (filter.value === value && filter.name === name) {
          checkIsInFilterTable = true;
        }
      });

      if (!checkIsInFilterTable) {
        if (value !== "") {
          addUpdateFilterLabel(name, value, text, key);
        } else {
          removeFilterLabel(name);
        }
      }
    },
    [filterLabelsRef, removeFilterLabel, addUpdateFilterLabel]
  );

  const handleSubmit = useCallback(() => {
    if (selectedBusinessSector?.value || clientIdValue || deliveryIdValue) {
      handleAddFilter(
        "deliveryCustomer.supplierSystem",
        `name:CSPS${selectedBusinessSector?.value ? `,businessSector:${selectedBusinessSector.value}` : ""}${
          clientIdValue ? ",clientId:" + clientIdValue : ""
        }${deliveryIdValue ? ",deliveryId:" + deliveryIdValue : ""}`,
        `${selectedBusinessSector?.value || ""} ${clientIdValue} ${deliveryIdValue}`
      );
    } else {
      handleAddFilter("deliveryCustomer.supplierSystem", "");
    }

    getOrders({ page: 1 });
  }, [
    selectedBusinessSector,
    clientIdValue,
    deliveryIdValue,
    handleAddFilter,
    getOrders,
  ]);

  const unwindColumn = useCallback(
    (inputState: boolean) => {
      setUnwind(inputState);

      toggleProductColumn(
        [
          "supplierSystem.businessSector",
          "supplierSystem.clientId",
          "supplierSystem.deliveryId",
        ],
        [inputState, inputState, inputState]
      );

      handleAddFilter(
        "unwind",
        inputState ? "deliveryCustomer.supplierSystem" : "",
        "Rozgrupuj"
      );

      if (!inputState) {
        turnOffUnwidProcess();
        toggleProductColumn(
          [
            "supplierSystem.businessSector",
            "supplierSystem.clientId",
            "supplierSystem.deliveryId",
          ],
          [false, false, false]
        );
      }

      handleSubmit();
    },
    [toggleProductColumn, handleAddFilter, handleSubmit, turnOffUnwidProcess]
  );

  const formFields: FormField[][] = useMemo(
    () => [
      [
        {
          type: FIELD_TYPE.SELECT,
          name: "businessSector",
          label: translations.format(
            "app.table.supplierSystem.bussinesSystem.backorders"
          ),
          placeholder: translations.format(
            "app.table.supplierSystem.bussinesSystem.backorders"
          ),
          options: [...dictionariesStatic.SUPPLIER_SYSTEMS],
          value: selectedBusinessSector?.value,
          onChange: (_, { value }) => {
            const newSelectedBusinessSector =
              dictionariesStatic.SUPPLIER_SYSTEMS.find(
                (dictionary) => dictionary.value === value
              );

            setSelectedBusinessSector(newSelectedBusinessSector);
          },
        },
        {
          type: FIELD_TYPE.INPUT,
          name: "clientIdFilter",
          label: translations.format("app.table.supplierSystem.clientId"),
          placeholder: translations.format("app.table.supplierSystem.clientId"),
          value: clientIdValue,
          maxLength: 7,
          onChange: (_, { value }) => {
            setClientIdValue(value);
          },
        },
        {
          type: FIELD_TYPE.INPUT,
          name: "deliveryIdFilter",
          label: translations.format("app.table.supplierSystem.deliveryId"),
          placeholder: translations.format(
            "app.table.supplierSystem.deliveryId"
          ),
          value: deliveryIdValue,
          maxLength: 4,
          onChange: (_, { value }) => {
            setDeliveryIdValue(value);
          },
        },
      ],
      [
        {
          type: FIELD_TYPE.CHECKBOX,
          label: "Rozgrupuj",
          name: "unwind",
          value: unwind,
          onChange: (_, { checked }) => {
            unwindColumn(checked);
          },
        },
      ],
    ],
    [
      clientIdValue,
      deliveryIdValue,
      selectedBusinessSector?.value,
      unwind,
      unwindColumn,
    ]
  );

  useEffect(() => {
    setColumnsFromLocalStorage();
  }, [setColumnsFromLocalStorage]);

  useEffect(() => {
    if (search) {
      const initParams = new URLSearchParams(search);

      initParams.forEach((value, name) => {
        if (value && value !== "null") {
          addUpdateFilterLabel(name, value, value);
        }
      });
    }
  }, [search, addUpdateFilterLabel, getOrders]);

  useEffect(() => {
    const filters = getTableFilterSession(tableKey);
    const supplierFilter = filters.find(
      (item: any) => item.name === "supplierSystem"
    );

    if (supplierFilter) {
      const values = supplierFilter.value.split(",") as string[];

      values.forEach((value) => {
        if (value.includes("businessSector")) {
          const newSelectedBusinessSector =
            dictionariesStatic.SUPPLIER_SYSTEMS.find(
              (dictionary) => dictionary.value === value.split(":")[1]
            ) || undefined;

          setSelectedBusinessSector(newSelectedBusinessSector);
        }
        if (value.includes("clientId")) {
          setClientIdValue(value.split(":")[1]);
        }
        if (value.includes("deliveryId")) {
          setDeliveryIdValue(value.split(":")[1]);
        }
      });
    }
  }, [
    getTableFilterSession,
    setSelectedBusinessSector,
    setClientIdValue,
    setDeliveryIdValue,
    tableKey,
  ]);

  return {
    formFields,
    handleSubmit,
  };
};
