import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Grid } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import PageHeader from "~/components/PageHeader/PageHeader";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { ApplicationState } from "~/store";
import { fetchStockDetails } from "~/store/distrigo-warehouses/actions";
import translations from "~/utils/translations";

const WarehouseDetails = () => {
  const dispatch = useDispatch();
  const { distrigoWarehouse, loadingDistrigoWarehouses } = useSelector(
    (state: ApplicationState) => state.distrigoWarehouses
  );
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    document.title = translations.format("app.distrigoStore");
    dispatch(fetchStockDetails(id));
  }, [dispatch, id]);

  const fetchDistrigoWarehouse = () => {
    dispatch(fetchStockDetails(id));
  };

  const { renderLabel } = useRenderingFunctions(distrigoWarehouse, false);

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const cards: JSX.Element[] = useMemo(() => {
    return !distrigoWarehouse
      ? []
      : [
          <DetailCard
            title="app.informations"
            id="info-1"
            key="info-1"
            minHeight={100}
            width={5}
          >
            {renderLabel(
              formatMessage("app.warehouse.name"),
              distrigoWarehouse.name
            )}
            {renderLabel(
              formatMessage("app.warehouse.warehouseName"),
              distrigoWarehouse.warehouseName
            )}
            {renderLabel(
              formatMessage("app.warehouse.distributionPlatformID"),
              distrigoWarehouse.distributionPlatformID
            )}
            {renderLabel(
              formatMessage("app.warehouse.sapId"),
              distrigoWarehouse.sapId
            )}
            {renderLabel(
              formatMessage("app.warehouse.warehouseID"),
              distrigoWarehouse.warehouseID
            )}
            {renderLabel(
              formatMessage("app.warehouse.vatId"),
              distrigoWarehouse.vatId
            )}
          </DetailCard>,
          <DetailCard
            title="app.address"
            id="info-2"
            key="info-2"
            minHeight={100}
            width={5}
          >
            {renderLabel(formatMessage("app.street"), distrigoWarehouse.street)}
            {renderLabel(
              formatMessage("app.buildingNumber"),
              distrigoWarehouse.buildingNumber
            )}
            {renderLabel(formatMessage("app.city"), distrigoWarehouse.city)}
            {renderLabel(
              formatMessage("app.zipCode"),
              distrigoWarehouse.zipCode
            )}
          </DetailCard>,
          <DetailCard
            title="app.delivery"
            id="info"
            key="info"
            minHeight={100}
            width={5}
          >
            {renderLabel(
              formatMessage("app.warehouse.ddsId"),
              distrigoWarehouse.ddsId
            )}
            {renderLabel(
              formatMessage("app.warehouse.sequenceNumber"),
              distrigoWarehouse.sequenceNumber
            )}
            {renderLabel(
              formatMessage("app.warehouse.deliveryPlatform"),
              distrigoWarehouse.deliveryPlatform
            )}
            {renderLabel(
              formatMessage("app.warehouse.deliveryRoute"),
              distrigoWarehouse.deliveryRoute
            )}
            {renderLabel(
              formatMessage("app.warehouse.deliveryZone"),
              distrigoWarehouse.deliveryZone
            )}
            {renderLabel(
              formatMessage("app.warehouse.deliveryGroup"),
              distrigoWarehouse.deliveryGroup
            )}
            {renderLabel(
              formatMessage("app.warehouse.stockDeliveryId"),
              distrigoWarehouse.stockDeliveryId
            )}
            {renderLabel(
              formatMessage("app.wmsCode"),
              distrigoWarehouse.wmsCode
            )}
            {renderLabel(
              formatMessage("app.warehouse.urgentDeliveryId"),
              distrigoWarehouse.urgentDeliveryId
            )}
          </DetailCard>,
        ];
  }, [distrigoWarehouse, renderLabel]);

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <div className="uber-content">
        <Grid stretched className="claim-details">
          {cards}
        </Grid>
      </div>
    );
  }, [cards]);

  return (
    <>
      <PageHeader
        icon="warehouse"
        title={`Magazyn ${id}`}
        breadcrumb={[
          {
            text: <FormattedMessage id="app.list" />,
            link: "/distrigo-warehouses",
          },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={[]}
        refreshAction={() => fetchDistrigoWarehouse()}
        loading={loadingDistrigoWarehouses}
      />
      {cardsRendered}
    </>
  );
};

export default WarehouseDetails;
