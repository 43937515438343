import { action } from "typesafe-actions";
import {
  ClaimsActionTypes,
  PaginableClaim,
  IClaimCreate,
  IClaim,
  CollectiveTaskData,
  CostCoveringProcessingLine,
} from "./types";

export const fetchClaims = (params: any) =>
  action(ClaimsActionTypes.FETCH_CLAIMS, params);
export const fetchClaimsSearch = (params: any) =>
  action(ClaimsActionTypes.FETCH_CLAIMS_SEARCH, params);
export const fetchClaimsSuccess = (claims: PaginableClaim) =>
  action(ClaimsActionTypes.FETCH_CLAIMS_SUCCESS, claims);
export const fetchClaimsSearchSuccess = (claims: PaginableClaim) =>
  action(ClaimsActionTypes.FETCH_CLAIMS_SEARCH_SUCCESS, claims);

export const createClaim = (claim: IClaimCreate) =>
  action(ClaimsActionTypes.CREATE_CLAIM, { claim });
export const createClaimSuccess = () =>
  action(ClaimsActionTypes.CREATE_CLAIM_SUCCESS);
export const clearCreated = () => action(ClaimsActionTypes.CLEAR_CREATED);

export const selectClaim = (claimNumber: string) =>
  action(ClaimsActionTypes.SELECT_CLAIM, claimNumber);

export const refreshClaim = (claimNumber: string) =>
  action(ClaimsActionTypes.REFRESH_CLAIM, claimNumber);

export const refreshClaimSuccess = (claim: IClaim) =>
  action(ClaimsActionTypes.REFRESH_CLAIM_SUCCESS, claim);

export const claimSelected = (claim: IClaim) =>
  action(ClaimsActionTypes.SELECTED_CLAIM, claim);
export const clearSelected = () =>
  action(ClaimsActionTypes.CLEAR_SELECTED_CLAIM);

export const getAttachment = (
  claimNumber: string,
  attachmentStoredName: string
) =>
  action(ClaimsActionTypes.GET_ATTACHMENT, {
    claimNumber,
    attachmentStoredName,
  });
export const addAttachments = (claimNumber: string, attachments: File[]) =>
  action(ClaimsActionTypes.ADD_ATTACHMENT, { claimNumber, attachments });
export const addAttachmentSuccess = () =>
  action(ClaimsActionTypes.ADD_ATTACHMENT_SUCCESS);
export const clearUpload = () => action(ClaimsActionTypes.CLEAR_ATTACHMENT);
export const createClaimTransition = (
  claimNumber: string,
  statusAction: string,
  statusNotes: string,
  lines: any
) =>
  action(ClaimsActionTypes.CREATE_CLAIM_TRANSITION, {
    claimNumber,
    statusAction,
    statusNotes,
    lines,
  });
export const createClaimTransitionSuccess = () =>
  action(ClaimsActionTypes.CREATE_CLAIM_TRANSITION_SUCCESS);
export const clearTransitionClaim = () =>
  action(ClaimsActionTypes.CLEAR_TRANSITION_CLAIM);

export const uploadCollectiveTask = (
  collectiveTaskData: CollectiveTaskData,
  file: File
) =>
  action(ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK, {
    collectiveTaskData,
    file,
  });
export const uploadCollectiveTaskSuccess = (
  claimsCreated: string[],
  errors: any[]
) =>
  action(ClaimsActionTypes.UPLOAD_COLLECTIVE_TASK_SUCCESS, {
    claimsCreated,
    errors,
  });
export const clearCollectiveData = () =>
  action(ClaimsActionTypes.CLEAR_COLLECTIVE_DATA);
export const getClaimLabel = (claimNo: string, pdfName: string) =>
  action(ClaimsActionTypes.GET_CLAIM_LABEL, { claimNo, pdfName });

export const fetchBackBoxReport = (params: {
  dateFrom: string;
  dateTo: string;
}) => action(ClaimsActionTypes.FETCH_BACK_BOX_REPORT, params);
export const fetchBackBoxReportSuccess = () =>
  action(ClaimsActionTypes.FETCH_BACK_BOX_REPORT_SUCCESS);

export const fetchChatTargetGroups = () =>
  action(ClaimsActionTypes.FETCH_CHAT_TARGET_GROUPS);
export const fetchChatTargetGroupsSuccess = (chatTargetGroups: string[]) =>
  action(ClaimsActionTypes.FETCH_CHAT_TARGET_GROUPS_SUCCESS, {
    chatTargetGroups,
  });

export const sendInnerMessage = (claimNumber: string, message: string) =>
  action(ClaimsActionTypes.SEND_INNER_MESSAGE, { claimNumber, message });

export const sendInnerMessageSuccess = () =>
  action(ClaimsActionTypes.SEND_INNER_MESSAGE_SUCCESS);

export const innerTransitionProcessing = (
  claimNumber: string,
  transportStatus: string,
  transportStatusNotes: string,
  warehouseStatus: string,
  warehouseStatusNotes: string
) =>
  action(ClaimsActionTypes.INNER_TRANSITION_PROCESSING, {
    claimNumber,
    transportStatus,
    transportStatusNotes,
    warehouseStatus,
    warehouseStatusNotes,
  });

export const innerTransitionProcessingSuccess = () =>
  action(ClaimsActionTypes.INNER_TRANSITION_PROCESSING_SUCCESS);

export const setAsUnread = (
  id: string,
  readStatus: boolean,
  redirect: () => void
) => action(ClaimsActionTypes.SET_AS_UNREAD, { id, readStatus, redirect });

export const setAsReadSuccess = (id: string) =>
  action(ClaimsActionTypes.SET_AS_UNREAD_SUCCESS, { id });

export const costCoveringProcessing = (
  lines: CostCoveringProcessingLine[],
  claimNr: string,
  notesWarehouse: string | null,
  notesTransport: string | null,
  closeModal: () => void
) =>
  action(ClaimsActionTypes.COST_COVERING_GROUP_PROCESS, {
    lines,
    claimNr,
    notesWarehouse,
    notesTransport,
    closeModal,
  });

export const costCoveringProcessingSuccess = () =>
  action(ClaimsActionTypes.COST_COVERING_GROUP_PROCESS_SUCCESS);

export const cloneClaim = (
  claim: IClaimCreate,
  sourceClaimNr: string,
  redirectAction: (newClaimNr: string) => void,
  handleError: () => void
) =>
  action(ClaimsActionTypes.CLONE_CLAIM, {
    claim,
    sourceClaimNr,
    redirectAction,
    handleErrorAction: handleError,
  });
