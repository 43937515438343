import React from "react";
import { useCallback } from "react";

import { BaseEditor, Editor, Element as SlateElement, Transforms } from "slate";
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "./constans";
import { ReactEditor } from "slate-react";
import { Button, Icon } from "semantic-ui-react";

type TProps = {
  editor: BaseEditor & ReactEditor;
  addText: (text: any) => void;
};

export const useTextEditor = ({ editor, addText }: TProps) => {
  const isBlockActive = useCallback(
    (editor: BaseEditor, format: any, blockType = "type") => {
      const { selection } = editor;
      if (!selection) return false;

      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            //@ts-ignore
            n[blockType] === format,
        })
      );

      return !!match;
    },
    []
  );

  const isMarkActive = useCallback((editor: BaseEditor, format: string) => {
    const marks = Editor.marks(editor);
    //@ts-ignore
    return marks ? marks[format] === true : false;
  }, []);

  const toggleBlock = useCallback(
    (editor: BaseEditor, format: any) => {
      const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      );
      const isList = LIST_TYPES.includes(format);

      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          // @ts-ignore
          LIST_TYPES.includes(n.type) &&
          !TEXT_ALIGN_TYPES.includes(format),
        split: true,
      });
      let newProperties: Partial<SlateElement>;
      if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
          // @ts-ignore
          align: isActive ? undefined : format,
        };
      } else {
        newProperties = {
          // @ts-ignore
          type: isActive ? "paragraph" : isList ? "list-item" : format,
        };
      }
      Transforms.setNodes<SlateElement>(editor, newProperties);

      if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
      }
    },
    [isBlockActive]
  );

  const toggleMark = useCallback(
    (editor: BaseEditor, format: string) => {
      const isActive = isMarkActive(editor, format);

      if (isActive) {
        Editor.removeMark(editor, format);
      } else {
        Editor.addMark(editor, format, true);
      }
    },
    [isMarkActive]
  );

  const BlockButton = useCallback(
    ({ format, icon }: any) => {
      return (
        <Button
          icon
          active={isBlockActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
          )}
          onMouseDown={(event: any) => {
            event.preventDefault();
            toggleBlock(editor, format);
            addText(editor.children);
          }}
        >
          <Icon name={icon} size={"large"}></Icon>
        </Button>
      );
    },
    [editor, addText, isBlockActive, toggleBlock]
  );

  const MarkButton = useCallback(
    ({ format, icon }: any) => {
      return (
        <Button
          icon
          active={isMarkActive(editor, format)}
          onMouseDown={(event: any) => {
            event.preventDefault();
            toggleMark(editor, format);
            addText(editor.children);
          }}
        >
          <Icon name={icon} size={"large"}></Icon>
        </Button>
      );
    },
    [editor, addText, isMarkActive, toggleMark]
  );

  return { isBlockActive, toggleBlock, toggleMark, BlockButton, MarkButton };
};
