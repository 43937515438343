import React from "react";
import { Notification } from "~/store/notifications/types";
import { Grid, Icon } from "semantic-ui-react";
import { getIconName } from "~/components/Notifications/lib/utils";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import dictionariesStatic from "~/store/dictionaries/static";
import { parseDate } from "~/utils/dateUtils";
import { makeRead } from "~/store/notifications/actions";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { useDispatch } from "react-redux";

export const NotificationInfoItem: React.FC<Notification> = ({ read, type, time, id, name }) => {
    const dispatch = useDispatch()
    return (
        <Grid.Row className={read ? "old-message" : "new-message"}>
            <Grid.Column textAlign="left">
                <Icon name={`${getIconName(type)}` as SemanticICONS} size="large" color="grey" />
                <div style={{ maxWidth: read ? "275px" : "250px", display: "inline-block" }}>
                    <span>
                        {
                            <DictionaryLabel value={name} dictionary={dictionariesStatic.NOTIFICATION_NAME} compact />
                        }
                    </span>
                </div>
                <em className="notification-date-info">{parseDate(time)}</em>
                {!read ?
                    <Icon
                        onClick={() => dispatch(makeRead(id))}
                        title={"Oznacz jako przeczytane"}
                        name="check circle outline"
                        size="large"
                        style={{ float: "right", cursor: "pointer" }}
                    />
                    : null
                }
            </Grid.Column>
        </Grid.Row>
    )
}