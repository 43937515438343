import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAdvancedSearch } from "~/components/AdvancedSearch/hooks/useAdvancedSearch";

import {
  FIELD_TYPE,
  FormField,
} from "~/components/AdvancedSearch/types/advancedSearchType";
import { TColumn } from "~/components/Table/lib/types";
import { IRefObject } from "~/components/TableFilter/TableFilter";
import { ApplicationState } from "~/store";
import { prepareDateForFilter } from "~/utils/dateUtils";
import TableFilterSession, {
  TTableFilterSession,
} from "~/utils/tableFilterSession";

export const useOrdersClientPanelAdvancedSearch = (
  columns: TColumn[],
  setColumns: Dispatch<SetStateAction<TColumn[]>>,
  sortColumn: string,
  sortDirection: string,
  getOrders: (params?: Object) => void,
  filterLabelsRef: MutableRefObject<TTableFilterSession[]>,
  tableKey: string,
  deliveryAddresses: any[]
) => {
  const tableFilter = React.createRef<IRefObject>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [deliveryAddress, setDeliveryAddress] = useState<string>("");

  const { ordersReferenceId, ordersDateFrom, ordersDateTo } = useSelector(
    (store: ApplicationState) => store.orders
  );
  const { search } = useLocation();
  const { addUpdateFilterLabel, setColumnsFromLocalStorage } =
    useAdvancedSearch(
      columns,
      setColumns,
      sortColumn,
      sortDirection,
      tableKey,
      filterLabelsRef,
      "ordersColumns"
    );

  const removeFilterLabel = useCallback(
    (name: string) => {
      const newFilterLabels = filterLabelsRef.current.filter(
        (label) => label.name !== name
      );

      filterLabelsRef.current = newFilterLabels;

      TableFilterSession.clearFilter({ name, table: tableKey, value: "" });

      if (tableFilter.current) {
        tableFilter.current.clearFilter(name);
      }
    },
    [filterLabelsRef, tableFilter, tableKey]
  );

  const handleAddFilter = useCallback(
    (name: string, value: string, text?: string, key?: string) => {
      let checkIsInFilterTable = false;

      filterLabelsRef.current.forEach((filter) => {
        if (filter.value === value && filter.name === name) {
          checkIsInFilterTable = true;
        }
      });

      if (!checkIsInFilterTable) {
        if (value !== "") {
          addUpdateFilterLabel(name, value, text, key);
        } else {
          removeFilterLabel(name);
        }
      }
    },
    [filterLabelsRef, removeFilterLabel, addUpdateFilterLabel]
  );

  const handleSubmit = useCallback(() => {
    let formReferenceId: string;

    if (dateTo || dateFrom) {
      handleAddFilter(
        "orderDate",
        prepareDateForFilter(dateTo, dateFrom, 19),
        "Data" + (dateFrom ? " od" : "") + (dateTo ? " do" : "")
      );
    } else {
      handleAddFilter("orderDate", "");
    }

    if (deliveryAddress) {
      handleAddFilter(
        "deliveryCustomer.rrid",
        deliveryAddress,
        "Adres dostawy"
      );
    } else {
      handleAddFilter("deliveryCustomer.rrid", "");
    }

    if (referenceId) {
      formReferenceId = "lines.referenceID:" + referenceId;
    } else {
      formReferenceId = "";
    }

    handleAddFilter("stringEqual", formReferenceId, "Numer Referencji");

    getOrders({ page: 1 });
  }, [
    dateTo,
    dateFrom,
    referenceId,
    handleAddFilter,
    getOrders,
    deliveryAddress,
  ]);

  const mapDelivery = (deliveryPoints: any): any[] => {
    return deliveryPoints.content.map((point: any, index: any) => {
      const key = `${point.name}-${index}`;
      return {
        key,
        title: `${point.city || ""} ${point.street || ""} ${point.number || ""}`,
        description: point.name,
        point,
      };
    });
  };

  const formFields: FormField[][] = [
    [
      {
        type: FIELD_TYPE.INPUT,
        name: "referenceId",
        label: "Referencja",
        placeholder: "Referencja",
        value: ordersReferenceId,
        onChange: useCallback(
          (_, { value }) => {
            setReferenceId(value.trim());
          },
          [setReferenceId]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateFrom",
        label: "Data od:",
        placeholder: "RRRR-MM-DD",
        id: "initialDate",
        value: ordersDateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateFrom(value);
          },
          [setDateFrom]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateTo",
        label: "Data do:",
        placeholder: "RRRR-MM-DD",
        id: "finalDate",
        value: ordersDateTo,
        minDate: dateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateTo(value);
          },
          [setDateTo]
        ),
      },
      {
        type: FIELD_TYPE.SEARCH,
        name: "deliveryAddress",
        label: "Adres Dostawy",
        placeholder: "Adres Dostawy",
        value: deliveryAddress,
        options: deliveryAddresses,
        searchSelect: true,
        uberSearch: {
          debounce: 700,
          endpoint: "/customer-panel/customers/delivery-points",
          mapper: mapDelivery,
          onResultSelect: useCallback(
            (selected: {
              point: {
                rrdi: string;
              };
            }) => {
              setDeliveryAddress(selected.point.rrdi || "");
              return selected.point.rrdi;
            },
            [setDeliveryAddress]
          ),
        },
        onChange: () => {},
      },
    ],
    [],
  ];

  useEffect(() => {
    setColumnsFromLocalStorage();
  }, [setColumnsFromLocalStorage]);

  useEffect(() => {
    if (search) {
      const initParams = new URLSearchParams(search);

      initParams.forEach((value, name) => {
        if (value && value !== "null") {
          addUpdateFilterLabel(name, value, value);
        }
      });
    }
  }, [search, addUpdateFilterLabel]);

  return {
    formFields,
    handleSubmit,
  };
};
