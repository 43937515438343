import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { handleError } from "~/store/error/actions";
import {
  fetchAnnouncements,
  fetchAnnouncementsSuccess,
  fetchAnnouncementsPartsError,
  fetchAnnouncementList,
  fetchAnnouncementListSuccess,
  fetchAnnouncementDetails,
  fetchAnnouncementDetailsSuccess,
  uploadAnnouncement,
  uploadAnnouncementSuccess,
  removeAnnouncement,
  removeAnnouncementSuccess,
  showAnnouncement,
  hideAnnouncement,
  editAnnouncement,
  editAnnouncementSuccess,
  fetchAnnouncementListError,
} from "./actions";
import {
  Announcement,
  AnnouncementsActionTypes,
  TNewAnnouncementDTO,
} from "./types";

import translations from "~/utils/translations";
import { Paginable } from "../types";
import { callApi, callApiUploadFile } from "~/utils/api";
import { AnnouncementType } from "~/pages/Dashboard/lib/constans";
import { toastSuccess } from "~/utils/toast";

function* handleFetchAnnouncementList(
  action: ReturnType<typeof fetchAnnouncementList>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });
    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }
    const announcementList: Paginable<Announcement> = yield call(
      callApi,
      "get",
      `/customer-panel/advertisements/administration?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );
    yield put(fetchAnnouncementListSuccess(announcementList));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("Wystąpił błąd podczas pobierania ogłoszeń")
      )
    );
    yield put(fetchAnnouncementListError());
  }
}

function* handleFetchAnnouncements(
  action: ReturnType<typeof fetchAnnouncements>
) {
  try {
    const textAnnouncements: Announcement[] = yield call(
      callApi,
      "get",
      "/customer-panel/advertisements/type/TEXT"
    );
    const imageAnnouncements: Announcement[] = yield call(
      callApi,
      "get",
      "/customer-panel/advertisements/type/IMAGE"
    );

    const announcementsSuccess: Announcement[] = yield put(
      fetchAnnouncementsSuccess([...textAnnouncements, ...imageAnnouncements])
    );
    return announcementsSuccess;
  } catch (error) {
    yield put(fetchAnnouncementsPartsError(error));
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("Wystąpił błąd podczas pobierania ogłoszeń")
      )
    );
  }
}

function* handleFetchAnnouncementDetails(
  action: ReturnType<typeof fetchAnnouncementDetails>
) {
  try {
    const announcementDetails: Announcement = yield call(
      callApi,
      "get",
      `/customer-panel/advertisements/${action.payload}`
    );
    yield put(fetchAnnouncementDetailsSuccess(announcementDetails));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        true,
        translations.format("Wystąpił błąd podczas pobierania ogłoszenia")
      )
    );
  }
}

function* handleUploadAnnouncement(
  action: ReturnType<typeof uploadAnnouncement>
) {
  try {
    const { type, file } = action.payload;
    if (type === AnnouncementType.IMAGE) {
      const savedImg: { filename: string } = yield call(
        //@ts-ignore:
        callApiUploadFile,
        "/customer-panel/images",
        file,
        "image"
      );
      if (savedImg && "filename" in savedImg) {
        const newAnnouncement: Announcement = yield call(
          callApi,
          "POST",
          "/customer-panel/advertisements",
          { ...action.payload, image: savedImg.filename }
        );
        yield put(uploadAnnouncementSuccess(newAnnouncement.id));
        toastSuccess("Ukończono", "Pomyślnie dodano nowe ogłoszenie");
      }
    } else {
      const newAnnouncement: Announcement = yield call(
        callApi,
        "POST",
        "/customer-panel/advertisements",
        action.payload
      );
      yield put(uploadAnnouncementSuccess(newAnnouncement.id));
      toastSuccess("Ukończono", "Pomyślnie dodano nowe ogłoszenie");
    }
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("Wystąpił błąd podczas dodawania ogłoszenia")
      )
    );
  }
}

function* handleRemoveAnnouncement(
  action: ReturnType<typeof removeAnnouncement>
) {
  try {
    yield call(
      callApi,
      "DELETE",
      `/customer-panel/advertisements/${action.payload}`
    );
    yield put(removeAnnouncementSuccess());
    toastSuccess("Usunięto", "Pomyślnie usunięto ogłoszenie");
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("Wystąpił błąd podczas usuwania ogłoszenia")
      )
    );
  }
}

function* handleShowAnnouncement(action: ReturnType<typeof showAnnouncement>) {
  try {
    yield call(
      callApi,
      "PUT",
      `/customer-panel/advertisements/${action.payload}/show`
    );
    yield put(removeAnnouncementSuccess());
    toastSuccess("Sukces", "Ogłoszenie zostało oznaczone jako widoczne");
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format(
          "Wystąpił błąd podczas zmiany widoczności ogłoszenia"
        )
      )
    );
  }
}

function* handleHideAnnouncement(action: ReturnType<typeof hideAnnouncement>) {
  try {
    yield call(
      callApi,
      "PUT",
      `/customer-panel/advertisements/${action.payload}/hide `
    );
    yield put(removeAnnouncementSuccess());
    toastSuccess("Sukces", "Ogłoszenie zostało oznaczone jako niewidoczne");
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format(
          "Wystąpił błąd podczas zmiany widoczności ogłoszenia"
        )
      )
    );
  }
}

function* handleEditAnnouncement(action: ReturnType<typeof editAnnouncement>) {
  try {
    const {
      hidden,
      id,
      type,
      content,
      endDate,
      intendedFor,
      priority,
      startDate,
      title,
      file,
      imageOriginal,
    } = action.payload as Announcement & TNewAnnouncementDTO;
    let DTO = {
      hidden,
      content:
        type === AnnouncementType.TEXT
          ? content
            ? JSON.stringify(content)
            : null
          : null,
      endDate,
      image:
        type === AnnouncementType.IMAGE
          ? imageOriginal
            ? imageOriginal
            : null
          : null,
      intendedFor,
      priority,
      startDate,
      title,
      type,
    };
    if (type === AnnouncementType.TEXT || !file) {
      yield call(callApi, "PUT", `/customer-panel/advertisements/${id}`, DTO);
      yield put(editAnnouncementSuccess());
    } else {
      const savedImg: { filename: string } = yield call(
        //@ts-ignore:
        callApiUploadFile,
        "/customer-panel/images",
        file,
        "image"
      );
      if (savedImg && "filename" in savedImg) {
        yield call(callApi, "PUT", `/customer-panel/advertisements/${id}`, {
          ...DTO,
          image: savedImg.filename,
        });
        yield put(editAnnouncementSuccess());
      }
    }
    toastSuccess("Edytowano", "Pomyślnie edytowano ogłoszenie");
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        translations.format("Wystąpił błąd podczas edycji ogłoszenia")
      )
    );
  }
}

function* watchFetchAnnouncementList() {
  yield takeEvery(
    AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST,
    handleFetchAnnouncementList
  );
}

function* watchFetchAnnouncements() {
  yield takeEvery(
    AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS,
    handleFetchAnnouncements
  );
}

function* watchFetchAnnouncementDetails() {
  yield takeEvery(
    AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_DETAILS,
    handleFetchAnnouncementDetails
  );
}

function* watchUploadAnnouncement() {
  yield takeEvery(
    AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT,
    handleUploadAnnouncement
  );
}

function* watchShowAnnouncement() {
  yield takeEvery(
    AnnouncementsActionTypes.SHOW_ANNOUNCEMENT,
    handleShowAnnouncement
  );
}

function* watchHideAnnouncement() {
  yield takeEvery(
    AnnouncementsActionTypes.HIDE_ANNOUNCEMENT,
    handleHideAnnouncement
  );
}

function* watchRemoveteAnnouncement() {
  yield takeEvery(
    AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT,
    handleRemoveAnnouncement
  );
}

function* watchEditAnnouncement() {
  yield takeEvery(
    AnnouncementsActionTypes.EDIT_ANNOUNCEMENT,
    handleEditAnnouncement
  );
}

function* announcementsSaga() {
  yield all([
    fork(watchFetchAnnouncements),
    fork(watchFetchAnnouncementList),
    fork(watchFetchAnnouncementDetails),
    fork(watchUploadAnnouncement),
    fork(watchRemoveteAnnouncement),
    fork(watchShowAnnouncement),
    fork(watchHideAnnouncement),
    fork(watchEditAnnouncement),
  ]);
}

export default announcementsSaga;
