import React from 'react';
import { Input, Label } from 'semantic-ui-react';
import translations from '~/utils/translations';

type Brand = {
  brand: string;
  code: string;
};

interface Props {
  brands: Brand[] | undefined;
}

export const BrandCode: React.FC<Props> = ({ brands }) => {
  const renderLabel = (brand: Brand): JSX.Element => (
    <div
      key={brand.brand}
      style={{
        marginBottom: 10,
        textAlign: 'left',
        width: '80%',
        maxHeight: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Label
        content={translations.format(`app.customer-brand.${brand.brand}`)}
        style={{ width: '30%', height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      />
      <Input disabled style={{ width: '70%', opacity: 1 }} key={brand.brand} value={brand.code} />
    </div>
  );

  return (
    <div style={{ maxHeight: 370, overflowY: 'auto' }}>
      {brands && brands.map((item) => renderLabel(item))}
      {(!brands || !brands.length) && <span>Dla wybranego kontrahenta nie została przypisana żadna marka.</span>}
    </div>
  );
};
