import React from 'react';
import { DeliveryAddressProps } from '~/pages/Orders/Details/lib/constants';

const DeliveryAddress = ({ customer }: DeliveryAddressProps) => (
  <>
    {customer.street} {customer.streetNumber}
    <br />
    {customer.postcode} {customer.city}
  </>
);

export default DeliveryAddress;
