import { Button, Icon, Popup } from 'semantic-ui-react';
import { ProductCodes } from '~/store/backorder/types';

import React from 'react';

type TCodableProductLine = {
  codes?: ProductCodes | null;
};

type TCodingCellProps<T> = {
  line: T;
  loading: boolean;
  editMode: boolean;
  addCodingHandler: (line: T) => void;
  disablePopup: boolean;
};

const CodingCell = <T extends TCodableProductLine>({
  line,
  loading,
  editMode,
  addCodingHandler,
  disablePopup = false,
}: TCodingCellProps<T>) => {
  const labels: { [key: string]: string } = {
    vin: 'VIN',
    cleCode: 'CLE',
    transmitterCode: 'EMETTEUR',
    nreCode: 'NRE',
    wheelCode: 'ROUE',
    tiresCode: 'PNEUMATIQUE',
  };

  const codes = line.codes || {};

  const buttonDescription = (): JSX.Element => {
    const description = Object.entries(codes)
      .filter(([code, entry]) => entry)
      .map(([code, entry]) => (
        <small key={code} style={{ display: 'block' }}>
          {labels[code]}: {entry && entry.value}
        </small>
      ));
    return (
      <div style={{ display: 'inline-block', textAlign: 'left', verticalAlign: 'middle', lineHeight: '1em' }}>
        {description}
      </div>
    );
  };

  const buttonWrapper = (children: JSX.Element): JSX.Element =>
    editMode ? (
      <Button
        style={{ whiteSpace: 'nowrap' }}
        basic
        onClick={() => {
          addCodingHandler(line);
        }}
        disabled={loading || !editMode}
      >
        {children}
      </Button>
    ) : (
      <div style={{ whiteSpace: 'nowrap' }}>{children}</div>
    );

  const codingButton: JSX.Element = !disablePopup ? (
    <Popup
      content={'Wymagana aktualizacja parametrów kodowania.' + (!editMode ? ' Włącz tryb edycji, aby zmienić.' : '')}
      trigger={buttonWrapper(
        <>
          <Icon name="warning sign" color="orange" />
          {buttonDescription()}
        </>
      )}
    />
  ) : (
    buttonDescription()
  );

  const codingButtonOk: JSX.Element = !disablePopup ? (
    <Popup
      content={editMode ? 'Zmiana parametrów kodowania.' : 'Włącz tryb edycji, aby zmienić.'}
      trigger={buttonWrapper(
        <>
          {editMode && <Icon name="edit" />}
          {buttonDescription()}
        </>
      )}
    />
  ) : (
    buttonDescription()
  );

  const parseCodingButton = (): JSX.Element => {
    if (!line.codes) {
      return <>-</>;
    }
    const requiredEmpty = (Object.keys(codes) as (keyof ProductCodes)[]).find((code) => {
      const coding = codes[code] || { required: false };
      return coding.required && !coding.value;
    });
    if (requiredEmpty) {
      return codingButton;
    }
    return codingButtonOk;
  };

  return parseCodingButton();
};

export default CodingCell;
