import { DictionaryItem } from '~/store/dictionaries/types';

export const useSelectOptions = () => {
  const method: DictionaryItem[] = [
    { key: 'CROSS_DOCK', value: 'CROSS_DOCK', text: 'Cross-doc do klienta' },
    { key: 'REPLENISHMENT', value: 'REPLENISHMENT', text: 'Uzupełnienie stocku' },
  ];

  const type: DictionaryItem[] = [
    { key: 'STOCK', value: 'STOCK', text: 'Stock' },
    { key: 'URGENT', value: 'URGENT', text: 'Pilne' },
  ];

  return {
    method,
    type,
  };
};
