import { Table } from 'semantic-ui-react';
import { parsePrice } from '~/utils/parsePrice';
import React from 'react';
import { TableCellType } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';

export const PriceTableCell = ({ column, line }: TableCellType) => (
  <Table.Cell key={column.name} style={{ textAlign: 'right' }}>
    {parsePrice(line[column.name])}
  </Table.Cell>
);
