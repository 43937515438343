export const CONTAIN_N_DIGITS = (text: string, numOfDigits: number): boolean => {
    const reg = new RegExp(`(.*\\d.*){${numOfDigits}}`)
    return reg.test(text)
}

export const CONTAIN_N_UPPERCASE_LETTERS = (text: string, numOfLetters: number): boolean => {
    const reg = new RegExp(`(.*[A-Z].*){${numOfLetters}}`)
    return reg.test(text)
}
export const CONTAIN_N_LOWERCASE_LETTERS = (text: string, numOfLetters: number): boolean => {
    const reg = new RegExp(`(.*[a-z].*){${numOfLetters}}`)
    return reg.test(text)
}
export const CONTAIN_N_SPECIAL_CHARACTERS = (text: string, numOfCharacters: number): boolean => {
    const reg = new RegExp(`(.*\\W.*){${numOfCharacters}}`)
    return reg.test(text)
}
export const NOT_CONTAIN_USERNAME = (text: string, username: string): boolean => {
    const reg = new RegExp(`(.*${username}.*)`)
    return !reg.test(text)
}