import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageHeader from "~/components/PageHeader/PageHeader";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { useLibraryButtons } from "./hooks/useLibraryButtons";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useLibraryColumns } from "./hooks/useLibraryColumns";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import {
  addLibraryItem,
  deleteLibraryItem,
  fetchLibrary,
  fetchLibraryItem,
} from "~/store/library/actions";
import { AddLibraryItem } from "./lib/AddLibraryItem";
import { LibraryItem } from "~/store/library/types";
import { Dropdown, Table } from "semantic-ui-react";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import IconHover from "~/components/IconHover/IconHover";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import translations from "~/utils/translations";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

type TStateProps = {
  library: LibraryItem[];
  meta: PaginationMeta;
  loading: boolean;
  totalRecords: TotalRecords;
  deleteItemLoading: boolean;
  additemLoading: boolean;
};

type TReduxActions = {
  fetchLibrary: typeof fetchLibrary;
  deleteLibraryItem: typeof deleteLibraryItem;
  addLibraryItem: typeof addLibraryItem;
  fetchLibraryItem: typeof fetchLibraryItem;
};

type TProps = TStateProps & TReduxActions;

const Library: React.FC<TProps> = ({
  meta,
  loading,
  totalRecords,
  library,
  deleteItemLoading,
  additemLoading,
  fetchLibrary,
  deleteLibraryItem,
  addLibraryItem,
  fetchLibraryItem,
}) => {
  const [tableKey] = useState("library");
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [columns, setColumns] = useState<TColumn[]>(
    useLibraryColumns({ tableKey })
  );
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("createdAt");
  const [sortDirection] = useState<any>("DESC");
  const [params, setParams] = useState<Object>({});
  const [openConfirmRemoveModal, setOpenConfirmRemoveModal] =
    useState<LibraryItem>();
  const [removeActionPending, setRemoveActionPending] =
    useState<boolean>(false);
  const [addActionPending, setAddActionPending] = useState<boolean>(false);
  const { isSuperAdmin, hasRole } = useClientPanelRoles();

  const getLibraryList = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchLibrary(Object.assign(paramsObj, params));
    },
    [
      fetchLibrary,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  const refresh = useCallback(() => {
    fetchLibrary({ ...params, page: 1 });
  }, [params, fetchLibrary]);

  useEffect(() => {
    if (!deleteItemLoading) {
      setOpenConfirmRemoveModal(undefined);
    }
  }, [deleteItemLoading]);

  useEffect(() => {
    if (!openConfirmRemoveModal && removeActionPending) {
      setRemoveActionPending(false);
      refresh();
    }
  }, [openConfirmRemoveModal, refresh, removeActionPending]);

  const handleCloseAddModal = useCallback(() => {
    setOpenAddModal(false);
  }, []);

  useEffect(() => {
    if (!additemLoading && addActionPending) {
      setAddActionPending(false);
      refresh();
    }
  }, [additemLoading, addActionPending, refresh]);

  const buttons = useLibraryButtons({
    isSuperAdmin,
    setOpenAddModal,
    hasEditRole: hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_LIBRARY_EDIT),
  });

  const handleDownload = useCallback(
    (announcement: LibraryItem) => {
      fetchLibraryItem(announcement.id, announcement.name);
    },
    [fetchLibraryItem]
  );

  // useEffect(() => {
  //   setColumns((items) => completeColumnDictionaries(items));
  // }, [completeColumnDictionaries]);

  const dropdown = useCallback(
    (announcement: LibraryItem) => {
      return isSuperAdmin &&
        hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_LIBRARY_EDIT) ? (
        <TableMoreDropdown>
          <Dropdown.Item
            text="Pobierz"
            icon="cloud download"
            onClick={() => handleDownload(announcement)}
          />
          <Dropdown.Item
            style={{ color: "red" }}
            text="Usuń"
            icon="trash"
            onClick={() => setOpenConfirmRemoveModal(announcement)}
          />
        </TableMoreDropdown>
      ) : (
        <div role="button" onClick={() => handleDownload(announcement)}>
          <IconHover name="cloud download" title="Pobierz" />
        </div>
      );
    },
    [isSuperAdmin, handleDownload, hasRole]
  );
  return (
    <>
      <PageHeader
        icon="book"
        title="Biblioteka"
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        loading={false}
        refreshAction={refresh}
      />
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getLibraryList}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        setColumns={setColumns}
      >
        {library.map((document: LibraryItem, index: number) => (
          <Table.Row key={document.id}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              {dropdown(document)}
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
      <AddLibraryItem
        handleCloseAddModal={handleCloseAddModal}
        openAddModal={openAddModal}
        addLibraryItem={addLibraryItem}
        setAddActionPending={setAddActionPending}
      />
      <ModalConfirm
        modalOpen={!!openConfirmRemoveModal}
        headerIcon="trash alternate"
        headerText="app.library-item-remove"
        contentText={
          <>
            {translations.format("app.library-item-remove.question") + " "}
            <strong>{openConfirmRemoveModal?.name}</strong> ?
          </>
        }
        onCancelClick={() => setOpenConfirmRemoveModal(undefined)}
        onConfirmClick={() => {
          deleteLibraryItem(openConfirmRemoveModal!.id);
          setRemoveActionPending(true);
        }}
        loading={deleteItemLoading}
      />
    </>
  );
};

const mapStateToProps = ({ library }: ApplicationState): TStateProps => ({
  library: library.library,
  loading: library.loading,
  meta: library.meta,
  totalRecords: library.totalRecords,
  deleteItemLoading: library.deleteItemLoading,
  additemLoading: library.addItemLoading,
});

const mapDispatchToProps: TReduxActions = {
  fetchLibrary,
  deleteLibraryItem,
  addLibraryItem,
  fetchLibraryItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Library);
