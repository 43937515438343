import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
} from "react";
import { Checkbox, Form, Segment } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import { FIELD_TYPE, FormField } from "../types/advancedSearchType";
import { DictionaryItem } from "~/store/dictionaries/types";
import { TColumn } from "~/components/Table/lib/types";
import TableSortSession from "~/utils/tableSortSession";
import TableFilterSession, {
  TTableFilterSession,
} from "~/utils/tableFilterSession";
import { storageOrdersColumns } from "~/pages/ManualOrders/constans";
import { localStorageService } from "~/services/LocalStorage";
import UberSearch from "~/components/UberSearch/UberSearch";

export const useAdvancedSearch = (
  columns?: TColumn[],
  setColumns?: Dispatch<SetStateAction<TColumn[]>>,
  sortColumn?: string,
  sortDirection?: string,
  tableKey?: string,
  filterLabelsRef?: MutableRefObject<TTableFilterSession[]>,
  localStorageColumnsKey?: string
) => {
  const renderFormFields = (formFields: FormField[]): JSX.Element => {
    const formFieldsToDisplay = formFields?.map((field) => {
      switch (field.type) {
        case FIELD_TYPE.SELECT:
          return (
            <Form.Select
              label={field.label}
              placeholder={field.placeholder}
              name={field.name}
              value={field.value as (string | number | boolean)[]}
              options={field.options as DictionaryItem[]}
              onChange={field.onChange}
              key={field.name}
              multiple={field.multiple}
              className="advanced-search-select"
              search={field.searchSelect}
            />
          );
        case FIELD_TYPE.DATE_PICKER:
          return (
            <SemanticDatepicker
              label={field.label}
              id={field.id}
              name={field.name}
              value={field.value as Date | Date[]}
              placeholder={field.placeholder}
              locale="pl-PL"
              onChange={field.onChange}
              autoComplete="off"
              minDate={field.minDate}
              key={field.name}
            />
          );
        case FIELD_TYPE.CHECKBOX:
          return (
            <Segment
              sizes="mini"
              className="toggle-checkbox toggle-top"
              key={field.name}
            >
              <Checkbox
                label={field.label}
                toggle
                checked={field.value as boolean}
                onChange={field.onChange}
              />
            </Segment>
          );
        case FIELD_TYPE.SEARCH:
          if (!field.uberSearch) return <p>wrong search props</p>;
          return (
            <Form.Field key={field.label}>
              <label>{field.label}:</label>
              <UberSearch
                placeholder="Wyszukaj..."
                fluid
                endpoint={field.uberSearch!.endpoint}
                mapper={(response) => field.uberSearch!.mapper(response)}
                onResultSelect={(selected: any) =>
                  field.uberSearch!.onResultSelect(selected)
                }
                debounce={field!.uberSearch.debounce}
                resultRenderer={(props) => {
                  return (
                    <>
                      <div>
                        <strong>{props.title}</strong>
                      </div>
                      <div>{props.description}</div>
                      <div>{props.point.rrdi}</div>
                    </>
                  );
                }}
              />
            </Form.Field>
          );
        default:
          return (
            <Form.Input
              label={field.label}
              placeholder={field.placeholder}
              name={field.name}
              value={field.value as String}
              onChange={field.onChange}
              key={field.name}
            />
          );
      }
    });

    return <>{formFieldsToDisplay}</>;
  };

  const toggleProductColumn = useCallback(
    (columnsName: string[], columnsChecked: boolean[]) => {
      const newColumns = columns?.map((column) => {
        const findIndex = columnsName.findIndex((name) => column.name === name);

        return findIndex >= 0
          ? { ...column, projection: columnsChecked[findIndex] }
          : { ...column };
      });

      setColumns && setColumns(newColumns || []);
    },
    [columns, setColumns]
  );

  const turnOffUnwidProcess = useCallback(() => {
    TableSortSession.setSoring({
      key: tableKey || "",
      value: sortColumn || "",
      direction: sortDirection || "",
    });
  }, [tableKey, sortColumn, sortDirection]);

  const addUpdateFilterLabel = useCallback(
    (name: string, value: string, text?: string, key?: string) => {
      const found = filterLabelsRef?.current.find(
        (label) => label.name === name
      );
      const hiddenKey = columns?.find((x) => x.name === name);

      if (filterLabelsRef) {
        const newFilterLabels = found
          ? filterLabelsRef.current.map((label) =>
              label.name === name ? { name, value, text, key } : { ...label }
            )
          : [
              ...filterLabelsRef.current,
              {
                name,
                value,
                text,
                key,
                visibleKey: !!(hiddenKey && hiddenKey.projection),
              },
            ];

        filterLabelsRef.current = newFilterLabels as TTableFilterSession[];
      }

      if (name && value) {
        TableFilterSession.setFilters({
          name,
          value,
          text,
          key,
          table: tableKey || "",
        });
      }
    },
    [filterLabelsRef, columns, tableKey]
  );

  const setColumnsFromLocalStorage = useCallback(() => {
    const columnsLS: storageOrdersColumns[] = localStorageService.get(
      localStorageColumnsKey || ""
    );
    let shouldUpdate = false;

    if (columnsLS && columns) {
      const newColumns = columns.map((column) => {
        const findColumn = columnsLS.find(
          (columnLS) => column.name === columnLS.name
        );

        if (findColumn && findColumn.shouldDisplay !== column.projection) {
          shouldUpdate = true;

          return { ...column, projection: findColumn.shouldDisplay };
        }

        return column;
      });

      if (shouldUpdate) {
        setColumns && setColumns(newColumns);
      }
    }
  }, [columns, localStorageColumnsKey, setColumns]);

  const getTableFilterSession = useCallback((name: string) => {
    return TableFilterSession.getFilters(name);
  }, []);

  return {
    renderFormFields,
    toggleProductColumn,
    turnOffUnwidProcess,
    addUpdateFilterLabel,
    setColumnsFromLocalStorage,
    getTableFilterSession,
  };
};
