import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { useCells } from "~/services/useCells";
import { DictionariesState } from "~/store/dictionaries/types";

type TProps = {
  tableKey: string;
};

export const useWarehousesColumns = ({ tableKey }: TProps) => {
  const { getLinkCell, getTextCell } = useCellsRenderer();
  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "id",
        label: "ID",
        projection: true,
        disableSort: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `distrigo-warehouses/${document.id}`,
            document.id
          ),
      },
      {
        name: "name",
        label: "Nazwa",
        projection: true,
        disableSort: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "city",
        label: "Miasto",
        projection: true,
        disableSort: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "street",
        label: "Ulica",
        projection: true,
        disableSort: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "zipCode",
        label: "Kod pocztowy",
        projection: true,
        disableSort: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getLinkCell, getTextCell]
  );

  const { completeColumnDictionaries } = useCells({
    tableKey,
    dictionaries: {} as DictionariesState,
  });

  return { columnsConfig: completeColumnDictionaries(columnsConfig) };
};
