import { SelectionPropagation, TNode, TNodeProps } from "~/components/Tree/lib/types";
import React, { useEffect, useState } from "react";
import { Button, Input, List } from "semantic-ui-react";
import TreeNode from "~/components/Tree/TreeNode";

export const useTreeNode = ({
    node,
    selection,
    onSelectionChange = () => { },
    loadProvider,
    parents = [],
    parentChecked = false,
    editable = true,
    sortFn,
    advancedSearchResponse
}: TNodeProps) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [children, setChildren] = useState<TNode[]>(node.children || [])
    const [search, setSearch] = useState<string>("")
    const [page, setPage] = useState<number>(0)

    const checked = selection.isChecked(node, parents) || parentChecked
    const indeterminate = selection.isIndeterminate(node, parents)

    const handleSelectionChange = (): void => {
        if (checked) {
            onSelectionChange(node)
        } else {
            selection.select(node, parents)
            onSelectionChange()
        }
    }

    const handleChildSelectionChange: SelectionPropagation = (deselected) => {
        if (deselected) {
            if (checked) {
                const otherChildren = (children || []).filter(child => child.id !== deselected.id)
                selection.selectMany(otherChildren.map(child => ({ node: child, parents: [...parents, node] })))
                onSelectionChange(node)
            } else {
                selection.deselect(deselected, [...parents, node])
                onSelectionChange()
            }
        } else {
            onSelectionChange()
        }
    }

    const handleExpandCollapse = (): void => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        if (loading) {
            loadProvider({...node, parents}).then(nodes => {
                setChildren(nodes)
                setLoading(false)
                setLoaded(true)
            })
        }
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        if (expanded && !loaded) {
            setLoading(true)
        }
    }, [expanded, loaded])

    useEffect(() => {
        if (advancedSearchResponse && advancedSearchResponse.length === 1) {
            if (
                advancedSearchResponse[0]?.value === node.id
            ) {
                !expanded && setExpanded(true)
            }
            if (advancedSearchResponse[0]?.segments && advancedSearchResponse[0]?.segments.length === 1) {
                if (
                    advancedSearchResponse[0]?.segments[0].value === node.id
                ) {
                    !expanded && setExpanded(true)
                }

                if (advancedSearchResponse[0]?.segments[0]?.slots && advancedSearchResponse[0]?.segments[0]?.slots.length === 1) {
                    if (
                        advancedSearchResponse[0]?.segments[0].slots[0].value === node.id
                    ) {
                        !expanded && setExpanded(true)
                    }

                    if (advancedSearchResponse[0]?.segments[0]?.slots[0]?.families && advancedSearchResponse[0]?.segments[0]?.slots[0]?.families.length === 1) {

                        if (
                            advancedSearchResponse[0]?.segments[0].slots[0].families[0].value === node.id
                        ) {
                            !expanded && setExpanded(true)
                        }

                        if (advancedSearchResponse[0]?.segments[0]?.slots[0]?.families[0]?.products && advancedSearchResponse[0]?.segments[0]?.slots[0]?.families[0]?.products.length === 1) {
                            if (
                                advancedSearchResponse[0]?.segments[0].slots[0].families[0].products[0].value === node.id
                            ) {
                                !expanded && setExpanded(true)
                            }

                        }
                    }
                }

            }
        }
        // eslint-disable-next-line
    }, [advancedSearchResponse])

    const renderPaginatedList = (children: TNode[], advancedSearchResponse: any): JSX.Element | JSX.Element[] => {
        const pageSize = 10
        const total = children.length
        const startIndex = pageSize * page
        const endIndex = startIndex + pageSize
        const hasMore = endIndex < total
        let hasPagination = false

        let filteredChildren = search ? children.filter((child) => {
            return child.id.toUpperCase().indexOf(search.toUpperCase()) !== -1 || child.label.toUpperCase().indexOf(search.toUpperCase()) !== -1
        }) : children


        if (advancedSearchResponse && advancedSearchResponse.length === 1) {
            if (
                advancedSearchResponse[0]?.value === node.id
            ) {
                hasPagination = false
            }
            if (advancedSearchResponse[0]?.segments && advancedSearchResponse[0]?.segments.length === 1) {
                if (
                    advancedSearchResponse[0]?.segments[0].value === node.id
                ) {
                    hasPagination = false
                }

                if (advancedSearchResponse[0]?.segments[0]?.slots && advancedSearchResponse[0]?.segments[0]?.slots.length === 1) {
                    if (
                        advancedSearchResponse[0]?.segments[0].slots[0].value === node.id
                    ) {
                        hasPagination = false
                    }

                    if (advancedSearchResponse[0]?.segments[0]?.slots[0]?.families && advancedSearchResponse[0]?.segments[0]?.slots[0]?.families.length === 1) {

                        if (
                            advancedSearchResponse[0]?.segments[0].slots[0].families[0].value === node.id
                        ) {
                            hasPagination = false
                        }

                        if (advancedSearchResponse[0]?.segments[0]?.slots[0]?.families[0]?.products && advancedSearchResponse[0]?.segments[0]?.slots[0]?.families[0]?.products.length === 1) {
                            if (
                                advancedSearchResponse[0]?.segments[0].slots[0].families[0].products[0].value === node.id
                            ) {
                                hasPagination = false
                            }

                        }
                    }
                }

            }
        } else if (filteredChildren.length > pageSize + 5) {
            hasPagination = true
            filteredChildren = filteredChildren.slice(startIndex, endIndex)
        }

        if (sortFn) {
            filteredChildren = filteredChildren.sort(sortFn)
        }

        const renderedItems = filteredChildren.map(child => (
            <TreeNode
                key={child.id}
                parents={[...parents, node]}
                parentChecked={checked}
                node={child}
                selection={selection}
                loadProvider={loadProvider}
                onSelectionChange={handleChildSelectionChange}
                editable={editable}
                sortFn={sortFn}
                advancedSearchResponse={advancedSearchResponse}
            />
        ))

        if (total > renderedItems.length) {
            return <>


                {<List.Item style={{ paddingLeft: 24 }}>
                    <Input size="mini" icon="search" placeholder="Wyszukaj..." onChange={(ev, prop) => {
                        setSearch(prop.value);
                        setPage(0)
                    }} />
                </List.Item>}
                {filteredChildren && filteredChildren.length ? renderedItems : <div style={{marginLeft: 25, marginBottom: 5, marginTop: 5, fontStyle: "italic"}}>Brak wyników</div>}
                {hasPagination && <List.Item key={node.id} style={{ paddingLeft: 24 }}>
                    <Button disabled={page === 0} icon='left arrow' labelPosition='left' basic compact size="mini"
                        content="Wstecz" onClick={() => {
                            setPage(page - 1)
                        }} />
                    <Button disabled={!hasMore} icon='right arrow' labelPosition='right' basic compact size="mini"
                        content="Dalej" onClick={() => {
                            setPage(page + 1)
                        }} />
                </List.Item>}
            </>
        }
        return renderedItems
    }

    return {
        indeterminate,
        checked,
        children,
        handleSelectionChange,
        handleExpandCollapse,
        renderPaginatedList,
        loading,
        loaded,
        expanded
    }
}