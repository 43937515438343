import { action } from 'typesafe-actions'
import {
    CustomerPaymentDistrigoCredit,
    CustomerPaymentDistrigoLimitDTO,
    CustomerDistrigoCredit,
    DistrigoCreditActionTypes
} from './types'

export const fetchDistrigoCredit = (customerId: string) => action(DistrigoCreditActionTypes.FETCH_DISTRIGO_CREDIT, { customerId })
export const fetchDistrigoCreditSuccess = (distrigoCredit: CustomerDistrigoCredit) => action(DistrigoCreditActionTypes.FETCH_DISTRIGO_CREDIT_SUCCESS, { distrigoCredit })

export const createDistrigoCredit = (( customerId: string, customerPaymentFactor: CustomerPaymentDistrigoLimitDTO) => action(DistrigoCreditActionTypes.CREATE_DISTRIGO_CREDIT, { customerPaymentFactor, customerId }))
export const createDistrigoCreditSuccess = (distrigoCredit: CustomerPaymentDistrigoCredit) => action(DistrigoCreditActionTypes.CREATE_DISTRIGO_CREDIT_SUCCESS, { distrigoCredit })








