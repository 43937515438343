import { ApiError } from "../error/types";

export enum SpeederActionTypes {
    SPEEDER_GENERATE_RAPORT_SUCCESS = '@@speeder/SPEEDER_GENERATE_RAPORT_SUCCESS',
    SPEEDER_GENERATE_RAPORT_FAILED = '@@speeder/SPEEDER_GENERATE_RAPORT_FAILED',
    SPEEDER_GENERATE_LIGFACTQ_RAPORT = '@@speeder/SPEEDER_GENERATE_LIGFACTQ_RAPORT',
    SPEEDER_GENERATE_VENTESQ_RAPORT = '@@speeder/SPEEDER_GENERATE_VENTESQ_RAPORT',
    SPEEDER_GENERATE_STKVTEI_RAPORT = '@@speeder/SPEEDER_GENERATE_STKVTEI_RAPORT',
    SPEEDER_GENERATE_STKVTH_RAPORT = '@@speeder/SPEEDER_GENERATE_STKVTH_RAPORT',
    SAVE_DATA_FROM = '@@speeder/SAVE_DATA_FROM',
    SAVE_DATA_TO = '@@speeder/SAVE_DATA_TO',
    SAVE_EXACT_DATE_FROM = '@@speeder/SAVE_EXACT_DATE_FROM',
    SAVE_EXACT_DATE_TO = '@@speeder/SAVE_EXACT_DATE_TO'
}

export interface SpeederState {
    readonly dateFrom: Date | null,
    readonly dateTo: Date | null,
    readonly error: ApiError | null,
    readonly exactDateFrom: Date | null,
    readonly exactDateTo: Date | null,
    readonly loading: boolean,
    readonly result: object
}