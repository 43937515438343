import { Divider, Form, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import React from "react";
import { TCodableProductLine } from "~/components/Modals/hooks/useCodesModal";

export type Props = {
  selectedLine: TCodableProductLine;
  elements: JSX.Element[];
};

export const ModalContent: React.FC<Props> = ({ selectedLine, elements }) => {
  return (
    <Modal.Content>
      <Message style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Icon
            name="info circle"
            size="big"
            style={{ minWidth: 15, position: "relative", top: 0 }}
          />
        </div>
        <Message.Content>
          Referencja wymaga podania specyfikacji kodowania.
        </Message.Content>
      </Message>
      <Form>
        <SmartLabel
          label="Referencja"
          value={selectedLine.referenceId}
          align="left"
        />
        <SmartLabel
          label="Nazwa"
          value={selectedLine.description}
          align="left"
        />
        <Divider />
        <Grid>{elements}</Grid>
      </Form>
    </Modal.Content>
  );
};
