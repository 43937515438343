import { PaginationMeta, TotalRecords, Paginable } from '~/store/types'

export type TPart = {
    quantity: any
    referenceId: string
    vin: string
    selected?: boolean
    quantityOrgin?: number
}

export type TPartDestroyed = {
    quantity: number
    referenceId: string
    status: string
    lineNumber: number
}

export type TAffectedParts = {
    partsExpected: TPart[]
    partsReceived: TPart[]
    missingParts: TPart[]
}

export type TAnomalyType =
    'GDN_SUPPLIER_CLAIM' |
    'GDN_CLIENT_ORDER' |
    'GDN_CLIENT_CLAIM' |
    'GDN_INTERNAL' |
    'GRN_BACKORDER' |
    'GRN_CLIENT_CLAIM' |
    'GRN_CLIENT_PROVISION' |
    'GRN_INTERNAL' |
    'CROSS_DOCK_PCD' |
    'CROSS_DOCK_OV' |
    'STOCK_AVAILABLE' |
    'STOCK_QUALITY_CONTROL' |
    'STOCK_INTERNAL_CONSUMPTION' |
    'STOCK_BLOCKED' |
    'REQUIRES_CONFIRMATION' |
    'ICN' |
    'STOCKTAKING_GRN' |
    'STOCKTAKING_ICN'

export interface Anomaly {
    anomalyID: string
    anomalyNo: string
    associatedDocumentID: string
    associatedDocumentNo: string
    anomalyType: TAnomalyType
    anomalyStatus: string
    affectedParts: TAffectedParts
    date: string
    destroyedParts: any[]
    orderNo?: string
    orderID?: string
}

export type ApiResponse = Record<string, any>

export enum AnomaliesActionTypes {
    FETCH_REQUEST = '@@anomalies/FETCH_REQUEST',
    FETCH_SUCCESS = '@@anomalies/FETCH_SUCCESS',
    FETCH_ERROR = '@@anomalies/FETCH_ERROR',
    SELECT_ANOMALY = '@@anomalies/SELECT_ANOMALY',
    SELECTED = '@@anomalies/SELECTED',
    CLOSE_ANOMALY = '@@anomalies/CLOSE_ANOMALY',
    RESOLVE_ANOMALY = '@@anomalies/RESOLVE_ANOMALY',
    CLOSE_RESOLVE_SUCCESS = '@@anomalies/CLOSE_RESOLVE_SUCCESS',
    RESOLVE_DESTROYED_PARTS = '@@anomalies/RESOLVE_DESTROYED_PARTS',
    RESOLVE_DESTROYED_PARTS_SUCCESS = '@@anomalies/RESOLVE_DESTROYED_PARTS_SUCCESS',
    REJECT_RW = '@@anomalies/REJECT_RW',
    REJECT_RW_SUCCESS = '@@anomalies/REJECT_RW_SUCCESS'
}

export interface AnomaliesState {
    readonly list: Anomaly[]
    readonly meta: PaginationMeta
    readonly loadingDestoryedParts: boolean
    readonly loadingAnomalies: boolean
    readonly loadingAnomaly: boolean
    readonly totalRecords: TotalRecords
    readonly selected?: Anomaly
}

export type PaginableAnomaly = Paginable<Anomaly>