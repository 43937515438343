import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { DistrigoCreditActionTypes, DistrigoCreditState } from './types'

export const initialPaymentMethodsState: DistrigoCreditState = {
    loading: false,
    distrigoCredit: null,
    creating: false,
    created: false,
    currentDistrigoCredit: null
}

const reducer: Reducer<DistrigoCreditState> = (state = initialPaymentMethodsState, action) => {
    switch (action.type) {
        case DistrigoCreditActionTypes.FETCH_DISTRIGO_CREDIT: {
            return { ...state, loading: true }
        }
        case DistrigoCreditActionTypes.FETCH_DISTRIGO_CREDIT_SUCCESS: {
            return { ...state, loading: false, distrigoCredit: action.payload.distrigoCredit, currentDistrigoCredit: action.payload.distrigoCredit.current }
        }
        case DistrigoCreditActionTypes.CREATE_DISTRIGO_CREDIT: {
            return { ...state, loading: true, created: false, creating: true }
        }
        case DistrigoCreditActionTypes.CREATE_DISTRIGO_CREDIT_SUCCESS: {
            return { ...state, loading: false, created: true, creating: false, currentDistrigoCredit: action.payload.distrigoCredit }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loading: false, creating: false }
        }
        default: {
            return state
        }
    }
}

export { reducer as distrigoCreditReducer }
