import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Divider, Message, Table } from 'semantic-ui-react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { fetchDistrigoCredit } from '~/store/distrigo-credit/actions';
import { CustomerDistrigoCredit, CustomerPaymentDistrigoCredit } from '~/store/distrigo-credit/types';
import { DictionariesState, DictionaryItem } from '~/store/dictionaries/types';
import { parseDate } from '~/utils/dateUtils';
import CommonLoader from '~/components/Loaders/CommonLoader';
import { ApplicationState } from '~/store';
import { connect } from 'react-redux';
import HistoryModal from '~/pages/Customers/Details/lib/HistoryModal';
import { parsePrice } from '~/utils/parsePrice';
import mapDictionary from '~/components/MapDictionary/mapDictionary';
import dictionariesStatic from '~/store/dictionaries/static';

type ActualDistrigoCreditProps = {
  customerId: string;
  editMode: boolean;
};

type TReduxState = {
  loading: boolean;
  distrigoCredit: CustomerDistrigoCredit | null;
  paymentDueDictionary: DictionaryItem[];
  history: CustomerPaymentDistrigoCredit[] | undefined;
  currentCredit: CustomerPaymentDistrigoCredit | null;
  creating: boolean;
  created: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchDistrigoCredit: typeof fetchDistrigoCredit;
};

type Props = TReduxActions & TReduxState & ActualDistrigoCreditProps;

export const toCurrencyString = (value: number | undefined) => {
  return value ? `${value.toString()} PLN` : '0 PLN';
};

const ActualDistrigoCredit = ({
  customerId,
  fetchDistrigoCredit,
  loading,
  distrigoCredit,
  currentCredit,
  history,
  creating,
  created,
  dictionaries,
}: Props) => {
  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  useEffect(() => {
    fetchDistrigoCredit(customerId);
    // eslint-disable-next-line
  }, [customerId, created]);

  const renderLines = (methods: CustomerPaymentDistrigoCredit[] | undefined): JSX.Element => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell>Okres</Table.HeaderCell>
            <Table.HeaderCell>Termin płatności</Table.HeaderCell>
            <Table.HeaderCell>Obowiązuje do</Table.HeaderCell>
            <Table.HeaderCell>Data dodania</Table.HeaderCell>
            <Table.HeaderCell>Komentarz</Table.HeaderCell>
            <Table.HeaderCell>Użytkownik</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {methods
            ?.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())
            .map((line, index) => (
              <Table.Row key={index}>
                <Table.Cell>{parsePrice(line.amount)}</Table.Cell>
                <Table.Cell>
                  {mapDictionary(line.renewalType, dictionariesStatic.DISTRIGO_LIMIT_RENEWAL_TYPE, true, true, true)}
                </Table.Cell>
                <Table.Cell>
                  {mapDictionary(line.paymentDueId, dictionaries['payment-due'], true, true, true)}
                </Table.Cell>
                <Table.Cell>{parseDate(line.validUntil)}</Table.Cell>
                <Table.Cell>{parseDate(line.creationTime)}</Table.Cell>
                <Table.Cell>{line.creationComment === '' ? '-' : line.creationComment}</Table.Cell>
                <Table.Cell>{line.authorUsername}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  };

  const currentLimit: JSX.Element = useMemo(() => {
    return (
      <>
        <SmartLabel
          label={formatMessage('app.customer.loan.amount')}
          value={parsePrice(currentCredit?.amount ? currentCredit.amount : 0)}
          align="left"
        />
        <SmartLabel
          label={formatMessage('app.customer.loan.orders')}
          value={parsePrice(distrigoCredit?.ordersAmount ? distrigoCredit.ordersAmount : 0)}
          align="left"
        />
        <SmartLabel
          label={formatMessage('app.customer.loan.invoices')}
          value={parsePrice(distrigoCredit?.invoicesAmount ? distrigoCredit.invoicesAmount : 0)}
          align="left"
        />

        <Divider />

        <SmartLabel
          label={formatMessage('app.customer.loan.available')}
          value={parsePrice(distrigoCredit?.currentLimitTotal ? distrigoCredit.currentLimitTotal : 0)}
          align="left"
        />

        <p style={{ marginTop: '40px' }} />

        {currentCredit?.creationComment ? (
          <SmartLabel
            label={formatMessage('app.customer.loan.details')}
            value={currentCredit?.creationComment}
            align="left"
          />
        ) : null}
        {distrigoCredit?.current ? (
          <>
            <SmartLabel
              label={formatMessage('app.customer.loan.renewable')}
              value={currentCredit?.renewalType !== 'NONE' ? 'Tak' : 'Nie'}
              align="left"
            />
            {currentCredit?.renewalType !== 'NONE' ? (
              <SmartLabel
                label={formatMessage('app.customer.loan.period')}
                value={mapDictionary(
                  currentCredit?.renewalType,
                  dictionariesStatic.DISTRIGO_LIMIT_RENEWAL_TYPE,
                  true,
                  true,
                  true
                )}
                align="left"
              />
            ) : null}
            <SmartLabel
              label={formatMessage('app.customer.loan.paymentPeriod')}
              value={mapDictionary(
                currentCredit?.paymentDueId ? currentCredit.paymentDueId : '',
                dictionaries['payment-due'],
                true,
                true,
                true
              )}
              align="left"
            />

            <SmartLabel
              label={formatMessage('app.customer.loan.availableFrom')}
              value={parseDate(currentCredit?.creationTime)}
              align="left"
            />
            <SmartLabel
              label={formatMessage('app.customer.loan.availableTo')}
              value={parseDate(currentCredit?.validUntil)}
              align="left"
            />
          </>
        ) : null}
      </>
    );
  }, [distrigoCredit, currentCredit, dictionaries]);

  return (
    <>
      {distrigoCredit?.current || distrigoCredit?.currentLimitTotal ? (
        currentLimit
      ) : (
        <Message color="orange" style={{ width: '100%' }}>
          <Container fluid textAlign="center">
            Brak aktualnego kredytu Distrigo.
          </Container>
        </Message>
      )}
      <CommonLoader loading={loading || creating} />
      <HistoryModal loading={loading} list={history || []}>
        {renderLines(history || [])}
      </HistoryModal>
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  distrigoCredit,
  dictionaries,
}: ApplicationState) => {
  return {
    loading: distrigoCredit.loading,
    distrigoCredit: distrigoCredit.distrigoCredit,
    history: distrigoCredit.distrigoCredit?.history,
    currentCredit: distrigoCredit.currentDistrigoCredit,
    paymentDueDictionary: dictionaries['payment-due'],
    creating: distrigoCredit.creating,
    created: distrigoCredit.created,
    dictionaries,
  };
};
const mapDispatchToProps: TReduxActions = { fetchDistrigoCredit };

export default connect(mapStateToProps, mapDispatchToProps)(ActualDistrigoCredit);
