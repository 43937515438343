import DetailCard from '~/components/DetailCard/DetailCard';
import { Form, Input } from 'semantic-ui-react';
import { FormInputNumber } from '~/components/InputNumber/InputNumber';
import React from 'react';
import { CardDetailsWarrantyProps } from '~/pages/Orders/lib/Add/Warranty/constants';

const CardDetailsWarranty = ({ order, summary, updateOrder }: CardDetailsWarrantyProps) => (
  <DetailCard title="app.guarantee" id="warranty" key="warranty" minHeight={40} width={3} smallWidth={5.33}>
    <Form.Field>
      <label>Nr wniosku gwar.</label>
      <Input
        fluid
        value={order.sagaiNumber}
        readOnly={summary}
        onChange={(e, d) => updateOrder('sagaiNumber', d.value)}
      />
    </Form.Field>
    <Form.Field>
      <label>Koszt części zamiennych</label>
      <FormInputNumber
        fluid
        type="number"
        min={0}
        precision={2}
        value={order.sagaiPartsCost}
        readOnly={summary}
        onChange={(e, d) => updateOrder('sagaiPartsCost', d.value)}
        placeholder="Koszt w PLN"
      />
    </Form.Field>
    <Form.Field>
      <label>Koszt robocizny</label>
      <FormInputNumber
        fluid
        type="number"
        min={0}
        precision={2}
        value={order.sagaiWorkCost}
        readOnly={summary}
        onChange={(e, d) => updateOrder('sagaiWorkCost', d.value)}
        placeholder="Koszt w PLN"
      />
    </Form.Field>
  </DetailCard>
);

export default CardDetailsWarranty;
