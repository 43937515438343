import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '~/utils/api'
import { toastError } from '~/utils/toast'
import {fetchError, fetchErrorSparePart, fetchPriceList, fetchSuccess, fetchSuccessSparePart} from "./actions"
import { PriceListActionTypes, SparePartPricing } from "./types"

function* handleFetch(action: ReturnType<typeof fetchPriceList>) {
    try {
        // TODO remove slice and replace with backend pagination when it's ready
        const res:SparePartPricing[] = yield call(callApi, 'get', `/spare-parts-price-list?query=${action.payload}`)
        yield put(fetchSuccess(res.slice(0, 10)))
    } catch (error) {
        toastError("app.error.common.load", "app.error.order.select-spare-part")
        put(fetchError(error))
    }
}

function* handleFetchSparePart(action: ReturnType<typeof fetchPriceList>) {
    try {
        const res:SparePartPricing = yield call(callApi, 'get', `/spare-parts-price-list/${action.payload}`)
        yield put(fetchSuccessSparePart(res))
    } catch (error) {
        toastError("app.error.common.load", "app.error.order.select-spare-part")
        put(fetchErrorSparePart(error))
    }
}

function* watchFetchRequest() {
    yield takeEvery(PriceListActionTypes.FETCH_REQUEST, handleFetch)
}

function* watchFetchRequestSparePart() {
    yield takeEvery(PriceListActionTypes.FETCH_REQUEST_SPARE_PART, handleFetchSparePart)
}

function* priceListSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchFetchRequestSparePart)
    ])
}

export default priceListSaga
