import React from 'react';
import { useOrdersRolesRights } from './useOrdersRolesRights';
import { openChooseTypeOfExportDataModal } from '~/store/orders/actions';
import translations from '~/utils/translations';
import { useHistory } from 'react-router';

type Props = {
  setOpenCancelOrderPage: React.Dispatch<React.SetStateAction<boolean>>;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  exportPercent: number;
  disableButton: boolean;
  setOpenUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCancelLinesModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useOrderButtons = (props: Props) => {
  const {
    userHaveLdcUiOrderCloseUnrealizedRole,
    userHaveLdcUiOrderBonusRole,
    userHaveLdcUiOrderSpecialRole,
    userHaveLdcUiOrderNormalRole,
    userHaveLdcUiOrderWarrantyRole,
   } = useOrdersRolesRights();

  const history = useHistory();

  const openCreatePage = (): void => {
    history.push('/order/create');
  };

  const cancelButton = {
    icon: 'cancel',
    content: translations.format('app.button.cancel'),
    primary: true,
    onClick: () => props.setOpenCancelOrderPage(true),
    visible: userHaveLdcUiOrderCloseUnrealizedRole,
  };

  const addButton = {
    icon: 'add',
    content: translations.format('app.button.add'),
    primary: true,
    onClick: () => openCreatePage(),
    visible:
      userHaveLdcUiOrderBonusRole ||
      userHaveLdcUiOrderSpecialRole ||
      userHaveLdcUiOrderNormalRole ||
      userHaveLdcUiOrderWarrantyRole,
  };

  const downloadButton = {
    icon: 'download',
    content: translations.format('app.button.orders.export'),
    primary: true,
    onClick: () => props.openChooseTypeOfExportDataModal(true),
    window: props.exportPercent,
    disabled: props.disableButton,
  };

  const uploadButton = {
    icon: 'upload',
    content: translations.format('app.button.orders.upload-bonus-lines'),
    primary: true,
    onClick: () => props.setOpenUploadModal(true),
  };

  const linesUploadButton = {
    icon: 'upload',
    content: translations.format('app.button.orders.upload-cancel-lines'),
    primary: true,
    onClick: () => props.setOpenCancelLinesModal(true),
  };

  return [cancelButton, addButton, downloadButton, uploadButton, linesUploadButton];
};
