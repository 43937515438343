import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import { setAsUnread } from "~/store/claims/actions";
import { IClaim } from "~/store/claims/types";

type TReduxActions = {
  setAsUnread: typeof setAsUnread;
};

type TProps = RouteComponentProps &
  TReduxActions & {
    claim: IClaim | undefined;
  };

enum IconState {
  open = "envelope open",
  closed = "envelope",
}

const ClaimDetailsHeader: React.FC<TProps> = ({
  claim,
  history,
  setAsUnread,
}) => {
  const [iconName, setIconName] = useState<SemanticICONS>(IconState.open);
  const {
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  } = useClaimsRolesRights();

  const renderedClaimTitle: string = useMemo(() => {
    if (!claim) {
      return "Wczytywanie...";
    }
    return `Reklamacja ${claim.claimNumber}`;
  }, [claim]);

  const redirect = useCallback(() => {
    history.push("../claims");
  }, [history]);

  const setAsUnreadAndReturn = useCallback(() => {
    if (!claim || !claim.claimNumber) return;
    setAsUnread(claim?.claimNumber, false, redirect);
  }, [redirect, claim, setAsUnread]);

  const trigger = useCallback(() => {
    return (
      <Icon
        onMouseEnter={() => setIconName(IconState.closed)}
        onMouseLeave={() => setIconName(IconState.open)}
        name={iconName}
        color="orange"
        style={{ cursor: "pointer", marginLeft: 10 }}
        onClick={() => setAsUnreadAndReturn()}
      />
    );
  }, [iconName, setAsUnreadAndReturn]);

  const readStatusIconVisible = useCallback(() => {
    return (
      userHaveLdcUiClaimsProcessRole ||
      userHaveLdcUiWarehouseClaimsProcessRole ||
      userHaveLdcUiTransportClaimsProcessRole
    );
  }, [
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
  ]);

  return (
    <>
      {renderedClaimTitle}
      {readStatusIconVisible() ? (
        <Popup trigger={trigger()}>
          {"Oznacz jako nieprzeczytane i wyjdź"}
        </Popup>
      ) : null}
    </>
  );
};

const mapDispatchToProps: TReduxActions = {
  setAsUnread: setAsUnread,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ClaimDetailsHeader));
