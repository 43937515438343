
export const encodeRequestFilters = (filters: any[]): any[] => filters
    .flatMap(filter => {
        return {
            ...filter,
            key: sanitize(filter.key),
            value: sanitize(filter.value),
        }
    })

const sanitize = (raw: any): any => (typeof raw === "string") ?
    String(raw).replaceAll("&", "%26")
    :
    raw;
