import { useContext } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const usePromotionsRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiPromotionProcessRole = (): boolean =>
    context?.keycloak?.hasResourceRole(
      AVAILABLE_ROLE_ENUM.LDC_PROMOTION_PROCESS
    );

  return { userHaveLdcUiPromotionProcessRole };
};
