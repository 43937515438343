import React from 'react';
import { GridColumn } from 'semantic-ui-react';
import { parsePrice } from '~/utils/parsePrice';
import { TotalAmountProps } from '~/pages/Orders/lib/Add/Lines/constants';

const TotalAmount = ({ deliveryPoint, currLimit, getTotalAmount }: TotalAmountProps) => {
  return (
    <GridColumn textAlign="right">
      <div>
        <label>Całkowita wartość zamówienia: </label>
        <strong style={{ color: 'red' }}>{parsePrice(getTotalAmount())}</strong>
      </div>
      <div>
        <label>Dostępny limit: </label>
        <strong>{parsePrice(deliveryPoint ? currLimit : 0)}</strong>
      </div>
    </GridColumn>
  );
};

export default TotalAmount;
