import React from "react";
import { useCallback, useMemo } from "react";
import { Button, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { useCells } from "~/services/useCells";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionariesState } from "~/store/dictionaries/types";
import translations from "~/utils/translations";

type TProps = {
  tableKey: string;
};

export const useLibraryColumns = ({ tableKey }: TProps) => {
  const { getTextCell, getDateCell } = useCellsRenderer();
  const { isSuperAdmin } = useClientPanelRoles();

  const getIntendedForCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name].length}
        <InfoIcon
          documents={document.customerClasses}
          clickableItem={(customerClas) => (
            <Button
              compact
              basic
              key={customerClas}
              style={{
                display: "block",
                width: "100%",
                boxShadow: "none",
                outline: "none",
              }}
            >
              {translations.format(`app.customer-class.${customerClas}`)}
            </Button>
          )}
          noResultsMessage="Brak"
        />
      </Table.Cell>
    ),
    []
  );

  const userColumns: TColumn[] = useMemo(
    () => [
      {
        name: "name",
        label: "Nazwa",
        projection: true,

        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "description",
        label: "Opis",
        projection: true,
        width: 7,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "createdAt",
        label: "Data dodania",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      } as TColumn,
    ],
    [getTextCell, getDateCell]
  );

  const adminColumns: TColumn[] = useMemo(
    () => [
      ...userColumns,
      {
        name: "author",
        label: "Autor",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      } as TColumn,
      {
        name: "customerClasses",
        label: "Przeznaczone dla",
        projection: true,
        getCell: (document, column) => getIntendedForCell(document, column),
        disableSort: true,
        filteringBlocked: true,
        dictionary: dictionariesStatic.CUSTOMER_CLASSES,
        noKey: true,
      } as TColumn,
    ],
    [userColumns, getIntendedForCell, getTextCell]
  );

  const { completeColumnDictionaries } = useCells({
    tableKey,
    dictionaries: {} as DictionariesState,
  });

  return isSuperAdmin
    ? completeColumnDictionaries(adminColumns)
    : completeColumnDictionaries(userColumns);
};
