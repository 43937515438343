import { connect } from 'react-redux';
import { Button, Grid, Popup, Table } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import {
  addBackorderLine,
  deleteBackorderLine,
  fetchBackorderLines,
  importBackorderLines,
  linesOperationFinished,
  loadLinesCsv,
  loadLinesExcel,
  loadLinesLoaded,
  updateBackorderLine,
} from '~/store/backorder/actions';
import { Backorder, BackorderLine } from '~/store/backorder/types';
import { fetchDictionary } from '~/store/dictionaries/actions';
import { useRemoveLineModal, useReplacementsModal, useUploadCsvModal, useUploadXlsModal } from './Modals';
import { DictionaryItem } from '~/store/dictionaries/types';
import { SparePartPricing } from '~/store/price-list/types';
import { toastWarn } from '~/utils/toast';

import React, { useCallback, useEffect, useMemo, useState, SyntheticEvent } from 'react';
import CommonLoader from '~/components/Loaders/CommonLoader';
import useCodesModal from '~/components/Modals/hooks/useCodesModal';
import BackorderItem from './Item';
import TablePaginationFooter from '~/components/TablePaginationFooter/TablePaginationFooter';
import EnterSearch from '~/components/EnterSearch/EnterSearch';

type TReduxState = {
  lines: BackorderLine[];
  meta: any;
  loading: boolean;
  loadingLines: boolean;
  progress: number;
  parsedLines: BackorderLine[];
  operationSuccess: boolean;
  backorder: Backorder;
};

type TReduxActions = {
  fetchDictionary: typeof fetchDictionary;
  fetchBackorderLines: typeof fetchBackorderLines;
  addBackorderLine: typeof addBackorderLine;
  updateBackorderLine: typeof updateBackorderLine;
  deleteBackorderLine: typeof deleteBackorderLine;
  loadLinesCsv: typeof loadLinesCsv;
  loadLinesExcel: typeof loadLinesExcel;
  loadLinesLoaded: typeof loadLinesLoaded;
  importBackorderLines: typeof importBackorderLines;
  linesOperationFinished: typeof linesOperationFinished;
};

type TProps = {
  editMode: boolean;
  editModeOrigin?: boolean;
};

export type TBackorderColumn = {
  name: keyof BackorderLine;
  label: string | JSX.Element;
  width?: number;
  dictionary?: DictionaryItem[];
  textAlign?: 'left' | 'center' | 'right';
};

type TBackorderLinesProps = TReduxState & TReduxActions & TProps;

const BackorderLines: React.FC<TBackorderLinesProps> = ({
  lines,
  meta,
  loading,
  loadingLines,
  progress,
  parsedLines,
  operationSuccess,
  editMode,
  editModeOrigin,
  backorder,
  fetchDictionary,
  fetchBackorderLines,
  addBackorderLine,
  updateBackorderLine,
  deleteBackorderLine,
  loadLinesCsv,
  loadLinesExcel,
  loadLinesLoaded,
  importBackorderLines,
}) => {
  const columns = useMemo(
    (): TBackorderColumn[] => [
      { name: 'number', label: 'Nr', width: 50 },
      { name: 'referenceId', label: 'Referencja' },
      { name: 'description', label: 'Nazwa' },
      { name: 'price', label: 'Cena', width: 120 },
      { name: 'quantity', label: 'Ilość', width: 120 },
      { name: 'packagingQuantity', label: 'UC', width: 60 },
      { name: 'codes', label: 'Kodowanie', width: 140 },
      { name: 'confirmedQuantity', label: 'Potwierdzone', textAlign: 'right' },
      { name: 'receptionQuantity', label: 'Oczekujące', textAlign: 'right' },
      { name: 'receivedQuantity', label: 'Zrealizowane', textAlign: 'right' },
      { name: 'shippedReferenceId', label: 'Referencja zrealizowana', width: 120 },
    ],
    []
  );

  const [openModal, setOpenModal] = useState(false);

  const [selectedLine, setSelectedLine] = useState<BackorderLine>();
  const [selectedSparePart, setSelectedSparePart] = useState<SparePartPricing>();
  const [newLine, setNewLine] = useState<BackorderLine>();
  const [pageSize, setPageSize] = useState(50);
  const [maximized, setMaximized] = useState(false);
  const [backorderLinesQueryFilter, setbackorderLinesQueryFilter] = useState<string>('');

  const pageChange = useCallback(
    (page: number): void => {
      fetchBackorderLines(backorder.id, { page, size: pageSize });
    },
    [fetchBackorderLines, backorder.id, pageSize]
  );

  useEffect(() => {
    if (backorder && backorder.id) {
      fetchBackorderLines(backorder.id, { page: 1, size: pageSize });
    }
  }, [pageSize, backorder, fetchBackorderLines]);

  const resetModal = useCallback((): void => {
    setCurrentModal(undefined);
    setOpenModal(false);
    loadLinesLoaded([]);
  }, [loadLinesLoaded]);

  useEffect(() => {
    if (operationSuccess) {
      resetModal();
    }
  }, [operationSuccess, resetModal]);

  const handleImportXls = (): void => {
    setCurrentModal('IMPORT_XLS');
    setOpenModal(true);
  };

  const handleImportClipboard = (): void => {
    setCurrentModal('IMPORT_CSV');
    setOpenModal(true);
  };

  const handleAddCoding = (line: BackorderLine): void => {
    setSelectedLine(line);
    setCurrentModal('CODES');
    setOpenModal(true);
  };

  const handleCancelLine = (line: BackorderLine): void => {
    setNewLine(undefined);
  };

  const toggleMaximized = (): void => {
    setMaximized(!maximized);
  };

  const handleRemoveLine = useCallback(
    (line: BackorderLine): void => {
      if (lines.length === 1) {
        toastWarn('Nie można usunąć', 'Zamówienie musi zawierać co najmniej jedną pozycję.');
      } else {
        setSelectedLine(line);
        setCurrentModal('REMOVE_LINE');
        setOpenModal(true);
      }
    },
    [lines.length]
  );

  const handleAddLine = (): void => {
    setNewLine({ number: meta.total + 1 } as BackorderLine);
  };

  const handleSaveNewLine = useCallback(
    (referenceId: string): void => {
      setNewLine(undefined);
      addBackorderLine(backorder.id, { referenceId, quantity: 1 } as BackorderLine);
    },
    [addBackorderLine, backorder.id]
  );

  const handleSaveNewLineReplacements = (product: SparePartPricing): void => {
    product.replacements
      .map((replacement) => replacement.sparePart)
      .forEach((reference) => {
        handleSaveNewLine(reference.referenceId);
      });
  };

  const handleCodingSave = (modifiedLine: BackorderLine): void => {
    const codes: any = {};
    Object.entries(modifiedLine.codes || {})
      .filter(([code, entry]) => !!entry)
      .forEach(([code, entry]) => {
        codes[code] = entry && { value: entry.value };
      });
    updateBackorderLine(backorder.id, {
      number: modifiedLine.number,
      codes,
    } as BackorderLine);
  };

  const handleReplacements = (product: SparePartPricing): void => {
    setSelectedSparePart(product);
    setCurrentModal('REPLACEMENTS');
    setOpenModal(true);
  };

  const getOrderLinesWithQueryFilter = (): void => {
    fetchBackorderLines(backorder.id, { page: 1, size: pageSize }, [
      { name: 'query', value: backorderLinesQueryFilter },
    ]);
  };

  const handleQuickSearchChange = (event: SyntheticEvent, data: any): void => {
    const { value } = data;
    if (value) {
      setbackorderLinesQueryFilter(value);
    } else {
      setbackorderLinesQueryFilter('');
      fetchBackorderLines(backorder.id, { page: 1, size: pageSize });
    }
  };

  const renderEnterSearch = (): JSX.Element =>
    !editModeOrigin ? (
      <div style={{ maxWidth: 'fit-content', margin: '10px 0' }}>
        <EnterSearch onSearchChange={handleQuickSearchChange} onSearchAction={getOrderLinesWithQueryFilter} />
      </div>
    ) : (
      <></>
    );

  const renderedOrderLines: JSX.Element = useMemo(() => {
    const rows = lines.map((line, index) => {
      return (
        <BackorderItem
          key={`${line.number}-${line.referenceId}`}
          backorder={backorder}
          loading={loadingLines}
          columns={columns}
          lineIn={line}
          isNew={false}
          editMode={editMode}
          addCodingHandler={handleAddCoding}
          saveNewLineHandler={handleSaveNewLine}
          replacementsHandler={handleReplacements}
          removeLineHandler={handleRemoveLine}
        />
      );
    });

    if (newLine) {
      rows.push(
        <BackorderItem
          key="newitem"
          backorder={backorder}
          loading={loadingLines}
          columns={columns}
          lineIn={newLine}
          isNew={true}
          editMode={editMode}
          addCodingHandler={handleAddCoding}
          saveNewLineHandler={handleSaveNewLine}
          replacementsHandler={handleReplacements}
          removeLineHandler={handleCancelLine}
        />
      );
    }

    return (
      <div
        className={`${!editMode ? 'uber-table' : ''} no-wrap`}
        style={{
          maxHeight: maximized ? '100%' : 'calc(100vh - 300px)',
          minHeight: 200,
          height: maximized ? 'auto' : 'auto',
        }}
      >
        <Table>
          <Table.Header>
            <Table.Row>
              {columns.map((column) => (
                <Table.HeaderCell
                  key={column.name}
                  style={
                    column.textAlign ? { textAlign: column.textAlign, width: column.width } : { width: column.width }
                  }
                >
                  {column.label}
                </Table.HeaderCell>
              ))}
              {editMode && <Table.HeaderCell style={{ width: 75 }} />}
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
          <TablePaginationFooter
            meta={meta}
            totalRecords={undefined}
            onPageChange={pageChange}
            onPageSizeChange={(val) => setPageSize(val)}
          />
        </Table>
        <CommonLoader loading={loadingLines && !loading} />
      </div>
    );
  }, [
    lines,
    loadingLines,
    editMode,
    maximized,
    newLine,
    backorder,
    columns,
    handleRemoveLine,
    handleSaveNewLine,
    loading,
    meta,
    pageChange,
  ]);

  const [currentModal, setCurrentModal] = useState<
    'IMPORT_XLS' | 'IMPORT_CSV' | 'REMOVE_LINE' | 'CODES' | 'REPLACEMENTS'
  >();
  const importXlsModal = useUploadXlsModal(
    openModal,
    resetModal,
    loadingLines,
    parsedLines,
    progress,
    backorder.id,
    loadLinesExcel,
    importBackorderLines
  );
  const importCsvModal = useUploadCsvModal(
    openModal,
    resetModal,
    loadingLines,
    parsedLines,
    progress,
    backorder.id,
    loadLinesCsv,
    importBackorderLines
  );
  const removeLineModal = useRemoveLineModal(
    openModal,
    resetModal,
    loadingLines,
    backorder.id,
    deleteBackorderLine,
    selectedLine
  );
  const codesModal = useCodesModal(openModal, resetModal, handleCodingSave, loadingLines, selectedLine);
  const replacementsModal = useReplacementsModal(
    openModal,
    resetModal,
    loadingLines,
    handleSaveNewLineReplacements,
    selectedSparePart
  );

  const getCurrentModal = (): JSX.Element => {
    switch (currentModal) {
      case 'IMPORT_XLS':
        return importXlsModal;
      case 'IMPORT_CSV':
        return importCsvModal;
      case 'REMOVE_LINE':
        return removeLineModal;
      case 'CODES':
        return codesModal;
      case 'REPLACEMENTS':
        return replacementsModal;
      default:
        return <></>;
    }
  };

  return (
    <>
      {getCurrentModal()}
      <Grid columns="2">
        <Grid.Row>
          <Grid.Column verticalAlign="bottom">
            Ilość: <b>{meta.total}</b>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Popup
              trigger={
                <Button
                  circular
                  basic
                  icon="file excel"
                  onClick={handleImportXls}
                  disabled={loading || !editMode || backorder.method === 'CROSS_DOCK'}
                />
              }
              content="Importuj linie z pliku XLS"
            />
            <Popup
              trigger={
                <Button
                  circular
                  basic
                  icon="paste"
                  onClick={handleImportClipboard}
                  disabled={loading || !editMode || backorder.method === 'CROSS_DOCK'}
                  className="display-none"
                />
              }
              content="Importuj ze schowka linie oddzielone przecinkami"
            />
            <Popup
              trigger={
                <Button
                  circular
                  basic
                  icon={maximized ? 'window restore outline' : 'expand arrows alternate'}
                  onClick={toggleMaximized}
                />
              }
              position="bottom right"
              content={maximized ? 'Minimalizuj widok tabeli' : 'Maksymalizuj widok tabeli'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {renderEnterSearch()}
      {backorderLinesQueryFilter && lines.length === 0 ? (
        <p>
          Brak wyników wyszukiwania dla frazy <b>{backorderLinesQueryFilter}</b>.
        </p>
      ) : (
        <>
          {lines.length > 0 && (
            <div className="table-footer-up">
              <TablePaginationFooter
                meta={meta}
                totalRecords={undefined}
                pageSizeVisible={false}
                onPageChange={pageChange}
                onPageSizeChange={(val) => setPageSize(val)}
              />
            </div>
          )}
          {renderedOrderLines}
        </>
      )}
      <Popup
        trigger={
          <div>
            <Button
              circular
              basic
              icon="add"
              onClick={handleAddLine}
              disabled={loading || !editMode || backorder.method === 'CROSS_DOCK'}
            />
          </div>
        }
        content={
          backorder.method === 'CROSS_DOCK'
            ? 'Nie można dodawać linii do zamówienia typu CROSS-DOC'
            : 'Dodaj kolejną linię do zamówienia'
        }
      />
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  backorders,
  dictionaries,
  pricing,
}: ApplicationState) => {
  return {
    backorder: backorders.selected as Backorder,
    backorderStatus: backorders.selected ? backorders.selected.status : '',
    lines: backorders.selectedLines || [],
    meta: backorders.linesMeta,
    loading: backorders.loadingBackorder,
    loadingLines: backorders.loadingLines,
    progress: backorders.progress,
    parsedLines: backorders.parsedLines,
    operationSuccess: backorders.operationSuccess,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchDictionary,
  fetchBackorderLines,
  addBackorderLine,
  updateBackorderLine,
  deleteBackorderLine,
  loadLinesCsv,
  loadLinesExcel,
  loadLinesLoaded,
  importBackorderLines,
  linesOperationFinished,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackorderLines);
