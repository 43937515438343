export const roundQuantity = (inputValue: number, packing: number): number => {
    if(inputValue){
        let value = Number(inputValue)
        if (packing) {
            const step = packing || 1
            return value <= step ? step : (Math.round(value / step) * step)
        }
        return value
    }
    return packing ? packing : 0
}