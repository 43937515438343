import { action } from 'typesafe-actions'
import { ApiError, ErrorActionTypes, TUiError } from './types'

import translations from '~/utils/translations'

const getApiErrorTitle = (method: string): string => {
    switch (method.toUpperCase()) {
        case 'GET':
            return translations.format("app.error.common.load")
        case 'POST':
        case 'PUT':
            return translations.format("app.error.common.create")
        case 'PATCH':
            return translations.format("app.error.common.update")
        case 'DELETE':
            return translations.format("app.error.common.delete")
        default:
            return translations.format("app.error.common.unknown")
    }
}

const getApiErrorDescription = (status: number): string | undefined => {
    switch (status) {
        case 400:
            return translations.format("app.error.common.description.request")
        case 401:
            return translations.format("app.error.common.description.unauthorized")
        case 403:
            return translations.format("app.error.common.description.forbidden")
        case 404:
            return translations.format("app.error.common.description.notfound")
        case 409:
            return translations.format("app.error.common.description.conflict")
        case 422:
            return translations.format("app.error.common.description.unprocessableEntity")
        case 500:
            return translations.format("app.error.common.description.server")
        case 0:
        case 502:
        case 503:
            return translations.format("app.error.common.description.connection")
    }
}

/**
 * Handle error.
 * 
 * @param error Error to handle
 * @param blocking Should error block whole UI page.
 * @param title Title of the error to show in toast. If not provided, generic will be used.
 * @param description Description of the error to show in toast.
 */
export const handleError = (error: Error, blocking?: boolean, title?: string, description?: string) => {
    if (error instanceof ApiError) {
        title || (title = getApiErrorTitle(error.method))
        description || (description = getApiErrorDescription(error.status))
    }

    title || (title = translations.format('app.error.common.unknown'))
    description || (description = translations.format("app.error.common.description.unknown"))

    return action(ErrorActionTypes.HANDLE_ERROR, { error, blocking, title, description } as TUiError)
}

/**
 * Clear all errors.
 */
export const clearErrors = () => action(ErrorActionTypes.CLEAR_ERRORS)