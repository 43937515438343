import TableCellPopup from "~/components/TableCellPopup/TableCellPopup";
import React from "react";
import { TableCellType } from "~/pages/Orders/Details/lib/Lines/Item/TableCell/constants";

export const DescriptionTableCell = ({ column, line }: TableCellType) => {
  const getSapStatus = (status?: string): JSX.Element => {
    let text = "";

    switch (status) {
      case "PROCESSED_BY_SAP":
        text = "Zrealizowane";
        break;
      case "REPLACED_BY_SAP":
        text = "";
        break;
      case "ADDED_BY_SAP":
        text = "";
        break;
      case "REJECTED":
        text = "";
        break;
      default:
        text = "Niezrealizowane";
        break;
    }
    return (
      <span style={{ fontSize: 10 }}>{text.length ? `(${text})` : null}</span>
    );
  };

  return (
    <TableCellPopup key={column.name}>
      {line[column.name]} {getSapStatus(line.sapStatus)}
    </TableCellPopup>
  );
};
