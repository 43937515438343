import { action } from "typesafe-actions";
import { NotificationActionTypes, PaginableNotification } from "./types";

export const fetchNotifications = (params: any) =>
  action(NotificationActionTypes.FETCH_REQUEST, params);
export const fetchIndividualNotification = (notificationId: any) =>
  action(NotificationActionTypes.FETCH_INDIVIDUAL_REQUEST, notificationId);
export const fetchIndividualNotificationSuccess = (uniqueNotification: any) =>
  action(
    NotificationActionTypes.FETCH_INDIVIDUAL_REQUEST_SUCCESS,
    uniqueNotification
  );
export const fetchSuccess = (notifications: PaginableNotification) =>
  action(NotificationActionTypes.FETCH_SUCCESS, notifications);
export const fetchLasts = (params: any) =>
  action(NotificationActionTypes.FETCH_LAST_REQUEST, params);
export const fetchLastsSuccess = (notifications: PaginableNotification) =>
  action(NotificationActionTypes.FETCH_LASTS_SUCCESS, notifications);

export const fetchCount = () => action(NotificationActionTypes.FETCH_COUNT);
export const fetchCountSuccess = (count: number) =>
  action(NotificationActionTypes.FETCH_COUNT_SUCCESS, { count });

export const fetchManualOrdersCount = () =>
  action(NotificationActionTypes.FETCH_MANUAL_ORDERS_COUNT);
export const fetchManualOrdersCountSuccess = (count: number) =>
  action(NotificationActionTypes.FETCH_MANUAL_ORDERS_COUNT_SUCCESS, { count });

export const makeRead = (id: string) =>
  action(NotificationActionTypes.MAKE_READ, id);
export const makeAllRead = (notifications?: string[]) =>
  action(NotificationActionTypes.MAKE_ALL_READ, notifications);
