import { DictionariesState, DictionaryItem } from '~/store/dictionaries/types';
import { Order } from '~/store/orders/types';
import { RouteComponentProps } from 'react-router';
import { changeStatusOrder, clearSelected, selectOrder, clearCreated } from '~/store/orders/actions';
import { fetchDictionary } from '~/store/dictionaries/actions';

export const options: DictionaryItem[] = [
  { key: 'true', text: 'Tak', value: true },
  { key: 'false', text: 'Nie', value: false },
];

export type TReduxState = {
  orderIn?: Order;
  dictionaries: DictionariesState;
  loading: boolean;
  created?: string;
};

export type TReduxActions = {
  selectOrder: typeof selectOrder;
  clearSelected: typeof clearSelected;
  changeStatusOrder: typeof changeStatusOrder;
  fetchDictionary: typeof fetchDictionary;
  clearCreated: typeof clearCreated;
};

type TRouteParams = RouteComponentProps<{
  id: string;
  edit?: string;
}>;

export type TOrderDetailsProps = TReduxState & TReduxActions & TRouteParams;
