import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { ReplenishmentActionTypes, ReplenishmentState } from './types'

export const initialReplenishmentState: ReplenishmentState = {
    loading: false,
    replenishments: []
}

const reducer: Reducer<ReplenishmentState> = (state = initialReplenishmentState, action) => {
    switch (action.type) {
        case ReplenishmentActionTypes.FETCH_REPLENISHMENT: {
            return { ...state, loading: true }
        }
        case ReplenishmentActionTypes.FETCH_REPLENISHMENT_SUCCESS: {
            return { ...state, loading: false, replenishments: action.payload.replenishments.lines }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loading: false, replenishments: [] }
        }
        default: {
            return state
        }
    }
}

export { reducer as replenishmentReducer }
