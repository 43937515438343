import { Label } from "semantic-ui-react"
import { TotalRecords } from '~/store/types'

import React from 'react'
import { textInformation } from "~/components/TotalRecords/bin/utils";

type Props = {
    totalRecords?: TotalRecords
}

const TotalRecordsInfo: React.FC<Props> = ({ totalRecords }): JSX.Element => {

    return (
        totalRecords ?
        <div style={{ display: 'inline-block', marginRight: '5px' }}>
            <Label basic color='grey' size='tiny' floated='left' style={{ marginBottom: '5px', fontWeight: 'normal' }}>Wyszukano: {totalRecords.total} {textInformation(totalRecords.total || 0)}</Label>
            {
                (totalRecords.filtered !== 0 && totalRecords.filtered) &&
                <Label basic color='grey' size='tiny' floated='left' style={{ marginBottom: '5px', fontWeight: 'normal' }}>Odfiltrowano: {totalRecords.filtered} {textInformation(totalRecords.filtered || 0)}</Label>
            }
        </div>
        : <></>
    )
}

export default TotalRecordsInfo
