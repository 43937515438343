import { Form, FormFieldProps, Menu, Select } from "semantic-ui-react";
import TopSearch from "~/components/TopSearch/TopSearch";
import { TopSearchGroup } from "~/components/PsaHeader/lib/types";
import React, { useContext } from "react";
import { AppContext } from "~/context/AuthContext";
import { getSearchOptions } from "~/components/PsaHeader/lib/utils";

export type Props = {
  selectValue: string;
  onChange: (e: any, el: FormFieldProps) => void;
};

export const MenuSearchField: React.FC<Props> = ({ selectValue, onChange }) => {
  const appContext = useContext(AppContext);

  return (
    <Menu.Item>
      <Form size="mini">
        <Form.Group
          style={{ margin: 0, padding: 0 }}
          className="topbar-dark-field"
        >
          <TopSearch searchingGroup={selectValue as TopSearchGroup} />
          <Select
            options={getSearchOptions(appContext)}
            onChange={onChange}
            value={selectValue}
            style={{ borderRadius: 0 }}
          />
        </Form.Group>
      </Form>
    </Menu.Item>
  );
};
