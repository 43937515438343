import { connect } from 'react-redux';
import { Button, Dropdown, Form, Grid, Header, Modal } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import { fetchCustomers, selectCustomer } from '~/store/customer/actions';
import { clearCreated, addBackorder } from '~/store/backorder/actions';
import { fetchDistrigoWarehouses } from '~/store/distrigo-warehouses/actions';
import { Customer, DeliveryLocation } from '~/store/customer/types';
import { DictionaryItem } from '~/store/dictionaries/types';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { DistrigoWarehouse } from '~/store/distrigo-warehouses/types';

import React, { useEffect, useState } from 'react';
import DetailCard from '~/components/DetailCard/DetailCard';
import dictionariesStatic from '~/store/dictionaries/static';

type TReduxState = {
  customers: Customer[];
  loadingCustomers: boolean;
  deliveryAddresses: DeliveryLocation[];
  created?: boolean;
  creating?: boolean;
  createdId: string;
  warehouses: DistrigoWarehouse[];
};

type TReduxActions = {
  clearCreated: typeof clearCreated;
  fetchCustomers: typeof fetchCustomers;
  selectCustomer: typeof selectCustomer;
  addBackorder: typeof addBackorder;
  fetchDistrigoWarehouses: typeof fetchDistrigoWarehouses;
};

type TProps = {
  triggerOpen: boolean;
  createdSuccess: () => void;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type TAddOrderProps = TReduxState & TReduxActions & TProps;

const AddBackorder: React.FC<TAddOrderProps> = ({
  children,
  created,
  creating,
  createdSuccess,
  clearCreated,
  addBackorder,
  triggerOpen,
  createdId,
  fetchDistrigoWarehouses,
  warehouses,
  setOpenCreateModal,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [warehouse, setWarehouse] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');

  useEffect(() => {
    fetchDistrigoWarehouses();
  }, [fetchDistrigoWarehouses]);

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    if (!open) {
      setOpenCreateModal(false);
    }
  }, [open, setOpenCreateModal]);

  const mapWarehouses = (warehouses: DistrigoWarehouse[]): DictionaryItem[] => {
    return warehouses.map((warehouse) => ({
      key: warehouse.id,
      text: `${warehouse.id} ${warehouse.name || ''}`,
      value: warehouse.id,
    }));
  };

  const handleAddBackorder = (): void => {
    addBackorder(warehouse, supplier);
  };

  const handleCancel = (): void => {
    if (!creating) {
      setOpen(false);
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  if (created && open) {
    const url = `/backorder/${createdId}/edit`;
    clearCreated();
    createdSuccess();
    return <Redirect to={url} />;
  }

  const isValidForm = (): boolean => (warehouse === '' || supplier === '' ? false : true);

  return (
    <Modal
      size="small"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="shop" content="Dodaj zamówienie hurtowe" />
      <Modal.Content>
        <Grid style={{ marginBottom: 'unset' }} stretched>
          <DetailCard title="app.warehouse" id="warehouse" width={16} style={{ maxHeight: '100px' }} minHeight={50}>
            <Form.Field>
              <Dropdown
                placeholder="Wyszukaj magazyn"
                fluid
                search
                selection
                value={warehouse}
                onChange={(e, d: any) => setWarehouse(d.value)}
                onSearchChange={() => fetchDistrigoWarehouses()}
                options={mapWarehouses(warehouses)}
                noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
              />
            </Form.Field>
          </DetailCard>

          <DetailCard title="app.supplier" id="supplier" width={16} style={{ maxHeight: '100px' }} minHeight={50}>
            <Form.Field>
              <Dropdown
                placeholder="Wybierz dostawcę"
                fluid
                search
                selection
                value={supplier}
                onChange={(e, d: any) => setSupplier(d.value)}
                options={dictionariesStatic.SPARE_PART_SUPPLIER}
              />
            </Form.Field>
          </DetailCard>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Dodaj"
          icon="add"
          labelPosition="left"
          primary
          loading={creating}
          onClick={handleAddBackorder}
          disabled={!isValidForm() || creating}
        />
        <Button content="Anuluj" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  customers,
  backorders,
  distrigoWarehouses,
}: ApplicationState) => ({
  warehouses: distrigoWarehouses.list,
  customers: customers.list,
  loadingCustomers: customers.loading,
  deliveryAddresses: customers.selected ? customers.selected.deliveryAddresses : [],
  created: backorders.created,
  creating: backorders.creating,
  createdId: backorders.createdId,
});

const mapDispatchToProps: TReduxActions = {
  clearCreated,
  fetchCustomers,
  selectCustomer,
  addBackorder,
  fetchDistrigoWarehouses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBackorder);
