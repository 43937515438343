import { connect } from "react-redux";
import { Button, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { importReferences } from "~/store/spare-parts/actions";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import ExampleXls from "~/assets/images/examples/import-references-stock-xls.png";
import PreviewImage from "~/components/PreviewImage/PreviewImage";
import ModalMessageHeader from "~/components/Modals/ModalMessageHeader";

type TReduxActions = {
  importReferences: typeof importReferences;
};

type TReduxState = {
  importing: boolean;
  imported?: boolean;
};

type TProps = TReduxActions &
  TReduxState & {
    triggerOpen: boolean;
    importSuccess: () => void;
    setOpenImportModal: React.Dispatch<React.SetStateAction<boolean>>;
  };

const ImportReferences: React.FC<TProps> = ({
  triggerOpen,
  importing,
  imported,
  importSuccess,
  importReferences,
  setOpenImportModal,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    if (!open) {
      setOpenImportModal(false);
    }
  }, [open, setOpenImportModal]);

  const handleClose = useCallback((): void => {
    if (!importing) {
      setOpen(false);
    }
  }, [importing, setOpen]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  const handleLoad = useCallback((): void => {
    if (file) {
      importReferences(file);
    }
  }, [file, importReferences]);

  useEffect(() => {
    if (imported) {
      importSuccess();
      handleClose();
    }
  }, [handleClose, imported, importSuccess]);

  return useMemo(
    () => (
      <Modal
        open={open}
        size="small"
        closeOnDimmerClick={false}
        onUnmount={() => setOpen(false)}
      >
        <Header icon="file archive" content="Importowanie referencji" />
        <Modal.Content>
          <ModalMessageHeader format />
          <PreviewImage src={ExampleXls} />
          <Segment placeholder>
            <Header icon>
              <Icon name="file archive outline" />
              {file ? file.name : "Załaduj plik"}
            </Header>
            <Input type="file" onChange={handleFileChange} />
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Wczytaj"
            icon="upload"
            labelPosition="right"
            loading={importing}
            disabled={importing || !file}
            onClick={handleLoad}
          />
          <Button
            content="Zamknij"
            disabled={importing}
            onClick={handleClose}
          />
        </Modal.Actions>
      </Modal>
    ),
    [open, handleClose, handleLoad, importing, file]
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  spareParts,
}: ApplicationState) => {
  return {
    importing: spareParts.importing,
    imported: spareParts.imported,
  };
};

const mapDispatchToProps: TReduxActions = {
  importReferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportReferences);
