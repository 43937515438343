export interface IWarehouse extends Response {
  id?: string;
  sapId?: string;
  sapDeliveryId?: string;
  sapUrgentDeliveryId?: string;
  ddsId?: string;
  ddsDeliveryId?: string;
  name?: string;
  street?: string;
  buildingNumber?: string;
  city?: string;
  zipCode?: string;
  vatId?: string;
  deliveryPlatform?: string;
  deliveryRoute?: string;
  deliveryZone?: string;
  deliveryGroup?: string;
  wmsCode?: string;
  daysOff?: string[];
}

export type TEvent = {
  allDay: boolean;
  start: string | null;
  end: string | null;
  title?: string;
};

export type WarehouseData = {
  res: [
    { daysOff: string[]; id?: string | undefined; name?: string | undefined }
  ];
};

export interface HandleChange {
  [key: string]: string | any;
}

export enum WarehouseActionTypes {
  FETCH_WAREHOUSES = "@@version/FETCH_WAREHOUSES",
  FETCH_WAREHOUSES_SUCCESS = "@@version/FETCH_WAREHOUSES_SUCCESS",
}

export interface WarehousesState {
  readonly loading: boolean;
  readonly loadingWarehouse: boolean;
  readonly updatedDayOff: boolean;
  readonly selectedWarehouse?: {
    title:
      | string
      | number
      | boolean
      | (string | number | boolean)[]
      | undefined;
    daysOff?: string[];
    name?: string;
  };
  readonly warehousesList: {
    res: [{ daysOff: string[]; id?: string; name?: string }];
  };
}
