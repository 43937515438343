import { Table } from 'semantic-ui-react';
import CodingCell from '~/pages/Backorders/Details/lib/CodingCell';
import { TLine } from '~/pages/Orders/lib/Add/constants';
import React from 'react';
import { CodesTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';

const CodesTableCell = ({ line, handleAddCoding, summary }: CodesTableCellProps) => (
  <Table.Cell style={{ maxWidth: 'none' }}>
    {line.codes && (line.orderLineType !== 'REPLACED') !== null && (
      <CodingCell<TLine>
        line={line}
        addCodingHandler={handleAddCoding}
        loading={false}
        editMode={!summary}
        disablePopup={false}
      />
    )}
  </Table.Cell>
);

export default CodesTableCell;
