import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { AccountingCPBreadcrumbs } from "../AccountingClientPanel";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { useCallback, useMemo } from "react";

type Props = {
  setActiveTab: React.Dispatch<React.SetStateAction<AccountingCPBreadcrumbs>>;
  setRefreshTrigger: React.Dispatch<React.SetStateAction<number>>;
  setExportTrigger: React.Dispatch<React.SetStateAction<number>>;
};

export const useAccountingCPBreadcrumbs = (props: Props) => {
  const { setActiveTab } = props;
  const { hasRole } = useClientPanelRoles();

  const setTab = useCallback(
    (tabName: AccountingCPBreadcrumbs) => {
      props.setRefreshTrigger(0);
      props.setExportTrigger(0);
      setTimeout(() => setActiveTab(tabName), 10);
    },
    [props, setActiveTab]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Faktury",
        id: AccountingCPBreadcrumbs.INVOICES,
        clickAction: () => setTab(AccountingCPBreadcrumbs.INVOICES),
      },
      {
        text: "Korekty faktur",
        id: AccountingCPBreadcrumbs.CORRECTIONS,
        clickAction: () => setTab(AccountingCPBreadcrumbs.CORRECTIONS),
      },
      {
        text: "Korekty zbiorcze FV",
        id: AccountingCPBreadcrumbs.COLLECTIVE_INVOICE_CORRECTIONS,
        clickAction: () =>
          setTab(AccountingCPBreadcrumbs.COLLECTIVE_INVOICE_CORRECTIONS),
        visible: hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ACCOUNTING_COLLECTIVE_INVOICE_CORRECTION
        ),
      },
      {
        text: "Limity",
        id: AccountingCPBreadcrumbs.LIMITS,
        clickAction: () => setTab(AccountingCPBreadcrumbs.LIMITS),
      },
    ],
    [setTab, hasRole]
  );

  return breadcrumbs;
};
