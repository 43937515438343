import DetailCard from "~/components/DetailCard/DetailCard";
import React from "react";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { Announcement } from "~/store/annouoncements/types";
import { TNewAnnouncement } from "../../AddAnnouncement/AddAnnouncement";

type TAnnouncementStatusCard = {
  announcement: Announcement | TNewAnnouncement;
  edit: boolean;
};

const AnnouncementStatusCard: React.FC<TAnnouncementStatusCard> = ({
  announcement,
  edit,
}) => {
  const { renderToggle, renderField } = useRenderingFunctions(
    announcement,
    true
  );

  return (
    <DetailCard
      title="app.status"
      id="status"
      key="status"
      width={4}
      smallWidth={5.33}
      style={{ zIndex: 30 }}
    >
      {renderToggle("hidden", {
        label: "Ukryte",
        noSuccesInfo: true,
        readOnly: !edit,
      })}
      {!edit &&
        renderField("author", {
          label: "Autor",
          readOnly: true,
        })}
    </DetailCard>
  );
};

export default AnnouncementStatusCard;
