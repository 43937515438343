import { Form, Radio } from 'semantic-ui-react';
import React from 'react';
import { ForcedPaymentMethodTypeFieldProps } from '~/pages/Orders/lib/Add/Type/constants';

const ForcedPaymentMethodTypeField = ({
  summary,
  selectedCustomer,
  deliveryPoint,
  updateOrder,
  setForced,
}: ForcedPaymentMethodTypeFieldProps) => (
  <Form.Field style={{ marginTop: '30px' }}>
    <label>Wymuś Kredyt Distrigo</label>
    <Radio
      toggle
      type="radio"
      disabled={
        summary || selectedCustomer?.detail.paymentDistrigoCredits?.current === null || deliveryPoint === undefined
      }
      name="forcedPaymentMethodType"
      onChange={(e, d) => {
        updateOrder('forcedPaymentMethodType', d.checked ? 'DISTRIGO_CREDIT' : null);
        setForced(!!d.checked);
      }}
    />
  </Form.Field>
);

export default ForcedPaymentMethodTypeField;
