import React, { useEffect, useState } from "react";
import { TSelectProps } from "~/components/SmartField/lib/types";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { Form, FormFieldProps } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { FormattedMessage } from "react-intl";
import { getSelectIcon } from "~/components/SmartField/lib/utils";

/**
 * Dropdown/Select component. Value will be saved automatically after selection.
 */
export const SmartSelect: React.FC<TSelectProps> = (props) => {

    const {
        currentValue,
        loading,
        error,
        success,
        handleEdit,
        handleSave,
        getPopupContent,
        isRequired
    } = useSmartFieldLogic(props);

    const [selectAction, setSelectAction] = useState(false)

    const handleSpecificEdit = (ev: any, el: FormFieldProps): void => {
        handleEdit(el.value)
        setSelectAction(true)
    }

    useEffect(() => {
        if (selectAction) {
            setSelectAction(false)
            handleSave()
        }
    }, [selectAction, handleSave])

    const { formatLabel } = useLabelFormatter(props)

    return (
        <SmartPopup
            mouseEnterDelay={error ? 0 : 300}
            position="bottom center"
            offset="0, -15px"
            description={getPopupContent()}
        >
            <div className="ui field">
                <Form.Select
                    fluid
                    required={isRequired}
                    style={props.style}
                    label={formatLabel()}
                    value={currentValue}
                    options={props.options}
                    onChange={handleSpecificEdit}
                    loading={loading}
                    error={!!error}
                    icon={getSelectIcon(error, success)}
                    width={props.width}
                    disabled={props.disabled || props.readOnly}
                    className={props.readOnly ? "read-only-disabled" : "super-field"}
                    noResultsMessage={<FormattedMessage id="app.noResultsMessage"/>}
                />
            </div>
        </SmartPopup>
    )
}