import React, { useCallback } from "react";
import { AccountingBreadcrumbs } from "../Accounting";
import { useAccountingRolesRights } from "./useAccountingRolesRights";
import { Grid, Label } from "semantic-ui-react";
import { parseDate } from "~/utils/dateUtils";
import {
  IInvoiceLinesSchedulers,
  IInvoiceSchedulers,
} from "~/store/invoices/types";

type Prosp = {
  handleExportClick: () => void;
  disableExportButton: boolean;
  percenExportButton: number;
  activeTab: AccountingBreadcrumbs;
  gdnPositionsSchedulers: IInvoiceSchedulers;
  invoiceLinesSchedulers: IInvoiceLinesSchedulers;
  setOpenAddCorrectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenBulkCorrectionsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddTransportFVModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useAccountingButtons = (props: Prosp) => {
  const {
    userHaveLdcUiAccountingInvoiceCorrectionRole,
    userHaveLdcUiAccountingCollectiveInvoiceCorrectionRole,
  } = useAccountingRolesRights();

  const toSchedulerGridRow = (deliveryTime: {
    delivery: string;
    time: Date | string | null;
  }): JSX.Element => (
    <Grid.Row key={"toSchedulerGridRow" + deliveryTime.delivery}>
      <Grid.Column width={6}>
        <b>{deliveryTime.delivery}</b>
      </Grid.Column>
      <Grid.Column width={9}>{parseDate(deliveryTime.time)}</Grid.Column>
    </Grid.Row>
  );

  const getSchedulersGrid = useCallback(
    (deliveryTimes: { delivery: string; time: Date | string | null }[]) => (
      <Grid>
        {" "}
        key="getSchedulersGrid"
        <Label attached="top">
          {props.activeTab === 10
            ? "Najbliższe fakturowanie"
            : "Najbliższe generowanie"}
        </Label>
        {deliveryTimes.map(toSchedulerGridRow)}
      </Grid>
    ),
    [props.activeTab]
  );

  const schedulerButton = {
    icon: "clock outline",
    basic: true,
    secondary: true,
    disabled: true,
    labelPosition: undefined,
    style: undefined,
    popup: getSchedulersGrid([
      {
        delivery: "Stock",
        time:
          props.activeTab === AccountingBreadcrumbs.ITEMS_TO_INVOICE
            ? props.gdnPositionsSchedulers.stock
            : props.invoiceLinesSchedulers.stock,
      },
      {
        delivery: "Urgent",
        time:
          props.activeTab === AccountingBreadcrumbs.ITEMS_TO_INVOICE
            ? props.gdnPositionsSchedulers.urgent
            : props.invoiceLinesSchedulers.urgent,
      },
      {
        delivery: "Turbo Pro",
        time:
          props.activeTab === AccountingBreadcrumbs.ITEMS_TO_INVOICE
            ? props.gdnPositionsSchedulers.turboPro
            : props.invoiceLinesSchedulers.turboPro,
      },
    ]),
    visible:
      props.activeTab === AccountingBreadcrumbs.ITEMS_TO_INVOICE ||
      props.activeTab === AccountingBreadcrumbs.SENT100,
  };

  const exportButton = {
    icon: "download",
    content: "Eksport danych",
    primary: true,
    onClick: props.handleExportClick,
    disabled: props.disableExportButton,
    window: props.percenExportButton,
  };

  const addCorrection = {
    icon: "add",
    content: "Korekta faktury",
    onClick: () => props.setOpenAddCorrectionModal(true),
    primary: true,
    visible:
      props.activeTab === AccountingBreadcrumbs.CORRECTIONS &&
      userHaveLdcUiAccountingInvoiceCorrectionRole,
  };

  const addNoteCorrection = {
    icon: "add",
    content: "Korekta noty",
    onClick: () => props.setOpenAddCorrectionModal(true),
    primary: true,
    visible:
      props.activeTab === AccountingBreadcrumbs.DEBIT_NOTES_CORRECTIONS &&
      userHaveLdcUiAccountingInvoiceCorrectionRole,
  };

  const addCollectiveCorrections = {
    icon: "upload",
    content: "Korekty zbiorcze faktur/not",
    onClick: () => props.setOpenBulkCorrectionsModal(true),
    primary: true,
    visible:
      (props.activeTab === AccountingBreadcrumbs.COLLECTIVE_CORRECTIONS_LIST ||
        props.activeTab ===
          AccountingBreadcrumbs.COLLECTIVE_DEBIT_NOTE_CORRECTIONS) &&
      userHaveLdcUiAccountingCollectiveInvoiceCorrectionRole,
  };

  const addInvoiceTransport = {
    icon: "plus",
    content: "Koszty transportu",
    onClick: () => props.setOpenAddTransportFVModal(true),
    primary: true,
    visible: props.activeTab === AccountingBreadcrumbs.SOURCE_DOCUMENTS,
  };

  return [
    schedulerButton,
    exportButton,
    addCorrection,
    addNoteCorrection,
    addCollectiveCorrections,
    addInvoiceTransport,
  ];
};
