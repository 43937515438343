import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { TPromotion } from '~/store/promotions/types'
import { REQUIRED } from '~/components/SmartField/lib/validator'

import React from 'react'
import DetailCard from '~/components/DetailCard/DetailCard'
import MinimumCondition from './lib/Minimum'

type TProps = {
    promotion: TPromotion
    editMode: boolean
}

const CascadePromotion: React.FC<TProps> = ({
    promotion,
    editMode
}) => {
    const { renderField } = useRenderingFunctions(promotion as any, editMode)

    const saveMapper = (v: string) => Number(v) <= 0 ? null : Number(v)

    return (
        <>
            <DetailCard id='threshold-1' title='app.first-fret' width={5.33} minHeight={0}>
                {renderField("firstDiscount.percentageDiscount", { label: "Upust procentowy (%)", type: 'number', saveMapper, min: 0, precision: 2, validators: [REQUIRED] })}
                <MinimumCondition promotion={promotion} editMode={editMode} discountGroup="firstDiscount" />
            </DetailCard>
            <DetailCard id='threshold-2' title='app.second-fret' width={5.33} minHeight={0}>
                {renderField("secondDiscount.percentageDiscount", { label: "Upust procentowy (%)", type: 'number', saveMapper, min: 0, precision: 2 })}
                <MinimumCondition promotion={promotion} editMode={editMode} discountGroup="secondDiscount" />
            </DetailCard>
        </>
    )
}

export default CascadePromotion
