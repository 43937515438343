import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import PageHeader from "~/components/PageHeader/PageHeader";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import { ApplicationState } from "~/store";
import {
  editAnnouncement,
  editAnnouncementRefresh,
  fetchAnnouncementDetails,
} from "~/store/annouoncements/actions";
import {
  Announcement,
  TNewAnnouncementDTO,
} from "~/store/annouoncements/types";
import AnnouncementInfoCard from "./lib/AnnouncementInfoCard";
import { RouteComponentProps } from "react-router-dom";
import AnnouncementStatusCard from "./lib/AnnouncementStatusCard";
import AnnouncementRolesCard from "./lib/AnnouncementRolesCard";
import AnnouncementContentCard from "./lib/AnnouncementContentCard";
import { useAnnouncementsValidation } from "../useAnnouncementsValidation";
import { useAdministrationRights } from "~/pages/Administration/hooks/useAdministrationRights";

type TRouteParams = RouteComponentProps<{
  id: string;
  edit?: string;
}>;

type TPropsFromState = {
  announcementDetails: Announcement | undefined;
  loading: boolean;
  editLoading: boolean;
  editSuccess: boolean;
};
type TReduxActions = {
  fetchAnnouncementDetails: typeof fetchAnnouncementDetails;
  editAnnouncement: typeof editAnnouncement;
  editAnnouncementRefresh: typeof editAnnouncementRefresh;
};

type TProps = TPropsFromState & TReduxActions & TRouteParams;

const AnnouncementDetails: React.FC<TProps> = ({
  match,
  announcementDetails,
  loading,
  editLoading,
  editSuccess,
  fetchAnnouncementDetails,
  editAnnouncement,
  editAnnouncementRefresh,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [announcementForEdit, setAnnouncementForEdit] =
    useState<Announcement>();

  const { isValid, validMessage } = useAnnouncementsValidation({
    announcementForEdit,
  });

  const { userHaveCustomerPanelUiAdvertisingEditRole } =
    useAdministrationRights();

  const handleRefresh = useCallback(() => {
    fetchAnnouncementDetails(match.params.id);
  }, [match.params.id, fetchAnnouncementDetails]);

  useEffect(() => {
    if (editSuccess) {
      setEdit(false);
      editAnnouncementRefresh();
    }
  }, [editAnnouncementRefresh, editSuccess, handleRefresh, setEdit]);

  useEffect(() => {
    if (!announcementDetails || announcementDetails.id !== match.params.id) {
      fetchAnnouncementDetails(match.params.id);
    }
    // eslint-disable-next-line
  }, [match.params.id, fetchAnnouncementDetails]);

  useEffect(() => {
    setAnnouncementForEdit(() => {
      if (!announcementDetails) return;
      let mappedAnnouncement = {
        ...announcementDetails,
        imageOriginal: announcementDetails?.image || undefined,
      } as Announcement & TNewAnnouncementDTO;
      if (mappedAnnouncement && mappedAnnouncement?.content) {
        if (typeof mappedAnnouncement.content === "string") {
          mappedAnnouncement.content = JSON.parse(mappedAnnouncement.content);
        }
      }
      setEdit(false);
      return mappedAnnouncement;
    });
  }, [announcementDetails, match.params.id]);

  const handleSave = useCallback(() => {
    if (!isValid || !announcementForEdit) return;
    editAnnouncement(announcementForEdit);
  }, [announcementForEdit, editAnnouncement, isValid]);

  return (
    <Fragment>
      <SmartWrapper model={announcementForEdit}>
        <PageHeader
          icon="bullhorn"
          title={"Ogłoszenia"}
          breadcrumb={[
            {
              text: "Lista",
              link: "/administration",
              clickAction: () => setEdit(false),
            },
            {
              text: "Szczegóły",
            },
          ]}
          buttons={[
            {
              icon: "edit",
              content: edit ? "wył. edycję" : "Edytuj",
              primary: !edit,
              onClick: () => {
                setEdit(!edit);
                edit && handleRefresh();
              },
              visible: userHaveCustomerPanelUiAdvertisingEditRole,
            },
            {
              icon: "save",
              content: "Zapisz",
              primary: true,
              popup: validMessage,
              onClick: handleSave,
              visible: edit,
              disabled: !isValid,
            },
          ]}
          loading={loading || editLoading}
          refreshAction={() => handleRefresh()}
        />
        <div className="uber-content overflow-visible">
          <Grid stretched>
            {announcementForEdit ? (
              <>
                <AnnouncementInfoCard
                  edit={edit}
                  announcement={announcementForEdit}
                  setAnnouncementForEdit={setAnnouncementForEdit}
                />
                <AnnouncementStatusCard
                  edit={edit}
                  announcement={announcementForEdit}
                />
                <AnnouncementRolesCard
                  edit={edit}
                  announcement={announcementForEdit}
                  setAnnouncementForEdit={setAnnouncementForEdit}
                />
                <AnnouncementContentCard
                  edit={edit}
                  announcement={announcementForEdit}
                  setNewAnnouncement={setAnnouncementForEdit}
                />
              </>
            ) : null}
          </Grid>
        </div>
      </SmartWrapper>
    </Fragment>
  );
};

const mapStateToProps = ({ announcements }: ApplicationState) => ({
  announcementDetails: announcements.announcementDetails,
  loading: announcements.loadingAnnouncementDetails,
  editSuccess: announcements.editSuccess,
  editLoading: announcements.editLoading,
});

const mapDispatchToProps = {
  fetchAnnouncementDetails,
  editAnnouncementRefresh,
  editAnnouncement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementDetails);
