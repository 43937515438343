import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import MoveWareContent from './MoveWareContent/MoveWareContent';
import { confirmMovingWares, fetchDistrigoWarehouses } from '~/store/distrigo-warehouses/actions';
import { TWareLine } from '~/store/distrigo-warehouses/types';
import { toastInfo } from '~/utils/toast';

type TReduxActions = {
    confirmMovingWares: typeof confirmMovingWares,
    fetchDistrigoWarehouses: typeof fetchDistrigoWarehouses,
}

type MoveWareProps = {
    closeMoveWareModal: () => void,
    openMoveWareModal: boolean,
}

type TMoveWare = MoveWareProps & TReduxActions 

const MoveWare = ({ closeMoveWareModal, confirmMovingWares, fetchDistrigoWarehouses, openMoveWareModal }: TMoveWare) => {
    const [deliveryWareValue, setDeliveryWareValue] = useState<string | number | boolean | (string | number | boolean)[] | undefined>("");
    const [receiveWareValue, setReceiveWareValue] = useState<string | number | boolean | (string | number | boolean)[] | undefined>("");
    const [open, setOpen] = useState<boolean>(openMoveWareModal);
    const [cancel, setCancel] = useState<boolean>(false);
    const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [lines, setLines] = useState<TWareLine[]>([]);
    const [maximized, setMaximized] = useState<boolean>(false);
    const tableEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchDistrigoWarehouses();
    }, [fetchDistrigoWarehouses]);

    useEffect(() => {
        if (openMoveWareModal) {
            setOpen(true)
        }
    }, [openMoveWareModal]);

    const handleDeliveryWarehouseChange = useCallback((e: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => {
        setDeliveryWareValue(data);
    }, []);

    const handleReceiveWarehouseChange = useCallback((e: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => {
        setReceiveWareValue(data);
    }, []);

    const toggleMaximized = useCallback((): void => {
        setMaximized(!maximized);
    }, [maximized]);

    const clearDeliveryWareValue = useCallback((e: SyntheticEvent) => {
        if (deliveryWareValue) {
            setDeliveryWareValue("");
        }
        setLines([])
    }, [deliveryWareValue]);

    const clearReceiveWareValue = useCallback((e: SyntheticEvent) => {
        if (receiveWareValue) {
            setReceiveWareValue("");
        }
        setLines([])
    }, [receiveWareValue]);

    const handleSubmit = useCallback(() => {
        confirmMovingWares({
            supplierID: String(deliveryWareValue),
            recipientID: String(receiveWareValue),
            parts: lines,
            additionalInfo: ""
        });
        setCancel(true);
    }, [deliveryWareValue, lines, receiveWareValue, confirmMovingWares]);

    const validNumber = useCallback((value: number): boolean => {
        return isNaN(value) ? false : typeof value === 'number' && String(value) !== '0'
    }, []);

    useEffect(() => {
        if (cancel) {
            setOpenConfirmModal(true);
        }
    }, [cancel]);

    const updateIndexing =   useCallback((updatedlines: TWareLine[]): void => {
        updatedlines.forEach((line, index) => line.lineNumber = index + 1);
        setLines(updatedlines);
    },[])

    const getProvision = useCallback((lineNumber: number, lines: TWareLine[]): TWareLine | undefined => {
        const updatingLine = lines.find(line => line.lineNumber === lineNumber)
        if (updatingLine) {
            return lines.find(line => line.partID === updatingLine.partID);
        }
    }, []);

    const getReplacements = useCallback((lineNumber: number): TWareLine[] => {
        const updatingLine = lines.find(line => line.lineNumber === lineNumber);
        if (updatingLine) {
            const replacements = lines.filter(line => line.partID === updatingLine.partID);
            return [...replacements];
        }
        return []
    }, [lines]);

    const linesCopy = useCallback((): any[] => {
        return lines.map(line => ({ ...line }));
    }, [lines]);

    const changeQuantity = useCallback((lineNumber: number, currentValue: number, round: boolean): void => {
        const lines = linesCopy()
        const lineToUpdate = lines.find(line => line.lineNumber === lineNumber);
        if (lineToUpdate) {
            let value = Number(currentValue)
            if (round) {
                const step = lineToUpdate.packingForceQuantity || 1
                if (value <= 0) {
                    value = step;
                } else {
                    value = Math.round(value / step) * step;
                }
            }
            lineToUpdate.quantity = value;
            const provision = getProvision(lineNumber, lines);
            if (provision) {
                provision.quantity = value;
            }
        }
        setLines(lines);
    }, [ getProvision, linesCopy]);

    const changeReferenceId = useCallback((lineNumber: number, data: TWareLine): string => {
        const found = data.item.referenceWarehouseId.referenceId;
        const newLines: TWareLine[] = []
        let isPartOnLine = false
        if (found) {
            if (lines.find(line => line.partID === found)) {
                toastInfo("Część została już dodana", "Wybrana część znajduje się już w zamówieniu.");
                return ''
            }
            if (found) {
                Object.entries(found).forEach(([key, val]) => {
                    if (lines.find(line => line.partID === val)) {
                        toastInfo(`Część została już dodana`, `Zamiennik ${val} znajduje się już w zamówieniu.`);
                        isPartOnLine = true;
                    }
                })
            }
            if (!isPartOnLine) {
                lines.forEach(line => {
                    if (line.lineNumber !== lineNumber) {
                        newLines.push({ ...line })
                    } else {
                        newLines.push({
                            ...line,
                            partID: found,
                            available: data.item.available
                        });
                    }
                })
                updateIndexing(newLines);
                setLines(newLines);
                return found;
            }
        }
        return ''
    }, [updateIndexing, lines]);

    const removeLine = useCallback((lineIn: TWareLine): void => {
        const replacements = getReplacements(lineIn.lineNumber).map(line => line.lineNumber)
        const provisions = [getProvision(lineIn.lineNumber, lines), ...replacements.map(lineNo => getProvision(Number(lineNo), lines))]
            .filter(line => !!line)
            .map(line => (line && line.lineNumber ? line.lineNumber : []))
        const newLines = linesCopy().filter(line => !(
            line.lineNumber === lineIn.lineNumber ||
            replacements.indexOf(line.lineNumber) !== -1 ||
            provisions.indexOf(line.lineNumber) !== -1
        ));
        changeReferenceId(lineIn.lineNumber, lineIn);
        changeQuantity(lineIn.lineNumber, Number(lineIn.quantity), true);
        setLines(newLines);
        updateIndexing(newLines);
    }, [lines, setLines, updateIndexing, changeQuantity, changeReferenceId, getProvision, getReplacements, linesCopy]);

    const addLine = useCallback((): void => {
        if (lines.length > 0 && !lines[lines.length - 1].partID) {
            return
        };
        const newLines = [...lines]
        newLines.push({
            item: { referenceWarehouseId: {} }, lineNumber: !lines.length ? 1 : lines[lines.length - 1].lineNumber + 1,
            partID: undefined, quantity: undefined, referenceWarehouseId: {}
        });
        setLines(newLines);
    }, [lines]);

    if (confirmCancel) {
        return <Redirect to='/stock-products/PL9999P' />
    }

    return <MoveWareContent
        addLine={addLine}
        changeQuantity={changeQuantity}
        changeReferenceId={changeReferenceId}
        clearDeliveryWareValue={clearDeliveryWareValue}
        clearReceiveWareValue={clearReceiveWareValue}
        closeMoveWareModal={closeMoveWareModal}
        confirmCancel={confirmCancel}
        deliveryWareValue={deliveryWareValue}
        handleDeliveryWarehouseChange={handleDeliveryWarehouseChange}
        handleReceiveWarehouseChange={handleReceiveWarehouseChange}
        handleSubmit={handleSubmit}
        lines={lines}
        maximized={maximized}
        open={open}
        openConfirmModal={openConfirmModal}
        openMoveWareModal={openMoveWareModal}
        setCancel={setCancel}
        setConfirmCancel={setConfirmCancel}
        setOpenConfirmModal={setOpenConfirmModal}
        receiveWareValue={receiveWareValue}
        removeLine={removeLine}
        tableEl={tableEl}
        toggleMaximized={toggleMaximized}
        validNumber={validNumber}
    />
}

const mapDispatchToProps: TReduxActions = {
    confirmMovingWares, fetchDistrigoWarehouses, 
}

export default connect(null, mapDispatchToProps)(MoveWare);
