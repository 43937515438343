import { useMemo } from "react";
import { TOrderColumn } from ".";

export const useLinesColumns = ({
  isClientPanelUser,
}: {
  isClientPanelUser: boolean;
}) => {
  const columns = useMemo(
    (): TOrderColumn[] => [
      { name: "selected", label: " ", width: 50 },
      { name: "lineNo", label: "Nr", width: 50 },
      { name: "referenceID", label: "Referencja" },
      { name: "quantity", label: "Ilość", width: 120 },
      { name: "codes", label: "Kodowanie", width: 140 },
      { name: "description", label: "Nazwa" },
      { name: "packagingQuantity", label: "UC", width: 60 },
      { name: "stock", label: "Stock", width: 60 },
      { name: "stockLevel", label: "Stany magazynowe", width: 60 },
      {
        name: "netPriceListPrice",
        label: "Cena PVP",
        width: 120,
        textAlign: "right",
      },
      { name: "pricePNR", label: "Cena PNR", width: 120, textAlign: "right" },
      { name: "priceReductionDiscountCode", label: "Kod rabatu", width: 60 },
      { name: "bonus", label: "Rabat %", width: 120 },
      { name: "priceDRZ", label: "Wartość DRZ", width: 120 },
      {
        name: "netPrice",
        label: "Cena sprzedaży",
        width: 120,
        textAlign: "right",
      },
      {
        name: "totalNetPrice",
        label: "Wartość netto",
        width: 120,
        textAlign: "right",
      },
      { name: "totalTaxValue", label: "VAT", width: 120, textAlign: "right" },
      {
        name: "totalGrossPrice",
        label: "Wartość brutto",
        width: 120,
        textAlign: "right",
      },
      { name: "promoCode", label: "Promocja", width: 120 },
      {
        name: "rejectedQuantity",
        label: "Distrigo odrzucone",
        textAlign: "right",
      },
      {
        name: "rejectionReasons",
        label: "Przyczyna odrzucenia",
        textAlign: "right",
      },
      {
        name: "awaitingForReplenishment",
        label: "Oczekujące na replenishment",
        textAlign: "right",
      },
      { name: "packageNumbers", label: "Numery paczek", width: 120 },
      {
        name: "confirmedQuantity",
        label: "Distrigo potwierdzone",
        textAlign: "right",
      },
      {
        name: "orderedCrossDock",
        label: "Cross dock zlecone",
        textAlign: "right",
      },
      {
        name: "confirmedCrossDock",
        label: "Cross dock potwierdzone",
        textAlign: "right",
      },
      {
        name: "manualOrderDocument",
        label: "Zam. manualne zlecone",
        textAlign: "right",
      },
      {
        name: "backorderReplenishmentDocuments",
        label: "Zamówienia hurtowe Distrigo",
        textAlign: "right",
      },
      { name: "sapInvoices", label: "Faktury SAP", textAlign: "right" },
      { name: "invoiceIdNumbers", label: "Faktury LDC", textAlign: "right" },
    ],
    []
  );

  const columnsClientPanel = useMemo(
    (): TOrderColumn[] => [
      { name: "selected", label: " ", width: 50 },
      { name: "lineNo", label: "Nr", width: 50 },
      { name: "referenceID", label: "Referencja" },
      { name: "quantity", label: "Ilość", width: 120 },
      { name: "codes", label: "Kodowanie", width: 140 },
      { name: "description", label: "Nazwa" },
      { name: "packagingQuantity", label: "UC", width: 60 },
      { name: "stock", label: "Stock", width: 60 },
      { name: "stockLevel", label: "Stany magazynowe", width: 60 },
      {
        name: "netPriceListPrice",
        label: "Cena PVP",
        width: 120,
        textAlign: "right",
      },
      { name: "pricePNR", label: "Cena PNR", width: 120, textAlign: "right" },
      { name: "priceReductionDiscountCode", label: "Kod rabatu", width: 60 },
      { name: "bonus", label: "Rabat %", width: 120 },
      { name: "priceDRZ", label: "Wartość DRZ", width: 120 },
      {
        name: "netPrice",
        label: "Cena sprzedaży",
        width: 120,
        textAlign: "right",
      },
      {
        name: "totalNetPrice",
        label: "Wartość netto",
        width: 120,
        textAlign: "right",
      },
      { name: "totalTaxValue", label: "VAT", width: 120, textAlign: "right" },
      {
        name: "totalGrossPrice",
        label: "Wartość brutto",
        width: 120,
        textAlign: "right",
      },
      { name: "promoCode", label: "Promocja", width: 120 },
      {
        name: "rejectedQuantity",
        label: "Distrigo odrzucone",
        textAlign: "right",
      },
      {
        name: "rejectionReasons",
        label: "Przyczyna odrzucenia",
        textAlign: "right",
      },

      { name: "packageNumbers", label: "Numery paczek", width: 120 },
      {
        name: "confirmedQuantity",
        label: "Distrigo potwierdzone",
        textAlign: "right",
      },
      {
        name: "orderedCrossDock",
        label: "Cross dock zlecone",
        textAlign: "right",
      },
      { name: "invoiceIdNumbers", label: "Faktury LDC", textAlign: "right" },
    ],
    []
  );

  return isClientPanelUser ? columnsClientPanel : columns;
};
