import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useInternalCompsumptionsColumns = () => {
  const { getDateCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "number",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "issueDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "warehouseName",
        label: "Magazyn",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "consumedPartsCount",
        label: "Liczba linii",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Numer zamówienia",
        projection: false,
        hide: true,
      },
    ],
    [getDateCell, getTextCell]
  );

  return { columnsConfig };
};
