import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useGoodsReceivedNotesColumns = () => {
  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "goodsReceivedNoteID",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "creationDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "issueDate",
        label: "Data rozwiązania",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "recipientWarehouseName",
        label: "Magazyn",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderNo",
        label: "Zamówienie",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "recipientID",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "recipientWarehouseID",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "type",
        dictionaryName: DictionaryName.grnType,
        label: "Typ",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        noKey: true,
      },
      {
        name: "status",
        dictionaryName: DictionaryName.warehouseMovementStatus,
        label: "Status",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        noKey: true,
      },
      {
        name: "receivedLinesCount",
        label: "Liczba linii",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "expectedPartsCount",
        label: "Do realizacji",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "realizedPartsCount",
        label: "WMS Potwierdzone",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Numer zamówienia",
        getCell: (document, column) => getTextCell(document, column),
        projection: false,
        hide: true,
      },
    ],
    [getDateCell, getDictionaryCell, getTextCell]
  );

  return { columnsConfig };
};
