import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { HierarchyActionTypes, HierarchyState } from './types'

export const initialHierarchyState: HierarchyState = {
    fetched: [],
    parent: undefined,
    failed: false,
    advancedSearchResponseGroup1: [],
    advancedSearchResponseGroup2: [],
}

const reducer: Reducer<HierarchyState> = (state = initialHierarchyState, action) => {
    switch (action.type) {
        case HierarchyActionTypes.FETCH_SEGMENTS:
        case HierarchyActionTypes.FETCH_SLOTS:
        case HierarchyActionTypes.FETCH_FAMILIES:
        case HierarchyActionTypes.FETCH_REFERENCES: {
            return { ...state, failed: false }
        }
        case HierarchyActionTypes.FETCH_SUCCESS: {
            return { ...state, failed: false, parent: action.parent, fetched: action.fetched }
        }
        case HierarchyActionTypes.ADVANCED_SEARCH_FETCH_GROUP_1_SUCCESS: {
            return { ...state, failed: false, advancedSearchResponseGroup1: action.payload }
        }
        case HierarchyActionTypes.ADVANCED_SEARCH_FETCH_GROUP_2_SUCCESS: {
            return { ...state, failed: false, advancedSearchResponseGroup2: action.payload }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, failed: true, fetched: [], parent: undefined }
        }
        default: {
            return state
        }
    }
}

export { reducer as hierarchyReducer }
