import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Header, Modal, Table } from 'semantic-ui-react';
import { TClaimHistory } from '~/store/claims/types';
import { parseDate } from '~/utils/dateUtils';
import { DictionaryLabel } from '~/components/MapDictionary/DictionaryLabel';

type TProps = {
  triggerOpen: boolean;
  history: TClaimHistory[];
  dictionary: any;
};

const ClaimHistory: React.FC<TProps> = ({ children, triggerOpen, history, dictionary }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const historyLines = useCallback((): JSX.Element[] => {
    return history
      .sort((a: any, b: any) => a - b)
      .map((element, index) => {
        return (
          <Table.Row key={index}>
            <Table.Cell>
              <DictionaryLabel flexDisplay={true} value={element.status} dictionary={dictionary} />
            </Table.Cell>
            <Table.Cell>{parseDate(element.timestamp)}</Table.Cell>
            <Table.Cell>{element.note}</Table.Cell>
          </Table.Row>
        );
      });
  }, [dictionary, history]);

  const renderHistory: JSX.Element = useMemo(() => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Znacznik czasowy</Table.HeaderCell>
            <Table.HeaderCell>Komentarz</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{historyLines()}</Table.Body>
      </Table>
    );
  }, [historyLines]);

  const handleCancel = (): void => setOpen(false);

  const handleOpen = (): void => setOpen(true);

  return (
    <Modal trigger={children} open={open} onOpen={handleOpen} onClose={handleCancel} closeOnDimmerClick={false}>
      <Header icon="history" content="Historia" />
      <Modal.Content>{renderHistory}</Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

export default ClaimHistory;
