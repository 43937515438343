import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { MaximizedPopupProps } from '~/pages/Orders/lib/Add/constants';

const MaximizedPopup = ({ maximized, toggleMaximized }: MaximizedPopupProps) => (
  <Popup
    trigger={
      <Button
        circular
        basic
        icon={maximized ? 'window restore outline' : 'expand arrows alternate'}
        onClick={toggleMaximized}
      />
    }
    position="bottom right"
    content={maximized ? 'Minimalizuj widok tabeli' : 'Maksymalizuj widok tabeli'}
  />
);

export default MaximizedPopup;
