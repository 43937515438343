import { action } from "typesafe-actions";
import {
  Announcement,
  AnnouncementsActionTypes,
  TNewAnnouncementDTO,
} from "./types";
import { Paginable } from "../types";

export const fetchAnnouncements = (params?: any) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS, params);
export const fetchAnnouncementsSuccess = (announcements: Announcement[]) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS, announcements);
export const fetchAnnouncementsPartsError = (error: any) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS_ERROR, error);

export const fetchAnnouncementImage = (params?: any) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS, params);

export const fetchAnnouncementList = (params?: any) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST, params);
export const fetchAnnouncementListSuccess = (
  announcements: Paginable<Announcement>
) =>
  action(
    AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST_SUCCESS,
    announcements
  );
export const fetchAnnouncementListError = () =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST_ERROR);

export const fetchAnnouncementDetails = (id: string) =>
  action(AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_DETAILS, id);
export const fetchAnnouncementDetailsSuccess = (announcement: Announcement) =>
  action(
    AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_DETAILS_SUCCESS,
    announcement
  );
export const uploadAnnouncementStart = () =>
  action(AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT_START);
export const uploadAnnouncement = (announcement: TNewAnnouncementDTO) =>
  action(AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT, announcement);
export const uploadAnnouncementSuccess = (id: string) =>
  action(AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT_SUCCESS, id);

export const removeAnnouncementRefresh = () =>
  action(AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT_REFRESH);
export const removeAnnouncement = (announcementId: string) =>
  action(AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT, announcementId);
export const removeAnnouncementSuccess = () =>
  action(AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT_SUCCESS);

export const showAnnouncement = (announcementId: string) =>
  action(AnnouncementsActionTypes.SHOW_ANNOUNCEMENT, announcementId);
export const hideAnnouncement = (announcementId: string) =>
  action(AnnouncementsActionTypes.HIDE_ANNOUNCEMENT, announcementId);

export const editAnnouncement = (announcement: Announcement) =>
  action(AnnouncementsActionTypes.EDIT_ANNOUNCEMENT, announcement);
export const editAnnouncementSuccess = () =>
  action(AnnouncementsActionTypes.EDIT_ANNOUNCEMENT_SUCCESS);
export const editAnnouncementRefresh = () =>
  action(AnnouncementsActionTypes.EDIT_ANNOUNCEMENT_REFRESH);
