import {
  AVAILABLE_ROLE_ENUM,
  AvailableRole,
  AvailableRolesInGroups,
} from "~/store/users/types";
import {
  fetchUserRoles,
  fetchAvailableRoles,
  fetchUserById,
  resetUser,
  changeUserRolesAndLimit,
} from "~/store/users/actions";
import { ComponentProps } from "react";
import { selectCustomer } from "~/store/customer/actions";
import { CustomerSelected } from "~/store/customer/types";

export type TReduxState = {
  availableRoles: AvailableRolesInGroups;
  userRoles: AvailableRolesInGroups;
  loading: boolean;
  loadingAvailableRoles: boolean;
  loadingUserRoles: boolean;
  loadingChangeUserRolesAndLimit: boolean;
  loadingUser: boolean;
  selectedCustomer: CustomerSelected | undefined;
};

export type TReduxActions = {
  fetchAvailableRoles: typeof fetchAvailableRoles;
  fetchUserRoles: typeof fetchUserRoles;
  fetchUserById: typeof fetchUserById;
  resetUser: typeof resetUser;
  changeUserRolesAndLimit: typeof changeUserRolesAndLimit;
  selectCustomer: typeof selectCustomer;
};

export type TProps = {
  userId: string;
  userName: string;
};

export type TAddContractorProps = TReduxState &
  TReduxActions &
  TProps &
  ComponentProps<any>;

export const VIEW = "VIEW";

export interface ExtendedRole extends AvailableRole {
  isToAdd: boolean;
  isToRemove: boolean;
  isSelected: boolean;
}

export type ExtendedRoleGroup = {
  [key in AVAILABLE_ROLE_ENUM]: ExtendedRole[];
};
