export enum ClaimGroup {
  "unset" = "",
  "realtedToInvoice" = "RK1",
  "notRealtedToInvoice" = "RK2",
  "RK3" = "RK3",
  "provision" = "RK4",
  "withoutInvoice" = "RK5",
}

export enum ClaimCategory {
  "quantityClaim" = "RK1_ZNRE",
  "qualityClaim" = "RK1_ZRDP",
  "newPartReturn" = "RK1_ZRPN",
  "transportClaim" = "RK1_ZRVE",
  "surplusWithoutReturn" = "RK2_ZRNG",
  "surplusWithReturn" = "RK2_ZRNF",
  "lackClaim" = "RK3_Z1",
  "orderIntention" = "RK3_Z2",
  "depositReturn" = "RK4_P1",
  "tractionBatteryReturn" = "RK4_P2",
  "coreWithoutInvoice" = "RK5_P1",
  "batteryWithoutInvoice" = "RK5_B1",
  "unset" = "",
}

export const allowedForEmployeeProcess = [
  ClaimCategory.qualityClaim,
  ClaimCategory.qualityClaim,
  ClaimCategory.transportClaim,
];

export enum SearchType {
  documentMask = "documentMask",
  packageNumber = "packageNumber",
}

export enum CostCoveringGroup {
  distrigo = "DISTRIGO_BOK",
  warehouse = "WAREHOUSE",
  transport = "TRANSPORT",
}
