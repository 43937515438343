import { Reducer } from 'redux'
import { ErrorActionTypes, TErrorsState } from './types'

export const initialErrorsState: TErrorsState = []

const reducer: Reducer<TErrorsState> = (state = initialErrorsState, action) => {
    switch (action.type) {
        case ErrorActionTypes.HANDLE_ERROR: {
            return [...state, action.payload]
        }
        case ErrorActionTypes.CLEAR_ERRORS: {
            return []
        }
        default: {
            return state
        }
    }
}

export { reducer as errorsReducer };

