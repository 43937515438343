import React, { useCallback, useMemo } from "react";
import { Label, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import StorageLimitCell from "~/pages/SpareParts/Cell/StorageLimitCell";
import { SparePart } from "~/store/spare-parts/types";

export const useSparePartsColumns = () => {
  const { getAmountCell, getDateCell, getDictionaryCell, getTextCell } =
    useCellsRenderer();

  const getStorageLimitCell = useCallback(
    (sparePart: SparePart) => (
      <Table.Cell key={"storageLimit"}>
        <StorageLimitCell storageLimit={sparePart.storageLimit} />
      </Table.Cell>
    ),
    []
  );

  const priceListTypeDictionary: DictionaryItem[] = useMemo(
    () => [
      { key: "PCD", value: "PCD", text: "PCD" },
      { key: "IAM", value: "IAM", text: "IAM" },
      { key: "OV", value: "OV", text: "OV" },
      { key: "FCA", value: "FCA", text: "FCA" },
    ],
    []
  );

  const typeMap: { [key: string]: string } = useMemo(
    () => ({
      PCD: "#C34711",
      IAM: "#CC8700",
      OV: "#4D4D4D",
    }),
    []
  );

  const gtuCodesDictionary = useMemo(
    () =>
      dictionariesStatic.GTU_CODES.map((dict) =>
        dict.key === "NONE"
          ? { key: "null", value: "null", text: "BRAK" }
          : { ...dict }
      ),
    []
  );

  const getPriceListTypeCell = useMemo(
    () =>
      (priceListType: string): JSX.Element => {
        const color = typeMap[priceListType] || "black";
        return (
          <Table.Cell key={Math.random() + "priceListType"}>
            <Label basic size="tiny" style={{ borderColor: color, color }}>
              {priceListType}
            </Label>
          </Table.Cell>
        );
      },
    [typeMap]
  );
  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "priceListType",
        dictionary: priceListTypeDictionary,
        label: "Cennik",
        projection: true,
        getCell: (document: any) =>
          getPriceListTypeCell(document.priceListType),
      },
      {
        name: "referenceId",
        label: "Referencja",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "designationPolish",
        label: "Nazwa",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "description",
        label: "Opis",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingGtin",
        label: "GTIN",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "categoryOrigin",
        dictionaryName: DictionaryName.sparePartCategoryOrigin,
        label: "Źródło",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceSelling",
        label: "Cena netto (PVP)",
        projection: true,
        priceColumn: true,
        getCell: (document: any, column: TColumn) =>
          getAmountCell(document, column),
      },
      {
        name: "pricePNR",
        label: "Cena netto (PNR) z upustem",
        projection: true,
        priceColumn: true,
        getCell: (document: any, column: TColumn) =>
          getAmountCell(document, column),
      },
      {
        name: "priceTax",
        dictionaryName: DictionaryName.sparePartTaxCategory,
        label: "VAT",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceReductionDiscountPcd",
        dictionaryName: DictionaryName.sparePartDiscountPcd,
        label: "Upust PCD",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceReductionDiscountFca",
        dictionaryName: DictionaryName.sparePartDiscountFca,
        label: "Upust FCA",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceReductionDiscountIam",
        dictionaryName: DictionaryName.sparePartDiscountIam,
        label: "Upust IAM",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "packingForceQuantity",
        label: "UV",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingQuantity",
        label: "UC",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "thresholdStop",
        label: "Limit",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "categoryAdv",
        dictionaryName: DictionaryName.sparePartCategoryAdv,
        label: "Status ADV",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "state",
        dictionaryName: DictionaryName.sparePartState,
        label: "Status Distrigo",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "provisionForceCategory",
        dictionaryName: DictionaryName.sparePartProvisionForceCategory,
        label: "Status kaucji",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "provisionCategory",
        dictionaryName: DictionaryName.sparePartProvisionCategory,
        label: "Status wymiany",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchyIndex",
        dictionaryName: DictionaryName.sparePartHierarchyIndex,
        label: "Indeks",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchySegment",
        dictionaryName: DictionaryName.sparePartHierarchySegment,
        label: "Segment",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchySlot",
        dictionaryName: DictionaryName.sparePartHierarchySlot,
        label: "Slot",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchyFamily",
        dictionaryName: DictionaryName.sparePartHierarchyFamily,
        label: "Rodzina MKT",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "businessBrand",
        dictionaryName: DictionaryName.sparePartBrand,
        label: "Zastosowanie",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "replacementCategory",
        dictionaryName: DictionaryName.spareParReplacementCategory,
        label: "Zamienność",
        projection: true,
        undefinedOption: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryIdentifier",
        label: "Kodowanie",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "iamCode",
        label: "Kod IAM",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "storageLimit",
        label: "Limit składowania",
        noKey: true,
        dictionary: dictionariesStatic.STORAGE_LIMIT,
        projection: true,
        getCell: (document) => getStorageLimitCell(document),
      },
      {
        name: "storageDuration",
        label: "Okres składowania",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingCtu",
        label: "CTU",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsLength",
        label: "Długość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsWidth",
        label: "Szerokość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsHeight",
        label: "Wysokość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsVolume",
        label: "Objętość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreName",
        label: "Opony Nazwa",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreCategory",
        label: "Opony Kategoria",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersFuelEfficiency",
        label: "Opony Wydajność",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersRoadHolding",
        label: "Opony Przyczepność",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersDrivingNoise",
        label: "Opony Hałas",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersSoundClass",
        label: "Opony Klasa hałasu",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreDimensionsSection",
        label: "Opony Szerokość",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreDimensionsHeight",
        label: "Opony Profil",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreDimensionsDiameter",
        label: "Opony Średnica",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersLoadIndex",
        label: "Opony Nośność",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "tyreParametersSpeedRating",
        label: "Opony Prędkość",
        projection: false,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "updateTime",
        label: "Aktualizacja cennika",
        projection: true,
        type: "date",
        getCell: (document: any, column: TColumn) =>
          getDateCell(document, column, true),
      },
      {
        name: "gtuCode",
        label: "GTU",
        dictionary: gtuCodesDictionary,
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
    ],
    [
      getAmountCell,
      getDateCell,
      getDictionaryCell,
      getTextCell,
      getPriceListTypeCell,
      gtuCodesDictionary,
      priceListTypeDictionary,
      getStorageLimitCell,
    ]
  );

  const columnsConfigClientPanel: TColumn[] = useMemo(
    () => [
      {
        name: "priceListType",
        dictionary: priceListTypeDictionary,
        label: "Cennik",
        projection: true,
        disableSort: true,
        getCell: (document: any) =>
          getPriceListTypeCell(document.priceListType),
      },
      {
        name: "referenceId",
        disableSort: true,
        label: "Referencja",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "designationPolish",
        disableSort: true,
        label: "Nazwa",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "description",
        disableSort: true,
        label: "Opis",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingGtin",
        disableSort: true,
        label: "GTIN",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "categoryOrigin",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartCategoryOrigin,
        label: "Źródło",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceSelling",
        disableSort: true,
        label: "Cena netto (PVP)",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getAmountCell(document, column),
      },
      {
        name: "pricePNR",
        disableSort: true,
        label: "Cena netto (PNR) z upustem",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getAmountCell(document, column),
      },
      {
        name: "priceTax",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartTaxCategory,
        label: "VAT",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "priceReductionDiscount",
        disableSort: true,
        label: "Upust",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingForceQuantity",
        disableSort: true,
        label: "UV",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingQuantity",
        disableSort: true,
        label: "UC",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "thresholdStop",
        disableSort: true,
        label: "Limit",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "categoryAdv",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartCategoryAdv,
        label: "Status ADV",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "state",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartState,
        label: "Status Distrigo",
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "provisionForceCategory",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartProvisionForceCategory,
        label: "Status kaucji",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "provisionCategory",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartProvisionCategory,
        label: "Status wymiany",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchyIndex",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartHierarchyIndex,
        label: "Indeks",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchySegment",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartHierarchySegment,
        label: "Segment",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchySlot",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartHierarchySlot,
        label: "Slot",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryHierarchyFamily",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartHierarchyFamily,
        label: "Rodzina MKT",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "businessBrand",
        disableSort: true,
        dictionaryName: DictionaryName.sparePartBrand,
        label: "Zastosowanie",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "replacementCategory",
        disableSort: true,
        dictionaryName: DictionaryName.spareParReplacementCategory,
        label: "Zamienność",
        projection: true,
        undefinedOption: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
      {
        name: "categoryIdentifier",
        disableSort: true,
        label: "Kodowanie",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "iamCode",
        disableSort: true,
        label: "Kod IAM",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "storageLimit",
        disableSort: true,
        label: "Limit składowania",
        noKey: true,
        dictionary: dictionariesStatic.STORAGE_LIMIT,
        projection: true,
        getCell: (document) => getStorageLimitCell(document),
      },
      {
        name: "storageDuration",
        disableSort: true,
        label: "Okres składowania",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingCtu",
        disableSort: true,
        label: "CTU",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsLength",
        disableSort: true,
        label: "Długość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsWidth",
        disableSort: true,
        label: "Szerokość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsHeight",
        disableSort: true,
        label: "Wysokość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "packingDimensionsVolume",
        disableSort: true,
        label: "Objętość",
        projection: true,
        getCell: (document: any, column: TColumn) =>
          getTextCell(document, column),
      },
      {
        name: "updateTime",
        disableSort: true,
        label: "Aktualizacja cennika",
        projection: true,
        type: "date",
        getCell: (document: any, column: TColumn) =>
          getDateCell(document, column, true),
      },
      {
        name: "gtuCode",
        disableSort: true,
        label: "GTU",
        dictionary: gtuCodesDictionary,
        projection: true,
        noKey: true,
        getCell: (document: any, column: TColumn) =>
          getDictionaryCell(document, column),
      },
    ],
    [
      getAmountCell,
      getDateCell,
      getDictionaryCell,
      getTextCell,
      getPriceListTypeCell,
      gtuCodesDictionary,
      priceListTypeDictionary,
      getStorageLimitCell,
    ]
  );
  return { columnsConfig, columnsConfigClientPanel };
};
