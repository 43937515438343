import React, {
  ChangeEvent,
  LegacyRef,
  SyntheticEvent,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Dropdown,
  DropdownProps,
  Icon,
  Popup,
  Table,
} from "semantic-ui-react";
import {
  FormInputNumber,
  InputNumberOnChangeData,
} from "~/components/InputNumber/InputNumber";

import UberSearch from "~/components/UberSearch/UberSearch";
import { ApplicationState } from "~/store";
import { DictionaryItem } from "~/store/dictionaries/types";
import {
  TImportedFile,
  TLine,
  TWareCreate,
} from "~/store/distrigo-warehouses/types";
import { SparePartPricing } from "~/store/price-list/types";
import { toastInfo } from "~/utils/toast";

import "./ReferenceLineTable.scss";

type ReferenceLineTableProps = {
  changeQuantity: (
    lineNumber: number,
    currentValue: number,
    prevValue: number,
    round: boolean
  ) => void;
  changePrice: (
    lineNumber: number,
    currentValue: number,
    prevValue: number,
    round: boolean
  ) => void;
  changeSupplier: (
    lineNumber: number,
    data: string | number | boolean | (string | number | boolean)[] | undefined
  ) => string;
  lines: TLine[];
  mapProducts: (key: SparePartPricing[]) => any[];
  maximized?: boolean;
  removeLine: (lineIn: TLine) => void;
  setLines: (key: TLine[]) => void;
  summary?: boolean;
  tableEl?: LegacyRef<HTMLDivElement>;
  updateIndexing: (key: TLine[]) => void;
  ware: TWareCreate;
  wareId?: string;
  validNumber: (key: number) => void;
};

type TReduxState = {
  finishedImportingToLine?: boolean;
  imported?: TImportedFile[];
};

type TReferenceLineTable = TReduxState &
  ReferenceLineTableProps &
  RouteComponentProps;

const ReferenceLineTable = ({
  changePrice,
  changeQuantity,
  changeSupplier,
  finishedImportingToLine,
  imported,
  lines,
  mapProducts,
  maximized,
  removeLine,
  setLines,
  summary,
  tableEl,
  updateIndexing,
  ware,
  wareId,
  validNumber,
}: TReferenceLineTable) => {
  const changeProductReferenceId = useCallback(
    (lineNumber: number, data: any): string => {
      const newLines: TLine[] = [];
      const found = data.product.referenceId;
      let isPartOnLine = false;
      if (found) {
        if (lines.find((line) => line.referenceId === found)) {
          toastInfo(
            "Część została już dodana",
            `Zamiennik ${found} znajduje się już w zamówieniu.`
          );
          isPartOnLine = true;
        }
        if (!isPartOnLine) {
          lines.forEach((line) => {
            if (line.lineNumber !== lineNumber) {
              newLines.push({ ...line });
            } else {
              newLines.push({
                ...line,
                referenceId: found || undefined,
              });
            }
          });
          updateIndexing(newLines);
          setLines(newLines);
          return found;
        }
      }
      return "";
    },
    [lines, setLines, updateIndexing]
  );

  const supplierType: DictionaryItem[] = [
    { key: "PLPR", value: "PLPR", text: "PLPR" },
    { key: "PLOV", value: "PLOV", text: "PLOV" },
  ];

  return (
    <div
      className="uber-table no-wrap"
      ref={tableEl}
      style={{
        maxHeight: `calc(100vh - ${maximized ? 300 : 585}px)`,
        minHeight: 200,
      }}
    >
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>Nr</Table.HeaderCell>
            <Table.HeaderCell>Id Referencji</Table.HeaderCell>
            <Table.HeaderCell>Ilość do przyjęcia</Table.HeaderCell>
            <Table.HeaderCell>Jednostkowa cena zakupu</Table.HeaderCell>
            <Table.HeaderCell>Dostawca</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lines.map((line) => (
            <Table.Row key={line.lineNumber}>
              <Table.Cell collapsing>{line.lineNumber}</Table.Cell>
              <Table.Cell
                disabled={summary}
                style={{ overflow: "visible", maxWidth: "none" }}
              >
                <div className="UberSearchInput">
                  {!!line.rrid && (
                    <Icon
                      name="money bill alternate outline"
                      color="grey"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <UberSearch
                    key={wareId}
                    fluid
                    style={{ display: "inline-block" }}
                    aligned="left"
                    endpoint="/spare-parts-price-list"
                    mapper={(response) => mapProducts(response.slice(0, 10))}
                    onResultSelect={(selected: any) =>
                      changeProductReferenceId(line.lineNumber, selected)
                    }
                    value={
                      finishedImportingToLine && imported && imported.length
                        ? imported.reduce((item) => Object.assign(item, {}))
                            .referenceId
                        : line.referenceId
                    }
                    debounce={700}
                  />
                </div>
              </Table.Cell>
              <Table.Cell>
                {!summary ? (
                  <FormInputNumber
                    disabled={imported && finishedImportingToLine}
                    id="quantity"
                    fluid
                    min={0}
                    onBlur={(event: SyntheticEvent) =>
                      changeQuantity(
                        line.lineNumber,
                        Number(line.quantity),
                        Number(line.quantity),
                        true
                      )
                    }
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      data: InputNumberOnChangeData
                    ) => {
                      changeQuantity(
                        line.lineNumber,
                        data.value,
                        Number(line.quantity),
                        false
                      );
                    }}
                    step={1}
                    style={{ width: 100, opacity: 1 }}
                    value={
                      finishedImportingToLine && imported && imported.length
                        ? imported.reduce((item) => Object.assign(item, {}))
                            .quantity
                        : line.quantity && validNumber(line.quantity)
                          ? line.quantity
                          : 0
                    }
                  />
                ) : (
                  <div style={{ width: 100 }}>{line.quantity}</div>
                )}
              </Table.Cell>
              <Table.Cell className="FormInputNumberStyle">
                {!summary ? (
                  <FormInputNumber
                    disabled={imported && finishedImportingToLine}
                    fluid
                    id="price"
                    min={0.0}
                    precision={2}
                    style={{ width: 100, opacity: 1 }}
                    onBlur={(event: SyntheticEvent) =>
                      changePrice(
                        line.lineNumber,
                        Number(line.price),
                        Number(line.price),
                        false
                      )
                    }
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      data: InputNumberOnChangeData
                    ) => {
                      changePrice(
                        line.lineNumber,
                        data.value,
                        Number(line.price),
                        false
                      );
                    }}
                    step={0.01}
                    value={
                      finishedImportingToLine && imported && imported.length
                        ? imported.reduce((item) => Object.assign(item, {}))
                            .price
                        : line.price && validNumber(Number(line.price))
                          ? Number(Number(line.price).toFixed(2))
                          : Number(Number(0).toFixed(2))
                    }
                  />
                ) : (
                  <div style={{ width: 100 }}>
                    {Number(Number(line.price).toFixed(2))}
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className="DeliveryRow">
                {!summary ? (
                  <Dropdown
                    disabled={imported && finishedImportingToLine}
                    fluid
                    id="delivery"
                    style={{ width: 100, opacity: 1 }}
                    onBlur={(event: SyntheticEvent) =>
                      changeSupplier(line.lineNumber, line.supplier)
                    }
                    onChange={(event: SyntheticEvent, d: DropdownProps) => {
                      changeSupplier(line.lineNumber, d.value);
                    }}
                    options={supplierType}
                    placeholder="Wybierz rodzaj dostawcy"
                    search
                    selection
                    step={line.supplier}
                    value={
                      finishedImportingToLine && imported && imported.length
                        ? imported.reduce((item) => Object.assign(item, {}))
                            .supplier
                        : line.supplier
                    }
                  />
                ) : (
                  <div style={{ width: 100 }}>{line.supplier}</div>
                )}
              </Table.Cell>
              <Table.Cell className="col-dropdown-menu-sticky">
                {!summary && (
                  <Popup
                    trigger={
                      <Button
                        compact
                        disabled={!ware.parts}
                        circular
                        basic
                        icon="remove"
                        style={{ marginRight: 0 }}
                        onClick={() => removeLine(line)}
                      />
                    }
                    content="Usuń linię z dostawy"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="100" className="TableFooterStyle" />
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  distrigoWarehouses,
}: ApplicationState) => ({
  finishedImportingToLine: distrigoWarehouses.finishedImportingToLine,
  imported: distrigoWarehouses.imported,
});

export default connect(mapStateToProps, null)(withRouter(ReferenceLineTable));
