import DetailCard from "~/components/DetailCard/DetailCard";
import React, { useCallback } from "react";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { Announcement } from "~/store/annouoncements/types";
import { TNewAnnouncement } from "../../AddAnnouncement/AddAnnouncement";
import { parseDateNoTime } from "~/utils/dateUtils";

type TAnnouncementInfoCard = {
  announcement: Announcement | TNewAnnouncement;
  edit: boolean;
  setAnnouncementForEdit: React.Dispatch<React.SetStateAction<any>>;
};

const AnnouncementInfoCard: React.FC<TAnnouncementInfoCard> = ({
  announcement,
  edit,
  setAnnouncementForEdit,
}) => {
  const { renderField } = useRenderingFunctions(announcement, true);

  const handleChange = useCallback(
    (data: any, key: any): any => {
      setAnnouncementForEdit((item: any) => ({ ...item, [key]: data }));
      return data;
    },
    [setAnnouncementForEdit]
  );

  return (
    <DetailCard
      title="app.informations"
      id="info"
      key="info"
      width={4}
      smallWidth={5.33}
      style={{ zIndex: 30 }}
    >
      {renderField("title", {
        label: "Tytuł",
        width: 16,
        validators: [REQUIRED],
        onChangeFunc: (item: any) => handleChange(item, "title"),
        readOnly: !edit,
      })}
      {renderField("priority", {
        label: "Priorytet",
        width: 12,
        validators: [REQUIRED],
        type: "number",
        min: 1,
        onChangeFunc: (item: any) => handleChange(item, "priority"),
        readOnly: !edit,
      })}
      {renderField("startDate", {
        label: "Data rozpoczęcia",
        width: 12,
        validators: [REQUIRED],
        type: "date",
        readOnly: !edit,
        onChangeFunc: (item: any) => handleChange(item, "startDate"),
        minDate:
          "id" in announcement ? undefined : parseDateNoTime(new Date()) || "",
      })}
      {renderField("endDate", {
        label: "Data zakończenia",
        width: 12,
        validators: [REQUIRED],
        type: "date",
        readOnly: !edit,
        onChangeFunc: (item: any) => handleChange(item, "endDate"),
        minDate: announcement.startDate,
      })}
    </DetailCard>
  );
};

export default AnnouncementInfoCard;
