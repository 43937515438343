import React, { useCallback, useMemo } from "react";

import { TClaimLine } from "~/store/claims/types";
import { Table } from "semantic-ui-react";
import { parseDate } from "~/utils/dateUtils";
import { DictionaryItem } from "~/store/dictionaries/types";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { Link } from "react-router-dom";
import { COLUMN_NAME } from "./Transition/transitionColumns.conf";

type TClaimLineProps = {
  lines: TClaimLine[];
  statusDictionary: DictionaryItem[];
};

export type TClaimLineColumn = {
  name: keyof TClaimLine;
  label: string | JSX.Element;
  width?: number;
  dictionary?: DictionaryItem[];
  select?: JSX.Element;
};

const ClaimLines: React.FC<TClaimLineProps> = ({ lines, statusDictionary }) => {
  const columns = useMemo(
    (): TClaimLineColumn[] => [
      { name: COLUMN_NAME.referenceId, label: "Referencja" },
      { name: COLUMN_NAME.referenceName, label: "Nazwa referencji" },
      { name: COLUMN_NAME.orderNumber, label: "Numer zamówienia" },
      { name: COLUMN_NAME.packageNumber, label: "Numer paczki" },
      {
        name: COLUMN_NAME.claimedQuantity,
        label: "Ilość sztuk reklamowana",
        width: 50,
      },
      {
        name: COLUMN_NAME.acceptedQuantityByWarehouse,
        label: "Ilość zaakceptowana Magazyn",
        width: 50,
      },
      {
        name: COLUMN_NAME.acceptedQuantityByTransport,
        label: "Ilość zaakceptowana Transport",
        width: 50,
      },
      {
        name: COLUMN_NAME.acceptedQuantityByDistrigo,
        label: "Ilość zaakceptowana Distrigo",
        width: 50,
      },
      {
        name: COLUMN_NAME.acceptedQuantity,
        label: "Ilość sztuk do zaakceptowania",
        width: 50,
      },
      {
        name: COLUMN_NAME.toReceiveQuantity,
        label: "Ilość do zwrotu",
        width: 50,
      },
      {
        name: COLUMN_NAME.receivedQuantityFullValue,
        label: "Ilość zwrócona pełnowartościowa",
        width: 50,
      },
      {
        name: COLUMN_NAME.receivedQuantityDefective,
        label: "Ilość zwrócona niepełnowartościowa ",
        width: 50,
      },
      {
        name: COLUMN_NAME.status,
        label: "Status",
        width: 150,
        dictionary: statusDictionary,
      },
      { name: COLUMN_NAME.wmsComment, label: "Komentarze z WMS", width: 110 },
    ],
    [statusDictionary]
  );

  const getCell = useCallback(
    (line: TClaimLine, column: TClaimLineColumn): JSX.Element => {
      const { name } = column;
      switch (name) {
        case COLUMN_NAME.statusDate:
          return <Table.Cell key={name}>{parseDate(line[name])}</Table.Cell>;
        case COLUMN_NAME.referenceId:
          return (
            <Table.Cell key={name}>
              {line.claimReturnedPart?.id
                ? line.claimReturnedPart?.id
                : line[name]}
            </Table.Cell>
          );
        case COLUMN_NAME.referenceName:
          return (
            <Table.Cell key={name}>
              {line.claimReturnedPart?.name
                ? line.claimReturnedPart?.name
                : line[name]}
            </Table.Cell>
          );
        case COLUMN_NAME.status:
          return (
            <Table.Cell key={name}>
              <DictionaryLabel
                flexDisplay={true}
                value={line.status}
                dictionary={statusDictionary}
              />
            </Table.Cell>
          );
        case COLUMN_NAME.orderNumber:
          return (
            <Table.Cell key={name}>
              <Link target="_blank" to={`/order/${line.orderId}`}>
                {line.orderNumber}
              </Link>
            </Table.Cell>
          );
        case COLUMN_NAME.toReceiveQuantity:
        case COLUMN_NAME.receivedQuantityFullValue:
        case COLUMN_NAME.acceptedQuantity:
        case COLUMN_NAME.receivedQuantityDefective:
        case COLUMN_NAME.acceptedQuantityByWarehouse:
        case COLUMN_NAME.acceptedQuantityByTransport:
        case COLUMN_NAME.acceptedQuantityByDistrigo:
          return (
            <Table.Cell key={name}>
              {line[name] === null ? 0 : line[name]}
            </Table.Cell>
          );
        case COLUMN_NAME.packageNumber:
          return (
            <Table.Cell key={name}>
              {line[name] === null || line[name] === undefined
                ? "-"
                : line[name]}
            </Table.Cell>
          );
        default:
          return <Table.Cell key={name}>{line[name]}</Table.Cell>;
      }
    },
    [statusDictionary]
  );

  const renderedClaimsLines: JSX.Element = useMemo(() => {
    const rows = lines.map((line, index): JSX.Element => {
      return (
        <Table.Row key={index}>
          {columns.map((column) => getCell(line, column))}
        </Table.Row>
      );
    });

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            {columns.map((column) => (
              <Table.HeaderCell
                key={column.name}
                style={{ width: column.width }}
              >
                {column.label}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  }, [lines, columns, getCell]);

  return <>{renderedClaimsLines}</>;
};

export default ClaimLines;
