import { Menu } from 'semantic-ui-react';

import React, { useEffect, useState } from 'react';
import { CustomerType } from '~/pages/Customers/lib/AddContractor';
import { TCardsMapping } from '~/pages/Customers/Details/constants/TCardsMapping';
import { CardKey } from '~/pages/Customers/Details/constants/cardKey';
import mapping from '~/pages/Customers/Details/constants/mapping';

export type TCard = CardKey;

export type TTabName = keyof TCardsMapping;

type TProps = {
  tabs: {
    name: TTabName;
    title: JSX.Element;
  }[];
  onTabChange: (cards: TCard[]) => void;
  categoryType?: string;
  customerType?: CustomerType;
  userHaveLdcCustomerPayerEditRole: boolean;
  activeItem:
    | 'main'
    | 'address'
    | 'payments'
    | 'logistic'
    | 'bookkeeping'
    | 'gdpr'
    | 'other'
    | 'client'
    | 'all'
    | 'factor'
    | 'distrigoLoan'
    | 'changePayer';
  setActiveItem: React.Dispatch<
    React.SetStateAction<
      | 'main'
      | 'address'
      | 'payments'
      | 'logistic'
      | 'bookkeeping'
      | 'gdpr'
      | 'other'
      | 'client'
      | 'all'
      | 'factor'
      | 'distrigoLoan'
      | 'changePayer'
    >
  >;
};

const TabSwitcher: React.FC<TProps> = ({
  activeItem,
  setActiveItem,
  tabs,
  onTabChange,
  customerType,
  userHaveLdcCustomerPayerEditRole,
}) => {
  const [localMappings, setLocalMappings] = useState<TCardsMapping>(mapping);

  useEffect(() => {
    let newAll: TCard[] = [];
    let main: TCard[] = [...mapping.main];
    let changePayer: TCard[] = [...mapping.changePayer];

    setLocalMappings((prevState) => {
      if (customerType === CustomerType.PAYER) {
        if (userHaveLdcCustomerPayerEditRole) {
          changePayer = [...mapping.changePayer, CardKey.changePayer];
        }

        newAll = [
          ...main,
          ...prevState.address,
          ...prevState.payments,
          ...prevState.bookkeeping,
          ...mapping.factor,
          ...mapping.distrigoLoan,
          ...prevState.gdpr,
          ...prevState.other,
          ...prevState.client,
          ...changePayer,
        ];
      } else if (customerType === CustomerType.DELIVERY) {
        main = [...mapping.main, CardKey.docsDistribution];

        newAll = [...main, ...prevState.address, ...prevState.logistic, ...prevState.other, ...prevState.client];
      }

      return {
        ...prevState,
        all: [...newAll],
        main: [...main],
        changePayer: [...changePayer],
      };
    });
  }, [activeItem, onTabChange, customerType, userHaveLdcCustomerPayerEditRole]);

  useEffect(() => {
    onTabChange(localMappings[activeItem]);
  }, [activeItem, onTabChange, localMappings]);

  const renderTab = (name: TTabName, title: JSX.Element): JSX.Element => {
    const handleClick = (): void => {
      setActiveItem(name);
    };
    return (
      <Menu.Item name={name} key={name + Math.random()} active={activeItem === name} onClick={handleClick}>
        {title}
      </Menu.Item>
    );
  };

  const renderTabs = (): JSX.Element[] => {
    return tabs.map((tab) => {
      if (tab.name === 'logistic' && customerType === CustomerType.PAYER) {
        return <div key={tab.name} />;
      } else if (
        (tab.name === 'bookkeeping' ||
          tab.name === 'payments' ||
          tab.name === 'distrigoLoan' ||
          tab.name === 'changePayer' ||
          tab.name === 'factor' ||
          tab.name === 'gdpr') &&
        customerType === 'DELIVERY'
      ) {
        return <div key={tab.name} />;
      } else {
        return renderTab(tab.name, tab.title);
      }
    });
  };

  return <Menu>{renderTabs()}</Menu>;
};

export { TabSwitcher };
