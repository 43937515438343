import { useEffect } from "react";
import { fetchManualOrdersCount } from "~/store/notifications/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "~/store";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";

export const useManualOrdersBadge = ({
  isOrderViewRole,
}: {
  isOrderViewRole: boolean;
}) => {
  const manualOrdersCount = useSelector(
    (state: ApplicationState) => state.notifications.manualOrdersCount
  );
  const dispatch = useDispatch();
  const { isClientPanelUser } = useClientPanelRoles();

  useEffect(() => {
    if (!isOrderViewRole || isClientPanelUser) return;
    dispatch(fetchManualOrdersCount());
    const interval = setInterval(() => {
      dispatch(fetchManualOrdersCount());
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, isOrderViewRole, isClientPanelUser]);

  return { manualOrdersCount: isOrderViewRole ? manualOrdersCount : 0 };
};
