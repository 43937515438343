import { FormattedMessage } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { Divider, Form, Grid, Icon, Label } from "semantic-ui-react";
import {
  activatePromotion,
  clonePromotion,
  closePromotion,
  deletePromotion,
  promoCollisionViewClosed,
  promotionSelected,
  selectPromotion,
} from "~/store/promotions/actions";
import {
  TPromotion,
  TPromotionActivateCollisionInfo,
  TPromotionStatus,
} from "~/store/promotions/types";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { SmartHidden } from "~/components/SmartField/SmartHidden";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import {
  DATE,
  DATE_MAX,
  DATE_MIN,
  REQUIRED,
} from "~/components/SmartField/lib/validator";
import { ApplicationState } from "~/store";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import dictionariesStatic from "~/store/dictionaries/static";
import EffectDefinition from "./lib/effect";
import CustomersPromo from "./lib/triggers/Customers";
import ProductsTree from "./lib/triggers/ProductsTree";
import { THeaderButton } from "~/components/PageHeader/lib/types";
import { usePromoCollisionModal } from "~/pages/Promotions/Details/lib/triggers/Modals";
import { isPromotionValid } from "~/pages/Promotions/Details/lib/Validator";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import translations from "~/utils/translations";

type TReduxState = {
  promotion?: TPromotion;
  loading: boolean;
  deleting: boolean;
  deleted: boolean;
  created?: string;
  activatingPromo: boolean;
  activationFailed: boolean;
  promoCollisionInfo: TPromotionActivateCollisionInfo | undefined;
  closing: boolean;
};

type TReduxActions = {
  selectPromotion: typeof selectPromotion;
  promotionSelected: typeof promotionSelected;
  deletePromotion: typeof deletePromotion;
  promoCollisionViewClosed: typeof promoCollisionViewClosed;
  activatePromotion: typeof activatePromotion;
  closePromotion: typeof closePromotion;
};

type TRouteParams = RouteComponentProps<{
  id: string;
  mode?: string;
}>;

type TPromotionDetailsProps = TReduxState & TReduxActions & TRouteParams;

const PromotionDetails: React.FC<TPromotionDetailsProps> = ({
  match,
  promotion,
  loading,
  deleting,
  deleted,
  created,
  selectPromotion,
  promotionSelected,
  deletePromotion,
  activatingPromo,
  activationFailed,
  promoCollisionInfo,
  promoCollisionViewClosed,
  activatePromotion,
  closePromotion,
  closing,
}) => {
  const [editMode, setEditMode] = useState<boolean>(
    match.params.mode === "edit"
  );
  const [status, setStatus] = useState<TPromotionStatus>();
  const [confirmClonePromotion, setConfirmClonePromotion] =
    useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<boolean>(false);
  const [statusChanging, setStatusChanging] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const promoCollisionModal = usePromoCollisionModal(
    activationFailed && !!promoCollisionInfo,
    promoCollisionInfo?.additionalData,
    () => promoCollisionViewClosed()
  );
  const dispatch = useDispatch();

  const appContext = useContext(AppContext);

  const userHaveLdcUiPromotionProcessRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_PROMOTION_PROCESS
      ),
    [appContext]
  );

  useEffect(() => {
    document.title = translations.format("app.promotions")
    setStatus(promotion?.status);
  }, [promotion]);

  useEffect(() => {
    selectPromotion(match.params.id);
  }, [match.params.id, selectPromotion, activatingPromo, setStatus, closing]);

  useEffect(() => {
    if (status) {
      setSaveStatus(true);
    }
  }, [status]);

  useEffect(() => {
    if (saveStatus) {
      setSaveStatus(false);
      setStatus(undefined);
    }
  }, [saveStatus]);

  const handleClonePromotion = useCallback(
    () => dispatch(clonePromotion(promotion)),
    [dispatch, promotion]
  );

  const handleBeforeSave = (data: any): TPromotion => {
    if (promotion && promotion.type !== data.type) {
      data.firstDiscount = undefined;
      data.secondDiscount = undefined;
      data.associativeDiscountGroup =
        data.type === "ASSOCIATIVE" ? "ALL" : undefined;
    }
    promotionSelected(data);
    return data;
  };

  const handleStatusChange = (modified: boolean, loading: boolean): void => {
    setStatusChanging(loading);
  };

  const isPromoActivated = useCallback((): boolean => {
    return (
      !activationFailed &&
      !promoCollisionInfo &&
      !activatingPromo &&
      !!promotion &&
      promotion.status === "AWAITING_ACTIVATION"
    );
  }, [activationFailed, promoCollisionInfo, activatingPromo, promotion]);

  const isStatusDraft = useCallback((): boolean => {
    return !!promotion && promotion.status === "DRAFT";
  }, [promotion]);

  const editable: boolean = editMode && isStatusDraft();

  const {
    renderField,
    renderNumber,
    renderSelect,
    renderToggle,
    renderTextArea,
    renderLabel,
  } = useRenderingFunctions(promotion, editable);

  const isPromoValid = useCallback((): boolean => {
    return promotion ? isPromotionValid(promotion) : false;
  }, [promotion]);

  const toggleEditMode = useCallback((): void => {
    setEditMode(!editMode);
  }, [editMode, setEditMode]);

  const cards = useMemo(() => {
    return !promotion
      ? []
      : [
          <DetailCard
            title="app.promo.basic"
            id="basic"
            key="basic"
            minHeight={100}
            width={5.33}
          >
            <Form.Group widths="equal">
              {renderField("code", {
                label: "Kod",
                validators: [REQUIRED],
                readOnly: true,
              })}
              {renderNumber("priority", { label: "Priorytet" })}
            </Form.Group>
            {renderTextArea("description", {
              label: "Opis",
              rows: 4,
              style: { marginBottom: 15 },
            })}
          </DetailCard>,
          <DetailCard
            title="app.promo.scope.of.adaptation"
            id="date"
            key="date"
            minHeight={100}
            width={5.33}
          >
            {renderLabel(
              "Status",
              <DictionaryLabel
                value={promotion.status}
                dictionary={dictionariesStatic.PROMO_STATUS}
                compact
              />
            )}
            <Divider />
            <Form.Group widths="equal">
              {renderField("dateFrom", {
                label: "Data rozpoczęcia",
                type: "date",
                validators: [
                  REQUIRED,
                  DATE,
                  DATE_MAX(
                    "dateTo",
                    "Data rozpoczęcia promocji nie może być późniejsza niż jej data zakończenia."
                  ),
                ],
              })}
              {renderField("dateTo", {
                label: "Data zakończenia",
                type: "date",
                validators: [
                  REQUIRED,
                  DATE,
                  DATE_MIN(
                    "dateFrom",
                    "Data zakończenia promocji nie moze być mniejsza niż jej data rozpoczęcia."
                  ),
                ],
              })}
            </Form.Group>
            <Divider />
            {renderToggle("bonusASO", {
              label: "Bonus ASO",
              description:
                "Flaga dla raportu „Kwartalne wyniki sprzedaży”. Określa, czy produkty sprzedawane w ramach promocji, mają być uwzględniane przy liczeniu „premii ASO”.",
            })}
          </DetailCard>,
          <DetailCard
            title="app.promo.type"
            id="type"
            key="type"
            minHeight={100}
            width={5.33}
          >
            {renderSelect("type", dictionariesStatic.PROMO_TYPE, {
              label: "Typ promocji",
            })}
            {!promotion.type && (
              <div style={{ textAlign: "center" }}>
                <Label pointing="above" color="orange" style={{ marginTop: 0 }}>
                  <Icon name="info circle" />
                  Wybierz typ promocji, aby przejść dalej.
                </Label>
              </div>
            )}
            {promotion.type && (
              <Label
                pointing="above"
                style={{ marginTop: 0, lineHeight: "18px" }}
              >
                {promotion.type === "CASCADE" &&
                  "Nadaje dodatkowy względem cennika upust procentowy na cenę jednostkową produktu. Można zdefiniować dwa progi upustu. Całkowity upust zostanie naliczony kaskadowo: najpierw upust cennikowy, następnie upust progu pierwszego i na końcu upust progu drugiego."}
                {promotion.type === "ACTUAL" &&
                  "Nadaje upust procentowy lub kwotowy, który zastąpi upust cennikowy. Można zdefiniować dwa progi upustu. Podczas składania zamówienia uwzględniony będzie próg dający większy upust."}
                {promotion.type === "CURRENCY" &&
                  "Zastępuje cenę produktu ceną promocyjną w zależności od liczby kupowanych sztuk lub wartości pozycji w zamówieniu."}
                {promotion.type === "ASSOCIATIVE" &&
                  "Nadaje upust procentowy na wybrane produkty w zależności od innych produktów znajdujących się w koszyku. Upust może zostać nałożony na obydwie lub jedną z poniżej zdefiniowanych grup. Warunkiem zadziałania promocji jest obecność w zamówieniu produktów z obydwu grup w wybranej liczności."}
                {promotion.type === "GROUP" &&
                  "Nadaje wybrany upust na produkty wskazane w drugiej grupie. Aby promocja została zastosowana w zamówieniu musi znaleźć się odpowiednia ilość lub odpowiednia wartość produktów z grupy pierwszej."}
              </Label>
            )}
          </DetailCard>,
          promotion.type && [
            promotion.type === "ASSOCIATIVE" ? (
              [
                <DetailCard
                  title="app.promo.firstGroup"
                  id="products-required"
                  key="products-required"
                  minHeight={0}
                  width={8}
                >
                  <ProductsTree
                    editMode={editable}
                    associativeDiscountGroup={"GROUP_1"}
                    referenceRule={
                      promotion.ruleSet && promotion.ruleSet.referenceRule
                    }
                    modelPropertyName="ruleSet.referenceRule"
                    cardinalityControl
                    description={
                      "Aby promocja została zastosowana, w zamówieniu muszą znaleźć się produkty z zaznaczonych obok hierarchii w liczności zdefiniowanej poniżej. " +
                      (promotion.associativeDiscountGroup === "ALL" ||
                      promotion.associativeDiscountGroup === "GROUP_1"
                        ? "Produkty z tej grupy będą objęte rabatem."
                        : "Produkty z tej grupy NIE będą objęte rabatem.")
                    }
                  />
                </DetailCard>,
                <DetailCard
                  title="app.promo.secondGroup"
                  id="products-additional"
                  key="products-additional"
                  minHeight={0}
                  width={8}
                >
                  <ProductsTree
                    editMode={editable}
                    associativeDiscountGroup={"GROUP_2"}
                    referenceRule={
                      promotion.resultSet && promotion.resultSet.referenceRule
                    }
                    modelPropertyName="resultSet.referenceRule"
                    cardinalityControl
                    description={
                      "Aby promocja została zastosowana, w zamówieniu muszą znaleźć się produkty z zaznaczonych obok hierarchii w liczności zdefiniowanej poniżej. " +
                      (promotion.associativeDiscountGroup === "ALL" ||
                      promotion.associativeDiscountGroup === "GROUP_2"
                        ? "Produkty z tej grupy będą objęte rabatem."
                        : "Produkty z tej grupy NIE będą objęte rabatem.")
                    }
                  />
                </DetailCard>,
              ]
            ) : promotion.type === "GROUP" ? (
              [
                <DetailCard
                  title="app.promo.firstGroup"
                  id="products-required"
                  key="products-required"
                  minHeight={0}
                  width={8}
                >
                  <ProductsTree
                    editMode={editable}
                    associativeDiscountGroup={"GROUP_1"}
                    referenceRule={
                      promotion.ruleSet && promotion.ruleSet.referenceRule
                    }
                    modelPropertyName="ruleSet.referenceRule"
                    isolatedCollectionControl
                    description={
                      "Aby promocja została zastosowana, w zamówieniu muszą znaleźć się produkty z zaznaczonych obok hierarchii w liczności zdefiniowanej poniżej. " +
                      (promotion.associativeDiscountGroup === "ALL" ||
                      promotion.associativeDiscountGroup === "GROUP_1"
                        ? "Produkty z tej grupy będą objęte rabatem."
                        : "Produkty z tej grupy NIE będą objęte rabatem.")
                    }
                  />
                </DetailCard>,
                <DetailCard
                  title="app.promo.secondGroup"
                  id="products-additional"
                  key="products-additional"
                  minHeight={0}
                  width={8}
                >
                  <ProductsTree
                    editMode={editable}
                    associativeDiscountGroup={"GROUP_2"}
                    referenceRule={
                      promotion.resultSet && promotion.resultSet.referenceRule
                    }
                    modelPropertyName="resultSet.referenceRule"
                    cardinalityControlRange
                    isolatedResultCollectionControl
                    description={
                      "Aby promocja została zastosowana, w zamówieniu muszą znaleźć się produkty z zaznaczonych obok hierarchii w liczności zdefiniowanej poniżej. " +
                      (promotion.associativeDiscountGroup === "ALL" ||
                      promotion.associativeDiscountGroup === "GROUP_2"
                        ? "Produkty z tej grupy będą objęte rabatem."
                        : "Produkty z tej grupy NIE będą objęte rabatem.")
                    }
                  />
                </DetailCard>,
              ]
            ) : (
              <DetailCard
                title="app.promo.groupProduct"
                id="products"
                key="products"
                minHeight={0}
                width={10.66}
              >
                <ProductsTree
                  editMode={editable}
                  referenceRule={
                    promotion.ruleSet && promotion.ruleSet.referenceRule
                  }
                  modelPropertyName="ruleSet.referenceRule"
                  description="Zaznaczone produkty i hierarchie będą objęte promocją podczas składania zamówienia."
                />
              </DetailCard>
            ),
            <DetailCard
              title="app.customers"
              id="customers"
              key="customers"
              minHeight={0}
              width={5.33}
              smallWidth={16}
            >
              <CustomersPromo editMode={editable} promotion={promotion} />
            </DetailCard>,
            <EffectDefinition
              key="effect-definition"
              editMode={editable}
              type={promotion.type}
              promotion={promotion}
            />,
            !["ASSOCIATIVE", "GROUP"].includes(promotion.type) && (
              <DetailCard
                title="app.promo.settingsIzolation"
                id="isolation"
                key="isolation"
                width={5.33}
                minHeight={0}
              >
                {renderToggle("isolatedCollection", {
                  label: "Zbiór izolowany",
                })}
                <Label
                  pointing="above"
                  style={{ lineHeight: "18px", marginTop: 0 }}
                >
                  Określa czy warunki zdefiniowane dla poszczególnych progów
                  dotyczą wszystkich produktów znajdujących się w koszyku czy
                  każdy produkt będzie rozpatrywany osobno.
                </Label>
              </DetailCard>
            ),
          ],
        ];
  }, [
    promotion,
    editable,
    renderField,
    renderLabel,
    renderNumber,
    renderSelect,
    renderTextArea,
    renderToggle,
  ]);

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <div className="uber-content">
        <Grid stretched>{cards}</Grid>
      </div>
    );
  }, [cards]);

  const renderedPromotionTitle: string = useMemo(() => {
    if (!promotion) {
      return "Wczytywanie...";
    }
    return promotion.code;
  }, [promotion]);

  const headerButtons: THeaderButton[] = useMemo(() => {
    if (!promotion) {
      return [];
    }
    const buttons: THeaderButton[] = [
      {
        icon: editMode ? "lock" : "edit",
        content: editMode ? (
          "Wył. edycję"
        ) : (
          <FormattedMessage id="app.button.edit" />
        ),
        onClick: toggleEditMode,
        primary: true,
        visible: isStatusDraft() && userHaveLdcUiPromotionProcessRole,
      },
      {
        icon: "clone",
        content: "Klonuj",
        popup: "Utwórz nową promocję na podstawie aktualnie przeglądanej.",
        disabled: confirmClonePromotion,
        visible:
          ((!isStatusDraft() && !confirmClonePromotion) ||
            isPromoActivated()) &&
          userHaveLdcUiPromotionProcessRole,
        onClick: () => {
          setConfirmClonePromotion(true);
        },
      },
      {
        icon: "clone",
        content: "Potwierdź klonowanie",
        popup: "Utwórz nową promocję na podstawie aktualnie przeglądanej.",
        visible: confirmClonePromotion,
        primary: true,
        onClick: () => {
          handleClonePromotion();
          setConfirmClonePromotion(false);
        },
      },
      {
        icon: "window close",
        content: "Anuluj klonowanie",
        visible: confirmClonePromotion,
        onClick: () => {
          setConfirmClonePromotion(false);
        },
      },
      {
        icon: "checkmark",
        content: "Aktywuj",
        visible: isStatusDraft() && !isPromoActivated(),
        disabled: !isPromoValid() || confirmClonePromotion,
        popup: isPromoValid()
          ? "Aktywuj promocję. Po aktywacji promocji nie będzie można dokonywać zmian."
          : "Obecna definicja promocji jest niekompletna i nie pozwala na jej aktywację.",
        onClick: () => {
          if (promotion.promoID) {
            activatePromotion(promotion.promoID);
          }
        },
      },
      {
        icon: "trash",
        content: "Usuń",
        popup: "Usuń ten szablon promocji.",
        visible: isStatusDraft() && !isPromoActivated(),
        onClick: () => {
          setOpenDeleteModal(true);
        },
      },
      {
        icon: "window close",
        content: "Zakończ",
        disabled: confirmClonePromotion,
        visible:
          userHaveLdcUiPromotionProcessRole &&
          (promotion.status === "ACTIVE" ||
            promotion.status === "AWAITING_ACTIVATION"),
        onClick: () => {
          if (promotion.promoID) {
            closePromotion(promotion.promoID);
          }
        },
      },
    ];
    if (match.params.mode === "debug") {
      buttons.push({
        icon: "redo",
        content: "Revert",
        visible: promotion.status !== "DRAFT",
        popup: "Revert status to Draft / debug button",
        basic: true,
        onClick: () => {
          setStatus("DRAFT");
        },
      });
    }

    return buttons;
    // eslint-disable-next-line
  }, [
    promotion,
    editMode,
    isPromoValid,
    isStatusDraft,
    match.params.mode,
    toggleEditMode,
    handleClonePromotion,
    confirmClonePromotion,
    activatingPromo,
    promoCollisionInfo,
    activationFailed,
    activatePromotion,
    closePromotion,
    isPromoActivated,
  ]);

  if (deleted) {
    return <Redirect to="/promotions" />;
  }

  if (created) {
    return <Redirect to={`/promotion/${created}/edit`} />;
  }

  return (
    <SmartWrapper
      endpoint={promotion ? `/promo/${promotion.promoID}` : ""}
      method="POST"
      model={promotion}
      onBeforeSave={handleBeforeSave}
    >
      {activationFailed ? promoCollisionModal : null}
      <ModalConfirm
        modalOpen={openDeleteModal}
        headerIcon="trash"
        headerText="Usunięcie promocji"
        contentText="Czy na pewno chcesz usunąć ten szablon promocji?"
        loading={deleting}
        onCancelClick={() => {
          setOpenDeleteModal(false);
        }}
        onConfirmClick={() => {
          deletePromotion(
            promotion && promotion.promoID ? promotion.promoID : ""
          );
        }}
      />
      <PageHeader
        icon="star outline"
        title={
          <>
            <FormattedMessage id="app.promotion" />: {renderedPromotionTitle}
          </>
        }
        breadcrumb={[
          { text: <FormattedMessage id="app.list" />, link: "/promotions" },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={headerButtons}
        refreshAction={() => {
          selectPromotion(match.params.id);
        }}
        loading={loading || activatingPromo}
      />
      <SmartHidden
        name="status"
        stateChange={handleStatusChange}
        value={promotion && promotion.status}
        editValue={status}
        blur={saveStatus}
      />
      {cardsRendered}
      <CommonLoader
        loading={loading || statusChanging || !promotion || activatingPromo}
      />
    </SmartWrapper>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  promotions,
}: ApplicationState) => {
  return {
    promotion: promotions.selected,
    loading: promotions.loading,
    deleting: promotions.deleting,
    deleted: !!promotions.deleted,
    created: promotions.created,
    activatingPromo: promotions.activating,
    activationFailed: promotions.activationFailed,
    promoCollisionInfo: promotions.collisionInfo,
    closing: promotions.closing,
  };
};

const mapDispatchToProps: TReduxActions = {
  selectPromotion,
  promotionSelected,
  deletePromotion,
  promoCollisionViewClosed,
  activatePromotion,
  closePromotion,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetails);
