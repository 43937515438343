import React from "react";
import { TClaimMessege } from "~/store/claims/types";
import { useComments } from "../Cards/Comments/hooks/useComments";
import { Grid } from "semantic-ui-react";

type RenderMesagesProps = {
  messages: TClaimMessege[] | null;
};

export const RenderMessages: React.FC<RenderMesagesProps> = ({ messages }) => {
  const {
    addStyleClassByRole,
    renderInfoBadge,
    renderCommentContent,
    setLeftRightClass,
  } = useComments();

  return (
    <>
      {messages &&
        messages.map((comment) => (
          <Grid.Row
            key={comment.timestamp + Math.random()}
            className={setLeftRightClass(comment)}
          >
            <div className="comment-wrapper">
              {renderCommentContent(comment)}
            </div>
            <div className={"info " + addStyleClassByRole(comment)}>
              {renderInfoBadge(comment)}
            </div>
          </Grid.Row>
        ))}
    </>
  );
};
