import { SmartPopup } from '~/components/SmartField/SmartPopup';
import { Form, TextAreaProps } from 'semantic-ui-react';
import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';
import { CardDetailsAdnotationProps } from '~/pages/Orders/lib/Add/Adnotation/constants';

const CardDetailsAdnotation = ({ shrink, order, summary, updateOrder }: CardDetailsAdnotationProps) => (
  <DetailCard
    title="app.adnotation"
    id="annotations"
    key="annotations"
    minHeight={40}
    width={shrink ? 2 : 3}
    smallWidth={5.33}
  >
    <SmartPopup description="Dodatkowe informacje od klienta na temat zamówienia">
      <Form.TextArea
        disabled={!order.type}
        readOnly={summary}
        label="Uwagi klienta"
        rows={6}
        value={order.comments}
        onChange={(ev, props: TextAreaProps) => {
          updateOrder('comments', String(props.value));
        }}
      />
    </SmartPopup>
  </DetailCard>
);

export default CardDetailsAdnotation;
