import { Grid } from "semantic-ui-react";
import React, { useCallback, useMemo } from "react";
import DetailCardStatus from "~/pages/Orders/Details/lib/DetailCardStatus";
import DetailCardDelivery from "~/pages/Orders/Details/lib/DetailCardDelivery";
import DetailCardAnnotations from "~/pages/Orders/Details/lib/DetailCardAnnotations";
import DetailCardService from "~/pages/Orders/Details/lib/DetailCardService";
import DetailCardLines from "~/pages/Orders/Details/lib/DetailCardLines";
import { deprioritize, prioritize } from "~/store/orders/actions";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { useDispatch } from "react-redux";
import { CardsProps } from "~/pages/Orders/Details/lib/constants";
import BackOrderNumberList from "./BackOrderNumberList";

const CardsClientPanel = ({ editMode, order }: CardsProps) => {
  const dispatch = useDispatch();

  const { renderField, renderTextArea, renderLabel } = useRenderingFunctions(
    order,
    editMode
  );

  const handlePrioritetUpdate = useMemo(
    () => (e: any, data: any) => {
      if (order) {
        if (data.value === true) {
          dispatch(prioritize(order.id));
        }

        if (data.value === false) {
          dispatch(deprioritize(order.id));
        }
      }
    },
    [order, dispatch]
  );

  const isStatus = (value: string, ...statuses: string[]): boolean => {
    return statuses.indexOf(value) !== -1;
  };

  const renderBackOrderNumberList = useCallback(
    (): JSX.Element => (
      <BackOrderNumberList
        key="batch"
        shrink={false}
        renderLabel={renderLabel}
        order={order}
        forClientPanel={true}
      />
    ),
    [order, renderLabel]
  );

  const cards = useMemo(
    () =>
      !order
        ? []
        : [
            <DetailCardStatus
              key="status"
              order={order}
              renderLabel={renderLabel}
              shrink={false}
            />,
            <DetailCardDelivery
              key="delivery"
              order={order}
              renderLabel={renderLabel}
              shrink={false}
            />,
            <DetailCardAnnotations
              key="annotations"
              shrink={false}
              renderTextArea={renderTextArea}
            />,
            <DetailCardService
              key="service"
              renderTextArea={renderTextArea}
              shrink={false}
              renderLabel={renderLabel}
              editMode={editMode}
              handlePrioritetUpdate={handlePrioritetUpdate}
              isStatus={isStatus}
              renderField={renderField}
              order={order}
              forClientPanel={true}
            />,
            order.backorderIdNumberList &&
              order.backorderIdNumberList.length > 0 &&
              renderBackOrderNumberList(),
            <DetailCardLines
              key="lines"
              order={order}
              isStatus={isStatus}
              editMode={editMode}
            />,
          ],
    [
      editMode,
      order,
      renderField,
      renderLabel,
      renderTextArea,
      handlePrioritetUpdate,
      renderBackOrderNumberList,
    ]
  );

  return (
    <div className="uber-content overflow-visible">
      <Grid stretched>{cards}</Grid>
    </div>
  );
};

export default CardsClientPanel;
