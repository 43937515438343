import React from 'react'
import { Dimmer, Header, Icon } from 'semantic-ui-react'

export type TProps = {
    error?: boolean
}

const InitialLoader: React.FC<TProps> = ({
    error = false
}) => {

    return (
        <Dimmer active inverted>
            {!error ?
                <>
                    <Icon name="cog" color="grey" size="big" className="loading-rv" fitted
                        style={{
                            position: 'relative',
                            left: 10,
                            top: -22
                        }}
                    />
                    <Icon name="cog" color="grey" size="huge" loading fitted />
                </>
                :
                <Header icon color="orange">
                    <Icon name="warning sign" size="big" style={{ paddingBottom: 10 }} />
                    Wystąpił błąd podczas logowania< br />
                    <small>
                        Odśwież stronę używając klawiszy Ctrl + F5. Jeżeli problem będzie się powtarzał, skontaktuj się z administratorem.
                        </small>
                </Header>
            }
        </Dimmer>
    )
}

export default InitialLoader
