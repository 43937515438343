export const getIconName = (type: string): string => {
    switch (type) {
        case "ERROR":
            return 'exclamation circle'
        case "INFO":
            return 'info circle'
        case "WARN":
            return 'exclamation triangle'
        case "STATUS":
            return 'sticky note'
        default:
            return 'minus'
    }
}
