export function downloadFile(
  res: any,
  fileName: string,
  type = "application/octet-stream"
): void {
  const url = window.URL.createObjectURL(new Blob([res], { type }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
