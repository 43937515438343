import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import AnomaliesList from "~/pages/Anomalies/List";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { Anomaly } from "~/store/anomalies/types";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";

export const useAnomalyTable = (
  anomalies: Anomaly[],
  dictionaries: DictionariesState
) => {
  const { getTextCell, getDictionaryCell } = useCellsRenderer();

  const [columns, setColumns] = useState<TColumn[]>([
    {
      name: "associatedDocumentID",
      label: "Dokument ID",
      projection: true,
      getCell: (d, c) => getTextCell(d, c),
    },
    {
      name: "anomalyType",
      dictionaryName: DictionaryName.anomalyType,
      label: "Typ",
      projection: true,
      noKey: true,
      getCell: (d, c) => getDictionaryCell(d, c),
    },
    {
      name: "anomalyStatus",
      dictionaryName: DictionaryName.anomalyStatus,
      label: "Status",
      projection: true,
      noKey: true,
      getCell: (d, c) => getDictionaryCell(d, c),
    },
  ]);

  useEffect(() => {
    const completeColumnDictionaries = (columnsToMap: TColumn[]): TColumn[] =>
      columnsToMap.map((column) =>
        column.dictionaryName
          ? {
              ...column,
              dictionary: dictionaries[column.dictionaryName],
            }
          : { ...column }
      );
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries]);

  const renderAnomalyTable = () => (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {columns.map((column) => (
            <Table.HeaderCell key={column.name}>
              {column.label}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <AnomaliesList anomalies={anomalies} columns={columns} />
      </Table.Body>
    </Table>
  );

  return { renderAnomalyTable };
};
