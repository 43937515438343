import { connect } from 'react-redux'
import { Button, Header, Icon, Modal, Table } from 'semantic-ui-react'
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper'
import { ModelInput } from '~/components/ModelForm/ModelInput'
import { SubmitButton } from '~/components/ModelForm/SubmitButton'
import { ApplicationState } from '~/store'
import { rejectToSupplier } from '~/store/distrigo-warehouses/actions'
import { StockWarehouse, TRejectedPart, TRejectToSupplier } from '~/store/distrigo-warehouses/types'

import React, { useEffect, useState, SyntheticEvent } from 'react'
import { validNumberKey } from '~/utils/validNumberKey'
import { ModelTextArea } from "~/components/ModelForm/ModelTextArea";

type TFormRawModel = { [key: string]: number } & { notes?: string }

type TReduxState = {
    rejecting: boolean
    rejected: boolean
}

type TReduxActions = {
    rejectToSupplier: typeof rejectToSupplier
}

type TProps = TReduxState & TReduxActions & {
    triggerOpen: boolean
    warehouseId: string
    selection: StockWarehouse[]
    createdSuccess: () => void
}

const RejectToSupplier: React.FC<TProps> = ({
    children,
    rejecting,
    rejected,
    triggerOpen,
    warehouseId,
    selection,
    rejectToSupplier,
    createdSuccess
}) => {

    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    useEffect(() => {
        if (rejected) {
            setOpen(false)
            createdSuccess()
        }
    }, [createdSuccess, rejected])

    const handleOpen = (): void => setOpen(true)

    const handleCancel = (): void => {
        if (!rejecting) {
            setOpen(false)
        }
    }

    const model = selection.reduce((prev, selected) => {
        const referenceId = selected.referenceWarehouseId.referenceId
        return {
            ...prev,
            [referenceId]: 1
        }
    }, {})

    const handleSubmit = (data: TFormRawModel): void => {
        const parts: TRejectedPart[] = []
        Object.entries(data).forEach(entry => {
            if (!entry.includes('notes')) {
                parts.push({
                    referenceId: entry[0],
                    quantity: +entry[1]
                })
            }
        })
        const rejectedParts: TRejectToSupplier = {
            parts,
            notes: data['notes']
        }
        rejectToSupplier(rejectedParts)
    }

    const productStockInfo = (selected: StockWarehouse): JSX.Element => {
        return (
            selected &&
            <div style={{ color: '#9f9f9f', fontSize: '12px' }}>
                <label>Dostępne: <strong>{selected.available}</strong></label>
                <label style={{ marginLeft: '10px' }}>QC: <strong>{selected.qualityControl}</strong></label>
            </div>
        )
    }

    return (
        <Modal
            size='tiny'
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}
        >
            <ModelFormWrapper<TFormRawModel> onSubmit={handleSubmit} model={model} >
                <Header><Icon name="reply" flipped="vertically" />Zwrot części do dostawcy</Header>
                <Modal.Content>
                    <div>
                        <label style={{ fontWeight: 'bold' }}>Uwagi:</label>
                        <ModelTextArea className='textarea-wrapper' name={'notes'} />
                    </div>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ID referencji</Table.HeaderCell>
                                <Table.HeaderCell>Ilość</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {selection.map((selected, index) => {
                                const referenceId = selected.referenceWarehouseId.referenceId
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>{referenceId}</Table.Cell>
                                        <Table.Cell>
                                            <ModelInput fluid name={referenceId} onKeyDown={(event: SyntheticEvent) => { event.persist(); validNumberKey(event) }} type="number" min="0" placeholder="Podaj ilość" value={1} required />
                                            {productStockInfo(selected)}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <SubmitButton content="Zwróć" icon="checkmark" labelPosition="left" primary loading={rejecting} />
                    <Button content="Zamknij" onClick={handleCancel} />
                </Modal.Actions>
            </ModelFormWrapper>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ distrigoWarehouses }: ApplicationState) => {
    return {
        rejecting: distrigoWarehouses.rejecting,
        rejected: distrigoWarehouses.rejected
    }
}

const mapDispatchToProps: TReduxActions = { rejectToSupplier }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RejectToSupplier)