export enum DistrigoCreditActionTypes {
    FETCH_DISTRIGO_CREDIT = '@@payment_methods/FETCH_DISTRIGO_CREDIT',
    FETCH_DISTRIGO_CREDIT_SUCCESS = '@@payment_methods/FETCH_DISTRIGO_CREDIT_SUCCESS',
    CREATE_DISTRIGO_CREDIT = '@@payment_methods/CREATE_DISTRIGO_CREDIT',
    CREATE_DISTRIGO_CREDIT_SUCCESS = '@@payment_methods/CREATE_DISTRIGO_CREDIT_SUCCESS',
}

export type CustomerDistrigoCredit = {
  history: CustomerPaymentDistrigoCredit[]
  ordersAmount: number,
  invoicesAmount: number,
  currentLimitTotal: number
  current?: CustomerPaymentDistrigoCredit
}

export type CustomerPaymentDistrigoCredit = {
    amount: number,
    validUntil: string,
    renewalType: DistrigoLimitRenewalType,
    paymentDueId: string
    authorUsername?: string,
    creationTime: string,
    creationComment: string
}

export type CustomerPaymentDistrigoLimitDTO = {
    amount: number,
    validUntil: string,
    renewalType: DistrigoLimitRenewalType,
    paymentDueId: string
    creationComment: string
}

export enum LimitRenewalType {
  NONE = "NONE",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY"
}

export type DistrigoLimitRenewalType = "NONE" | "WEEKLY" | "MONTHLY" | "QUARTERLY"
 
export interface DistrigoCreditState {
    readonly loading: boolean
    readonly creating: boolean
    readonly created: boolean
    readonly currentDistrigoCredit: CustomerPaymentDistrigoCredit | null
    readonly distrigoCredit: CustomerDistrigoCredit | null
}

