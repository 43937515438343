export enum PaymentMethodActionTypes {
  FETCH_PAYMENT_METHODS = "@@payment_methods/FETCH_PAYMENT_METHODS",
  FETCH_PAYMENT_METHODS_SUCCESS = "@@payment_methods/FETCH_PAYMENT_METHODS_SUCCESS",
  FETCH_PAYMENT_METHOD_BY_ID = "@@payment_methods/FETCH_PAYMENT_METHOD_BY_ID",
  FETCH_PAYMENT_METHOD_BY_ID_SUCCESS = "@@payment_methods/FETCH_PAYMENT_METHOD_BY_ID_SUCCESS",
  FETCH_CURRENT_PAYMENT_METHOD = "@@payment_methods/FETCH_CURRENT_PAYMENT_METHOD",
  FETCH_CURRENT_PAYMENT_METHOD_SUCCESS = "@@payment_methods/FETCH_CURRENT_PAYMENT_METHOD_SUCCESS",
  CREATE_PAYMENT_METHOD = "@@payment_methods/CREATE_PAYMENT_METHOD",
  CREATE_PAYMENT_METHOD_SUCCESS = "@@payment_methods/CREATE_PAYMENT_METHOD_SUCCESS",
  CANCEL_PAYMENT_METHOD = "@@payment_methods/CANCEL_PAYMENT_METHOD",
  CANCEL_PAYMENT_METHOD_SUCCESS = "@@payment_methods/CANCEL_PAYMENT_METHOD_SUCCESS",
}

type PaymentMethodType =
  | "FACTOR"
  | "CREDIT_CARD"
  | "DISTRIGO_LIMIT"
  | "UNDEFINED";

export type CustomerPaymentMethod = {
  paymentMethodId: string;
  type: PaymentMethodType;
  validFrom: string;
  authorUsername?: string;
  creationTime: string;
  cancelUsername?: string;
  cancellationTime?: string;
  canceled: boolean;
};

export type CustomerPaymentMethodDTO = {
  type: PaymentMethodType;
  validFrom: string;
};

export interface PaymentMethodState {
  readonly loading: boolean;
  readonly creating: boolean;
  readonly created: boolean;
  readonly cancelingPaymentMethod: boolean;
  readonly customerPaymentMethods: CustomerPaymentMethod[];
  readonly customerPaymentMethod: CustomerPaymentMethod | null;
}
