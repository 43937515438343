import { DropdownProps, Icon, Pagination, PaginationProps, Popup } from 'semantic-ui-react'
import { PaginationMeta, TotalRecords } from '~/store/types'

import React from 'react'
import { PaginationInfoSelect } from "~/components/PaginationBar/PaginationInfoSelect";

export type TProps = {
    meta: PaginationMeta
    totalRecords?: TotalRecords
    pageSizeVisible?: boolean
    onPageChange: (page: number) => void
    onPageSizeChange?: (size: number) => void
    showInfo?: boolean
}

const PaginationBar: React.FC<TProps> = ({
    meta,
    totalRecords,
    pageSizeVisible = true,
    onPageChange,
    onPageSizeChange,
    showInfo
}) => {

    const pageChange = (ev: any, props: PaginationProps): void => {
        onPageChange(Number(props.activePage))
    }

    const pageSizeChange = (ev: any, props: DropdownProps): void => {
        onPageSizeChange && onPageSizeChange(Number(props.value))
    }

    return (
        <div>
            <div style={{ float: 'left', position: 'sticky', left: 6 }}>
                <Pagination size='mini' activePage={meta.page} totalPages={meta.pages || 1} onPageChange={pageChange}
                            boundaryRange={2} siblingRange={2}/>
            </div>
            {
                showInfo ? (
                    <Popup trigger={<Icon style={{ margin: '0.4rem' }} circular name='info circle'/>}>
                        Liczba wyświetlanych rekordów została ograniczona
                    </Popup>
                ) : null
            }
            <PaginationInfoSelect totalRecords={totalRecords} onPageSizeChange={onPageSizeChange}
                                  pageSizeVisible={pageSizeVisible} value={meta.size} onChange={pageSizeChange}/>
        </div>
    )
}

export default PaginationBar
