import { SupplierSystem } from "~/store/customer/types"
import { parseDate } from "~/utils/dateUtils"

/**
 * Validator for SmartField, receives value and returns error message or nothing if validation passed.
 */
export type SmartValidator = (value: any, model?: any) => string | undefined

const REQUIRED: SmartValidator = (value) => {
    if (value === null
        || value === undefined
        || value === false
        || value === ""
        || value === 0
        || JSON.stringify(value) === "[]"
        || JSON.stringify(value) === "{}"
    ) {
        return "To pole jest wymagane."
    }
}

const EMAIL: SmartValidator = (value) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return "Niepoprawny adres e-mail."
    }
}

const PHONE: SmartValidator = (value) => {
    // eslint-disable-next-line
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value)) {
        return "Nieprawidłowy numer telefonu."
    }
}

const MAX: (maximum: number) => SmartValidator = (maximum) => {
    return (value) => {
        if (value && value.length > maximum) {
            return `Maksymalna liczba znaków: ${maximum}`
        }
    }
}

const MIN: (minimum: number) => SmartValidator = (minimum) => {
    return (value) => {
        if (value && value.length < minimum) {
            return `Minimalna liczba znaków: ${minimum}`
        }
    }
}

const LENGTH: (length: number, lengthSecond?: number) => SmartValidator = (length, lengthSecond) => {
    return (value) => {
        if (value && lengthSecond) {
            if (!(value.length === length || value.length === lengthSecond)) {
                return `Wymagana liczba znaków: ${length} lub ${lengthSecond}`
            }
        }
        else if (value && value.length !== length) {
            return `Wymagana liczba znaków: ${length}`
        }
    }
}

const MIN_VALUE: (minValue: number) => SmartValidator = (minValue) => {
    return (value) => {
        if (value < minValue) {
            return `Wartość nie może być mniejsza niż ${minValue}`
        }
    }
}

const MAX_VALUE: (maxValue: number) => SmartValidator = (maxValue) => {
    return (value) => {
        if (value > maxValue) {
            return `Wartość nie może być większa niż ${maxValue}`
        }
    }
}

const NUMBER_VALUES: SmartValidator = (value) => {
    if (String(value).length >= 2 && String(value).substring(0, 1) === "0") {
        return 'Niepoprawny format liczby.'
    }
    if (!/^[0-9]*$/.test(value)) {
        return 'Pełna wartość jest wymagana.'
    }
}

const DATE: SmartValidator = (value) => {
    const todaysDate = new Date().setHours(0, 0, 0, 0)
    const inputDate = new Date(value).setHours(0, 0, 0, 0)

    if ((inputDate.valueOf() < todaysDate.valueOf()) && value !== null) {
        return "Data nie może być wcześniejsza od dzisiejszej."
    }
}

const DATE_MIN: (minDateProperty: string, message?: string) => SmartValidator = (minDateProperty, message) => {
    return (value, model) => {
        if (model && model[minDateProperty] && new Date(value).getTime() < new Date(model[minDateProperty]).getTime()) {
            return message || "Data nie może byc wcześniejsza niż " + parseDate(model[minDateProperty])
        }
    }
}

const DATE_MAX: (maxDateProperty: string, message?: string) => SmartValidator = (maxDateProperty, message) => {
    return (value, model) => {
        if (model && model[maxDateProperty] && new Date(value).getTime() > new Date(model[maxDateProperty]).getTime()) {
            return message || "Data nie może być późniejsza niż " + parseDate(model[maxDateProperty])
        }
    }
}

const CUSTOM_REGEX: (regex: RegExp, value: string) => SmartValidator = (regex, value) => {
    return (regex, value) => {
        if (regex.test(value)) {
            return "Nieprawidłowy format"
        }
    }
}

const ONLY_NUMBER_VALUES: (regex: RegExp, value: string) => SmartValidator = (regex) => {
    return (value) => {
        if (!regex.test(value)) {
            return "Nieprawidłowy format"
        }
    }
}

const SUPPLIER_SYSTEM_PAIR: (
    supplierSystem: SupplierSystem[],
    setSupplierSystem: React.Dispatch<React.SetStateAction<SupplierSystem[]>>,
    supplierSector: 'FLHAP' | 'CJD',
    dataType: 'clientId' | 'deliveryId'
    ) => SmartValidator = (supplierSystem, setSupplierSystem, supplierSector, dataType) => {
    return (value) => {
        const errorMessage = "Wszystkie pola 'odbiorca' i 'płatnik' muszą być wypełnione lub puste"
        if (!supplierSystem) {
            const sys: SupplierSystem = {
                businessSector: supplierSector,
                clientId: dataType === 'clientId' ? value : '',
                deliveryId: dataType === 'deliveryId' ? value : '',
                name: 'CSPS'
            };

            setSupplierSystem([sys])
            return errorMessage
        } else if (!supplierSystem.find(system => system.businessSector === supplierSector)) {
            const sys: SupplierSystem = {
                businessSector: supplierSector,
                clientId: dataType === 'clientId' ? value : '',
                deliveryId: dataType === 'deliveryId' ? value : '',
                name: 'CSPS'
            };
            
            setSupplierSystem([...supplierSystem, sys])

            return errorMessage
        } else if (supplierSystem.find(system => system.businessSector === supplierSector)) {
            const sys: SupplierSystem = {
                businessSector: supplierSector,
                clientId: dataType === 'clientId' ? value : supplierSystem.find(system => system.businessSector === supplierSector)?.clientId,
                deliveryId: dataType === 'deliveryId' ? value : supplierSystem.find(system => system.businessSector === supplierSector)?.deliveryId,
                name: 'CSPS'
            };
            const index = supplierSystem.findIndex(sys => sys.businessSector === supplierSector)
            const updated: SupplierSystem[] = [...supplierSystem]
            updated[index] = sys
            setSupplierSystem(updated)
            if (supplierSystem.length  < 2) {
                return errorMessage
            } else if (
                !updated[0].clientId
                && !updated[0].deliveryId
                && !updated[1].clientId
                && !updated[1].deliveryId
            ) {
                setSupplierSystem([])
                return undefined

            } else if (
                !updated[0].clientId
                || !updated[0].deliveryId
                || !updated[1].clientId
                || !updated[1].deliveryId
            ) {
                return errorMessage

            } else {
                return undefined
            }
        
        }
    }
}

export const validateDateToFrom = (dateFrom: string, dateTo: string): boolean => {
    const date1 = new Date(dateFrom)
    const date2 = new Date(dateTo)

    return (date2.valueOf() >= date1.valueOf())
}

export { 
    REQUIRED, EMAIL, PHONE, MAX, MIN, LENGTH, MIN_VALUE, MAX_VALUE, NUMBER_VALUES,
    DATE, DATE_MIN, DATE_MAX, CUSTOM_REGEX, ONLY_NUMBER_VALUES, SUPPLIER_SYSTEM_PAIR }