import { RouteComponentProps } from "react-router-dom";
import { TColumn } from "~/components/Table/lib/types";
import {
  completeCodedPart,
  fetchCodedParts,
} from "~/store/coded-parts/actions";
import { CodedPart, MappedCodedPart } from "~/store/coded-parts/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import dictionariesStatic from "~/store/dictionaries/static";
import { DictionariesState } from "~/store/dictionaries/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import translations from "~/utils/translations";
dictionariesStatic.SUPPLIER_SYSTEMS.filter((item) => item.value);
translations.format("app.table.supplierSystem.deliveryId");
export enum CodedPartStatusEnum {
  NEW = "NEW",
  ORDERED = "ORDERED",
  CANCELED = "CANCELED",
  INVOICED = "INVOICED",
}

export interface storageOrdersColumns {
  name: string;
  shouldDisplay: boolean;
}

export type TReduxState = {
  codedPartsList: CodedPart[];
  loadingCodedParts: boolean;
  meta: PaginationMeta;
  totalRecords: TotalRecords;
  dictionaries: DictionariesState;
  loadingCompleteCodedPart: boolean;
  completeCodedPartSuccess?: boolean;
};

export type TReduxActions = {
  fetchCodedParts: typeof fetchCodedParts;
  fetchDictionary: typeof fetchDictionary;
  completeCodedPart: typeof completeCodedPart;
};

export type TProps = {
  triggerOpen: boolean;
  createdSuccess: () => void;
};

type TRouteParams = RouteComponentProps<{
  manualOrderId: string;
  ldcOrderId: string;
}>;

export type TCodedPartsListProps = {
  parts: MappedCodedPart[];
  columns: TColumn[];
  cancelCodedPartReqestPending: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (newState: boolean) => void;
  loadingCompleteCodedPart: boolean;
  showDetailsId?: string;
  ldcOrderId?: string;
  completeCodedPart: typeof completeCodedPart;
};

export type TCodedPartsProps = TReduxState &
  TReduxActions &
  TProps &
  TRouteParams;

export const shouldRenderRealisationButton = (
  status: CodedPartStatusEnum
): boolean =>
  ![
    CodedPartStatusEnum.ORDERED,
    CodedPartStatusEnum.INVOICED,
    CodedPartStatusEnum.CANCELED,
  ].includes(status);
