import DetailCard from "~/components/DetailCard/DetailCard";
import { Form, Select } from "semantic-ui-react";
import UberSearch from "~/components/UberSearch/UberSearch";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import React, { useEffect } from "react";
import { CardDetailCustomerProps } from "~/pages/Orders/lib/Add/Customer/constants";
import translations from "~/utils/translations";
import { DeliveryLocation } from "~/store/customer/types";

const CardDetailCustomer = ({
  shrink,
  order,
  summary,
  mapDelivery,
  selectDeliveryPoint,
  deliveryDetails,
  formatDeliveryAddress,
  forClientPanel,
  deliveryAddresses,
}: CardDetailCustomerProps) => {
  const renderLabel = (item: DeliveryLocation) => (
    <div>
      <p style={{ marginBottom: 0 }}>{item.rrdi + ", " + item.street}</p>
      <p>{item.company}</p>
    </div>
  );

  const textContent = (elem: React.ReactNode): string => {
    if (!elem) {
      return "";
    }
    if (
      typeof elem === "string" ||
      typeof elem === "number" ||
      typeof elem === "boolean" ||
      !("props" in elem)
    ) {
      return elem.toString();
    }
    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
      return children.map(textContent).join("");
    }
    return textContent(children);
  };

  useEffect(() => {
    if (deliveryAddresses && deliveryAddresses.length === 1) {
      selectDeliveryPoint(
        deliveryAddresses.map((item) => ({
          text: renderLabel(item),
          value: item.rrdi,
        }))[0]
      );
    }
  }, [deliveryAddresses, selectDeliveryPoint]);

  return (
    <DetailCard
      title="app.customer.delivery"
      id="client"
      key="client"
      width={shrink ? 3.5 : 4}
      smallWidth={5.33}
      style={{ zIndex: 30 }}
    >
      {!forClientPanel ? (
        <>
          <Form.Field disabled={!order.type || summary}>
            <UberSearch
              placeholder="Wyszukaj..."
              fluid
              endpoint={`/customers/delivery-points`}
              mapper={(response) => mapDelivery(response)}
              onResultSelect={(selected: any) => selectDeliveryPoint(selected)}
              debounce={700}
            />
          </Form.Field>
          {!!order.deliveryCustomerId && (
            <>
              <SmartLabel
                label="Nazwa"
                value={deliveryDetails && deliveryDetails.company}
              />
              <SmartLabel
                label="Adres"
                value={
                  deliveryDetails && formatDeliveryAddress(deliveryDetails)
                }
              />
              <SmartLabel label="Płatnik" value={order.customerId} />
              <SmartLabel
                label="PCD płatnik"
                value={deliveryDetails!.sapClientId}
              />
              <SmartLabel
                label={translations.format("app.customer.FLHAP-Payer")}
                value={
                  deliveryDetails &&
                  deliveryDetails.supplierSystem &&
                  deliveryDetails.supplierSystem.findIndex(
                    (sys) => sys.businessSector === "FLHAP"
                  ) >= 0
                    ? deliveryDetails.supplierSystem[
                        deliveryDetails.supplierSystem.findIndex(
                          (sys) => sys.businessSector === "FLHAP"
                        )
                      ].clientId
                    : "-"
                }
              />
              <SmartLabel
                label={translations.format("app.customer.CJD-Payer")}
                value={
                  deliveryDetails &&
                  deliveryDetails.supplierSystem &&
                  deliveryDetails.supplierSystem.findIndex(
                    (sys) => sys.businessSector === "CJD"
                  ) >= 0
                    ? deliveryDetails.supplierSystem[
                        deliveryDetails.supplierSystem.findIndex(
                          (sys) => sys.businessSector === "CJD"
                        )
                      ].clientId
                    : "-"
                }
              />
              {deliveryDetails?.discountDRZPercentage ? (
                <SmartLabel
                  label="Rabat DRZ"
                  value={deliveryDetails.discountDRZPercentage + "%"}
                />
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Form.Field disabled={!order.type || summary}>
            <Select
              options={
                deliveryAddresses
                  ? deliveryAddresses.map((item) => ({
                      text: renderLabel(item),
                      value: item.rrdi,
                    }))
                  : []
              }
              search={(items, text) => {
                return items.filter((item) =>
                  textContent(item.text).includes(text)
                );
              }}
              onChange={(e, data) => selectDeliveryPoint(data)}
              disabled={deliveryAddresses?.length === 1}
            />
          </Form.Field>
          {!!order.deliveryCustomerId ? (
            <>
              <SmartLabel
                label="Nazwa"
                value={deliveryDetails && deliveryDetails.company}
              />
              <SmartLabel
                label="Adres"
                value={
                  deliveryDetails && formatDeliveryAddress(deliveryDetails)
                }
              />
              <SmartLabel label="Płatnik" value={order.customerId} />
            </>
          ) : null}
        </>
      )}
    </DetailCard>
  );
};

export default CardDetailCustomer;
