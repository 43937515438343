import { Reducer } from "redux";
import { ErrorActionTypes } from "~/store/error/types";
import { Paginable } from "~/store/types";
import { SparePart, SparePartsActionTypes, SparePartsState } from "./types";

export const initialSparePartsState: SparePartsState = {
  loadingSpareParts: false,
  loadingSparePart: false,
  loadingSparePartCP: false,
  list: [],
  customerPanelList: [],
  searchList: [],
  meta: {
    page: 1,
    pages: 1,
    size: 15,
    total: 0,
  },
  selected: undefined,
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  importing: false,
};

const reducer: Reducer<SparePartsState> = (
  state = initialSparePartsState,
  action
): SparePartsState => {
  switch (action.type) {
    case SparePartsActionTypes.FETCH_SPAREPARTS_REQUEST: {
      return {
        ...state,
        loadingSpareParts: true,
        importing: false,
        imported: undefined,
      };
    }
    case SparePartsActionTypes.FETCH_SPAREPARTS_SUCCESS: {
      const payload = action.payload as Paginable<SparePart>;
      return {
        ...state,
        loadingSpareParts: false,
        list: payload.content,
        searchList: payload.content,
        meta: {
          ...state.meta,
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_SUCCESS: {
      return { ...state, searchList: action.payload.spareParts.content };
    }
    case SparePartsActionTypes.SELECT_SPAREPART: {
      return { ...state, loadingSparePart: true };
    }
    case SparePartsActionTypes.SPAREPART_SELECTED: {
      return { ...state, loadingSparePart: false, selected: action.payload };
    }
    case SparePartsActionTypes.IMPORT_REFERENCES: {
      return { ...state, importing: true, imported: undefined };
    }
    case SparePartsActionTypes.IMPORT_FINISHED: {
      return { ...state, importing: false, imported: true };
    }
    case SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP: {
      return { ...state, loadingSparePartCP: true };
    }
    case SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP_SUCCESS: {
      let list = [...state.customerPanelList];
      if (action.payload.sparePart) {
        const isExist = list.find(
          (item) =>
            !!(item.referenceId === action.payload.sparePart.referenceId)
        );
        if (!isExist) {
          list.push(action.payload.sparePart);
        } else {
          action.payload.setErrorInfo(
            "Wybrana referencja występuje już na liście"
          );
        }
      }
      return {
        ...state,
        customerPanelList: list,
        loadingSparePartCP: false,
      };
    }
    case SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP_ERROR: {
      return { ...state, loadingSparePartCP: false };
    }
    case SparePartsActionTypes.REMOVE_SPAREPARTS_FOR_CP: {
      const indexToRemove = state.customerPanelList.findIndex(
        (item) => item.referenceId === action.payload
      );
      const newList = [...state.customerPanelList];
      newList.splice(indexToRemove, 1);
      return { ...state, customerPanelList: newList };
    }
    case SparePartsActionTypes.REMOVE_ALL_SPAREPARTS: {
      return { ...state, customerPanelList: [] };
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        loadingSparePart: false,
        loadingSpareParts: false,
        importing: false,
      };
    }
  }

  return state;
};

export { reducer as sparePartsReducer };
