import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { reducer } from "~/utils/reducer";
import { DictionariesState, DictionaryItem } from "~/store/dictionaries/types";
import { ClaimGroup } from "~/pages/Claims/lib/claimTypes";
import { claimCategories } from "~/pages/Claims/lib/claimCategoryDefinitions.conf";
import { IClaim } from "~/store/claims/types";
import claimTypeMap from "~/pages/Claims/lib/claimTypeMap.conf";
const _ = require("lodash");

type TProps = {
  dictionaries: DictionariesState;
  claim: IClaim;
};

export const useClaimCloningState = ({ dictionaries, claim }: TProps) => {
  const [group, setGroup] = useState(claim.claimGroup);
  const [invoice, setInvoice] = useState<string>(claim.invoiceDocumentMask);
  const [category, setCategory] = useState(claim.claimCategory);
  const [type, setType] = useState(claim.claimType);
  const [actors, setActors] = useState<any[]>(["WAREHOUSE", "TRANSPORT"]);
  const [processAutomatically, setProcessAutomatically] =
    useState<boolean>(false);
  const [description, setDescription] = useState(claim.description);
  const [lines, setLines] = useState(claim.claimLines);
  const [searchValue, setSearchValue] = useState("");
  const [searchPackageNr, setSearchPackageNr] = useState("");

  const [tableData, setTableData] = React.useReducer(reducer, {
    data: lines,
    direction: null,
    loading: false,
    checkedLine: null,
    selected: null,
    column: null,
  });

  const timeoutRef = React.useRef();

  const categoriesWithActors = useMemo(
    () => ["RK1_ZNRE", "RK1_ZRDP", "RK1_ZRVE"],
    []
  );

  const resetAfterSearchType = useCallback(() => {
    setTableData({ type: "INIT_TABLE", column: null, data: [] });
    setLines([]);
  }, []);

  const isDFSCategoryOrigin = useCallback((line: any): boolean => {
    return line.categoryOrigin && ["2", "4", "5"].includes(line.categoryOrigin);
  }, []);

  const filterType = useCallback(
    (types: DictionaryItem[], category: string): DictionaryItem[] => {
      return types.filter((type) => {
        return (
          category &&
          // @ts-ignore
          claimTypeMap[`${category}`] &&
          // @ts-ignore
          claimTypeMap[`${category}`].includes(type.key)
        );
      });
    },
    []
  );

  const changeCategory = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setCategory(data.value);

      const type = filterType(dictionaries["claim-type"], data.value);
      if (type.length === 1) {
        setType(type[0].value);
      } else {
        setType(ClaimGroup.unset);
      }
      switch (data.value) {
        case claimCategories.RK1_ZNRE:
          setActors(["WAREHOUSE", "TRANSPORT"]);
          setProcessAutomatically(true);
          break;
        case claimCategories.RK1_ZRDP:
        case claimCategories.RK1_ZRVE:
          setActors(["WAREHOUSE", "TRANSPORT"]);
          setProcessAutomatically(false);
          break;
        case claimCategories.RK1_ZRPN:
          setActors([]);
          setProcessAutomatically(true);
          break;
        default:
          setActors([]);
          setProcessAutomatically(false);
          break;
      }
    },
    [dictionaries, filterType]
  );

  const changeActors = useCallback((event: SyntheticEvent, data: any): void => {
    setActors(data.value);
  }, []);

  const changeType = useCallback((event: SyntheticEvent, data: any): void => {
    setType(data.value);
  }, []);

  const inputDescription = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setDescription(data.value);
    },
    []
  );

  const changeProcessAutomatically = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setProcessAutomatically(data.checked);
    },
    []
  );

  const filterCategory = (
    categories: DictionaryItem[],
    group: string
  ): DictionaryItem[] => {
    return categories
      .filter((category) => {
        const categoryKeyArray = category.key.split("_");
        return categoryKeyArray[0] === group;
      })
      .filter((item) => item.value !== "RK1_ZRPN");
  };

  const changeInvoice = useCallback((): void => {
    let invoiceLines = claim.claimLines;

    const linesToSet = invoiceLines;
    const mappedLines = linesToSet.map((line) => {
      return {
        lineNumber: line.lineNumber,
        referenceId: line.referenceId,
        quantity: line.billedQuantity,
        quantityOrgin: line.billedQuantity,
        orderedQuantity: line.billedQuantity,
        orderNumber: line.orderNumber,
        quantityCopy: line.quantity,
        packingForceQuantity: line.packingForceQuantity || 1,
        selected: false,
        pvpPrice: isDFSCategoryOrigin(line) ? line.pvpPrice : undefined,
        pnrPrice: isDFSCategoryOrigin(line) ? line.pnrPrice : undefined,
        packageNumber: line.packageNumber ? [line.packageNumber] : undefined,
      };
    });
    setLines(mappedLines as any);
    setTableData({
      type: "INIT_TABLE",
      column: "lineNumber",
      data: mappedLines.map((line) => {
        if (line.orderedQuantity && line.quantity > line.orderedQuantity) {
          return {
            ...line,
            quantityOrgin: line.quantity,
            orderedQuantity: line.quantity,
          };
        } else {
          return line;
        }
      }),
    });
  }, [isDFSCategoryOrigin, claim.claimLines]);

  const handleSearchChange = useCallback(() => {
    setTableData({ type: "START_SEARCH", query: "query" });
    timeoutRef.current = setTimeout(() => {
      if (searchValue.length === 0 && searchPackageNr.length === 0) {
        setTableData({ type: "INIT_TABLE", data: lines });
        return;
      }
      const re = new RegExp(_.escapeRegExp(searchValue), "i");
      const rePackageNr = new RegExp(_.escapeRegExp(searchPackageNr), "i");
      const isMatch = (result: any) => {
        return (
          re.test(result.referenceId + result.orderNumber) &&
          rePackageNr.test(result.packageNumber)
        );
      };
      setTableData({
        type: "FINISH_SEARCH",
        results: _.filter(lines, isMatch),
        data: _.filter(lines, isMatch),
      });
    }, 300) as any;
  }, [lines, searchValue, searchPackageNr, setTableData]);

  useEffect(() => {
    handleSearchChange();
  }, [searchValue, searchPackageNr, handleSearchChange]);

  return {
    group,
    setGroup,
    invoice,
    setInvoice,
    category,
    setCategory,
    actors,
    setActors,
    type,
    setType,
    description,
    setDescription,
    lines,
    setLines,
    processAutomatically,
    setProcessAutomatically,
    tableData,
    setTableData,
    categoriesWithActors,
    searchValue,
    setSearchValue,
    searchPackageNr,
    setSearchPackageNr,
    resetAfterSearchType,
    isDFSCategoryOrigin,
    filterType,
    changeCategory,
    changeType,
    changeActors,
    inputDescription,
    changeProcessAutomatically,
    filterCategory,
    changeInvoice,
  };
};
