import translations from "~/utils/translations";
import { useHistory } from "react-router";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

type TProp = {
  setOpenUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasRole: (roleName: string) => boolean;
  handleExport: () => void;
  exportPending: boolean | undefined;
};

export const useOrdersClientPanelButtons = (props: TProp) => {
  const history = useHistory();

  const openCreatePage = (): void => {
    history.push("/order/create");
  };

  const addButton = {
    icon: "add",
    content: translations.format("app.button.add"),
    primary: true,
    onClick: () => openCreatePage(),
    visible: props.hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_EDIT),
  };

  // hidden at the customer's request
  // const importButton = {
  //   icon: "upload",
  //   content: "Import zamówienia",
  //   primary: true,
  //   onClick: () => props.setOpenUploadModal(true),
  //   visible: props.hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_EDIT),
  // };

  const downloadButton = {
    icon: "download",
    content: translations.format("app.button.orders.export"),
    primary: true,
    loading: props.exportPending,
    onClick: () => props.handleExport(),
  };

  return [addButton, downloadButton];
};
