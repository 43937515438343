import * as React from "react";
import { useContext, useMemo } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { Segment, Sidebar } from "semantic-ui-react";

import { Dashboard } from "~/pages/Dashboard/Dashboard";
import Customers from "~/pages/Customers/Customers";
import CustomerDetails from "~/pages/Customers/Details";
import Orders from "~/pages/Orders/Orders";
import OrderDetails from "~/pages/Orders/Details";
import Products from "~/pages/SpareParts/SpareParts";
import PsaHeader from "~/components/PsaHeader/PsaHeader";
import SideBarMenu from "~/components/Sidebar/Sidebar";
import Backorders from "~/pages/Backorders/Backorders";
import BackorderDetails from "~/pages/Backorders/Details";
import SparePartDetails from "~/pages/SpareParts/Details";
import DistrigoWarehouses from "~/pages/Warehouses/DistrigoWarehouses";
import StocksWarehouse from "~/pages/StockProducts";

import AnomalyDetails from "~/pages/Anomalies/Details";
import Promotions from "~/pages/Promotions/Promotions";
import PromotionDetails from "~/pages/Promotions/Details";
import Claims from "~/pages/Claims/Claims";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary";
import Notifications from "~/pages/Notifications";
import Accounting from "~/pages/Accounting/Accounting";
import ClaimDetails from "~/pages/Claims/Details/ClaimDetails";
import Reports from "~/pages/Reports";
import AddOrder from "~/pages/Orders/lib/Add";
import CancelOrder from "~/pages/Orders/lib/CancelOrder/CancelOrder";
import CreateReceiptOfWare from "~/pages/StockProducts/lib/CreateReceiptOfWare/CreateReceiptOfWare";
import WarehouseDetails from "./pages/Warehouses/Details/WarehouseDetails";
import Replenishments from "./pages/Replenishments/Replenishments";
import PromotionImport from "./pages/Promotions/Details/PromotionImport";
import PromotionImportList from "./pages/Promotions/Details/PromotionImportList";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import ManualOrders from "./pages/ManualOrders/ManualOrders";
import Anomalies from "./pages/Anomalies/Anomalies";
import { ClientPanelRoutes } from "./routesClientPanel";
import Administration from "./pages/Administration/Administration";

export interface CustomRouteProps extends RouteProps {
  role?: AVAILABLE_ROLE_ENUM;
  availableForCustomer?: boolean;
  availableForGroups?: string[];
}

export const ProtectedRoute = ({
  role,
  availableForCustomer,
  availableForGroups,
  ...props
}: CustomRouteProps) => {
  const appContext = useContext(AppContext);
  const isInAllowedGroup = useMemo(
    () =>
      !!appContext?.userInfo.userGroup.find((item) =>
        availableForGroups?.find((group) => group === item)
      ),
    [appContext, availableForGroups]
  );
  const isAllowed = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(role) ||
      availableForCustomer ||
      isInAllowedGroup,
    [appContext, role, availableForCustomer, isInAllowedGroup]
  );
  return isAllowed ? <Route strict {...props} /> : <Redirect to="/" />;
};

const Routes: React.FC = (): JSX.Element => {
  const appContext = useContext(AppContext);
  const isClientPanelUser = useMemo(
    () => appContext?.clientPanelUser,
    [appContext]
  );
  return (
    <div>
      <PsaHeader />
      <Sidebar.Pushable>
        <SideBarMenu />
        <Sidebar.Pusher>
          <Segment basic>
            <ErrorBoundary>
              {isClientPanelUser ? (
                <ClientPanelRoutes />
              ) : (
                <Switch>
                  <Redirect exact from="/" to="/dashboard" />
                  <Route path="/dashboard" component={Dashboard} />
                  <ProtectedRoute
                    path="/customers"
                    component={Customers}
                    role={AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_VIEW}
                  />
                  <ProtectedRoute
                    path="/customer/:rrdi/:edit?"
                    component={CustomerDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_VIEW}
                  />
                  <ProtectedRoute
                    path="/orders"
                    component={Orders}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/order/create"
                    component={AddOrder}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/order/cancel"
                    component={CancelOrder}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/order/:id"
                    component={OrderDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/backorders"
                    component={Backorders}
                    role={AVAILABLE_ROLE_ENUM.LDC_BACKORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/backorder/:id/:edit?"
                    component={BackorderDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_BACKORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/promotions"
                    component={Promotions}
                    role={AVAILABLE_ROLE_ENUM.LDC_PROMOTION_VIEW}
                  />
                  <ProtectedRoute
                    path="/promotion/import-promotion"
                    component={PromotionImport}
                    role={AVAILABLE_ROLE_ENUM.LDC_PROMOTION_VIEW}
                  />
                  <ProtectedRoute
                    path="/promotion/import-promotion-list"
                    component={PromotionImportList}
                    role={AVAILABLE_ROLE_ENUM.LDC_PROMOTION_VIEW}
                  />
                  <ProtectedRoute
                    path="/promotion/:id/:mode?"
                    component={PromotionDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_PROMOTION_VIEW}
                  />
                  <ProtectedRoute
                    path="/products"
                    component={Products}
                    role={AVAILABLE_ROLE_ENUM.LDC_PRODUCT_VIEW}
                  />
                  <ProtectedRoute
                    path="/product/:referenceId/:edit?"
                    component={SparePartDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_PRODUCT_VIEW}
                  />
                  <ProtectedRoute
                    path="/distrigo-warehouses"
                    component={DistrigoWarehouses}
                    exact
                    role={AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_VIEW}
                  />
                  <ProtectedRoute
                    path="/distrigo-warehouses/:id"
                    component={WarehouseDetails}
                    exact
                    role={AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_VIEW}
                  />
                  <ProtectedRoute
                    path="/stock-products/:id"
                    component={StocksWarehouse}
                    role={AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_VIEW}
                  />
                  <ProtectedRoute
                    path="/stock-product/:warehouseId/create-receipt-of-ware"
                    component={CreateReceiptOfWare}
                    role={AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_VIEW}
                  />
                  <ProtectedRoute
                    path="/administration"
                    component={Administration}
                    role={AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_VIEW}
                  />
                  <ProtectedRoute
                    path="/anomalies"
                    component={Anomalies}
                    role={AVAILABLE_ROLE_ENUM.LDC_ANOMALY_VIEW}
                  />
                  <ProtectedRoute
                    path="/anomaly/:anomalyId"
                    component={AnomalyDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_ANOMALY_VIEW}
                  />
                  <ProtectedRoute
                    path="/claims"
                    component={Claims}
                    role={AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW}
                  />
                  <ProtectedRoute
                    path="/claim/:claimNumber"
                    component={ClaimDetails}
                    role={AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW}
                  />
                  <Route
                    path="/replenishments/:warehouseID/:partID"
                    component={Replenishments}
                  />
                  <Route path="/notifications" component={Notifications} />
                  <ProtectedRoute
                    path="/accounting"
                    component={Accounting}
                    role={AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_VIEW}
                  />
                  <ProtectedRoute
                    path="/manual-orders"
                    component={ManualOrders}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/manual-order/:manualOrderId/:ldcOrderId"
                    component={ManualOrders}
                    role={AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW}
                  />
                  <ProtectedRoute
                    path="/reports"
                    component={Reports}
                    role={AVAILABLE_ROLE_ENUM.LDC_REPORTS_VIEW}
                  />
                  <Route component={() => <div>Not Found</div>} />
                </Switch>
              )}
            </ErrorBoundary>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default Routes;
