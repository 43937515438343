import { Order, OrderStatusEnum } from "~/store/orders/types";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import React from "react";
import DropdownItemOrderStatusOption from "~/pages/Orders/lib/DropdownItemOrderStatusOption";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";

interface ProductListDropdownProps {
  order: Order;
  onlyViewAction?: boolean;
  statusChangeHandler: (id: string, status: string) => void;
  cancelRole?: boolean;
}

const ProductListDropdown = (props: ProductListDropdownProps): JSX.Element => {
  const { isClientPanelUser } = useClientPanelRoles();
  return isClientPanelUser ||
    props.onlyViewAction ||
    props.order.status === OrderStatusEnum.COMPLETED ||
    props.order.status === OrderStatusEnum.REJECTED ? (
    <TablePreviewDetails path={`/order/${props.order.id}`} />
  ) : (
    <TableMoreDropdown>
      <Dropdown.Item
        as={Link}
        to={`/order/${props.order.id}`}
        text="Podgląd zamówienia"
      />
      {props.order.status !== OrderStatusEnum.CANCELLED && <Dropdown.Divider />}

      {props.cancelRole && (
        <>
          <DropdownItemOrderStatusOption
            id={props.order.id}
            status={props.order.status}
            cancelable={props.order.cancelable}
            enabledStatuses={[
              OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE,
              OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
            ]}
            className={"text-green"}
            text={"Akceptacja zamówienia"}
            statusToChange={OrderStatusEnum.ACCEPTED}
            statusChangeHandler={props.statusChangeHandler}
          />

          <DropdownItemOrderStatusOption
            id={props.order.id}
            status={props.order.status}
            cancelable={props.order.cancelable}
            enabledStatuses={[
              OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE,
              OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
            ]}
            className={"text-red"}
            text={"Odrzucenie zamówienia"}
            statusToChange={OrderStatusEnum.REJECTED}
            statusChangeHandler={props.statusChangeHandler}
          />

          <DropdownItemOrderStatusOption
            id={props.order.id}
            status={props.order.status}
            cancelable={props.order.cancelable}
            enabledStatuses={[
              OrderStatusEnum.NEW,
              OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE,
              OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
              OrderStatusEnum.ACCEPTED,
            ]}
            className={"text-red"}
            text={"Anulowanie zamówienia"}
            statusToChange={OrderStatusEnum.CANCELLED}
            statusChangeHandler={props.statusChangeHandler}
          />
        </>
      )}
    </TableMoreDropdown>
  );
};

export default ProductListDropdown;
