import { Icon, Popup } from 'semantic-ui-react';

import React from 'react';

type TProps = {
  documents: any[];
  noResultsMessage: string | JSX.Element;
  clickableItem: (item: any) => JSX.Element;
  position?:
    | 'bottom right'
    | 'top left'
    | 'top right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
    | undefined;
};

const InfoIcon: React.FC<TProps> = ({ documents = [], noResultsMessage, clickableItem, position }) => {
  return (
    <Popup
      position={position ? position : 'bottom right'}
      size="small"
      flowing
      content={documents.length === 0 ? noResultsMessage : documents.map(clickableItem)}
      on="click"
      closeOnPortalMouseLeave
      trigger={
        <Icon.Group size="large" style={{ marginLeft: 10, cursor: 'pointer', opacity: !documents.length ? 0.2 : 1 }}>
          <Icon name="circle outline" fitted />
          <Icon name="info" size="mini" fitted />
        </Icon.Group>
      }
    />
  );
};

export default InfoIcon;
