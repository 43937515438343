import { CardKey } from '~/pages/Customers/Details/constants/cardKey';
import { TCardsMapping } from '~/pages/Customers/Details/constants/TCardsMapping';

const mapping: TCardsMapping = {
  main: [
    CardKey.identification,
    CardKey.personality,
    CardKey.classesAndBrands,
    CardKey.characteristic,
    CardKey.contact,
    CardKey.brandsCodes,
  ],
  address: [CardKey.address],
  payments: [CardKey.activePaymentForm, CardKey.addPaymentForm],
  logistic: [CardKey.warehouse0, CardKey.warehouse1, CardKey.warehouse2, CardKey.warehouse3, CardKey.addWarehouse],
  bookkeeping: [CardKey.paymentPerson, CardKey.docsDistribution, CardKey.bank],
  gdpr: [CardKey.permissions],
  other: [CardKey.adnotation, CardKey.additionalDiscountCode],
  client: [CardKey.userAccount, CardKey.userStat],
  all: [],
  factor: [CardKey.factor0, CardKey.factor1, CardKey.factor2, CardKey.factor3, CardKey.addFactor],
  distrigoLoan: [CardKey.distrigoLoanLimit, CardKey.addNewLoan, CardKey.creditRules],
  changePayer: [CardKey.payerDetails],
};

mapping.all = [
  ...mapping.main,
  ...mapping.address,
  ...mapping.payments,
  ...mapping.logistic,
  ...mapping.factor,
  ...mapping.distrigoLoan,
  ...mapping.bookkeeping,
  ...mapping.gdpr,
  ...mapping.other,
  ...mapping.client,
  ...mapping.changePayer,
];

export default mapping;
