import React, { useMemo } from "react";
import { Label, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import OrdersList from "~/pages/Orders/List";
import { sourceColorMap, sourceNameMap } from "~/pages/Orders/lib/helpers";
import { useCellsRenderer } from "~/services/useCellRenderer";
import dictionariesStatic from "~/store/dictionaries/static";
import { Order } from "~/store/orders/types";

export const useOrdersTable = (orders: Order[]) => {
  const { getTextCell, getDateCell, getLinkCell } = useCellsRenderer();
  const getSourceCell = (source: string) => {
    return (
      <Table.Cell key={"source-cell"}>
        <Label basic size="tiny" color={sourceColorMap[source] || "grey"}>
          {sourceNameMap[source] || "?"}
        </Label>
      </Table.Cell>
    );
  };
  const columns: TColumn[] = useMemo(
    () => [
      {
        name: "orderNumber",
        label: "Numer",
        projection: true,
        getCell: (d, c) => getTextCell(d, c),
      },
      {
        name: "orderDate",
        label: "Utworzone",
        projection: true,
        getCell: (d, c) => getDateCell(d, c, true),
      },
      {
        name: "customer.rrid",
        label: "Płatnik",
        projection: true,
        getCell: (d, c) => getLinkCell(d, c, `/customer/${d.customer.rrid}`),
      },
      {
        name: "deliveryCustomer.rrid",
        label: "Odbiorca",
        projection: true,
        getCell: (d, c) =>
          getLinkCell(d, c, `/customer/${d.deliveryCustomer.rrid}`),
      },
      {
        name: "source",
        label: "Źródło",
        dictionary: dictionariesStatic.ORDER_SOURCE,
        projection: true,
        getCell: (d) => getSourceCell(d.source),
      },
    ],
    [getDateCell, getLinkCell, getTextCell]
  );

  const renderOrdersTable = () => (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {columns.map((column) => (
            <Table.HeaderCell key={column.name}>
              {column.label}
            </Table.HeaderCell>
          ))}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <OrdersList orders={orders} columns={columns} onlyViewAction />
      </Table.Body>
    </Table>
  );

  return { renderOrdersTable };
};
