import { Table } from "semantic-ui-react";

import React from "react";
import "./Customer.scss";
import TableNoResults from "~/components/TableNoResults/TableNoResults";

import CustomerListDropdown from "~/pages/Customers/CustomerListDropdown";
import { Props } from "~/pages/Customers/constants";
import { useCustomersRolesRights } from "./hooks/useCustomersRights";

const CustomersList: React.FC<Props> = ({ customers, columns }) => {
  const { userHaveLdcUiCustomerEditRole } = useCustomersRolesRights();

  if (customers.length === 0) {
    return <TableNoResults />;
  }

  return (
    <>
      {customers.map((customer) => (
        <Table.Row key={customer.rrdi + Math.random()}>
          {columns.map(
            (column) =>
              column.projection &&
              column.getCell &&
              column.getCell(customer, column)
          )}
          <Table.Cell className="col-dropdown-menu-sticky">
            <CustomerListDropdown
              customer={customer}
              userHaveLdcUiCustomerEditRole={userHaveLdcUiCustomerEditRole}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default CustomersList;
