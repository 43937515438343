import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Table } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { fetchReplenishmentPrediction } from "~/store/replenishment/actions";
import ReplenishmentsList from "./List";
import { columnsReplenishments } from "./replenishments.conf";
import translations from "~/utils/translations";
import { DictionaryName } from "~/store/dictionaries/types";

export interface Replenishment {
  replenishmentID: {
    orderID: string;
    partID: string;
    warehouseID: string;
  };
  replacedPartID: string;
  orderID: string;
  deliveryID: string;
  ordered: string;
  coveredByBackorders: any;
  backorders: {
    backorderNumber: string;
    backorderID: string;
  }[];
  coveredByCreatedGrns: any;
  grns: string[];
  replenished: string;
  gdnLines: {
    gdnId: string;
    partID: string;
    partsDispatched: null | string;
    quantity: number;
    status: string;
  }[];
  orderType: string;
  creationDate: string;
  status: string;
  priority: string;
  dispatched: number;
  awaitingDispatch: number;
}

const Replenishments = () => {
  let { warehouseID, partID } = useParams<{
    warehouseID: string;
    partID: string;
  }>();
  const {
    replenishment: { replenishments, loading },
    dictionaries,
  } = useSelector((state: ApplicationState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchDictionary(
        DictionaryName.claimStatus,
        DictionaryName.orderDeliveryType
      )
    );
  }, [dispatch]);

  useEffect(() => {
    document.title = translations.format("app.replenishments");
    dispatch(fetchReplenishmentPrediction(warehouseID, partID));
  }, [partID, warehouseID, dispatch]);

  return (
    <>
      <PageHeader
        icon="warehouse"
        title={`Produkt ${partID}`}
        breadcrumb={[
          { text: "Magazyny", link: "/distrigo-warehouses" },
          { text: `${warehouseID}`, link: `/stock-products/${warehouseID}` },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={[]}
        loading={loading}
      />

      <div className="uber-content">
        <Grid stretched>
          <DetailCard
            title="Lista realizacji zamówień oczekujących na uzupełnienie magazynu"
            id="replenishmentLines"
            key="replenishmentLines"
            titleFormat={true}
            width={16}
            minHeight={100}
          >
            <div style={{ overflowX: "scroll" }}>
              <Table selectable>
                <Table.Header>
                  <Table.Row>
                    {columnsReplenishments.map(
                      (column) =>
                        column.projection && (
                          <Table.HeaderCell
                            key={column.name}
                            style={{ width: column.width }}
                          >
                            {column.label}
                          </Table.HeaderCell>
                        )
                    )}
                    <Table.HeaderCell collapsing />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <ReplenishmentsList
                    replenishments={replenishments}
                    columns={columnsReplenishments}
                    dictionaries={dictionaries}
                  />
                </Table.Body>
              </Table>
            </div>
          </DetailCard>
        </Grid>
      </div>
      <CommonLoader loading={loading} />
    </>
  );
};

export default Replenishments;
