import { Form, Label } from 'semantic-ui-react'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { SmartValidator } from '~/components/SmartField/lib/validator'
import { TPromotion } from '~/store/promotions/types'

import React from 'react'

type TProps = {
    promotion: TPromotion,
    editMode: boolean,
    discountGroup: 'firstDiscount' | 'secondDiscount'
}

const MinimumCondition: React.FC<TProps> = ({
    promotion,
    editMode,
    discountGroup
}) => {

    const { renderField } = useRenderingFunctions(promotion as any, editMode)

    const saveMapper = (v: string) => v === '0' ? null : Number(v)

    const VALIDATE_PRICE: SmartValidator = (value, model: TPromotion) => {
        const discount = model[discountGroup]
        if (!value && !(discount && discount.quantityThreshold)) {
            return "Co najmniej jedno z pól jest wymagane: Minimalna wartość lub Minimalna liczba produktów."
        }
    }

    const VALIDATE_QUANTITY: SmartValidator = (value, model: TPromotion) => {
        const discount = model[discountGroup]
        if (!value && !(discount && discount.priceThreshold)) {
            return "Co najmniej jedno z pól jest wymagane: Minimalna wartość lub Minimalna liczba produktów."
        }
    }

    return (<>
        <Form.Group widths="equal">
            {renderField(`${discountGroup}.priceThreshold`, { label: "Min. wartość produktów (zł)", type: 'number', saveMapper, min: 0, precision: 2, description: "Minimalna wartość produktów w zamówieniu (bez VAT) wymagana do zastosowania upustu.", validators: [VALIDATE_PRICE] })}
            {renderField(`${discountGroup}.quantityThreshold`, { label: "Min. liczba produktów (szt.)", type: 'number', saveMapper, min: 0, description: "Minimalna liczba produktów w zamówieniu wymagana do zastosowania upustu.", validators: [VALIDATE_QUANTITY] })}
        </Form.Group>
        <Label pointing="above" style={{ lineHeight: '18px' }}>
            Obydwa warunki muszą być spełnione jednocześnie, aby próg został zastosowany. Wymagane podanie co najmniej jednego warunku.
        </Label>
    </>)
}

export default MinimumCondition