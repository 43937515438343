import { Dropdown } from "semantic-ui-react";
import { User } from "~/store/users/types";

import ChangeGroup from "../MenageUserRole";
import React from "react";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { UserTableDropdownProps } from "~/pages/Administration/lib/UserTableDropdown/constants";

class UserTableDropdown extends React.Component<UserTableDropdownProps> {
  private static getUserName(user: User): string {
    return `${user.username} ${user.email ? `(${user.email})` : ""}`;
  }

  render() {
    return (
      <TableMoreDropdown>
        {this.props.user.enabled ? (
          <Dropdown.Item
            text="Dezaktywuj użytkownika"
            style={{ color: "red" }}
            onClick={() =>
              this.props.onDeactivateUser(
                this.props.user.id,
                UserTableDropdown.getUserName(this.props.user)
              )
            }
          />
        ) : (
          <Dropdown.Item
            text="Aktywuj użytkownika"
            style={{ color: "green" }}
            onClick={() =>
              this.props.onActivateUser(
                this.props.user.id,
                UserTableDropdown.getUserName(this.props.user)
              )
            }
          />
        )}
        <ChangeGroup
          userId={this.props.user.id}
          userName={UserTableDropdown.getUserName(this.props.user)}
        >
          <Dropdown.Item text="Zarządzaj prawami użytkownika" />
        </ChangeGroup>
        <Dropdown.Divider />
        <Dropdown.Item
          text="Usuń użytkownika"
          style={{ color: "red" }}
          onClick={() =>
            this.props.onRemoveUser(
              this.props.user.id,
              UserTableDropdown.getUserName(this.props.user)
            )
          }
        />
      </TableMoreDropdown>
    );
  }
}

export default UserTableDropdown;
