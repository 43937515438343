import { connect } from "react-redux";
import {
  Button,
  Header,
  Modal,
  Table,
  Grid,
  Container,
} from "semantic-ui-react";
import { ApplicationState } from "~/store";
import { selectGoodsDispatchNote } from "~/store/invoices/actions";
import {
  TGoodsDispatchNotePart,
  TCodes,
  IGoodsDispatchNote,
} from "~/store/invoices/types";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { parseDate } from "~/utils/dateUtils";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";

import React, { useState, useEffect } from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import mapDictionary from "~/components/MapDictionary/mapDictionary";

type TReduxState = {
  goodsDispatchNotes?: IGoodsDispatchNote;
  loading: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  selectGoodsDispatchNote: typeof selectGoodsDispatchNote;
  fetchDictionary: typeof fetchDictionary;
};

type TProps = {
  noteId: string;
  triggerOpen?: boolean;
};

type TGoodsDispatchNotesDetailsProps = TReduxState & TReduxActions & TProps;

const GoodsDispatchNotesDetails: React.FC<TGoodsDispatchNotesDetailsProps> = ({
  children,
  noteId,
  triggerOpen,
  selectGoodsDispatchNote,
  fetchDictionary,
  goodsDispatchNotes,
  dictionaries,
  loading,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { renderLabel } = useRenderingFunctions(goodsDispatchNotes, false);

  useEffect(() => {
    if (open) {
      fetchDictionary(DictionaryName.gdnType);
      fetchDictionary(DictionaryName.warehouseMovementStatus);
      selectGoodsDispatchNote(noteId);
    }
  }, [open, fetchDictionary, noteId, selectGoodsDispatchNote]);

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const labels: { [key: string]: string } = {
    vin: "VIN",
    cleCode: "CLE",
    transmitterCode: "EMETTEUR",
    nreCode: "NRE",
    wheelCode: "ROUE",
    tiresCode: "PNEUMATIQUE",
  };

  const renderCodes = (codes: TCodes): JSX.Element => {
    if (!codes) {
      return <></>;
    }
    const description = Object.entries(codes)
      .filter(([code, entry]) => entry)
      .map(([code, entry]) => (
        <small key={code} style={{ display: "block" }}>
          {labels[code]}: {entry}
        </small>
      ));
    return (
      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          verticalAlign: "middle",
          lineHeight: "1em",
        }}
      >
        {description}
      </div>
    );
  };

  const renderLines = (lines: TGoodsDispatchNotePart[]): JSX.Element => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Referencja</Table.HeaderCell>
            <Table.HeaderCell>Ilość</Table.HeaderCell>
            <Table.HeaderCell>Kodowanie</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lines.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="14">
                <Container fluid textAlign="center">
                  Brak elementów do wyświetlenia.
                </Container>
              </Table.Cell>
            </Table.Row>
          )}
          {lines
            .sort((a, b) => (a.referenceId > b.referenceId ? 1 : -1))
            .map((line) => (
              <Table.Row key={line.referenceId}>
                <Table.Cell>{line.referenceId}</Table.Cell>
                <Table.Cell>{line.quantity}</Table.Cell>
                <Table.Cell>{renderCodes(line.codes)}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Modal
      size="large"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header
        icon="briefcase"
        content={
          goodsDispatchNotes
            ? goodsDispatchNotes.goodsDispatchNoteID
            : "Wczytywanie..."
        }
      />
      <Modal.Content>
        <Grid style={{ marginBottom: "unset" }} stretched>
          <DetailCard
            title="app.details"
            id="details"
            key="details"
            width={16}
            minHeight={100}
          >
            {goodsDispatchNotes && (
              <Grid columns={2} divided stretched>
                <Grid.Row>
                  <Grid.Column>
                    {renderLabel(
                      "Numer",
                      goodsDispatchNotes.goodsDispatchNoteID
                    )}
                    {renderLabel(
                      "Data wystawiania",
                      parseDate(goodsDispatchNotes.creationDate)
                    )}
                    {renderLabel(
                      "Data rozwiązania",
                      parseDate(goodsDispatchNotes.issueDate)
                    )}
                    {renderLabel(
                      "Zamówienie LDC",
                      goodsDispatchNotes.orderNo
                        ? goodsDispatchNotes.orderNo
                        : "-"
                    )}
                    {renderLabel(
                      "Typ",
                      mapDictionary(
                        goodsDispatchNotes.type,
                        dictionaries["gdn-type"],
                        true,
                        true,
                        true
                      )
                    )}
                    {renderLabel(
                      "Status",
                      mapDictionary(
                        goodsDispatchNotes.status,
                        dictionaries["warehouse-movement-status"],
                        true,
                        true,
                        true
                      )
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {renderLabel(
                      "Magazyn",
                      goodsDispatchNotes.supplierName
                        ? goodsDispatchNotes.supplierName
                        : "-"
                    )}
                    {renderLabel(
                      "Płatnik",
                      goodsDispatchNotes.recipientID
                        ? goodsDispatchNotes.recipientID
                        : "-"
                    )}
                    {renderLabel(
                      "Odbiorca",
                      goodsDispatchNotes.recipientWarehouseID
                        ? `${goodsDispatchNotes.recipientWarehouseID}, ${goodsDispatchNotes.recipientWarehouseName} `
                        : "-"
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </DetailCard>
          <DetailCard
            title="app.lines.expected"
            id="expectedPartsToDispatch"
            key="expectedPartsToDispatch"
            width={8}
            smallWidth={8}
            minHeight={100}
          >
            {goodsDispatchNotes &&
              renderLines(goodsDispatchNotes.expectedPartsToDispatch || [])}
          </DetailCard>
          <DetailCard
            title="app.lines.sent"
            id="partsDispatched"
            key="partsDispatched"
            width={8}
            smallWidth={8}
            minHeight={100}
          >
            {goodsDispatchNotes &&
              renderLines(goodsDispatchNotes.partsDispatched || [])}
          </DetailCard>
        </Grid>
        <CommonLoader loading={loading} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  invoices,
  dictionaries,
}: ApplicationState) => ({
  goodsDispatchNotes: invoices.goodsDispatchNotes.selected,
  loading: invoices.goodsDispatchNotes.loading || dictionaries.loading,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  fetchDictionary,
  selectGoodsDispatchNote,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodsDispatchNotesDetails);
