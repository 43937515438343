import { TModelInputProps } from "~/components/ModelForm/lib/types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormContext } from "~/components/ModelForm/lib/form.config";
import { extractValue } from "~/utils/model";
import { FormFieldProps } from "semantic-ui-react";
import { DropdownPopupContent } from "~/components/ModelForm/DropdownPopupContent";

export type ModelDropdownHooksResponse = [
  any,
  any,
  (ev: any, el: FormFieldProps) => void,
  () => void,
  () => any,
  any,
] & {
  value: any;
  validation: any;
  handleChange: (ev: any, el: FormFieldProps) => void;
  handleBlur: () => void;
  formatPopupContent: () => any;
  model: any;
};

export const useFormElement = <T,>(props: TModelInputProps<T>) => {
  const { model, updateModel, validation, addValidators } =
    useContext(FormContext);

  const [value, setValue] = useState(
    extractValue(model, String(props.name)) || props.value || ""
  );

  useEffect(() => {
    const { name, validators, value } = props;

    if (name && validators) {
      addValidators(name, validators || []);
    }

    if (name && value) {
      updateModel(name, value);
    }
  }, [props, addValidators, updateModel]);

  const handleChange = useCallback(
    (ev: any, el: FormFieldProps): void => {
      if (el.value < props.min || el.value > props.max) return;

      setValue(el.value);
    },
    [props]
  );

  const handleBlur = useCallback((): void => {
    const { name } = props;

    if (name && value) {
      updateModel(props.name as string, value);
    }
  }, [props, updateModel, value]);

  const formatPopupContent = useCallback(() => {
    const { description, name } = props;

    if (name && validation && validation[props.name]) {
      return <DropdownPopupContent error={validation[props.name]} />;
    }

    if (description) {
      return description;
    }
  }, [props, validation]);

  return {
    value,
    validation,
    handleChange,
    handleBlur,
    formatPopupContent,
    model,
  } as ModelDropdownHooksResponse;
};
