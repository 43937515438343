import {shouldRenderRealisationButton} from "~/pages/ManualOrders/constans";
import {Dropdown} from "semantic-ui-react";
import translations from "~/utils/translations";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import React from "react";
import {MappedCodedPart} from "~/store/coded-parts/types";

interface props {
    codedPart: MappedCodedPart,
    openModal: (codedPart: MappedCodedPart, openModal: boolean) => void,
}

const manualOrdersListDropdown = ({ codedPart, openModal }: props): JSX.Element => {

    return (
        <TableMoreDropdown>
            {shouldRenderRealisationButton(codedPart.status) && (
                <>
                    <Dropdown.Item
                        text={translations.format("app.manual-orders.complete-line-CSPS")}
                        onClick={() => openModal(codedPart, true)}
                    />
                    <Dropdown.Divider />
                </>
            )}

            <Dropdown.Item
                text={translations.format("app.manual-orders.details")}
                onClick={() => openModal(codedPart, false)}
            />
        </TableMoreDropdown>
    );
}

export default manualOrdersListDropdown;