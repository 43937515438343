import { Button, Table } from 'semantic-ui-react';
import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import React from 'react';
import { TableCellType } from '../constants';

export const PackageNumbersTableCell = ({ column, line }: TableCellType) => (
  <Table.Cell key={column.name} textAlign="right">
    {line.packageNumbers ? line.packageNumbers.length : '0'}
    <InfoIcon
      documents={line.packageNumbers || []}
      clickableItem={(document) => (
        <Button style={{ display: 'block' }} compact basic>
          {document}
        </Button>
      )}
      noResultsMessage="Brak numerów paczek"
    />
  </Table.Cell>
);
