import { SyntheticEvent, useCallback } from "react";
import { TClaimLine } from "~/store/claims/types";
import translations from "~/utils/translations";

type TProps = {
  chatTargetGroups: string[] | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchPackageNr: React.Dispatch<React.SetStateAction<string>>;
  setLines: (value: React.SetStateAction<TClaimLine[]>) => void;
  lines: TClaimLine[];
  setTableData: (value: any) => void;
  tableData: any;
};

export const useClaimCloning = ({
  chatTargetGroups,
  setSearchValue,
  setSearchPackageNr,
  setLines,
  lines,
  setTableData,
  tableData,
}: TProps) => {
  const mapChatTargetGroups = useCallback(() => {
    if (!chatTargetGroups) return [];
    return chatTargetGroups.map((item) => ({
      text: translations.format("app." + item),
      value: item,
    }));
  }, [chatTargetGroups]);

  const handleInputChange = useCallback(
    (e, data, searchType: "search" | "searchPackageNr") => {
      if (searchType === "search") {
        setSearchValue(data.value);
      } else if (searchType === "searchPackageNr") {
        setSearchPackageNr(data.value);
      }
    },
    [setSearchPackageNr, setSearchValue]
  );

  const isCheckedAll = useCallback((tableData: any): boolean => {
    return !(
      tableData.data === null ||
      tableData.data.length === 0 ||
      tableData.data.some((line: { selected: boolean }) => !line.selected)
    );
  }, []);

  const selectAll = useCallback(
    (event: SyntheticEvent, data: any): void => {
      let linesItems: TClaimLine[] = [];
      setLines((items) => {
        linesItems = items.map((line) => ({
          ...line,
          selected: data.checked,
        }));
        return linesItems;
      });
      setTableData({
        type: "SELECT_ALL",
        column: "lineNumber",
        data: linesItems,
        selected: data.checked,
      });
    },
    [setLines, setTableData]
  );

  const selectLine = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.lineNumber === lineNumber) {
            setTableData({
              type: "SELECT",
              column: "lineNumber",
              data: prevLines,
              checkedLine: lineNumber,
              selected: data.checked,
            });
            return { ...line, selected: data.checked };
          }
          return { ...line };
        })
      );
    },
    [setLines, setTableData]
  );

  const changeQuantity = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.lineNumber === lineNumber) {
            line.quantity = parseInt(data.value);
            line.selected = true;
            tableData.data = prevLines;
          }
          return { ...line };
        })
      );
    },
    [tableData, setLines]
  );

  return {
    mapChatTargetGroups,
    handleInputChange,
    isCheckedAll,
    selectAll,
    selectLine,
    changeQuantity,
  };
};
