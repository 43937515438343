import { Icon } from "semantic-ui-react";

import React from "react";

import "./icon-hover.scss";

export type TProps = {
  name: string;
  title?: string;
  onClick?: () => void;
};

const IconHover: React.FC<TProps> = ({ name, title, onClick }): JSX.Element => {
  return (
    //@ts-ignore: icon name Type
    <Icon
      title={title ? title : ""}
      name={name}
      className="icon-hover"
      onClick={() => (onClick ? onClick() : {})}
    />
  );
};

export default IconHover;
