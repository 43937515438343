import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { DistrigoWarehousesActionTypes, DistrigoWarehousesState, StockWarehouse } from './types'
import { Paginable } from '~/store/types'

export const initialState: DistrigoWarehousesState = {
    distrigoWarehouse: undefined,
    loadingDistrigoWarehouses: false,
    loadingDistrigoWarehouse: false,
    loadingLines: false,
    loadingStockItems: false,
    list: [],
    stockItemsList: [],
    imported: undefined,
    error: '' && undefined,
    movedWares: [{}],
    deliveryWareValue: undefined,
    parts: [],
    additionalInfo: undefined,
    receiveWareValue: undefined,
    importing: false,
    finishedImportingToLine: false,
    created: undefined,
    creating: false,
    rejecting: false,
    destroying: false,
    uploadingStock: false,
    uploadedStock: false,
    meta: {
        page: 1,
        size: 15,
        pages: 1
    },
    totalRecords: {
        total: 0,
        filtered: 0
    },
    operationSuccess: false,
    selectedWarehouse: undefined,
    rejected: false,
    destroyed: false
}

const reducer: Reducer<DistrigoWarehousesState> = (state = initialState, action): DistrigoWarehousesState => {
    switch (action.type) {
        case DistrigoWarehousesActionTypes.FETCH_REQUEST: {
            return { ...state, loadingDistrigoWarehouses: true }
        }
        case DistrigoWarehousesActionTypes.FETCH_SUCCESS: {
            return {
                ...state,
                loadingDistrigoWarehouses: false,
                list: action.payload.distrigoWarehouses,
            }
        }
        case DistrigoWarehousesActionTypes.FETCH_STOCK_DETAILS: {
            return { ...state, loadingDistrigoWarehouses: true }
        }
        case DistrigoWarehousesActionTypes.FETCH_STOCK_DETAILS_SUCCESS: {
            return {
                ...state,
                loadingDistrigoWarehouses: false,
                distrigoWarehouse: action.payload,
            }
        }
        case DistrigoWarehousesActionTypes.FETCH_STOCK_PRODUCTS_REQUEST: {
            return { ...state, stockItemsList: [], loadingStockItems: true }
        }
        case DistrigoWarehousesActionTypes.FETCH_STOCK_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loadingStockItems: false,
                stockItemsList: action.payload.stockItemsList.content,
                meta: {
                    page: action.payload.stockItemsList.number + 1,
                    pages: action.payload.stockItemsList.totalPages,
                    size: action.payload.stockItemsList.size
                },
                totalRecords: {
                    total: action.payload.stockItemsList.totalElements,
                    filtered: action.payload.stockItemsList.filteredElements
                }
            }
        }
        case DistrigoWarehousesActionTypes.SELECT: {
            return { ...state, loadingDistrigoWarehouse: true }
        }
        case DistrigoWarehousesActionTypes.SELECTED: {
            const payload = action.payload as Paginable<StockWarehouse>
            return {
                ...state,
                loadingDistrigoWarehouse: false,
                rejected: true,
                selectedWarehouse: {
                    list: payload.content,
                    meta: {
                        page: payload.number + 1,
                        pages: payload.totalPages,
                        size: payload.size
                    },
                    totalRecords: {
                        total: payload.totalElements,
                        filtered: payload.filteredElements
                    }
                }
            }
        }
        case DistrigoWarehousesActionTypes.ADD_SPARE_PART_STOCK: {
            return { ...state, creating: true }
        }
        case DistrigoWarehousesActionTypes.ADD_SPARE_PART_CONFLICT: {
            return { ...state, creating: false, created: undefined }
        }
        case DistrigoWarehousesActionTypes.ADD_SPARE_PART_STOCK_SUCCESS: {
            return { ...state, creating: false, created: action.payload }
        }
        case DistrigoWarehousesActionTypes.CLEAR_CREATED: {
            return { ...state, created: undefined, creating: false }
        }
        case DistrigoWarehousesActionTypes.UPLOAD_XLS: {
            return { ...state, uploadingStock: true }
        }
        case DistrigoWarehousesActionTypes.UPLOAD_XLS_SUCCESS: {
            return { ...state, uploadingStock: false, uploadedStock: true }
        }
        case DistrigoWarehousesActionTypes.CLEAR_UPLOAD: {
            return { ...state, uploadedStock: false }
        }
        case DistrigoWarehousesActionTypes.REJECT_TO_SUPPLIER: {
            return { ...state, rejecting: true, rejected: false }
        }
        case DistrigoWarehousesActionTypes.INTERNAL_CONSUMPTION: {
            return { ...state, destroying: true, destroyed: false }
        }
        case DistrigoWarehousesActionTypes.REJECT_SUCCESS: {
            return { ...state, rejecting: false, rejected: true }
        }
        case DistrigoWarehousesActionTypes.INTERNAL_CONSUMPTION_SUCCESS: {
            return { ...state, destroying: false, destroyed: true }
        }
        case DistrigoWarehousesActionTypes.UPDATE_MIN_MAX_STOCK_ITEM: {
            const stockItemsList = [...state.stockItemsList].map(stockItem => {
                return stockItem.referenceWarehouseId.referenceId === action.payload.id ? { ...stockItem, stockMin: action.payload.min, stockMax: action.payload.max } : stockItem
            })

            return { ...state, stockItemsList }
        }
        case DistrigoWarehousesActionTypes.HANDLE_REMOVE_STOCK_LINE: {
            if (action.payload.stockLine.stockMin === 0 && action.payload.stockLine.stockMax === 0 && action.payload.code === 404) {
                const stockItemsList = [...state.stockItemsList]
                const stockItemToRemove = stockItemsList.findIndex(stockItem => stockItem.referenceWarehouseId.referenceId === action.payload.stockLine.referenceWarehouseId.referenceId)
                if (stockItemToRemove) {
                    stockItemsList.splice(stockItemToRemove, 1)
                }
                return { ...state, stockItemsList }
            }
            return { ...state }
        }
        case DistrigoWarehousesActionTypes.CREATE_WARE: {
            return { ...state, created: undefined, creating: true  }
        }
        case DistrigoWarehousesActionTypes.CREATE_WARE_SUCCESS: {
            return { ...state, created: action.payload, creating: false }
        }
        case DistrigoWarehousesActionTypes.ERROR_OF_DUPLICATE_REFERENCE: {
            return { ...state,  error: action.payload, creating: false, }
        }
        case DistrigoWarehousesActionTypes.LINES_OPERATION_FINISHED: {
            return { ...state, loadingLines: false, operationSuccess: action.payload }
        }
        case DistrigoWarehousesActionTypes.IMPORT_REFERENCES: {
            return { ...state, importing: true, imported: undefined }
        }
        case DistrigoWarehousesActionTypes.ERROR_OF_IMPORTING_REFERENCE: {
            return { ...state, importing: false, error: action.payload }
        }
        case DistrigoWarehousesActionTypes.IMPORT_FINISHED: {
            return { ...state, importing: false, imported: action.payload  }
        }
        case DistrigoWarehousesActionTypes.CONFIRM_FINISHING_OF_IMPORT: {
            return { ...state, finishedImportingToLine: true  }
        }
        case DistrigoWarehousesActionTypes.STOP_CONFIRM_FINISHING_OF_IMPORT: {
            return { ...state, finishedImportingToLine: false }
        }
        case DistrigoWarehousesActionTypes.CLEAR_IMPORTED_ARRAY: {
            return { ...state, imported: undefined }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loadingDistrigoWarehouse: false, loadingDistrigoWarehouses: false, rejecting: false, uploadingStock: false, creating: false }
        }
        default: {
            return state
        }
    }
}

export { reducer as distrigoWarehousesReducer }
