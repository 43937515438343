import React, {useEffect} from 'react';
import { useCallback, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';

import ContentOfCreatingReceiptOfWare from './ContentOfCreatingReceiptOfWare/ContentOfCreatingReceiptOfWare';
import { toastInfo } from '~/utils/toast';
import { TImportedFile, TLine, TWareCreate, TWareLocation } from '~/store/distrigo-warehouses/types';
import { addWareLine, clearArrayOFInmportedFile } from '~/store/distrigo-warehouses/actions';
import { ApplicationState } from '~/store';
import { useParams } from "react-router-dom";

type TReduxActions = {
    addWareLine: typeof addWareLine,
    clearArrayOFInmportedFile: typeof clearArrayOFInmportedFile
}

type TReduxState = {
    creating?: boolean,
    finishedImportingToLine: boolean,
    imported?: TImportedFile[]
}

type TCreateReceiptOfWareProps = TReduxActions & TReduxState & RouteComponentProps

const CreateReceiptOfWare = ({
                                 addWareLine,
                                 clearArrayOFInmportedFile,
                                 finishedImportingToLine,
                                 imported
                             }: TCreateReceiptOfWareProps) => {
    const [ware, setWare] = useState<TWareCreate>({
        invoiceDate: null,
        invoiceNumber: undefined,
        quantity: undefined,
        parts: [{price: undefined, quantity: undefined, referenceID: undefined}],
        rrid: undefined
    })
    const [confirmCreateReceiptOfWare, setConfirmCreateReceiptOfWare] = useState<boolean>(false);
    const [lines, setLines] = useState<TLine[]>([]);
    const [lineAdded, setLineAdded] = useState<boolean>(false);
    const [wareId, setWareId] = useState<string>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [maximized, setMaximized] = useState<boolean>(false);
    const [summary, setSummary] = useState<boolean>(false);
    const [wareDetails, setWareDetails] = useState<TWareLocation>();
    const tableEl = useRef<HTMLDivElement>(null);
    let { warehouseId } = useParams<{ warehouseId: string }>();

    const handleImportCSV = useCallback((): void => {
        setOpenModal(!openModal);
    }, [openModal]);

    const updateIndexing = useCallback((updatedlines: TLine[]): void => {
        updatedlines.forEach((line, index) => line.lineNumber = index + 1);
        setLines(updatedlines);
    }, []);

    const linesCopy = useCallback((): any[] => {
        return lines.map(line => ({...line}));
    }, [lines]);

    const getReplacements = useCallback((lineNumber: number): TLine[] => {
        const updatingLine = lines.find(line => line.lineNumber === lineNumber);
        if (updatingLine) {
            const replacements = lines.filter(line => line.referenceId === updatingLine.referenceId);
            return [...replacements];
        }
        return []
    }, [lines]);

    const toggleMaximized = useCallback((): void => {
        setMaximized(!maximized);
    }, [maximized]);

    const closeModal = useCallback((): void => {
        setOpenModal(!openModal);
    }, [openModal]);

    useEffect(() => {
        if (tableEl.current && lineAdded) {
            tableEl.current.scrollTop = 9999;
            setLineAdded(false);
        }
    }, [lineAdded, tableEl]);

    const calculateGross = useCallback((line: TLine): number => {
        return (line.price && line.quantity) ? Number(Number(line.price) * line.quantity) : 0
    }, []);

    const getTotalAmount = useCallback((): string => {
        return String(lines
            .reduce((sum: number, line: TLine) => sum + calculateGross(line), 0)
            .toFixed(2))
    }, [calculateGross, lines]);

    const getProvision = useCallback((lineNumber: number, lines: TLine[]): TLine | undefined => {
        const updatingLine = lines.find(line => line.lineNumber === lineNumber)
        if (updatingLine) {
            return lines.find(line => line.referenceId === updatingLine.referenceId);
        }
    }, []);

    const changeQuantity = useCallback((lineNumber: number, currentValue: number, previousValue: number, round: boolean): void => {
        const lines = linesCopy()
        const lineToUpdate = lines.find(line => line.lineNumber === lineNumber);
        if (lineToUpdate) {
            let value = Number(currentValue)
            if (round) {
                const step = lineToUpdate.packingForceQuantity || 1
                if (value <= 0) {
                    value = step;
                } else {
                    value = Math.round(value / step) * step;
                }
            }
            lineToUpdate.quantity = value;
            const provision = getProvision(lineNumber, lines);
            if (provision) {
                if (imported && finishedImportingToLine) {
                    provision.quantity = imported.reduce(item => Object.assign(item, {})).quantity
                } else {
                    provision.quantity = value;
                }
            }
        }
        setLines(lines);
    }, [finishedImportingToLine, getProvision, imported, linesCopy]);

    const changePrice = useCallback((lineNumber: number, currentValue: number, prevValue: number, round: boolean): void => {
        const lines = linesCopy()
        const lineToUpdate = lines.find(line => line.lineNumber === lineNumber);
        if (lineToUpdate) {
            let value = Number(currentValue.toFixed(2))
            if (round) {
                const step = lineToUpdate.packingForceQuantity || 1
                if (value <= 0) {
                    value = step;
                } else {
                    value = Math.round(value / step) * step;
                }
            }
            lineToUpdate.price = value;
        }
        setLines(lines);
    }, [linesCopy]);

    const changeSupplier = useCallback((lineNumber: number, data: string | number | boolean | (string | number | boolean)[] | undefined): string => {
        const found = data;
        const newLines: TLine[] = []
        let isPartOnLine = false
        if (found) {
            if (!isPartOnLine) {
                lines.forEach(line => {
                    if (line.lineNumber !== lineNumber) {
                        newLines.push({...line})
                    } else {
                        newLines.push({
                            ...line,
                            supplier: String(found)
                        });
                    }
                })
                updateIndexing(newLines);
                setLines(newLines);
                return String(found)
            }
        }
        return ''
    }, [lines, updateIndexing]);

    const changeReferenceId = useCallback((lineNumber: number, data: TLine): string => {
        const found = data.ware;
        const newLines: TLine[] = []
        let isPartOnLine = false
        if (found) {
            if (lines.find(line => line.rrid === (found.returningPointView && found.returningPointView.rrdi))) {
                toastInfo("Część została już dodana", "Wybrana część znajduje się już w zamówieniu.");
                return ''
            }
            if (found.returningPointView) {
                Object.entries(found.returningPointView).forEach(([key, val]) => {
                    if (lines.find(line => (line.returningPointView && line.returningPointView.rrdi) === val)) {
                        toastInfo(`Część została już dodana`, `Zamiennik ${val} znajduje się już w zamówieniu.`);
                        isPartOnLine = true;
                    }
                })
            }
            if (!isPartOnLine) {
                lines.forEach(line => {
                    if (line.lineNumber !== lineNumber) {
                        newLines.push({...line})
                    } else {
                        newLines.push({
                            ...line,
                            referenceId: found.returningPointView ? found.returningPointView.rrdi : undefined,
                        });
                    }
                })
                updateIndexing(newLines);
                setLines(newLines);
                return found.returningPointView ? String(found.returningPointView.rrdi) : ''
            }
        }
        return ''
    }, [lines, updateIndexing]);

    const removeLine = useCallback((lineIn: TLine): void => {
        const replacements = getReplacements(lineIn.lineNumber).map(line => line.lineNumber)
        const provisions = [getProvision(lineIn.lineNumber, lines), ...replacements.map(lineNo => getProvision(Number(lineNo), lines))]
            .filter(line => !!line)
            .map(line => (line && line.lineNumber ? line.lineNumber : []))
        const newLines = linesCopy().filter(line => !(
            line.lineNumber === lineIn.lineNumber ||
            replacements.indexOf(line.lineNumber) !== -1 ||
            provisions.indexOf(line.lineNumber) !== -1
        ));
        changeReferenceId(lineIn.lineNumber, lineIn);
        changeQuantity(lineIn.lineNumber, Number(lineIn.quantity), Number(lineIn.quantity), true);
        changePrice(lineIn.lineNumber, Number(lineIn.price), Number(lineIn.price), true);
        changeSupplier(lineIn.lineNumber, lineIn.supplier)
        getTotalAmount();
        setLines(newLines);
        updateIndexing(newLines);
        if (imported && imported.length) {
            const removeIndex = imported.findIndex((item, index) => index + 1 === lineIn.lineNumber);
            imported.splice(removeIndex, 1);
            if (!imported.length) {
                clearArrayOFInmportedFile();
            }
        }
    }, [changePrice, changeQuantity, changeSupplier, changeReferenceId, clearArrayOFInmportedFile, getProvision, getTotalAmount, linesCopy,
        lines, getReplacements, imported, setLines, updateIndexing]);

    const addLine = useCallback((): void => {
        if (lines.length > 0 && !lines[lines.length - 1].referenceId) {
            return
        }
        if (!finishedImportingToLine && imported && imported.length) {
            clearArrayOFInmportedFile(imported);
        }
        const newLines = [...lines]
        if (!imported) {
            newLines.push({
                lineNumber: !lines.length ? 1 : lines[lines.length - 1].lineNumber + 1,
                invoiceDate: null, invoiceNumber: undefined,
                referenceId: undefined, quantity: undefined, price: undefined, supplier: undefined,
                ware: undefined, rrid: undefined, returningPointView: {}
            });
        } else if (imported && imported.length) {
            imported.map((item, index) => {
                newLines.push({
                    lineNumber: !imported.length ? 1 : index + 1,
                    invoiceDate: null, invoiceNumber: undefined,
                    referenceId: (finishedImportingToLine && imported) ? item.referenceId : undefined,
                    quantity: (finishedImportingToLine && imported) ? item.quantity : undefined,
                    price: (finishedImportingToLine && imported) ? String(item.price) : undefined,
                    supplier: (finishedImportingToLine && imported) ? String(item.supplier) : undefined,
                    ware: undefined, rrid: undefined, returningPointView: {}
                });
                return newLines;
            });
        }
        setLines(newLines);
    }, [clearArrayOFInmportedFile, finishedImportingToLine, imported, lines]);

    const handleSummaryNewReceiptInWare = useCallback((): void => {
        const wareData = {...ware}
        wareData.parts = lines.map(line => ({
            price: Number(line.price).toFixed(2),
            quantity: line.quantity,
            referenceID: line.referenceId,
            supplier: line.supplier
        }));
        setSummary(true);
    }, [lines, ware]);

    const handleConfirmOfCreateNewReceiptOfWare = useCallback((): void => {
        const wareData = {...ware}
        wareData.parts = lines.map(line => ({
            price: Number(line.price).toFixed(2),
            quantity: line.quantity,
            referenceID: line.referenceId,
            supplier: line.supplier
        }))
        wareData.warehouse = warehouseId
        addWareLine(wareData);
        setConfirmCreateReceiptOfWare(true);
        setSummary(true)
    }, [addWareLine, lines, ware, warehouseId]);

    const updateWare = useCallback((field: keyof TWareCreate, value?: Date | null | number | string): void => {
        if (value) {
            setWare(ware => ({...ware, [field]: value}))
        } else {
            setWare(ware => {
                let updated = {...ware}
                delete updated[field]
                return updated
            })
        }
    }, []);

    const selectWareId = useCallback((selected: { rrid?: string, ware: TWareLocation }): string => {
        if (selected) {
            setWareId(selected.rrid);
            updateWare('rrid', selected.rrid);
            updateWare('rrdi', selected.ware.returningPointView ? selected.ware.returningPointView.rrdi : undefined);
            updateWare('warehouse', warehouseId);
            setWareDetails(selected.ware);
        } else {
            setWareId(undefined);
            toastInfo("Błąd konfiguracyjny", "Wybrane id dostawy skonfigurowane jest nieprawidłowo.");
        }
        return ''
    }, [updateWare, warehouseId]);

    const resetWare = useCallback((): void => {
        setLines(lines => lines.map((line) => ({...line})))
        setSummary(false)
    }, []);

    return (
        <ContentOfCreatingReceiptOfWare
            addLine={addLine}
            changePrice={changePrice}
            changeQuantity={changeQuantity}
            changeSupplier={changeSupplier}
            closeModal={closeModal}
            confirmCreateReceiptOfWare={confirmCreateReceiptOfWare}
            getProvision={getProvision}
            getTotalAmount={getTotalAmount}
            handleImportCSV={handleImportCSV}
            handleSummaryNewReceiptInWare={handleSummaryNewReceiptInWare}
            handleConfirmOfCreateNewReceiptOfWare={handleConfirmOfCreateNewReceiptOfWare}
            lines={lines}
            maximized={maximized}
            openModal={openModal}
            resetWare={resetWare}
            removeLine={removeLine}
            setWareDetails={setWareDetails}
            selectWareId={selectWareId}
            setConfirmCreateReceiptOfWare={setConfirmCreateReceiptOfWare}
            setLines={setLines}
            setSummary={setSummary}
            summary={summary}
            tableEl={tableEl}
            toggleMaximized={toggleMaximized}
            updateIndexing={updateIndexing}
            updateWare={updateWare}
            ware={ware}
            wareId={wareId}
            wareDetails={wareDetails}
            warehouseId={warehouseId}
        />
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({distrigoWarehouses}: ApplicationState) => ({
    creating: distrigoWarehouses.creating,
    finishedImportingToLine: distrigoWarehouses.finishedImportingToLine,
    imported: distrigoWarehouses.imported
});

const mapDispatchToProps: TReduxActions = {
    addWareLine, clearArrayOFInmportedFile
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateReceiptOfWare));
