import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'
import { fetchReplenishmentPredictionSuccess, fetchReplenishmentPrediction } from './actions'
import { ReplenishmentActionTypes } from './types'

function* handleFetchReplenishmentPrediction(action: ReturnType<typeof fetchReplenishmentPrediction>) {
    try {

        const {warehouseID, partID} = action.payload
        const response: any = yield call(callApi, 'get', `/replenishments/${warehouseID}/${partID}/prediction`)

        yield put(fetchReplenishmentPredictionSuccess(response))
    } catch (error) {
        yield put(handleError(error, false))
    }
}

function* watchFetchReplenishmentPredictionRequest() {
    yield takeEvery(ReplenishmentActionTypes.FETCH_REPLENISHMENT, handleFetchReplenishmentPrediction)
}

function* replenishmentSaga() {
    yield all([
        fork(watchFetchReplenishmentPredictionRequest),
    ])
}

export default replenishmentSaga
