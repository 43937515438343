import React from "react";
import { useSparePartsRolesRights } from "./useSparePartsRolesRights";

type Props = {
  exportPercent: number;
  disableButton: boolean;
  setOpenImportModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDownloadModal: () => Promise<void>;
};

export const useSparePartsButtons = (props: Props) => {
  const { userHaveLdcUiProductEditRole } = useSparePartsRolesRights();

  const downloadButton = {
    icon: "download",
    content: "Eksport produktów",
    primary: true,
    onClick: () => props.handleOpenDownloadModal(),
    window: props.exportPercent,
    disabled: props.disableButton,
  };

  const uploadButton = {
    icon: "upload",
    content: "Import produktów",
    primary: true,
    onClick: () => props.setOpenImportModal(true),
    visible: userHaveLdcUiProductEditRole,
  };

  return [downloadButton, uploadButton];
};
