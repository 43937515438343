import { Label } from 'semantic-ui-react';
import React from 'react';

const StorageLimitCell = ({ storageLimit }: { storageLimit: boolean }) => {
  return (
    <Label basic size="tiny" title={storageLimit ? 'Ustawiono limit' : 'Brak limitu'}>
      {storageLimit ? 'T' : 'N'}
    </Label>
  );
};

export default StorageLimitCell;
