import { THeaderButton } from "~/components/PageHeader/lib/types";
import React, { Fragment } from "react";
import { Progress } from "semantic-ui-react";

export type HeaderWindowBtnProps = {
    buttonElement: JSX.Element,
    button: THeaderButton
}

export const HeaderWindowProgressBtn: React.FC<HeaderWindowBtnProps> = ({ buttonElement, button }) => {
    return (
        <Fragment>
            <div style={{ display: "inline-block" }}>
                <div style={{ display: "inline-block" }}>{buttonElement}</div>
                <Progress percent={button.window} inverted color="red" progress size="small"
                          style={{ width: "98%", marginBottom: 0 }}/>
            </div>
        </Fragment>
    )
}