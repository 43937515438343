import { User } from "~/store/users/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { ConnectedReduxProps } from "~/store";
import {
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
} from "~/store/dictionaries/actions";
import {
  fetchUsers,
  exportUserListToXls,
  exportFP0536ToCsv,
} from "~/store/users/actions";
import { TColumn } from "~/components/Table/lib/types";

export const COLUMNS = [
  { name: "username", label: "Nazwa użytkownika", projection: true },
  { name: "firstName", label: "Imię", projection: true },
  { name: "lastName", label: "Nazwisko", projection: true },
  { name: "email", label: "E-mail", projection: true },
  { name: "enabled", label: "Aktywny", projection: true },
  { name: "createdTimestamp", label: "Data utworzenia", projection: true },
];

export interface PropsFromState {
  users: User[];
  loading: boolean;
  dictionaries: DictionariesState;
  downloadFP0536pending: boolean;
}

export interface PropsFromDispatch {
  fetchUsers: typeof fetchUsers;
  fetchDictionariesNames: typeof fetchDictionariesNames;
  fetchDictionariesNamesDetails: typeof fetchDictionariesNamesDetails;
  exportUserListToXls: typeof exportUserListToXls;
  exportFP0536ToCsv: typeof exportFP0536ToCsv;
}

export type TConfirmType =
  | ""
  | "ACTIVATE_USER"
  | "DEACTIVATE_USER"
  | "REMOVE_USER";

export type AppProps = PropsFromState & PropsFromDispatch & ConnectedReduxProps;

// export type TColumn = {
//   name: string;
//   label: string;
//   projection: boolean;
// };

export interface State {
  columns: TColumn[];
  modalOpen: boolean;
  confirmType: TConfirmType;
  actionId: string;
  actionUserName: string;
  request: boolean;
  tabIndex: number;
  name: string;
  openAddUserModal: boolean;
  openAddDictionaryItemModal: boolean;
  breadcrumbs: {
    id: BREADCRUMB_ID;
    text: string;
    clickAction: () => void;
    visible?: boolean;
  }[];
}

export interface storageUsersColumns {
  name: string;
  shouldDisplay: boolean;
}

export enum BREADCRUMB_ID {
  users,
  dict,
  speeder,
  import,
  hazardousGoods,
}

export enum BREADCRUMB_ID_CLIENT_PANEL {
  announcements,
  usersClientPanel,
}
