import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchGoodsDispatchNotes,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { IGoodsDispatchNote } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useHandleDownloadModal } from "../../hooks/useHandleDownloadModal";
import ChooseDownloadOptionModal from "../../lib/ChooseDownloadOptionModal/ChooseDownloadOptionModal";
import { useGoodsDispatchNotesColumns } from "./useGoodsDispatchNotesColumns";
import IconHover from "~/components/IconHover/IconHover";
import GoodsDispatchNotesDetails from "~/pages/Accounting/Details/GoodsDispatchNotes";
import { useGoodsDispatchAdvancedSearch } from "./useGoodsDispatchAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  setDisableExportButton: React.Dispatch<React.SetStateAction<boolean>>;
  setPercenExportButton: React.Dispatch<React.SetStateAction<number>>;
};

type TReduxState = {
  goodsDispatchNotes: IGoodsDispatchNote[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  isOpenChooseTypeOfExportDataModal?: boolean;
};

interface TReduxActions {
  fetchGoodsDispatchNotes: typeof fetchGoodsDispatchNotes;
  getInvoicePdf: typeof getInvoicePdf;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
}

type GoodsDispatchNotesProps = TComponentProps & TReduxState & TReduxActions;

const GoodsDispatchNotes: React.FC<GoodsDispatchNotesProps> = ({
  fetchGoodsDispatchNotes,
  goodsDispatchNotes,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  isOpenChooseTypeOfExportDataModal,
  openChooseTypeOfExportDataModal,
  setDisableExportButton,
  setPercenExportButton,
}) => {
  const { columnsConfig } = useGoodsDispatchNotesColumns();
  const [tableKey] = useState<string>("goods-dispatch-notes");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("issueDate");
  const [sortDirection] = useState<any>("DESC");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [exportPercent, setExportPercent] = useState<number>(0);
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { handleOpenDownloadModal } = useHandleDownloadModal({
    tableKey,
    totalRecords,
    exportPercent,
    setExportPercent,
    setDisableButton,
    params,
    selectedTypeOfDataToExport: undefined,
    columns,
    columnsWithInvoiceLine: columns,
    fileName: "WZ",
  });

  const getDebitNotesCorretions = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchGoodsDispatchNotes(Object.assign(paramsObj, params));
    },
    [
      fetchGoodsDispatchNotes,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getDebitNotesCorretions({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getDebitNotesCorretions]);

  useEffect(() => {
    setDisableExportButton(disableButton);
  }, [disableButton, setDisableExportButton]);

  useEffect(() => {
    setPercenExportButton(exportPercent);
  }, [exportPercent, setPercenExportButton]);

  useEffect(() => {
    if (isOpenChooseTypeOfExportDataModal) {
      handleOpenDownloadModal();
      openChooseTypeOfExportDataModal(false);
    }
  }, [
    isOpenChooseTypeOfExportDataModal,
    openChooseTypeOfExportDataModal,
    handleOpenDownloadModal,
  ]);

  const { formFields, handleSubmit } = useGoodsDispatchAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getDebitNotesCorretions,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getDebitNotesCorretions}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {goodsDispatchNotes.map(
          (document: IGoodsDispatchNote, index: number) => (
            <Table.Row disabled={!!document.cancellationReason} key={index}>
              {columns &&
                columns.length &&
                columns.map(
                  (column) =>
                    column.getCell &&
                    column.projection &&
                    column.getCell(document, column)
                )}
              <Table.Cell className="col-dropdown-menu-sticky">
                <GoodsDispatchNotesDetails
                  noteId={document.goodsDispatchNoteID}
                >
                  <div role="button">
                    <IconHover name="arrow right" title="Podgląd" />
                  </div>
                </GoodsDispatchNotesDetails>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </DataGridTable>
      {isOpenChooseTypeOfExportDataModal && (
        <ChooseDownloadOptionModal
          disableButton={() => setDisableButton(true)}
          handleOpenDownloadModal={handleOpenDownloadModal}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ invoices, dictionaries }: ApplicationState) => ({
  goodsDispatchNotes: invoices.goodsDispatchNotes.list,
  loading: invoices.goodsDispatchNotes.loading,
  totalRecords: invoices.goodsDispatchNotes.totalRecords,
  meta: invoices.goodsDispatchNotes.meta,
  dictionaries: dictionaries,
  isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
  selectedTypeOfDataToExport: invoices.selectedTypeOfDataToExport,
});

const mapDispatchToProps = {
  fetchGoodsDispatchNotes,
  getInvoicePdf,
  openChooseTypeOfExportDataModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoodsDispatchNotes);
