import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Button, Form, Header, Icon, Label, Modal } from 'semantic-ui-react';
import { ModelFormWrapper } from '~/components/ModelForm/ModelFormWrapper';
import { ModelInput } from '~/components/ModelForm/ModelInput';
import { SubmitButton } from '~/components/ModelForm/SubmitButton';
import { LENGTH, MAX, REQUIRED } from '~/components/SmartField/lib/validator';
import { ApplicationState } from '~/store';
import { addCustomer } from '~/store/customer/actions';
import { CustomerBase } from '~/store/customer/types';

import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSelectOptions } from '~/pages/Customers/Details/lib/useSelectOptions';
import { DictionariesState } from '~/store/dictionaries/types';
import { fetchDictionary } from '~/store/dictionaries/actions';

export enum CustomerType {
  PAYER = 'PAYER',
  DELIVERY = 'DELIVERY',
}

type TReduxState = {
  created?: string;
  creating?: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  addCustomer: typeof addCustomer;
  fetchDictionary: typeof fetchDictionary;
};

type TAddContractorProps = TReduxState &
  TReduxActions & {
    triggerOpen: boolean;
  };

const AddContractor: React.FC<TAddContractorProps> = ({ triggerOpen, created, creating, addCustomer }) => {
  const [open, setOpen] = useState(triggerOpen);
  const [customerType, setCustomerType] = useState<CustomerType | undefined>(undefined);
  const [clientCodeLength, setClientCodeLength] = useState(7);
  const [isDisabled, setDisabled] = useState<boolean>(true);

  const { customerTypes } = useSelectOptions();

  useEffect(() => {
    if (customerType === CustomerType.PAYER || customerType === CustomerType.DELIVERY) {
      setDisabled(false);
    }

    setClientCodeLength(customerType === CustomerType.PAYER ? 7 : 9);
  }, [customerType]);

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  const changeCustomerType = useCallback((event: SyntheticEvent, data: { value: CustomerType | undefined }): void => {
    switch (data.value) {
      case CustomerType.PAYER:
        setCustomerType(CustomerType.PAYER);
        break;
      case CustomerType.DELIVERY:
        setCustomerType(CustomerType.DELIVERY);
        break;
      default:
        setCustomerType(undefined);
    }
  }, []);

  const handleConfirm = useCallback(
    (customer: CustomerBase): void => {
      if (!creating) {
        customer.categoryCompany = !!customer.informationAddressCompany;
        customer.orderType = 'STOCK';
        customer.customerType = customerType;
        customer.clientId = customer.customerType === CustomerType.PAYER ? '' : customer.clientId;
        addCustomer(customer);
      }
    },
    [creating, addCustomer, customerType]
  );

  const handleCancel = useCallback((): void => {
    if (!creating) {
      setOpen(false);
      setDisabled(true);
      setCustomerType(undefined);
    }
  }, [creating]);

  const handleOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  if (created) {
    return <Redirect to={`/customer/${created}/edit`} />;
  }

  return (
    <Modal size="tiny" open={open} onOpen={handleOpen} onClose={handleCancel} closeOnDimmerClick={false}>
      <ModelFormWrapper<CustomerBase> onSubmit={handleConfirm}>
        <Header icon="user add" content="Dodaj kontrahenta" />
        <Modal.Content>
          <Form style={{ padding: 10 }}>
            <Label pointing="below" color="orange" style={{ display: isDisabled ? 'inline-block' : 'none' }}>
              <Icon name="info circle" />
              Wybierz typ kontrahenta aby przejść dalej.
            </Label>
            <Form.Dropdown
              placeholder="Typ kontrahenta"
              label={isDisabled ? null : 'Typ kontrahenta'}
              required={true}
              fluid
              selection
              value={customerType}
              options={customerTypes}
              onChange={changeCustomerType}
              style={{ marginBottom: '14px' }}
            />
            <Form.Group widths="equal">
              <ModelInput<CustomerBase>
                label="Kod klienta"
                placeholder="Unikalny kod klienta"
                name="rrdi"
                validators={[REQUIRED, LENGTH(clientCodeLength)]}
                disabled={isDisabled}
              />
              <ModelInput<CustomerBase>
                label="Płatnik"
                placeholder="Kod płatnika"
                name="clientId"
                validators={[LENGTH(7)]}
                disabled={isDisabled || customerType === CustomerType.PAYER}
              />
            </Form.Group>
            <ModelInput<CustomerBase>
              label="Firma"
              placeholder="Nazwa firmy"
              name="informationAddressCompany"
              validators={[REQUIRED, MAX(130)]}
              disabled={isDisabled}
            />
            <Form.Group widths="equal">
              <ModelInput<CustomerBase>
                label="Imię"
                placeholder="Imię kontrahenta"
                name="informationContactName"
                validators={[MAX(20)]}
                disabled={isDisabled}
              />
              <ModelInput<CustomerBase>
                label="Nazwisko"
                placeholder="Nazwisko kontrahenta"
                name="informationContactSurname"
                validators={[MAX(50)]}
                disabled={isDisabled}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <SubmitButton
            content="Dodaj"
            icon="add"
            labelPosition="right"
            primary
            loading={creating}
            disabled={isDisabled}
          />
          <Button content="Anuluj" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ customers, dictionaries }: ApplicationState) => {
  return {
    created: customers.created,
    creating: customers.creating,
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = { addCustomer, fetchDictionary };

export default connect(mapStateToProps, mapDispatchToProps)(AddContractor);
