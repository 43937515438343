import { action } from "typesafe-actions";
import { LibraryActionTypes, LibraryItem, NewLibraryItem } from "./types";
import { Paginable } from "../types";

export const fetchLibrary = (params: any) =>
  action(LibraryActionTypes.FETCH_LIBRARY, params);
export const fetchLibrarySuccess = (library: Paginable<LibraryItem>) =>
  action(LibraryActionTypes.FETCH_LIBRARY_SUCCESS, { library });
export const fetchLibraryError = () =>
  action(LibraryActionTypes.FETCH_LIBRARY_ERROR);

export const fetchLibraryItem = (id: string, fileName: string) =>
  action(LibraryActionTypes.FETCH_LIBRARY_ITEM, { id, fileName });
export const fetchLibraryItemSuccess = () =>
  action(LibraryActionTypes.FETCH_LIBRARY_ITEM_SUCCESS);
export const fetchLibraryItemError = () =>
  action(LibraryActionTypes.FETCH_LIBRARY_ITEM_ERROR);

export const deleteLibraryItem = (id: string) =>
  action(LibraryActionTypes.DELETE_LIBRARY_ITEM, id);
export const deleteLibraryItemSuccess = () =>
  action(LibraryActionTypes.DELETE_LIBRARY_ITEM_SUCCESS);
export const deleteLibraryItemError = () =>
  action(LibraryActionTypes.DELETE_LIBRARY_ITEM_ERROR);

export const addLibraryItem = (newItem: NewLibraryItem) =>
  action(LibraryActionTypes.ADD_LIBRARY_ITEM, { newItem });
export const addLibraryItemSuccess = () =>
  action(LibraryActionTypes.ADD_LIBRARY_ITEM_SUCCESS);
export const addLibraryItemError = () =>
  action(LibraryActionTypes.ADD_LIBRARY_ITEM_ERROR);
