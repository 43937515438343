import { CodedPartStatusEnum } from "~/pages/CodedParts/constans";
import { Paginable, PaginationMeta, TotalRecords } from "../types";

export type ApiResponse = Record<string, any>;

export enum CodedPartsActionTypes {
  FETCH_CODED_PARTS = "@@codedParts/FETCH_CODED_PARTS",
  FETCH_CODED_PARTS_SUCCESS = "@@codedParts/FETCH_CODED_PARTS_SUCCESS",
  FETCH_CODED_PARTS_ERROR = "@@codedParts/FETCH_CODED_PARTS_ERROR",
  COMPLETE_CODED_PART = "@@codedParts/COMPLETE_CODED_PART",
  COMPLETE_CODED_PART_SUCCESS = "@@codedParts/COMPLETE_CODED_PART_SUCCESS",
  COMPLETE_CODED_PART_ERROR = "@@codedParts/COMPLETE_CODED_PART_ERROR",
  COMPLETE_CODED_PART_REFRESH = "@@codedParts/COMPLETE_CODED_PART_REFRESH",
}

export interface CodedPartsState {
  readonly codedPartsList: CodedPart[]
  readonly loadingCodedParts: boolean
  readonly loadingCodedPartsError?: any
  readonly completeCodedPartError?: any
  readonly meta: PaginationMeta
  readonly totalRecords: TotalRecords
  readonly completeCodedPartSuccess?: boolean
  readonly loadingCompleteCodedPart: boolean
}

export interface CodedPart {
  id: string,
  orderNumber: string,
  referenceID: string,
  status: CodedPartStatusEnum,
  supplierOrderNumber: string,
  orderedDate: string | null,
  orderedBy: string | null,
  codes: any;
  packingForceQuantity: number,
  ldcOrderDate: string,
  businessSector: string,
  supplierSystemClientId: string,
  supplierSystemDeliveryId: string | null,
  categoryIdentifier: string | null,
}

export interface MappedCodedPart extends CodedPart {
  lineNumber: string,
  [key: string]: any
}

export type PaginableCodedPart = Paginable<CodedPart>;
