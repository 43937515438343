import { Checkbox, Dropdown, DropdownItemProps } from "semantic-ui-react";
import { localStorageService } from "~/services/LocalStorage";

import React, { useState } from "react";

export type Props = {
  name: string;
  label: string | JSX.Element;
  initialChecked?: boolean;
  storageKey: string;
  onClick: (name: string, checked: boolean) => void;
};

type LocalStorageColumn = {
  name: string;
  shouldDisplay: boolean;
};

const ToggleItem: React.FC<Props> = ({
  name,
  label,
  initialChecked,
  onClick,
  storageKey,
}) => {
  const [checked, setChecked] = useState(initialChecked || false);

  const handleClick = (ev: React.MouseEvent, el: DropdownItemProps) => {
    setChecked(!checked);
    onClick(el.name, !checked);
    setColumnLocalStorage(el.name, !checked);
  };

  const setColumnLocalStorage = (
    columnName: string,
    checked: boolean
  ): void => {
    let storedColumns =
      (localStorageService.get(
        `${storageKey}Columns`
      ) as LocalStorageColumn[]) || [];
    const columnToAdd = { name: columnName, shouldDisplay: checked };

    if (storedColumns) {
      if (!storedColumns.some((x) => x.name === columnName))
        storedColumns.push(columnToAdd);
      else {
        storedColumns = storedColumns.map((x) =>
          x.name === columnName ? columnToAdd : x
        );
      }
    } else {
      //@ts-ignore: Ignore Type never
      storedColumns.push(columnToAdd);
    }
    localStorageService.set(`${storageKey}Columns`, storedColumns);
  };

  return (
    <Dropdown.Item name={name} onClick={handleClick}>
      <Checkbox label={<label>{label}</label>} checked={checked} />
    </Dropdown.Item>
  );
};

export default ToggleItem;
