import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { IWarehouse, WarehouseActionTypes } from './types'
import {
    fetchWarehouses,
    fetchWarehousesSuccess,
} from './actions'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'

function* handleFetchWarehouses(action: ReturnType<typeof fetchWarehouses>) {
    try {
        const warehouseList: IWarehouse = yield call(callApi, 'get', '/warehouses')
        yield put(fetchWarehousesSuccess(warehouseList))
    } catch (error) {
        yield put(handleError(error as Error, false, 'Nie udało sie pobrać magazynów'))
    }
}

function* watchFetchWarehouses() {
    yield takeEvery(WarehouseActionTypes.FETCH_WAREHOUSES, handleFetchWarehouses)
}

function* warehousesSaga() {
    yield all([
        fork(watchFetchWarehouses)     
    ])
}

export default warehousesSaga