import translations from "~/utils/translations";

export const useLibraryButtons = (props: {
  isSuperAdmin: boolean;
  setOpenAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  hasEditRole: boolean;
}) => {
  const addButton = {
    icon: "add",
    content: translations.format("app.button.add"),
    primary: true,
    onClick: () => props.setOpenAddModal(true),
    visible: props.isSuperAdmin && props.hasEditRole,
  };

  return [addButton];
};
