import { Icon, Input } from 'semantic-ui-react'

import React, { Component } from 'react'

interface Props {
    column: {
        name: string
    }
    value: string
    type?: string
    onChangeFilter: (name: string, value: string, text: string) => void
    onChangeInputValue: (name: string, value: string) => void
    disabled?: boolean
}

class InputFilter extends Component<Props> {

    shouldComponentUpdate(nextProps: Props): boolean {
        return this.props.value !== nextProps.value
    }

    private changeInput(name: string, value: string): void {
        const { onChangeInputValue } = this.props
        onChangeInputValue(name, value)
    }

    private handleKeyPress(name: string, key: string, value: string): void {
        if (key === 'Enter') {
            const { onChangeFilter } = this.props
            onChangeFilter(name, value, value)
        }
    }

    private handleBlur(name: string, value: string): void {
        const { onChangeFilter } = this.props
        onChangeFilter(name, value, value)
    }

    private clear(name: string): void {
        const { onChangeFilter } = this.props
        onChangeFilter(name, '', '')
    }

    render() {
        const { column, value, type, disabled } = this.props

        return (
            <Input type={type ? type : 'text'} onChange={(e, d) => this.changeInput(column.name, d.value)}
                onKeyPress={(e: any) => this.handleKeyPress(column.name, e.key, e.target.value)}
                onBlur={(e: any) => this.handleBlur(column.name, e.target.value)}
                value={value}
                disabled={disabled}
                icon={value && <Icon name='delete' link onClick={() => this.clear(column.name)} />}
                style={{ width: '100%', minWidth: 50 }} className={`filter ${type === 'date' && !value ? 'date-filter-init' : ''}`} size='small' fluid transparent placeholder='...' />
        )
    }
}

export default InputFilter
