import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import dictionariesStatic from '~/store/dictionaries/static';
import { DictionaryItem } from '~/store/dictionaries/types';

const TransitionChange = ({ claimStatus, claimGroup, grn, setTransitionAction, transitionAction }: any) => {
  const showTransitionOptions = (option: string) => {
    switch (option) {
      case 'MARK_CLOSED':
        return claimStatus === 'CLOSED';
      case 'MARK_CLOSED_WITH_TRANSPORTATION_FEE':
        return claimStatus !== 'SENT_TO_CLIENT';
      case 'RETURN_TO_SUPPLIER':
        // return !((claimStatus === 'REFUNDED' || claimStatus === 'PARTIALLY_REFUNDED') && grn !== null && claimGroup !== 'RK4')
        return true;
      case 'DECISION_REJECTED':
        return !(claimStatus === 'AWAITING_DECISION' && claimGroup !== 'RK4');
      case 'REFUND':
        return !(
          (claimGroup === 'RK1' || claimGroup === 'RK4') &&
          (claimStatus === 'ACCEPTED' || claimStatus === 'PARTIALLY_ACCEPTED')
        );
      case 'DECISION_ACCEPTED':
        return claimStatus !== 'AWAITING_DECISION';
      case 'VERIFICATION_REQUEST_RETURN':
        return claimStatus !== 'VERIFICATION';
      case 'VERIFICATION_ACCEPTED':
        return claimStatus !== 'VERIFICATION';
      default:
        break;
    }
  };

  const getTransitionActionText = (action: DictionaryItem): string => {
    return claimStatus === 'PARTIALLY_REFUNDED' && action.value === 'RETURN_TO_SUPPLIER'
      ? 'Zwrot do dostawcy części zaakceptowanych'
      : action.text;
  };

  return (
    <>
      {dictionariesStatic.TRANSITION_ACTIONS.map((action, index) => {
        return !showTransitionOptions(action.value) ? (
          <Button
            key={index}
            fluid
            basic={transitionAction !== action.value}
            onClick={() => setTransitionAction(action.value)}
            style={{ marginBottom: 10, textAlign: 'left', display: 'inline-block', width: '33%' }}
          >
            <Form.Radio
              label={getTransitionActionText(action)}
              checked={transitionAction === action.value}
              value={action.value}
            />
          </Button>
        ) : null;
      })}
    </>
  );
};

export default TransitionChange;
