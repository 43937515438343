import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { PaymentMethodActionTypes, PaymentMethodState } from './types'

export const initialPaymentMethodsState: PaymentMethodState = {
    loading: false,
    creating: false,
    created: false,
    customerPaymentMethods: [],
    customerPaymentMethod:{
        paymentMethodId: '',
        type: 'CREDIT_CARD',
        validFrom: '',
        authorUsername: '',
        creationTime: '',
        cancelUsername: '',
        cancellationTime: '' ,
        canceled: false
    },
    cancelingPaymentMethod: false,
}

const reducer: Reducer<PaymentMethodState> = (state = initialPaymentMethodsState, action) => {
    switch (action.type) {
        case PaymentMethodActionTypes.FETCH_PAYMENT_METHODS: {
            return { ...state, loading: true }
        }
        case PaymentMethodActionTypes.FETCH_PAYMENT_METHODS_SUCCESS: {
            return { ...state, loading: false, customerPaymentMethods: action.payload.customerPaymentMethods }
        }
        case PaymentMethodActionTypes.FETCH_PAYMENT_METHOD_BY_ID: {
            return { ...state, loading: true }
        }
        case PaymentMethodActionTypes.FETCH_PAYMENT_METHOD_BY_ID_SUCCESS: {
            return { ...state, loading: false, customerPaymentMethod: action.payload.customerPaymentMethod }
        }
        case PaymentMethodActionTypes.FETCH_CURRENT_PAYMENT_METHOD: {
            return { ...state, loading: true }
        }
        case PaymentMethodActionTypes.FETCH_CURRENT_PAYMENT_METHOD_SUCCESS: {
            return { ...state, loading: false }
        }
        case PaymentMethodActionTypes.CREATE_PAYMENT_METHOD: {
            return { ...state, created: false, creating: true }
        }
        case PaymentMethodActionTypes.CREATE_PAYMENT_METHOD_SUCCESS: {
            return { ...state, created: true, creating: false, customerPaymentMethods: [...state.customerPaymentMethods, action.payload.createdPaymentMethod] }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return { ...state, loading: false, created: false, creating: false, customerPaymentMethods: [] }
        }
        case PaymentMethodActionTypes.CANCEL_PAYMENT_METHOD: {
            return { ...state, loading: true, cancelingPaymentMethod: true}
        }
        case PaymentMethodActionTypes.CANCEL_PAYMENT_METHOD_SUCCESS: {
            return { ...state, loading: false, cancelingPaymentMethod: false}
        }
        default: {
            return state
        }
    }
}

export { reducer as paymentMethodsReducer }
