export const claimCategories = {
  RK1_ZNRE: "RK1_ZNRE",
  RK1_ZRDP: 'RK1_ZRDP',
  RK1_ZRPN: 'RK1_ZRPN',
  RK1_ZRVE: 'RK1_ZRVE',
  RK2_ZRNG: 'RK2_ZRNG',
  RK2_ZRNF: 'RK2_ZRNF',
  RK3_Z1: 'RK3_Z1',
  RK3_Z2: 'RK3_Z2',
  RK4_P1: 'RK4_P1',
  RK4_P2: 'RK4_P2',
};
export const allowedClaimCategories = {
  COMMENTS : [claimCategories.RK1_ZNRE,claimCategories.RK1_ZRDP,claimCategories.RK1_ZRVE]
}

