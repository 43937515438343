import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Dashboard } from "~/pages/Dashboard/Dashboard";
import Products from "./pages/SpareParts/SparePartsClientPanel";

import Promotions from "~/pages/Promotions/Promotions";
import Claims from "~/pages/Claims/Claims";
import Accounting from "~/pages/Accounting/Accounting";
import ClaimDetails from "~/pages/Claims/Details/ClaimDetails";
import { ProtectedRoute } from "./routes";
import CancelRequests from "./pages/CancelRequests/CancelRequests";
import Results from "./pages/Results/Results";
import Library from "./pages/Library/Library";
import Contact from "./pages/Contact/Contact";
import Administration from "./pages/Administration/Administration";
import AnnouncementDetails from "./pages/Administration/lib/Announcements/AnnouncementDetails/AnnouncementDetails";
import AddAnnouncement from "./pages/Administration/lib/Announcements/AddAnnouncement/AddAnnouncement";
import OrdersClientPanel from "./pages/Orders/OrdersClientPanel";
import AddOrderClientPanel from "./pages/Orders/lib/Add/AddOrderClientPanel";
import OrderDetailsClientPanel from "./pages/Orders/Details/OrderDetailsClientPanel";
import { useClientPanelRoles } from "./services/useClientPanelRoles";
import { AVAILABLE_ROLE_ENUM } from "./store/users/types";
import SparePartDetails from "~/pages/SpareParts/Details";

enum Groups {
  SUPER_ADMIN = "/CUSTOMER_PANEL/SUPER_ADMIN",
  LOCAL_ADMIN = "/CUSTOMER_PANEL/LOCAL_ADMIN",
  LOCAL_USER = "/CUSTOMER_PANEL/LOCAL_USER",
}

export const ClientPanelRoutes: React.FC = () => {
  const { hasRole } = useClientPanelRoles();
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route path="/dashboard" component={Dashboard} />
      <ProtectedRoute
        path="/orders"
        component={OrdersClientPanel}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_VIEW
        )}
      />
      <ProtectedRoute
        path="/order/create"
        component={AddOrderClientPanel}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_EDIT
        )}
      />
      <ProtectedRoute
        path="/order/:id"
        component={OrderDetailsClientPanel}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_VIEW
        )}
      />
      <ProtectedRoute
        path="/accounting"
        component={Accounting}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ACCOUNTING_VIEW
        )}
      />
      <ProtectedRoute
        path="/claims"
        component={Claims}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/claim/:claimNumber"
        component={ClaimDetails}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/products"
        component={Products}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_PRODUCT_VIEW
        )}
      />
      <ProtectedRoute
        path="/product/:referenceId"
        component={SparePartDetails}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/promotions"
        component={Promotions}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/administration"
        component={Administration}
        availableForGroups={[Groups.SUPER_ADMIN, Groups.LOCAL_ADMIN]}
      />
      <ProtectedRoute
        path="/announcements/:id"
        component={AnnouncementDetails}
        availableForGroups={[Groups.SUPER_ADMIN]}
      />
      <ProtectedRoute
        path="/announcement/new"
        component={AddAnnouncement}
        availableForGroups={[Groups.SUPER_ADMIN]}
      />
      <ProtectedRoute
        path="/cancel-requests"
        component={CancelRequests}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/results"
        component={Results}
        availableForCustomer={true}
      />
      <ProtectedRoute
        path="/library"
        component={Library}
        availableForCustomer={hasRole(
          AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_LIBRARY_VIEW
        )}
      />
      <Route path="/contact" component={Contact} />
      <Route component={() => <div>Not Found</div>} />
    </Switch>
  );
};
