import { Reducer } from "redux";
import { ErrorActionTypes } from "~/store/error/types";
import { CustomerPaymentFactorState, FactorActionTypes } from "./types";

export const initialPaymentMethodsState: CustomerPaymentFactorState = {
  loading: false,
  customerPaymentFactors: [],
  customerPaymentFactor: null,
  created: false,
  creating: false,
  priorityChanged: false,
  paymentDueChanged: false,
  creatingLimit: false,
  limitCreated: false,
  createdFactorId: null,
  removing: false,
  downloadXLSPending: false
};

const reducer: Reducer<CustomerPaymentFactorState> = (
  state = initialPaymentMethodsState,
  action
) => {
  switch (action.type) {
    case FactorActionTypes.FETCH_FACTORS: {
      return { ...state, loading: true };
    }
    case FactorActionTypes.FETCH_FACTORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerPaymentFactors: action.payload.customerPaymentFactors,
      };
    }
    case FactorActionTypes.FETCH_FACTOR_BY_ID: {
      return { ...state, loading: true };
    }
    case FactorActionTypes.FETCH_FACTOR_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerPaymentFactor: action.payload.customerPaymentFactor,
      };
    }
    case FactorActionTypes.CHANGE_FACTOR_PRIORITY: {
      return { ...state };
    }
    case FactorActionTypes.CHANGE_FACTOR_PRIORITY_SUCCESS: {
      return {
        ...state,
        priorityChanged: !state.priorityChanged,
        customerPaymentFactors: action.payload.customerPaymentFactors,
      };
    }
    case FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE: {
      return {
        ...state,
        paymentDueChanged: true,
      };
    }
    case FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE_SUCCESS: {
      return {
        ...state,
        paymentDueChanged: false,
        customerPaymentFactors: action.payload.customerPaymentFactors,
      };
    }
    case FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE_FAILED: {
      return {
        ...state,
        paymentDueChanged: false,
      };
    }
    case FactorActionTypes.CREATE_FACTOR: {
      return { ...state, created: false, creating: true };
    }
    case FactorActionTypes.CREATE_FACTOR_SUCCESS: {
      return {
        ...state,
        created: true,
        creating: false,
        createdFactorId: action.payload.factorId,
      };
    }
    case FactorActionTypes.REMOVE_FACTOR: {
      return { ...state, removing: true };
    }
    case FactorActionTypes.REMOVE_FACTOR_SUCCESS: {
      return {
        ...state,
        removing: false,
        customerPaymentFactors: state.customerPaymentFactors.filter(
          (factor) => factor.paymentFactorId !== action.payload.removedFactorId
        ),
      };
    }
    case FactorActionTypes.ADD_DISTRIGO_LIMIT: {
      return { ...state, creatingLimit: true, limitCreated: false };
    }
    case FactorActionTypes.ADD_DISTRIGO_LIMIT_SUCCESS: {
      return { ...state, creatingLimit: false, limitCreated: true };
    }
    case FactorActionTypes.IMPORT_FACTOR_LIMITS_FROM_BANK: {
      return { ...state, loading: true };
    }
    case FactorActionTypes.IMPORT_FACTOR_LIMITS_FROM_BANK_SUCCESS: {
      return { ...state, loading: false };
    }
    case FactorActionTypes.DOWNLOAD_FACTOR_ORDERS_XLS: {
      return {...state, downloadXLSPending: true}
    }
    case FactorActionTypes.DOWNLOAD_FACTOR_ORDERS_XLS_END: {
      return {...state, downloadXLSPending: false}
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        loading: false,
        creating: false,
        priorityChanging: false,
        creatingLimit: false,
        created: false,
        removing: false,
        downloadXLSPending: false
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as factorReducer };
