import React from "react";
import { TLabelProps } from "~/components/SmartField/lib/types";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { Form, Icon } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";

/**
 * Non-editable label that renders similary to rest of Smart components.
 * May be used for sections titles or displaying non-editable properties.
 */
export const SmartLabel: React.FC<TLabelProps> = ({
  value,
  label,
  description,
  light,
  icon,
  align = "left",
  labelWidth = "6",
}) => {
  const { formatLabel } = useLabelFormatter({ label, light });

  if (value) {
    if (icon) {
      return (
        <Form.Group className="smart-label">
          <SmartPopup mouseEnterDelay={300} description={description}>
            <Form.Field
              label={<Icon name={icon} style={{ top: 0 }} />}
              style={{ position: "relative", textAlign: "end", marginRight: 5 }}
            ></Form.Field>
          </SmartPopup>
          <Form.Field label={formatLabel()} width={labelWidth} />
          <Form.Field
            label={<span>{value}</span>}
            style={{ textAlign: align }}
            width="6"
          />
        </Form.Group>
      );
    } else {
      return (
        <SmartPopup mouseEnterDelay={300} description={description}>
          <Form.Group className="smart-label">
            <Form.Field label={formatLabel()} width={labelWidth} />
            <Form.Field
              label={<span>{value}</span>}
              style={{ textAlign: align }}
              width="10"
            />
          </Form.Group>
        </SmartPopup>
      );
    }
  } else {
    return (
      <SmartPopup
        mouseEnterDelay={300}
        description={description}
        style={{ marginTop: "40px" }}
      >
        <Form.Field label={formatLabel()} style={{ marginTop: "1em" }} />
      </SmartPopup>
    );
  }
};
