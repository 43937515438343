import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button, Grid, Header, Icon } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { clearErrors, handleError } from '~/store/error/actions'
import { TUiError } from '~/store/error/types'
import { toastError } from '~/utils/toast'
import React from 'react'

type TReduxState = {
    error: TUiError
}

type TReduxActions = {
    handleError: typeof handleError
    clearErrors: typeof clearErrors
}

export type TProps = TReduxActions & TReduxState & RouteComponentProps

export type TState = {
    componentError?: any
}

export class ErrorBoundary extends React.Component<TProps, TState> {

    state: TState = {}

    static getDerivedStateFromError(error: any): TState {
        return { componentError: error }
    }

    componentDidUpdate(prevProps: TProps): void {
        if (this.props.location !== prevProps.location) {
            this.clearErrors()
        }
    }

    hasBlockingError(): boolean {
        return this.props.error && !!this.props.error.blocking
    }

    clearErrors(): void {
        this.props.clearErrors()
        this.setState({ componentError: undefined })
    }

    render(): JSX.Element {
        if (this.props.error && !this.props.error.blocking) {
            toastError(this.props.error.title, this.props.error.description)
            this.props.clearErrors()
        }

        if (this.state.componentError || this.hasBlockingError()) {
            return (
                <Grid textAlign='center' style={{ height: '100%', opacity: 0.8, paddingBottom: 50 }} verticalAlign='middle'>
                    <Grid.Column>
                        <Header icon color="orange">
                            <Icon name="warning sign" size="huge" style={{ paddingBottom: 10 }} />
                            {this.state.componentError ?
                                "Błąd podczas wczytywania widoku"
                                :
                                <>
                                    {this.props.error.title} < br />
                                    <small>{this.props.error.description}</small>
                                </>}
                        </Header>
                        <br />
                        <Button icon="refresh" basic circular onClick={() => this.clearErrors()} title="Odśwież" />
                    </Grid.Column>
                </Grid>
            )
        }

        return (
            <>
                {this.props.children}
            </>
        )
    }
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ errors }: ApplicationState) => {
    return {
        error: errors[errors.length - 1]
    }
}

const mapDispatchToProps: TReduxActions = { clearErrors, handleError }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ErrorBoundary))