import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TColumn } from "~/components/Table/lib/types";
import { IInvoice } from "~/store/invoices/types";
import { connect } from "react-redux";
import { Divider, Table } from "semantic-ui-react";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { ApplicationState } from "~/store";
import { DictionariesState } from "~/store/dictionaries/types";
import {
  exportDocumentOnRequest,
  fetchInvoices,
  getInvoicePdf,
} from "~/store/invoices/actions";
import { TotalRecords, PaginationMeta } from "~/store/types";
import { useInvoicesColumns } from "./useInvoicesCPColumns";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useCPInvoicesAdvancedSearch } from "./useInvoicesCPAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import IconHover from "~/components/IconHover/IconHover";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  exportTrigger: number;
};

type TReduxState = {
  invoices: IInvoice[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchInvoices: typeof fetchInvoices;
  getInvoicePdf: typeof getInvoicePdf;
  exportDocumentOnRequest: typeof exportDocumentOnRequest;
};

type InvoicesProps = TComponentProps & TReduxState & TReduxActions;

const InvoicesCP: React.FC<InvoicesProps> = ({
  fetchInvoices,
  getInvoicePdf,
  exportDocumentOnRequest,
  invoices,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  exportTrigger,
}) => {
  const { columnsConfig } = useInvoicesColumns();
  const [tableKey] = useState<string>("accounting/invoices-CP");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("invoiceDate");
  const [sortDirection] = useState<any>("DESC");
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState(false);

  const getInvoices = useCallback(
    (params?: any): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInvoices(Object.assign(paramsObj, params), true);
    },
    [
      setParams,
      fetchInvoices,
      filterLabelsRef,
      meta.page,
      meta.size,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters.map((item: any) => {
      if (
        item.name === "orderCustomer.deliveryType" &&
        item.value === "URGENT"
      ) {
        return { ...item, value: "URGENT,TURBO_PRO" };
      } else {
        return item;
      }
    });
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getInvoices({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getInvoices]);

  useEffect(() => {
    if (exportTrigger) {
      exportDocumentOnRequest("/customer-panel/accounting/invoices/export", {
        ...params,
      });
    }
    // eslint-disable-next-line
  }, [exportTrigger, exportDocumentOnRequest]);

  const { formFields, handleSubmit } = useCPInvoicesAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getInvoices,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getInvoices}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {invoices.map((document: IInvoice, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <IconHover
                name="download"
                onClick={() =>
                  getInvoicePdf(
                    document.invoiceNo,
                    `${document.documentMask}.pdf`,
                    true
                  )
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
    </Fragment>
  );
};

const mapStateToProps = ({
  invoices,
  dictionaries,
}: ApplicationState): TReduxState => ({
  invoices: invoices.list,
  loading: invoices.loadingInvoices,
  totalRecords: invoices.totalRecords,
  meta: invoices.meta,
  dictionaries: dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  fetchInvoices,
  getInvoicePdf,
  exportDocumentOnRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesCP);
