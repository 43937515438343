import { Paginable } from "~/store/types";
import { action } from "typesafe-actions";
import {
  Order,
  OrdersActionTypes,
  OrderStatus,
  TOrderCreate,
  PaginationParams,
  PaginableOrderLine,
  OrderLine,
  ModifyLinePayload,
  OrderBonusImportLine,
} from "./types";

export const fetchOrders = (params: any, forClientPanel?: boolean) =>
  action(OrdersActionTypes.FETCH_REQUEST, params, forClientPanel);

export const fetchOrdersSearch = (params: any) =>
  action(OrdersActionTypes.FETCH_SEARCH_REQUEST, params);

export const fetchSuccess = (orders: Paginable<Order>) =>
  action(OrdersActionTypes.FETCH_SUCCESS, orders);

export const fetchSearchSuccess = (orders: Order[]) =>
  action(OrdersActionTypes.FETCH_SEARCH_SUCCESS, { orders });

export const selectOrder = (id: string, forClientPanel?: boolean) =>
  action(OrdersActionTypes.SELECT_ORDER, id, forClientPanel);

export const orderSelected = (order: Order) =>
  action(OrdersActionTypes.SELECTED, order);

export const clearSelected = () => action(OrdersActionTypes.CLEAR_SELECTED);

export const prioritize = (orderID: string) =>
  action(OrdersActionTypes.PRIORITIZE, { orderID });

export const deprioritize = (orderID: string) =>
  action(OrdersActionTypes.DEPRIORITIZE, { orderID });

export const placeOrder = (order: TOrderCreate, forClientPanel?: boolean) =>
  action(OrdersActionTypes.PLACE_ORDER, order, forClientPanel);

export const placeOrderSuccess = (orderId: string) =>
  action(OrdersActionTypes.PLACE_ORDER_SUCCESS, orderId);

export const cancelOrder = (data?: object) =>
  action(OrdersActionTypes.CANCEL_ORDER, data);

export const cancelOrderSuccess = (resultOfCancelling?: string) =>
  action(OrdersActionTypes.CANCEL_ORDER_SUCCESS, resultOfCancelling);

export const clearCreated = () => action(OrdersActionTypes.CLEAR_CREATED);

export const changeStatusOrder = (id: string, status: OrderStatus) =>
  action(OrdersActionTypes.CHANGE_STATUS_ORDER, id, status);

export const changeStatusOrderSuccess = (id: string, status: OrderStatus) =>
  action(OrdersActionTypes.CHANGE_STATUS_ORDER_SUCCESS, id, status);

export const fetchOrderLines = (
  orderId: string,
  params: PaginationParams,
  filters?: any,
  forClientPanel?: boolean
) =>
  action(
    OrdersActionTypes.FETCH_LINES,
    { orderId, params, filters },
    forClientPanel
  );
export const fetchLinesSuccess = (lines: PaginableOrderLine) =>
  action<OrdersActionTypes, PaginableOrderLine>(
    OrdersActionTypes.FETCH_LINES_SUCCESS,
    lines
  );

export const addOrderLine = (orderId: string, line: OrderLine) =>
  action<OrdersActionTypes, ModifyLinePayload>(OrdersActionTypes.ADD_LINE, {
    orderId,
    line,
  });

export const updateOrderLine = (orderId: string, line: OrderLine) =>
  action<OrdersActionTypes, ModifyLinePayload>(OrdersActionTypes.UPDATE_LINE, {
    orderId,
    line,
  });
export const deleteOrderLine = (orderId: string, line: OrderLine) =>
  action<OrdersActionTypes, ModifyLinePayload>(OrdersActionTypes.DELETE_LINE, {
    orderId,
    line,
  });

export const loadLinesCsv = (textLines: string) =>
  action<OrdersActionTypes, string>(OrdersActionTypes.LOAD_CSV, textLines);

export const loadLinesExcel = (excelFile: File, forClientPanel?: boolean) =>
  action<OrdersActionTypes, File, boolean>(
    OrdersActionTypes.LOAD_XLS,
    excelFile,
    !!forClientPanel
  );

export const loadLinesExcelSpecial = (excelFile: File) =>
  action<OrdersActionTypes, File>(
    OrdersActionTypes.LOAD_XLS_SPECIAL,
    excelFile
  );

export const loadLinesLoaded = (lines: any[]) =>
  action<OrdersActionTypes, any[]>(OrdersActionTypes.LOAD_LINES_LOADED, lines);

export const uploadBonusOrders = (file: File, forClientPanel?: boolean) =>
  action<OrdersActionTypes, File, boolean>(
    OrdersActionTypes.UPLOAD_BONUS_ORDERS,
    file,
    !!forClientPanel
  );

export const uploadBonusOrdersSuccess = (
  orderBonusLines: OrderBonusImportLine[]
) =>
  action<OrdersActionTypes, OrderBonusImportLine[]>(
    OrdersActionTypes.UPLOAD_BONUS_ORDERS_SUCCESS,
    orderBonusLines
  );

export const uploadBonusOrdersClear = () =>
  action<OrdersActionTypes>(OrdersActionTypes.UPLOAD_BONUS_ORDERS_CLEAR);

export const uploadCancelLines = (file: File) =>
  action<OrdersActionTypes, File>(OrdersActionTypes.UPLOAD_CANCEL_LINES, file);

export const uploadCancelLinesSuccess = (status: number) =>
  action<OrdersActionTypes, number>(
    OrdersActionTypes.UPLOAD_CANCEL_LINES_SUCCESS,
    status
  );

export const uploadCancelLinesClear = () =>
  action<OrdersActionTypes>(OrdersActionTypes.UPLOAD_CANCEL_LINES_CLEAR);

export const cancelLines = (orderId: string, lines: string[]) =>
  action<OrdersActionTypes, { orderId: string; lines: string[] }>(
    OrdersActionTypes.CANCEL_LINES,
    { orderId, lines }
  );

export const cancelLinesSuccess = (status: number) =>
  action<OrdersActionTypes, number>(
    OrdersActionTypes.CANCEL_LINES_SUCCESS,
    status
  );

export const cancelLinesClear = () =>
  action<OrdersActionTypes>(OrdersActionTypes.CANCEL_LINES_CLEAR);

export const linesOperationFinished = (success: boolean) =>
  action<OrdersActionTypes, boolean>(
    OrdersActionTypes.LINES_OPERATION_FINISHED,
    success
  );

export const applyPromotion = (order: TOrderCreate, forClientPanel?: boolean) =>
  action(OrdersActionTypes.APPLY_PROMO, order, forClientPanel);

export const applyPromoFinished = (result?: TOrderCreate) =>
  action(OrdersActionTypes.PROMO_APPLIED, result);

export const clearPromotion = () => action(OrdersActionTypes.CLEAR_PROMO);

export const setTypeOfExportData = (typeOfExportData?: string) =>
  action(OrdersActionTypes.SET_TYPE_OF_EXPORT_DATA, typeOfExportData);

export const openChooseTypeOfExportDataModal = (params: any) =>
  action(OrdersActionTypes.OPEN_CHOOSE_EXPORT_DATA_MODAL, params);

export const saveDataFromSearchForm = (ordersDateFrom?: Date) =>
  action<OrdersActionTypes, Date | undefined>(
    OrdersActionTypes.SAVE_DATA_FROM_SEARCHFORM,
    ordersDateFrom
  );

export const saveDataToSearchForm = (ordersDateTo?: Date) =>
  action<OrdersActionTypes, Date | undefined>(
    OrdersActionTypes.SAVE_DATA_TO_SEARCHFORM,
    ordersDateTo
  );

export const saveReferenceIdSearchForm = (ordersReferenceId?: string) =>
  action<OrdersActionTypes, string | undefined>(
    OrdersActionTypes.SAVE_REFERENCE_ID_SEARCHFORM,
    ordersReferenceId
  );

export const exportOrdersOnRequest = (params?: any) =>
  action<OrdersActionTypes.EXPORT_ON_REQUEST, any>(
    OrdersActionTypes.EXPORT_ON_REQUEST,
    params
  );
export const exportOrdersOnRequestSuccess = () =>
  action<OrdersActionTypes.EXPORT_ON_REQUEST_SUCCESS>(
    OrdersActionTypes.EXPORT_ON_REQUEST_SUCCESS
  );
export const exportOrdersOnRequestError = () =>
  action<OrdersActionTypes.EXPORT_ON_REQUEST_ERROR>(
    OrdersActionTypes.EXPORT_ON_REQUEST_ERROR
  );
