import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { ApplicationState } from "~/store";

import React, { useCallback, useEffect } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import "./dashboard.scss";
import translations from "~/utils/translations";

import { AnnouncementCarousel } from "./lib/Announcements/AnnouncementCarousel";
import { fetchAnnouncements } from "~/store/annouoncements/actions";
import { Announcement } from "~/store/annouoncements/types";
import CommonLoader from "~/components/Loaders/CommonLoader";

type TReduxState = {
  announcements: Announcement[];
  loadingAnnouncements: boolean;
};

interface TReduxActions {
  fetchAnnouncements: typeof fetchAnnouncements;
}

type DashboardProps = TReduxState & TReduxActions;

const DashboardClientPanel: React.FC<DashboardProps> = ({
  fetchAnnouncements,
  announcements,
  loadingAnnouncements,
}) => {
  const getData = useCallback(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  useEffect(() => {
    document.title = translations.format("app.dashboard");
    getData();
  }, [getData]);

  return (
    <>
      <PageHeader
        icon="home"
        title={"Strona główna"}
        breadcrumb={[]}
        buttons={[]}
        loading={loadingAnnouncements}
        refreshAction={getData}
      />
      <CommonLoader loading={loadingAnnouncements} />
      <Grid
        style={{
          marginBottom: "unset",
          display: "block",
          paddingBottom: 15,
        }}
        stretched
      >
        <AnnouncementCarousel announcements={announcements} />
      </Grid>
    </>
  );
};

const mapStateToProps = ({ announcements }: ApplicationState) => ({
  announcements: announcements.announcements,
  loadingAnnouncements: announcements.loadingAnnouncements,
});

const mapDispatchToProps = {
  fetchAnnouncements,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardClientPanel);
