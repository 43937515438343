import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Dropdown, Grid, Popup } from 'semantic-ui-react'
import { fetchCount, fetchLasts, fetchNotifications, makeRead } from '~/store/notifications/actions'
import { ApplicationState } from '~/store'
import React, { useMemo } from 'react'
import CommonLoader from '~/components/Loaders/CommonLoader'
import './notification.scss'
import { TReduxActions, TReduxState } from './lib/types'
import { NotificationDropdownToggle } from "~/components/Notifications/NotificationDropdownToggle";
import { useNotification } from "~/components/Notifications/hooks/useNotification";
import { NotificationInfoItem } from "~/components/Notifications/NotificationInfoItem";


export type TNotifactionsProps = TReduxState & TReduxActions & RouteComponentProps

export const Notifications: React.FC<TNotifactionsProps> = (props) => {
    const { lasts, loadingCount, loadingLasts } = props;
    const {
        NOTIFICATIONS_FILTERS,
        notificationCount,
        showMessagesPopUp,
        lastsNotificationFilter,
        setLastsNotificationFilter,
        togglePopUp,
        changeGroup,
        seeAllMessages
    } = useNotification(props);

    const lastsNotifications = useMemo((): JSX.Element[] | JSX.Element => lasts.length ? lasts.map(notification => (
        <NotificationInfoItem key={notification.id} {...notification}/>
    )) : <p className='no-lasts-messages'>Brak ostatnich wiadomości.</p>, [lasts])

    return (
        <Popup on={'click'}
               disabled={showMessagesPopUp}
               onUnmount={() => setLastsNotificationFilter('')}
               position="top right"
               trigger={<NotificationDropdownToggle onClick={() => togglePopUp()}
                                                    notificationCount={notificationCount}
                                                    active={loadingCount}/>
               }>
            <Grid className='notifications-popup' padded reversed='computer' columns='equal'
                  style={{ width: '450px' }}>
                <Dropdown
                    placeholder='Wybierz rodzaj'
                    fluid
                    selection
                    value={lastsNotificationFilter}
                    onChange={changeGroup}
                    options={NOTIFICATIONS_FILTERS}
                    noResultsMessage='Brak wyników'
                    style={{ marginBottom: '10px' }}
                />
                {lastsNotifications}
                <CommonLoader loading={loadingLasts}/>
                <Grid.Row>
                    < Grid.Column textAlign='right'>
                        <Button primary onClick={() => seeAllMessages()} floated={'right'} size="small" compact>
                            Zobacz wszystkie
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Popup>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ notifications }: ApplicationState) => ({
    count: notifications.count,
    loadingCount: notifications.loadingCount,
    loadingLasts: notifications.loadingLasts,
    lasts: notifications.lasts
})

const mapDispatchToProps: TReduxActions = { fetchCount, fetchLasts, fetchNotifications, makeRead }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications))
