import { TModelInputProps } from "~/components/ModelForm/lib/types";
import React, { Fragment, ReactNode, useMemo } from "react";
import { Form } from "semantic-ui-react";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { useFormElement } from "~/components/ModelForm/hooks/useFormElement";

export const ModelTextArea = <T,>(props: TModelInputProps<T>) => {

    const { value, validation, handleChange, handleBlur, formatPopupContent } = useFormElement(props);

    const hasRequiredValidator = props.validators && props.validators.indexOf(REQUIRED) !== -1

    const rendered: ReactNode = useMemo(
        () => (
            <SmartPopup description={formatPopupContent()}>
                <Form.TextArea
                    required={hasRequiredValidator}
                    {...props}
                    rows={5}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!validation[props.name]}
                />
            </SmartPopup>
        ), [formatPopupContent, handleBlur, handleChange, hasRequiredValidator, props, validation, value]
    )

    return (
        <Fragment>
            {rendered}
        </Fragment>
    )
}