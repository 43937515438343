export const columnsReplenishments = [
    { name: 'orderNumber', label: 'Numer zamówienia', projection: true },
    { name: 'deliveryID', label: 'RRDI', projection: true },
    { name: 'ordered', label: 'Ilość', projection: true },
    { name: 'backorders', label: 'Zamówienia hurtowe', projection: true},
    { name: 'grns', label: 'Przyjęcia', projection: true },
    { name: 'gdnLines', label: 'Wydania', projection: true },
    { name: 'orderType', label: 'Typ zamówienia', projection: true, noKey: true },
    { name: 'creationDate', label: 'Data utworzenia', projection: true, type: 'date' },
    { name: 'status', label: 'Status zamówienia', projection: true, noKey: true },
    { name: 'priority', label: 'Priorytet', projection: true },
]

export const dictionariesClaimStatus = [
    {
        key: "AWAITING_BACKORDER",
        text: "Oczekujące na zam. hurt.",
        value: "AWAITING_BACKORDER"
    },
    {
        key: "BACKORDER_CREATED",
        text: "Zam. Hurt. W realizacji",
        value: "BACKORDER_CREATED"
    },
    {
        key: "GRN_CREATED",
        text: "Oczekujące na przyjęcie",
        value: "GRN_CREATED"
    },
    {
        key: "GDN_CREATED",
        text: "Oczekujące na wydanie",
        value: "GDN_CREATED"
    },
]