import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import {
  fetchSparePartForCP,
  removeAllSparePartForCP,
  removeSparePartForCP,
} from "~/store/spare-parts/actions";
import { SparePartCP } from "~/store/spare-parts/types";
import translations from "~/utils/translations";
import { DataGridTable } from "~/components/Table/DataGridTable";
import SparePartsList from "./List";
import { useSparePartsColumns } from "./hooks/useSparePartsColumns";
import { useSparePartsButtonsClientPanel } from "./hooks/useSparePartsButtonsClientPanel";
import { Button, Search, SearchProps, Table } from "semantic-ui-react";

import "./Spare-Parts.scss";
import { useHandleDownloadClientPanel } from "./hooks/useHandleDownloadClientPanel";
import { useCells } from "~/services/useCells";

type TReduxState = {
  spareParts: SparePartCP[];
  loadingSpareaPart: boolean;
  loadingDictionaries: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchSparePartForCP: typeof fetchSparePartForCP;
  fetchDictionary: typeof fetchDictionary;
  removeSparePartForCP: typeof removeSparePartForCP;
  removeAllSparePartForCP: typeof removeAllSparePartForCP;
};

type TProps = TReduxState & TReduxActions & ConnectedReduxProps;

const SparePartsClientPanel: React.FC<TProps> = ({
  spareParts,
  loadingSpareaPart,
  loadingDictionaries,
  dictionaries,
  fetchDictionary,
  fetchSparePartForCP,
  removeSparePartForCP,
  removeAllSparePartForCP,
}) => {
  const { columnsConfigClientPanel } = useSparePartsColumns();
  const [tableKey] = useState<string>("spare-parts-client-panel");
  const [columns, setColumns] = useState(columnsConfigClientPanel);
  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorClass, setErrorClas] = useState("");

  useEffect(() => {
    if (errorMessage.includes("ilość")) {
      setErrorClas("add-refference-info-error");
    } else {
      setErrorClas("add-refference-red-error");
    }
  }, [errorMessage]);

  const setErrorInfo = useCallback((errorContent: string) => {
    setErrorMessage(errorContent);
  }, []);

  const handleRemoveAll = useCallback(() => {
    removeAllSparePartForCP();
    setErrorInfo("");
    setSearchValue("");
  }, [removeAllSparePartForCP, setErrorInfo]);

  const { handleOpenDownloadModal } = useHandleDownloadClientPanel({
    columns,
    spareParts,
  });

  const buttons = useSparePartsButtonsClientPanel({
    handleExport: () => handleOpenDownloadModal(),
    handleRemoveAll,
    spareParts,
  });

  const clearForm = useCallback(() => {
    setSearchValue("");
  }, []);

  const searchReference = useCallback(() => {
    if (!searchValue.length) return;
    fetchSparePartForCP(searchValue, clearForm, setErrorInfo);
  }, [searchValue, clearForm, fetchSparePartForCP, setErrorInfo]);

  useEffect(() => {
    document.title = translations.format("app.products");
    fetchDictionary(
      DictionaryName.sparePartCategoryAdv,
      DictionaryName.sparePartCategoryOrigin,
      DictionaryName.sparePartTaxCategory,
      DictionaryName.sparePartDiscountPcd,
      DictionaryName.sparePartDiscountIam,
      DictionaryName.sparePartDiscountFca,
      DictionaryName.sparePartState,
      DictionaryName.spareParReplacementCategory,
      DictionaryName.sparePartBrand,
      DictionaryName.sparePartHierarchyFamily,
      DictionaryName.sparePartHierarchySlot,
      DictionaryName.sparePartHierarchySegment,
      DictionaryName.sparePartHierarchyIndex,
      DictionaryName.sparePartProvisionCategory,
      DictionaryName.sparePartProvisionForceCategory
    );
  }, [fetchDictionary]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  const handleSearchChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, data: SearchProps) => {
      setSearchValue(data.value || "");
      setErrorMessage("");
    },
    []
  );

  const searchInputIsActive = useMemo(
    () => spareParts.length < 10,
    [spareParts]
  );

  useEffect(() => {
    if (spareParts.length === 10) {
      setErrorInfo("Osiągnięto maksymalną ilość pozycji na liście.");
    }
  }, [spareParts, setErrorInfo]);

  const handleRemoveFromList = useCallback(
    (sparePart: SparePartCP) => {
      removeSparePartForCP(sparePart.referenceId);
      setErrorInfo("");
    },
    [removeSparePartForCP, setErrorInfo]
  );

  return (
    <Fragment>
      <PageHeader
        icon="tag"
        title={"Produkty"}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        refreshAction={undefined}
        loading={loadingDictionaries}
      />

      <DataGridTable
        columns={columns}
        fetchMethod={() => {}}
        initSortColumn={undefined}
        initSortDirection={undefined}
        isAdvancedSearchOpen={false}
        loading={false}
        setColumns={setColumns}
        tableKey={tableKey}
        dictionaries={dictionaries}
        meta={undefined}
        totalRecords={undefined}
        customHeight="calc(95vh - 150px)"
        noSearch={true}
      >
        <SparePartsList
          columns={columns}
          spareParts={spareParts}
          forClientPanel={true}
          handleRemoveFromList={handleRemoveFromList}
        />
        <Table.Row>
          <Table.Cell
            colSpan="100"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingInline: "10px",
              width: "100%",
            }}
          >
            <div className={errorClass}>{errorMessage}</div>
            <Search
              className="add-reference-search"
              loading={loadingSpareaPart}
              open={false}
              value={searchValue}
              onSearchChange={handleSearchChange}
              placeholder={"Wyszukiwanie referencji"}
              disabled={!searchInputIsActive}
            />
            <span>
              <Button
                style={{ marginLeft: 10, borderRadius: "50%" }}
                primary
                icon="search"
                size="tiny"
                loading={loadingSpareaPart}
                onClick={searchReference}
                disabled={!searchInputIsActive}
              ></Button>
            </span>
          </Table.Cell>
        </Table.Row>
      </DataGridTable>
      <CommonLoader loading={loadingDictionaries} />
    </Fragment>
  );
};

const mapStateToProps = ({
  spareParts,
  dictionaries,
}: ApplicationState): TReduxState => ({
  spareParts: spareParts.customerPanelList,
  loadingDictionaries: dictionaries.loading,
  loadingSpareaPart: spareParts.loadingSparePartCP,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  fetchSparePartForCP,
  fetchDictionary,
  removeSparePartForCP,
  removeAllSparePartForCP,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SparePartsClientPanel);
