import { Form, Label } from "semantic-ui-react";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { TPromotion } from "~/store/promotions/types";

import React from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import dictionariesStatic from "~/store/dictionaries/static";
import GroupMinimumCondition from "./lib/GroupMinimum";

type TProps = {
  promotion: TPromotion;
  editMode: boolean;
};

const GroupPromotion: React.FC<TProps> = ({ promotion, editMode }) => {
  const { renderField, renderSelect } = useRenderingFunctions(
    promotion as any,
    editMode
  );

  const saveMapper = (v: string) => (Number(v) <= 0 ? null : Number(v));
  const isFirstDiscountTypeSet =
    promotion.firstDiscount && promotion.firstDiscount.type;
  return (
    <DetailCard id="discount" title="Upust" width={5.33} minHeight={0}>
      <Form.Group widths="equal">
        {renderSelect(
          "firstDiscount.Type",
          dictionariesStatic.PROMO_GROUP_DISCOUNT_TYPE,
          { label: "Rodzaj upustu", validators: [REQUIRED] }
        )}
        {renderField(
          promotion.firstDiscount && promotion.firstDiscount.type === "PRICING"
            ? "firstDiscount.amountDiscount"
            : "firstDiscount.percentageDiscount",
          {
            label:
              promotion.firstDiscount &&
              promotion.firstDiscount.type === "PRICING"
                ? "Nowa cena za sztukę (zł)"
                : "Wysokość rabatu (%)",
            type: "number",
            saveMapper,
            min: 0,
            precision: 2,
            validators: [REQUIRED],
          }
        )}
      </Form.Group>
      {isFirstDiscountTypeSet && (
        <GroupMinimumCondition
          promotion={promotion}
          editMode={editMode}
          discountGroup="firstDiscount"
        />
      )}
      {
        <Label style={{ lineHeight: "18px" }}>
          Upust zostanie zastosowany dla produktów wchodzących w skład drugiej
          grupy produktów pod Warunkiem zakupu odpowiedniej ilości referencji
          zdefiniowanych w pierwszej grupie
        </Label>
      }
    </DetailCard>
  );
};

export default GroupPromotion;
