import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import { getEndecodedClaimNumber } from "~/store/claims/sagas";
import { IClaim } from "~/store/claims/types";

type Props = {
  claim: IClaim;
  setClaim: React.Dispatch<React.SetStateAction<IClaim | undefined>>;
};

export const CaseNumbers: React.FC<Props> = ({ claim, setClaim }) => {
  const [editMode, setEditMode] = useState(false);
  const { renderField } = useRenderingFunctions(claim, editMode);

  const handleClaimUpdate = (updatedModel: IClaim) => {
    setClaim(updatedModel);
  };

  const { userHaveLdcUiClaimsProcessRole } = useClaimsRolesRights();

  return (
    <>
      {userHaveLdcUiClaimsProcessRole ? (
        <Button
          icon="pencil"
          primary={editMode}
          onClick={() => setEditMode((state) => !state)}
          className="edit-button"
        />
      ) : null}
      <SmartWrapper
        endpoint={
          claim
            ? `/claims/${getEndecodedClaimNumber(claim.claimNumber)}/external`
            : ""
        }
        model={claim}
        onUpdate={handleClaimUpdate}
        method="PUT"
      >
        {renderField("cspsClaimNumber", {
          label: "Numer reklamacji CSPS",
        })}
        {renderField("sapPlpr", {
          label: "SAP PLPR",
        })}
        {renderField("ticketPowerLine", {
          label: "Ticket Power Line",
        })}
        {renderField("ticketPamir", {
          label: "Ticket PAMIR",
        })}
        {renderField("ticketCrm", {
          label: "Ticket CRM",
        })}
      </SmartWrapper>
    </>
  );
};
