import { ModelInput } from "~/components/ModelForm/ModelInput";
import React, { SyntheticEvent } from "react";
import {
  getInputType,
  validNumberKey,
} from "~/pages/Administration/lib/Dictionaries/utils";
import { TDetailsFieldType } from "~/store/dictionaries/types";
import { SmartValidator } from "~/components/SmartField/lib/validator";
import { CustomDropdown } from "~/pages/Administration/lib/Dictionaries/Dropdown";
import { FieldNameEnum } from "~/pages/Administration/lib/Dictionaries/constants";

export const renderInput = (
  type: TDetailsFieldType,
  name: string,
  validators: SmartValidator[]
): JSX.Element => {
  switch (name) {
    case FieldNameEnum.sell:
    case FieldNameEnum.buy:
      return (
        <ModelInput
          label={name}
          type={getInputType(type)}
          onKeyDown={(event: SyntheticEvent) => {
            event.persist();
            validNumberKey(event);
          }}
          placeholder={`Wprowadź ${name}`}
          name={name}
          validators={validators}
          step={0.01}
        />
      );
    case FieldNameEnum.gtu:
      return <CustomDropdown name="gtu" placeholder="Wybierz kod" text="gtu" />;
    default:
      return (
        <ModelInput
          label={name}
          type={getInputType(type)}
          placeholder={`Wprowadź ${name}`}
          name={name}
          validators={validators}
        />
      );
  }
};