import { useEffect, useMemo, useState } from "react";
import { Announcement } from "~/store/annouoncements/types";
import { TNewAnnouncement } from "./AddAnnouncement/AddAnnouncement";

type TProps = {
  announcementForEdit: Announcement | TNewAnnouncement | undefined;
};

export const useAnnouncementsValidation = ({ announcementForEdit }: TProps) => {
  const [validMessage, setValidMessage] = useState<string>("");

  const validate = useMemo(() => {
    if (!announcementForEdit) return false;

    const { content, image, title, startDate, endDate, intendedFor } =
      announcementForEdit;

    if (!(content || image)) {
      setValidMessage("Wymagane jest dodanie treści bądź obrazka");
      return false;
    }

    if (!title) {
      setValidMessage("Wymagane jest dodanie tytułu");
      return false;
    }

    if (!startDate || !endDate) {
      setValidMessage("Data rozpoczęcia i zakończenia muszą być uzupełnione");
      return false;
    }

    if (!intendedFor.length) {
      setValidMessage(
        "Wymagane jest oznaczenie przynajmniej jednej roli dla któej ogłoszenie ma być dostępne"
      );
      return false;
    }

    setValidMessage("");
    return true;
  }, [announcementForEdit]);

  const [isValid, setIsValid] = useState<boolean>(validate);

  useEffect(() => {
    setIsValid(validate);
  }, [announcementForEdit, validate]);

  return { isValid, validMessage };
};
