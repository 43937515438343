export const parseDate = (
  dateRaw: Date | string | number | null | undefined
): string | number | null => {
  if (!dateRaw) return null;
  const date = new Date(dateRaw);
  return `${date.getFullYear()}-${formatToZeroInFront(
    date.getMonth() + 1
  )}-${formatToZeroInFront(date.getDate())} ${formatToZeroInFront(
    date.getHours()
  )}:${formatToZeroInFront(date.getMinutes())}:${formatToZeroInFront(
    date.getSeconds()
  )}`;
};

export const parseDateNoTime = (
  dateRaw: Date | string | null
): string | null => {
  if (!dateRaw) return null;
  const date = new Date(dateRaw);
  return `${date.getFullYear()}-${formatToZeroInFront(
    date.getMonth() + 1
  )}-${formatToZeroInFront(date.getDate())}`;
};

export const prepareDateForFilter = (
  dateTo: Date | undefined,
  dateFrom: Date | undefined,
  substring: number
) => {

  const countTimezoneOffset= (date: Date): number  => {
    return date.getTimezoneOffset() * 60000;
  }
  
  let formDateTo: Date | string;
  if (dateTo && dateFrom) {
    formDateTo = new Date(Number(dateTo) - countTimezoneOffset(dateTo));
    formDateTo.setUTCHours(23, 59, 59, 0);
    return (
      new Date(Number(dateFrom) - countTimezoneOffset(dateFrom))
        .toISOString()
        .substring(0, substring) +
      "::" +
      formDateTo.toISOString().substring(0, substring)
    );
  } else if (dateTo) {
    formDateTo = new Date(Number(dateTo) - countTimezoneOffset(dateTo));
    formDateTo.setUTCHours(23, 59, 59, 0);
    return "::" + formDateTo.toISOString().substring(0, substring);
  } else if (dateFrom) {
    return (
      new Date(Number(dateFrom) - countTimezoneOffset(dateFrom))
        .toISOString()
        .substring(0, substring) + "::"
    );
  } else {
    return "";
  }
};

const isDate = (date: Date, days = 0) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const dateFromCalendar = new Date(date).setHours(0, 0, 0, 0);

  return (
    new Date(currentDate + days).toISOString() <=
    new Date(dateFromCalendar).toISOString()
  );
};

export const isDatePast = (date: Date): boolean => isDate(date);

export const isDatePastOrCurrent = (date: Date): boolean => isDate(date, 1);

const formatToZeroInFront = (number: number): string =>
  String("0" + number).slice(-2);
