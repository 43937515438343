import React, { useCallback } from "react";
import { useMemo } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { useCells } from "~/services/useCells";
import { DictionariesState } from "~/store/dictionaries/types";

type TProps = {
  tableKey: string;
};

export const useAnnouncementsColumns = ({ tableKey }: TProps) => {
  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();

  const getActiveUnactiveCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name] ? (
          <Icon name="eye slash" size="large" style={{ color: "red" }} />
        ) : (
          <Icon name="eye" size="large" style={{ color: "green" }} />
        )}
      </Table.Cell>
    ),
    []
  );

  const getIntendedForCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name].length}
        <InfoIcon
          documents={document.intendedFor}
          clickableItem={(intendedItem) => (
            <Button
              compact
              basic
              key={intendedItem}
              style={{
                display: "block",
                width: "100%",
                boxShadow: "none",
                outline: "none",
              }}
            >
              {intendedItem}
            </Button>
          )}
          noResultsMessage="Brak"
        />
      </Table.Cell>
    ),
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "createDate",
        label: "Data dodania",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "title",
        label: "Tytuł",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "type",
        label: "Typ",
        projection: true,
        noKey: true,
        dictionary: [
          { key: "TEXT", value: "TEXT", text: "Tekst" },
          { key: "IMAGE", value: "IMAGE", text: "Obraz" },
        ],
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "hidden",
        label: "Widoczność",
        projection: true,
        noKey: true,
        dictionary: [
          { key: "true", value: "true", text: "Tak" },
          { key: "false", value: "false", text: "Nie" },
        ],
        getCell: (document, column) => getActiveUnactiveCell(document, column),
      },
      {
        name: "priority",
        label: "Priorytet",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "intendedFor",
        label: "Widoczne dla",
        projection: true,
        getCell: (document, column) => getIntendedForCell(document, column),
      },
      {
        name: "startDate",
        label: "Data rozpoczęcia",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "endDate",
        label: "Data zakończenia",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "author",
        label: "Autor",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [
      getDateCell,
      getTextCell,
      getDictionaryCell,
      getIntendedForCell,
      getActiveUnactiveCell,
    ]
  );

  const { completeColumnDictionaries } = useCells({
    tableKey,
    dictionaries: {} as DictionariesState,
  });

  return { columnsConfig: completeColumnDictionaries(columnsConfig) };
};
