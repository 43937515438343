import { SmartLabel } from '~/components/SmartField/SmartLabel';
import React from 'react';
import { DeliveryStockProps } from '~/pages/Orders/lib/Add/constants';

const DeliveryStock = ({ warehouses }: DeliveryStockProps) => (
  <>
    <SmartLabel label="Magazyn" />
    {warehouses.map(({ warehouseId, warehouseName }) => (
      <div key={warehouseId}>
        {warehouseName} ({warehouseId})
      </div>
    ))}
  </>
);

export default DeliveryStock;
