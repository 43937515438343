import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import { ImportXlsPopupProps } from "~/pages/Orders/lib/Add/constants";

const ImportXlsPopup = ({
  handleImportXls,
  order,
  summary,
}: ImportXlsPopupProps) => (
  <Popup
    trigger={
      <Button basic onClick={handleImportXls} disabled={!order.type || summary}>
        <Icon name="file excel" />
        Importuj linie zamówienia
      </Button>
    }
    position="bottom right"
    content="Importuj linie z pliku XLSX, XLS, CSV"
  />
);

export default ImportXlsPopup;
