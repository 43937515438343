import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Message, Table } from 'semantic-ui-react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState } from '~/store';
import { Customer } from '~/store/customer/types';
import { CustomerPaymentMethod } from '~/store/payment-methods/types';
import { cancelPaymentMethod, fetchPaymentMethods } from '~/store/payment-methods/actions';
import HistoryModal from '~/pages/Customers/Details/lib/HistoryModal';
import { parseDate } from '~/utils/dateUtils';
import CommonLoader from '~/components/Loaders/CommonLoader';
import mapDictionary from '~/components/MapDictionary/mapDictionary';
import { DictionariesState } from '~/store/dictionaries/types';

type ActivePaymentFormProps = {
  customer: Customer;
  editMode: boolean;
};

type TReduxState = {
  loading: boolean;
  canceling: boolean;
  paymentMethods: CustomerPaymentMethod[];
  creating: boolean;
  created: boolean;
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchPaymentMethods: typeof fetchPaymentMethods;
  deletePaymentMethod: typeof cancelPaymentMethod;
};

type Props = TReduxActions & TReduxState & ActivePaymentFormProps;

const ActualPaymentMethod = ({
  customer,
  paymentMethods,
  fetchPaymentMethods,
  deletePaymentMethod,
  loading,
  canceling,
  creating,
  created,
  dictionaries,
}: Props) => {
  const [customerId, setCustomerId] = useState<string>(customer.rrdi);

  useEffect(() => {
    setCustomerId(customer.rrdi);
    fetchPaymentMethods(customerId);
    // eslint-disable-next-line
  }, [canceling, created, customer.rrdi]);

  const handleCancelPaymentMethod = useCallback(
    (paymentMethodId: string) => {
      deletePaymentMethod(customerId, paymentMethodId);
    },
    [customerId, deletePaymentMethod]
  );

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const isPaymentMethodBeforeCurrent = useCallback(
    (paymentMethodDate: Date) => {
      if (customer && customer.currentPaymentMethod) {
        return paymentMethodDate.toISOString() <= new Date(customer.currentPaymentMethod?.validFrom).toISOString();
      }
    },
    [customer]
  );

  const isPaymentMethodCancelledOrInactive = useCallback(
    (method: CustomerPaymentMethod): boolean => {
      const inactiveMethods = paymentMethods.filter(
        (e) =>
          e.canceled ||
          e.paymentMethodId === customer.currentPaymentMethod?.paymentMethodId ||
          isPaymentMethodBeforeCurrent(new Date(e.validFrom))
      );
      return inactiveMethods.some((e) => e === method);
    },
    [customer.currentPaymentMethod?.paymentMethodId, paymentMethods, isPaymentMethodBeforeCurrent]
  );

  const getDateOfNewestPaymentMethod = (): string => {
    if (paymentMethods.length !== 0) {
      return parseDate(
        new Date(Math.max(...paymentMethods.filter((e) => !e.canceled).map((e) => Number(new Date(e.validFrom)))))
      )
        ?.toLocaleString()
        .split(' ')[0] as string;
    }
    return '';
  };

  const isActivePaymentMethodEqualToNewest = (): boolean => {
    if (paymentMethods.length !== 0 && customer.currentPaymentMethod) {
      return (
        new Date(
          Math.max(...paymentMethods.filter((e) => !e.canceled).map((e) => Number(new Date(e.creationTime))))
        ).getTime() === new Date(customer.currentPaymentMethod?.creationTime).getTime()
      );
    }
    return false;
  };

  const renderLines = useCallback(
    (methods: CustomerPaymentMethod[]): JSX.Element => {
      return (
        <Table basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Metoda płatności</Table.HeaderCell>
              <Table.HeaderCell>Data zobowiązywania</Table.HeaderCell>
              <Table.HeaderCell>Użytkownik</Table.HeaderCell>
              <Table.HeaderCell>Data dodania</Table.HeaderCell>
              <Table.HeaderCell>Anulowanie</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {methods
              ?.sort((a, b) => new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime())
              .map((line) => (
                <Table.Row key={line.paymentMethodId}>
                  <Table.Cell style={{ fontSize: '100%' }}>
                    {mapDictionary(line.type, dictionaries['payment-method'], true, true, true)}
                  </Table.Cell>
                  <Table.Cell>{parseDate(line.validFrom)}</Table.Cell>
                  <Table.Cell>{line.authorUsername}</Table.Cell>
                  <Table.Cell>{parseDate(line.creationTime)}</Table.Cell>
                  <Table.Cell style={{ fontStyle: 'italic' }}>
                    {line.canceled ? `${parseDate(line.cancellationTime)} / ${line.cancelUsername}` : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(line.validFrom) > new Date() && !line.canceled ? (
                      <Button
                        color="orange"
                        onClick={() => handleCancelPaymentMethod(line.paymentMethodId)}
                        disabled={isPaymentMethodCancelledOrInactive(line)}
                        content="Anuluj"
                        key={line.paymentMethodId}
                      />
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      );
    },
    [dictionaries, handleCancelPaymentMethod, isPaymentMethodCancelledOrInactive]
  );

  return (
    <>
      {customer && customer.currentPaymentMethod ? (
        <>
          <CommonLoader loading={loading || creating} />
          <SmartLabel
            label={formatMessage('app.customer.activePaymentForm.paymentForm')}
            value={mapDictionary(customer?.currentPaymentMethod.type, dictionaries['payment-method'], true, true, true)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.activePaymentForm.paymentFormStart')}
            value={parseDate(customer?.currentPaymentMethod.validFrom)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.activePaymentForm.user')}
            value={customer?.currentPaymentMethod.authorUsername}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.activePaymentForm.userDate')}
            value={parseDate(customer?.currentPaymentMethod.creationTime)}
            align="left"
          />
        </>
      ) : (
        <Message color="orange" style={{ width: '100%' }}>
          <Container fluid textAlign="center">
            Brak aktualnej metody płatności.
          </Container>
        </Message>
      )}
      {!isActivePaymentMethodEqualToNewest() &&
      paymentMethods?.length !== 0 &&
      paymentMethods.some((method) => !method.canceled) ? (
        <Message
          color="orange"
          content={`Zdefiniowano nową metodę płatności obowiązującą od: ${getDateOfNewestPaymentMethod()} (Kliknij w Historia, aby zobaczyć  sczegóły)`}
        />
      ) : null}
      <HistoryModal loading={loading} list={paymentMethods}>
        {renderLines(paymentMethods || [])}
        <CommonLoader loading={canceling} />
      </HistoryModal>
    </>
  );
};
const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  paymentMethods,
  dictionaries,
}: ApplicationState) => {
  return {
    loading: paymentMethods.loading,
    paymentMethods: paymentMethods.customerPaymentMethods,
    canceling: paymentMethods.cancelingPaymentMethod,
    creating: paymentMethods.creating,
    created: paymentMethods.created,
    dictionaries,
  };
};
const mapDispatchToProps: TReduxActions = {
  fetchPaymentMethods,
  deletePaymentMethod: cancelPaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActualPaymentMethod);
