/**
 * Get value of object property based on property name.
 * 
 * @param model Object with properties.
 * @param name Name of property to read value. Dot separated for nested.
 */
export const extractValue = (model: any, name: string): any => {
    if (!model) {
        return model
    }
    const splitPoint = name.indexOf('.')
    if (splitPoint !== -1) {
        const keyName = name.substr(0, splitPoint)
        const restOfName = name.substr(splitPoint + 1)
        const arrayValue = keyName.match(/\[(.*?)\]/)

        if (arrayValue !== null) {
            const arraySplitPoint = keyName.indexOf('[')
            const arrayKeyName = keyName.substr(0, arraySplitPoint)
            
            if (!model[arrayKeyName]) return model[arrayKeyName]
            return extractValue(model[arrayKeyName][arrayValue[1]], restOfName)
        }

        return extractValue(model[keyName], restOfName)
    }
    return model[name]
}

/**
 * Set specified value as a property of specified object.
 * 
 * @param model Object with properties.
 * @param name Name of property to put value in. Dot separated for nested.
 * @param value Value to put.
 */
export const mergeValue = (model: any, name: string, value: any): any => {
    const splitPoint = name.indexOf('.')
    if (splitPoint !== -1) {
        const name1 = name.substr(0, splitPoint)
        const name2 = name.substr(splitPoint + 1)
        return { ...model, [name1]: mergeValue(model ? model[name1] : {}, name2, value) }
    }
    return { ...model, [name]: value }
}
