import { ModelDropdown } from "~/components/ModelForm/ModelDropdown";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import React from "react";
import { mapDictionary } from "~/pages/Administration/lib/Dictionaries/utils";
import { CustomDropdownProps } from "~/pages/Administration/lib/Dictionaries/constants";

export const CustomDropdown = ({
  name,
  placeholder,
  text,
}: CustomDropdownProps) => (
  <div className="required field">
    <label>{text}</label>
    <ModelDropdown
      name={name}
      placeholder={placeholder}
      fluid
      selection
      validators={[REQUIRED]}
      options={mapDictionary("GTU_CODES")}
    />
  </div>
);