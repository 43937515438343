import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { ConfirmModalProps } from '~/pages/Orders/lib/Add/Type/constants';

const ConfirmModal = ({
  summary,
  openConfirmModal,
  typeDict,
  order,
  updateOrder,
  discardChangeOrder,
  confirmChangeOrder,
  currentOrder,
}: ConfirmModalProps) => (
  <Modal
    open={openConfirmModal}
    size="small"
    closeOnDimmerClick={false}
    trigger={
      <Button
        key={typeDict.key}
        fluid
        compact
        basic={order.type !== typeDict.value}
        disabled={summary}
        style={{ marginBottom: 10, textAlign: 'left' }}
        onClick={() => {
          updateOrder('type', typeDict.value);
        }}
      >
        {typeDict.text}
      </Button>
    }
  >
    <Header icon="warning" content="Zmiana typu zamówienia wyczyści listę części. Kontynuować?" />

    <Modal.Actions>
      <Button content="Anuluj" onClick={() => discardChangeOrder()} />
      <Button primary content="Kontynuuj" onClick={() => confirmChangeOrder(currentOrder)} />
    </Modal.Actions>
  </Modal>
);

export default ConfirmModal;
