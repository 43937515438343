import { connect } from 'react-redux'
import { Button, Header, Modal, Table, Grid, Container } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { selectMM } from '~/store/invoices/actions'
import { ICollectiveMM } from '~/store/invoices/types'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { parseDate } from '~/utils/dateUtils'

import React, { useState, useEffect } from 'react'
import DetailCard from '~/components/DetailCard/DetailCard'
import CommonLoader from '~/components/Loaders/CommonLoader'

type TReduxState = {
    mmItem?: ICollectiveMM,
    loading: boolean
}

type TReduxActions = {
    selectMM: typeof selectMM
}

type TProps = {
    noteId: string
}

type TInternalConsumptionNotesDetailsProps = TReduxState & TReduxActions & TProps

const MMDetails: React.FC<TInternalConsumptionNotesDetailsProps> = ({
    children, noteId, selectMM, mmItem, loading
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const { renderLabel } = useRenderingFunctions(mmItem, false)

    useEffect(() => {
        if (open) {
            selectMM(noteId)
        }
    }, [open, noteId, selectMM])

    const handleCancel = (): void => {
        setOpen(false)
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    const renderLines = (lines: any[]): JSX.Element => {
        return <Table basic>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Referencja</Table.HeaderCell>
                    <Table.HeaderCell>Ilość zlecona</Table.HeaderCell>
                    <Table.HeaderCell>Ilość wysłana</Table.HeaderCell>
                    <Table.HeaderCell>Ilość dostarczona</Table.HeaderCell>
                    <Table.HeaderCell>Ilość odrzucona</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {lines.length === 0 && <Table.Row>
                    <Table.Cell colSpan="14">
                        <Container fluid textAlign="center">
                            Brak elementów do wyświetlenia.
                        </Container>
                    </Table.Cell>
                </Table.Row>}
                {lines.map(line =>
                    <Table.Row key={line.partID}>
                        <Table.Cell>{line.partID}</Table.Cell>
                        <Table.Cell>{line.reserved}</Table.Cell>
                        <Table.Cell>{line.sent}</Table.Cell>
                        <Table.Cell>{line.delivered}</Table.Cell>
                        <Table.Cell>{line.cancelled}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    }

    return (
        <Modal
            size="large"
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}>
            <Header icon='briefcase' content={mmItem ? mmItem.number : 'Wczytywanie...'} />
            <Modal.Content>
                <Grid style={{ marginBottom: 'unset' }} stretched>
                    <DetailCard title="app.details" id="details" key="details" width={12} smallWidth={12} minHeight={100}>
                        {mmItem && renderLabel("Numer", mmItem.number)}
                        {mmItem && renderLabel("Data wystawiania", mmItem.issueDate ? parseDate(mmItem.issueDate) : null)}
                        {mmItem && renderLabel("Data wysłania", mmItem.dispatchDate ? parseDate(mmItem.dispatchDate) : null)}
                        {mmItem && renderLabel("Data dostarczenia", mmItem.deliveryDate ? parseDate(mmItem.deliveryDate) : null)}
                        {mmItem && renderLabel("Status", mmItem.status)}
                        {mmItem && renderLabel("Magazyn dostawczy", mmItem.supplierName ? `${mmItem.supplierName} ${mmItem.supplierID}` : '-')}
                        {mmItem && renderLabel("Magazyn odbiorczy", mmItem.recipientName ? `${mmItem.recipientName} ${mmItem.recipientID}` : '-')}
                    </DetailCard>
                    <DetailCard title="app.moved.parts" id="movedParts" key="movedParts" width={16} smallWidth={16} minHeight={100}>
                        {mmItem && renderLines(mmItem.parts || [])}
                    </DetailCard>
                </Grid >
                <CommonLoader loading={loading}/>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Zamknij" onClick={handleCancel} />
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ invoices }: ApplicationState) => ({
    mmItem: invoices.mmDataResponse.selected,
    loading: invoices.mmDataResponse.loading
})

const mapDispatchToProps: TReduxActions = { selectMM }

export default connect(mapStateToProps, mapDispatchToProps)(MMDetails)