import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Divider, Dropdown, Form, Message, Table } from 'semantic-ui-react';
import DetailCard from '~/components/DetailCard/DetailCard';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import {
  addLimitDistrigo,
  changeFactorPaymentDue,
  changeFactorPriority,
  createFactor,
  fetchFactors,
  removeFactor,
} from '~/store/factors/actions';
import { HandleChange } from '~/store/warehouses-in-calendar/types';
import { parsePrice } from '~/utils/parsePrice';
import { DictionariesState, DictionaryItem } from '~/store/dictionaries/types';
import {
  CustomerPaymentFactor,
  CustomerPaymentFactorDistrigoLimit,
  CustomerPaymentFactorDistrigoLimitDTO,
  CustomerPaymentFactorDTO,
} from '~/store/factors/types';
import { ApplicationState } from '~/store';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { isDatePast, parseDate } from '~/utils/dateUtils';
import HistoryModal from '~/pages/Customers/Details/lib/HistoryModal';
import CommonLoader from '~/components/Loaders/CommonLoader';
import { TextAreaProps } from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import { ButtonProps } from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import moment from 'moment';
import OrdersModal from './OrdersModal';

type FactorProps = {
  index: number;
  editMode: boolean;
  handleRemove: (index: number) => void;
  factor: CustomerPaymentFactor;
  customerId: string;
  handleAdd: () => void;
  isNewFactor: boolean;
  downloadXLSPending: boolean;
};

type TReduxState = {
  paymentDueChanged: boolean;
  loading: boolean;
  dictionaries: DictionariesState;
  creatingLimit: boolean;
  factors: CustomerPaymentFactor[];
  creating: boolean;
  createdFactorId: string | null;
  limitCreated: boolean;
  priorityChanged: boolean;
};

type TReduxActions = {
  fetchFactors: typeof fetchFactors;
  createFactor: typeof createFactor;
  addLimitDistrigo: typeof addLimitDistrigo;
  removeFactor: typeof removeFactor;
  changeFactorPriority: typeof changeFactorPriority;
  changeFactorPaymentDue: typeof changeFactorPaymentDue;
};

type Props = FactorProps & TReduxActions & TReduxState;

const isFactorFormValid = (factor: CustomerPaymentFactorDTO) => {
  return (
    factor.paymentFactorId !== '' &&
    factor.paymentDueId !== '' &&
    factor.priority &&
    factor.paymentFactorId &&
    factor.paymentDueId &&
    factor.priority
  );
};

const distrigoLimitInitialState: CustomerPaymentFactorDistrigoLimitDTO = {
  amount: 0,
  creationComment: '',
  validUntil: '',
};

export type TFactorLimitInfo = {
  factorLimitAgreementAmount: number;
  factorUnpaidInvoicesAmount: number;
  assignedFactor: number;
  notBoughtFactor: number;
  assignedDistrigo: number;
  assignedSum: number;
  orders: number;
  invoices: number;
  available: number;
};

const Factor = ({
  index,
  dictionaries,
  editMode = true,
  handleRemove,
  customerId,
  handleAdd,
  createFactor,
  loading,
  factor,
  factors,
  addLimitDistrigo,
  creatingLimit,
  fetchFactors,
  isNewFactor,
  creating,
  createdFactorId,
  removeFactor,
  limitCreated,
  changeFactorPriority,
  priorityChanged,
  changeFactorPaymentDue,
  paymentDueChanged,
  downloadXLSPending,
}: Props) => {
  const [distrigoLimitDTO, setDistrigoLimitDTO] =
    useState<CustomerPaymentFactorDistrigoLimitDTO>(distrigoLimitInitialState);
  const [customerPaymentFactor, setCustomerPaymentFactor] = useState<CustomerPaymentFactorDTO>({
    paymentFactorId: factor.paymentFactorId,
    priority: factor.priority,
    paymentDueId: factor.paymentDueId,
  });
  const [factorLimitInfo, setFactorLimitInfo] = useState<TFactorLimitInfo>({
    assignedFactor: 0,
    factorUnpaidInvoicesAmount: 0,
    assignedDistrigo: 0,
    available: 0,
    orders: 0,
    invoices: 0,
    assignedSum: 0,
    factorLimitAgreementAmount: 0,
    notBoughtFactor: 0,
  });
  const [currentFactor, setCurrentFactor] = useState<CustomerPaymentFactor>(factor);
  const [factorNames, setFactorNames] = useState<DictionaryItem[]>([]);
  const [agreementNumbers, setAgreementNumbers] = useState<DictionaryItem[]>();
  const [name, setName] = useState('');
  const [priorityList, setPriorityList] = useState([
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    {
      text: 3,
      value: 3,
    },
  ]);

  const orZero = (input?: number): number => (input !== undefined && input !== null ? input : 0);

  useEffect(() => {
    if (currentFactor) {
      setFactorLimitInfo({
        factorLimitAgreementAmount: orZero(currentFactor.factorLimitAgreementAmount),
        factorUnpaidInvoicesAmount: orZero(currentFactor.factorUnpaidInvoicesAmount),
        assignedFactor: orZero(currentFactor.factorLimitAmount),
        notBoughtFactor: orZero(currentFactor.factorNotBoughtInvoicesAmount),
        assignedDistrigo: orZero(currentFactor.currentDistrigoLimit?.amount),
        assignedSum: orZero(currentFactor.currentLimitAssigned),
        orders: orZero(currentFactor.ordersAmount),
        invoices: orZero(currentFactor.invoicesAmount),
        available: orZero(currentFactor.currentLimitTotal),
      });
    }
  }, [currentFactor]);

  useEffect(() => {
    const uniqueNames = [...new Set(dictionaries['payment-factor'].map((item) => item.text))];
    setFactorNames(
      uniqueNames.map((item) => {
        return {
          key: item,
          value: item,
          text: item,
        };
      })
    );
  }, [customerId, dictionaries]);

  useEffect(() => {
    if (customerId) {
      fetchFactors(customerId);
    }
  }, [createdFactorId, limitCreated, removeFactor, priorityChanged, fetchFactors, customerId]);

  useEffect(() => {
    const currFactor = factors.filter((item) => item.paymentFactorId === factor.paymentFactorId)[0];
    if (currFactor) {
      setCurrentFactor(currFactor);
      setCustomerPaymentFactor((prevState) => {
        return {
          ...prevState,
          priority: currFactor.priority,
        };
      });
    }
  }, [factors, factor, addLimitDistrigo]);

  useEffect(() => {
    if (currentFactor?.paymentFactorId) {
      const name: string = dictionaries['payment-factor'].filter((item) => item.key === factor.paymentFactorId)[0]
        ?.text;
      setName(name);
    }

    if (factors && isNewFactor) {
      const aggreementNumsInUsage: string[] = factors.map((factor) => factor.paymentFactorId);
      setAgreementNumbers(
        dictionaries['payment-factor']
          .filter((item) => aggreementNumsInUsage.indexOf(item.key) === -1)
          .filter((item) => item.text === name)
          .map((item) => {
            return {
              ...item,
              text: item.agreementNo,
            };
          }) as DictionaryItem[]
      );

      const prioritiesInUsage = factors.map((item) => item.priority);
      setPriorityList((prevState) => prevState.filter((item) => prioritiesInUsage.indexOf(item.value) === -1));
    } else {
      setAgreementNumbers(
        dictionaries['payment-factor']
          .filter((item) => item.text === name)
          .map((item) => {
            return {
              ...item,
              text: item.agreementNo,
            };
          }) as DictionaryItem[]
      );
    }
  }, [
    dictionaries,
    customerId,
    currentFactor,
    creating,
    factors,
    removeFactor,
    name,
    isNewFactor,
    factor.paymentFactorId,
  ]);

  const handleAddFactor = () => {
    createFactor(customerId, {
      priority: customerPaymentFactor.priority,
      paymentFactorId: customerPaymentFactor.paymentFactorId,
      paymentDueId: customerPaymentFactor.paymentDueId,
    });
  };

  const clearLimitForm = () => {
    setDistrigoLimitDTO(distrigoLimitInitialState);
  };

  const isLimitFormValid = () => {
    return (
      distrigoLimitDTO.amount >= 0 &&
      distrigoLimitDTO.validUntil &&
      distrigoLimitDTO.validUntil !== '' &&
      distrigoLimitDTO.creationComment !== ''
    );
  };

  useEffect(() => {
    if (limitCreated) {
      clearLimitForm();
    }
  }, [limitCreated]);

  const handleName = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setName(data.value as string);
    const aggreementNumsInUsage: string[] = factors.map((factor) => factor.paymentFactorId);
    const agreementNumbersByName = dictionaries['payment-factor']
      .filter((item) => aggreementNumsInUsage.indexOf(item.key) === -1)
      .filter((item) => item.text === data.value)
      .map((item) => {
        return {
          ...item,
          text: item.agreementNo,
        };
      }) as DictionaryItem[];
    setAgreementNumbers(agreementNumbersByName);
  };

  const handlePaymentDue = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (customerPaymentFactor.paymentDueId && !isNewFactor && data.value !== customerPaymentFactor.paymentDueId) {
      changeFactorPaymentDue(customerId, customerPaymentFactor.paymentFactorId, data.value as number);
    }

    setCustomerPaymentFactor((prevState) => {
      return {
        ...prevState,
        paymentDueId: data.value as string,
      };
    });
  };

  const handleAgreementNo = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setCustomerPaymentFactor((prevState) => {
      return {
        ...prevState,
        paymentFactorId: data.value as string,
      };
    });
  };

  const handlePriority = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (customerPaymentFactor.priority && !isNewFactor && data.value !== customerPaymentFactor.priority) {
      changeFactorPriority(customerId, customerPaymentFactor.paymentFactorId, data.value as number);
    }
    setCustomerPaymentFactor((prevState) => {
      return {
        ...prevState,
        priority: data.value as number,
      };
    });
  };

  const handleChange = (e: SyntheticEvent<Element, Event> | undefined, { value }: HandleChange) => {
    setDistrigoLimitDTO((prevState) => {
      return {
        ...prevState,
        validUntil: value,
      };
    });
  };

  const handleComment = (event: React.FormEvent<HTMLTextAreaElement>, { value }: TextAreaProps): void => {
    setDistrigoLimitDTO((prevState) => {
      return {
        ...prevState,
        creationComment: value as string,
      };
    });
  };

  const handleSetAmount = (event: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData): void => {
    setDistrigoLimitDTO((prevState) => {
      return {
        ...prevState,
        amount: isNaN(Number.parseFloat(value)) ? 0 : Number.parseFloat(value),
      };
    });
  };

  const handleAddLimit = (): void => {
    addLimitDistrigo(customerId, customerPaymentFactor.paymentFactorId, {
      ...distrigoLimitDTO,
      validUntil: moment(distrigoLimitDTO.validUntil).format('YYYY-MM-DDT23:59:59'),
    });
  };

  useEffect(() => {
    setCustomerPaymentFactor({
      paymentFactorId: factor.paymentFactorId,
      priority: factor.priority,
      paymentDueId: factor.paymentDueId,
    });
  }, [factor]);

  const handleRemoveFactor = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    handleRemove(index);
    if (!isNewFactor) {
      const id: string = currentFactor.paymentFactorId;
      removeFactor(customerId, id);
    }
  };

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const getPrice = (value: number | undefined | null) => {
    return value ? parsePrice(value, true) : parsePrice(0, true);
  };

  const renderLines = (factorLimits: CustomerPaymentFactorDistrigoLimit[] | undefined): JSX.Element => {
    return (
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kwota</Table.HeaderCell>
            <Table.HeaderCell>Obowiązuje do</Table.HeaderCell>
            <Table.HeaderCell>Data dodania</Table.HeaderCell>
            <Table.HeaderCell>Komentarz</Table.HeaderCell>
            <Table.HeaderCell>Użytkownik</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {factorLimits
            ?.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())
            .map((line, index) => (
              <Table.Row key={index}>
                <Table.Cell>{parsePrice(line.amount)}</Table.Cell>
                <Table.Cell>{parseDate(line.validUntil)}</Table.Cell>
                <Table.Cell>{parseDate(line.creationTime)}</Table.Cell>
                <Table.Cell>{line.creationComment === '' ? '-' : line.creationComment}</Table.Cell>
                <Table.Cell>{line.authorUsername}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <>
      <div className="ui stretched grid">
        <DetailCard title="app.customer.factor" id="factor" key="factor" width={5.3}>
          <Form.Field required>
            <label>{formatMessage('app.customer.factoring.name')}</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              value={name}
              onChange={handleName}
              options={factorNames}
              disabled={!editMode || !isNewFactor}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field required>
            <label>{formatMessage('app.customer.factoring.agreementNo')}</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              disabled={!editMode || !isNewFactor || agreementNumbers?.length === 0}
              value={customerPaymentFactor!.paymentFactorId}
              onChange={handleAgreementNo}
              options={agreementNumbers}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field required>
            <label>{formatMessage('app.customer.factoring.priorytet')}</label>
            <Dropdown
              placeholder="Wybierz kategorię"
              fluid
              selection
              disabled={!editMode}
              value={customerPaymentFactor!.priority}
              onChange={handlePriority}
              options={priorityList}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          <Form.Field required>
            <label>{formatMessage('app.customer.factoring.paymentPeriod')}</label>
            <Dropdown
              placeholder="Wybierz okres płatności"
              fluid
              selection
              disabled={!editMode}
              value={customerPaymentFactor!.paymentDueId}
              onChange={handlePaymentDue}
              options={dictionaries['payment-due']}
              noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
            />
          </Form.Field>
          {isNewFactor ||
          !factors.some((factor) => factor.paymentFactorId === customerPaymentFactor.paymentFactorId) ? (
            <Button
              icon="add"
              size="medium"
              circular
              basic
              style={{ marginTop: '20px' }}
              className="primary"
              disabled={!editMode || !isFactorFormValid(customerPaymentFactor)}
              content="Dodaj faktor"
              onClick={handleAddFactor}
            />
          ) : null}
          <CommonLoader loading={loading || paymentDueChanged} />
        </DetailCard>

        <DetailCard title="app.customer.limit" id="limit" key="limit" width={5.3}>
          <CommonLoader loading={creatingLimit || loading} />

          <SmartLabel
            label={formatMessage('app.customer.factoring.factorLimitAgreementAmount')}
            value={getPrice(factorLimitInfo.factorLimitAgreementAmount)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.factoring.invoiceNotPaid')}
            value={getPrice(currentFactor?.factorUnpaidInvoicesAmount)}
            align="left"
          />

          <SmartLabel
            label={formatMessage('app.customer.factoring.assignedFaktor')}
            value={getPrice(factorLimitInfo.assignedFactor)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.factoring.invoiceNotBought')}
            value={getPrice(factorLimitInfo.notBoughtFactor)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.factoring.assignedDistrigo')}
            value={getPrice(factorLimitInfo.assignedDistrigo)}
            align="left"
          />
          <HistoryModal
            loading={loading}
            triggerButton={
              <Button style={{ position: 'absolute', right: 0, top: 0 }} color="orange" content="Historia" />
            }
          >
            {currentFactor.distrigoLimits?.length !== 0 && currentFactor.distrigoLimits ? (
              <div
                style={{
                  maxHeight: '300px',
                  width: '100%',
                  overflowY: 'scroll',
                }}
              >
                {renderLines(currentFactor?.distrigoLimits || [])}
              </div>
            ) : (
              <Message style={{ width: '100%' }}>
                <Container fluid textAlign="center">
                  Brak elementów do wyświetlenia.
                </Container>
              </Message>
            )}
          </HistoryModal>
          <OrdersModal
            deliveryCustomerRRID={customerId}
            factorId={factor.paymentFactorId}
            downloadXLSPending={downloadXLSPending}
          ></OrdersModal>
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
          <SmartLabel
            label={formatMessage('app.customer.factoring.assignedSum')}
            value={getPrice(factorLimitInfo.assignedSum)}
            align="left"
          />
          <p style={{ marginTop: '40px' }}></p>

          <SmartLabel
            label={formatMessage('app.customer.factoring.ordered')}
            value={getPrice(factorLimitInfo.orders)}
            align="left"
          />
          <SmartLabel
            label={formatMessage('app.customer.factoring.invoiced')}
            value={getPrice(factorLimitInfo.invoices)}
            align="left"
          />

          <Divider />
          <SmartLabel
            label={formatMessage('app.customer.factoring.available')}
            value={getPrice(factorLimitInfo.available)}
            align="left"
          />
        </DetailCard>

        {currentFactor ? (
          <DetailCard title="app.addDistigoLimit" id="addDistigoLimit" key="addDistigoLimit" width={5.3}>
            <Form.Field required>
              <label>{formatMessage('app.customer.loan.amountLimit')}</label>
              <Form.Input
                fluid
                className="super-field"
                value={distrigoLimitDTO.amount}
                onChange={handleSetAmount}
                disabled={!editMode || isNewFactor}
                required
              />
            </Form.Field>
            <SemanticDatepicker
              label={formatMessage('app.customer.loan.availableTo')}
              id="finalDate"
              filterDate={isDatePast}
              name="dateTo"
              value={distrigoLimitDTO.validUntil}
              placeholder="RRRR-MM-DD"
              locale="pl-PL"
              onChange={handleChange}
              disabled={!editMode || isNewFactor}
              autoComplete="off"
              required
            />{' '}
            <br />
            <Form.Field required>
              <label>{formatMessage('app.customer.loan.reasonForGrantingTheLimit')}</label>
              <Form.TextArea
                required
                disabled={!editMode || isNewFactor}
                readOnly={false}
                rows={6}
                value={distrigoLimitDTO.creationComment}
                onChange={handleComment}
              />
            </Form.Field>
            <div>
              <Button
                icon="add"
                size="medium"
                circular
                basic
                style={{ marginTop: '20px' }}
                className="primary"
                disabled={!editMode || !isLimitFormValid() || isNewFactor}
                content="Dodaj"
                onClick={handleAddLimit}
              />
            </div>
            <CommonLoader loading={creatingLimit} />
          </DetailCard>
        ) : null}

        <div className="button-container">
          <Divider style={{ display: editMode ? 'block' : 'none' }} />
          {factors.length === 3 ? null : (
            <Button
              icon="add"
              size="medium"
              circular
              basic
              style={{
                display: editMode && factors.length === index + 1 ? 'inline-block' : 'none',
              }}
              content="Dodaj Factora"
              onClick={handleAdd}
            />
          )}
          <Button
            icon="remove"
            size="medium"
            circular
            basic
            style={{ display: editMode ? 'inline-block' : 'none' }}
            content="Usuń Factora"
            onClick={handleRemoveFactor}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ factor, dictionaries }: ApplicationState) => {
  return {
    paymentDueChanged: factor.paymentDueChanged,
    loading: factor.loading || factor.creating,
    creatingLimit: factor.creatingLimit,
    factors: factor.customerPaymentFactors,
    creating: factor.creating,
    createdFactorId: factor.createdFactorId,
    limitCreated: factor.limitCreated,
    priorityChanged: factor.priorityChanged,
    downloadXLSPending: factor.downloadXLSPending,
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchFactors,
  createFactor,
  addLimitDistrigo,
  removeFactor,
  changeFactorPriority,
  changeFactorPaymentDue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Factor);
