import { createBrowserHistory } from 'history'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import Main from './main'
import configureStore from './configureStore'

import 'semantic-ui-less/semantic.less'

fetch('/config.json')
	.then(response => response.json())
	.then(config => {
		window.config = config
		const history = createBrowserHistory()
		const initialState = window.initialReduxState
		const store = configureStore(history, initialState)

		ReactDOM.render(<Main store={store} history={history} />, document.getElementById('root'))
		serviceWorker.unregister()
})