import { Form } from "semantic-ui-react";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { TDiscountType, TPromotion } from "~/store/promotions/types";

import React from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import dictionariesStatic from "~/store/dictionaries/static";
import MinimumCondition from "./lib/Minimum";

type TProps = {
  promotion: TPromotion;
  editMode: boolean;
};

const ActualPromotion: React.FC<TProps> = ({ promotion, editMode }) => {
  const { renderField, renderSelect } = useRenderingFunctions(
    promotion as any,
    editMode
  );

  const saveMapper = (v: string) => (Number(v) <= 0 ? null : Number(v));

  const isFirstDiscountType = (types: TDiscountType[]) =>
    promotion.firstDiscount &&
    types.indexOf(promotion.firstDiscount.type) !== -1;
  const isFirstDiscountTypeSet =
    promotion.firstDiscount && promotion.firstDiscount.type;
  const isSecondDiscountType = (types: TDiscountType[]) =>
    promotion.secondDiscount &&
    types.indexOf(promotion.secondDiscount.type) !== -1;
  const isSecondDiscountTypeSet =
    promotion.secondDiscount && promotion.secondDiscount.type;

  return (
    <>
      <DetailCard
        id="threshold-1"
        title="app.promo.firstThreshold"
        width={5.33}
        minHeight={0}
      >
        {renderSelect(
          "firstDiscount.Type",
          dictionariesStatic.PROMO_DISCOUNT_TYPE,
          { label: "Rodzaj upustu", validators: [REQUIRED] }
        )}
        {isFirstDiscountType(["PERCENTAGE", "PERCENTAGE_AND_PRICING"]) &&
          renderField("firstDiscount.percentageDiscount", {
            label: "Upust procentowy (%)",
            type: "number",
            saveMapper,
            min: 0,
            precision: 2,
            validators: [REQUIRED],
          })}
        {isFirstDiscountType(["PRICING", "PERCENTAGE_AND_PRICING"]) && (
          <Form.Group widths="equal">
            {renderField("firstDiscount.amountDiscount", {
              label: "Upust kwotowy (zł)",
              type: "number",
              saveMapper,
              min: 0,
              precision: 2,
              validators: [REQUIRED],
            })}
            {renderField("firstDiscount.multiple", {
              label: "Co ile produktow?",
              type: "number",
              saveMapper,
              min: 0,
              validators: [REQUIRED],
              description:
                "Liczba produktów dla której wielokrotności naliczany będzie upust kwotowy.",
            })}
          </Form.Group>
        )}
        {isFirstDiscountTypeSet && (
          <MinimumCondition
            promotion={promotion}
            editMode={editMode}
            discountGroup="firstDiscount"
          />
        )}
      </DetailCard>
      <DetailCard
        id="threshold-2"
        title="app.promo.secondThreshold"
        width={5.33}
        minHeight={0}
      >
        {renderSelect(
          "secondDiscount.Type",
          dictionariesStatic.PROMO_DISCOUNT_TYPE,
          { label: "Rodzaj upustu" }
        )}
        {isSecondDiscountType(["PERCENTAGE", "PERCENTAGE_AND_PRICING"]) &&
          renderField("secondDiscount.percentageDiscount", {
            label: "Upust procentowy (%)",
            type: "number",
            saveMapper,
            min: 0,
            precision: 2,
          })}
        {isSecondDiscountType(["PRICING", "PERCENTAGE_AND_PRICING"]) && (
          <Form.Group widths="equal">
            {renderField("secondDiscount.amountDiscount", {
              label: "Upust kwotowy (zł)",
              type: "number",
              saveMapper,
              min: 0,
              precision: 2,
            })}
            {renderField("secondDiscount.multiple", {
              label: "Co ile produktow?",
              type: "number",
              saveMapper,
              min: 0,
              description:
                "Liczba produktów dla której wielokrotności naliczany będzie upust kwotowy.",
            })}
          </Form.Group>
        )}
        {isSecondDiscountTypeSet && (
          <MinimumCondition
            promotion={promotion}
            editMode={editMode}
            discountGroup="secondDiscount"
          />
        )}
      </DetailCard>
    </>
  );
};

export default ActualPromotion;
