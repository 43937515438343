import { Checkbox, List } from "semantic-ui-react"
import { TNodeProps } from "./lib/types"

import React from 'react'
import { showLoading } from "~/components/Tree/lib/utils";
import { useTreeNode } from "~/components/Tree/hooks/useTreeNode";

const TreeNode: React.FC<TNodeProps> = (props) => {

    const { node, editable = true, advancedSearchResponse } = props

    const {
        expanded,
        loaded,
        loading,
        handleExpandCollapse,
        checked,
        handleSelectionChange,
        renderPaginatedList,
        indeterminate,
        children
    } = useTreeNode(props);

    if (advancedSearchResponse && advancedSearchResponse.length) {
        const { type, value, segments } = advancedSearchResponse[0];

        if (type === node.group && value.toLowerCase() !== node.id.toLowerCase()) return null
        if (segments[0].slots) {
            if (segments[0].type === node.group && segments[0].value.toLowerCase() !== node.id.toLowerCase()) return null
            const { type, value, families } = advancedSearchResponse[0].segments[0].slots[0]

            if (families) {
                if (type === node.group && value.toLowerCase() !== node.id.toLowerCase()) return null
                if (families[0].products) {
                    if (families[0].type === node.group && families[0].value.toLowerCase() !== node.id.toLowerCase()) return null
                    if (families[0].products.length === 1)
                        if (families[0].products[0].value.toLowerCase() !== node.id.toLowerCase() && node.group === 'REFERENCE') return null
                }
            }
        }
    }

    if (node.leaf) {
        return (
            <List.Item key={node.id}>
                <List.Icon name='tag' color="grey" />
                <List.Content>
                    <List.Header>
                        <Checkbox disabled={!editable} label={`[${node.id}] ${node.label}`} checked={checked}
                            onChange={handleSelectionChange} />
                    </List.Header>
                </List.Content>
            </List.Item>
        )
    } else {
        return (
            <List.Item key={node.id}>
                <List.Icon
                    name={expanded ? 'folder open' : 'folder'}
                    color="orange"
                    style={{ cursor: 'pointer', minWidth: 22 }}
                    onClick={handleExpandCollapse} />
                <List.Content>
                    <List.Header>
                        <Checkbox disabled={!editable} label={`[${node.id}] ${node.label}`} checked={checked}
                            onChange={handleSelectionChange} indeterminate={indeterminate} />
                    </List.Header>
                    <List style={{ display: expanded ? 'block' : 'none' }}>
                        {(children && children.length > 0)
                            ?
                            renderPaginatedList(children, advancedSearchResponse)
                            :
                            showLoading(loading, loaded)
                        }
                    </List>
                </List.Content>
            </List.Item>
        )
    }
}

export default TreeNode
