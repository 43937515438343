import { TColumn } from "~/components/Table/lib/types";
import { SparePartCP } from "~/store/spare-parts/types";
import { exportFile } from "~/utils/exportFile";

type Props = {
  columns: TColumn[];
  spareParts: SparePartCP[];
};

export const useHandleDownloadClientPanel = ({
  columns,
  spareParts,
}: Props) => {
  const handleOpenDownloadModal = async (): Promise<void> => {
    exportFile("Produkty", spareParts as any[], columns);
  };

  return { handleOpenDownloadModal };
};
