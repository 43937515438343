import { User } from "~/store/users/types";
import { TConfirmType } from "~/pages/Administration/constants";
import { enableUser, fetchUsers, removeUser } from "~/store/users/actions";
import { TColumn } from "~/components/Table/lib/types";
import { PaginationMeta, TotalRecords } from "~/store/types";

export interface PropsFromState {
  loading: boolean;
}

export interface PropsFromDispatch {
  enableUser: typeof enableUser;
  removeUser: typeof removeUser;
}

export type UserTableWrapperT = {
  columns: TColumn[];
  setColumns: React.Dispatch<React.SetStateAction<TColumn[]>>;
  confirmType: TConfirmType;
  actionId: string;
  actionUserName: string;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmType: React.Dispatch<React.SetStateAction<TConfirmType>>;
  setActrionId: React.Dispatch<React.SetStateAction<string>>;
  setActionUserName: React.Dispatch<React.SetStateAction<string>>;
  fetchUsers: typeof fetchUsers;
};

export type UserTableWrapperProps = UserTableWrapperT &
  PropsFromDispatch &
  PropsFromState;

export enum ACTION_TYPE_ENUM {
  ACTIVATE_USER = "ACTIVATE_USER",
  DEACTIVATE_USER = "DEACTIVATE_USER",
  REMOVE_USER = "REMOVE_USER",
}

export interface UserTablePropsFromState {
  users: User[];
  meta: PaginationMeta;
  totalRecords: TotalRecords;
}

export type UserTableProps = UserTableWrapperT & UserTablePropsFromState;
