import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Divider } from "semantic-ui-react";
import { itemStyle } from "../constants";
import MenuTitle from "../MenuTitle";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

const useClientPanelButtons = () => {
  const { isLocalAdmin, isSuperAdmin, hasRole } = useClientPanelRoles();
  return (
    <>
      <Button
        style={itemStyle}
        as={NavLink}
        to="/dashboard"
        icon="home"
        content={<MenuTitle id="app.home-page" />}
      />
      <Divider />
      {hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ORDER_VIEW) && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/orders"
          icon="shop"
          content={<MenuTitle id="app.orders" />}
        />
      )}
      {/* temporary false - cancel requests in customer panel not implemented */}
      {false && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/cancel-requests"
          icon="window close outline"
          content={<MenuTitle id="app.cancel-requests" />}
        />
      )}
      {hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_ACCOUNTING_VIEW) && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/accounting"
          icon="briefcase"
          content={<MenuTitle id="app.accounting" />}
        />
      )}
      {/* temporary false - claims in customer panel not implemented */}
      {false && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/claims"
          icon="exclamation triangle"
          content={<MenuTitle id="app.claims" />}
        />
      )}
      {/* temporary false - results in customer panel not implemented */}
      {false && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/results"
          icon="chart line"
          content={<MenuTitle id="app.results" />}
        />
      )}
      {hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_PRODUCT_VIEW) && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/products"
          icon="tag"
          content={<MenuTitle id="app.products" />}
        />
      )}
      {/* temporary false - promotions in customer panel not implemented */}
      {false && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/promotions"
          icon="star"
          content={<MenuTitle id="app.promotions" />}
        />
      )}
      {hasRole(AVAILABLE_ROLE_ENUM.CUSTOMER_PANEL_UI_LIBRARY_VIEW) && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/library"
          icon="book"
          content={<MenuTitle id="app.library" />}
        />
      )}
      {true && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/contact"
          icon="phone"
          content={<MenuTitle id="app.contact" />}
        />
      )}
      {(isLocalAdmin || isSuperAdmin) && (
        <Button
          style={itemStyle}
          as={NavLink}
          to="/administration"
          icon="cogs"
          content={<MenuTitle id="app.administration" />}
        />
      )}
    </>
  );
};

export default useClientPanelButtons;
