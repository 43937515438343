import { Reducer } from "redux";
import {
  Announcement,
  AnnouncementsActionTypes,
  AnnouncementsState,
} from "./types";

export const initialAnnouncementsState: AnnouncementsState = {
  announcements: [],
  announcementList: [],
  announcementDetails: undefined,
  loadingAnnouncements: false,
  loadingAnnouncementList: false,
  loadingAnnouncementsError: false,
  loadingAnnouncementDetails: false,
  loadingAnnouncementAdd: false,
  removeLoading: false,
  removeSuccess: false,
  editSuccess: false,
  editLoading: false,
  created: "",
  meta: {
    page: 1,
    pages: 1,
    size: 15,
    total: 0,
  },
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  selectedAnnouncement: undefined,
};

const reducer: Reducer<AnnouncementsState> = (
  state = initialAnnouncementsState,
  action
): AnnouncementsState => {
  switch (action.type) {
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS: {
      return {
        ...state,
        loadingAnnouncements: true,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS_SUCCESS: {
      const payload = action.payload as Announcement[];
      return {
        ...state,
        loadingAnnouncements: false,
        announcements: payload,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENTS_ERROR: {
      return {
        ...state,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST: {
      return {
        ...state,
        loadingAnnouncementList: true,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST_ERROR: {
      return {
        ...state,
        loadingAnnouncementList: false,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_LIST_SUCCESS: {
      const {
        content,
        totalElements,
        number,
        totalPages,
        size,
        filteredElements,
      } = action.payload;
      return {
        ...state,
        loadingAnnouncementList: false,
        announcementList: content,
        totalRecords: {
          filtered: filteredElements,
          total: totalElements,
        },
        meta: {
          page: number + 1,
          pages: totalPages,
          total: totalElements,
          size: size,
        },
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_DETAILS: {
      return {
        ...state,
        loadingAnnouncementDetails: true,
        announcementDetails: undefined,
      };
    }
    case AnnouncementsActionTypes.FETCH_ANNOUNCEMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loadingAnnouncementDetails: false,
        announcementDetails: action.payload,
      };
    }
    case AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT_START: {
      return {
        ...state,
        created: undefined,
      };
    }
    case AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT: {
      return {
        ...state,
        loadingAnnouncementAdd: true,
      };
    }
    case AnnouncementsActionTypes.UPLOAD_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        loadingAnnouncementAdd: false,
        created: action.payload,
      };
    }
    case AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT_REFRESH: {
      return {
        ...state,
        removeSuccess: false,
      };
    }
    case AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT: {
      return {
        ...state,
        removeLoading: true,
      };
    }
    case AnnouncementsActionTypes.REMOVE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        removeSuccess: true,
        removeLoading: false,
      };
    }
    case AnnouncementsActionTypes.HIDE_ANNOUNCEMENT: {
      return {
        ...state,
        removeLoading: true,
      };
    }
    case AnnouncementsActionTypes.SHOW_ANNOUNCEMENT: {
      return {
        ...state,
        removeLoading: true,
      };
    }
    case AnnouncementsActionTypes.EDIT_ANNOUNCEMENT_REFRESH: {
      return {
        ...state,
        editSuccess: false,
      };
    }
    case AnnouncementsActionTypes.EDIT_ANNOUNCEMENT: {
      return {
        ...state,
        editLoading: true,
      };
    }
    case AnnouncementsActionTypes.EDIT_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        editSuccess: true,
        editLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as announcementsReducer };
