import {Table} from "semantic-ui-react"
import { PaginationMeta, TotalRecords } from "~/store/types"

import React from 'react'
import PaginationBar from "../PaginationBar/PaginationBar"

export type TProps = {
    meta: PaginationMeta
    totalRecords?: TotalRecords
    pageSizeVisible?: boolean
    onPageChange: (page: number) => void
    onPageSizeChange: (size: number) => void
    showInfo?: boolean
}

const TablePaginationFooter: React.FC<TProps> = ({ meta, totalRecords, pageSizeVisible, onPageChange, onPageSizeChange, showInfo }): JSX.Element => {
    return (
        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan='100'>
                    <PaginationBar meta={meta} totalRecords={totalRecords} pageSizeVisible={pageSizeVisible} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}  showInfo={showInfo}/>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    )
}

export default TablePaginationFooter
