import { Table, Icon } from "semantic-ui-react";
import { AVAILABLE_ROLE_ENUM, User } from "~/store/users/types";
import { parseDate } from "~/utils/dateUtils";

import TableCellPopup from "~/components/TableCellPopup/TableCellPopup";
import React from "react";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import UserTableDropdown from "~/pages/Administration/lib/UserTableDropdown";
import { AppContext } from "~/context/AuthContext";

interface Props {
  users: User[];
  columns: {
    name: keyof User;
    label: string;
    projection: boolean;
  }[];
  onActivateUser: (id: string, name: string) => void;
  onDeactivateUser: (id: string, name: string) => void;
  onRemoveUser: (id: string, name: string) => void;
}

class UsersList extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      JSON.stringify(nextProps.columns) !==
        JSON.stringify(this.props.columns) ||
      JSON.stringify(nextProps.users) !== JSON.stringify(this.props.users)
    );
  }

  private usersListDropdown(user: User): JSX.Element {
    const { onActivateUser, onDeactivateUser, onRemoveUser } = this.props;

    return (
      <UserTableDropdown
        onActivateUser={onActivateUser.bind(this)}
        onDeactivateUser={onDeactivateUser.bind(this)}
        onRemoveUser={onRemoveUser.bind(this)}
        user={user}
      />
    );
  }

  private static getEnabledCell(enabled: boolean): JSX.Element {
    return enabled ? (
      <Icon name="check" size="large" style={{ color: "green" }} />
    ) : (
      <Icon name="close" size="large" style={{ color: "red" }} />
    );
  }

  private getCell(user: User, name: keyof User): JSX.Element {
    switch (name) {
      case "enabled":
        return (
          <Table.Cell key={name}>
            {UsersList.getEnabledCell(user.enabled)}
          </Table.Cell>
        );
      case "createdTimestamp":
        return (
          <Table.Cell key={name}>{parseDate(user.createdTimestamp)}</Table.Cell>
        );
      default:
        return <TableCellPopup key={name}>{user[name]}</TableCellPopup>;
    }
  }

  render() {
    const { users, columns } = this.props;

    if (users.length === 0) {
      return <TableNoResults />;
    }

    const userHaveLdcUiAdministrationUsersRole =
      this.context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_USERS
      );

    return users.map((user) => (
      <Table.Row key={user.id}>
        {columns.map(
          (column) => column.projection && this.getCell(user, column.name)
        )}
        <Table.Cell className="col-dropdown-menu-sticky">
          {userHaveLdcUiAdministrationUsersRole && this.usersListDropdown(user)}
        </Table.Cell>
      </Table.Row>
    ));
  }
}

UsersList.contextType = AppContext;

export default UsersList;
