import { Button, Container, Grid, Header, Icon, Message, Modal, Popup, Table } from 'semantic-ui-react';
import React, { ReactElement, useState } from 'react';
import CommonLoader from '~/components/Loaders/CommonLoader';
import { parseDate } from '~/utils/dateUtils';
import { AdditionalDiscountHistory, Customer } from '~/store/customer/types';
import { downloadProductGroup } from '~/store/customer/actions';

type TProps = {
  triggerButton?: ReactElement;
  history: AdditionalDiscountHistory[] | undefined;
  downloadProductGroup: typeof downloadProductGroup;
  downloadProductGroupPending: boolean;
  customer: Customer | undefined;
};

const AdditionalDiscountCodeHistoryModal: React.FC<TProps> = ({
  triggerButton,
  history,
  downloadProductGroup,
  downloadProductGroupPending,
  customer,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleExportClick = (groupId: string) => {
    if (!customer) return;
    downloadProductGroup(customer.rrdi, groupId);
  };

  const showComment = (comment: string) => {
    if (!comment) {
      return '';
    } else {
      return (
        <div style={{ position: 'relative' }}>
          <Popup
            position="bottom center"
            content={<span>{comment}</span>}
            on="click"
            closeOnPortalMouseLeave={false}
            trigger={
              <Icon.Group size="large" style={{ cursor: 'pointer' }}>
                <Icon name="comment" fitted />
              </Icon.Group>
            }
          />
        </div>
      );
    }
  };

  return (
    <Modal
      size="small"
      trigger={
        triggerButton || <Button style={{ display: 'block', marginTop: '20px' }} color="orange" content="Historia" />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="briefcase" content="Historia" />
      <Modal.Content scrolling>
        <CommonLoader loading={downloadProductGroupPending} messageId="app.downloading" />
        <Grid style={{ marginBottom: 'unset' }} stretched>
          {history && history?.length !== 0 ? (
            <div style={{ maxHeight: '300px', width: '100%', overflowY: 'auto' }}>
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Data dodania</Table.HeaderCell>
                  <Table.HeaderCell>Grupa produktów</Table.HeaderCell>
                  <Table.HeaderCell>Wartość rabatu %</Table.HeaderCell>
                  <Table.HeaderCell>Komentarz</Table.HeaderCell>
                  <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {history.map((item) => (
                    <Table.Row key={Math.random()}>
                      <Table.Cell>{parseDate(item.creationTime)}</Table.Cell>
                      <Table.Cell>
                        <Button
                          primary
                          onClick={() => handleExportClick(item.id)}
                          disabled={downloadProductGroupPending}
                        >
                          Pobierz
                        </Button>
                      </Table.Cell>
                      <Table.Cell>{item.percentage}</Table.Cell>
                      <Table.Cell>{showComment(item.comment)}</Table.Cell>
                      <Table.Cell>{item.authorUsername}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <Message style={{ width: '100%' }}>
              <Container fluid textAlign="center">
                Brak elementów do wyświetlenia.
              </Container>
            </Message>
          )}
        </Grid>
        <CommonLoader loading={false} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

export default AdditionalDiscountCodeHistoryModal;
