import { PaginationMeta, TotalRecords, Paginable } from "~/store/types";

export type TCodeField = {
  required: boolean;
  value?: string;
};

export type TCodes = {
  vin?: TCodeField;
  cleCode?: TCodeField;
  transmitterCode?: TCodeField;
  wheelCode?: TCodeField;
  nreCode?: TCodeField;
  tiresCode?: TCodeField;
};

export type TInvoiceLine = {
  lineNumber: number;
  referenceId: string;
  quantity: number;
  taxRate: number;
  discount: number;
  lineUnitPriceNet: number;
  unitPriceNetBeforeDiscount: number;
  unitPriceNetAfterDiscount: number;
  orderID: string;
  serviceBoxOrderNo?: string;
  provisionId?: string;
  quantityOrgin?: number;
  orderedQuantity?: number;
  orderNumber: string;
  packingForceQuantity?: number;
  pvpPrice?: number;
  pnrPrice?: number;
  packageNumber?: string;
};

export type TInvoicePreviousLine = {
  [key: string]: number;
  lineNumber: number;
  quantity: number;
  discount: number;
  price: number;
};

export interface IInvoice extends ApiResponse {
  invoiceID: string;
  documentMask: string;
  invoiceNo: string;
  invoiceLines: TInvoiceLine[];
  invoiceDate: string;
  accountingCustomer: {
    id: string;
    taxCode: string;
  };
  orderCustomer: {
    id: string;
  };
  paymentMethod: string;
  netAmount: number;
  taxAmount: number;
  grossAmount: number;
  orderID: string;
  finalInvoice: {
    finalInvoiceLines: TInvoiceLine[];
  };
  erpInvoiceNumber?: string;
  erpInvoiceId?: string;
  invoiceNoToCorrect?: string;
  invoiceDocumentMaskToCorrect?: string;
  orderNumbers?: string[];
  isOpenChooseTypeOfExportDataModal?: boolean;
}

export interface IInvoiceLines extends ApiResponse {
  status: string;
  category: string;
  createTime: string;
  invoiceTime: string;
  deliveryCustomerId: string;
  deliveryType: string;
  paymentMethod: string;
  orderNumber: string;
  serviceBoxInternalOrderNumber: string;
  serviceBoxExternalOrderNumber: string;
  sapOrderNumber: string;
  referenceId: string;
  designation: string;
  quantity: number;
  vin: string;
  taxRate: string;
  unitPriceNetBeforeDiscount: string;
  unitPriceNetAfterDiscount: string;
  pnrPrice: string;
  promoCode: string;
  provisionId: string;
  invoiceId?: string;
  invoiceNumber?: string;
  invoiceDocumentMask?: string;
}

export interface IInvoiceSchedulers extends ApiResponse {
  stock?: string | undefined;
  turboPro?: string | undefined;
  urgent?: string | undefined;
  loading?: boolean;
}
export interface IInvoiceLinesSchedulers extends ApiResponse {
  stock?: string | undefined;
  turboPro?: string | undefined;
  urgent?: string | undefined;
  loading?: boolean;
}

export type TGoodsDispatchNotePart = {
  referenceId: string;
  quantity: number;
  codes: TCodes;
};

export interface IGoodsDispatchNote extends ApiResponse {
  goodsDispatchNoteID: string;
  dispatchedLinesCount: number;
  expectedPartsToDispatch: TGoodsDispatchNotePart[];
  issueDate: string;
  creationDate: string;
  orderID: string;
  orderNo: string;
  partsDispatched: TGoodsDispatchNotePart[];
  realisationID: string;
  recipientID: string;
  recipientWarehouseID: string;
  recipientWarehouseName: string;
  status: string;
  supplierID: string;
  type: string;
  expectedPartsCount: number;
}

export type TGoodsReceivedNotePart = {
  referenceId: string;
  available: number;
  codes: TCodes;
  qualityControl: number | null;
};

export interface IGoodsReceivedNote extends ApiResponse {
  expectedParts: TGoodsReceivedNotePart[];
  goodsReceivedNoteID: string;
  issueDate: string;
  orderID: string;
  orderNo: string;
  claimNumber: string;
  parcelNumber: string;
  receivedLinesCount: number;
  receivedParts: TGoodsReceivedNotePart[];
  recipientID: string;
  recipientWarehouseID: string;
  recipientWarehouseName: string;
  status: string;
  supplierID: string;
  type: string;
  backorderID: string;
  backorderNo: string;
  creationDate: string;
  expectedPartsCount: number;
}

export interface IInternalConsumptionNote extends ApiResponse {
  documentID: string;
  warehouseName: string;
  references: any[];
  consumedParts: any[];
  issueDate: string;
  consumedPartsCount: number;
  additionalInformation: string;
}

export interface IGoodsDispatchNotePostponed extends ApiResponse {
  id: string;
  type: string;
  status: string;
  createTime: string;
  issueTime: string;
  gdnNumber: string;
  deliveryType: string;
  supplierId: string;
  recipientWarehouseId: string;
  referenceId: string;
  quantity: string;
  partAdditionalInfo: any[];
  gdnAdditionalInfo: string;
  orderId: string;
  orderNumber: string;
  orderRealisationId: string;
  orderRealisationType: string;
  claimNumber: string;
  weight: string;
  volume: string;
}
export interface IInvoiceErp extends ApiResponse {
  id: string;
  invoiceNumber?: string;
  invoiceDate: string;
  soldToCustomerCode: string;
  shipToCustomerCode: string;
  invoiceType: string;
  orderNumber: string;
  sapOrderNumber: string;
  orderDate: string;
  orderType: string;
  salesOrganisation: string;
  sapInvoiceLines: any[];
  deliveryNumber: string;
}

export type TBillingLines = {
  deliveryCustomerId: string;
  description: string;
  eotpNumber: string;
  externalCustomerId: string;
  priceNet: number;
  taxRate: number;
};

export type TCorrectionPaymentCustomer = {
  city: string;
  email: string;
  id: string;
  name: string;
  postcode: string;
  street: string;
  streetNumber: string;
  taxCode: string;
};

export type TSourceLines = {
  invoiceDate: string;
  invoiceNumber: string;
};

export interface ICollectiveCorrectionsList extends ApiResponse {
  id: string;
  documentMask: string;
  bonusName: string;
  billingLines: TBillingLines[];
  invoiceNotes: string;
  paymentCustomer: TCorrectionPaymentCustomer;
  sourceLines: TSourceLines[];
  totalPriceGross?: number;
  totalPriceNet?: number;
  totalPriceTax?: number;
}

export interface ICollectiveDebitNoteCorrections extends ApiResponse {
  id: string;
  bonusName: string;
  billingLines: TBillingLines[];
  invoiceNotes: string;
  sourceLines: TSourceLines[];
  paymentCustomer: TCorrectionPaymentCustomer;
  totalPriceGross?: number;
  totalPriceNet?: number;
  totalPriceTax?: number;
}

export interface ICollectiveMM extends ApiResponse {
  additionalInfo?: string;
  creationTime?: string;
  deliveryTime?: string | null;
  dispatchTime?: string | null;
  id?: string;
  jpkStatus?: string;
  number?: string;
  parts?: [
    {
      partID?: string;
      reserved?: number;
      sent?: null;
      delivered: null | boolean;
      cancelled: null | boolean;
      priceQuantities: number | null;
    },
  ];
  recipientID?: string;
  recipientName?: string;
  status?: string;
  supplierID?: string;
  supplierName?: string;
}

export type ICreateInvoiceCorrection = {
  correctionReason: string;
  correctedInvoiceLines: {
    numberLine: number;
    correctedQuantity: number;
    correctedTaxRate: number;
    correctedPercentageDiscount?: number;
    correctedLineUnitPriceNet?: number;
  }[];
};

export type TCreateInvoiceTransport = {
  deliveryCustomerId: string;
  netPrice: number;
};

export type ApiResponse = Record<string, any>;

export enum InvoicesActionTypes {
  FETCH_INVOICES = "@@invoices/FETCH_INVOICES",
  FETCH_INVOICES_SUCCESS = "@@invoices/FETCH_INVOICES_SUCCESS",
  FETCH_INVOICES_WITH_PROVISION = "@@invoices/FETCH_INVOICES_WITH_PROVISION",
  FETCH_INVOICES_WITH_PROVISION_SUCCESS = "@@invoices/FETCH_INVOICES_WITH_PROVISION_SUCCESS",
  FETCH_INVOICES_CORRECTIONS = "@@invoices/FETCH_INVOICES_CORRECTIONS",
  FETCH_INVOICES_CORRECTIONS_SUCCESS = "@@invoices/FETCH_INVOICES_CORRECTIONS_SUCCESS",
  CREATE_INVOICE_CORRECTION = "@@invoices/CREATE_INVOICE_CORRECTION",
  CREATE_INVOICE_CORRECTION_SUCCESS = "@@invoices/CREATE_INVOICE_CORRECTION_SUCCESS",
  CLEAR_CREATED = "@@invoices/CLEAR_CREATED",
  FETCH_GOODS_DISPATCH_NOTES = "@@invoices/FETCH_GOODS_DISPATCH_NOTES",
  FETCH_GOODS_DISPATCH_NOTES_SUCCESS = "@@invoices/FETCH_GOODS_DISPATCH_NOTES_SUCCESS",
  FETCH_GOODS_RECEIVED_NOTES = "@@invoices/FETCH_GOODS_RECEIVED_NOTES",
  FETCH_GOODS_RECEIVED_NOTES_SUCCESS = "@@invoices/FETCH_GOODS_RECEIVED_NOTES_SUCCESS",
  FETCH_INTERNAL_CONSUMPTION_NOTES = "@@invoices/FETCH_INTERNAL_CONSUMPTION_NOTES",
  FETCH_INTERNAL_CONSUMPTION_NOTES_SUCCESS = "@@invoices/FETCH_INTERNAL_CONSUMPTION_NOTES_SUCCESS",
  CREATE_INVOICE_TRANSPORT = "@@invoices/CREATE_INVOICE_TRANSPORT",
  CREATE_INVOICE_TRANSPORT_SUCCESS = "@@invoices/CREATE_INVOICE_TRANSPORT_SUCCESS",
  FETCH_INVOICES_ERP = "@@invoices/FETCH_INVOICES_ERP",
  FETCH_INVOICES_ERP_SUCCESS = "@@invoices/FETCH_INVOICES_ERP_SUCCESS",
  FETCH_GOOD_DISPATCH_NOTE_POSTPONED = "@@invoices/FETCH_GOOD_DISPATCH_NOTE_POSTPONED",
  FETCH_GOOD_DISPATCH_NOTE_POSTPONED_SUCCESS = "@@invoices/FETCH_GOOD_DISPATCH_NOTE_POSTPONED_SUCCESS",
  FETCH_INVOICE_LINES = "@@invoices/FETCH_INVOICE_LINES",
  FETCH_INVOICE_LINES_SUCCESS = "@@invoices/FETCH_INVOICES_LINES_SUCCESS",
  FETCH_INVOICE_SCHEDULERS = "@@invoices/FETCH_INVOICE_SCHEDULERS",
  FETCH_INVOICE_SCHEDULERS_SUCCESS = "@@invoices/FETCH_INVOICE_SCHEDULERS_SUCCESS",
  FETCH_INVOICE_LINES_SCHEDULERS = "@@invoices/FETCH_INVOICE_LINES_SCHEDULERS",
  FETCH_INVOICE_LINES_SCHEDULERS_SUCCESS = "@@invoices/FETCH_INVOICE_LINES_SCHEDULERS_SUCCESS",
  GET_INVOICE_PDF = "@@invoices/GET_INVOICE_PDF",
  GET_COLLECTIVE_CORRECTION_PDF = "@@invoices/GET_COLLECTIVE_CORRECTION_PDF",
  GET_COLLECTIVE_DEBIT_CORRECTION_PDF = "@@invoices/GET_COLLECTIVE_DEBIT_CORRECTION_PDF",
  PREVIEW_COLLECTIVE_CORRECTION_PDF = "@@invoices/PREVIEW_COLLECTIVE_CORRECTION_PDF",
  SELECT_GOODS_DISPATCH_NOTE = "@@invoices/SELECT_GOODS_DISPATCH_NOTE",
  GOODS_DISPATCH_NOTE_SELECTED = "@@invoices/GOODS_DISPATCH_NOTE_SELECTED",
  SELECT_GOODS_RECEIVED_NOTE = "@@invoices/SELECT_GOODS_RECEIVED_NOTE",
  GOODS_RECEIVED_NOTE_SELECTED = "@@invoices/GOODS_RECEIVED_NOTE_SELECTED",
  SELECT_INTERNAL_CONSUMPTION_NOTE = "@@invoices/SELECT_INTERNAL_CONSUMPTION_NOTE",
  INTERNAL_CONSUMPTION_NOTE_SELECTED = "@@invoices/INTERNAL_CONSUMPTION_NOTE_SELECTED",
  SELECT_INVOICE_ERP = "@@invoices/SELECT_INVOICE_ERP",
  INVOICE_ERP_SELECTED = "@@invoices/INVOICE_ERP_SELECTED",
  SELECT_GOOD_DISPATCH_NOTE_POSTPONED = "@@invoices/SELECT_GOOD_DISPATCH_NOTE_POSTPONED",
  GOOD_DISPATCH_NOTE_POSTPONED_SELECTED = "@@invoices/GOOD_DISPATCH_NOTE_POSTPONED_SELECTED",
  INVOICE_RETRY_SENT = "@@invoices/INVOICE_RETRY_SENT",
  FETCH_INVOICE_FINAL = "@@invoices/FETCH_INVOICE_FINAL",
  FETCH_INVOICE_FINAL_SUCCESS = "@@invoices/FETCH_INVOICE_FINAL_SUCCESS",
  UPLOAD_COLLECTIVE_CORRECTIONS = "@@invoices/UPLOAD_COLLECTIVE_CORRECTIONS",
  LOAD_COLLECTIVE_CORRECTIONS = "@@invoices/LOAD_COLLECTIVE_CORRECTIONS",
  LOAD_COLLECTIVE_CORRECTIONS_ERROR = "@@invoices/LOAD_COLLECTIVE_CORRECTIONS_ERROR",
  FETCH_COLLECTIVE_CORRECTIONS = "@@invoices/FETCH_COLLECTIVE_CORRECTIONS",
  FETCH_COLLECTIVE_CORRECTIONS_SUCCESS = "@@invoices/FETCH_COLLECTIVE_CORRECTIONS_SUCCESS",
  CONFIRM_COLLECTIVE_CORRECTIONS = "@@invoices/CONFIRM_COLLECTIVE_CORRECTIONS",
  CONFIRM_COLLECTIVE_CORRECTIONS_SUCCESS = "@@invoices/CONFIRM_COLLECTIVE_CORRECTIONS_SUCCESS",
  FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS = "@@invoices/FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS",
  FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS_SUCCESS = "@@invoices/FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS_SUCCESS",
  CLEAR_FINAL_INVOICE = "@@invoices/CLEAR_FINAL_INVOICE",
  CLEAR_COLLECTIVE_CORRECTIONS = "@@invoices/CLEAR_COLLECTIVE_CORRECTIONS",
  FETCH_DEBIT_NOTES = "@@invoices/FETCH_DEBIT_NOTES",
  FETCH_DEBIT_NOTES_SUCCESS = "@@invoices/FETCH_DEBIT_NOTES_SUCESS",
  FETCH_DEBIT_NOTE_CORRECTIONS = "@@invoices/FETCH_DEBIT_NOTE_CORRECTIONS",
  FETCH_DEBIT_NOTE_CORRECTIONS_SUCCESS = "@@invoices/FETCH_DEBIT_NOTE_CORRECTIONS_SUCCESS",
  FETCH_INVOICES_WITH_DEBIT_NOTES = "@@invoices/FETCH_INVOICES_WITH_DEBIT_NOTES",
  FETCH_INVOICES_WITH_DEBIT_NOTES_SUCCESS = "@@invoices/FETCH_INVOICES_WITH_DEBIT_NOTES_SUCCESS",
  SET_TYPE_OF_EXPORT_DATA = "@@invoices/SET_TYPE_OF_EXPORT_DATA",
  OPEN_CHOOSE_EXPORT_DATA_MODAL = "@@invoices/OPEN_CHOOSE_EXPORT_DATA_MODAL",
  SAVE_QUANTITY_TO_REDUX = "@@invoices/SAVE_QUANTITY_TO_REDUX",
  FETCH_MM_DATA = "@@invoices/FETCH_MM_DATA",
  FETCH_MM_DATA_SUCCESS = "@@invoices/FETCH_MM_DATA_SUCCESS",
  SELECT_MM = "@@invoices/SELECT_MM",
  MM_SELECTED = "@@invoices/MM_SELECTED",
  EXPORT_ON_REQUEST = "@@invoices/EXPORT_ON_REQUEST",
  EXPORT_ON_REQUEST_SUCCESS = "@@invoices/EXPORT_ON_REQUEST_SUCCESS",
  EXPORT_ON_REQUEST_ERROR = "@@invoices/EXPORT_ON_REQUEST_ERROR",
}

export interface InvoicesState {
  readonly loadingInvoices: boolean;
  readonly loadingInvoice: boolean;
  readonly exportOnRequestPending: boolean;
  readonly list: IInvoice[];
  readonly meta: PaginationMeta;
  readonly corrections: {
    list: IInvoice[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
  };
  readonly goodsDispatchNotes: {
    list: IGoodsDispatchNote[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IGoodsDispatchNote;
  };
  readonly mmDataResponse: {
    list: ICollectiveMM[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: ICollectiveMM;
  };
  readonly goodsReceivedNotes: {
    list: IGoodsReceivedNote[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IGoodsReceivedNote;
  };
  readonly internalConsumptionNote: {
    list: IInternalConsumptionNote[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInternalConsumptionNote;
  };
  readonly invoicesErp: {
    list: IInvoiceErp[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInvoiceErp;
  };
  readonly goodsDispatchNotePostponed: {
    list: IGoodsDispatchNotePostponed[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IGoodsDispatchNotePostponed;
  };
  readonly collectiveCorrectionsList: {
    list: ICollectiveCorrectionsList[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: any;
  };
  readonly collectiveDebitNoteCorrections: {
    list: ICollectiveDebitNoteCorrections[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: any;
  };
  readonly invoicesWithProvision: {
    list: ICollectiveCorrectionsList[];
    loading: boolean;
  };
  readonly debitNotes: {
    list: IInvoice[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInvoice;
  };
  readonly debitNotesCorrections: {
    list: IInvoice[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInvoice;
  };
  readonly invoicesWithDebitNotes: {
    list: IInvoice[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInvoice;
  };
  readonly invoiceLines: {
    list: IInvoiceLines[];
    meta: PaginationMeta;
    loading: boolean;
    totalRecords: TotalRecords;
    selected?: IInvoice;
  };
  readonly gdnPositionsSchedulers: {
    stock?: string;
    turboPro?: string;
    urgent?: string;
    loading: boolean;
  };
  readonly invoiceLinesSchedulers: {
    stock?: string;
    turboPro?: string;
    urgent?: string;
    loading: boolean;
  };
  readonly collectiveCorrectionsErrorBody: any;
  readonly totalRecords: TotalRecords;
  readonly loadingCollectiveCorrections: boolean;
  readonly loadingConfirmCollectiveCorrections: boolean;
  readonly loadingCollectiveCorretions: boolean;
  readonly loadingInvoiceLines: boolean;
  readonly loadingGoodsDispatchNotePostponed: boolean;
  readonly loadingCollectiveDebitNoteCorrections: boolean;
  readonly loadingDebitNotes: boolean;
  readonly loadingDebitNotesCorrections: boolean;
  readonly loadingInvoicesWithDebitNotes: boolean;
  readonly loadingMM: boolean;
  readonly selectedFinalInvoice?: IInvoice;
  readonly collectiveCorrections?: ICollectiveCorrectionsList[];
  readonly createdCollectiveCorretion?: boolean;
  readonly created?: boolean;
  readonly creating?: boolean;
  readonly selectedTypeOfDataToExport?: string;
  readonly isOpenChooseTypeOfExportDataModal?: boolean;
  readonly quantityRedux?: number;
}

export type PaginableInvoice = Paginable<IInvoice>;
export type PaginableGoodsDispatchNotes = Paginable<IGoodsDispatchNote>;
export type PaginableGoodsReceivedNotes = Paginable<IGoodsReceivedNote>;
export type PaginableInternalConsumptionNotes =
  Paginable<IInternalConsumptionNote>;
export type PaginableInvoiceErp = Paginable<IInvoiceErp>;
export type PaginableICollectiveCorrections =
  Paginable<ICollectiveCorrectionsList>;
export type PaginableIMM = Paginable<ICollectiveMM>;
export type PaginableICollectiveDebitNoteCorrections =
  Paginable<ICollectiveDebitNoteCorrections>;
export type PaginableIInvoiceLines = Paginable<IInvoiceLines>;
export type PaginableGoodsDispatchNotePostponed =
  Paginable<IGoodsDispatchNotePostponed>;
