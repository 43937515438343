import { Message } from "semantic-ui-react"

import React from 'react'

export type Props = {
    format: boolean
    differentFiles?: string
    additionalMessage?: string | JSX.Element
}

const ModalMessageHeader: React.FC<Props> = ({ format, differentFiles, additionalMessage }) => {
    return (
        <Message size='small'>
            <Message.Content>
                Załaduj plik { differentFiles ? differentFiles : 'XLSX, XLS, CSV' }{ format ? ' według wzorcowego formatu' : null }.<br />
                { additionalMessage ? (<>{ additionalMessage } <br /></>) : null }
                <span style={{ color: 'gray', fontSize: '10px'}}>*Maksymalny rozmiar pliku 15MB.</span>
            </Message.Content>
        </Message>
    )
}

export default ModalMessageHeader