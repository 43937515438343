import { Dropdown } from "semantic-ui-react";

import React from "react";

import "./table-more-dropdown.scss";

type TProps = {
  children: React.ReactNode;
};

const TableMoreDropdown: React.FC<TProps> = ({ children }): JSX.Element => {
  return (
    <Dropdown
      icon="ellipsis horizontal"
      className="table-more-dropdown"
      title="Więcej"
    >
      <Dropdown.Menu direction="left">{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default TableMoreDropdown;
