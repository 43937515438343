import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'
import { 
  fetchCodedParts,
  fetchCodedPartsError,
  fetchCodedPartsSuccess,
  completeCodedPart,
  completeCodedPartSuccess,
  completeCodedPartError } from './actions'
import { CodedPart, CodedPartsActionTypes, PaginableCodedPart } from './types'

import translations from '~/utils/translations'
import { toastError, toastSuccess } from '~/utils/toast'

function* handleFetchCodedParts(action: ReturnType<typeof fetchCodedParts>) {
    try {
        let filters = ''
        let sort = ''
        action.payload.filters.forEach((filter: any) => {
            filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
        })

        if (action.payload.sortColumn && action.payload.sortDirection) {
            sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`
        }

        const codedParts: PaginableCodedPart = yield call(callApi, 'get', 
        `/manual-orders?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`)

        const codedPartsSuccess: PaginableCodedPart = yield put(fetchCodedPartsSuccess(codedParts))
        return codedPartsSuccess

    } catch (error) {
        yield put(fetchCodedPartsError(error))
        yield put(handleError(error as Error, false, translations.format("app.error.coded-parts.load")))
    }
}

  function* handleCompleteCodedPart(action: ReturnType<typeof completeCodedPart>) {
    try {
      const params = action.payload

      const res: CodedPart = yield call(
        callApi,
        "PUT",
        `/manual-orders/${params.orderId}-${params.lineNr}/status`,
        {
          status: "ORDERED",
          supplierOrderNumber: action.payload.CSPSOrderNr
        }
      )

      if (res) {
        toastSuccess(
          translations.format("app.manual-orders.processing-completed"),
          translations.format("app.manual-orders.CSPS-nr-saved")
        )
        yield put(completeCodedPartSuccess(res))
      }

    } catch (error) {
      toastError(
        translations.format("app.manual-orders.processing-failed"), 
        translations.format("app.manual-orders.CSPS-nr-not-saved")
        )
      yield put(completeCodedPartError(error));
    }
  }

function* watchFetchCodedParts() {
  yield takeEvery(CodedPartsActionTypes.FETCH_CODED_PARTS, handleFetchCodedParts)
}

function* watchCompleteCodedPart() {
  yield takeEvery(CodedPartsActionTypes.COMPLETE_CODED_PART, handleCompleteCodedPart)
}

function* codedPartsSaga() {
    yield all([
        fork(watchFetchCodedParts),
        fork(watchCompleteCodedPart),
    ])
}

export default codedPartsSaga