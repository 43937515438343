import React from "react";
import { Icon, Label, Popup } from "semantic-ui-react";
import { TNiceLabelProps } from "~/components/MapDictionary/lib/types";


export const NiceLabel: React.FC<TNiceLabelProps> = ({
    text,
    description,
    light,
    popup,
    color,
    icon,
    flexDisplay,
    hideKey,
    regular,
    discountValues,
    isDiscountLabel
}) => {

    const roundDiscount = (value: number): string => {
        if (!isDiscountLabel) return ''
        return (' -' + (Math.abs(value) * 100).toFixed(2) ) +  ' %'
    }

    const countDiscount = (value: number): string => {
        if (!isDiscountLabel) return ''
        return (' -' + (Math.abs(value) * 100)) +  ' %'
    }

    const label = (
        <span className="nice-label">
            {
                !hideKey ? (
                        <Label color={color} image={!light && !!description} basic
                               className={'nice-label-flex' + (flexDisplay ? ' flex-max' : '')}>
                            {icon ? <Icon name={icon} fitted={!text}/> : null}
                            {text ? <span>{text + (discountValues && discountValues.sell !== undefined ? roundDiscount(discountValues.sell) : '')}</span> : null}
                            {(!light && description) ? <Label.Detail>{description}</Label.Detail> : null}
                        </Label>)
                    : <span>
                        {!light && description && <Label.Detail>{description}</Label.Detail>}
                    </span>

            }
            {(light && description) ? (regular ? <span>{description}</span> : <small>{description}</small>) : null}
        </span>
    )
    return popup ? (
        <Popup 
            position="top center"
            size="tiny"
            offset="0, 5px"
            trigger={label}
            content={
            <i>{popup + (discountValues && discountValues.sell !== undefined ? countDiscount(discountValues.sell) : '')}</i>
        }/>)
        : label
}