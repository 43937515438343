import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi } from "~/utils/api";
import { handleError } from "~/store/error/actions";
import {
  advancedSearch,
  advancedSearchGroup1Success,
  advancedSearchGroup2Success,
  fetchFamilies,
  fetchSegments,
  fetchSlots,
  fetchSpareParts,
  fetchSuccess,
} from "./actions";
import { HierarchyActionTypes, TNodeData } from "./types";

function* handleFetchSegments(action: ReturnType<typeof fetchSegments>) {
  try {
    const response: TNodeData[] = yield call(
      callApi,
      "get",
      `/category-hierarchy/index/${action.payload.index.id}/children`
    );
    const results = (response && response.length > 0 ? response : []).map(
      (result: any): TNodeData => ({
        id: result.key,
        label: result.label,
        group: "SEGMENT",
        leaf: false,
      })
    );
    action.payload.fetcher(results);
    yield put(fetchSuccess(action.payload.index, results));
  } catch (error) {
    action.payload.fetcher([]);
    yield put(handleError(error, false));
  }
}

function* handleFetchSlots(action: ReturnType<typeof fetchSlots>) {
  try {
    const parents = action.payload.segment.parents;
    const url = `/category-hierarchy${
      parents ? `/index/${parents[0].id}` : ``
    }/segment/${action.payload.segment.id}/children`;

    const response: TNodeData[] = yield call(callApi, "get", url);
    const results = (response && response.length > 0 ? response : []).map(
      (result: any): TNodeData => ({
        id: result.key,
        label: result.label,
        group: "SLOT",
        leaf: false,
      })
    );
    action.payload.fetcher(results);
    yield put(fetchSuccess(action.payload.segment, results));
  } catch (error) {
    action.payload.fetcher([]);
    yield put(handleError(error, false));
  }
}

function* handleFetchFamilies(action: ReturnType<typeof fetchFamilies>) {
  try {
    const parents = action.payload.slot.parents;
    const url = `/category-hierarchy${
      parents ? `/index/${parents[0].id}/segment/${parents[1].id}` : ``
    }/slot/${action.payload.slot.id}/children`;

    const response: TNodeData[] = yield call(callApi, "get", url);
    const results = (response && response.length > 0 ? response : []).map(
      (result: any): TNodeData => ({
        id: result.key,
        label: result.label,
        group: "FAMILY",
        leaf: false,
      })
    );
    action.payload.fetcher(results);
    yield put(fetchSuccess(action.payload.slot, results));
  } catch (error) {
    action.payload.fetcher([]);
    yield put(handleError(error, false));
  }
}

function* handleFetchReferences(action: ReturnType<typeof fetchSpareParts>) {
  try {
    const parents = action.payload.family.parents;
    const url = `/category-hierarchy${
      parents
        ? `/index/${parents[0].id}/segment/${parents[1].id}/slot/${parents[2].id}`
        : ``
    }/family/${action.payload.family.id}/children`;

    const response: TNodeData[] = yield call(callApi, "get", url);
    const results = (response || []).map(
      (result: any): TNodeData => ({
        id: result.key,
        label: result.label,
        group: "REFERENCE",
        leaf: true,
      })
    );
    action.payload.fetcher(results);
    yield put(fetchSuccess(action.payload.family, results));
  } catch (error) {
    action.payload.fetcher([]);
    yield put(handleError(error, false));
  }
}

function* handleAdvancedSearch(action: ReturnType<typeof advancedSearch>) {
  try {
    const { param, key, associativeDiscountGroup } = action.payload;
    const response: TNodeData[] = yield call(
      callApi,
      "get",
      `/category-hierarchy?${param}=${key}`
    );

    if (associativeDiscountGroup === "GROUP_1") {
      yield put(advancedSearchGroup1Success(response));
    } else {
      yield put(advancedSearchGroup2Success(response));
    }
  } catch (error) {
    yield put(handleError(error, false));
  }
}

function* watchFetchSegmentsRequest() {
  yield takeEvery(HierarchyActionTypes.FETCH_SEGMENTS, handleFetchSegments);
}

function* watchFetchSlotsRequest() {
  yield takeEvery(HierarchyActionTypes.FETCH_SLOTS, handleFetchSlots);
}

function* watchFetchFamiliesRequest() {
  yield takeEvery(HierarchyActionTypes.FETCH_FAMILIES, handleFetchFamilies);
}

function* watchFetchReferencesRequest() {
  yield takeEvery(HierarchyActionTypes.FETCH_REFERENCES, handleFetchReferences);
}

function* watchAdvancedSearch() {
  yield takeEvery(
    HierarchyActionTypes.ADVANCED_SEARCH_FETCH,
    handleAdvancedSearch
  );
}

function* hierarchySaga() {
  yield all([
    fork(watchFetchSegmentsRequest),
    fork(watchFetchSlotsRequest),
    fork(watchFetchFamiliesRequest),
    fork(watchFetchReferencesRequest),
    fork(watchAdvancedSearch),
  ]);
}

export default hierarchySaga;
