import { Table } from "semantic-ui-react";
import { Order, OrderStatus } from "~/store/orders/types";

import React, { useCallback, useEffect, useState } from "react";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import { TColumn } from "~/components/Table/lib/types";
import ProductListDropdown from "~/pages/Orders/lib/ProductListDropdown";

interface Props {
  orders: Order[];
  columns: TColumn[];
  onlyViewAction?: boolean;
  changeStatusOrder?: (id: string, status: OrderStatus) => void;
  cancelRole?: boolean;
}

export const OrdersList = ({
  orders,
  columns,
  changeStatusOrder,
  onlyViewAction,
  cancelRole
}: Props) => {
  const [openOrderStatusModal, setOpenOrderStatusModal] =
    useState<boolean>(false);
  const [orderStatusId, setOrderStatusId] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string>("");

  const statusChangeHandler = useCallback(
    (id: string, status: string) => {
      setOrderStatus(status);
      setOrderStatusId(id);
    },
    [setOrderStatusId, setOrderStatus]
  );

  const handleConfirmClick = useCallback(() => {
    if (!changeStatusOrder) return;
    changeStatusOrder(orderStatusId, {
      status: orderStatus,
      onList: true,
    });
    statusChangeHandler("", "");
  }, [changeStatusOrder, orderStatus, orderStatusId, statusChangeHandler]);

  const handleCancelClick = useCallback(() => {
    statusChangeHandler("", "");
    setOpenOrderStatusModal(false);
  }, [statusChangeHandler]);

  useEffect(() => {
    if (orderStatus && orderStatusId) {
      setOpenOrderStatusModal(true);
    } else {
      setOpenOrderStatusModal(false);
    }
  }, [orderStatusId, orderStatus]);

  return (
    <>
      {!orders.length ? (
        <TableNoResults key={Math.random()} />
      ) : (
        orders.map((order) => (
          <Table.Row
            key={order.id + Math.random()}
            style={{ background: order.modifiedBySap && "rgba(0,0,0,0.1)" }}
          >
            {columns.map(
              (column) =>
                column.projection &&
                column.getCell &&
                column.getCell(order, column)
            )}
            <Table.Cell
              className="col-dropdown-menu-sticky"
              key={"dropdown-list"}
            >
              <ProductListDropdown
                order={order}
                statusChangeHandler={statusChangeHandler}
                onlyViewAction={onlyViewAction}
                cancelRole={cancelRole}
              />
            </Table.Cell>
          </Table.Row>
        ))
      )}
      <ModalConfirm
        modalOpen={openOrderStatusModal}
        contentText="app.order.confirmStatusUpdateOuestion"
        headerIcon="question circle"
        headerText="app.confirmOperation"
        onCancelClick={handleCancelClick}
        onConfirmClick={handleConfirmClick}
      />
    </>
  );
};

export default OrdersList;
