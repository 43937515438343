import { action } from 'typesafe-actions'
import {
    AdditionalData,
    PaginablePromotion,
    PromotionsActionTypes,
    TPromotion,
    TPromotionActivateCollisionInfo,
    TReferenceRuleData
} from './types'

export const fetchPromotions = (params: any) => action(PromotionsActionTypes.FETCH_REQUEST, params)
export const filterPromotions = (query: string) => action(PromotionsActionTypes.FILTER_PROMOTIONS, query)
export const fetchSuccess = (data: PaginablePromotion) => action(PromotionsActionTypes.FETCH_SUCCESS, data)

export const selectPromotion = (promotionId: string) => action(PromotionsActionTypes.SELECT_PROMOTION, promotionId)
export const promotionSelected = (promotion: TPromotion) => action(PromotionsActionTypes.SELECTED, promotion)
export const clearSelected = () => action(PromotionsActionTypes.CLEAR_SELECTED)

export const updatePromo = (id: string, promotion: TPromotion) => action(PromotionsActionTypes.UPDATE_PROMO, { id, promotion })
export const updatePromoSuccess = () => action(PromotionsActionTypes.UPDATE_PROMO_SUCCESS)

export const activatePromotion = (promoId: string) => action(PromotionsActionTypes.ACTIVATE_PROMOTION, { promoId })
export const activatePromotionSuccess = () => action(PromotionsActionTypes.ACTIVATE_PROMOTION_SUCCESS)
export const activatePromotionCollision = (promoCollision: TPromotionActivateCollisionInfo) => action(PromotionsActionTypes.ACTIVATE_PROMOTION_COLLISION, { promoCollision })
export const activatePromotionFailure = () => action(PromotionsActionTypes.ACTIVATE_PROMOTION_FAILURE)
export const promoCollisionViewClosed = () => action(PromotionsActionTypes.ACTIVATE_PROMOTION_COLLISION_VIEW_CLOSED)

export const createPromotion = (promotion?: TPromotion) => action(PromotionsActionTypes.CREATE_PROMOTION, promotion)
export const createSuccess = (promoID: string) => action(PromotionsActionTypes.CREATE_SUCCESS, promoID)

export const clonePromotion = (promotion?: TPromotion) => action(PromotionsActionTypes.CLONE_PROMOTION, promotion)
export const cloneSuccess = (promoID: string) => action(PromotionsActionTypes.CLONE_SUCCESS, promoID)

export const deletePromotion = (promotionId: string) => action(PromotionsActionTypes.DELETE_PROMOTION, promotionId)
export const deleteSuccess = () => action(PromotionsActionTypes.DELETED_SUCCESS)

export const closePromotion = (promoID: string) => action(PromotionsActionTypes.CLOSE_PROMOTION, promoID)
export const closePromotionSuccess = () => action(PromotionsActionTypes.CLOSE_PROMOTION_SUCCESS)

export const validatePromotion = (promotion: TPromotion) => action(PromotionsActionTypes.VALIDATE_PROMO, promotion)
export const validateFinished = (result: AdditionalData[]) => action(PromotionsActionTypes.VALIDATE_FINISHED, result)

export const importReferences = (file: File) => action(PromotionsActionTypes.IMPORT_REFERENCES, file)
export const importPromotion = (file: File, additionalData: any) => action(PromotionsActionTypes.IMPORT_PROMOTION, {
    file,
    additionalData
})
export const importFinished = (result?: TReferenceRuleData[]) => action(PromotionsActionTypes.IMPORT_FINISHED, result)

export const redirectToPromoViewList = () => action(PromotionsActionTypes.REDIRECT_TO_PROMO_LIST_VIEW)
export const redirectToPromoViewListFinished = () => action(PromotionsActionTypes.REDIRECT_TO_PROMO_LIST_VIEW_FINISHED)
