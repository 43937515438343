import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useAdvancedSearch } from "~/components/AdvancedSearch/hooks/useAdvancedSearch";

import {
  FIELD_TYPE,
  FormField,
} from "~/components/AdvancedSearch/types/advancedSearchType";
import { TColumn } from "~/components/Table/lib/types";
import { IRefObject } from "~/components/TableFilter/TableFilter";
import { prepareDateForFilter } from "~/utils/dateUtils";
import TableFilterSession, {
  TTableFilterSession,
} from "~/utils/tableFilterSession";

export const useGoodsReceivedNotesAdvancedSearch = (
  columns: TColumn[],
  setColumns: Dispatch<SetStateAction<TColumn[]>>,
  sortColumn: string,
  sortDirection: string,
  fetchMethod: (params?: Object) => void,
  filterLabelsRef: MutableRefObject<TTableFilterSession[]>,
  tableKey: string
) => {
  const tableFilter = React.createRef<IRefObject>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  const { search } = useLocation();
  const { addUpdateFilterLabel, setColumnsFromLocalStorage } =
    useAdvancedSearch(
      columns,
      setColumns,
      sortColumn,
      sortDirection,
      tableKey,
      filterLabelsRef,
      tableKey
    );

  const removeFilterLabel = useCallback(
    (name: string) => {
      const newFilterLabels = filterLabelsRef.current.filter(
        (label) => label.name !== name
      );

      filterLabelsRef.current = newFilterLabels;

      TableFilterSession.clearFilter({ name, table: tableKey, value: "" });

      if (tableFilter.current) {
        tableFilter.current.clearFilter(name);
      }
    },
    [filterLabelsRef, tableFilter, tableKey]
  );

  const handleAddFilter = useCallback(
    (name: string, value: string, text?: string, key?: string) => {
      let checkIsInFilterTable = false;

      filterLabelsRef.current.forEach((filter) => {
        if (filter.value === value && filter.name === name) {
          checkIsInFilterTable = true;
        }
      });

      if (!checkIsInFilterTable) {
        if (value !== "") {
          addUpdateFilterLabel(name, value, text, key);
        } else {
          removeFilterLabel(name);
        }
      }
    },
    [filterLabelsRef, removeFilterLabel, addUpdateFilterLabel]
  );

  const handleSubmit = useCallback(() => {
    let formReferenceId: string;

    if (dateTo || dateFrom) {
      handleAddFilter(
        "creationDate",
        prepareDateForFilter(dateTo, dateFrom, 19),
        "Data" + (dateFrom ? " od" : "") + (dateTo ? " do" : "")
      );
    } else {
      handleAddFilter("creationDate", "");
    }

    let stringEqualLabelName = "";

    if (referenceId) {
      formReferenceId = "expectedParts.referenceId:" + referenceId + "::";
      stringEqualLabelName += "Numer referencji ";
    } else {
      formReferenceId = "";
    }
    handleAddFilter("stringEqual", formReferenceId, stringEqualLabelName);

    fetchMethod({ page: 1 });
  }, [dateTo, dateFrom, referenceId, handleAddFilter, fetchMethod]);

  const formFields: FormField[][] = [
    [
      {
        type: FIELD_TYPE.INPUT,
        name: "referenceId",
        label: "Referencja",
        placeholder: "Referencja",
        value: referenceId,
        onChange: useCallback(
          (_, { value }) => {
            setReferenceId(value.trim());
          },
          [setReferenceId]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateFrom",
        label: "Data od:",
        placeholder: "RRRR-MM-DD",
        id: "initialDate",
        value: dateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateFrom(value);
          },
          [setDateFrom]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateTo",
        label: "Data do:",
        placeholder: "RRRR-MM-DD",
        id: "finalDate",
        value: dateTo,
        minDate: dateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateTo(value);
          },
          [setDateTo]
        ),
      },
    ],
  ];

  useEffect(() => {
    setColumnsFromLocalStorage();
  }, [setColumnsFromLocalStorage]);

  useEffect(() => {
    if (search) {
      const initParams = new URLSearchParams(search);

      initParams.forEach((value, name) => {
        if (value && value !== "null") {
          addUpdateFilterLabel(name, value, value);
        }
      });
    }
  }, [search, addUpdateFilterLabel]);

  return {
    formFields,
    handleSubmit,
  };
};
