import { Container, Icon, Label } from 'semantic-ui-react'

import React from 'react'

type TProps = {
    filterLabels: any[]
    removeFilterLabel: (name: string) => void
    clearAllFilter: () => void
}

const FilterLabels: React.FC<TProps> = ({
    filterLabels,
    removeFilterLabel,
    clearAllFilter
}) => {
    return (
        <Container textAlign='left' fluid style={{ display: filterLabels.length !== 0 ? 'inline-block' : 'none' }}>
            <Label as='a' color='grey' onClick={clearAllFilter}>Wyczyść filtr</Label>
            {filterLabels.map((label, index) =>
                <Label
                    key={index}
                    as='a'
                    style={{ marginBottom: 5 }}
                    onClick={() => { removeFilterLabel(label.name) }}
                >
                    {label.key && !label.noKey && (label.key !== 'false' && label.key !== 'true') ? `[${label.key}] ${label.text}` : label.text}
                    <Icon name='delete' fitted style={{ paddingLeft: 5 }} />
                </Label>
            )}
        </Container>
    )
}

export default FilterLabels
