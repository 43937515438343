import { usePromotionsRolesRights } from "./usePromotionsRolesRights";

type Props = {
  handleAddPromotion: () => void;
  handleImportPromotion: () => void;
};

export const usePromotionsButtons = (props: Props) => {
  const { userHaveLdcUiPromotionProcessRole } = usePromotionsRolesRights();

  const addButton = {
    icon: "plus",
    content: "Utwórz promocję",
    onClick: () => props.handleAddPromotion(),
    primary: true,
    visible: userHaveLdcUiPromotionProcessRole(),
  };

  const uploadButton = {
    icon: "upload",
    content: "Importuj promocję",
    primary: true,
    onClick: () => props.handleImportPromotion(),
  };

  return [addButton, uploadButton];
};
