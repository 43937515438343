import { TBaseProps } from "~/components/SmartField/lib/types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SmartFieldContext } from "~/components/SmartField/lib/smartField.config";
import { Grid, Icon } from "semantic-ui-react";
import { REQUIRED } from "~/components/SmartField/lib/validator";
import { useMappers } from "~/components/SmartField/hooks/useMappers";

export type SmartLogicHookResponse = [
  any,
  boolean,
  boolean,
  boolean,
  string,
  (value: any) => void,
  () => void,
  (ev: any) => void,
  () => string | JSX.Element | undefined,
  boolean | undefined,
] & {
  currentValue: any;
  modified: boolean;
  loading: boolean;
  success: boolean;
  error: string;
  handleEdit: (value: any) => void;
  handleSave: () => void;
  handleKeyPress: (ev: any) => void;
  getPopupContent: () => string | JSX.Element | undefined;
  isRequired: boolean | undefined;
};

export const useSmartFieldLogic = (props: TBaseProps) => {
  const { loadMapper, saveMapper } = useMappers(props);

  const [previousValue, setPreviousValue] = useState(loadMapper(props.value));
  const [currentValue, setCurrentValue] = useState(loadMapper(props.value));
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const { callPatch, model } = useContext(SmartFieldContext);

  useEffect(() => {
    if (typeof previousValue === "object" || typeof currentValue === "object") {
      setModified(
        JSON.stringify(previousValue) !== JSON.stringify(currentValue)
      );
    } else {
      setModified(previousValue !== currentValue);
    }
  }, [previousValue, currentValue]);

  useEffect(() => {
    const newValue = loadMapper(props.value);
    if (currentValue !== newValue) {
      setPreviousValue(newValue);
      setCurrentValue(newValue);
    }
    // eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    if (props.readOnly) {
      setPreviousValue(loadMapper(props.value));
      setCurrentValue(loadMapper(props.value));
      setError("");
    }
    // eslint-disable-next-line
  }, [props.readOnly]);

  useEffect(() => {
    if (loading) {
      setError("");
      callPatch(
        props.name,
        currentValue === "BRAK" || currentValue === "NONE"
          ? null
          : saveMapper(currentValue)
      )
        .then((res) => {
          setPreviousValue(currentValue);
          setSuccess(true);
        })
        .catch((err) => {
          setPreviousValue(null);
          setError("Błąd podczas zapisywania. Spróbuj ponownie.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [loading]);

  const validate = useCallback((): string | undefined => {
    return (props.validators ? props.validators : [])
      .map((validator) => {
        return validator(currentValue, model);
      })
      .find((message) => {
        return !!message;
      });
  }, [currentValue, model, props.validators]);

  const handleSave = useCallback((): void => {
    if (props.readOnly) {
      return;
    }
    const validationError = validate();
    if (!!validationError) {
      setError(validationError);
    } else if (previousValue !== currentValue) {
      setLoading(true);
    }
  }, [props.readOnly, currentValue, previousValue, validate]);

  useEffect(() => {
    if (success) {
      let successRef = setTimeout(() => {
        setSuccess(false);
        handleSave();
      }, 1500);
      // cleanup
      return () => {
        if (successRef) {
          clearTimeout(successRef);
        }
      };
    }
    // eslint-disable-next-line
  }, [success]);

  const handleEdit = (value: any): void => {
    setCurrentValue(value);
    setError("");
    if (props.onChangeFunc) {
      props.onChangeFunc(value);
    }
  };

  const handleKeyPress = (ev: any): void => {
    if (ev.key === "Enter") {
      handleSave();
    }
  };

  const getPopupContent = (): string | JSX.Element | undefined => {
    if (error) {
      return (
        <Grid columns="2">
          <Grid.Row verticalAlign="middle">
            <Grid.Column width="1">
              <Icon color="red" name="exclamation" />
            </Grid.Column>
            <Grid.Column width="13">{error}</Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else {
      return props.description;
    }
  };

  const isRequired =
    props.validators && props.validators.indexOf(REQUIRED) !== -1;

  return {
    currentValue,
    modified,
    loading,
    success,
    error,
    handleEdit,
    handleSave,
    handleKeyPress,
    getPopupContent,
    isRequired,
  } as SmartLogicHookResponse;
};
