import { connect } from 'react-redux'
import React from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';

import { openChooseTypeOfExportDataModal, setTypeOfExportData } from '~/store/invoices/actions';
import { IInvoice } from '~/store/invoices/types'

import './ChooseDownloadOptionModal.scss';

type ApplicationState = {
    invoices: IInvoice,
}

type TChooseDownloadOptionModalProps = {
    disableButton: (key: boolean) => void,
    handleOpenDownloadModal: () => Promise<void>,
    isOpenChooseTypeOfExportDataModal?: boolean,
    openChooseTypeOfExportDataModal: (key: boolean) => void,
    setTypeOfExportData: (key: string) => void,
}

const ChooseDownloadOptionModal = ({
    disableButton, handleOpenDownloadModal,
    isOpenChooseTypeOfExportDataModal,
    openChooseTypeOfExportDataModal,
    setTypeOfExportData
}: TChooseDownloadOptionModalProps) => (
    <Modal
        className="ChooseDownloadOptionModal"
        closeOnDimmerClick={false}
        onClose={() => openChooseTypeOfExportDataModal(false)}
        onOpen={() => openChooseTypeOfExportDataModal(true)}
        onUnmount={() => openChooseTypeOfExportDataModal(false)}
        open={isOpenChooseTypeOfExportDataModal}
        size="small"
    >
        <Header icon="download" content="Co chcesz pobrać?" />
        <div className="ModalContent">
            <Modal.Content>
                <Form>
                    <Button
                        content="Dokument"
                        onClick={() => {
                            setTypeOfExportData("Dokument");
                            disableButton(true)
                            setTimeout(() => {
                                handleOpenDownloadModal();
                            });
                            openChooseTypeOfExportDataModal(false);
                        }}
                        style={{ marginTop: '0.3vw' }}
                    />
                    <Button
                        content="Lista dokumentów"
                        onClick={() => {
                            setTypeOfExportData("Lista Dokumentów");
                            disableButton(true)
                            setTimeout(() => {
                                handleOpenDownloadModal();
                            });
                            openChooseTypeOfExportDataModal(false);
                        }}
                        style={{ marginTop: '0.3vw', }}
                    />
                </Form>
            </Modal.Content>
        </div>
        <div className="ModalFooter">
            <Modal.Actions>
                <Button content="Zamknij" onClick={() => openChooseTypeOfExportDataModal(false)} />
            </Modal.Actions>
        </div>
    </Modal >
);

const mapStateToProps = ({ invoices }: ApplicationState) => ({
    isOpenChooseTypeOfExportDataModal: invoices.isOpenChooseTypeOfExportDataModal,
})

const mapDispatchToProps = {
    openChooseTypeOfExportDataModal,
    setTypeOfExportData,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDownloadOptionModal);
