import React, { Fragment, memo, SyntheticEvent } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import DetailCard from '~/components/DetailCard/DetailCard';
import { ApplicationState } from '~/store';
import { ApiError } from '~/store/error/types';

import './SpeederContent.scss';

interface PropsFromState {
    error?: ApiError | null,
    loading?: boolean,
    speederDateFrom?: Date | null,
    speederDateTo?: Date | null,
    speederExactDateFrom?: Date | null,
    speederExactDateTo?: Date | null
}

interface HandleChange {
    [key: string]: any;
}

type SpeederContentProps = {
    dateFrom: Date | null,
    dateTo: Date | null,
    exactDateFrom: Date | null,
    exactDateTo: Date | null,
    handleChange: (e: SyntheticEvent<Element, Event> | undefined, { name, value }: HandleChange) => void,
    handleSubmitGenerateSTKVTEIRaport: () => void,
    handleSubmitGenerateSTKVTHRaport: () => void,
    handleSubmitGenerateLIGFACTQRaport: () => void,
    handleSubmitGenerateVENTESQRaport: () => void,
    LIGFATCQRaport?: boolean,
    STKVTEIRaport?: boolean,
    STKVTHRaport?: boolean,
    VENTESQRaport?: boolean
}

type ISpeederContentProps = SpeederContentProps & PropsFromState

const SpeederContent = ({ dateFrom, dateTo, exactDateFrom, exactDateTo, handleChange,
    handleSubmitGenerateSTKVTEIRaport, handleSubmitGenerateSTKVTHRaport, handleSubmitGenerateLIGFACTQRaport, handleSubmitGenerateVENTESQRaport,
    speederDateFrom, speederDateTo, speederExactDateFrom, speederExactDateTo,
    error, loading, LIGFATCQRaport, STKVTEIRaport, STKVTHRaport, VENTESQRaport }: ISpeederContentProps) => (
    <Fragment>
        <div className="SpeederHeader ">{'Raporty Speeder'}</div>
        <Grid className="SppederContainer" columns='three'>
            <DetailCard minHeight={15} maxHeight={'30px'} title="app.speeder.STKVTEI" id="STKVTEI" key="STKVTEI" width={16}>
                <Form className="DetailsContainer" onSubmit={handleSubmitGenerateSTKVTEIRaport}>
                    <div className="DescriptionContainer">
                        <label className="SpeederLabel">{'Raport STKVTEI'}</label>
                        <div className="SpeederDescription">{`Inicjalny transfer zawierający stan magazynu, sprzedaż (faktury) 
                    oraz zamówienia hurtowe z ostatnich 52 tygodni.`}</div>
                    </div>
                    <div className="ButtonContainer">
                        {(loading && STKVTEIRaport && !error) ? <Button className="LoaderButton" content="Wczytywanie" loading /> : <Button content="Zaplanuj" type="submit" />}
                    </div>
                </Form>
            </DetailCard>
            <DetailCard minHeight={15} maxHeight={'30px'} title="app.speeder.STKVTEH" id="STKVTEH" key="STKVTEH" width={16}>
                <Form className="DetailsContainer" onSubmit={handleSubmitGenerateSTKVTHRaport}>
                    <div className="DescriptionContainer">
                        <label className="SpeederLabel">{'Raport STKVTEH'}</label>
                        <div className="SpeederDescription">{`Inicjalny transfer zawierający stan magazynu,
                     sprzedaż (faktury) oraz zamówienia hurtowe z ostatnich 3 tygodni.`}</div>
                    </div>
                    <div className="ButtonContainer">
                        {(loading && STKVTHRaport && !error) ? <Button className="LoaderButton" content="Wczytywanie" loading /> : <Button content="Generuj" type="submit" />}
                    </div>
                </Form>
            </DetailCard>
            <DetailCard minHeight={15} maxHeight={'55px'} title="app.speeder.LIGFACTQ" id="LIGFACTQ" key="LIGFACTQ" width={16}>
                <Form className="DetailsContainer" onSubmit={handleSubmitGenerateLIGFACTQRaport}>
                    <div className="DescriptionContainerWithDatePickers">
                        <label className="SpeederLabel">{'Raport LIGFACTQ'}</label>
                        <div className="SpeederDescription">{'Linie faktur ze wskazanego przedziału czasu.'}</div>
                    </div>
                    <div className="DatePickerContainerLIGFACTQ">
                        <SemanticDatepicker
                            autoComplete="off"
                            className="SpeederInput"
                            clearIcon="close"
                            clearable
                            format="YYYY-MM-DD"
                            label="Data od:"
                            locale="pl-PL"
                            id="initialDate"
                            name='dateFrom'
                            maxDate={speederDateTo || dateTo || new Date(new Date().setUTCHours(23, 59, 59, 999) + new Date().getTimezoneOffset() * 60000)}
                            onChange={handleChange}
                            placeholder="RRRR-MM-DD"
                            pointing="top left"
                            value={speederDateFrom || dateFrom}
                        />
                        <SemanticDatepicker
                            autoComplete="off"
                            className="SpeederInput"
                            clearIcon="close"
                            clearable
                            format="YYYY-MM-DD"
                            label="Data do:"
                            locale="pl-PL"
                            id="finalDate"
                            maxDate={new Date(new Date().setUTCHours(23, 59, 59, 999) + new Date().getTimezoneOffset() * 60000)}
                            minDate={speederDateFrom || dateFrom || undefined}
                            name='dateTo'
                            onChange={handleChange}
                            placeholder="RRRR-MM-DD"
                            pointing="top left"
                            value={speederDateTo || dateTo}
                        />
                    </div>
                    <div className="ButtonContainerWhenDatePickerExistLIGFACTQ">
                        {(loading && LIGFATCQRaport && !error)
                            ? <Button className="LoaderButton" content="Wczytywanie" loading />
                            : <Button content="Zaplanuj" disabled={!dateFrom || !dateTo || (dateFrom && dateTo && (dateFrom > new Date() || (dateTo > new Date())))} type="submit" />}
                    </div>
                </Form>
            </DetailCard>
            <DetailCard minHeight={15} title="app.speeder.VENTESQ" id="VENTESQ" key="VENTESQ" width={16}>
                <Form className="DetailsContainer" onSubmit={handleSubmitGenerateVENTESQRaport}>
                    <div className="DescriptionContainerWithDatePickers">
                        <label className="SpeederLabel">{'Raport VENTESQ'}</label>
                        <div className="SpeederDescription">
                            <div>{`Dzienna sprzedaż. Transfer generowany na podstawie wskazanej daty, która musi być wcześniejsza niż  dzień dzisiejszy.`}
                                {`Jeśli wybrany zostanie piątek, dołączone zostaną także transfery z następującego po nim weekendu.`}</div>
                        </div>
                    </div>
                    <div className="DatePickerContainerVENTESQ">
                        <SemanticDatepicker
                            autoComplete="off"
                            className="SpeederInput"
                            clearIcon="close"
                            clearable
                            format="YYYY-MM-DD"
                            label="Data od:"
                            locale="pl-PL"
                            id="exactDateFrom"
                            name='exactDateFrom'
                            maxDate={speederExactDateTo || exactDateTo || new Date(new Date().setUTCHours(23, 59, 59, 999) + new Date().getTimezoneOffset() * 60000)}
                            onChange={handleChange}
                            placeholder="RRRR-MM-DD"
                            pointing="top left"
                            value={speederExactDateFrom || exactDateFrom}
                        />
                        <SemanticDatepicker
                            autoComplete="off"
                            className="SpeederInput"
                            clearIcon="close"
                            clearable
                            format="YYYY-MM-DD"
                            label="Data do:"
                            locale="pl-PL"
                            id="exactDateTo"
                            maxDate={new Date(new Date().setUTCHours(23, 59, 59, 999) + new Date().getTimezoneOffset() * 60000)}
                            minDate={speederExactDateFrom || exactDateFrom || undefined}
                            name='exactDateTo'
                            onChange={handleChange}
                            placeholder="RRRR-MM-DD"
                            pointing="top left"
                            value={speederExactDateTo || exactDateTo}
                        />
                    </div>
                    <div className="ButtonContainerWhenDatePickerExistVENTESQ">
                        {(loading && VENTESQRaport && !error)
                            ? <Button className="LoaderButton" content="Wczytywanie" loading />
                            : <Button content="Generuj" disabled={!exactDateFrom || !exactDateTo || (exactDateFrom && exactDateTo && (exactDateFrom > new Date() || (exactDateTo > new Date())))} type="submit" />}
                    </div>
                </Form>
            </DetailCard>
            <DetailCard minHeight={15} maxHeight={'36px'} title="app.speeder.STOCKMQ" id="STOCKMQ" key="STOCKMQ" width={16}>
                <div className="DetailsContainer">
                    <div className="DescriptionContainer">
                        <label className="SpeederLabel">{'Raport STOCKMQ'}</label>
                        <div className="SpeederDescription">
                            {`Stan magazynu oraz ilość na zamówieniach hurtowych (dla części magazynowanych).Plik generowany tylko jeśli ilości zmieniły się od ostatniego transferu.`}
                        </div>
                    </div>
                </div>
            </DetailCard>
        </Grid>
    </Fragment>
)

const mapStateToProps = ({ speeder }: ApplicationState) => ({
    error: speeder.error,
    loading: speeder.loading,
    speederDateFrom: speeder.dateFrom,
    speederDateTo: speeder.dateTo,
    speederExactDateFrom: speeder.exactDateFrom,
    speederExactDateTo: speeder.exactDateTo
});

export default memo(connect(mapStateToProps, null)(SpeederContent));
