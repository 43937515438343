import React from 'react';
import { Icon } from 'semantic-ui-react';
import { PageMainHeaderProps } from '~/pages/Orders/lib/Add/constants';
import PageHeader from '~/components/PageHeader/PageHeader';

const PageMainHeader = ({
  handleCancel,
  creating,
  summary,
  errorMessage,
  handleSummaryOrder,
  handleBack,
  handlePlaceOrder,
}: PageMainHeaderProps) => (
  <PageHeader
    loading={false}
    title="Utwórz zamówienie"
    buttons={[
      { content: 'Anuluj', icon: 'cancel', onClick: handleCancel },
      {
        content: 'Podsumuj zamówienie',
        icon: 'checkmark',
        primary: true,
        loading: creating,
        disabled: !!errorMessage || creating,
        onClick: handleSummaryOrder,
        visible: !summary,
        popup: errorMessage,
      },
      {
        content: 'Wróć',
        icon: 'arrow left',
        onClick: handleBack,
        visible: summary,
      },
      {
        content: 'Złóż zamówienie',
        icon: 'checkmark',
        primary: true,
        loading: creating,
        disabled: creating,
        onClick: handlePlaceOrder,
        visible: summary,
      },
    ]}
    icon={
      <Icon.Group className="icon">
        <Icon name="shop" />
        <Icon corner name="add" />
      </Icon.Group>
    }
    breadcrumb={[{ text: 'Lista', link: '/orders' }, { text: 'Tworzenie zamówienia' }]}
  />
);

export default PageMainHeader;
