import { Table, Container } from "semantic-ui-react";

import React from "react";

const TableNoResults: React.FC = (): JSX.Element => {
  return (
    <Table.Row>
      <Table.Cell colSpan="14">
        <Container fluid textAlign="center">
          Brak elementów do wyświetlenia.
        </Container>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableNoResults;
