import React, { useCallback } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { isManualStatus } from '~/pages/Orders/Details/utils';
import { ApplicationState } from '~/store';
import { connect } from 'react-redux';
import { OrderStatusProps, TOrderStatusReduxState } from '~/pages/Orders/Details/lib/constants';
import { OrderStatusEnum } from '~/store/orders/types';

const OrderStatus = ({ orderIn, setModalOpen, setStatusToChange }: OrderStatusProps) => {
  const onClickDropdownItem = useCallback(
    (status: string) => {
      setModalOpen(true);
      setStatusToChange(status);
    },
    [setStatusToChange, setModalOpen]
  );

  const orderStatusOption = (status: OrderStatusEnum, color: string, text: string): JSX.Element => {
    return (
      <Dropdown.Item onClick={() => onClickDropdownItem(status)} style={{ color: color }}>
        {text}
      </Dropdown.Item>
    );
  };

  const isCancelableStatus = (): boolean => {
    if (orderIn) {
      return (
        [
          OrderStatusEnum.NEW.toString(),
          OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE.toString(),
          OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT.toString(),
          OrderStatusEnum.ACCEPTED.toString(),
        ].indexOf(orderIn.status.toLocaleUpperCase()) !== -1 && orderIn.cancelable
      );
    }
    return false;
  };

  return (
    <>
      {isManualStatus(orderIn) && orderStatusOption(OrderStatusEnum.ACCEPTED, 'green', 'Akceptacja zamówienia')}
      {isManualStatus(orderIn) && orderStatusOption(OrderStatusEnum.REJECTED, 'red', 'Odrzucenie zamówienia')}
      {isCancelableStatus() && orderStatusOption(OrderStatusEnum.CANCELLED, 'black', 'Anulowanie zamówienia')}
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TOrderStatusReduxState = ({ orders }: ApplicationState) => ({
  orderIn: orders.selected,
});

export default connect(mapStateToProps)(OrderStatus);
