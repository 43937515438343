import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useGoodsDispatchNotePostponedColumns = () => {
  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "status",
        dictionaryName: DictionaryName.gdnPostponedPartStatus,
        label: "Status",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "createTime",
        label: "Czas utworzenia",
        type: "date",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "issueTime",
        label: "Czas wystawienia",
        type: "date",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "gdnNumber",
        label: "WZ",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "deliveryType",
        dictionaryName: DictionaryName.orderDeliveryType,
        label: "Typ dostawy",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "supplierId",
        label: "Magazyn",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "recipientWarehouseId",
        label: "Punkt dostawy",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "referenceId",
        label: "ID referencji",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "quantity",
        label: "Ilość",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "partAdditionalInfo",
        label: "Komentarz do linii",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "gdnAdditionalInfo",
        label: "Komentarz",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderNumber",
        label: "Zamówienie",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "claimNumber",
        label: "Reklamancja",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "totalWeight",
        label: "Sumaryczna Waga [g]",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "totalVolume",
        label: "Sumaryczna Objętość [l]",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "weight",
        label: "Waga [g]",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "volume",
        label: "Objętość [l]",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getDateCell, getTextCell, getDictionaryCell]
  );

  return { columnsConfig };
};
