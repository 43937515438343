import { Table } from "semantic-ui-react";
import React, { useCallback } from "react";
import { parseDate, parseDateNoTime } from "~/utils/dateUtils";
import { parsePrice } from "~/utils/parsePrice";
import mapDictionary from "~/components/MapDictionary/mapDictionary";
import { Link } from "react-router-dom";
import { DictionaryItem, DictionaryName } from "~/store/dictionaries/types";
import { TColumn } from "~/components/Table/lib/types";

export function getValue(document: any, name: string): any {
  if (name.lastIndexOf(".") === -1) {
    return document[name];
  }
  const nameArray = name.split(".");
  const first = nameArray[0];
  const sec = nameArray[1];

  return document[first][sec];
}

export const useCellsRenderer = () => {
  const getDateCell = useCallback(
    (document: any, column: any, withTime: boolean): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name]
          ? withTime
            ? parseDate(document[column.name])
            : parseDateNoTime(document[column.name])
          : ""}
      </Table.Cell>
    ),
    []
  );

  const getAmountCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name] || document[column.name] === 0
          ? parsePrice(document[column.name].toFixed(2))
          : ""}
      </Table.Cell>
    ),
    []
  );

  const getTextCell = useCallback(
    (document: any, column: TColumn, text?: string | number): JSX.Element => (
      <Table.Cell
        key={column.name}
        width={column.width ? column.width : undefined}
      >
        {text || getValue(document, column.name)}
      </Table.Cell>
    ),
    []
  );

  const getDictionaryCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {getValue(document, column.name) !== null
          ? mapDictionary(
              String(getValue(document, column.name)),
              column.dictionary || [],
              true,
              column.noKey,
              column.noKey
            )
          : "-"}
      </Table.Cell>
    ),
    []
  );

  const getLinkCell = useCallback(
    (document: any, column: any, link: string, text?: string): JSX.Element => (
      <Table.Cell key={column.name}>
        <Link to={link}>{text || getValue(document, column.name)}</Link>
      </Table.Cell>
    ),
    []
  );

  const getPriceCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name} style={{ textAlign: "right" }}>
        {parsePrice(document[column.name])}
      </Table.Cell>
    ),
    []
  );

  const getCustomCell = useCallback(
    (customCellRenderer: () => JSX.Element): JSX.Element =>
      customCellRenderer(),
    []
  );

  return {
    getDateCell,
    getAmountCell,
    getTextCell,
    getDictionaryCell,
    getLinkCell,
    getPriceCell,
    getCustomCell,
  };
};

export interface IColumnExport {
  name: string;
  label: (name: string) => string;
  dictionary?: DictionaryItem[];
  dictionaryName?: DictionaryName;
  projection: boolean;
  hide?: boolean;
  disableSort?: boolean;
  type?: string;
  list?: string;
  width?: number | string;
  filteringBlocked?: boolean;
}
