import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi } from "~/utils/api";
import {
  fetchSuccess,
  fetchCount,
  fetchIndividualNotificationSuccess,
  fetchCountSuccess,
  fetchNotifications,
  fetchIndividualNotification,
  makeAllRead,
  makeRead,
  fetchLastsSuccess,
  fetchManualOrdersCount,
  fetchManualOrdersCountSuccess,
} from "./actions";
import { NotificationActionTypes, PaginableNotification } from "./types";
import { handleError } from "~/store/error/actions";

function* handleFetchNotifications(
  action: ReturnType<typeof fetchNotifications>
) {
  try {
    let filters = "";
    let sort = "";

    sort = sort === "" ? "&sort=time,DESC" : sort;
    action.payload.filters.forEach((filter: any) => {
      if (filter.name !== "dateFrom" && filter.name !== "dateTo") {
        filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
      }
    });

    const dateFromFilter = action.payload.filters.find(
      //@ts-ignore: filters any Type
      (x) => x.name === "dateFrom"
    );
    const dateToFilter = action.payload.filters.find(
      //@ts-ignore: filters any Type
      (x) => x.name === "dateTo"
    );

    if (dateFromFilter && dateToFilter) {
      filters += `&time=${dateFromFilter.value}::${dateToFilter.value}`;
    } else if (dateFromFilter) {
      filters += `&time=${dateFromFilter.value}::`;
    } else if (dateToFilter) {
      filters += `&time=::${dateToFilter.value}`;
    }

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn.replace(".", "_")},${
        action.payload.sortDirection
      }`;
    }
    const notifications: PaginableNotification = yield call(
      callApi,
      "get",
      `/notifications?page=${action.payload.page - 1}&size=${
        action.payload.size
      }${filters}${sort}`
    );

    yield put(
      action.payload.lasts
        ? fetchLastsSuccess(notifications)
        : fetchSuccess(notifications)
    );
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        true,
        "Wystąpił błąd podczas wczytywania wiadomości."
      )
    );
  }
}

function* handleFetchIndividualNotification(
  action: ReturnType<typeof fetchIndividualNotification>
) {
  try {
    const uniqueNotification: Response = yield call(
      callApi,
      "get",
      `/notifications/${action.payload}`
    );
    yield put(fetchIndividualNotificationSuccess(uniqueNotification));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Wystąpił błąd podczas odczytywania wiadomości."
      )
    );
  }
}

function* handleReadMessage(action: ReturnType<typeof makeRead>) {
  try {
    yield call(callApi, "post", `/notifications/${action.payload}`);
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Wystąpił błąd podczas odczytywania wiadomości."
      )
    );
  }
}

function* handleFetchCount(action: ReturnType<typeof fetchCount>) {
  try {
    const count: number = yield call(
      callApi,
      "get",
      `/notifications/unread/count`
    );

    yield put(fetchCountSuccess(count));
  } catch (error) {
    //yield put(handleError(error, false, "Błąd pobierania liczby wiadomości."))
  }
}

function* handleFetchManualOrdersCount(
  action: ReturnType<typeof fetchManualOrdersCount>
) {
  try {
    const count: number = yield call(
      callApi,
      "get",
      `/manual-orders/new/count`
    );
    yield put(fetchManualOrdersCountSuccess(count));
  } catch (error) {}
}

function* handleMakeAllRead(action: ReturnType<typeof makeAllRead>) {
  try {
    yield call(callApi, "post", "/notifications", action.payload);
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Błąd próby oznaczenia wszystkich wiadomości jako przeczytane."
      )
    );
  }
}

function* watchFetchNotifications() {
  yield takeEvery(
    NotificationActionTypes.FETCH_REQUEST,
    handleFetchNotifications
  );
}

function* watchFetchIndividualNotification() {
  yield takeEvery(
    NotificationActionTypes.FETCH_INDIVIDUAL_REQUEST,
    handleFetchIndividualNotification
  );
}

function* watchFetchLastsNotifications() {
  yield takeEvery(
    NotificationActionTypes.FETCH_LAST_REQUEST,
    handleFetchNotifications
  );
}

function* watchFetchCount() {
  yield takeEvery(NotificationActionTypes.FETCH_COUNT, handleFetchCount);
}

function* watchFetchManulaOrdersCount() {
  yield takeEvery(
    NotificationActionTypes.FETCH_MANUAL_ORDERS_COUNT,
    handleFetchManualOrdersCount
  );
}

function* watchMakeRead() {
  yield takeEvery(NotificationActionTypes.MAKE_READ, handleReadMessage);
}

function* watchMakeAllRead() {
  yield takeEvery(NotificationActionTypes.MAKE_ALL_READ, handleMakeAllRead);
}

function* notificationsSaga() {
  yield all([
    fork(watchFetchNotifications),
    fork(watchFetchIndividualNotification),
    fork(watchFetchCount),
    fork(watchMakeAllRead),
    fork(watchMakeRead),
    fork(watchFetchLastsNotifications),
    fork(watchFetchManulaOrdersCount),
  ]);
}

export default notificationsSaga;
