import React, { useCallback, useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import { MappedCodedPart } from "~/store/coded-parts/types";
import { TCodedPartsListProps } from "./constans";
import DetailsModal from "./lib/DetailsModal";
import ManualOrdersListDropdown from "~/pages/ManualOrders/components/ManualOrdersListDropdown";
import dictionariesStatic from "~/store/dictionaries/static";

const ManualOrdersList = ({
  parts,
  columns,
  isModalOpen,
  setIsModalOpen,
  loadingCompleteCodedPart,
  showDetailsId,
  ldcOrderId,
  completeCodedPart,
}: TCodedPartsListProps) => {
  const [selectedCodedPart, setSelectedCodedPart] = useState<
    MappedCodedPart | undefined
  >();
  const [modalWithProcess, setModalWithProcess] = useState(false);

  const openModal = useCallback(
    (codedPart: MappedCodedPart, processModal: boolean): void => {
      setSelectedCodedPart(codedPart);
      setModalWithProcess(processModal);
      setIsModalOpen(true);
    },
    [setSelectedCodedPart, setModalWithProcess, setIsModalOpen]
  );

  const handleCompleteCodedPart = useCallback(
    (params: { orderId: string; lineNr: string; CSPSOrderNr: string }) => {
      completeCodedPart(params);
      setIsModalOpen(false);
    },
    [completeCodedPart, setIsModalOpen]
  );

  useEffect(() => {
    if (showDetailsId) {
      const foundedPart = parts.find((item) => showDetailsId.includes(item.id));
      if (foundedPart) {
        openModal(foundedPart, false);
      }
    }
  }, [parts, showDetailsId, openModal]);

  const closeModal = (): void => {
    setIsModalOpen(false);
    setSelectedCodedPart(undefined);
  };

  return (
    <>
      {!parts?.length ? (
        <TableNoResults />
      ) : (
        parts.map((part) => (
          <Table.Row key={part.id + Math.random()}>
            {columns.map(
              (column) =>
                column.projection &&
                column.getCell &&
                column.getCell(part, column)
            )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <ManualOrdersListDropdown
                codedPart={part}
                openModal={openModal}
              />
            </Table.Cell>
          </Table.Row>
        ))
      )}
      {selectedCodedPart && (
        <DetailsModal
          selectedCodedPart={selectedCodedPart}
          isModalOpen={isModalOpen}
          modalWithProcess={modalWithProcess}
          closeModal={closeModal}
          statusDictionary={dictionariesStatic.CODED_PART_STATUS}
          loadingCompleteCodedPart={loadingCompleteCodedPart}
          ldcOrderId={ldcOrderId}
          handleCompleteCodedPart={handleCompleteCodedPart}
        />
      )}
    </>
  );
};

export default ManualOrdersList;
