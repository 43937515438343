import React from "react";
import { FormattedMessage } from "react-intl";
import PageHeader from "~/components/PageHeader/PageHeader";

type TProps = {};

const CancelRequests: React.FC<TProps> = () => {
  return (
    <PageHeader
      icon="window close outline"
      title="Prośby o anulowanie"
      breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
      buttons={[]}
      loading={false}
      refreshAction={() => {
        console.log("not implemented");
      }}
    ></PageHeader>
  );
};

export default CancelRequests;
