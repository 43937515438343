import { connect } from 'react-redux'
import { Button, Divider, Label } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { fetchDictionary } from '~/store/dictionaries/actions'
import { DictionariesState, DictionaryName } from '~/store/dictionaries/types'

import React, { useEffect, useState } from 'react'
import CustomerModal from './CustomerModal'
import { ClassAndBrands } from '~/store/customer/types'
import { fetchClassesAndBrands } from '~/store/customer/actions'
import translations from '~/utils/translations'

type TReduxState = {
    dictionaries: DictionariesState,
    classesAndBrands?: ClassAndBrands[],
    classesAndBrandsLoading: boolean
}

type TReduxActions = {
    fetchDictionary: typeof fetchDictionary,
    fetchClassesAndBrands: typeof fetchClassesAndBrands
}

export type TCustomerRuleMulti = {
    rrdiList: string[] | null
    classList: string[] | null
    brandList: string[] | null
}

type TProps = TReduxState & TReduxActions & {
    index: number
    rule: TCustomerRuleMulti
    editMode: boolean
    loading: boolean
    onEdit: (index: number, updated: TCustomerRuleMulti) => void
    onRemove: (index: number) => void
}

const CustomerItem: React.FC<TProps> = ({
    index,
    rule,
    editMode,
    loading,
    onEdit,
    onRemove,
    fetchDictionary,
    classesAndBrandsLoading,
    classesAndBrands,
    fetchClassesAndBrands
}) => {

    useEffect(() => {
        fetchDictionary(DictionaryName.customerCategoryOrganization)
    }, [fetchDictionary])

    const [rrdis, setRrdis] = useState<string[] | null>(rule.rrdiList )
    const [categoryType, setCategoryType] = useState<string[]>(typeof rule.classList === "string" || rule.classList === null ? [] : rule.classList)
    const [categoryOrganisation, setCategoryOrganisation] = useState<string[]>(typeof rule.brandList === "string" || rule.brandList === null ? [] : rule.brandList)

    useEffect(() => {
        const rrdisChanged = String(rrdis) !== String(rule.rrdiList)
        const categoryTypeChanged = categoryType !== rule.classList
        const categoryOrganisationChanged = categoryOrganisation !== rule.brandList
        if (rrdisChanged || categoryTypeChanged || categoryOrganisationChanged) {
            onEdit(index, { rrdiList: rrdis, classList: categoryType, brandList: categoryOrganisation })
        }

    }, [rrdis, categoryType, categoryOrganisation, index, onEdit, rule.rrdiList, rule.classList, rule.brandList])

    const renderDescription = (): JSX.Element => (
        <em>
            {rrdis && rrdis.length > 0 ? <>Kontrahenci {rrdis.map((rrdi) =>
                <Label key={rrdi} style={{ margin: 1 }}>{rrdi}</Label>
            )
            }</> : <>Wszyscy kontrahenci</>}
            {categoryType.length ? <>
                {categoryType.length > 1 ? " z klasami: " : " z klasą "} 
                <span style={{fontWeight: 700}}>{categoryType.map(item => translations.format(`app.customer-class.${item}`)).join(", ")}</span>
            </> : <> z dowolną klasą</>}
            {categoryType.length && categoryOrganisation ? <> 
                {categoryOrganisation.length > 1 ? " i obsługiwanymi markami: " : " i obsługiwaną marką "}
                <span style={{fontWeight: 700}}>{categoryOrganisation.map(item => translations.format(`app.customer-brand.${item}`)).join(", ")}.</span>
            </> : <> i dowolną obsługiwaną marką.</>}
        </em>
    )

    const handleSaveModal = (data: {classList: string [], brandList: string[], rrdiList: string[]}) => {
        setRrdis(data.rrdiList)
        setCategoryType(data.classList)
        setCategoryOrganisation(data.brandList)
    }

    return (
        <>
            <div key={index}  style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 10, gap: 10}}>
                {renderDescription()}
                {editMode && (
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2}}>
                        <CustomerModal 
                            triggerButton={
                                <Button circular basic compact icon="edit" primary disabled={loading}/>
                            }
                            categoryOrganisation={categoryOrganisation}
                            categoryType={categoryType}
                            ruleRrdis={rrdis}
                            handleSaveModal={handleSaveModal}
                            fetchClassesAndBrands={fetchClassesAndBrands}
                            classesAndBrands={classesAndBrands}
                            classesAndBrandsLoading={classesAndBrandsLoading}
                        />
                        <Button circular basic compact icon="delete"
                            disabled={loading}
                            onClick={() => { onRemove(index) }} 
                        />
                    </div>
                )}
            </div>
            <Divider />
        </>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ dictionaries, customers }: ApplicationState) => {
    return {
        dictionaries,
        classesAndBrands: customers.classesAndBrands,
        classesAndBrandsLoading: customers.classesAndBrandsLoading
    }
}

const mapDispatchToProps: TReduxActions = {
    fetchDictionary, fetchClassesAndBrands
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerItem)
