import React from 'react';
import { Divider, Label } from 'semantic-ui-react';
import ClassAndBrandsModal from '../../ClassAndBrandsModal';
import { Customer } from '~/store/customer/types';
import translations from '~/utils/translations';

interface Props {
  editMode: boolean;
  customer: Customer | undefined;
}

export const ClassAndBrandsCard: React.FC<Props> = ({ editMode, customer }) => {
  const renderTitle = (name: string): JSX.Element => <label style={{ fontWeight: 'bold' }}>{name}</label>;

  const renderLabel = (name: string, isBrand?: boolean): JSX.Element => (
    <Label key={Math.random()} style={{ marginTop: 10 }}>
      {translations.format(isBrand ? `app.customer-class.${name}` : `app.customer-brand.${name}`)}
    </Label>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {renderTitle('Klasy')}
      <div>
        {customer && customer.customerClasses && customer.customerClasses.length
          ? customer.customerClasses.map((item) => renderLabel(item, true))
          : renderLabel('-')}
      </div>
      <Divider></Divider>
      {renderTitle('Marki')}
      <div>
        {customer && customer.customerBrands && customer.customerBrands.length
          ? customer.customerBrands.map((item) => renderLabel(item.brand))
          : renderLabel('-')}
      </div>
      {editMode && (
        <div style={{ marginTop: 10, textAlign: 'end' }}>
          <ClassAndBrandsModal loading={false} customer={customer} />
        </div>
      )}
    </div>
  );
};
