import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Divider, Form, Grid, Header, Icon, Input, Label, Message, Segment } from "semantic-ui-react"
import DetailCard from "~/components/DetailCard/DetailCard"
import { THeaderButton } from "~/components/PageHeader/lib/types"
import PageHeader from "~/components/PageHeader/PageHeader"
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions"
import { DATE, DATE_MAX, DATE_MIN, REQUIRED } from "~/components/SmartField/lib/validator"
import SmartWrapper from "~/components/SmartField/SmartWrapper"
import dictionariesStatic from "~/store/dictionaries/static"
import { importPromotion, promoCollisionViewClosed, redirectToPromoViewListFinished } from "~/store/promotions/actions"
import CustomersPromo from './lib/triggers/Customers'
import { isPromotionImportValid } from "./lib/Validator"
import PreviewImage from "~/components/PreviewImage/PreviewImage"
import ActualPromoPreview from "~/assets/images/examples/actual-promo-preview.png"
import GroupPromoPreview from "~/assets/images/examples/group-promo-preview.png"
import AssociativePromoPreview from "~/assets/images/examples/associative-promo-preview.png"
import CascadePromoPreview from "~/assets/images/examples/cascade-promo-preview.png"
import CurrencyPromoPreview from "~/assets/images/examples/currency-promo-preview.png"
import { ApplicationState } from "~/store";
import { usePromoCollisionModal } from "~/pages/Promotions/Details/lib/triggers/Modals";
import { TPromotion } from "~/store/promotions/types";

const promoPreviewMapByType: { [key: string]: string } = {
    "ACTUAL": ActualPromoPreview,
    "GROUP": GroupPromoPreview,
    "ASSOCIATIVE": AssociativePromoPreview,
    "CASCADE": CascadePromoPreview,
    "CURRENCY": CurrencyPromoPreview
}

const INITIAL_STATE: TPromotion = {
    dateFrom: '',
    dateTo: '',
    type: 'CASCADE',
    isolatedCollection: false,
    isolatedResultCollection: false,
    bonusASO: false,
    code: "",
    status: "DRAFT",
    priority: 1,
    ruleSet: {
        customerRuleList: [],
        referenceRule: {
            references: []
        }
    }
}

const PromotionImport = () => {
    const dispatch = useDispatch()
    const [file, setFile] = useState<File>()
    const [
        promotion,
        setPromotion
    ] = useState(INITIAL_STATE)

    let history = useHistory();

    const {
        importedList,
        importing,
        activationFailed,
        collisionInfo,
        redirectedToPromoViewList
    } = useSelector((store: ApplicationState) => store.promotions)

    const dispatchAction = useDispatch()

    const promoCollisionModal = usePromoCollisionModal(activationFailed, collisionInfo ? collisionInfo.additionalData : [], () => {
        dispatchAction(promoCollisionViewClosed())
    })

    const handleFileChange = useMemo(() => (ev: any) => {
        setFile(ev.target.files[0])
    }, [])

    const importPromotions = useMemo(() => () => {
        const promotionData = {
            ...promotion,
            customerRuleList: promotion.ruleSet.customerRuleList,
            ruleSet: undefined

        }
        if (file) {
            dispatch(importPromotion(file, promotionData))
        }
        // eslint-disable-next-line
    }, [file, dispatch, promotion, history, importedList, importing, collisionInfo, activationFailed])

    useEffect(() => {
        if (!activationFailed && importedList && importedList.length !== 0 && !collisionInfo && !redirectedToPromoViewList) {
            history.push('/promotion/import-promotion-list')
            dispatch(redirectToPromoViewListFinished())
        }
    }, [importedList, promotion, activationFailed, collisionInfo, dispatch, history, redirectedToPromoViewList])

    const handleBeforeSave = (data: any) => {
        if (promotion && promotion.type !== data.type) {
            data.firstDiscount = undefined
            data.secondDiscount = undefined
            data.associativeDiscountGroup = data.type === 'ASSOCIATIVE' ? 'ALL' : undefined
        }
        setPromotion(data)
        return data
    }

    const isPromoValid = useCallback((): boolean => {
        return promotion ? isPromotionImportValid(promotion, file) : false
    }, [promotion, file]);

    const { renderField, renderSelect, renderToggle, renderTextArea } = useRenderingFunctions(promotion, true)

    const cards = useMemo(() => {
        return promotion ? <>
            <DetailCard title="app.promo.scope.of.adaptation" id="date" key="date" minHeight={100} width={5.33}>
                <Form.Group widths="equal">
                    {renderField("dateFrom", { label: "Data rozpoczęcia", type: 'date', validators: [REQUIRED, DATE, DATE_MAX('dateTo', "Data rozpoczęcia promocji nie może być późniejsza niż jej data zakończenia.")] })}
                    {renderField("dateTo", { label: "Data zakończenia", type: 'date', validators: [REQUIRED, DATE, DATE_MIN('dateFrom', "Data zakończenia promocji nie moze być mniejsza niż jej data rozpoczęcia.")] })}
                </Form.Group>
                <Divider />
                {renderToggle("bonusASO", { label: "Bonus ASO", description: "Flaga dla raportu „Kwartalne wyniki sprzedaży”. Określa, czy produkty sprzedawane w ramach promocji, mają być uwzględniane przy liczeniu „premii ASO”." })}
                <Divider />
                {renderTextArea("description", { label: "Opis", rows: 4, style: { marginBottom: 15 } })}
            </DetailCard>
            <DetailCard title="app.promo.type" id="type" key="type" minHeight={100} width={5.33}>
                {renderSelect("type", dictionariesStatic.PROMO_TYPE, { label: "Typ promocji" })}
                {!promotion.type && <div style={{ textAlign: 'center' }}>
                    <Label pointing="above" color="orange" style={{ marginTop: 0 }}><Icon name="info circle" />Wybierz typ promocji, aby przejść dalej.</Label>
                </div>}
                {promotion.type && <Label pointing="above" style={{ marginTop: 0, lineHeight: '18px' }}>
                    {promotion.type === 'CASCADE' && "Nadaje dodatkowy względem cennika upust procentowy na cenę jednostkową produktu. Można zdefiniować dwa progi upustu. Całkowity upust zostanie naliczony kaskadowo: najpierw upust cennikowy, następnie upust progu pierwszego i na końcu upust progu drugiego."}
                    {promotion.type === 'ACTUAL' && "Nadaje upust procentowy lub kwotowy, który zastąpi upust cennikowy. Można zdefiniować dwa progi upustu. Podczas składania zamówienia uwzględniony będzie próg dający większy upust."}
                    {promotion.type === 'CURRENCY' && "Zastępuje cenę produktu ceną promocyjną w zależności od liczby kupowanych sztuk lub wartości pozycji w zamówieniu."}
                    {promotion.type === 'ASSOCIATIVE' && "Nadaje upust procentowy na wybrane produkty w zależności od innych produktów znajdujących się w koszyku. Upust może zostać nałożony na obydwie lub jedną z poniżej zdefiniowanych grup. Warunkiem zadziałania promocji jest obecność w zamówieniu produktów z obydwu grup w wybranej liczności."}
                    {promotion.type === 'GROUP' && "Nadaje wybrany upust na produkty wskazane w drugiej grupie. Aby promocja została zastosowana w zamówieniu musi znaleźć się odpowiednia ilość lub odpowiednia wartość produktów z grupy pierwszej."}
                </Label>}

                { !['ASSOCIATIVE', 'GROUP'].includes(promotion.type) && <>
                    <Divider />
                    {renderToggle("isolatedCollection", { label: "Zbiór izolowany" })}
                    <Label pointing="above" style={{ lineHeight: '18px', marginTop: 0 }}>
                        Określa czy warunki zdefiniowane dla poszczególnych progów dotyczą wszystkich produktów znajdujących się w koszyku czy każdy produkt będzie rozpatrywany osobno.
                    </Label>
                </>}
                { ['GROUP'].includes(promotion.type) && <>
                    <Divider />
                    {renderToggle("isolatedCollection", { label: "Zbiór izolowany (grupa pierwsza)" })}
                    {renderToggle("isolatedResultCollection", { label: " Zbiór izolowany (grupa druga)" })}
                    <Label pointing="above" style={{ lineHeight: '18px', marginTop: 0 }}>
                        Określa czy warunki zdefiniowane dla poszczególnych progów dotyczą wszystkich produktów znajdujących się w koszyku czy każdy produkt będzie rozpatrywany osobno.
                    </Label>
                </>}
            </DetailCard>
            <DetailCard title="app.customers" id="customers" key="customers" minHeight={0} width={5.33} smallWidth={16}>
                <CustomersPromo editMode={true} promotion={promotion} />
            </DetailCard>
            <DetailCard title="app.button.load" id="import" key="import" minHeight={0} width={16}>
                <Message>
                    <Message.Content>
                        Załaduj plik XLSX, XLS, CSV według wzorcowego formatu.<br />
                        Po wczytaniu pliku rekordy zostaną dodane do zamówienia.<br />
                        <span style={{ color: 'gray', fontSize: '10px' }}>*Maksymalny rozmiar pliku 15MB.</span>
                    </Message.Content>
                </Message>
                <PreviewImage src={promoPreviewMapByType[promotion.type]}/>
                <Segment placeholder>
                    <Header icon>
                        <Icon name="file archive outline" />
                        {file ? file.name : "Załaduj plik"}
                    </Header>
                    <Input type="file" onChange={handleFileChange} />
                </Segment>
            </DetailCard>
        </> : null
    }, [promotion, renderField, renderSelect, renderTextArea, renderToggle, handleFileChange, file])

    const cardsRendered: JSX.Element = useMemo(() => {
        return (
            <div className="uber-content">
                <Grid stretched>
                    {cards}
                </Grid>
            </div>
        )
    }, [cards])


    const headerButtons: THeaderButton[] = useMemo(() => {
        return [
            {
                icon: 'edit',
                content: "importuj promocje",
                onClick: importPromotions,
                popup: isPromoValid() ? "Po importowaniu nie będzie można już dokonać zmian." : "Obecna definicja importowanych promocji jest niekompletna i nie pozwala na ich importowanie.",
                disabled: !isPromoValid(),
                primary: true
            }
        ]
        // eslint-disable-next-line
    }, [importPromotions, isPromoValid, promotion])

    return <>
        <PageHeader
            icon='star outline'
            title={<><FormattedMessage id="app.promotion" /></>}
            breadcrumb={[
                { text: <FormattedMessage id="app.list" />, link: "/promotions" },
                { text: <FormattedMessage id="app.promo.ImportPageTitle" /> }
            ]}
            buttons={headerButtons}
            loading={false}
        />
        {promoCollisionModal}
        <SmartWrapper
            method="POST"
            model={promotion}
            onBeforeSave={handleBeforeSave}
        >
            {cardsRendered}
        </SmartWrapper>


    </>
}

export default PromotionImport