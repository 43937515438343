import { ClaimCategory } from "~/pages/Claims/lib/claimTypes";
import { TClaimLineColumn } from "../Lines";

export enum COLUMN_NAME {
  lineNumber = "lineNumber",
  referenceId = "referenceId",
  referenceName = "referenceName",
  toReceiveQuantity = "toReceiveQuantity",
  receivedQuantityFullValue = "receivedQuantityFullValue",
  receivedQuantityDefective = "receivedQuantityDefective",
  claimedQuantity = "claimedQuantity",
  statusNotes = "statusNotes",
  select = "select",
  acceptedQuantity = "acceptedQuantity",
  requestReturnQuantity = "requestReturnQuantity",
  rejectQuantity = "rejectQuantity",
  orderNumber = "orderNumber",
  status = "status",
  wmsComment = "wmsComment",
  statusDate = "statusDate",
  packageNumber = "packageNumber",
  acceptedQuantityByWarehouse = "acceptedQuantityByWarehouse",
  acceptedQuantityByTransport = "acceptedQuantityByTransport",
  acceptedQuantityByDistrigo = "acceptedQuantityByDistrigo",
}

export const verificationColumns: TClaimLineColumn[] = [
  { name: COLUMN_NAME.lineNumber, label: "Nr", width: 50 },
  { name: COLUMN_NAME.referenceId, label: "Referencja" },
  { name: COLUMN_NAME.referenceName, label: "Nazwa referencji" },
  {
    name: COLUMN_NAME.claimedQuantity,
    label: "Ilość reklamowana",
    width: 50,
  },
  {
    name: COLUMN_NAME.acceptedQuantity,
    label: "Ilość sztuk do akceptacji",
    width: 50,
  },
  {
    name: COLUMN_NAME.requestReturnQuantity,
    label: "Do zwrotu",
    width: 50,
  },
  {
    name: COLUMN_NAME.rejectQuantity,
    label: "Do odrzucenia",
    width: 50,
  },
  // { name: COLUMN_NAME.statusNotes, label: "Komentarz" },
  { name: COLUMN_NAME.select, label: "Edycja" },
];

export const decisionColumns: TClaimLineColumn[] = [
  { name: COLUMN_NAME.lineNumber, label: "Nr", width: 50 },
  { name: COLUMN_NAME.referenceId, label: "Referencja" },
  { name: COLUMN_NAME.referenceName, label: "Nazwa referencji" },
  { name: COLUMN_NAME.toReceiveQuantity, label: "Ilość do zwrotu", width: 50 },
  {
    name: COLUMN_NAME.receivedQuantityFullValue,
    label: "Ilość zwrócona pełnowartościowa",
    width: 50,
  },
  {
    name: COLUMN_NAME.receivedQuantityDefective,
    label: "Ilość zwrócona niepełnowartościowa ",
    width: 50,
  },
  {
    name: COLUMN_NAME.claimedQuantity,
    label: "Ilość sztuk reklamowana",
    width: 50,
  },
  { name: COLUMN_NAME.statusNotes, label: "Komentarz" },
  { name: COLUMN_NAME.select, label: "Edycja" },
];

export const editFormColumns: TClaimLineColumn[] = [
  { name: "akcja", label: "Akcja" },
  { name: "costCoveringGroup", label: "Zobowiązany" },
  { name: "claimedQuantity", label: "Ilość reklamowana", width: 50 },
];

export enum ClaimTransitionAction {
  VERIFICATION = "VERIFICATION",
  DECISION = "DECISION",
  REFUND = "REFUND",
  RETURN_TO_SUPPLIER = "RETURN_TO_SUPPLIER",
  MARK_CLOSED_WITH_STOCKTAKING_REQUEST = "MARK_CLOSED_WITH_STOCKTAKING_REQUEST",
  MARK_CLOSED_WITH_TRANSPORTATION_FEE = "MARK_CLOSED_WITH_TRANSPORTATION_FEE",
  MARK_CLOSED = "MARK_CLOSED",
  ACCEPTED = "ACCEPTED",
}
export enum FormCategory {
  RECEIVED_DEFECTIVE = "RECEIVED_DEFECTIVE",
  RECEIVED_FULL_VALUE = "RECEIVED_FULL_VALUE",
}

export enum ClaimTransitionLineActionType {
  VERIFICATION_ACCEPTED = "VERIFICATION_ACCEPTED",
  VERIFICATION_REQUEST_RETURN = "VERIFICATION_REQUEST_RETURN",
  VERIFICATION_REJECTED = "VERIFICATION_REJECTED",
  DECISION_ACCEPTED = "DECISION_ACCEPTED",
  DECISION_ACCEPTED_WITHOUT_CORRECTION = "DECISION_ACCEPTED_WITHOUT_CORRECTION",
  DECISION_TO_DESTROY = "DECISION_TO_DESTROY",
  DECISION_TO_DESTROY_WITHOUT_CORRECTION = "DECISION_TO_DESTROY_WITHOUT_CORRECTION",
  DECISION_REJECTED_TO_CLIENT = "DECISION_REJECTED_TO_CLIENT",
}

export const actionTypes = {
  VERIFICATION_ACCEPTED: "Akceptuj z korektą",
  VERIFICATION_ACCEPTED_NO_INVOICE: "Akceptuj",
  VERIFICATION_ACCEPTED_T_M: "Akceptuj",
  VERIFICATION_REQUEST_RETURN: "Do zwrotu",
  VERIFICATION_REJECTED: "Odrzuć",
  DECISION_ACCEPTED: "Akceptuj z korektą",
  DECISION_ACCEPTED_NO_INVOICE: "Akceptuj",
  DECISION_ACCEPTED_WITHOUT_CORRECTION: "Akceptuj bez korekty",
  DECISION_TO_DESTROY: "Zniszcz z korektą",
  DECISION_TO_DESTROY_WITHOUT_CORRECTION: "Zniszcz bez korekty",
  DECISION_REJECTED_TO_CLIENT: "Odeślij do klienta",
};

export const optionsVerification = [
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    text: actionTypes.DECISION_ACCEPTED,
  },

  {
    key: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
    value: ClaimTransitionLineActionType.VERIFICATION_REQUEST_RETURN,
    text: actionTypes.VERIFICATION_REQUEST_RETURN,
  },

  {
    key: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
    value: ClaimTransitionLineActionType.VERIFICATION_REJECTED,
    text: actionTypes.VERIFICATION_REJECTED,
  },
];

export const optionsDecision = [
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    text: actionTypes.DECISION_ACCEPTED,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED,
    text: actionTypes.DECISION_ACCEPTED,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
    value: ClaimTransitionLineActionType.DECISION_ACCEPTED_WITHOUT_CORRECTION,
    text: actionTypes.DECISION_ACCEPTED_WITHOUT_CORRECTION,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
    value: ClaimTransitionLineActionType.DECISION_TO_DESTROY,
    text: actionTypes.DECISION_TO_DESTROY,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
    value: ClaimTransitionLineActionType.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
    text: actionTypes.DECISION_TO_DESTROY_WITHOUT_CORRECTION,
  },
  {
    key: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
    value: ClaimTransitionLineActionType.DECISION_REJECTED_TO_CLIENT,
    text: actionTypes.DECISION_REJECTED_TO_CLIENT,
  },
];

const ALL_ALLOWED = [
  ClaimCategory.quantityClaim,
  ClaimCategory.qualityClaim,
  ClaimCategory.transportClaim,
  ClaimCategory.newPartReturn,
  ClaimCategory.surplusWithoutReturn,
  ClaimCategory.surplusWithReturn,
  ClaimCategory.depositReturn,
  ClaimCategory.tractionBatteryReturn,
  ClaimCategory.coreWithoutInvoice,
  ClaimCategory.batteryWithoutInvoice,
];

const allExcept = (...items: string[]) => {
  let newArray = [...ALL_ALLOWED];
  items.forEach((item) => {
    newArray = newArray.filter((f) => f !== item);
  });
  return newArray;
};

export const claimCategoriesToDisplayInputs: { [key: string]: any } = {
  VERIFICATION: {
    VERIFICATION_ACCEPTED: allExcept(
      ClaimCategory.surplusWithReturn,
      ClaimCategory.newPartReturn
    ),
    VERIFICATION_REQUEST_RETURN: allExcept(
      ClaimCategory.quantityClaim,
      ClaimCategory.surplusWithoutReturn
    ),
    VERIFICATION_REJECTED: allExcept(ClaimCategory.newPartReturn),
  },

  RECEIVED_FULL_VALUE: {
    DECISION_ACCEPTED: ALL_ALLOWED,
    // DECISION_ACCEPTED_WITHOUT_CORRECTION: allExcept(
    //   ClaimCategory.newPartReturn,
    //   ClaimCategory.transportClaim,
    //   ClaimCategory.qualityClaim
    // ),
    DECISION_ACCEPTED_WITHOUT_CORRECTION: ALL_ALLOWED,
    DECISION_TO_DESTROY: [],
    DECISION_TO_DESTROY_WITHOUT_CORRECTION: [],
    DECISION_REJECTED_TO_CLIENT: allExcept(
      ClaimCategory.newPartReturn,
      ClaimCategory.transportClaim,
      ClaimCategory.qualityClaim
    ),
  },

  RECEIVED_DEFECTIVE: {
    DECISION_ACCEPTED: [],
    DECISION_ACCEPTED_WITHOUT_CORRECTION: allExcept(
      ClaimCategory.newPartReturn,
      ClaimCategory.transportClaim,
      ClaimCategory.qualityClaim
    ),
    DECISION_TO_DESTROY: [
      ClaimCategory.newPartReturn,
      ClaimCategory.transportClaim,
      ClaimCategory.qualityClaim,
    ],
    // DECISION_TO_DESTROY_WITHOUT_CORRECTION: [ClaimCategory.newPartReturn],
    DECISION_TO_DESTROY_WITHOUT_CORRECTION: ALL_ALLOWED,
    DECISION_REJECTED_TO_CLIENT: ALL_ALLOWED,
  },
};
