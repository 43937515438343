import { TModelFormProps } from "~/components/ModelForm/lib/types";
import React from "react";
import { FormContext } from "~/components/ModelForm/lib/form.config";
import { useFormWrapper } from "~/components/ModelForm/hooks/useFormWrapper";

export const ModelFormWrapper = <T, >(props: TModelFormProps<T>) => {
    const {context} = useFormWrapper(props);

    return (
        <FormContext.Provider value={context}>
            {props.children}
        </FormContext.Provider>
    )
}