import { action } from "typesafe-actions";
import {
  PaymentMethodActionTypes,
  CustomerPaymentMethod,
  CustomerPaymentMethodDTO,
} from "./types";

export const fetchPaymentMethods = (customerId: string) =>
  action(PaymentMethodActionTypes.FETCH_PAYMENT_METHODS, { customerId });
export const fetchPaymentMethodsSuccess = (
  customerPaymentMethods: CustomerPaymentMethod[]
) =>
  action(PaymentMethodActionTypes.FETCH_PAYMENT_METHODS_SUCCESS, {
    customerPaymentMethods,
  });

export const fetchPaymentMethodById = (
  customerId: string,
  paymentMethodId: string
) =>
  action(PaymentMethodActionTypes.FETCH_PAYMENT_METHOD_BY_ID, {
    customerId,
    paymentMethodId,
  });
export const fetchPaymentMethodByIdSuccess = (
  customerPaymentMethod: CustomerPaymentMethod
) =>
  action(
    PaymentMethodActionTypes.FETCH_PAYMENT_METHOD_BY_ID_SUCCESS,
    customerPaymentMethod
  );

export const fetchCurrentPaymentMethod = (customerId: string) =>
  action(PaymentMethodActionTypes.FETCH_CURRENT_PAYMENT_METHOD, { customerId });
export const fetchCurrentPaymentMethodSuccess = (
  customerPaymentMethod: CustomerPaymentMethod
) =>
  action(PaymentMethodActionTypes.FETCH_CURRENT_PAYMENT_METHOD_SUCCESS, {
    customerPaymentMethod,
  });

export const createPaymentMethod = (
  customerId: string,
  customerPaymentMethodDto: CustomerPaymentMethodDTO,
  refreshAction: () => void
) =>
  action(PaymentMethodActionTypes.CREATE_PAYMENT_METHOD, {
    customerId,
    customerPaymentMethodDto,
    refreshAction,
  });
export const createPaymentMethodSuccess = (
  createdPaymentMethod: CustomerPaymentMethod
) =>
  action(PaymentMethodActionTypes.CREATE_PAYMENT_METHOD_SUCCESS, {
    createdPaymentMethod,
  });

export const cancelPaymentMethod = (
  customerId: string,
  paymentMethodId: string
) =>
  action(PaymentMethodActionTypes.CANCEL_PAYMENT_METHOD, {
    customerId,
    paymentMethodId,
  });
export const cancelPaymentMethodSuccess = () =>
  action(PaymentMethodActionTypes.CANCEL_PAYMENT_METHOD_SUCCESS);
