import { Checkbox, Dropdown, Icon, Loader } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { SmartFieldContext } from "~/components/SmartField/lib/smartField.config";
import { Customer } from "~/store/customer/types";
import { toastError, toastInfo } from "~/utils/toast";

import React, { useContext, useEffect, useState } from "react";

type TProps = {
  icon: SemanticICONS;
  label: string | JSX.Element;
  name: keyof Customer;
  checked: boolean;
  onMessage: string;
  offMessage: string;
  errorMessage: string;
  disabled?: boolean;
};

const DropdownToggle: React.FC<TProps> = ({
  icon,
  label,
  name,
  checked,
  onMessage,
  offMessage,
  errorMessage,
  disabled,
}) => {
  const [value, setValue] = useState(checked);
  const [loading, setLoading] = useState(false);
  const { callPatch } = useContext(SmartFieldContext);

  useEffect(() => setValue(checked), [checked]);

  const toggle = (): void => {
    if (loading || disabled) {
      return;
    }

    const newValue = !value;
    setValue(newValue);
    setLoading(true);
    callPatch(name, newValue)
      .then(() => {
        toastInfo("app.info.common.update", newValue ? onMessage : offMessage);
      })
      .catch(() => {
        toastError("app.error.common.update", errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIcon = (): JSX.Element => {
    if (loading) {
      return (
        <Icon size="large">
          <Loader active inline size="tiny" />
        </Icon>
      );
    } else {
      return <Icon name={icon} size="large" />;
    }
  };

  return (
    <Dropdown.Item
      onClick={toggle}
      className="block-menu-item"
      disabled={disabled}
    >
      {getIcon()}
      <Checkbox label={<label>{label}</label>} toggle checked={value} />
    </Dropdown.Item>
  );
};

export default DropdownToggle;
