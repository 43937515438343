import { connect } from 'react-redux'
import { handleError } from '~/store/error/actions'
import { callApi } from '~/utils/api'
import React, { useRef } from 'react'
import { TProps } from "~/components/SmartField/lib/types";
import { SmartFieldContext } from './lib/smartField.config';
const _ = require('lodash');

/**
 * Wrapper for fields that handles API patch calls.
 */
const SmartWrapper: React.FC<TProps> = ({
    endpoint,
    model = {},
    onBeforeSave = (v) => v,
    onUpdate = () => { },
    method = 'PATCH',
    children,
    handleError,
    customErrorHandler
}) => {
    const latestModel = useRef(model)
    latestModel.current = model

    const callPatch = async (name: string, value: any): Promise<any> => {
        const mainProperty = name.indexOf('.') !== -1 ? name.split('.')[0] : name
        _.set(latestModel.current, name, value);
        let data: any = method === 'PATCH' ? { [mainProperty]: value } : { ...latestModel.current }
 
        try {
            data = onBeforeSave({ ...data }, { name, value, latestModel: latestModel.current })
            if (data && endpoint) {
                const res = await callApi(method, endpoint, data)
                onUpdate(latestModel.current, res)
                return res
            }
        } catch (error) {
            if (customErrorHandler) {
                customErrorHandler(latestModel, error.status)
            }
            else {
                handleError(error, false, "Błąd zapisywania zmian")
                throw error
            }
        }
    }

    return (
        <SmartFieldContext.Provider value={{
            callPatch,
            model
        }}>
            {children}
        </SmartFieldContext.Provider>
    )
}

export default connect(() => ({}), { handleError })(SmartWrapper)

export { SmartFieldContext }

