import CodingCell from '~/pages/Backorders/Details/lib/CodingCell';
import { OrderLine } from '~/store/orders/types';
import { Table } from 'semantic-ui-react';
import React from 'react';
import { CodesTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/CodesTableCell/constants';

export const CodesTableCell = ({ column, line, loading, editMode, addCodingHandler }: CodesTableCellProps) => (
  <Table.Cell key={column.name}>
    <CodingCell<OrderLine>
      line={line}
      loading={loading}
      addCodingHandler={addCodingHandler}
      editMode={editMode}
      disablePopup
    />
  </Table.Cell>
);
