import { Table } from "semantic-ui-react";

import React, { useMemo } from "react";
import { DictionaryName } from "~/store/dictionaries/types";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useInvoicesColumns = () => {
  const {
    getAmountCell,
    getCustomCell,
    getDateCell,
    getDictionaryCell,
    getLinkCell,
    getTextCell,
  } = useCellsRenderer();

  const getSupplierSystemCell = useMemo(
    () =>
      (document: any, column: any, fieldName: string): JSX.Element => {
        return (
          <Table.Cell key={column.name}>
            {document.orderCustomer.supplierSystem
              ? document.orderCustomer.supplierSystem[0][fieldName]
              : ""}
          </Table.Cell>
        );
      },
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "accountingCustomer.id",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.id",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.orderCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.ddsDeliveryId",
        label: "OV odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
        noKey: true,
      },
      {
        name: "orderCustomer.supplierSystem.businessSector",
        label: "Dostawa",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getSupplierSystemCell(document, column, "businessSector"),
      },
      {
        name: "orderCustomer.supplierSystem.clientId",
        label: "Sincom",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getCustomCell(() =>
            getSupplierSystemCell(document, column, "clientId")
          ),
      },
      {
        name: "orderCustomer.supplierSystem.deliveryId",
        label: "Punkt dostawy",
        projection: false,
        hide: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getCustomCell(() =>
            getSupplierSystemCell(document, column, "deliveryId")
          ),
      },
      {
        name: "orderCustomer.deliveryType",
        dictionaryName: DictionaryName.orderDeliveryType,
        label: "Dostawa",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        noKey: true,
      },
      {
        name: "netAmount",
        label: "Kwota netto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "taxAmount",
        label: "Kwota VAT",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "grossAmount",
        label: "Kwota brutto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        noKey: true,
      },
      {
        name: "accountingCustomer.taxCode",
        label: "Nip płatnika",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
      {
        name: "erpInvoiceNumber",
        label: "Dokument źródłowy",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceLines.serviceBoxOrderNo",
        label: "Nr zam Service Box",
        projection: false,
        hide: true,
        disableSort: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          getTextCell(
            document,
            column,
            document.invoiceLines[0].serviceBoxOrderNo
          ),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Nr zamówienia",
        projection: false,
        hide: true,
        disableSort: true,
        filteringBlocked: true,
        getCell: (document, column) =>
          document.invoiceLines[0].orderID
            ? getLinkCell(
                document,
                column,
                `/order/${document.invoiceLines[0].orderID}`,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              )
            : getTextCell(
                document,
                column,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              ),
      },
    ],
    [
      getAmountCell,
      getCustomCell,
      getDateCell,
      getDictionaryCell,
      getLinkCell,
      getTextCell,
      getSupplierSystemCell,
    ]
  );

  const columnsConfigInvoicesWithInvoiceLine: TColumn[] = useMemo(
    () => [
      { name: "documentMask", label: "Numer", projection: true },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
      },
      { name: "accountingCustomer.id", label: "Płatnik", projection: true },
      { name: "orderCustomer.id", label: "Odbiorca", projection: true },
      {
        name: "orderCustomer.deliveryType",
        dictionaryName: DictionaryName.orderDeliveryType,
        label: "Dostawa",
        projection: true,
        noKey: true,
      },
      { name: "netAmount", label: "Kwota netto", projection: true },
      { name: "taxAmount", label: "Kwota VAT", projection: true },
      { name: "grossAmount", label: "Kwota brutto", projection: true },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
        noKey: true,
      },
      {
        name: "accountingCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
      },
      {
        name: "erpInvoiceNumber",
        label: "Dokument źródłowy",
        projection: false,
      },
      {
        name: "serviceBoxOrderNo",
        label: "Numer zamówienia Service Box",
        projection: true,
        disableSort: true,
      },
      {
        name: "orderNumber",
        label: "Numer zamówienia",
        projection: false,
        hide: true,
        disableSort: true,
      },
      {
        name: "unitPriceNetAfterDiscount",
        label: "Cena za sztukę",
        projection: false,
        hide: true,
      },
      {
        name: "taxRate",
        label: "Podatek VAT za sztukę",
        projection: false,
        hide: true,
      },
      {
        name: "referenceId",
        label: "Numer referencyjny",
        projection: false,
        hide: true,
      },
      {
        name: "quantity",
        label: "Ilośc na linii",
        projection: false,
        hide: true,
        disableSort: true,
      },
    ],
    []
  );

  return { columnsConfig, columnsConfigInvoicesWithInvoiceLine };
};
