import { TPromotionActivateCollisionInfo } from "~/store/promotions/types";

export enum ErrorActionTypes {
  HANDLE_ERROR = "@@error/HANDLE_ERROR",
  CLEAR_ERRORS = "@@error/CLEAR_ERRORS",
}

export type TErrorsState = TUiError[];

export type TUiError = {
  title: string;
  description: string;
  error: Error;
  blocking?: boolean;
};

export class ApiError extends Error {
  method: string;
  status: number;
  message: any;
  errorInfoObject?: TPromotionActivateCollisionInfo;
  code?: string;
  error?: string;

  constructor(
    method: string,
    status: number,
    message?: any,
    errorInfoObject?: TPromotionActivateCollisionInfo,
    code?: string,
    error?: string
  ) {
    super(`API call error: ${method} ${status}`);

    Object.setPrototypeOf(this, ApiError.prototype);
    this.method = method;
    this.status = status;
    this.message = message;
    this.errorInfoObject = errorInfoObject;
    this.code = code;
    this.error = error;
  }
}
