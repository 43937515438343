import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import translations from "~/utils/translations";
import { DictionaryName } from "~/store/dictionaries/types";
import { connect } from "react-redux";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { ApplicationState } from "~/store";
import "./accounting.scss";

import { useAccountingCPBreadcrumbs } from "./hooks/useAccountingCPBreadcrumbs";
import InvoicesCP from "./TabsClientPanel/Invoices/InvoicesCP";
import CorrectionsCP from "./TabsClientPanel/Corrections/CorrectionsCP";
import CollectiveInvoiceCorrectionsCP from "./TabsClientPanel/CollectiveInvoiceCorrections/CollectiveInvoiceCorrectionsCP";
import AccountingCPLimits from "./TabsClientPanel/Limits/AccountingCPLimits";

export enum AccountingCPBreadcrumbs {
  INVOICES,
  CORRECTIONS,
  COLLECTIVE_INVOICE_CORRECTIONS,
  LIMITS,
}

type TReduxState = {
  exportOnRequestPending: boolean;
};

interface TReduxActions {
  fetchDictionary: typeof fetchDictionary;
}

export type AccountingProps = TReduxState & TReduxActions;

const AccountingClientPanel: React.FC<AccountingProps> = ({
  fetchDictionary,
  exportOnRequestPending,
}) => {
  const [activeTab, setActiveTab] = useState<AccountingCPBreadcrumbs>(
    AccountingCPBreadcrumbs.INVOICES
  );
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [exportTrigger, setExportTrigger] = useState<number>(0);

  const breadcrumbs = useAccountingCPBreadcrumbs({
    setActiveTab,
    setRefreshTrigger,
    setExportTrigger,
  });

  const handleRefresh = useCallback(() => {
    setRefreshTrigger((refreshTrigger) => refreshTrigger + 1);
  }, [setRefreshTrigger]);

  const handleExport = useCallback(() => {
    setExportTrigger((value) => value + 1);
  }, [setExportTrigger]);

  const buttons = useMemo(
    () => [
      {
        icon: "download",
        content: "Eksport danych",
        primary: true,
        onClick: () => handleExport(),
        loading: exportOnRequestPending,
        visible: activeTab !== AccountingCPBreadcrumbs.LIMITS,
      },
    ],
    [activeTab, handleExport, exportOnRequestPending]
  );

  useEffect(() => {
    document.title = translations.format("LDC_ACCOUNTING");
    fetchDictionary(
      DictionaryName.orderDeliveryType,
      DictionaryName.paymentMethod
    );
  }, [fetchDictionary]);

  return (
    <>
      <PageHeader
        icon="briefcase"
        title="Księgowość"
        breadcrumb={breadcrumbs}
        buttons={buttons}
        refreshAction={() => handleRefresh()}
        breadCrumbTab={activeTab}
        breadCrumbStyles={`accounting-crumbs`}
        loading={false}
      />
      {activeTab === AccountingCPBreadcrumbs.INVOICES && (
        <InvoicesCP
          refreshTrigger={refreshTrigger}
          exportTrigger={exportTrigger}
        />
      )}
      {activeTab === AccountingCPBreadcrumbs.CORRECTIONS && (
        <CorrectionsCP
          refreshTrigger={refreshTrigger}
          exportTrigger={exportTrigger}
        />
      )}
      {activeTab === AccountingCPBreadcrumbs.COLLECTIVE_INVOICE_CORRECTIONS && (
        <CollectiveInvoiceCorrectionsCP
          refreshTrigger={refreshTrigger}
          exportTrigger={exportTrigger}
        />
      )}
      {activeTab === AccountingCPBreadcrumbs.LIMITS && (
        <AccountingCPLimits refreshTrigger={refreshTrigger} />
      )}
    </>
  );
};

const mapStateToProps = ({ invoices }: ApplicationState): TReduxState => ({
  exportOnRequestPending: invoices.exportOnRequestPending,
});
const mapDispatchToProps: TReduxActions = {
  fetchDictionary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingClientPanel);
