import React, { Dispatch } from 'react'
import { Button, Form } from 'semantic-ui-react';
import { useLabelFormatter } from '~/components/SmartField/hooks/useLabelFormatter';
import { SmartField } from '~/components/SmartField/SmartField';
import { toastSuccess } from '~/utils/toast';
import translations from '~/utils/translations';

type RenderLabelProps = {
    name: string,
    value: any,
    copyButton?: boolean,
    editable?: boolean,
    setNewCSPSORderNr?: Dispatch<React.SetStateAction<string>>
}

const copyToClipboard = (text: string):void => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    textArea.style.position = 'fixed'
    textArea.style.top = '-50px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy');
      toastSuccess(translations.format("app.manual-orders.CSPS-nr-copied"), text)
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

const RenderLabel: React.FC<RenderLabelProps> = ({name, value, copyButton, editable}) => {
    const { formatLabel } = useLabelFormatter({ label: name, light: true });
    const renderValue = (valueToRender: any) => {
        if (typeof valueToRender === 'string' || typeof valueToRender === 'number' || typeof valueToRender === 'undefined') {
            return (
                <div style={{position: 'relative'}}>
                    <SmartField 
                        name={translations.format("app.reference")}
                        value={editable ? '' : value || '-'}
                        readOnly={!editable}
                        style={{fontSize: 14}}
                    />
                    {copyButton ? (
                        // @ts-ignore
                        <Button
                            disabled={!value}
                            style={{position: 'absolute', top: 0, right: 0}}
                            onClick={() => copyToClipboard(value)}
                            icon="copy outline"
                        />
                    ) : ''}
                </div>
            )
        } else {
            return value
        }
    }
    
    return (
      <Form.Group className="smart-label" style={{alignItems: 'center', paddingBottom: 10}}>
        <Form.Field label={formatLabel()} width={6}/>
        <Form.Field
          label={<span>{renderValue(value)}</span>}
          style={{ textAlign: true }}
          width="8"
        />
      </Form.Group>
    )
}

export default RenderLabel