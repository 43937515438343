import React from 'react';
import { useClaimsRolesRights } from './useClaimsRights';
import { FormattedMessage } from 'react-intl';

type Props = {
  exportPercent: number;
  disableButton: boolean;
  fetchBackInTheBoxReportPending: boolean;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCollectiveTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenBackInTheBoxModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDownloadModal: () => Promise<void>;
};

export const useClaimsButtons = (props: Props) => {
  const { userHaveLdcUiClaimsProcessRole } = useClaimsRolesRights();

  const downloadButton = {
    icon: 'download',
    content: 'Eksport reklamacji i zwrotów',
    primary: true,
    onClick: () => props.handleOpenDownloadModal(),
    window: props.exportPercent,
    disabled: props.disableButton,
  };

  const addButton = {
    icon: 'add',
    content: <FormattedMessage id="app.button.add" />,
    primary: true,
    onClick: () => props.setOpenCreateModal(true),
    visible: userHaveLdcUiClaimsProcessRole,
  };

  const collectiveTaskButton = {
    icon: 'file alternate',
    content: 'Zbiorowe zadanie',
    primary: true,
    onClick: () => props.setOpenCollectiveTaskModal(true),
    visible: userHaveLdcUiClaimsProcessRole,
  };

  const backInTheBoxButton = {
    icon: 'download',
    content: 'Raport Back in the Box',
    primary: true,
    onClick: () => props.setOpenBackInTheBoxModal(true),
    disabled: props.fetchBackInTheBoxReportPending,
    loading: props.fetchBackInTheBoxReportPending,
  };

  return { downloadButton, addButton, collectiveTaskButton, backInTheBoxButton };
};
