import { PaginationMeta, TotalRecords } from "~/store/types";
import { Paginable } from "~/store/types";

export interface Notification {
  id: string;
  name:
    | "NEW_ANOMALY"
    | "NEW_ORDER_SB"
    | "NEW_ORDER_LDC"
    | "ORDER_STATUS_CHANGED"
    | "NEW_REPLENISHMENT"
    | "NEW_CLAIM"
    | "CLAIM_STATUS_CHANGED"
    | "CLAIM_RESERVATION_ERROR"
    | "NEW_PROVISION_RETURN"
    | "NEW_BACKORDER"
    | "ORDER_TO_ACCEPT"
    | "ORDER_CREDIT_LIMIT"
    | "STOCK_UPDATE"
    | "NEW_ERP_DOC"
    | "NEW_CLIENT_INVOICE"
    | "NEW_TRANSPORT_INVOICE"
    | "NEW_PAYMENT_LIST"
    | "WMS_ADAPTER_ERROR_AGRN"
    | "WMS_ADAPTER_ERROR_GRN"
    | "WMS_ADAPTER_ERROR_AGDN"
    | "WMS_ADAPTER_ERROR_GDN"
    | "WMS_ADAPTER_ERROR_STOCK"
    | "WMS_ADAPTER_ERROR_CONTRACTOR"
    | "WMS_ADAPTER_ERROR_INDEX"
    | "WMS_ADAPTER_ERROR"
    | "SAP_ADAPTER_ERROR_PRODUCT_CATALOG"
    | "SAP_ADAPTER_ERROR_INVOICE"
    | "SAP_ADAPTER_ERROR_BACKORDER"
    | "SAP_ADAPTER_ERROR_REINVOICE"
    | "BANK_ADAPTER_ERROR_INVOICES"
    | "BANK_ADAPTER_ERROR_LIMITS"
    | "JPK_ADAPTER_ERROR_INVOICES"
    | "JPK_ADAPTER_ERROR_WAREHOUSE"
    | "SB_ADAPTER_ERROR_CUSTOMER_SEARCH"
    | "SB_ADAPTER_ERROR_STOCK_QUERY"
    | "SB_ADAPTER_ERROR_ORDER"
    | "MAIL_ADAPTER_ERROR"
    | "SAP_ADAPTER_ERROR_SPEEDER_INVOICE_LINES"
    | "SAP_ADAPTER_ERROR_SPEEDER_SALES"
    | "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS"
    | "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_INITIAL"
    | "SAP_ADAPTER_ERROR_SPEEDER_STOCK_STATUS_WEEKLY"
    | "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT"
    | "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_CONFIRMATION"
    | "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE"
    | "SAP_ADAPTER_ERROR_SPEEDER_REPLENISHMENT_MODE_CONFIRMATION"
    | "BACKORDER_ID_MISSING_IN_XP0093"
    | "BACKORDER_ID_NOT_MATCHED"
    | "CODING_FOR_NON_CODED_PART"
    | "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_FOUND"
    | "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED"
    | "SPEEDER_CREATE_BACKORDER_BATCH_NOT_VALID"
    | "SPEEDER_CREATE_BACKORDER_BATCH_ALREADY_EXISTS"
    | "SPEEDER_BACKORDER_CREATE_LINE_PART_NOT_MANAGED_BY_MODE"
    | "ADAPTER_ERROR"
    | "SAP_ADAPTER_ERROR_HAZARDOUS_GOODS";
  params: any | undefined;
  read: boolean;
  time: string;
  type: "INFO" | "ERROR" | "STATUS" | "WARN";
}

export enum NotificationActionTypes {
  FETCH_REQUEST = "@@notifications/FETCH_REQUEST",
  FETCH_INDIVIDUAL_REQUEST = "@@notifications/FETCH_INDIVIDUAL_REQUEST",
  FETCH_LAST_REQUEST = "@@notifications/FETCH_LAST_REQUEST",
  FETCH_SUCCESS = "@@notifications/FETCH_SUCCESS",
  FETCH_INDIVIDUAL_REQUEST_SUCCESS = "@@notifications/FETCH_INDIVIDUAL_REQUEST_SUCCESS",
  FETCH_LASTS_SUCCESS = "@@notifications/FETCH_LASTS_SUCCESS",
  FETCH_COUNT = "@@notifications/FETCH_COUNT",
  FETCH_COUNT_SUCCESS = "@@notifications/FETCH_COUNT_SUCCESS",
  FETCH_MANUAL_ORDERS_COUNT = "@@notifications/FETCH_MANUAL_ORDERS_COUNT",
  FETCH_MANUAL_ORDERS_COUNT_SUCCESS = "@@notifications/FFETCH_MANUAL_ORDERS_COUNT_SUCCESS",
  MAKE_READ = "@@notifications/MAKE_READ",
  MAKE_ALL_READ = "@@notifications/MAKE_ALL_READ",
}

export interface NotificationsState {
  readonly list: Notification[];
  readonly count: number;
  readonly manualOrdersCount: number;
  readonly meta: PaginationMeta;
  readonly totalRecords: TotalRecords;
  readonly loadingLasts: boolean;
  readonly loadingCount: boolean;
  readonly loadingNotification: boolean;
  readonly loadingNotifications: boolean;
  readonly lasts: Notification[];
  readonly id: string;
  readonly params: Array<"">;
  readonly uniqueNotification: { id: string; params: any[] };
}

export type PaginableNotification = Paginable<Notification>;
