import { DictionaryItem, TDetailsFieldType } from "~/store/dictionaries/types";
import dictionariesStatic from "~/store/dictionaries/static";
import { FieldNameEnum } from "~/pages/Administration/lib/Dictionaries/constants";

export const getRequired = (name: string): boolean => {
  return (
    name === FieldNameEnum.key ||
    name === FieldNameEnum.label ||
    name === FieldNameEnum.formula ||
    name === FieldNameEnum.gtu
  );
};

export const getInputType = (type: TDetailsFieldType): string => {
  switch (type) {
    case "STRING":
      return "text";
    case "NUMBER":
      return "number";
    case "INTEGER":
      return "number";
    case "DATE":
      return "date";
    default:
      return "text";
  }
};

export const mapDictionary = (dictionary: any): DictionaryItem[] =>
  //@ts-ignore: dictionary items map
  dictionariesStatic[dictionary].map((dict) => ({
    key: dict.key,
    text: dict.text,
    value: dict.value,
  }));

export const validNumberKey = (event: any): void => {
  return ["+", "e"].includes(event.key) ? event.preventDefault() : null;
};
