import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header, Icon, Modal, Table } from "semantic-ui-react";
import { ModelFormWrapper } from "~/components/ModelForm/ModelFormWrapper";
import { ModelInput } from "~/components/ModelForm/ModelInput";
import { SubmitButton } from "~/components/ModelForm/SubmitButton";
import { ApplicationState } from "~/store";
import { internalConsumption } from "~/store/distrigo-warehouses/actions";
import {
  StockWarehouse,
  TInternalConsumptionPart,
  TInternalConsumptionPayload,
} from "~/store/distrigo-warehouses/types";

import React, { useEffect, useState, SyntheticEvent } from "react";
import { useParams } from "react-router";
import { validNumberKey } from "~/utils/validNumberKey";
import { toastError } from "~/utils/toast";

type TFormRawModel = { [key: string]: number } & {
  additionalInformation?: string;
};

enum PartType {
  FullyValuable = "FullyValuable",
  Defective = "Defective",
}

type TProps = {
  triggerOpen: boolean;
  selection: StockWarehouse[];
  createdSuccess: () => void;
};

const InternalConsumption: React.FC<TProps> = ({
  children,
  triggerOpen,
  selection,
  createdSuccess,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmDestruction, setConfirmDestruction] = useState<boolean>(false);
  const [additionalInformation, setAdditionalInformation] = useState("");

  let { id: warehouseID } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const { destroyed, destroying } = useSelector(
    (state: ApplicationState) => state.distrigoWarehouses
  );

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
    }
  }, [triggerOpen]);

  useEffect(() => {
    if (destroyed) {
      setOpen(false);
      createdSuccess();
    }
  }, [createdSuccess, destroyed]);

  const handleOpen = (): void => setOpen(true);

  const handleCancel = (): void => {
    if (!destroying) {
      setOpen(false);
    }
  };

  const handleConfirm = (): void => {
    if (!additionalInformation) {
      toastError(
        "app.validation.info",
        "app.additionalInformation.is.required"
      );
      return;
    }
    setConfirmDestruction((value) => !value);
  };

  const handleSubmit = (data: TFormRawModel): void => {
    if (!additionalInformation) {
      toastError(
        "app.validation.info",
        "app.additionalInformation.is.required"
      );
      return;
    }

    const parts: TInternalConsumptionPart[] = [];
    const partIDs = selection.map(
      (selected) => selected.referenceWarehouseId.referenceId
    );
    partIDs.forEach((partID) => {
      parts.push({
        partID,
        available: data[`${partID}-${PartType.FullyValuable}`]
          ? Number(data[`${partID}-${PartType.FullyValuable}`])
          : undefined,
        qualityControl: data[`${partID}-${PartType.Defective}`]
          ? Number(data[`${partID}-${PartType.Defective}`])
          : undefined,
      });
    });

    const consumedParts: TInternalConsumptionPayload = {
      parts,
      additionalInformation,
    };

    dispatch(internalConsumption(consumedParts, warehouseID));
  };

  const productStockInfo = (available: number): JSX.Element => {
    return (
      <div style={{ color: "#9f9f9f", fontSize: "12px" }}>
        <label>
          Dostępne: <strong>{available}</strong>
        </label>
      </div>
    );
  };

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <ModelFormWrapper<TFormRawModel> onSubmit={handleSubmit}>
        <Header>
          <Icon name="briefcase" />
          Rozchód wewnętrzny
        </Header>
        <Modal.Content>
          <div>
            <label style={{ fontWeight: "bold" }}>Powód zniszczenia</label>
            <Form.TextArea
              required={true}
              rows={5}
              value={additionalInformation}
              onChange={(e, data) =>
                setAdditionalInformation(data.value as string)
              }
              className="textarea-wrapper"
            />
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID referencji</Table.HeaderCell>
                <Table.HeaderCell>Pełnowartościowe</Table.HeaderCell>
                <Table.HeaderCell>Niepełnowartościowe</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {selection.map((selected, index) => {
                const referenceId = selected.referenceWarehouseId.referenceId;
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{referenceId}</Table.Cell>
                    <Table.Cell>
                      <ModelInput
                        disabled={confirmDestruction}
                        fluid
                        name={`${referenceId}-${PartType.FullyValuable}`}
                        onKeyDown={(event: SyntheticEvent) => {
                          event.persist();
                          validNumberKey(event);
                        }}
                        type="number"
                        min="0"
                        max={selected.available}
                        placeholder="Podaj ilość"
                        value={0}
                      />
                      {productStockInfo(selected.available)}
                    </Table.Cell>
                    <Table.Cell>
                      <ModelInput
                        disabled={confirmDestruction}
                        fluid
                        name={`${referenceId}-${PartType.Defective}`}
                        onKeyDown={(event: SyntheticEvent) => {
                          event.persist();
                          validNumberKey(event);
                        }}
                        type="number"
                        min="0"
                        max={selected.qualityControl}
                        placeholder="Podaj ilość"
                        value={0}
                      />
                      {productStockInfo(selected.qualityControl)}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          {confirmDestruction ? (
            <>
              <SubmitButton
                content="Zatwierdź"
                icon="checkmark"
                labelPosition="left"
                primary
                loading={destroying}
              />
              <Button
                content="Cofnij"
                icon={{ name: "reply", flipped: "vertically" }}
                onClick={handleConfirm}
              />
            </>
          ) : (
            <Button
              content="Zdejmij ze stanu"
              icon="checkmark"
              primary
              onClick={handleConfirm}
            />
          )}
          <Button content="Zamknij" icon="cancel" onClick={handleCancel} />
        </Modal.Actions>
      </ModelFormWrapper>
    </Modal>
  );
};

export default InternalConsumption;
