import { Icon, Loader } from "semantic-ui-react";
import React from "react";

export type NotificationDropdownProps = {
    onClick: () => void,
    notificationCount: number,
    active: boolean
}

export const NotificationDropdownToggle: React.FC<NotificationDropdownProps> = ({ onClick, notificationCount, active }) => {
    return (
        <div className="notifications-header-wrapper" onClick={onClick}>
            <Icon name={notificationCount === 0 ? "bell outline" : "bell"} inverted color="grey"
                  title={`Powiadomienia (${notificationCount})`} size="large" style={{ cursor: "pointer" }}/>
            {notificationCount > 0 &&
            <span className={`notification-count ${notificationCount > 99 ? "count-text-sm" : ""}`}
                  title={String(notificationCount)}>
              {notificationCount > 999 ? "999+" : notificationCount}
                <Loader active={active} inverted size="small"/>
            </span>
            }
        </div>
    );
}