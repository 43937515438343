import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import translations from "~/utils/translations";
import { ServiveInvoicesPopupProps } from "./constants";

export const ServiveInvoicesPopup = ({
  claim,
  getInvoicePdf,
}: ServiveInvoicesPopupProps) => {
  return (
    <Popup
      position="bottom right"
      size="small"
      flowing
      style={{ zIndex: 999 }}
      content={
        claim.serviceInvoices.length === 0
          ? translations.format("app.no.invoices")
          : claim.serviceInvoices.map(
              (serviceInvoice: {
                invoiceNumber: string;
                invoiceDocumentMask: string;
              }) => (
                <Button
                  onClick={() =>
                    getInvoicePdf(
                      serviceInvoice.invoiceNumber,
                      `${serviceInvoice.invoiceDocumentMask}.pdf`
                    )
                  }
                  style={{ display: "block" }}
                  compact
                  basic
                >
                  {serviceInvoice.invoiceDocumentMask}
                </Button>
              )
            )
      }
      on="click"
      trigger={
        <Icon.Group
          size="large"
          style={{
            marginLeft: 10,
            cursor: "pointer",
            opacity: !claim.serviceInvoices.length ? 0.2 : 1,
          }}
        >
          <Icon name="circle outline" fitted />
          <Icon name="info" size="mini" fitted />
        </Icon.Group>
      }
    />
  );
};
