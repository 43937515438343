import { Table } from "semantic-ui-react";
import { DistrigoWarehouse } from "~/store/distrigo-warehouses/types";

import React, { useCallback } from "react";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";
import TableNoResults from "~/components/TableNoResults/TableNoResults";

interface Props {
  distrigoWarehouses: DistrigoWarehouse[];
  columns: any[];
}

export const DistrigoWarehousesList: React.FC<Props> = ({
  distrigoWarehouses,
  columns,
}) => {
  const distrigoWarehouseOrderListDropdown = useCallback(
    (distrigoWarehouse: DistrigoWarehouse) => {
      return (
        <TablePreviewDetails path={`/stock-products/${distrigoWarehouse.id}`} />
      );
    },
    []
  );

  return (
    <>
      {distrigoWarehouses.length > 0 ? (
        distrigoWarehouses.map((distrigoWarehouse) => (
          <Table.Row key={distrigoWarehouse.id}>
            {columns.map(
              (column) =>
                column.projection && column.getCell(distrigoWarehouse, column)
            )}
            <Table.Cell className="col-dropdown-menu-sticky">
              {distrigoWarehouseOrderListDropdown(distrigoWarehouse)}
            </Table.Cell>
          </Table.Row>
        ))
      ) : (
        <TableNoResults />
      )}
    </>
  );
};
