import { useCallback, useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";

export const useClientPanelRoles = () => {
  const context = useContext(AppContext);

  const isClientPanelUser = useMemo(
    () => !!context?.clientPanelUser,
    [context]
  );

  const isSuperAdmin = useMemo(
    () =>
      !!context?.userInfo.userGroup.find(
        (item: string) => item === "/CUSTOMER_PANEL/SUPER_ADMIN"
      ),
    [context]
  );

  const isLocalAdmin = useMemo(
    () =>
      !!context?.userInfo.userGroup.find(
        (item: string) => item === "/CUSTOMER_PANEL/LOCAL_ADMIN"
      ),
    [context]
  );

  const isLocalUser = useMemo(
    () =>
      !!context?.userInfo.userGroup.find(
        (item: string) => item === "/CUSTOMER_PANEL/LOCAL_USER"
      ),
    [context]
  );

  const hasRole = useCallback(
    (roleName: string) => !!context?.keycloak.hasResourceRole(roleName),
    [context?.keycloak]
  );

  const payerId = useMemo(() => context?.userInfo.payerId, [context]);
  const rrdis = useMemo(() => context?.userInfo.rrdis, [context]);
  const user_Id = useMemo(() => context?.userInfo.sub, [context]);

  return {
    isClientPanelUser,
    isSuperAdmin,
    isLocalAdmin,
    isLocalUser,
    payerId,
    rrdis,
    user_Id,
    hasRole,
  };
};
