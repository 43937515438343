import { Button, Divider, Header, Input, InputOnChangeData, Modal, TextArea } from 'semantic-ui-react';
import React, { ReactElement, useEffect, useState } from 'react';
import CommonLoader from '~/components/Loaders/CommonLoader';
import ProductsTree from '~/pages/Promotions/Details/lib/triggers/ProductsTree';
import { saveAdditionalDiscount } from '~/store/customer/actions';
import { Customer } from '~/store/customer/types';

type TProps = {
  triggerButton?: ReactElement;
  customer: Customer | undefined;
  saveAdditionalDiscountPending: boolean;
  saveAdditionalDiscount: typeof saveAdditionalDiscount;
};

enum ValidationErrors {
  value = '- Wartość dodatkowego rabatu powinna być uzupełniona i większa od 0.',
  selected = '- Przynajmniej jeden produkt powinien być zaznaczony.',
}

const AdditionalDiscountCodeModal: React.FC<TProps> = ({
  triggerButton,
  saveAdditionalDiscountPending,
  saveAdditionalDiscount,
  customer,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>();
  const [discountValue, setDiscountValue] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [validateMessages, setValidateMessages] = useState<ValidationErrors[]>();

  const resetValues = (): void => {
    setSelected(undefined);
    setDiscountValue('');
    setComment('');
    setValidateMessages(undefined);
  };

  useEffect(() => {
    if (selected && selected.length) {
      setValidateMessages((messages) => messages?.filter((item) => item !== ValidationErrors.selected));
    }
  }, [selected]);

  useEffect(() => {
    if (discountValue && Number(discountValue) > 0) {
      setValidateMessages((messages) => messages?.filter((item) => item !== ValidationErrors.value));
    }
  }, [discountValue]);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    resetValues();
    setOpen(true);
  };

  const validate = (): boolean => {
    const isValidDiscountValue = !!discountValue;
    const isValidSelected = !!(selected && selected.length > 0);
    const messages = [];
    if (!isValidDiscountValue) {
      messages.push(ValidationErrors.value);
    }
    if (!isValidSelected) {
      messages.push(ValidationErrors.selected);
    }
    setValidateMessages(messages);
    return isValidDiscountValue && isValidSelected;
  };

  const handleSave = (): void => {
    const isValid = validate();
    if (!isValid) return;
    const DTO = {
      percentage: Number(discountValue),
      comment: comment,
      references: selected || [],
    };
    if (!customer) return;
    saveAdditionalDiscount(customer.rrdi, DTO);
    handleCancel();
  };

  const onSelectionChange = (selected: any[]) => {
    setSelected(selected);
  };

  const handleDiscountValueChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    if (Number(data.value) > 100) {
      setDiscountValue('100');
    } else if (Number(data.value) < 0) {
      setDiscountValue('0');
    } else {
      setDiscountValue(data.value);
    }
  };

  return (
    <Modal
      size="small"
      trigger={
        triggerButton || <Button style={{ display: 'block', marginTop: '20px' }} color="orange" content="Dodaj" />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="add" content="Dodaj grupę produktów" />
      <Modal.Content scrolling>
        <CommonLoader loading={saveAdditionalDiscountPending} messageId="app.saving"></CommonLoader>
        <div style={{ marginLeft: -7, marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
          <div className="ui form" style={{ width: '50%' }}>
            <div className="field" style={{ marginBottom: '1em' }}>
              <label style={{ fontWeight: 700, fontSize: '12px' }}>Wartość % dodatkowego rabatu</label>
            </div>
            <Input
              style={{ width: '80%' }}
              type="number"
              min={0}
              max={100}
              value={discountValue}
              onChange={handleDiscountValueChange}
              error={!!validateMessages?.find((item) => item === ValidationErrors.value)}
            />
          </div>
          <div className="ui form" style={{ width: '50%' }}>
            <div className="field">
              <label>Komentarz</label>
            </div>
            <TextArea rows={3} value={comment} onChange={(e, data) => setComment(String(data.value))}></TextArea>
          </div>
        </div>
        <Divider />
        <ProductsTree
          editMode={true}
          modelPropertyName={'additionalOrderDiscount'}
          description="Zaznaczone produkty i hierarchie będą dodatkowym rabatem podczas składania zamówienia."
          onSelectionChange={onSelectionChange}
        />
        <CommonLoader loading={false} />
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', gap: 5, flex: 1, justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'start' }}>
            {validateMessages &&
              validateMessages.map((message) => (
                <div key={Math.random()} style={{ marginBottom: 3, color: 'red' }}>
                  {message}
                </div>
              ))}
          </div>
          <div style={{ display: 'flex' }}>
            <Button primary content="Zapisz" onClick={handleSave} disabled={saveAdditionalDiscountPending} />
            <Button content="Zamknij" onClick={handleCancel} disabled={saveAdditionalDiscountPending} />
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default AdditionalDiscountCodeModal;
