const claimTypeMap = {
  RK1_ZNRE: ['ZNRE_E05'],
  RK1_ZRDP: ['ZRDP_Q01', 'ZRDP_Q02', 'ZRDP_Q03', 'ZRDP_Q04', 'ZRDP_Q05'],
  RK1_ZRPN: ['ZRPN_N01', 'ZRPN_N03', 'ZRPN_N05', 'ZRPN_N07', 'ZRPN_N13'],
  RK1_ZRVE: ['ZRVE_G02', 'ZRVE_G03'],
  RK2_ZRNG: ['ZRNG_E07'],
  RK2_ZRNF: ['ZRNF_E07'],
  RK4_P1: [],
};

export default claimTypeMap;
