import React from "react";

type TUserInfo = {
  bonusSpecialOrderDiscountLimit: number;
  client_id: string;
  email_verified: boolean;
  groups: string[];
  locale: string;
  origin: string;
  payerId: string;
  rrdis: string[];
  sub: string;
  userGroup: string[];
  user_name: string;
};

export interface AppContextInterface {
  keycloak: { [key: string]: any };
  authenticated: boolean;
  lang: string;
  menuVisibility: boolean;
  changeLanguage: (language: string) => void;
  toggleSidebar: () => void;
  changeKeycloak: () => void;
  userInfo: TUserInfo;
  clientPanelUser: boolean | undefined;
}

export const AppContext = React.createContext<AppContextInterface | null>(null);
