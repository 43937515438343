import React from 'react';
import { Button, Popup, Table } from 'semantic-ui-react';
import { RemoveTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';

const RemoveTableCell = ({ summary, order, line, removeLine }: RemoveTableCellProps) => {
  return (
    <Table.Cell className="col-dropdown-menu-sticky">
      {!summary &&
        line.orderLineType !== 'PROVISION' &&
        line.orderLineType !== 'REPLACEMENT' &&
        line.orderLineType !== 'REPLACEMENT_ORIGINAL' && (
          <Popup
            trigger={
              <Button
                compact
                disabled={!order.type}
                circular
                basic
                icon="remove"
                style={{ marginRight: 0 }}
                onClick={() => removeLine(line)}
              />
            }
            content="Usuń linię z zamówienia"
          />
        )}
    </Table.Cell>
  );
};

export default RemoveTableCell;
