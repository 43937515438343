import React, { useMemo, useState } from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Input,
  InputOnChangeData,
  Message,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { importFactorLimits } from "~/store/factors/actions";
import PreviewImage from "~/components/PreviewImage/PreviewImage";
import FactorLimitFilePreview from "~/assets/images/examples/import-factor-limit-file.png";
import CommonLoader from "~/components/Loaders/CommonLoader";
import { ApplicationState } from "~/store";

const ImportFactorLimits = (): JSX.Element => {
  const [file, setFile] = useState<File>();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ApplicationState) => state.factor.loading
  );

  const handleFileChange = useMemo(
    () =>
      (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (event?.target?.files?.length !== 0) {
          setFile(event.target.files?.[0] as File);
        }
      },
    []
  );

  const handleImport = () => {
    if (file) {
      dispatch(importFactorLimits(file));
    }
  };

  return (
    <Container>
      <CommonLoader loading={loading} />
      <Message style={{ display: "flex", justifyContent: "space-between" }}>
        <Message.Content>
          Załaduj plik XLS według wzorcowego formatu.
          <br />
          Po wczytaniu pliku, wartości limitu faktora zostaną nadpisane tylko
          dla pozycji z pliku (plik różnicowy).
          <br />
          <span style={{ color: "gray", fontSize: "10px" }}>
            *Maksymalny rozmiar pliku 15MB.
          </span>
        </Message.Content>
        <Button
          primary
          onClick={handleImport}
          disabled={file === undefined}
          size="large"
        >
          Importuj
        </Button>
      </Message>
      <PreviewImage src={FactorLimitFilePreview} />
      <Segment placeholder>
        <Header icon>
          <Icon name="file archive outline" />
          {file ? file.name : "Załaduj plik"}
        </Header>
        <Input type="file" onChange={handleFileChange} />
      </Segment>
    </Container>
  );
};

export default ImportFactorLimits;
