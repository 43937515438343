import React from "react";
import { Button, Header, Icon, List } from "semantic-ui-react";
import "./dropdown-file-area.scss";

export type DropdownFileAreaProps = {
  dragging: boolean;
  files: File[] | FileList;
  onSelectFileClick: () => void;
  onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  fileMaxSize?: number;
  reqestMaxSize?: number;
};

const DropdownFileArea: React.FC<DropdownFileAreaProps> = ({
  dragging,
  files,
  onSelectFileClick,
  onDrag,
  onDragStart,
  onDragEnd,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
  children,
  fileMaxSize = 10,
  reqestMaxSize = 10,
}) => {
  const uploadClasses = (): string =>
    dragging
      ? "dropdown-file-area dropdown-file-area--dragging"
      : "dropdown-file-area";

  return (
    <div
      className={uploadClasses()}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="dropdown-file-area__contents">
        <span className="dropdown-file-area__file-name" />
        <Header icon>
          <Icon name="file archive outline" />
        </Header>
        <span className="dropdown-file-area__text-info">
          {`*Limit ${fileMaxSize} MB na plik, ${reqestMaxSize} MB na żądanie`}
        </span>
        <List style={{ display: files && files.length > 0 ? "block" : "none" }}>
          <span>Liczba wgrywanych plików: {files && files.length}</span>
        </List>
        <span>Przenieś i upuść pliki</span>
        <span>Lub wybierz</span>
        <Button onClick={onSelectFileClick} primary>
          Wgraj plik
        </Button>
      </div>
      {children}
    </div>
  );
};

export default DropdownFileArea;
