import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContextInterface } from "~/context/AuthContext";

export const getSearchOptions = (appContext: AppContextInterface | null) =>
  [
    {
      key: "orders",
      text: "Zamówienia",
      value: "orders",
      visible: appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW
      ) ? 'true' : false,
    },
    {
      key: "products",
      text: "Produkty",
      value: "products",
      visible: appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_PRODUCT_VIEW
      ) ? 'true' : false,
    },
    {
      key: "claims",
      text: "Reklamacje i zwroty",
      value: "claims",
      visible: appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW
      ) ? 'true' : false,
    },
    {
      key: "customers",
      text: "Kontrahenci",
      value: "customers",
      visible: appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_VIEW
      ) ? 'true' : false,
    },
    {
      key: "backorders",
      text: "Zamówienia hurtowe",
      value: "backorders",
      visible: appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_BACKORDER_VIEW
      ) ? 'true' : false,
    },
  ].filter((item) => item.visible);
