import { TBaseProps, TDefaultMappers } from "~/components/SmartField/lib/types";

/**
 * Hook handles mappers configuration for save and load actions.
 */
export const useMappers = (props: TBaseProps) => {

    const defaultLoadMappers: TDefaultMappers = {
        number: Number
    }

    const defaultSaveMappers: TDefaultMappers = {
        number: Number
    }

    const loadMapper = props.loadMapper || (props.type && defaultLoadMappers[props.type]) || ((v: any) => v)
    const saveMapper = props.saveMapper || (props.type && defaultSaveMappers[props.type]) || ((v: any) => v)

    return { loadMapper, saveMapper }
}