import React, { Fragment, LegacyRef, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Button,
  ButtonProps,
  Form,
  Grid,
  GridColumn,
  Icon,
  Input,
  Popup,
} from "semantic-ui-react";

import DetailCard from "~/components/DetailCard/DetailCard";
import PageHeader from "~/components/PageHeader/PageHeader";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import UberSearch from "~/components/UberSearch/UberSearch";
import { ApplicationState } from "~/store";
import { SparePartPricing } from "~/store/price-list/types";
import {
  TImportedFile,
  TLine,
  TWareCreate,
  TWareLocation,
} from "~/store/distrigo-warehouses/types";
import ImportReferenceFromCSV from "~/pages/StockProducts/lib/CreateReceiptOfWare/ImportReferenceFromCSV/ImportReferenceFromCSV";
import ReferenceLineTable from "./ReferenceLineTable/ReferenceLineTable";

import "./ContentOfCreatingReceiptOfWare.scss";
import { parsePrice } from "~/utils/parsePrice";

type ContentOfCreatingReceiptOfWareProps = {
  addLine: () => void;
  changeQuantity: (
    lineNumber: number,
    currentValue: number,
    prevValue: number,
    round: boolean
  ) => void;
  changePrice: (
    lineNumber: number,
    currentValue: number,
    prevValue: number,
    round: boolean
  ) => void;
  changeSupplier: (
    lineNumber: number,
    data: string | number | boolean | (string | number | boolean)[] | undefined
  ) => string;
  closeModal: () => void;
  confirmCreateReceiptOfWare?: boolean;
  getProvision: (lineNumber: number, lines: TLine[]) => TLine | undefined;
  getTotalAmount: () => string;
  handleImportCSV: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
  handleSummaryNewReceiptInWare: () => void;
  handleConfirmOfCreateNewReceiptOfWare: () => void;
  lines: TLine[];
  maximized?: boolean;
  openModal?: boolean;
  removeLine: (lineIn: TLine) => void;
  resetWare: () => void;
  setWareDetails: (key: TWareLocation) => void;
  selectWareId: (selected: { rrid?: string; ware: TWareLocation }) => string;
  setConfirmCreateReceiptOfWare: (key: boolean) => void;
  setLines: (key: TLine[]) => void;
  setSummary: (key: boolean) => void;
  summary?: boolean;
  tableEl?: LegacyRef<HTMLDivElement>;
  toggleMaximized: () => void;
  updateIndexing: (key: TLine[]) => void;
  updateWare: (
    field: keyof TWareCreate,
    value?: Date | string | null | number
  ) => void;
  ware: TWareCreate;
  wareId?: string;
  wareDetails?: TWareLocation;
  warehouseId: string;
};

type TReduxState = {
  creating?: boolean;
  finishedImportingToLine?: boolean;
  imported?: TImportedFile[];
};

type TContentOfCreatingReceiptOfWareProps =
  ContentOfCreatingReceiptOfWareProps & TReduxState & RouteComponentProps;

const ContentOfCreatingReceiptOfWare = ({
  addLine,
  changeQuantity,
  changePrice,
  changeSupplier,
  closeModal,
  confirmCreateReceiptOfWare,
  creating,
  getTotalAmount,
  handleSummaryNewReceiptInWare,
  handleConfirmOfCreateNewReceiptOfWare,
  handleImportCSV,
  lines,
  maximized,
  openModal,
  resetWare,
  removeLine,
  selectWareId,
  setLines,
  setSummary,
  setConfirmCreateReceiptOfWare,
  summary,
  tableEl,
  toggleMaximized,
  updateIndexing,
  updateWare,
  ware,
  wareId,
  wareDetails,
  warehouseId,
}: TContentOfCreatingReceiptOfWareProps) => {
  const [cancel, setCancel] = useState<boolean>(false);

  const mapProducts = (products: SparePartPricing[]): any[] => {
    return products.map((product) => ({
      product,
      key: product.referenceId,
      title: product.referenceId,
      description: product.description,
    }));
  };

  const mapWares = (wares: TLine[]): any[] => {
    return wares.map((ware) => ({
      key: ware.returningPointView ? ware.returningPointView.rrdi : undefined,
      title: ware.displayName,
      rrid: ware.returningPointView ? ware.returningPointView.rrdi : undefined,
      ware: ware,
    }));
  };

  const handleCancel = useCallback(() => {
    setSummary(false);
    setConfirmCreateReceiptOfWare(false);
    setCancel(true);
  }, [setConfirmCreateReceiptOfWare, setCancel, setSummary]);

  const handleBack = useCallback((): void => {
    setSummary(false);
    resetWare();
  }, [resetWare, setSummary]);

  const formatWareAddress = (details: TWareLocation): JSX.Element => {
    return (
      <Fragment>
        {details.street} {details.number}
        <br />
        {details.zipcode} {details.city}
      </Fragment>
    );
  };

  const validNumber = useCallback((value: number): boolean => {
    return isNaN(value) ? false : String(value) !== "0";
  }, []);

  const isValidForm = useCallback((): string | undefined => {
    if (!ware.invoiceDate) {
      return "Nie wybrano daty faktury.";
    }

    if (!ware.invoiceNumber) {
      return "Nie wybranego numeru faktury.";
    }

    if (!ware.rrid) {
      return "Nie wybranego Id punktu dostawy.";
    }

    if (!lines.length) {
      return "Zamówienie musi mieć co najmniej jedną pozycję.";
    }

    const invalidQuantity = lines.some((line) => isNaN(Number(line.quantity)));
    if (invalidQuantity) {
      return "Ilość do zwrotu musi być wartością liczbową.";
    }

    const invalidPrice = lines.some((line) => isNaN(Number(line.price)));
    if (invalidPrice) {
      return "Jednostkowa cena zakupu musi być wartością liczbową.";
    }

    const invalidSupplier = lines.some((line) => !line.supplier);
    if (invalidSupplier) {
      return "Dostawca musi być wybrany";
    }

    const someInvalid = lines.some((line) => !line.referenceId);
    if (someInvalid) {
      return "Zamówienie nie może posiadać pustej pozycji.";
    }
  }, [lines, ware]);

  const errorMessage = isValidForm();

  if (cancel) {
    return <Redirect to="/distrigo-warehouses" />;
  }
  if (confirmCreateReceiptOfWare) {
    return <Redirect to={`/stock-products/${warehouseId}`} />;
  }
  return (
    <Fragment>
      {openModal && (
        <ImportReferenceFromCSV
          closeModal={closeModal}
          importSuccess={addLine}
          triggerOpen={openModal}
        />
      )}
      <PageHeader
        title="Przyjmij nowy towar"
        buttons={[
          { content: "Anuluj", icon: "cancel", onClick: handleCancel },
          {
            content: "Podsumuj przyjmowanie nowego towaru",
            icon: "checkmark",
            primary: true,
            loading: creating,
            disabled: !!errorMessage || creating,
            onClick: handleSummaryNewReceiptInWare,
            visible: !summary,
            popup: errorMessage,
          },
          {
            content: "Wróć",
            icon: "arrow left",
            onClick: handleBack,
            visible: summary,
          },
          {
            content: "Zatwierdź przyjęcie nowego towaru",
            icon: "checkmark",
            primary: true,
            onClick: handleConfirmOfCreateNewReceiptOfWare,
            loading: creating,
            disabled: creating,
            visible: summary,
          },
        ]}
        icon={
          <Icon.Group className="icon">
            <Icon name="shop" />
            <Icon corner name="add" />
          </Icon.Group>
        }
        breadcrumb={[
          { text: "Lista", link: "/stock-products/PL9999P" },
          { text: "Tworzenie przyjęcia nowego towaru" },
        ]}
        loading={false}
      />
      <Grid style={{ marginBottom: "unset" }} stretched>
        <DetailCard
          title="app.orderPointId"
          id="client"
          key="client"
          width={4}
          smallWidth={5.33}
          style={{ zIndex: 30 }}
        >
          <Form.Field disabled={summary}>
            <UberSearch
              placeholder="Wyszukaj..."
              fluid
              endpoint="/stock/external-reception/customers/return-points"
              mapper={(response) => mapWares(response)}
              onResultSelect={(selected: any) => selectWareId(selected)}
              debounce={700}
            />
          </Form.Field>
          {!!ware.rrid && (
            <>
              <SmartLabel
                label="Nazwa"
                value={
                  wareDetails &&
                  wareDetails.returningPointView &&
                  wareDetails.returningPointView.company
                }
              />
              <SmartLabel
                label="Adres"
                value={
                  wareDetails &&
                  wareDetails.returningPointView &&
                  formatWareAddress(wareDetails.returningPointView)
                }
              />
              <SmartLabel
                label="Płatnik"
                value={
                  wareDetails &&
                  wareDetails.returningPointView &&
                  wareDetails.returningPointView.rrdi
                }
              />
              <SmartLabel
                label="PCD płatnik"
                value={
                  wareDetails &&
                  wareDetails.returningPointView &&
                  wareDetails.returningPointView.sapClientId
                }
              />
              <SmartLabel
                label="OV płatnik"
                value={
                  wareDetails &&
                  wareDetails.returningPointView &&
                  wareDetails.returningPointView.ddsClientId
                }
              />
            </>
          )}
        </DetailCard>
        <DetailCard
          title="app.invoiceData"
          id="invoiceData"
          key="invoiceData"
          minHeight={10}
          width={4}
          smallWidth={5.33}
        >
          <Form.Group widths="equal" className="InvoiceSection">
            <Form.Field
              className="InvoiceNumber"
              disabled={!ware.rrid || summary}
            >
              <label>Numer faktury zewnętrznej</label>
              <Input
                className="InvoiceNumberInput"
                fluid
                type="text"
                onChange={(e, d) => updateWare("invoiceNumber", d.value)}
                value={ware.invoiceNumber || ""}
              />
            </Form.Field>
            <Form.Field
              disabled={
                (!ware.invoiceNumber && !ware.rrid) ||
                (!ware.invoiceNumber && !!ware.rrid) ||
                summary
              }
            >
              <label className="InvoiceDateLabel">
                Data faktury zewnętrznej
              </label>
              <SemanticDatepicker
                autoComplete="off"
                clearable
                id="invoiceDate"
                locale="pl-PL"
                onChange={(e, d) =>
                  updateWare(
                    "invoiceDate",
                    d.value ? new Date(Number(d.value)) : null
                  )
                }
                placeholder="RRRR-MM-DD"
                showToday
                value={ware.invoiceDate}
              />
            </Form.Field>
          </Form.Group>
        </DetailCard>
        <DetailCard
          title="app.referenceLine"
          id="referenceLine"
          key="referenceLine"
          minHeight={40}
          width={16}
        >
          <Grid columns="2" style={{ marginBottom: 0 }}>
            <Grid.Row>
              <Grid.Column verticalAlign="bottom">
                Ilość: {lines.length}
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Popup
                  trigger={
                    <Button
                      basic
                      circular
                      disabled={
                        (!ware.rrid &&
                          !ware.invoiceDate &&
                          !ware.invoiceNumber) ||
                        (!!ware.rrid &&
                          !ware.invoiceNumber &&
                          !ware.invoiceDate) ||
                        (!!ware.rrid &&
                          !!ware.invoiceNumber &&
                          !ware.invoiceDate) ||
                        summary
                      }
                      icon="file excel"
                      onClick={handleImportCSV}
                    />
                  }
                  position="bottom right"
                  content="Importuj linie z pliku XLSX, XLS, CSV"
                />
                <Popup
                  trigger={
                    <Button
                      circular
                      basic
                      icon={
                        maximized
                          ? "window restore outline"
                          : "expand arrows alternate"
                      }
                      onClick={toggleMaximized}
                    />
                  }
                  position="bottom right"
                  content={
                    maximized
                      ? "Minimalizuj widok tabeli"
                      : "Maksymalizuj widok tabeli"
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ReferenceLineTable
            changeQuantity={changeQuantity}
            changePrice={changePrice}
            changeSupplier={changeSupplier}
            lines={lines}
            mapProducts={mapProducts}
            maximized={maximized}
            removeLine={removeLine}
            setLines={setLines}
            summary={summary}
            tableEl={tableEl}
            updateIndexing={updateIndexing}
            ware={ware}
            wareId={wareId}
            validNumber={validNumber}
          />
          <Grid columns={2} style={{ marginTop: 0 }}>
            <Grid.Row>
              <GridColumn>
                <Popup
                  trigger={
                    <Button
                      disabled={
                        (!ware.rrid &&
                          !ware.invoiceDate &&
                          !ware.invoiceNumber) ||
                        (!!ware.rrid &&
                          !ware.invoiceNumber &&
                          !ware.invoiceDate) ||
                        (!!ware.rrid &&
                          !!ware.invoiceNumber &&
                          !ware.invoiceDate) ||
                        summary
                      }
                      circular
                      basic
                      icon="add"
                      onClick={addLine}
                    />
                  }
                  content="Dodaj kolejną linię do zamówienia"
                />
              </GridColumn>
              <GridColumn textAlign="right">
                <div>
                  <label>Całkowita wartość przyjętego towaru: </label>
                  <strong style={{ color: "red" }}>
                    {parsePrice(getTotalAmount())} PLN
                  </strong>
                </div>
              </GridColumn>
            </Grid.Row>
          </Grid>
        </DetailCard>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  distrigoWarehouses,
}: ApplicationState) => ({
  creating: distrigoWarehouses.creating,
  finishedImportingToLine: distrigoWarehouses.finishedImportingToLine,
  imported: distrigoWarehouses.imported,
});

export default connect(
  mapStateToProps,
  null
)(withRouter(ContentOfCreatingReceiptOfWare));
