import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApi } from "~/utils/api";
import { toastSuccess } from "~/utils/toast";
import { handleError } from "../error/actions";
import {
  anomalySelected,
  closeResolveSuccess,
  fetchAnomalies,
  fetchSuccess,
  selectAnomaly,
  resolveAnomaly,
  closeAnomaly,
  resolveDestroyedParts,
  resolveDestroyedLinesSuccess,
  rejectRW,
  rejectRWSuccess,
} from "./actions";
import {
  AnomaliesActionTypes,
  Anomaly,
  PaginableAnomaly,
  TAnomalyType,
} from "./types";

function* handleFetchAnomalies(action: ReturnType<typeof fetchAnomalies>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn.replace(".", "_")},${
        action.payload.sortDirection
      }`;
    }

    const anomalies: PaginableAnomaly = yield call(
      callApi,
      "get",
      `/anomalies?page=${action.payload.page - 1}&size=${
        action.payload.size
      }${filters}${sort}`
    );

    yield put(fetchSuccess(anomalies));
  } catch (error) {
    yield put(handleError(error, false, "Nie można wczytać listy anomalii"));
  }
}

function* handleSelectAnomaly(action: ReturnType<typeof selectAnomaly>) {
  try {
    const anomaly: Anomaly = yield call(
      callApi,
      "get",
      `/anomalies/${action.payload}`
    );
    yield put(anomalySelected(anomaly));
  } catch (error) {
    yield put(handleError(error, true, "Nie można wczytać wybranej anomalii"));
  }
}

function* handleRejectRW(action: ReturnType<typeof rejectRW>) {
  try {
    yield call(callApi, "post", `/anomalies/close/rw/${action.payload}`, {});
    yield toastSuccess("app.info.common.update", "app.info.common.success");
    yield put(rejectRWSuccess());
  } catch (error) {
    yield put(handleError(error, false, "Nie można wczytać wybranej anomalii"));
  }
}

const anomalyUrls: { types: TAnomalyType[]; resolve: string; close: string }[] =
  [
    {
      types: [
        "GDN_SUPPLIER_CLAIM",
        "GDN_CLIENT_ORDER",
        "GDN_CLIENT_CLAIM",
        "GDN_INTERNAL",
      ],
      resolve: "/anomalies/resolve/wz/",
      close: "/anomalies/close/wz/",
    },
    {
      types: [
        "GRN_BACKORDER",
        "GRN_CLIENT_CLAIM",
        "GRN_CLIENT_PROVISION",
        "GRN_INTERNAL",
      ],
      resolve: "/anomalies/resolve/pz/",
      close: "/anomalies/close/pz/",
    },
    {
      types: ["CROSS_DOCK_PCD", "CROSS_DOCK_OV"],
      resolve: "/anomalies/resolve/crossdock/",
      close: "/anomalies/close/crossdock/",
    },
    {
      types: [
        "STOCK_AVAILABLE",
        "STOCK_QUALITY_CONTROL",
        "STOCK_INTERNAL_CONSUMPTION",
        "STOCK_BLOCKED",
      ],
      resolve: "/anomalies/resolve/stock/",
      close: "/anomalies/reject/stock/",
    },
    {
      types: ["REQUIRES_CONFIRMATION"],
      resolve: "/internal-consumptions/confirm/",
      close: "/internal-consumptions/reject/",
    },
  ];

function* handleResolveDestroyedParts(
  action: ReturnType<typeof resolveDestroyedParts>
) {
  try {
    let data = {};
    if (action.payload.partsToDestroy) {
      data = {
        partsToDestroy: action.payload.partsToDestroy.map((part) => ({
          referenceId: part.referenceId,
          quantity: part.quantity,
          status: part.status,
        })),
      };
    }

    yield call(
      callApi,
      "post",
      `/anomalies/resolve/rw/${action.payload.anomalyId}`,
      data
    );
    yield toastSuccess("app.info.common.update", "app.info.common.success");
    yield put(resolveDestroyedLinesSuccess());
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        "Błąd podczas zmieniania statusów części uszkodzonych"
      )
    );
  }
}

function* handleResolveAnomaly(action: ReturnType<typeof resolveAnomaly>) {
  try {
    const { parts, selectedGDNOption } = action.payload;
    let data;
    if (parts) {
      data = {
        partsToResend: parts
          .filter((part) => part.selected)
          .map((part) => ({
            referenceId: part.referenceId,
            quantity: part.quantity,
            vin: part.vin,
          })),
        destination: selectedGDNOption ? selectedGDNOption : undefined,
      };
    } else {
      data = {
        destination: selectedGDNOption ? selectedGDNOption : undefined,
      };
    }
    const url = anomalyUrls.find(
      (d) => d.types.indexOf(action.payload.type) !== -1
    );
    if (url) {
      yield call(
        callApi,
        "post",
        `${url.resolve}${action.payload.anomalyId}`,
        data
      );
      yield toastSuccess("app.info.common.update", "app.info.common.success");
      yield put(closeResolveSuccess());
    } else {
      yield put(
        handleError(
          new Error(),
          false,
          "Błąd obsługi anomalii",
          "Nie można obsłużyć tego typu anomalii."
        )
      );
    }
  } catch (error) {
    yield put(handleError(error, false, "Nie można rozwiązać anomalii"));
  }
}

function* handleCloseAnomaly(action: ReturnType<typeof closeAnomaly>) {
  try {
    const url = anomalyUrls.find(
      (d) => d.types.indexOf(action.payload.type) !== -1
    );
    if (url) {
      yield call(callApi, "post", `${url.close}${action.payload.anomalyId}`, {
        destination: action.payload.selectedGDNOption
          ? action.payload.selectedGDNOption
          : undefined,
      });
      yield toastSuccess("app.info.common.update", "app.info.common.success");
      yield put(closeResolveSuccess());
    } else {
      // this should never happen (button shouldn't be rendered for unknown Type, but who knows...)
      yield put(
        handleError(
          new Error(),
          false,
          "Błąd obslugi anomalii",
          "Nie można obsłużyć tego typu anomalii."
        )
      );
    }
  } catch (error) {
    yield put(handleError(error, false, "Nie można zamknąć anomalii"));
  }
}

function* watchResolveAnomaly() {
  yield takeEvery(AnomaliesActionTypes.RESOLVE_ANOMALY, handleResolveAnomaly);
}

function* watchCloseAnomaly() {
  yield takeEvery(AnomaliesActionTypes.CLOSE_ANOMALY, handleCloseAnomaly);
}

function* watchFetchAnomalies() {
  yield takeEvery(AnomaliesActionTypes.FETCH_REQUEST, handleFetchAnomalies);
}

function* watchSelectAnomaly() {
  yield takeEvery(AnomaliesActionTypes.SELECT_ANOMALY, handleSelectAnomaly);
}

function* watchResolveDestroyedParts() {
  yield takeEvery(
    AnomaliesActionTypes.RESOLVE_DESTROYED_PARTS,
    handleResolveDestroyedParts
  );
}

function* watchRejectRW() {
  yield takeEvery(AnomaliesActionTypes.REJECT_RW, handleRejectRW);
}

function* anomaliesSaga() {
  yield all([
    fork(watchFetchAnomalies),
    fork(watchSelectAnomaly),
    fork(watchResolveAnomaly),
    fork(watchCloseAnomaly),
    fork(watchResolveDestroyedParts),
    fork(watchRejectRW),
  ]);
}

export default anomaliesSaga;
