import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  exportDocumentOnRequest,
  fetchInvoicesCorrections,
  getInvoicePdf,
} from "~/store/invoices/actions";
import { Divider, Table } from "semantic-ui-react";
import { IInvoice } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { useCorrectionsCPColumns } from "./useCorrectionsCPColumns";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { useCorrectionsAdvancedSearchCP } from "./useCorrectionsCPAdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import IconHover from "~/components/IconHover/IconHover";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  exportTrigger: number;
};

type TReduxState = {
  corrections: IInvoice[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
};

interface TReduxActions {
  fetchInvoicesCorrections: typeof fetchInvoicesCorrections;
  getInvoicePdf: typeof getInvoicePdf;
  exportDocumentOnRequest: typeof exportDocumentOnRequest;
}

type CorrectionsProps = TComponentProps & TReduxState & TReduxActions;

const CorrectionsCP: React.FC<CorrectionsProps> = ({
  fetchInvoicesCorrections,
  getInvoicePdf,
  exportDocumentOnRequest,
  exportTrigger,
  corrections,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
}) => {
  const { columnsConfig } = useCorrectionsCPColumns();
  const [tableKey] = useState<string>("accounting/corrections-CP");
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("invoiceDate");
  const [sortDirection] = useState<any>("DESC");
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getCorrections = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchInvoicesCorrections(Object.assign(paramsObj, params), true);
    },
    [
      fetchInvoicesCorrections,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );
  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getCorrections({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getCorrections]);

  useEffect(() => {
    if (exportTrigger) {
      exportDocumentOnRequest("/customer-panel/accounting/corrections/export", {
        ...params,
      });
    }
    // eslint-disable-next-line
  }, [exportTrigger, exportDocumentOnRequest]);

  const { formFields, handleSubmit } = useCorrectionsAdvancedSearchCP(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getCorrections,
    filterLabelsRef,
    tableKey
  );

  return (
    <Fragment>
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />

      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getCorrections}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {corrections.map((document: IInvoice, index: number) => (
          <Table.Row disabled={!!document.cancellationReason} key={index}>
            {columns &&
              columns.length &&
              columns.map(
                (column) =>
                  column.getCell &&
                  column.projection &&
                  column.getCell(document, column)
              )}
            <Table.Cell className="col-dropdown-menu-sticky">
              <IconHover
                name="download"
                onClick={() =>
                  getInvoicePdf(
                    document.invoiceNo,
                    `${document.documentMask}.pdf`,
                    true
                  )
                }
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </DataGridTable>
    </Fragment>
  );
};

const mapStateToProps = ({
  invoices,
  dictionaries,
}: ApplicationState): TReduxState => ({
  corrections: invoices.corrections.list,
  loading: invoices.corrections.loading,
  totalRecords: invoices.corrections.totalRecords,
  meta: invoices.corrections.meta,
  dictionaries: dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  fetchInvoicesCorrections,
  getInvoicePdf,
  exportDocumentOnRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorrectionsCP);
