import React, { useState } from 'react'
import { Button, Form, Grid, Modal } from 'semantic-ui-react'

interface GDNConfirmModalProps {
    setSelectedGDNOption: (option: 'ADDITION' | 'REMOVAL' | '') => void
    selectedGDNOption: 'ADDITION' | 'REMOVAL' | ''
    handleCancelClick: () => void
    modalOpen: boolean
    onConfirmClick: () => void
}


const GDNConfirmModal = ({ setSelectedGDNOption, selectedGDNOption, handleCancelClick, modalOpen, onConfirmClick }: GDNConfirmModalProps) => {

    const [confirmRW, setConfirmRW] = useState(false)

    const handleConfirmClick = () => {
        if ('REMOVAL' === selectedGDNOption) {
            setConfirmRW(true)
        } else {
            onConfirmClick()
        }
    }

    const handleConfirmRW = () => {
        onConfirmClick()
        setConfirmRW(false)
    }

    const handleCancelRW = () => {
        setConfirmRW(false)
    }

    return (
        <Modal
            className="MoveModal"
            closeOnDimmerClick={false}
            open={modalOpen}
            size='small'
        >
            <Modal.Header icon="add" content="Wydanie produkt" />
            <Modal.Content>
                <Grid>
                    <label style={{ fontWeight: 'bold' }}>Wskaż stan odrzuconych części:</label>
                    <Grid.Row>
                        <Button
                            key={1}
                            fluid
                            size='mini'
                            basic={('ADDITION' !== selectedGDNOption)}
                            onClick={() => setSelectedGDNOption('ADDITION')}
                            style={{ marginBottom: 10, textAlign: 'left', width: '49%' }}>
                            <Form.Radio
                                label={'Dostępne na magazynie'}
                                checked={'ADDITION' === selectedGDNOption}
                                value={'ADDITION'}
                            />
                        </Button>
                        <Button
                            key={2}
                            fluid
                            size='mini'
                            basic={('REMOVAL' !== selectedGDNOption)}
                            onClick={() => setSelectedGDNOption('REMOVAL')}
                            style={{ marginBottom: 10, textAlign: 'left', width: '49%' }}>
                            <Form.Radio
                                label={'Do zniszczenia'}
                                checked={'REMOVAL' === selectedGDNOption}
                                value={'REMOVAL'}
                            />
                        </Button>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                {
                    confirmRW ? <>
                        <Button
                            primary
                            disabled={selectedGDNOption === ''}
                            content="Potwierdź RW"
                            onClick={handleConfirmRW}
                        />
                        <Button content="Anuluj" onClick={handleCancelRW} />
                    </> : <Button
                        primary
                        disabled={selectedGDNOption === ''}
                        content="Potwierdź przesunięcie"
                        onClick={handleConfirmClick}
                    />
                }

                <Button content="Zamknij" onClick={handleCancelClick} />
            </Modal.Actions>
        </Modal>
    )
}


export default GDNConfirmModal
