import { Button, Table } from 'semantic-ui-react';
import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import React from 'react';
import { SapInvoicesTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/SapInvoicesTableCell/constants';

export const SapInvoicesTableCell = ({ column, line, invoiceErpPreviewHandler }: SapInvoicesTableCellProps) => (
  <Table.Cell key={column.name} textAlign="right">
    {line.sapInvoices.length}
    <InfoIcon
      documents={line.sapInvoices}
      clickableItem={(document) => (
        <Button
          style={{ display: 'block' }}
          compact
          basic
          onClick={() => {
            invoiceErpPreviewHandler(document);
          }}
        >
          Podgląd dokumentu
        </Button>
      )}
      noResultsMessage="Brak dokumentów"
    />
  </Table.Cell>
);
