import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { useAccountingRolesRights } from "../../hooks/useAccountingRolesRights";
import AddInvoiceCorrection from "../../lib/AddInvoiceCorrection/AddInvoiceCorrection";
import InvoiceErpDetails from "~/pages/Accounting/Details/InvoiceErp";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import { retrySent } from "~/store/invoices/actions";
import { IInvoice } from "~/store/invoices/types";

type Props = {
  document: IInvoice;
  getInvoice: (invoiceNr: string, documentMask: string) => void;
  createdHandle: () => void;
  retrySent: typeof retrySent;
};

export const InvoicesDropdown: React.FC<Props> = ({
  document,
  getInvoice,
  createdHandle,
  retrySent,
}) => {
  const { userHaveLdcUiAccountingInvoiceCorrectionRole } =
    useAccountingRolesRights();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleConfirm = useCallback(() => {
    setOpenModal(false);
    retrySent(document.invoiceNo);
  }, [document.invoiceNo, retrySent]);

  return (
    <TableMoreDropdown>
      <Dropdown.Item
        text="Pobierz dokument"
        onClick={() =>
          getInvoice(document.invoiceNo, `${document.documentMask}.pdf`)
        }
      />
      <Dropdown.Divider />
      {document.orderID && (
        <Dropdown.Item
          as={Link}
          to={`/order/${document.orderID}`}
          text="Podgląd zamówienia"
        />
      )}
      {userHaveLdcUiAccountingInvoiceCorrectionRole && (
        <AddInvoiceCorrection
          createdSuccess={createdHandle}
          isDebitNote={false}
          invoiceIn={document.invoiceNo}
          invoiceMask={document.documentMask}
          triggerOpen={false}
        >
          <Dropdown.Item text="Wystaw korektę" />
        </AddInvoiceCorrection>
      )}
      <Dropdown.Item
        text="Ponowne wysłanie faktury"
        onClick={() => setOpenModal(true)}
      />
      {document.erpInvoiceNumber != null && (
        <InvoiceErpDetails
          noteId={document.erpInvoiceId || ""}
          closeHandler={() => {}}
        >
          <Dropdown.Item text="Dokument źródłowy" />
        </InvoiceErpDetails>
      )}
      <ModalConfirm
        modalOpen={openModal}
        headerIcon="paper plane"
        headerText="app.resent"
        contentText="app.resent.question"
        onCancelClick={() => setOpenModal(false)}
        onConfirmClick={handleConfirm}
      />
    </TableMoreDropdown>
  );
};
