import React from "react";
import { useCallback, useMemo } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import InfoIcon from "~/pages/Orders/Details/lib/InfoIcon";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { useCells } from "~/services/useCells";
import { DictionariesState } from "~/store/dictionaries/types";
import { ClientPanelGroup, User } from "~/store/users/types";

type TProps = {
  tableKey: string;
};

export const useUsersColumns = ({ tableKey }: TProps) => {
  const { getDateCell, getTextCell } = useCellsRenderer();

  const getActiveUserCell = useCallback(
    (document: any, column: any): JSX.Element => (
      <Table.Cell key={column.name}>
        {document[column.name] ? (
          <Icon name="check" size="large" style={{ color: "green" }} />
        ) : (
          <Icon name="close" size="large" style={{ color: "red" }} />
        )}
      </Table.Cell>
    ),
    []
  );

  const getUserGroupCell = useCallback(
    (document: User, column: TColumn): JSX.Element => {
      let group: ClientPanelGroup = document[column.name];
      let label = "";
      switch (group) {
        case ClientPanelGroup.SUPER_ADMIN:
          label = "Super Administrator";
          break;
        case ClientPanelGroup.LOCAL_ADMIN:
          label = "Administrator Lokalny";
          break;
        case ClientPanelGroup.LOCAL_USER:
          label = "Użytkownik Lokalny";
          break;
      }
      return (
        <Table.Cell key={group}>
          <p>{label}</p>
        </Table.Cell>
      );
    },
    []
  );

  const getPayerIdCell = useCallback(
    (document: User, column: any): JSX.Element => {
      return (
        <Table.Cell key={column.name}>
          {document.attributes?.payerId || ""}
        </Table.Cell>
      );
    },
    []
  );

  const getRrdisCell = useCallback(
    (document: User, column: any): JSX.Element => {
      return (
        <Table.Cell key={column.name}>
          {document.attributes?.rrdis?.length || "0"}
          <InfoIcon
            documents={
              document.attributes?.rrdis ? document.attributes.rrdis : []
            }
            clickableItem={(document) => (
              <Button
                compact
                basic
                key={document}
                style={{
                  display: "block",
                  width: "100%",
                  boxShadow: "none",
                  outline: "none",
                }}
              >
                {document}
              </Button>
            )}
            noResultsMessage="Brak RRDI"
          />
        </Table.Cell>
      );
    },
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "username",
        label: "Nazwa użytkownika",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "firstName",
        label: "Imię",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "lastName",
        label: "Nazwisko",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "email",
        label: "E-mail",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "enabled",
        label: "Aktywny",
        projection: true,
        getCell: (document, column) => getActiveUserCell(document, column),
      },
      {
        name: "createdTimestamp",
        label: "Data utworzenia",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
    ],
    [getDateCell, getTextCell, getActiveUserCell]
  );

  const columnsConfigClientPanel: TColumn[] = useMemo(
    () => [
      ...columnsConfig.map((item) => ({ ...item, disableSort: true })),
      {
        name: "group",
        label: "Grupa",
        projection: true,
        getCell: (document, column) => getUserGroupCell(document, column),
        disableSort: true,
      },
      {
        name: "attributes.payerId",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) => getPayerIdCell(document, column),
        disableSort: true,
      },
      {
        name: "attributes.rrdis",
        label: "Punkty odbioru",
        projection: true,
        getCell: (document, column) => getRrdisCell(document, column),
        disableSort: true,
      },
    ],
    [columnsConfig, getRrdisCell, getUserGroupCell, getPayerIdCell]
  );

  const { completeColumnDictionaries } = useCells({
    tableKey,
    dictionaries: {} as DictionariesState,
  });

  return {
    columnsConfig: completeColumnDictionaries(columnsConfig),
    columnsConfigClientPanel: completeColumnDictionaries(
      columnsConfigClientPanel
    ),
  };
};
