import DetailCard from "~/components/DetailCard/DetailCard";
import React, { LegacyRef, useCallback, useEffect } from "react";

import { Button, Checkbox, CheckboxProps, Form } from "semantic-ui-react";
import { Announcement } from "~/store/annouoncements/types";
import { AnnouncementType } from "~/pages/Dashboard/lib/constans";
import { AnnouncementDisplay } from "~/pages/Dashboard/lib/Announcements/AnnouncementDisplay";
import { useGetAnnouncementImg } from "~/pages/Dashboard/lib/AnnouncementImage/useGetAnnouncementImg";
import { AnnouncementImage } from "~/pages/Dashboard/lib/AnnouncementImage/AnnouncementImage";
import { TNewAnnouncement } from "../../AddAnnouncement/AddAnnouncement";
import { TextEditor } from "../../AddAnnouncement/TextEditor/TextEditor";
import { toastWarn } from "~/utils/toast";

type TAnnouncementContentCard = {
  announcement: Announcement | TNewAnnouncement;
  setNewAnnouncement:
    | React.Dispatch<React.SetStateAction<Announcement | undefined>>
    | React.Dispatch<React.SetStateAction<TNewAnnouncement>>;
  edit: boolean;
};

const AnnouncementContentCard: React.FC<TAnnouncementContentCard> = ({
  announcement,
  setNewAnnouncement,
  edit,
}) => {
  const { getImageData, img, file, setImg, setFile } = useGetAnnouncementImg({
    imgId: announcement.image,
    announcement: announcement,
  });

  useEffect(() => {
    if ("id" in announcement && announcement.image && !img?.includes("blob")) {
      getImageData();
    }
  }, [announcement.image, getImageData, announcement, img]);

  const addText = (text: any): void => {
    setNewAnnouncement((item: any) => ({ ...item, content: text }));
  };

  const toggleAnnouncementType = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    if (!edit) return;
    if (data.id === AnnouncementType.TEXT) {
      setNewAnnouncement((item: any) => ({
        ...item,
        content: undefined,
        type: data.checked ? AnnouncementType.TEXT : AnnouncementType.IMAGE,
      }));
    }
    if (data.id === AnnouncementType.IMAGE) {
      setNewAnnouncement((item: any) => ({
        ...item,
        image: undefined,
        file: undefined,
        content: undefined,
        type: data.checked ? AnnouncementType.IMAGE : AnnouncementType.TEXT,
      }));
    }
    setImg(null);
    setFile(undefined);
  };

  useEffect(() => {
    setNewAnnouncement((item: any) => ({ ...item, image: img, file: file }));
  }, [img, file, setNewAnnouncement]);

  const fileInputRef: LegacyRef<HTMLInputElement> | undefined =
    React.createRef();

  const ImageImport = useCallback(() => {
    const changeSpecialChars = (name: string): string => {
      const specialChars = ["ą", "ć", "ę", "ł", "ó", "ń", "ś", "ź", "ż"];
      const charsToSwap = ["a", "c", "e", "l", "o", "n", "s", "z", "z"];
      let nameToSave = name.toLowerCase();
      return nameToSave
        .split("")
        .map((char) => {
          const index = specialChars.findIndex((item) => item === char);
          return index >= 0 ? charsToSwap[index] : char;
        })
        .join("");
    };
    const handleChange = (e: any) => {
      const loadedFile = e.target.files[0] as File;
      const fileSize = loadedFile.size / 1024 / 1024; // in MB
      if (fileSize > 2) {
        toastWarn("Plik jest zbyt duży", "Maksymalna wielkość to 2MB");
        return;
      }
      const fileToSave = new File(
        [loadedFile],
        changeSpecialChars(loadedFile.name),
        { type: loadedFile.type, lastModified: loadedFile.lastModified }
      );
      setFile(fileToSave);
      setImg(URL.createObjectURL(fileToSave));
    };

    return (
      <>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept="image/png, image/gif, image/jpeg"
          onChange={handleChange}
        />
        <div></div>
        <div
          style={{
            minHeight: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 200,
              gap: 10,
            }}
          >
            {img && edit && (
              <Button
                content="Usuń"
                labelPosition="left"
                icon="cancel"
                onClick={() => setImg(null)}
              />
            )}
          </div>
          <div
            style={{
              minWidth: 600,
              minHeight: 350,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#d3d3d336",
            }}
          >
            {img && (
              <AnnouncementImage
                middle={true}
                inputImage={img}
                addPreview={true}
              />
            )}
            {!img && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 15,
                }}
              >
                <Button
                  content="Wybierz obraz"
                  labelPosition="left"
                  icon="file"
                  onClick={() => {
                    fileInputRef.current && fileInputRef.current.click();
                  }}
                />
                <p style={{ color: "#e3480a" }}>
                  Dozwolone formaty to: jpg, jpeg i gif. <br />
                  Wielkość załącznika nie może przekraczać 2MB. <br />
                  Zalecane proporcje obrazu to 16x9.
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }, [edit, fileInputRef, img, setImg, setFile]);

  return (
    <DetailCard
      title="app.content"
      id="info"
      key="info"
      width={16}
      style={{ zIndex: 30 }}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          width: 230,
          marginBottom: 20,
        }}
      >
        <Form.Checkbox
          toggle
          label={"Ogłoszenie tekstowe"}
          checked={announcement.type === AnnouncementType.TEXT}
          onChange={toggleAnnouncementType}
          as={Checkbox}
          className={"field super-toggle "}
          id={AnnouncementType.TEXT}
        />
        <Form.Checkbox
          toggle
          label={"Ogłoszenie obrazkowe"}
          checked={announcement.type === AnnouncementType.IMAGE}
          onChange={toggleAnnouncementType}
          as={Checkbox}
          className={"field super-toggle "}
          id={AnnouncementType.IMAGE}
        />
      </Form>
      {announcement.type === AnnouncementType.IMAGE && ImageImport()}
      {announcement.type === AnnouncementType.TEXT && !edit && (
        <AnnouncementDisplay
          editable={false}
          text={announcement.content || []}
          addText={addText}
        ></AnnouncementDisplay>
      )}
      {announcement.type === AnnouncementType.TEXT && edit && (
        <TextEditor
          addText={addText}
          text={
            "id" in announcement && announcement.content
              ? announcement.content
              : undefined
          }
        />
      )}
      <div style={{ height: 50 }}></div>
    </DetailCard>
  );
};

export default AnnouncementContentCard;
