import React, { useEffect } from "react";
import { TToggleProps } from "~/components/SmartField/lib/types";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { Checkbox, CheckboxProps, Form } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { getToggleIcon } from "~/components/SmartField/lib/utils";

/**
 * Toggle button that automatically saves its value on change.
 */
export const SmartToggle: React.FC<TToggleProps> = (props) => {
  const {
    currentValue,
    loading,
    success,
    error,
    handleEdit,
    handleSave,
    getPopupContent,
    isRequired,
  } = useSmartFieldLogic(props);

  const { formatLabel } = useLabelFormatter(props);

  useEffect(() => {
    handleSave();
  }, [currentValue, handleSave]);

  const handleSpecificEdit = (ev: any, el: CheckboxProps) => {
    handleEdit(el.checked);
  };

  const hasErrorClass = !!error ? "toggle-error" : "";

  return (
    <SmartPopup offset="0, 5px" description={getPopupContent()}>
      <Form.Checkbox
        toggle
        label={
          <>
            {formatLabel()}
            {!props.noSuccesInfo
              ? getToggleIcon(loading, error, success)
              : null}
          </>
        }
        required={isRequired}
        style={props.style}
        checked={currentValue}
        onChange={handleSpecificEdit}
        disabled={props.disabled}
        readOnly={props.readOnly}
        as={Checkbox}
        className={"field super-toggle " + hasErrorClass}
      />
    </SmartPopup>
  );
};
