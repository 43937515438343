import { action } from 'typesafe-actions'
import { DictionariesActionTypes, DictionaryItem, DictionaryName, TDetails } from './types'

export const fetchDictionary = (...names: DictionaryName[]) => action(DictionariesActionTypes.FETCH_REQUEST, names)
export const fetchSuccess = (name: DictionaryName, data?: DictionaryItem[]) => action(DictionariesActionTypes.FETCH_SUCCESS, { name, data })

export const fetchDictionariesNames = () => action(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES)
export const fetchDictionariesNamesSuccess = (names: string[]) => action(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_SUCCESS, { names })

export const fetchDictionariesNamesDetails = (name: string) => action(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_DETAILS, name)
export const fetchDictionariesNamesDetailsSuccess = (details: TDetails, data: any) => action(DictionariesActionTypes.FETCH_DICTIONARIES_NAMES_DETAILS_SUCCESS, { details, data })

export const addDictionaryItem = (name: DictionaryName, item: Object) => action(DictionariesActionTypes.ADD_DICTIONARY_ITEM, { name, item })
export const updateDictionaryItem = (name: DictionaryName, key: string, item: Object) => action(DictionariesActionTypes.UPDATE_DICTIONARY_ITEM, { name, key, item })
export const removeDictionaryItem = (name: DictionaryName, key: string) => action(DictionariesActionTypes.REMOVE_DICTIONARY_ITEM, { name, key })
export const actionDictionaryItemSuccess = (name?: DictionaryName) =>action(DictionariesActionTypes.ACTION_DICTIONARY_ITEM_SUCCESS, name)
export const clearCreated = () => action(DictionariesActionTypes.CLEAR_CREATED)