import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ICollectiveMM,
  IGoodsDispatchNote,
  IGoodsDispatchNotePostponed,
  IGoodsReceivedNote,
  IInternalConsumptionNote,
  IInvoiceErp,
  IInvoiceLinesSchedulers,
  InvoicesActionTypes,
  PaginableGoodsDispatchNotePostponed,
  PaginableGoodsDispatchNotes,
  PaginableGoodsReceivedNotes,
  PaginableICollectiveDebitNoteCorrections,
  PaginableIInvoiceLines,
  PaginableInternalConsumptionNotes,
  PaginableInvoice,
  PaginableInvoiceErp,
} from "./types";
import {
  fetchInvoices,
  fetchInvoicesSuccess,
  fetchInvoicesWithProvision,
  fetchInvoicesWithProvisionSuccess,
  fetchInvoicesCorrections,
  fetchInvoicesCorrectionsSuccess,
  createInvoiceCorrection,
  createInvoiceCorrectionSuccess,
  fetchGoodsDispatchNotes,
  fetchGoodsDispatchNotesSuccess,
  fetchGoodsReceivedNotes,
  fetchGoodsReceivedNotesSuccess,
  fetchInternalConsumptionNotes,
  fetchInternalConsumptionNotesSuccess,
  createInvoiceTransport,
  createInvoiceTransportSuccess,
  fetchInvoicesErp,
  fetchInvoicesErpSuccess,
  fetchGoodsDispatchNotePostponed,
  fetchGoodsDispatchNotePostponedSuccess,
  getInvoicePdf,
  selectGoodsDispatchNote,
  GoodsDispatchNoteSelected,
  selectGoodsReceivedNote,
  goodsReceivedNoteSelected,
  selectInternalConsumptionNote,
  internalConsumptionNoteSelected,
  invoiceErpSelected,
  selectInvoiceErp,
  retrySent,
  fetchFinalInvoice,
  fetchFinalInvoiceSuccess,
  uploadCollectiveCorrections,
  loadCollectiveCorrectionsLines,
  fetchCollectiveCorrections,
  fetchCollectiveCorrectionsSuccess,
  confirmCollectiveCorrectionsLines,
  confirmCollectiveCorrectionSuccess,
  fetchCollectiveDebitNoteCorrections,
  fetchCollectiveDebitNoteCorrectionsSuccess,
  loadCollectiveCorrectionsLinesError,
  getCollectiveCorrectionPdf,
  previewCollectiveCorrectionPdf,
  fetchDebitNotes,
  fetchDebitNotesSuccess,
  fetchDebitNotesCorrections,
  fetchDebitNotesCorrectionsSuccess,
  fetchInvoicesWithDebitNotes,
  fetchInvoicesWithDebitNotesSuccess,
  getCollectiveDebitCorrectionPdf,
  fetchInvoiceLines,
  fetchInvoiceLinesSuccess,
  fetchInvoiceLinesSchedulers,
  fetchInvoiceLinesSchedulersSuccess,
  fetchInvoiceSchedulers,
  fetchInvoiceSchedulersSuccess,
  selectGoodsDispatchNotePostponed,
  goodsDispatchNotePostponedSelected,
  fetchMMDataSuccess,
  fetchMMData,
  selectMM,
  mmSelected,
  exportDocumentOnRequest,
  exportDocumentOnRequestSuccess,
  exportDocumentOnRequestError,
} from "./actions";
import { callApi, callApiDownload, callApiUploadFile } from "~/utils/api";
import { handleError } from "../error/actions";
import { downloadFile } from "~/utils/downloadFile";
import { toastSuccess } from "~/utils/toast";
import { handleFileUploadError } from "~/utils/fileUploadErrorHandler";
import { ApiError } from "../error/types";

function* handleFetchInvoices(action: ReturnType<typeof fetchInvoices>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      if (
        action.meta &&
        filter.name === "orderCustomer.deliveryType" &&
        filter.value === "URGENT"
      ) {
        filters += `&${filter.name}=URGENT,TURBO_PRO`;
      } else {
        filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
      }
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoices: PaginableInvoice = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/accounting/invoices?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchInvoicesSuccess(invoices));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać faktur")
    );
  }
}

function* handleFetchInvoicesWithDebitNotes(
  action: ReturnType<typeof fetchInvoicesWithDebitNotes>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoicesWithDebitNotes: PaginableInvoice = yield call(
      callApi,
      "get",
      `/accounting/original?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchInvoicesWithDebitNotesSuccess(invoicesWithDebitNotes));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać faktur i potwierdzeń RW"
      )
    );
  }
}

function* handleFetchInvoicesWithProvision(
  action: ReturnType<typeof fetchInvoicesWithProvision>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoicesWithProvision: PaginableInvoice = yield call(
      callApi,
      "get",
      `/accounting/original-with-provisions?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchInvoicesWithProvisionSuccess(invoicesWithProvision));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać faktur")
    );
  }
}

function* handleFetchCollectiveCorrections(
  action: ReturnType<typeof fetchCollectiveCorrections>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const urlDistrigo =
      "/accounting/collective-corrections/collective-invoice-corrections";
    const urlCP = `/customer-panel/accounting/collective-invoice-corrections`;

    const collectiveCorrections: PaginableInvoice = yield call(
      callApi,
      "get",
      `${action.meta ? urlCP : urlDistrigo}?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchCollectiveCorrectionsSuccess(collectiveCorrections));
  } catch (error) {
    yield put(
      fetchCollectiveCorrectionsSuccess({
        content: [],
        number: 0,
        totalPages: 0,
        size: 0,
      })
    );
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać korekt zbiorczych"
      )
    );
  }
}

function* handleFetchCollectiveDebitNoteCorrections(
  action: ReturnType<typeof fetchCollectiveDebitNoteCorrections>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const noteCorrections: PaginableICollectiveDebitNoteCorrections =
      yield call(
        callApi,
        "get",
        `/accounting/collective-corrections/collective-debit-note-corrections?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
      );

    yield put(fetchCollectiveDebitNoteCorrectionsSuccess(noteCorrections));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać not zbiorczych")
    );
  }
}

function* handleFetchInvoicesCorrections(
  action: ReturnType<typeof fetchInvoicesCorrections>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoicesCorrections: PaginableInvoice = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/accounting/corrections?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchInvoicesCorrectionsSuccess(invoicesCorrections));
  } catch (error) {
    yield put(
      fetchInvoicesCorrectionsSuccess({
        content: [],
        number: 0,
        totalPages: 0,
        size: 0,
      })
    );
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać korekt faktur")
    );
  }
}

function* handleFetchGoodsDispatchNotes(
  action: ReturnType<typeof fetchGoodsDispatchNotes>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const goodsDispatchNotes: PaginableGoodsDispatchNotes = yield call(
      callApi,
      "get",
      `/goods-dispatch-notes?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchGoodsDispatchNotesSuccess(goodsDispatchNotes));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać dokumentów WZ")
    );
  }
}

function* handleFetchGoodsReceivedNotes(
  action: ReturnType<typeof fetchGoodsReceivedNotes>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const goodsReceivedNotes: PaginableGoodsReceivedNotes = yield call(
      callApi,
      "get",
      `/goods-received-notes?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchGoodsReceivedNotesSuccess(goodsReceivedNotes));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać dokumentów PZ")
    );
  }
}

function* handleFetchInternalConsumptionNotes(
  action: ReturnType<typeof fetchInternalConsumptionNotes>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const internalConsumptionNotes: PaginableInternalConsumptionNotes =
      yield call(
        callApi,
        "get",
        `/internal-consumptions?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
      );

    yield put(fetchInternalConsumptionNotesSuccess(internalConsumptionNotes));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać dokumentów RW")
    );
  }
}

function* handleFetchInvoicesErp(action: ReturnType<typeof fetchInvoicesErp>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoices: PaginableInvoiceErp = yield call(
      callApi,
      "get",
      `/invoices/erp?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchInvoicesErpSuccess(invoices));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać dokumentów ERP")
    );
  }
}

function* handleFetchGoodsDispatchNotePostponed(
  action: ReturnType<typeof fetchGoodsDispatchNotePostponed>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoices: PaginableGoodsDispatchNotePostponed = yield call(
      callApi,
      "get",
      `/goods-dispatch-notes/postponed-parts?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );
    yield put(fetchGoodsDispatchNotePostponedSuccess(invoices));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać zakolejkowanych elementów"
      )
    );
  }
}

function* handleFetchDebitNotes(action: ReturnType<typeof fetchDebitNotes>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const debitNotes: PaginableInvoice = yield call(
      callApi,
      "get",
      `/accounting/debit-notes?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchDebitNotesSuccess(debitNotes));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać potwierdzeń RW")
    );
  }
}

function* handleFetchInvoiceLines(
  action: ReturnType<typeof fetchInvoiceLines>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const invoiceLines: PaginableIInvoiceLines = yield call(
      callApi,
      "get",
      `/accounting/items-to-invoice?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );
    yield put(fetchInvoiceLinesSuccess(invoiceLines));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać linii do zafakturowania"
      )
    );
  }
}

function* handleFetchInvoiceSchedulers(
  action: ReturnType<typeof fetchInvoiceSchedulers>
) {
  try {
    const schedulers: IInvoiceLinesSchedulers = yield call(
      callApi,
      "get",
      `/accounting/items-to-invoice/schedulers`
    );
    yield put(fetchInvoiceSchedulersSuccess(schedulers));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać najbliższego czasu fakturacji"
      )
    );
  }
}

function* handleFetchInvoiceLinesSchedulers(
  action: ReturnType<typeof fetchInvoiceLinesSchedulers>
) {
  try {
    const schedulers: IInvoiceLinesSchedulers = yield call(
      callApi,
      "get",
      `/goods-dispatch-notes/postponed-parts/schedulers`
    );
    yield put(fetchInvoiceLinesSchedulersSuccess(schedulers));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać najbliższego czasu fakturacji"
      )
    );
  }
}

function* handleFetchDebitNotesCorrections(
  action: ReturnType<typeof fetchDebitNotesCorrections>
) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const debitNotesCorrections: PaginableInvoice = yield call(
      callApi,
      "get",
      `/accounting/debit-note-corrections?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchDebitNotesCorrectionsSuccess(debitNotesCorrections));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się pobrać korekt potwierdzeń RW"
      )
    );
  }
}

function* handleFetchMMData(action: ReturnType<typeof fetchMMData>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
    }

    const mmData: PaginableInvoice = yield call(
      callApi,
      "get",
      `/inventory-transfer?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`
    );

    yield put(fetchMMDataSuccess(mmData));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Nie udało się pobrać danych z MM")
    );
  }
}

function* handleSelectMM(action: ReturnType<typeof selectMM>) {
  try {
    const mmItem: ICollectiveMM = yield call(
      callApi,
      "get",
      `/inventory-transfer/${action.payload}`
    );

    yield put(mmSelected(mmItem));
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas pobierania WZ"));
  }
}

function* handleCreateInvoiceCorrection(
  action: ReturnType<typeof createInvoiceCorrection>
) {
  try {
    const encodeNumber = (value: string) => value.replaceAll("/", "-");

    const url = action.payload.correctionForClaim
      ? `/claims/${encodeNumber(action.payload.correctionForClaim)}/correction`
      : `/accounting/${encodeNumber(action.payload.invoiceNo)}/correction`;
    const res: { invoiceCorrectionDocumentMask: string | undefined } =
      yield call(callApi, "post", url, action.payload.correction);

    const { invoiceCorrectionDocumentMask } = res;

    const copyToClipboard = (text: string | undefined): void => {
      const textArea = document.createElement("textarea");
      textArea.value = text || "";
      textArea.style.position = "fixed";
      textArea.style.top = "-50px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    };
    copyToClipboard(invoiceCorrectionDocumentMask);
    yield toastSuccess(
      "Korekta faktury została utworzona",
      invoiceCorrectionDocumentMask
        ? `Utworzono korketę: ${invoiceCorrectionDocumentMask}. Jej numer został skopiowany do schowka.`
        : "",
      undefined,
      invoiceCorrectionDocumentMask ? 0 : undefined
    );
    yield put(createInvoiceCorrectionSuccess());
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się utworzyć korekty faktury",
        error.message || null
      )
    );
  }
}

function* handleRetrySent(action: ReturnType<typeof retrySent>) {
  try {
    const invoiceNumberEncoded = action.payload.invoiceNo.replaceAll("/", "-");
    yield call(
      callApi,
      "post",
      `/accounting/${invoiceNumberEncoded}/send-request`,
      action.payload.invoiceNo
    );
    yield toastSuccess(
      "app.resent.success.title",
      "app.resent.success.description"
    );
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się wysłać ponownie faktury"
      )
    );
  }
}

function* handleCreateInvoiceTransport(
  action: ReturnType<typeof createInvoiceTransport>
) {
  try {
    yield call(
      callApi,
      "post",
      "/accounting/transportation",
      action.payload.transport
    );
    yield put(createInvoiceTransportSuccess());
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się utworzyć FV transportowej"
      )
    );
  }
}

function* handleGetInvoicePdf(action: ReturnType<typeof getInvoicePdf>) {
  try {
    const invoiceNumberEncoded = action.payload.invoiceNo.replaceAll("/", "-");
    const res: Response = yield call(
      callApiDownload,
      "get",
      `${action.meta ? "/customer-panel" : ""}/accounting${action.meta ? "/invoices" : ""}/${invoiceNumberEncoded}/pdf`,
      action.payload.pdfName
    );

    downloadFile(res, action.payload.pdfName);
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Błąd podczas pobierania PDF")
    );
  }
}

function* handleGetCollectiveDebitCorrectionPdf(
  action: ReturnType<typeof getCollectiveDebitCorrectionPdf>
) {
  try {
    const documentNumberEncoded = action.payload.id.replaceAll("/", "-");
    const res: Response = yield call(
      callApiDownload,
      "get",
      `/accounting/collective-corrections/collective-debit-note-corrections/${documentNumberEncoded}/pdf`,
      action.payload.pdfName
    );
    downloadFile(res, action.payload.pdfName);
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Błąd podczas pobierania PDF")
    );
  }
}

function* handleGetCollectiveCorrectionPdf(
  action: ReturnType<typeof getCollectiveCorrectionPdf>
) {
  try {
    const documentNumberEncoded = action.payload.id.replaceAll("/", "-");
    const urlDistrigo =
      "/accounting/collective-corrections/collective-invoice-corrections";
    const urlCP = `/customer-panel/accounting/collective-invoice-corrections`;
    const res: Response = yield call(
      callApiDownload,
      "get",
      `${action.meta ? urlCP : urlDistrigo}/${documentNumberEncoded}/pdf`,
      action.payload.pdfName
    );

    downloadFile(res, action.payload.pdfName);
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Błąd podczas pobierania PDF")
    );
  }
}

function* handlePreviewCollectiveCorrectionPdf(
  action: ReturnType<typeof previewCollectiveCorrectionPdf>
) {
  try {
    const res: Response = yield call(
      callApiDownload,
      "post",
      `/accounting/collective-corrections/pdf-preview`,
      "",
      action.payload.lines
    );

    if (res) {
      downloadFile(res, "preview.pdf");
    }
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas podglądu PDF"));
  }
}

function* handleSelectGoodsDispatchNote(
  action: ReturnType<typeof selectGoodsDispatchNote>
) {
  try {
    const goodsDispatchNote: IGoodsDispatchNote = yield call(
      callApi,
      "get",
      `/goods-dispatch-notes/${action.payload}`
    );

    yield put(GoodsDispatchNoteSelected(goodsDispatchNote));
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas pobierania WZ"));
  }
}

function* handleSelectGoodsReceivedNote(
  action: ReturnType<typeof selectGoodsReceivedNote>
) {
  try {
    const goodsReceivedNote: IGoodsReceivedNote = yield call(
      callApi,
      "get",
      `/goods-received-notes/${action.payload}`
    );

    yield put(goodsReceivedNoteSelected(goodsReceivedNote));
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas pobierania PZ"));
  }
}

function* handleSelectInternalConsumptionNote(
  action: ReturnType<typeof selectInternalConsumptionNote>
) {
  try {
    const internalConsumptionNote: IInternalConsumptionNote = yield call(
      callApi,
      "get",
      `/internal-consumptions/${action.payload}`
    );

    yield put(internalConsumptionNoteSelected(internalConsumptionNote));
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd podczas pobierania RW"));
  }
}

function* handleSelectInvoiceErp(action: ReturnType<typeof selectInvoiceErp>) {
  try {
    const invoiceErp: IInvoiceErp = yield call(
      callApi,
      "get",
      `/invoices/erp/${action.payload}`
    );

    yield put(invoiceErpSelected(invoiceErp));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Błąd podczas pobierania dokumentu ERP"
      )
    );
  }
}

function* handleSelectGoodsDispatchNotePostponed(
  action: ReturnType<typeof selectGoodsDispatchNotePostponed>
) {
  try {
    const goodsDispatchNotePostponed: IGoodsDispatchNotePostponed = yield call(
      callApi,
      "get",
      `/goods-dispatch-notes/postponed-parts/${action.payload}`
    );

    yield put(goodsDispatchNotePostponedSelected(goodsDispatchNotePostponed));
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Błąd podczas pobierania zakolejkowanych elementów"
      )
    );
  }
}

function* handleFetchFinalInvoice(
  action: ReturnType<typeof fetchFinalInvoice>
) {
  try {
    const invoiceNumberEncoded = action.payload.replaceAll("/", "-");
    const finalInvoice: Response = yield call(
      callApi,
      "get",
      `/accounting/final-invoices/${invoiceNumberEncoded}`
    );

    yield put(fetchFinalInvoiceSuccess(finalInvoice));
  } catch (error) {
    yield put(
      handleError(error as Error, false, "Błąd podczas pobierania linii faktur")
    );
  }
}

function* handleUploadCollectiveCorrections(
  action: ReturnType<typeof uploadCollectiveCorrections>
) {
  try {
    const parsedLines: [] = yield call(
      callApiUploadFile,
      `/accounting/collective-corrections/import`,
      action.payload,
      "file"
    );

    if (!parsedLines.hasOwnProperty("error")) {
      yield put(loadCollectiveCorrectionsLines(parsedLines));
    } else {
      yield put(loadCollectiveCorrectionsLinesError(parsedLines));
    }
  } catch (error) {
    yield put(loadCollectiveCorrectionsLinesError(""));
    yield handleFileUploadError(error as ApiError);
  }
}

function* handleConfirmCollectiveCorrections(
  action: ReturnType<typeof confirmCollectiveCorrectionsLines>
) {
  try {
    yield call(
      callApi,
      "post",
      `/accounting/collective-corrections`,
      action.payload
    );
    yield put(confirmCollectiveCorrectionSuccess());
  } catch (error) {
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało się utworzyć korekt zbiorczych"
      )
    );
  }
}

function* handleExportDocumentOnRequest(
  action: ReturnType<typeof exportDocumentOnRequest>
) {
  try {
    let filters = "";

    const { url, params } = action.payload;

    const payloadFilters: any[] = params.filters;
    payloadFilters.forEach((filter: any, index) => {
      filters += `${index === 0 ? "?" : "&"}${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    yield call(callApi, "POST", `${url}${filters}`);
    yield put(exportDocumentOnRequestSuccess());
    toastSuccess(
      "Złożono prośbę o eksport",
      `Twoja prośba o wyeksportowanie wybranych zamówień została zarejestrowana. Proszę czekać na plik który zostanie wysłany w podanym adresie email w ciągu 24 godzin.`,
      { timeOut: 10000 }
    );
  } catch (e) {
    const error = e as Error;
    yield put(exportDocumentOnRequestError());
    yield put(handleError(error, false, "Błąd eksportu", error.message));
  }
}

function* watchFetchInvoices() {
  yield takeEvery(InvoicesActionTypes.FETCH_INVOICES, handleFetchInvoices);
}

function* watchFetchInvoicesWithProvision() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICES_WITH_PROVISION,
    handleFetchInvoicesWithProvision
  );
}

function* watchFetchInvoicesCorrections() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICES_CORRECTIONS,
    handleFetchInvoicesCorrections
  );
}

function* watchFetchGoodsDispatchNotes() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_GOODS_DISPATCH_NOTES,
    handleFetchGoodsDispatchNotes
  );
}

function* watchFetchGoodsReceivedNotes() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_GOODS_RECEIVED_NOTES,
    handleFetchGoodsReceivedNotes
  );
}

function* watchFetchCollectiveCorrections() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_COLLECTIVE_CORRECTIONS,
    handleFetchCollectiveCorrections
  );
}

function* watchFetchCollectiveDebitNoteCorrections() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_COLLECTIVE_DEBIT_NOTE_CORRECTIONS,
    handleFetchCollectiveDebitNoteCorrections
  );
}

function* watchFetchInternalConsumptionNotes() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INTERNAL_CONSUMPTION_NOTES,
    handleFetchInternalConsumptionNotes
  );
}

function* watchFetchInvoicesErp() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICES_ERP,
    handleFetchInvoicesErp
  );
}

function* watchFetchGoodsDispatchNotePostponed() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_GOOD_DISPATCH_NOTE_POSTPONED,
    handleFetchGoodsDispatchNotePostponed
  );
}

function* watchCreateInvoiceCorrection() {
  yield takeEvery(
    InvoicesActionTypes.CREATE_INVOICE_CORRECTION,
    handleCreateInvoiceCorrection
  );
}

function* watchCreateInvoiceTransport() {
  yield takeEvery(
    InvoicesActionTypes.CREATE_INVOICE_TRANSPORT,
    handleCreateInvoiceTransport
  );
}

function* watchGetInvoicePdf() {
  yield takeEvery(InvoicesActionTypes.GET_INVOICE_PDF, handleGetInvoicePdf);
}

function* watchGetCollectiveDebitCorrectionPdf() {
  yield takeEvery(
    InvoicesActionTypes.GET_COLLECTIVE_DEBIT_CORRECTION_PDF,
    handleGetCollectiveDebitCorrectionPdf
  );
}

function* watchGetCollectiveCorrectionPdf() {
  yield takeEvery(
    InvoicesActionTypes.GET_COLLECTIVE_CORRECTION_PDF,
    handleGetCollectiveCorrectionPdf
  );
}

function* watchSelectGoodsDispatchNote() {
  yield takeEvery(
    InvoicesActionTypes.SELECT_GOODS_DISPATCH_NOTE,
    handleSelectGoodsDispatchNote
  );
}

function* watchSelectGoodsReceivedNote() {
  yield takeEvery(
    InvoicesActionTypes.SELECT_GOODS_RECEIVED_NOTE,
    handleSelectGoodsReceivedNote
  );
}

function* watchSelectInternalConsumptionNote() {
  yield takeEvery(
    InvoicesActionTypes.SELECT_INTERNAL_CONSUMPTION_NOTE,
    handleSelectInternalConsumptionNote
  );
}

function* watchSelectInvoiceErp() {
  yield takeEvery(
    InvoicesActionTypes.SELECT_INVOICE_ERP,
    handleSelectInvoiceErp
  );
}

function* watchSelectGoodsDispatchNotePostponed() {
  yield takeEvery(
    InvoicesActionTypes.SELECT_GOOD_DISPATCH_NOTE_POSTPONED,
    handleSelectGoodsDispatchNotePostponed
  );
}

function* watchRetrySent() {
  yield takeEvery(InvoicesActionTypes.INVOICE_RETRY_SENT, handleRetrySent);
}

function* watchFetchInvoiceFinal() {
  yield takeLatest(
    InvoicesActionTypes.FETCH_INVOICE_FINAL,
    handleFetchFinalInvoice
  );
}

function* watchUploadCollectiveCorrections() {
  yield takeEvery(
    InvoicesActionTypes.UPLOAD_COLLECTIVE_CORRECTIONS,
    handleUploadCollectiveCorrections
  );
}

function* watchConfirmCollectiveCorrections() {
  yield takeEvery(
    InvoicesActionTypes.CONFIRM_COLLECTIVE_CORRECTIONS,
    handleConfirmCollectiveCorrections
  );
}

function* watchPreviewCollectiveCorrectionPdf() {
  yield takeEvery(
    InvoicesActionTypes.PREVIEW_COLLECTIVE_CORRECTION_PDF,
    handlePreviewCollectiveCorrectionPdf
  );
}

function* watchFetchDebitNotes() {
  yield takeEvery(InvoicesActionTypes.FETCH_DEBIT_NOTES, handleFetchDebitNotes);
}

function* watchFetchDebitNotesCorrections() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_DEBIT_NOTE_CORRECTIONS,
    handleFetchDebitNotesCorrections
  );
}

function* watchFetchInvoiceLines() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICE_LINES,
    handleFetchInvoiceLines
  );
}

function* watchFetchInvoiceSchedulers() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICE_SCHEDULERS,
    handleFetchInvoiceSchedulers
  );
}

function* watchFetchInvoiceLinesSchedulers() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICE_LINES_SCHEDULERS,
    handleFetchInvoiceLinesSchedulers
  );
}

function* watchFetchInvoicesWithDebitNotes() {
  yield takeEvery(
    InvoicesActionTypes.FETCH_INVOICES_WITH_DEBIT_NOTES,
    handleFetchInvoicesWithDebitNotes
  );
}

function* watchFetchMMData() {
  yield takeEvery(InvoicesActionTypes.FETCH_MM_DATA, handleFetchMMData);
}

function* watchSelectMM() {
  yield takeEvery(InvoicesActionTypes.SELECT_MM, handleSelectMM);
}

function* watchExportOnRequest() {
  yield takeEvery(
    InvoicesActionTypes.EXPORT_ON_REQUEST,
    handleExportDocumentOnRequest
  );
}

function* invoicesSaga() {
  yield all([
    fork(watchFetchInvoices),
    fork(watchFetchInvoicesCorrections),
    fork(watchCreateInvoiceCorrection),
    fork(watchFetchGoodsDispatchNotes),
    fork(watchFetchGoodsReceivedNotes),
    fork(watchFetchInternalConsumptionNotes),
    fork(watchCreateInvoiceTransport),
    fork(watchFetchInvoicesErp),
    fork(watchFetchGoodsDispatchNotePostponed),
    fork(watchGetInvoicePdf),
    fork(watchSelectGoodsDispatchNote),
    fork(watchSelectGoodsReceivedNote),
    fork(watchSelectInternalConsumptionNote),
    fork(watchSelectInvoiceErp),
    fork(watchSelectGoodsDispatchNotePostponed),
    fork(watchFetchInvoicesWithProvision),
    fork(watchRetrySent),
    fork(watchFetchInvoiceFinal),
    fork(watchUploadCollectiveCorrections),
    fork(watchFetchCollectiveCorrections),
    fork(watchConfirmCollectiveCorrections),
    fork(watchFetchCollectiveDebitNoteCorrections),
    fork(watchGetCollectiveDebitCorrectionPdf),
    fork(watchGetCollectiveCorrectionPdf),
    fork(watchPreviewCollectiveCorrectionPdf),
    fork(watchFetchDebitNotes),
    fork(watchFetchDebitNotesCorrections),
    fork(watchFetchInvoiceLines),
    fork(watchFetchInvoiceSchedulers),
    fork(watchFetchInvoiceLinesSchedulers),
    fork(watchFetchInvoicesWithDebitNotes),
    fork(watchFetchMMData),
    fork(watchSelectMM),
    fork(watchExportOnRequest),
  ]);
}

export default invoicesSaga;
