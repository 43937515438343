import { Button, Header, Modal } from "semantic-ui-react";

import React, { useCallback, useMemo } from "react";

import { WarningModalProps } from "~/pages/Administration/lib/MenageUserRole/WarningModal/constants";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { FormattedMessage } from "react-intl";

const WarningModal: React.FC<WarningModalProps> = ({
  children,
  handleCancel,
  open,
  roles,
  userName,
  handleRemove,
}) => {
  const isAdministrationViewRole = useMemo(
    () =>
      roles.find(
        (role) => role.name === AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_VIEW
      ),
    [roles]
  );

  const getDescription = useCallback(() => {
    const index = roles.findIndex(
      (role) => role.name === AVAILABLE_ROLE_ENUM.LDC_ADMINISTRATION_VIEW
    );

    return roles?.[index]?.description;
  }, [roles]);

  return (
    <Modal
      size="tiny"
      trigger={children}
      open={open}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="group" content="Odebranie uprawnień użytkownikowi" />
      <Modal.Content>
        <p>
          <FormattedMessage
            id="app.administration.users.warning.info"
            defaultMessage="app.administration.users.warning.info"
            values={{
              roleName: (
                <strong>
                  {isAdministrationViewRole
                    ? getDescription()
                    : roles?.[0]?.description}
                </strong>
              ),
              userName: <strong>{userName}</strong>,
              tab: (
                <strong>
                  {isAdministrationViewRole ? (
                    <FormattedMessage id="app.administration" />
                  ) : (
                    <FormattedMessage id="app.administration.users" />
                  )}
                </strong>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage id="app.administration.users.warning.question" />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={<FormattedMessage id="app.yes" />}
          onClick={handleRemove}
          color="orange"
        />
        <Button
          content={<FormattedMessage id="app.no" />}
          onClick={handleCancel}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
