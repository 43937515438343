import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import OrdersModal from "~/pages/Customers/Details/lib/OrdersModal";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { ApplicationState } from "~/store";
import { fetchFactors } from "~/store/factors/actions";
import { CustomerPaymentFactor } from "~/store/factors/types";
import { parsePrice } from "~/utils/parsePrice";
import translations from "~/utils/translations";

type TReduxActions = {
  fetchFactors: typeof fetchFactors;
};

type TReduxState = {
  customerPaymentFactors: CustomerPaymentFactor[];
  loading: boolean;
  downloadXLSPending: boolean;
};

type TProps = TReduxActions & TReduxState & { refreshTrigger: number };

const AccountingCPLimits = ({
  customerPaymentFactors,
  fetchFactors,
  downloadXLSPending,
  refreshTrigger,
  loading,
}: TProps) => {
  const { payerId } = useClientPanelRoles();

  const getFactors = useCallback(() => {
    payerId && fetchFactors(payerId, true);
  }, [payerId, fetchFactors]);

  useEffect(() => {
    getFactors();
  }, [getFactors]);

  const getPrice = useCallback((value: number | undefined | null) => {
    return value ? parsePrice(value, true) : parsePrice(0, true);
  }, []);

  useEffect(() => {
    if (refreshTrigger) {
      getFactors();
    }
  }, [refreshTrigger, getFactors]);

  return (
    <div style={{ display: "flex", gap: 15 }}>
      {loading ? (
        <CommonLoader loading={loading} />
      ) : (
        customerPaymentFactors.map((factor, index) => (
          <DetailCard
            titleFormat={true}
            title={`Limit (wartości brutto) - Faktor ${index + 1}`}
            id={`limit - factor ${index + 1}`}
            key={`limit - factor ${index + 1}`}
            width={6}
          >
            <CommonLoader loading={false} />

            <SmartLabel
              label={translations.format(
                "app.customer.factoring.factorLimitAgreementAmount"
              )}
              value={getPrice(factor.factorLimitAgreementAmount)}
              align="left"
              icon="info circle"
              description={
                "Przyznany Kontrahentowi przez Faktora Limit na zakup części zamiennych"
              }
            />
            <SmartLabel
              label={translations.format(
                "app.customer.factoring.invoiceNotPaid"
              )}
              value={getPrice(factor.factorUnpaidInvoicesAmount)}
              align="left"
              icon="info circle"
              description={
                "Wartość wszystkich faktur niespłaconych niezależnie od terminu płatności, skupionych przez Faktora"
              }
            />

            <SmartLabel
              label={translations.format(
                "app.customer.factoring.assignedFaktor"
              )}
              value={getPrice(factor.factorLimitAmount)}
              align="left"
              icon="info circle"
              description={
                "Przydzielony w danym dniu (aktualizowany dwa razy dziennie) przez Faktora limit na zakup części zamiennych"
              }
            />
            <SmartLabel
              label={translations.format(
                "app.customer.factoring.invoiceNotBought"
              )}
              value={getPrice(factor.factorNotBoughtInvoicesAmount)}
              align="left"
              icon="info circle"
              description={
                "Wartość faktur niespłaconych niezależnie od terminu płatności, niezabezpieczonych przez Faktora"
              }
            />
            <SmartLabel
              label={translations.format(
                "app.customer.factoring.assignedDistrigo"
              )}
              value={getPrice(factor.currentDistrigoLimit?.amount)}
              align="left"
              icon="info circle"
              description={
                "Przyznany Kontrahentowi przez Distrigo limit na zakup części zamiennych"
              }
            />
            <OrdersModal
              deliveryCustomerRRID={payerId || ""}
              factorId={factor.paymentFactorId}
              downloadXLSPending={downloadXLSPending}
              forClientPanel={true}
            ></OrdersModal>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
            <SmartLabel
              label={translations.format("app.customer.factoring.assignedSum")}
              value={getPrice(factor.currentLimitAssigned)}
              align="left"
              icon="info circle"
              description={
                "„Przydzielony [Faktor]” minus „Nieskupione [Faktor]” plus „Przydzielony [Distrigo]”"
              }
            />
            <p style={{ marginTop: "40px" }}></p>

            <SmartLabel
              label={translations.format("app.customer.factoring.ordered")}
              value={getPrice(factor.ordersAmount)}
              align="left"
              icon="info circle"
              description={
                "Wartość wszystkich faktur wystawionych, ale jeszcze nie przesłanych do Faktora"
              }
            />
            <SmartLabel
              label={translations.format("app.customer.factoring.invoiced")}
              value={getPrice(factor.invoicesAmount)}
              align="left"
              icon="info circle"
              description={
                "Wartość wszystkich faktur wystawionych, ale jeszcze nie przesłanych do Faktora"
              }
            />

            <Divider />
            <SmartLabel
              label={translations.format("app.customer.factoring.available")}
              value={getPrice(factor.currentLimitTotal)}
              align="left"
              icon="info circle"
              description={
                "„Przydzielony [SUMA]” minus „Faktury” minus  „Zamówienia”"
              }
            />
          </DetailCard>
        ))
      )}
    </div>
  );
};

const mapStateToProps = ({ factor }: ApplicationState): TReduxState => ({
  customerPaymentFactors: factor.customerPaymentFactors,
  loading: factor.loading,
  downloadXLSPending: factor.downloadXLSPending,
});

const mapDispatchToProps: TReduxActions = {
  fetchFactors,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingCPLimits);
