import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';

import { DetailCardAnnotationsProps } from '~/pages/Orders/Details/lib/constants';

const DetailCardAnnotations = ({ shrink, renderTextArea }: DetailCardAnnotationsProps) => (
  <DetailCard
    title="app.adnotation"
    id="annotations"
    key="annotations"
    minHeight={40}
    width={shrink ? 2.75 : 4}
    smallWidth={5.33}
  >
    {renderTextArea('comments', {
      label: 'Uwagi klienta',
      rows: 6,
      description: 'Dodatkowe informacje od klienta na temat zamówienia',
    })}
  </DetailCard>
);

export default DetailCardAnnotations;
