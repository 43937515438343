import { connect } from "react-redux";
import {
  Table,
  Modal,
  Button,
  Icon,
  Checkbox,
  CheckboxProps,
} from "semantic-ui-react";
import { Notification } from "~/store/notifications/types";
import { fetchIndividualNotification } from "~/store/notifications/actions";
import { ApplicationState } from "~/store";
import { parseDate, parseDateNoTime } from "~/utils/dateUtils";

import React from "react";
import dictionariesStatic from "~/store/dictionaries/static";
import mapDictionary from "~/components/MapDictionary/mapDictionary";
import translations from "~/utils/translations";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import IconHover from "~/components/IconHover/IconHover";

import "./notifications.scss";

interface Props {
  notifications: Notification[];
  columns: any[];
  readMessage: (id: string) => void;
  toggleNotificationsToRead: (id: string) => void;
  checkedToRead: string[];
  uniqueNotification: Notification;
  params: any[];
}

interface NotificationToPreview extends Notification {
  open: boolean;
}

interface TReduxActions {
  fetchIndividualNotification: typeof fetchIndividualNotification;
}

interface State {
  notificationToPreview?: NotificationToPreview;
}

type TList = Props & TReduxActions;

class NotificationsList extends React.Component<TList, State> {
  state: State = {
    notificationToPreview: undefined,
  };

  componentDidUpdate(prevProps: TList, prevState: State) {
    if (prevProps.uniqueNotification.id !== this.props.uniqueNotification.id) {
      this.setModalPreviewMessage(
        this.props.uniqueNotification as NotificationToPreview
      );
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      JSON.stringify(nextProps.columns) !==
        JSON.stringify(this.props.columns) ||
      JSON.stringify(nextProps.notifications) !==
        JSON.stringify(this.props.notifications) ||
      JSON.stringify(nextProps.checkedToRead) !==
        JSON.stringify(this.props.checkedToRead) ||
      JSON.stringify(nextProps.uniqueNotification.id) !==
        JSON.stringify(this.props.uniqueNotification.id)
    );
  }

  private notificationListDropdown(notification: Notification): JSX.Element {
    const { fetchIndividualNotification } = this.props;
    return (
      <IconHover
        name="arrow right"
        title="Podgląd"
        onClick={() => {
          if (this.state.notificationToPreview && this.state.notificationToPreview.id === notification.id) {
            this.handleModalPreviewToggle(this.state.notificationToPreview, true)
          } else {
            fetchIndividualNotification(notification.id);
          }
        }}
      />
    );
  }

  private setModalPreviewMessage(
    notificationToPreview: NotificationToPreview
  ): void {
    if (!notificationToPreview.read) {
      this.props.readMessage(notificationToPreview.id);
    }
    this.handleModalPreviewToggle(notificationToPreview, true);
  }

  private handleModalPreviewToggle(
    notificationToPreview: NotificationToPreview,
    status: boolean
  ): void {
    this.setState({
      notificationToPreview: {
        ...notificationToPreview,
        open: status,
      },
    });
    this.forceUpdate();
  }

  private renderHTMLContent(message: string): JSX.Element {
    const { params } = this.props;
    const paramsToRender = [...params];
    const args = translations
      .format(`app.notification.description.${message}`)
      .match(/\{([^}]+)\}/g);
    paramsToRender.splice(0, args != null ? args.length : 0);
    return (
      <>
        {translations
          .format(`app.notification.description.${message}`, params)
          .split("\n")
          .map((label, index) => (
            <p key={index}>{label}</p>
          ))}
        {paramsToRender.length > 0 && <hr></hr>}
        {paramsToRender.map((label, index) => (
          <p key={index}>{label}</p>
        ))}
      </>
    );
  }

  private modalPreviewMessage(): JSX.Element {
    const { notificationToPreview } = this.state;
    const { uniqueNotification } = this.props;

    if (notificationToPreview) {
      return (
        <Modal open={notificationToPreview.open} size="tiny">
          <Modal.Header>
            <Icon name="envelope" />
            {mapDictionary(
              String(uniqueNotification.name),
              dictionariesStatic.NOTIFICATION_NAME || [],
              true,
              true
            )}
          </Modal.Header>
          <Modal.Content style={{ overflowY: "auto", maxHeight: "500px" }}>
            {this.renderHTMLContent(uniqueNotification.name)}
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() =>
                this.handleModalPreviewToggle(notificationToPreview, false)
              }
            >
              Zamknij
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return <></>;
  }

  private getCell(notification: any, column: any): JSX.Element {
    const name = column.name;
    switch (name) {
      case "type":
        return (
          <Table.Cell key={name}>
            {NotificationsList.getMethodCell(notification.type)}
          </Table.Cell>
        );
      case "time":
        return (
          <Table.Cell key={name}>{parseDate(notification.time)}</Table.Cell>
        );
      case "title":
        return (
          <Table.Cell key={name}>{parseDate(notification.title)}</Table.Cell>
        );
      case "dateFrom":
      case "dateTo":
        return (
          <Table.Cell key={name}>
            {parseDateNoTime(notification.time)}
          </Table.Cell>
        );
      default:
        if (column.dictionaryName || column.dictionary) {
          return (
            <Table.Cell key={name}>
              {mapDictionary(
                String(notification[name]),
                column.dictionary || [],
                true,
                true
              )}
            </Table.Cell>
          );
        }
        return <Table.Cell key={name}>{notification[name]}</Table.Cell>;
    }
  }

  private static getMethodCell(type: string): JSX.Element {
    let iconName;
    switch (type) {
      case "ERROR":
        iconName = "exclamation circle";
        break;
      case "INFO":
        iconName = "info circle";
        break;
      case "WARN":
        iconName = "exclamation triangle";
        break;
      case "STATUS":
        iconName = "sticky note";
        break;
      default:
        iconName = "minus";
        break;
    }
    //@ts-ignore: icon name Type
    return <Icon name={`${iconName}`} size="large" color="grey" />;
  }

  render() {
    const { notifications, columns, checkedToRead } = this.props;

    if (!notifications.length) {
      return <TableNoResults />;
    }

    return (
      <>
        {notifications.map((notification) => (
          <Table.Row
            key={notification.id}
            className={notification.read ? "old-message" : "new-message"}
          >
            <Table.Cell key={notification.id}>
              <Checkbox
                disabled={notification.read}
                key={notification.id}
                checked={!!checkedToRead.find((n) => n === notification.id)}
                onClick={(ev, props: CheckboxProps) =>
                  this.props.toggleNotificationsToRead(notification.id)
                }
              />
            </Table.Cell>
            {columns.map(
              (column) =>
                column.projection && this.getCell(notification, column)
            )}
            <Table.Cell className="col-dropdown-menu-sticky">
              {this.notificationListDropdown(notification)}
            </Table.Cell>
          </Table.Row>
        ))}
        {this.modalPreviewMessage()}
      </>
    );
  }
}

const mapStateToProps = ({ notifications }: ApplicationState) => ({
  params: notifications.uniqueNotification.params,
  uniqueNotification: notifications.uniqueNotification,
});

const mapDispatchToProps = {
  fetchIndividualNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
