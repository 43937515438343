import { useContext } from 'react';
import { AppContext } from '~/context/AuthContext';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';

export const useBackordersRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiBackorderProcessRole: boolean = context?.keycloak?.hasResourceRole(
    AVAILABLE_ROLE_ENUM.LDC_BACKORDER_PROCESS
  );

  return { userHaveLdcUiBackorderProcessRole };
};
