import React from "react";
import {
  Button,
  Container,
  Message,
} from "semantic-ui-react";
import translations from "~/utils/translations";

interface props {
  exportFP0536ToCsv: (fileName: string) => void;
  loading: boolean;
}

const HazardousGoods = (props: props): JSX.Element => {

  const {exportFP0536ToCsv, loading} = props;

  const downloadXLSFile = () => {
    exportFP0536ToCsv('FP0536')
  };
  
  return (
    <Container>
      <Message style={{ display: "flex", justifyContent: "space-between" }}>
        <Message.Content>
          {translations.format("app.adminitration.FP0536-download-info")}
        </Message.Content>
        <Button
          primary
          onClick={downloadXLSFile}
          disabled={loading}
          loading={loading}
          size="large"
          icon="download"
          children="Pobierz"
        />
      </Message>
    </Container>
  );
};

export default HazardousGoods;
