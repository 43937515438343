import { Table } from 'semantic-ui-react';
import { FormInputNumber, InputNumberOnChangeData } from '~/components/InputNumber/InputNumber';
import React from 'react';
import { QuantityTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';
import { validNumber } from '~/pages/Orders/lib/Add/utility';

const QuantityTableCell = ({ summary, line, changeQuantity }: QuantityTableCellProps) => {
  return (
    <Table.Cell>
      {!summary &&
      line.orderLineType !== 'PROVISION' &&
      line.orderLineType !== 'REPLACEMENT' &&
      line.orderLineType !== 'REPLACEMENT_ORIGINAL' ? (
        <FormInputNumber
          min={0}
          step={line.packingForceQuantity}
          fluid
          style={{ width: 100, opacity: 1 }}
          value={validNumber(line.quantity) ? line.quantity : 0}
          onBlur={() => changeQuantity(line.lineNumber, line.quantity, line.quantity, true)}
          onChange={(e: any, d: InputNumberOnChangeData) =>
            changeQuantity(line.lineNumber, d.value, line.quantity, false)
          }
          max={line.codes ? 1 : undefined}
        />
      ) : (
        <div style={{ width: 100 }}>{line.quantity}</div>
      )}
    </Table.Cell>
  );
};

export default QuantityTableCell;
