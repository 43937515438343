import { Reducer } from "redux";
import {
  CustomersActionTypes,
  CustomersState,
  PaginableCustomer,
} from "./types";
import { ErrorActionTypes } from "~/store/error/types";

export const initialCustomersState: CustomersState = {
  list: [],
  searchList: [],
  selected: undefined,
  created: undefined,
  loading: false,
  creating: false,
  meta: {
    page: 1,
    size: 15,
    pages: 1,
  },
  deliveryPoints: [],
  loadingDeliveryPoints: false,
  loadingPreImport: false,
  loadingImportAdd: false,
  loadingImportUpdate: false,
  preImportResults: undefined,
  importedAdd: false,
  importedUpdate: false,
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  currentPayer: null,
  loadingCurrentPayer: false,
  loadingAddNewPayer: false,
  loadingPayerHistoryList: false,
  payerHistoryList: [],
  downloadProductGroupPending: false,
  saveAdditionalDiscountPending: false,
  classesAndBrandsLoading: false,
  classesAndBrands: undefined,
  updateCustomerPending: false,
  classifications: []
};

const reducer: Reducer<CustomersState> = (
  state = initialCustomersState,
  action
): CustomersState => {
  switch (action.type) {
    case CustomersActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case CustomersActionTypes.FETCH_SUCCESS: {
      const payload = action.payload as PaginableCustomer;
      return {
        ...state,
        loading: false,
        list: payload.content,
        searchList: payload.content,
        meta: {
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case CustomersActionTypes.FETCH_SEARCH_SUCCESS: {
      return {
        ...state,
        searchList: action.payload.content,
      };
    }
    case CustomersActionTypes.FILTER_CUSTOMERS: {
      return { ...state, loading: true, list: [] };
    }
    case CustomersActionTypes.FETCH_DELIVERY_POINTS: {
      return { ...state, loadingDeliveryPoints: true, list: [] };
    }
    case CustomersActionTypes.FETCH_DELIVERY_POINTS_SUCCESS: {
      return {
        ...state,
        loadingDeliveryPoints: false,
        deliveryPoints: action.payload,
      };
    }
    case CustomersActionTypes.SELECT_CUSTOMER: {
      return { ...state, loading: true, selected: undefined };
    }
    case CustomersActionTypes.SELECTED: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
        created: undefined,
      };
    }
    case CustomersActionTypes.CLEAR_SELECTED: {
      return { ...state, selected: undefined };
    }
    case CustomersActionTypes.ADD_CUSTOMER: {
      return { ...state, creating: true };
    }
    case CustomersActionTypes.ADD_SUCCESS: {
      return { ...state, creating: false, created: action.payload };
    }
    case CustomersActionTypes.PRE_IMPORT: {
      return { ...state, loadingPreImport: true };
    }
    case CustomersActionTypes.PRE_IMPORT_SUCCESS: {
      return {
        ...state,
        loadingPreImport: false,
        preImportResults: action.payload,
      };
    }
    case CustomersActionTypes.IMPORT_ADD: {
      return { ...state, loadingImportAdd: true };
    }
    case CustomersActionTypes.IMPORT_ADD_SUCCESS: {
      return { ...state, loadingImportAdd: false, importedAdd: true };
    }
    case CustomersActionTypes.IMPORT_UPDATE: {
      return { ...state, loadingImportUpdate: true };
    }
    case CustomersActionTypes.IMPORT_UPDATE_SUCCESS: {
      return { ...state, loadingImportUpdate: false, importedUpdate: true };
    }
    case CustomersActionTypes.CLEAR_IMPORT: {
      return {
        ...state,
        loadingImportAdd: false,
        loadingImportUpdate: false,
        importedUpdate: false,
        importedAdd: false,
        loadingPreImport: false,
        preImportResults: undefined,
      };
    }
    case ErrorActionTypes.HANDLE_ERROR: {
      return {
        ...state,
        creating: false,
        preImportResults: undefined,
        loading: false,
        loadingDeliveryPoints: false,
        loadingPreImport: false,
        loadingImportAdd: false,
        loadingImportUpdate: false,
      };
    }
    case CustomersActionTypes.FETCH_CURRENT_PAYER: {
      return { ...state, loadingCurrentPayer: true };
    }
    case CustomersActionTypes.FETCH_CURRENT_PAYER_SUCCESS: {
      return {
        ...state,
        loadingCurrentPayer: false,
        currentPayer: action.payload,
      };
    }
    case CustomersActionTypes.FETCH_CURRENT_PAYER_FAILED: {
      return { ...state, loadingCurrentPayer: false };
    }
    case CustomersActionTypes.ADD_NEW_PAYER: {
      return { ...state, loadingAddNewPayer: true };
    }
    case CustomersActionTypes.ADD_NEW_PAYER_ACTION_STOP: {
      return {
        ...state,
        loadingAddNewPayer: false,
      };
    }
    case CustomersActionTypes.FETCH_PAYER_HISTORY_LIST: {
      return { ...state, loadingPayerHistoryList: true };
    }
    case CustomersActionTypes.FETCH_PAYER_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        loadingPayerHistoryList: false,
        payerHistoryList: action.payload,
      };
    }
    case CustomersActionTypes.FETCH_PAYER_HISTORY_LIST_FAILED: {
      return { ...state, loadingPayerHistoryList: false };
    }
    case CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP: {
      return { ...state, downloadProductGroupPending: true };
    }
    case CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP_ERROR:
    case CustomersActionTypes.DOWNLOAD_PRODUCTS_GRUP_SUCCESS: {
      return { ...state, downloadProductGroupPending: false };
    }
    case CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT: {
      return { ...state, saveAdditionalDiscountPending: true };
    }
    case CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT_SUCCESS: 
    case CustomersActionTypes.SAVE_ADDITIONAL_DISCOUNT_ERROR: {
        return { ...state, saveAdditionalDiscountPending: false };
      }
    case CustomersActionTypes.FETCH_CLASSES_AND_BRANDS: {
      return { ...state, classesAndBrandsLoading: true };
    }
    case CustomersActionTypes.FETCH_CLASSES_AND_BRANDS_SUCCESS: {
      return { ...state, classesAndBrands: action.payload, classesAndBrandsLoading: false}
    }
    case CustomersActionTypes.FETCH_CLASSES_AND_BRANDS_ERROR: {
      return { ...state, classesAndBrandsLoading: false };
    }
    case CustomersActionTypes.UPDATE_CUSOMER: {
      return { ...state, updateCustomerPending: true };
    }
    case CustomersActionTypes.UPDATE_CUSOMER_SUCCESS: {
      if (!state.selected) return {...state}
      const selected = {...state.selected}
      const detail = {...selected.detail}
      return { 
        ...state, 
        selected: {
          ...selected, 
          detail: {
            ...detail, 
            customerClasses: action.payload.customerClasses,
            customerBrands: action.payload.customerBrands
          }
        }, 
        updateCustomerPending: false}
    }
    case CustomersActionTypes.UPDATE_CUSOMER_ERROR: {
      return { ...state, updateCustomerPending: false };
    }
    case CustomersActionTypes.FETCH_CUSTOMER_CLASSIFICATIONS_SUCCESS: {
      return { ...state, classifications: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as customersReducer };
