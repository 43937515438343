import { Label } from 'semantic-ui-react';
import React from 'react';

const ChangePayerCell = ({ changePayer }: { changePayer: boolean }) => {
  return (
    <Label basic size="tiny" title={changePayer ? 'Zmieniono płatnika' : 'Nie zmieniono płatnika'}>
      {changePayer ? 'T' : 'N'}
    </Label>
  );
};

export default ChangePayerCell;
