import { action } from "typesafe-actions";
import {
  CustomerPaymentFactor,
  CustomerPaymentFactorDistrigoLimitDTO,
  CustomerPaymentFactorDTO,
  FactorActionTypes,
} from "./types";

export const fetchFactors = (customerId: string, forClientPanel?: boolean) =>
  action(FactorActionTypes.FETCH_FACTORS, { customerId }, forClientPanel);
export const fetchFactorsSuccess = (
  customerPaymentFactors: CustomerPaymentFactor[]
) =>
  action(FactorActionTypes.FETCH_FACTORS_SUCCESS, { customerPaymentFactors });

export const fetchFactorById = (customerId: string, factorId: string) =>
  action(FactorActionTypes.FETCH_FACTOR_BY_ID, { customerId, factorId });
export const fetchFactorByIdSuccess = (
  customerPaymentFactor: CustomerPaymentFactor
) =>
  action(FactorActionTypes.FETCH_FACTOR_BY_ID_SUCCESS, {
    customerPaymentFactor,
  });

export const changeFactorPriority = (
  customerId: string,
  factorId: string,
  priority: number
) =>
  action(FactorActionTypes.CHANGE_FACTOR_PRIORITY, {
    customerId,
    factorId,
    priority,
  });
export const changeFactorPrioritySuccess = (
  customerPaymentFactors: CustomerPaymentFactor[]
) =>
  action(FactorActionTypes.CHANGE_FACTOR_PRIORITY_SUCCESS, {
    customerPaymentFactors,
  });

export const changeFactorPaymentDue = (
  customerId: string,
  factorId: string,
  paymentDueId: number
) =>
  action(FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE, {
    customerId,
    factorId,
    paymentDueId,
  });
export const changeFactorPaymentDueSuccess = (
  customerPaymentFactors: CustomerPaymentFactor[]
) =>
  action(FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE_SUCCESS, {
    customerPaymentFactors,
  });
export const changeFactorPaymentDueFailed = () =>
  action(FactorActionTypes.CHANGE_FACTOR_PAYMENT_DUE_FAILED);

export const createFactor = (
  customerId: string,
  customerPaymentFactor: CustomerPaymentFactorDTO
) =>
  action(FactorActionTypes.CREATE_FACTOR, {
    customerPaymentFactor,
    customerId,
  });
export const createFactorSuccess = (factorId: string) =>
  action(FactorActionTypes.CREATE_FACTOR_SUCCESS, { factorId });

export const removeFactor = (customerId: string, factorId: string) =>
  action(FactorActionTypes.REMOVE_FACTOR, { customerId, factorId });
export const removeFactorSuccess = (removedFactorId: string) =>
  action(FactorActionTypes.REMOVE_FACTOR_SUCCESS, { removedFactorId });

export const addLimitDistrigo = (
  customerId: string,
  factorId: string,
  distrigoLimitDTO: CustomerPaymentFactorDistrigoLimitDTO
) =>
  action(FactorActionTypes.ADD_DISTRIGO_LIMIT, {
    distrigoLimitDTO,
    customerId,
    factorId,
  });
export const addLimitDistrigoSuccess = (
  customerPaymentFactor: CustomerPaymentFactor
) =>
  action(FactorActionTypes.ADD_DISTRIGO_LIMIT_SUCCESS, {
    customerPaymentFactor,
  });

export const importFactorLimits = (file: File) =>
  action(FactorActionTypes.IMPORT_FACTOR_LIMITS_FROM_BANK, { file });
export const importFactorLimitsSuccess = () =>
  action(FactorActionTypes.IMPORT_FACTOR_LIMITS_FROM_BANK_SUCCESS);

export const downloadFactorOrdersXls = (
  customerId: string,
  factorId: string,
  fileName: string,
  forClientPanel?: boolean
) =>
  action(
    FactorActionTypes.DOWNLOAD_FACTOR_ORDERS_XLS,
    {
      customerId,
      factorId,
      fileName,
    },
    forClientPanel
  );

export const downloadFactorOrdersXlsEnd = () =>
  action(FactorActionTypes.DOWNLOAD_FACTOR_ORDERS_XLS_END);
