import DetailCard from '~/components/DetailCard/DetailCard';
import { parseDate } from '~/utils/dateUtils';
import { Divider, Label } from 'semantic-ui-react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import React from 'react';
import { ApplicationState } from '~/store';
import { connect } from 'react-redux';
import { DetailCardStatusProps, DetailCardTReduxState } from '~/pages/Orders/Details/lib/constants';
import { DictionaryName } from '~/store/dictionaries/types';
import { ORDER_SOURCE_ENUM } from '~/store/orders/types';
import mapDictionary from '~/components/MapDictionary/mapDictionary';

const DetailCardStatus = ({ shrink, renderLabel, order, dictionaries }: DetailCardStatusProps) => (
  <DetailCard title="app.status" id="status" key="status" minHeight={40} width={shrink ? 4 : 4} smallWidth={5.33}>
    {order &&
      renderLabel(
        'Status',
        mapDictionary(order.status, dictionaries[DictionaryName.orderStatus] || [], true, true, true)
      )}
    {order && renderLabel('Data statusu', parseDate(order.statusDate))}
    {order && renderLabel('Utworzone', parseDate(order.orderDate))}
    <Divider />
    {order && renderLabel('Realizacja', `${order.realisationPercent} %`)}
    <SmartLabel
      label="Źródło"
      value={
        <Label basic color={order?.source === ORDER_SOURCE_ENUM.LDC ? 'orange' : 'grey'}>
          {order?.source}
        </Label>
      }
      align="left"
    />
    {order?.source === ORDER_SOURCE_ENUM.SERVICE_BOX && (
      <SmartLabel label="Numer" value={order.serviceBoxOrderNo} align="left" />
    )}
    {order && renderLabel('Wymuszony Kredyt Distrigo', order.forcedPaymentMethodType ? 'Tak' : 'Nie')}
  </DetailCard>
);

const mapStateToProps: (state: ApplicationState) => DetailCardTReduxState = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(DetailCardStatus);
