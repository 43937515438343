import { Reducer } from 'redux'
import { SpeederActionTypes, SpeederState } from './types'

export const initialSpeederState: SpeederState = {
    dateFrom: null,
    dateTo: null,
    error: null,
    exactDateFrom: null,
    exactDateTo: null,
    loading: false,
    result: {}
}

const reducer: Reducer<SpeederState> = (state = initialSpeederState, action): SpeederState => {
    switch (action.type) {
        case SpeederActionTypes.SPEEDER_GENERATE_LIGFACTQ_RAPORT:
        case SpeederActionTypes.SPEEDER_GENERATE_VENTESQ_RAPORT:
        case SpeederActionTypes.SPEEDER_GENERATE_STKVTH_RAPORT:
        case SpeederActionTypes.SPEEDER_GENERATE_STKVTEI_RAPORT: {
            return { ...state, loading: true }
        }
        case SpeederActionTypes.SPEEDER_GENERATE_RAPORT_SUCCESS: {
            return { ...state, loading: false, result: action.payload }
        }
        case SpeederActionTypes.SPEEDER_GENERATE_RAPORT_FAILED: {
            return { ...state, loading: false, error: action.payload }
        }
        case SpeederActionTypes.SAVE_DATA_FROM: {
            return { ...state, dateFrom: action.payload }
        }
        case SpeederActionTypes.SAVE_DATA_TO: {
            return { ...state, dateTo: action.payload }
        }
        case SpeederActionTypes.SAVE_EXACT_DATE_FROM: {
            return { ...state, exactDateFrom: action.payload }
        }
        case SpeederActionTypes.SAVE_EXACT_DATE_TO: {
            return { ...state, exactDateTo: action.payload }
        }
        default: {
            return state
        }
    }
}

export { reducer as speederReducer }