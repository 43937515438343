import React, { useCallback } from "react";
import { Table } from "semantic-ui-react";
import LinesTableHeaders from "~/pages/Orders/lib/Add/Table/TableHeaders";
import ReferenceTableCell from "~/pages/Orders/lib/Add/Table/TableBody/ReferenceTableCell";
import QuantityTableCell from "~/pages/Orders/lib/Add/Table/TableBody/QuantityTableCell";
import CodesTableCell from "~/pages/Orders/lib/Add/Table/TableBody/CodesTableCell";
import DescriptionTableCell from "~/pages/Orders/lib/Add/Table/TableBody/DescriptionTableCell";
import { parsePrice } from "~/utils/parsePrice";
import BonusTableCell from "~/pages/Orders/lib/Add/Table/TableBody/BonusTableCell";
import SellPriceCell from "~/pages/Orders/lib/Add/Table/TableBody/SellPriceCell";
import ReasonForRejectionTableCell from "~/pages/Orders/lib/Add/Table/TableBody/ReasonForRejectionTableCell";
import RemoveTableCell from "~/pages/Orders/lib/Add/Table/TableBody/RemoveTableCell";
import { toastInfo } from "~/utils/toast";
import { TLine } from "~/pages/Orders/lib/Add/constants";
import {
  calculateGross,
  calculateVat,
  updateIndexing,
  validNumber,
} from "~/pages/Orders/lib/Add/utility";
import { LinesTableProps } from "~/pages/Orders/lib/Add/Table/constants";
import { ORDER_TYPE_ENUM } from "~/store/orders/types";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import AddNewLine from "../Lines/AddNewLine";
import TotalAmount from "../Lines/TotalAmount";

const LinesTable = ({
  lines,
  summary,
  setLines,
  calculateSellPrice,
  setSelectedLine,
  setOpenModal,
  modalType,
  order,
  limit,
  addLine,
  deliveryPoint,
  currLimit,
  getTotalAmount,
}: LinesTableProps) => {
  const { isClientPanelUser } = useClientPanelRoles();
  const changeQuantity = (
    lineNumber: number,
    currentValue: number,
    previousValue: number,
    round: boolean
  ): void => {
    const copyLines = [...lines];
    const lineToUpdate = copyLines.find(
      (line) => line.lineNumber === lineNumber
    );

    if (lineToUpdate) {
      let value = Number(currentValue);
      if (round) {
        const step = lineToUpdate.packingForceQuantity || 1;
        if (value <= 0) {
          value = step;
        } else {
          value = Math.round(value / step) * step;
        }
      }
      lineToUpdate.quantity = value;
      if (lineToUpdate.orderLineType === "REPLACED") {
        const replacements = getReplacements(lineNumber, copyLines);
        replacements.forEach((replacement) => {
          replacement.quantity =
            replacement.packingForceQuantity *
            Math.ceil(
              (value * (replacement.replacementBaseQuantity || 1)) /
                (replacement.packingForceQuantity || 1)
            );
          const provision = getProvision(replacement.lineNumber, copyLines);
          if (provision) {
            provision.quantity = replacement.quantity;
          }
        });
      }
      if (lineToUpdate.orderLineType === "REPLACED_TO_ORIGINAL") {
        const replacedOriginalLine = copyLines.find(
          (line) => line.replacementReferenceId === lineToUpdate.referenceId
        );
        if (replacedOriginalLine) {
          replacedOriginalLine.quantity =
            replacedOriginalLine.packingForceQuantity *
            Math.ceil(
              (value * (replacedOriginalLine.replacementBaseQuantity || 1)) /
                (replacedOriginalLine.packingForceQuantity || 1)
            );
        }
      }
      const provision = getProvision(lineNumber, copyLines);
      if (provision) {
        provision.quantity = value;
      }
    }
    setLines(copyLines);
  };

  const changeReferenceId = (lineNumber: number, data: any): string => {
    const oldLine = lines.find((line) => line.lineNumber === lineNumber);
    const oldLineId = oldLine ? oldLine.referenceId : "";
    const found = data.product;
    let isPartOnLine = false;
    if (found) {
      if (lines.find((line) => line.referenceId === found.referenceId)) {
        toastInfo(
          "Część została już dodana",
          "Wybrana część znajduje się już w zamówieniu."
        );
        return "";
      }
      if (found.orderLineType === "REPLACED" && found.replacements.length > 0) {
        found.replacements.forEach((replacement: any) => {
          if (
            lines.find(
              (line) => line.referenceId === replacement.sparePart.referenceId
            )
          ) {
            toastInfo(
              "Część została już dodana",
              "Zamiennik " +
                replacement.sparePart.referenceId +
                " znajduje się już w zamówieniu."
            );
            isPartOnLine = true;
          }
        });
      }
      if (!isPartOnLine) {
        const newLines: TLine[] = [];
        lines.forEach((line) => {
          if (
            line.replacementReferenceId === oldLineId ||
            line.provisionReferenceId === oldLineId
          ) {
            // skip replacement or provision for previous line
          } else if (line.lineNumber !== lineNumber) {
            newLines.push({ ...line });
          } else {
            newLines.push({
              ...line,
              referenceId: found.referenceId,
              description: found.description,
              packingQuantity: found.packingQuantity,
              packingForceQuantity: found.packingForceQuantity,
              stock: found.stock,
              pnrPrice: found.pnrPrice,
              pvpPrice: found.pvpPrice,
              priceReductionDiscountCode: found.priceReductionDiscountCode,
              sellPrice: calculateSellPrice(found),
              quantity: found.packingForceQuantity,
              codes: found.codes ? { ...found.codes } : null,
              orderLineType: found.orderLineType,
              priceTaxCode: found.priceTax,
              bonus: setpriceReductionDiscountPercentValue(found),
              stockLevel: found.stockLevel,
            });
            if (found.orderLineType === "REPLACED_TO_ORIGINAL") {
              const { quantity, sparePart } = found.replacementToOriginal;
              newLines.push({
                ...sparePart,
                quantity,
                replacementReferenceId: found.referenceId,
                sellPrice: sparePart.pnrPrice,
                priceTaxCode: sparePart.priceTax,
              });
            }
            if (
              found.orderLineType === "REPLACED" &&
              found.replacements.length > 0
            ) {
              found.replacements.forEach((replacement: any) => {
                const {
                  referenceId,
                  packingForceQuantity,
                  description,
                  stock,
                  pnrPrice,
                  pvpPrice,
                  priceReductionDiscountCode,
                  orderLineType,
                  codes,
                  priceTax,
                  provision,
                } = replacement.sparePart;
                newLines.push({
                  lineNumber: 0,
                  referenceId,
                  quantity: packingForceQuantity,
                  description,
                  packingQuantity: packingForceQuantity,
                  packingForceQuantity,
                  stock,
                  priceReductionDiscountCode,
                  pnrPrice,
                  pvpPrice,
                  sellPrice: pnrPrice,
                  orderLineType,
                  codes: codes ? { ...codes } : null,
                  replacementReferenceId: found.referenceId,
                  replacementBaseQuantity: replacement.quantity,
                  priceTaxCode: priceTax,
                  bonus: setpriceReductionDiscountPercentValue(found),
                  stockLevel: found.stockLevel,
                  priceDRZ: null,
                });
                if (provision) {
                  newLines.push({
                    lineNumber: 0,
                    referenceId: provision.referenceId,
                    quantity: replacement.quantity * found.packingForceQuantity,
                    description: provision.description,
                    packingQuantity: provision.packingQuantity,
                    packingForceQuantity: found.packingForceQuantity,
                    stock: provision.stock,
                    pnrPrice: provision.pnrPrice,
                    pvpPrice: provision.pvpPrice,
                    priceReductionDiscountCode:
                      provision.priceReductionDiscountCode,
                    sellPrice: provision.pnrPrice,
                    orderLineType: provision.orderLineType,
                    provisionReferenceId: referenceId,
                    priceTaxCode: provision.priceTax,
                    bonus: setpriceReductionDiscountPercentValue(found),
                    stockLevel: found.stockLevel,
                    priceDRZ: null,
                  });
                }
              });
            } else if (found.provision) {
              newLines.push({
                lineNumber: 0,
                referenceId: found.provision.referenceId,
                quantity: found.packingForceQuantity,
                description: found.provision.description,
                packingQuantity: found.provision.packingQuantity,
                packingForceQuantity: found.packingForceQuantity,
                stock: found.provision.stock,
                pnrPrice: found.provision.pnrPrice,
                pvpPrice: found.provision.pvpPrice,
                priceReductionDiscountCode: found.priceReductionDiscountCode,
                sellPrice: found.provision.pnrPrice,
                orderLineType: found.provision.orderLineType,
                provisionReferenceId: found.referenceId,
                priceTaxCode: found.provision.priceTax,
                bonus: setpriceReductionDiscountPercentValue(found),
                stockLevel: found.stockLevel,
                priceDRZ: null,
              });
            }
          }
        });
        updateIndexing(newLines);
        setLines(newLines);
        return found.referenceId;
      }
    }
    return "";
  };

  const setpriceReductionDiscountPercentValue = (
    found: any
  ): number | undefined => {
    if (
      order.type === ORDER_TYPE_ENUM.BONUS ||
      order.type === ORDER_TYPE_ENUM.BONUS_PLUS ||
      order.type === ORDER_TYPE_ENUM.SPECIAL
    ) {
      return 0;
    } else {
      return found.bonus || undefined;
    }
  };

  const getProvision = (
    lineNumber: number,
    lines: TLine[]
  ): TLine | undefined => {
    const updatingLine = lines.find((line) => line.lineNumber === lineNumber);
    if (updatingLine) {
      return lines.find(
        (line) => line.provisionReferenceId === updatingLine.referenceId
      );
    }
  };

  const getReplacements = (lineNumber: number, lines: TLine[]): TLine[] => {
    const updatingLine = lines.find((line) => line.lineNumber === lineNumber);
    if (updatingLine) {
      const replacements = lines.filter(
        (line) => line.replacementReferenceId === updatingLine.referenceId
      );
      const innerReplacements = replacements
        .map((line) => getReplacements(line.lineNumber, lines))
        .flat();
      return [...replacements, ...innerReplacements];
    }
    return [];
  };

  const removeLine = (lineIn: TLine): void => {
    const replacements = getReplacements(lineIn.lineNumber, lines).map(
      (line) => line.lineNumber
    );
    const provisions = [
      getProvision(lineIn.lineNumber, lines),
      ...replacements.map((lineNo) => getProvision(lineNo, lines)),
    ]
      .filter((line) => !!line)
      .map((line) => line!.lineNumber);

    const newLines = [...lines].filter(
      (line) =>
        !(
          line.lineNumber === lineIn.lineNumber ||
          replacements.indexOf(line.lineNumber) !== -1 ||
          provisions.indexOf(line.lineNumber) !== -1
        )
    );

    updateIndexing(newLines);
    setLines(newLines);
  };

  const handleAddCoding = (line: TLine): void => {
    setSelectedLine(line);
    modalType.current = "CODES";
    setOpenModal(true);
  };

  const renderFooter = useCallback(
    () => (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="100" />
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell
            // colSpan value depnends from add order lines columns amount
            colSpan={isClientPanelUser ? "17" : "18"}
            style={{ background: "#fff", border: "none" }}
          >
            <AddNewLine order={order} summary={summary} addLine={addLine} />
          </Table.HeaderCell>
          <Table.HeaderCell
            colsSpan="1"
            style={{ background: "#fff", border: "none" }}
            className="col-dropdown-menu-sticky"
          >
            <TotalAmount
              deliveryPoint={deliveryPoint}
              currLimit={currLimit}
              getTotalAmount={getTotalAmount}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    ),
    [
      addLine,
      currLimit,
      deliveryPoint,
      getTotalAmount,
      order,
      summary,
      isClientPanelUser,
    ]
  );

  return !isClientPanelUser ? (
    <Table>
      <LinesTableHeaders />
      <Table.Body>
        {lines.map((line) => (
          <Table.Row key={line.lineNumber}>
            <Table.Cell collapsing>{line.lineNumber}</Table.Cell>
            <ReferenceTableCell
              line={line}
              summary={summary}
              changeReferenceId={changeReferenceId}
            />
            <QuantityTableCell
              line={line}
              summary={summary}
              changeQuantity={changeQuantity}
            />
            <CodesTableCell
              handleAddCoding={handleAddCoding}
              line={line}
              summary={summary}
            />
            <DescriptionTableCell line={line} />
            <Table.Cell>{line.packingQuantity}</Table.Cell>
            <Table.Cell>{line.stock}</Table.Cell>
            <Table.Cell textAlign="center">{line.stockLevel || 0}</Table.Cell>
            <Table.Cell>{parsePrice(line.pvpPrice)}</Table.Cell>
            <Table.Cell>{parsePrice(line.pnrPrice)}</Table.Cell>
            <Table.Cell>{line.priceReductionDiscountCode}</Table.Cell>
            <BonusTableCell
              limit={limit}
              setLines={setLines}
              order={order}
              line={line}
              summary={summary}
            />
            <Table.Cell>
              {line.priceDRZ ? parsePrice(line.priceDRZ) : "-"}
            </Table.Cell>
            <SellPriceCell
              setLines={setLines}
              order={order}
              line={line}
              summary={summary}
            />
            <Table.Cell>
              {parsePrice(
                validNumber(line.sellPrice) ? line.sellPrice * line.quantity : 0
              )}
            </Table.Cell>
            <Table.Cell>
              {parsePrice(
                calculateVat(
                  validNumber(line.sellPrice) ? line.sellPrice : 0,
                  line.quantity,
                  line.priceTaxCode
                )
              )}
            </Table.Cell>
            <Table.Cell>{parsePrice(calculateGross(line))}</Table.Cell>
            <Table.Cell>{line.promoCode || "-"}</Table.Cell>
            <ReasonForRejectionTableCell line={line} />
            <RemoveTableCell
              order={order}
              removeLine={removeLine}
              line={line}
              summary={summary}
            />
          </Table.Row>
        ))}
      </Table.Body>
      {renderFooter()}
    </Table>
  ) : (
    <Table>
      <LinesTableHeaders />
      <Table.Body>
        {lines.map((line) => (
          <Table.Row key={line.lineNumber}>
            <Table.Cell collapsing>{line.lineNumber}</Table.Cell>
            <ReferenceTableCell
              line={line}
              summary={summary}
              changeReferenceId={changeReferenceId}
            />
            <QuantityTableCell
              line={line}
              summary={summary}
              changeQuantity={changeQuantity}
            />
            <CodesTableCell
              handleAddCoding={handleAddCoding}
              line={line}
              summary={summary}
            />
            <DescriptionTableCell line={line} />
            <Table.Cell>{line.packingQuantity}</Table.Cell>
            <Table.Cell>{line.stock}</Table.Cell>
            <Table.Cell>{parsePrice(line.pvpPrice)}</Table.Cell>
            <Table.Cell>{parsePrice(line.pnrPrice)}</Table.Cell>
            <Table.Cell>{line.priceReductionDiscountCode}</Table.Cell>
            <BonusTableCell
              limit={limit}
              setLines={setLines}
              order={order}
              line={line}
              summary={summary}
            />
            <Table.Cell>
              {line.priceDRZ ? parsePrice(line.priceDRZ) : "-"}
            </Table.Cell>
            <SellPriceCell
              setLines={setLines}
              order={order}
              line={line}
              summary={summary}
            />
            <Table.Cell>
              {parsePrice(
                validNumber(line.sellPrice) ? line.sellPrice * line.quantity : 0
              )}
            </Table.Cell>
            <Table.Cell>
              {parsePrice(
                calculateVat(
                  validNumber(line.sellPrice) ? line.sellPrice : 0,
                  line.quantity,
                  line.priceTaxCode
                )
              )}
            </Table.Cell>
            <Table.Cell>{parsePrice(calculateGross(line))}</Table.Cell>
            <Table.Cell>{line.promoCode || "-"}</Table.Cell>
            <ReasonForRejectionTableCell line={line} />
            <RemoveTableCell
              order={order}
              removeLine={removeLine}
              line={line}
              summary={summary}
            />
          </Table.Row>
        ))}
      </Table.Body>
      {renderFooter()}
    </Table>
  );
};

export default LinesTable;
