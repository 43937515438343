import { List } from 'semantic-ui-react'
import { TRootProps } from './lib/types'

import React, { useEffect } from 'react'
import TreeNode from './TreeNode'
import useSelection from './hooks/useSelection'

const Tree: React.FC<TRootProps> = ({
    rootNodes,
    initialSelection = [],
    loadProvider,
    onSelectionChange,
    editable = true,
    sortFn,
    advancedSearchResponse,
}) => {

    const selection = useSelection(initialSelection, onSelectionChange)

    useEffect(() => {
        selection.reinit(initialSelection)
    }, [initialSelection, selection])

    if (sortFn) {
        rootNodes = rootNodes.sort(sortFn)
    }

    return (
        <List>
            {
                rootNodes.map((node): JSX.Element => (
                    <TreeNode key={node.id} node={node} loadProvider={loadProvider}
                              onSelectionChange={selection.handleSelectionChange} advancedSearchResponse={advancedSearchResponse}
                              selection={selection} parents={[]} editable={editable} sortFn={sortFn}/>
                ))
            }
        </List>
    )
}

export default Tree
