import { Paginable } from "~/store/types";
import { action } from "typesafe-actions";
import { SparePart, SparePartCP, SparePartsActionTypes } from "./types";

export const fetchSpareParts = (params: any) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_REQUEST, params);
export const fetchSparePartsSearch = (params: any) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_REQUEST, params);
export const fetchSparePartsSuccess = (spareParts: Paginable<SparePart>) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_SUCCESS, spareParts);
export const fetchSparePartsSearchSuccess = (spareParts: SparePart[]) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_SEARCH_SUCCESS, { spareParts });

export const selectSparePart = (
  referenceId: string,
  forClientPanel?: boolean
) =>
  action(SparePartsActionTypes.SELECT_SPAREPART, referenceId, forClientPanel);
export const sparePartSelected = (sparePart: SparePart) =>
  action(SparePartsActionTypes.SPAREPART_SELECTED, sparePart);

export const importReferences = (file: File) =>
  action(SparePartsActionTypes.IMPORT_REFERENCES, file);
export const importFinished = () =>
  action(SparePartsActionTypes.IMPORT_FINISHED);

export const fetchSparePartForCP = (
  referenceId: string,
  clearForm: () => void,
  setErrorInfo: (errorContent: string) => void
) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP, {
    referenceId,
    clearForm,
    setErrorInfo,
  });
export const fetchSparePartForCPSuccess = (
  sparePart: SparePartCP,
  setErrorInfo: (errorContent: string) => void
) =>
  action(SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP_SUCCESS, {
    sparePart,
    setErrorInfo,
  });
export const fetchSparePartForCPError = (
  setErrorInfo: (errorContent: string) => void
) => action(SparePartsActionTypes.FETCH_SPAREPARTS_FOR_CP_ERROR, setErrorInfo);
export const removeSparePartForCP = (referenceId: string) =>
  action(SparePartsActionTypes.REMOVE_SPAREPARTS_FOR_CP, referenceId);

export const removeAllSparePartForCP = () =>
  action(SparePartsActionTypes.REMOVE_ALL_SPAREPARTS);
