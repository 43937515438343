import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, Header, Modal } from "semantic-ui-react";
import "./styles.scss";
import { useComments } from "../Cards/Comments/hooks/useComments";
import { TClaimMessege } from "~/store/claims/types";
import { sendInnerMessage } from "~/store/claims/actions";
import { CommentsModalInput } from "./CommentsTextInput";
import { RenderMessages } from "./RenderMessages";

type props = {
  claimNr: string;
  open: boolean;
  innerMessages: TClaimMessege[] | null;
  clientMessages: TClaimMessege[] | null;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleRefresh: () => void;
  sendInnerMessage: typeof sendInnerMessage;
};

enum ChatType {
  ClientChat,
  ServiceChat,
}

export const CommentsModal: React.FC<props> = ({
  claimNr,
  open,
  innerMessages,
  clientMessages,
  handleClose,
  handleRefresh,
  sendInnerMessage,
}) => {
  const [chats, setChats] = useState<ChatType[]>([ChatType.ServiceChat]);
  const [chatType, setChatType] = useState<ChatType>(chats[0]);

  const scrollBottom = useCallback(() => {
    const elem = document.querySelector(".modal .scrolling.content");
    if (!elem) return;
    elem.scrollTo({ top: elem.scrollHeight, behavior: "smooth" });
  }, []);

  const { canShowClientComments, canShowInnerComments } = useComments();

  useEffect(() => {
    let chatsToSet: ChatType[] = [];
    if (canShowClientComments()) {
      chatsToSet.push(ChatType.ClientChat);
    }
    if (canShowInnerComments()) {
      chatsToSet.push(ChatType.ServiceChat);
    }
    setChats(chatsToSet);
    setChatType(chatsToSet[0]);
  }, [canShowClientComments, canShowInnerComments]);

  useEffect(() => {
    setTimeout(() => scrollBottom(), 50);
  }, [innerMessages, scrollBottom]);

  useEffect(() => {
    setTimeout(() => scrollBottom(), 50);
  }, [clientMessages, scrollBottom]);

  useEffect(() => {
    if (open) {
      handleRefresh();
      setTimeout(() => scrollBottom(), 100);
    }
    // eslint-disable-next-line
  }, [scrollBottom, open]);

  const sendMessage = useCallback(
    (message: string) => {
      if (!message || !message.length) return;

      if (chatType === ChatType.ServiceChat) {
        sendInnerMessage(claimNr, message);
      }
      if (chatType === ChatType.ClientChat) {
        // TODO: send request to client chat, disabled until client panel
      }
    },
    [chatType, claimNr, sendInnerMessage]
  );

  return (
    <Modal size="small" open={open}>
      <Header
        children={
          <>
            {chats.length > 1 ? (
              <Header
                icon="comments"
                content={
                  <>
                    {chats.includes(ChatType.ClientChat) && (
                      <Button
                        basic
                        primary={chatType === ChatType.ClientChat}
                        onClick={() => setChatType(ChatType.ClientChat)}
                        disabled={!canShowClientComments()}
                      >
                        Chat z klientem
                      </Button>
                    )}
                    {chats.includes(ChatType.ServiceChat) && (
                      <Button
                        basic
                        primary={chatType === ChatType.ServiceChat}
                        onClick={() => {
                          setChatType(ChatType.ServiceChat);
                        }}
                      >
                        Chat z obsługą
                      </Button>
                    )}
                  </>
                }
              ></Header>
            ) : (
              <Header icon="comments" content="Czat"></Header>
            )}
          </>
        }
      />
      <Modal.Content scrolling className="comments">
        <Grid>
          {chatType === ChatType.ClientChat && (
            <RenderMessages messages={clientMessages} />
          )}
          {chatType === ChatType.ServiceChat && (
            <RenderMessages messages={innerMessages} />
          )}
        </Grid>
      </Modal.Content>
      <Modal.Content>
        <CommentsModalInput
          sendMessage={sendMessage}
          handleRefresh={handleRefresh}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={() => handleClose(false)} />
      </Modal.Actions>
    </Modal>
  );
};
