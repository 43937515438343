import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import { DictionaryLabel } from "~/components/MapDictionary/DictionaryLabel";
import { NiceLabel } from "~/components/MapDictionary/NiceLabel";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import dictionariesStatic from "~/store/dictionaries/static";

export const usePromotionsColumns = () => {
  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();
  const getTypeCell = useMemo(
    () => (document: any, column: TColumn) => (
      <Table.Cell collapsing key={Math.random()}>
        <DictionaryLabel
          value={document.type}
          dictionary={column.dictionary || []}
          iconOnly
          compact
          placeholder={<NiceLabel icon="question" />}
        />
      </Table.Cell>
    ),
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "type",
        label: "Typ",
        projection: true,
        dictionary: dictionariesStatic.PROMO_TYPE,
        getCell: (document, column) => getTypeCell(document, column),
      },
      {
        name: "code",
        label: "Kod promocji",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "description",
        label: "Opis",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "dateFrom",
        label: "Data rozpoczęcia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "dateTo",
        label: "Data zakończenia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "status",
        label: "Status",
        projection: true,
        dictionary: dictionariesStatic.PROMO_STATUS,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "priority",
        label: "Priorytet",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getTypeCell, getDateCell, getDictionaryCell, getTextCell]
  );

  return { columnsConfig };
};
