import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Popup,
  Progress,
  Segment,
  Table,
  TextAreaProps,
} from 'semantic-ui-react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { deleteBackorderLine, importBackorderLines, loadLinesCsv, loadLinesExcel } from '~/store/backorder/actions';
import { BackorderLine, TBackorderImportLine } from '~/store/backorder/types';
import { SparePartPricing } from '~/store/price-list/types';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExampleXls from '~/assets/images/examples/backorder-xls.png';
import PreviewImage from '~/components/PreviewImage/PreviewImage';
import ModalMessageHeader from '~/components/Modals/ModalMessageHeader';

type TImportSummaryProps = {
  parsedLines: TBackorderImportLine[] | BackorderLine[];
  loading: boolean;
  progress: number;
};

const ImportSummarytView: React.FC<TImportSummaryProps> = ({ parsedLines, loading, progress }) => {
  let lastReplacedId: string = '';

  return (
    <>
      <Message icon>
        <Icon name="info circle" />
        <Message.Content>
          Przejrzyj wczytane referencje.
          <br />
          Zmian będzie można dokonywać po ich zapisaniu.
        </Message.Content>
      </Message>
      <div style={{ maxHeight: 450, overflow: 'auto' }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nr</Table.HeaderCell>
              <Table.HeaderCell>Referencja</Table.HeaderCell>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.HeaderCell>PVP</Table.HeaderCell>
              <Table.HeaderCell>PNR</Table.HeaderCell>
              <Table.HeaderCell>Ilość</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              // @ts-ignore: Not callable
              parsedLines.map((line, index) => {
                if (line.orderPartType === 'REPLACED') {
                  lastReplacedId = line.referenceID;
                }
                const replacementInfo =
                  line.orderPartType !== 'REPLACEMENT' ? (
                    ''
                  ) : (
                    <Popup
                      content={`Zamiennik dla ${lastReplacedId}`}
                      trigger={<Icon name="sync alternate" color="grey" style={{ float: 'right' }} />}
                    />
                  );
                const codingInfo = !line.codes ? (
                  ''
                ) : (
                  <Popup
                    content={'Wymagane będzie podanie parametrów kodowania.'}
                    trigger={<Icon name="code branch" color="grey" style={{ float: 'right' }} />}
                  />
                );
                return (
                  <Table.Row disabled={line.orderPartType === 'REPLACED'}>
                    <Table.Cell>
                      {line.success ? (
                        <Icon color="green" name="check" />
                      ) : line.error ? (
                        <Icon color="red" name="close" />
                      ) : (
                        index + 1
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {line.referenceID} {replacementInfo} {codingInfo}
                    </Table.Cell>
                    <Table.Cell>{line.description}</Table.Cell>
                    <Table.Cell>{line.pvpPrice}</Table.Cell>
                    <Table.Cell>{line.pnrPrice}</Table.Cell>
                    <Table.Cell>{line.quantity}</Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </div>
      <Progress style={{ marginBottom: 0, opacity: loading ? 1 : 0 }} active={loading} percent={Math.floor(progress)} />
    </>
  );
};

type importBackorderLinesType = typeof importBackorderLines;
type loadLinesExcelType = typeof loadLinesExcel;

export const useUploadXlsModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  parsedLines: TBackorderImportLine[] | BackorderLine[],
  progress: number,
  backorderId: string,
  loadLinesExcel: loadLinesExcelType,
  importBackorderLines: importBackorderLinesType
) => {
  const [file, setFile] = useState<File>();
  const loaded = parsedLines.length > 0;

  const handleLoad = useCallback((): void => {
    if (file) {
      loadLinesExcel(file);
    }
  }, [file, loadLinesExcel]);

  const handleSave = useCallback((): void => {
    importBackorderLines(backorderId);
  }, [backorderId, importBackorderLines]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="file archive" content="Wstawianie linii zamówienia" />
        <Modal.Content>
          {!loaded ? (
            <>
              <ModalMessageHeader
                format
                additionalMessage="Po wczytaniu pliku będzie można przejrzeć dodawane rekordy przed zapisem."
              />
              <PreviewImage src={ExampleXls} />
              <Segment placeholder>
                {/* TODO Make whole segment as a drop-zone (use external lib) */}
                <Header icon>
                  <Icon name="file archive outline" />
                  {file ? file.name : 'Załaduj plik'}
                </Header>
                <Input type="file" onChange={handleFileChange} />
              </Segment>
            </>
          ) : (
            <ImportSummarytView parsedLines={parsedLines} loading={loading} progress={progress} />
          )}
        </Modal.Content>
        <Modal.Actions>
          {!loaded && (
            <Button
              primary
              content="Wczytaj"
              icon="upload"
              labelPosition="right"
              loading={loading}
              disabled={loading || !file}
              onClick={handleLoad}
            />
          )}
          {loaded && (
            <Button
              primary
              content="Zapisz"
              icon="save"
              labelPosition="right"
              loading={loading}
              disabled={loading || !file}
              onClick={handleSave}
            />
          )}
          <Button content="Zamknij" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, file, closeHandler, handleLoad, handleSave, loaded, parsedLines, progress]
  );
};

type loadLinesCsvType = typeof loadLinesCsv;

export const useUploadCsvModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  parsedLines: TBackorderImportLine[] | BackorderLine[],
  progress: number,
  backorderId: string,
  loadLinesCsv: loadLinesCsvType,
  importBackorderLines: importBackorderLinesType
) => {
  const [lines, setLines] = useState('');
  const loaded = parsedLines.length > 0;

  useEffect(() => {
    if (open) {
      setLines('');
    }
  }, [open]);

  const editLines = (ev: any, el: TextAreaProps): void => {
    setLines(el.value as string);
  };

  const linesCounter = lines === '' ? 0 : lines.split(/\r\n|\r|\n/).length;

  const handleLoad = useCallback((): void => {
    if (lines) {
      loadLinesCsv(lines);
    }
  }, [lines, loadLinesCsv]);

  const handleSave = useCallback((): void => {
    importBackorderLines(backorderId);
  }, [backorderId, importBackorderLines]);

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
        <Header icon="paste" content="Wstawianie linii zamówienia" />
        <Modal.Content>
          {!loaded ? (
            <>
              <Message>
                <Message.Content>
                  Wklej do poniższego pola listę referencji według poniższego wzorca. <br />
                  Po wczytaniu pliku będzie można przejrzeć dodawane rekordy przed zapisem.
                </Message.Content>
              </Message>
              <Form>
                <Form.TextArea
                  label="Wzorzec"
                  readOnly
                  style={{ resize: 'none' }}
                  value={`nr_linii;referencja_1;ilość_1\nnr_linii;referencja_2;ilość_2\nnr_linii;referencja_3;ilość_3`}
                ></Form.TextArea>
                <Form.TextArea label={`Liczba linii: ${linesCounter}`} rows="15" value={lines} onChange={editLines} />
              </Form>
            </>
          ) : (
            <ImportSummarytView parsedLines={parsedLines} loading={loading} progress={progress} />
          )}
        </Modal.Content>
        <Modal.Actions>
          {!loaded && (
            <Button
              primary
              content="Wczytaj"
              icon="upload"
              labelPosition="right"
              loading={loading}
              disabled={loading || !lines}
              onClick={handleLoad}
            />
          )}
          {loaded && (
            <Button
              primary
              content="Zapisz"
              icon="save"
              labelPosition="right"
              loading={loading}
              disabled={loading || !lines}
              onClick={handleSave}
            />
          )}
          <Button content="Anuluj" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, lines, parsedLines, progress, closeHandler, handleLoad, handleSave, linesCounter, loaded]
  );
};

type deleteBackorderLineType = typeof deleteBackorderLine;

export const useRemoveLineModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  backorderId: string,
  deleteBackorderLine: deleteBackorderLineType,
  selectedLine?: BackorderLine
) => {
  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="tiny" closeOnDimmerClick={false}>
        <Header icon="trash" content="Jedno usuń" />
        <Modal.Content>Jesteś pewien, że chesz usunąć wybraną linię zamówienia?</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Tak"
            icon="checkmark"
            labelPosition="right"
            loading={loading}
            disabled={loading || !selectedLine}
            onClick={() => {
              if (selectedLine) {
                deleteBackorderLine(backorderId, selectedLine);
              }
            }}
          />
          <Button content="Anuluj" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, backorderId, selectedLine, closeHandler, deleteBackorderLine]
  );
};

export const useReplacementsModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  acceptHandler: (product: SparePartPricing) => void,
  product?: SparePartPricing
): JSX.Element => {
  if (!product) {
    return <></>;
  }

  const handleAccept = (): void => {
    acceptHandler(product);
  };

  const replacements: JSX.Element[] = product.replacements.map((replacement, index) => (
    <Table.Row key={index}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{replacement.sparePart.referenceId}</Table.Cell>
      <Table.Cell>{replacement.sparePart.description}</Table.Cell>
      <Table.Cell>{replacement.sparePart.pnrPrice} PLN</Table.Cell>
      <Table.Cell>{replacement.quantity} szt.</Table.Cell>
    </Table.Row>
  ));

  return (
    <Modal open={open} onClose={closeHandler} size="small" closeOnDimmerClick={false}>
      <Header icon="recycle" content="Zamienność referencji" />
      <Modal.Content>
        <Message icon>
          <Icon name="info circle" />
          <Message.Content>
            Wybrana referencja podlega specyfikacji zamienności.
            <br />
            Przejrzyj zamienniki i dokonaj akceptacji zamiany.
          </Message.Content>
        </Message>
        <Form>
          <Grid columns="2">
            <Grid.Row>
              <Grid.Column>
                <SmartLabel label="Referencja" value={product.referenceId} align="left" />
              </Grid.Column>
              <Grid.Column>
                <SmartLabel label="Nazwa" value={product.description} align="left" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <SmartLabel label="Kod rozwiązania zamienności" value={product.replacementCategory} align="left" />
              </Grid.Column>
              <Grid.Column>
                <SmartLabel label="Lista zamienności" value={product.replacementListId} align="left" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <span>Zamienniki</span>
        <Divider />
        <Table basic="very" compact>
          <Table.Body>{replacements}</Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Kontynuuj"
          icon="arrow circle right"
          labelPosition="right"
          loading={loading}
          disabled={loading}
          onClick={handleAccept}
        />
        <Button content="Anuluj" onClick={closeHandler} />
      </Modal.Actions>
    </Modal>
  );
};
