import { TModelMessageProps } from "~/components/ModelForm/lib/types";
import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { List, Message, SemanticCOLORS } from "semantic-ui-react";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { useFormElement } from "~/components/ModelForm/hooks/useFormElement";
import { getPasswordPolicyMessage } from "~/pages/Administration/lib/utils";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { PasswordPolicy } from "~/store/users/types";
import {
  CONTAIN_N_DIGITS,
  CONTAIN_N_LOWERCASE_LETTERS,
  CONTAIN_N_SPECIAL_CHARACTERS,
  CONTAIN_N_UPPERCASE_LETTERS,
  NOT_CONTAIN_USERNAME,
} from "~/components/ModelForm/lib/utils";

export type PasswordPolicyCheck = PasswordPolicy & {
  icon: {
    color: SemanticCOLORS;
    name: SemanticICONS;
  };
};

export const ModelMessage = <T,>(props: TModelMessageProps<T>) => {
  const isPasswordValidByType = (policy: PasswordPolicy): boolean => {
    switch (policy.type) {
      case "DIGITS": {
        return CONTAIN_N_DIGITS(model.password, policy.min);
      }
      case "LENGTH": {
        return model.password.length >= policy.min;
      }
      case "UPPERCASE": {
        return CONTAIN_N_UPPERCASE_LETTERS(model.password, policy.min);
      }
      case "LOWERCASE": {
        return CONTAIN_N_LOWERCASE_LETTERS(model.password, policy.min);
      }
      case "SPECIAL_CHARACTERS": {
        return CONTAIN_N_SPECIAL_CHARACTERS(model.password, policy.min);
      }
      case "NOT_USERNAME": {
        return NOT_CONTAIN_USERNAME(model.password, model.username);
      }
      default: {
        return false;
      }
    }
  };

  const initialState = (): PasswordPolicyCheck[] => {
    return props.passwordPolicies.map((policy) => {
      return {
        ...policy,
        icon: {
          name: "question circle outline",
          color: "grey",
        },
      };
    });
  };

  const { model, formatPopupContent } = useFormElement(props);

  const getInitAndUpdatedPolicyState = (): PasswordPolicyCheck[] => {
    if (model.password) {
      return props.passwordPolicies.map((policy) => {
        return {
          ...policy,
          icon: {
            name: isPasswordValidByType(policy)
              ? "check circle outline"
              : "question circle outline",
            color: isPasswordValidByType(policy) ? "green" : "grey",
          },
        };
      });
    }
    return initialState();
  };

  const [passwordPolicyCheck, setPasswordPolicyCheck] = useState<
    PasswordPolicyCheck[]
  >(getInitAndUpdatedPolicyState());

  useEffect(() => {
    setPasswordPolicyCheck(getInitAndUpdatedPolicyState());
    // eslint-disable-next-line
  }, [model, props.passwordPolicies]);

  const rendered: ReactNode = useMemo(
    () => (
      <SmartPopup description={formatPopupContent()}>
        <Message color="orange">
          <Message.Header>{props.headline}</Message.Header>
          <List>
            {passwordPolicyCheck
              .filter((policy) => policy.type !== "FORCE_PASSWORD_CHANGE")
              .map((policy, index) => (
                <List.Item key={index}>
                  <List.Icon
                    name={policy.icon.name}
                    color={policy.icon.color}
                  />
                  <List.Content>
                    {getPasswordPolicyMessage(policy)}
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Message>
      </SmartPopup>
      // eslint-disable-next-line
    ),
    [formatPopupContent, props, passwordPolicyCheck]
  );

  return <Fragment>{rendered}</Fragment>;
};
