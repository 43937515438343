import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Divider, Form, Grid } from 'semantic-ui-react';
import { ApplicationState } from '~/store';
import { clearSelected, selectBackorder } from '~/store/backorder/actions';
import { Backorder, BackorderCustomer, TBackorderStatus } from '~/store/backorder/types';
import { fetchDictionary } from '~/store/dictionaries/actions';
import { DictionariesState, DictionaryName } from '~/store/dictionaries/types';
import { SmartHidden } from '~/components/SmartField/SmartHidden';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { useSelectOptions } from './lib/Hooks';
import { parseDate } from '~/utils/dateUtils';

import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DetailCard from '~/components/DetailCard/DetailCard';
import CommonLoader from '~/components/Loaders/CommonLoader';
import mapDictionary from '~/components/MapDictionary/mapDictionary';
import PageHeader from '~/components/PageHeader/PageHeader';
import SmartWrapper from '~/components/SmartField/SmartWrapper';
import BackorderLines from './lib/Lines';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';
import { AppContext } from '~/context/AuthContext';
import translations from '~/utils/translations';

type TReduxState = {
  backorderIn?: Backorder;
  dictionaries: DictionariesState;
  loading: boolean;
};

type TReduxActions = {
  selectBackorder: typeof selectBackorder;
  clearSelected: typeof clearSelected;
  fetchDictionary: typeof fetchDictionary;
};

type TRouteParams = RouteComponentProps<{
  id: string;
  edit?: string;
}>;

type TBackorderDetailsProps = TReduxState & TReduxActions & TRouteParams;

const BackorderDetails: React.FC<TBackorderDetailsProps> = ({
  match,
  backorderIn,
  dictionaries,
  loading,
  selectBackorder,
  clearSelected,
  fetchDictionary,
}) => {
  const [backorder, setBackorder] = useState<Backorder>();
  const [editMode, setEditMode] = useState<boolean>(!!match.params.edit);
  const [status, setStatus] = useState<TBackorderStatus>();
  const [saveStatus, setSaveStatus] = useState<boolean>(false);
  const [statusChanging, setStatusChanging] = useState<boolean>(false);

  const customer: BackorderCustomer =
    backorder && backorder.customer
      ? backorder.customer
      : {
          rrdi: '',
          address: '-',
          city: '-',
          name: '-',
          postCode: '-',
        };
  const deliveryCustomer: BackorderCustomer =
    backorder && backorder.deliveryCustomer
      ? backorder.deliveryCustomer
      : {
          rrdi: '',
          address: '-',
          city: '-',
          name: '-',
          postCode: '-',
        };

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (!backorder || (backorder && backorder.id !== match.params.id)) {
      fetchDictionary(DictionaryName.backorderState);
      selectBackorder(match.params.id);
      return () => {
        clearSelected();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, clearSelected, fetchDictionary, selectBackorder]);

  useEffect(() => {
    document.title = translations.format('LDC_BACKORDER');
    setBackorder(backorderIn);
  }, [backorderIn]);

  useEffect(() => {
    if (status) {
      setSaveStatus(true);
    }
  }, [status]);

  useEffect(() => {
    if (saveStatus) {
      setSaveStatus(false);
      setStatus(undefined);
    }
  }, [saveStatus]);

  const formatMessage = (id: string): JSX.Element => <FormattedMessage id={id} />;

  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };

  const options = useSelectOptions();
  const { renderNumber, renderSelect, renderTextArea, renderLabel } = useRenderingFunctions(backorder, editMode);

  const handleBackorderUpdate = (updatedModel: Backorder, response: any): void => {
    if (backorder && backorder.customer.rrdi !== updatedModel.customer.rrdi) {
      selectBackorder(backorder.id);
    } else if (backorder && backorder.deliveryCustomer.rrdi !== updatedModel.deliveryCustomer.rrdi) {
      selectBackorder(backorder.id);
    } else {
      setBackorder(updatedModel);
    }
  };

  const handleStatusChange = (modified: boolean, loading: boolean): void => {
    if (statusChanging && !loading) {
      selectBackorder(backorder!.id);
    }
    setStatusChanging(loading);
  };

  const isStatus = (value: string, ...statuses: string[]): boolean => {
    return statuses.indexOf(value) !== -1;
  };

  const hasStatus = useCallback(
    (value: string): boolean | undefined => {
      return (backorder && backorder.status === 'NEW') || (backorder && backorder.status === 'QUEUED');
    },
    [backorder]
  );

  const cards: JSX.Element[] = useMemo(() => {
    return !backorder
      ? []
      : [
          <DetailCard title="app.order" id="order" key="order" minHeight={100} width={5.33}>
            {renderLabel(translations.format('app.supplier'), backorder.supplier || 'n/d')}
            <Divider />
            {renderSelect('method', options.method, {
              label: 'Typ',
              readOnly: true,
            })}
            <Form.Group widths="equal">
              {renderSelect('type', options.type, {
                label: 'Dostawa',
                width: 10,
                readOnly: !isStatus(backorder.status, 'NEW', 'QUEUED'),
              })}
              {renderNumber('priority', {
                label: 'Priorytet',
                width: 6,
                readOnly: !isStatus(backorder.status, 'NEW', 'QUEUED'),
              })}
            </Form.Group>
          </DetailCard>,
          <DetailCard title="app.status" id="status" key="status" minHeight={100} width={5.33}>
            {renderLabel('STATUS', mapDictionary(backorder.status, dictionaries['backorder-state'], true, true, true))}
            {renderLabel('Utworzone', parseDate(backorder.creationTime))}
            {renderLabel('Ostatnia zmiana', parseDate(backorder.updateTime))}
            <Divider />
            {renderLabel('Realizacja', backorder.realisation + '%')}
          </DetailCard>,
          <DetailCard title="app.adnotation" id="annotations" key="annotations" minHeight={100} width={5.33}>
            {renderTextArea('comments', {
              label: 'Uwagi',
              rows: 5,
              description: 'Dodatkowe informacje na temat zamówienia',
              readOnly: isStatus(backorder.status, 'CLOSED'),
            })}
          </DetailCard>,
          <DetailCard title="app.purchaser" id="purchaser" key="purchaser" minHeight={100} width={5.33}>
            {renderLabel('ID', customer.rrdi)}
            <Divider />
            {renderLabel('Nazwa', customer.name)}
            {renderLabel('Adres', customer.address)}
            {renderLabel('Miejscowość', customer.city)}
            {renderLabel('Kod pocztowy', customer.postCode)}
          </DetailCard>,
          <DetailCard title="app.customer.delivery" id="delivery" key="delivery" minHeight={100} width={5.33}>
            {renderLabel('ID', deliveryCustomer.rrdi)}
            <Divider />
            {deliveryCustomer.ddsDeliveryId &&
              renderLabel(formatMessage('app.customer.ovDeliveryId'), deliveryCustomer.ddsDeliveryId)}
            {deliveryCustomer.ddsDeliveryId && <Divider />}
            {deliveryCustomer.supplierSystem &&
              renderLabel(
                formatMessage('app.customer.FLHAP-Payer'),
                deliveryCustomer.supplierSystem[
                  deliveryCustomer.supplierSystem?.findIndex((sys) => sys.businessSector === 'FLHAP')
                ].clientId
              )}
            {deliveryCustomer.supplierSystem &&
              renderLabel(
                formatMessage('app.customer.FLHAP-Recipient'),
                deliveryCustomer.supplierSystem[
                  deliveryCustomer.supplierSystem?.findIndex((sys) => sys.businessSector === 'FLHAP')
                ].deliveryId
              )}
            {deliveryCustomer.supplierSystem &&
            deliveryCustomer.supplierSystem.findIndex((sys) => sys.businessSector === 'FLHAP') >= 0 ? (
              <Divider />
            ) : (
              ''
            )}
            {deliveryCustomer.supplierSystem &&
              renderLabel(
                formatMessage('app.customer.CJD-Payer'),
                deliveryCustomer.supplierSystem[
                  deliveryCustomer.supplierSystem?.findIndex((sys) => sys.businessSector === 'CJD')
                ].clientId
              )}
            {deliveryCustomer.supplierSystem &&
              renderLabel(
                formatMessage('app.customer.CJD-Recipient'),
                deliveryCustomer.supplierSystem[
                  deliveryCustomer.supplierSystem?.findIndex((sys) => sys.businessSector === 'CJD')
                ].deliveryId
              )}
            {deliveryCustomer.supplierSystem &&
            deliveryCustomer.supplierSystem.findIndex((sys) => sys.businessSector === 'CJD') >= 0 ? (
              <Divider />
            ) : (
              ''
            )}
            {renderLabel('Nazwa', deliveryCustomer.name)}
            {renderLabel('Adres', deliveryCustomer.address)}
            {renderLabel('Miejscowość', deliveryCustomer.city)}
            {renderLabel('Kod pocztowy', deliveryCustomer.postCode)}
          </DetailCard>,
          <DetailCard title="app.batch" id="batch" key="batch" minHeight={100} width={5.33}>
            {renderLabel('ID', backorder.batchId || 'n/d')}
            {renderLabel('Data', parseDate(backorder.sentTime) || 'n/d')}
          </DetailCard>,
          <DetailCard title="app.order.lines" id="orderLines" key="orderLines" width={16} minHeight={100}>
            <BackorderLines
              editMode={editMode && isStatus(backorder.status, 'NEW', 'QUEUED')}
              editModeOrigin={backorder && hasStatus(backorder.status)}
            />
          </DetailCard>,
        ];
  }, [
    backorder,
    customer.address,
    customer.city,
    customer.name,
    customer.postCode,
    customer.rrdi,
    deliveryCustomer.address,
    deliveryCustomer.city,
    deliveryCustomer.ddsDeliveryId,
    deliveryCustomer.name,
    deliveryCustomer.postCode,
    deliveryCustomer.supplierSystem,
    dictionaries,
    editMode,
    hasStatus,
    options.method,
    deliveryCustomer.rrdi,
    options.type,
    renderLabel,
    renderNumber,
    renderSelect,
    renderTextArea,
  ]);

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <div className="uber-content">
        <Grid stretched>{cards}</Grid>
      </div>
    );
  }, [cards]);

  const renderedBackorderTitle: string = useMemo(() => {
    if (!backorder) {
      return 'Wczytywanie...';
    }
    return backorder.backorderNumber;
  }, [backorder]);

  const userHaveLdcUiBackorderProcessRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_BACKORDER_PROCESS),
    [appContext]
  );

  return (
    <Fragment>
      <SmartWrapper
        endpoint={backorder ? `/stock/backorders/${backorder.id}` : ''}
        model={backorder}
        onUpdate={handleBackorderUpdate}
      >
        <PageHeader
          icon="shop"
          title={
            <>
              {formatMessage('app.backorder.details.title')}: {renderedBackorderTitle}
            </>
          }
          breadcrumb={[
            { text: <FormattedMessage id="app.list" />, link: '/backorders' },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[
            {
              icon: editMode ? 'lock' : 'edit',
              content: editMode ? 'Wył. edycję' : <FormattedMessage id="app.button.edit" />,
              onClick: toggleEditMode,
              primary: true,
              visible: backorder && hasStatus(backorder.status) && userHaveLdcUiBackorderProcessRole,
            },
            {
              icon: 'close',
              content: <FormattedMessage id="app.button.cancel" />,
              onClick: () => {
                setStatus('CANCELED');
              },
              visible: backorder && hasStatus(backorder.status) && userHaveLdcUiBackorderProcessRole,
            },
          ]}
          refreshAction={() => {
            clearSelected();
            selectBackorder(match.params.id);
          }}
          loading={loading}
        />
        <SmartHidden
          name="status"
          stateChange={handleStatusChange}
          value={backorder && backorder.status}
          editValue={status}
          blur={saveStatus}
        />
        {cardsRendered}
      </SmartWrapper>
      <CommonLoader loading={loading || statusChanging || !backorder} />
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  backorders,
  dictionaries,
  users,
}: ApplicationState) => {
  return {
    backorderIn: backorders.selected,
    loading: backorders.loadingBackorder || dictionaries.loading,
    dictionaries,
  };
};

const mapDispatchToProps: TReduxActions = {
  selectBackorder,
  clearSelected,
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackorderDetails);
