import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import DetailCard from "~/components/DetailCard/DetailCard";
import { fetchDictionary } from "~/store/dictionaries/actions";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";

import "./style.scss";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";

type TReduxState = {
  dictionaries: DictionariesState;
};

type TReduxActions = {
  fetchDictionary: typeof fetchDictionary;
};

type TProps = TReduxState & TReduxActions;

type TContactData = {
  key: string;
  value: string;
  text: string;
  description: string;
  email: string;
  emailText: string;
  label: string;
  phoneNumber: string;
  phoneNumberText: string;
};

const ContactTile: React.FC<TProps> = ({ dictionaries, fetchDictionary }) => {
  const [contactData, setContactData] = useState<TContactData>();
  const [refreshPening, setRefreshPening] = useState(false);
  const [initRender, setInitRender] = useState(true);

  useEffect(() => {
    if (initRender) {
      fetchDictionary(DictionaryName.contact);
      setInitRender(false);
    }
  }, [fetchDictionary, initRender]);

  useEffect(() => {
    if (dictionaries.contact && dictionaries.contact.length) {
      const contactItem = dictionaries.contact[0] as TContactData;
      setContactData(contactItem);
      setRefreshPening(false);
    }
    if (!dictionaries.contact && !refreshPening) {
      fetchDictionary(DictionaryName.contact);
      setRefreshPening(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries]);

  const orEmpty = useCallback(
    (name: keyof TContactData) => (!contactData ? "" : contactData[name]),
    [contactData]
  );

  return (
    <>
      {contactData ? (
        <DetailCard
          title="Biuro Obsługi Klienta"
          titleFormat={true}
          id="contact-info"
          width={9}
        >
          <p className="info-text">{orEmpty("description")}</p>

          <div className="tile-container">
            <div className="icon-container">
              <Icon name="phone volume" color="orange" size="huge" />
            </div>
            <div>
              <div className="tile-header">Zadzwoń pod number:</div>
              <div>
                <p className="orange value">{orEmpty("phoneNumber")}</p>
              </div>
              <div>
                <small>{orEmpty("phoneNumberText")}</small>
              </div>
            </div>
          </div>
          <div className="tile-container">
            <div className="icon-container">
              <Icon name="at" color="orange" size="huge" />
            </div>
            <div>
              <div className="tile-header">Wyślij e-mail na adres:</div>
              <div>
                <p className="orange value">{orEmpty("email")}</p>
              </div>
              <div>
                <small>{orEmpty("emailText")}</small>
              </div>
            </div>
          </div>
        </DetailCard>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ dictionaries }: ApplicationState): TReduxState => ({
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  fetchDictionary,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactTile);
