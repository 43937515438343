import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useAdvancedSearch } from "~/components/AdvancedSearch/hooks/useAdvancedSearch";

import {
  FIELD_TYPE,
  FormField,
} from "~/components/AdvancedSearch/types/advancedSearchType";
import { TColumn } from "~/components/Table/lib/types";
import { IRefObject } from "~/components/TableFilter/TableFilter";
import { prepareDateForFilter } from "~/utils/dateUtils";
import TableFilterSession, {
  TTableFilterSession,
} from "~/utils/tableFilterSession";

export const useCorrectionsAdvancedSearchCP = (
  columns: TColumn[],
  setColumns: Dispatch<SetStateAction<TColumn[]>>,
  sortColumn: string,
  sortDirection: string,
  fetchMethod: (params?: Object) => void,
  filterLabelsRef: MutableRefObject<TTableFilterSession[]>,
  tableKey: string
) => {
  const tableFilter = React.createRef<IRefObject>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();
  const [orderNr, setOrderNr] = useState<string>("");
  const [serviceBoxOrderNo, setServiceBoxOrderNo] = useState<string>("");
  const [deliveryPoint, setDeliveryPoint] = useState<string>("");
  const [packageNr, setPackageNr] = useState<string>("");
  const [unwindByLineState, setUnwindByLineState] = useState<boolean>(false);

  const { search } = useLocation();
  const {
    toggleProductColumn,
    turnOffUnwidProcess,
    addUpdateFilterLabel,
    setColumnsFromLocalStorage,
  } = useAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    tableKey,
    filterLabelsRef,
    tableKey
  );

  const removeFilterLabel = useCallback(
    (name: string) => {
      if (name.includes("unwind")) {
        setUnwindByLineState(false);
        toggleProductColumn(
          [
            "invoiceLines.orderNumber",
            "invoiceLines.serviceBoxOrderNo",
            "packageNumber",
          ],
          [false, false, false]
        );

        turnOffUnwidProcess();
      }

      const newFilterLabels = filterLabelsRef.current.filter(
        (label) => label.name !== name
      );

      filterLabelsRef.current = newFilterLabels;

      TableFilterSession.clearFilter({ name, table: tableKey, value: "" });

      if (tableFilter.current) {
        tableFilter.current.clearFilter(name);
      }
    },
    [
      filterLabelsRef,
      tableFilter,
      toggleProductColumn,
      turnOffUnwidProcess,
      tableKey,
    ]
  );

  const handleAddFilter = useCallback(
    (name: string, value: string, text?: string, key?: string) => {
      let checkIsInFilterTable = false;

      filterLabelsRef.current.forEach((filter) => {
        if (filter.value === value && filter.name === name) {
          checkIsInFilterTable = true;
        }
      });

      if (!checkIsInFilterTable) {
        if (value !== "") {
          addUpdateFilterLabel(name, value, text, key);
        } else {
          removeFilterLabel(name);
        }
      }
    },
    [filterLabelsRef, removeFilterLabel, addUpdateFilterLabel]
  );

  const handleSubmit = useCallback(() => {
    let formReferenceId: string;
    let formOrderNr: string;
    let formSBorderNr: string;
    let formpackageNr: string;

    if (dateTo || dateFrom) {
      handleAddFilter(
        "invoiceDate",
        prepareDateForFilter(dateTo, dateFrom, 19),
        "Data" + (dateFrom ? " od" : "") + (dateTo ? " do" : "")
      );
    } else {
      handleAddFilter("invoiceDate", "");
    }

    let stringEqualLabelName = "";

    if (referenceId) {
      formReferenceId = "invoiceLines.referenceId:" + referenceId + "::";
      stringEqualLabelName += "Numer referencji ";
    } else {
      formReferenceId = "";
    }

    if (orderNr) {
      formOrderNr = "invoiceLines.orderNumber:" + orderNr + "::";
      stringEqualLabelName += "Numer zamówienia ";
    } else {
      formOrderNr = "";
    }

    if (serviceBoxOrderNo) {
      formSBorderNr =
        "invoiceLines.serviceBoxOrderNo:" + serviceBoxOrderNo + "::";
      stringEqualLabelName += "Numer zamówienia SB ";
    } else {
      formSBorderNr = "";
    }

    if (packageNr) {
      formpackageNr = "invoiceLines.packageNumber:" + packageNr + "::";
      stringEqualLabelName += "Numer paczki ";
    } else {
      formpackageNr = "";
    }

    if (deliveryPoint) {
      handleAddFilter("orderCustomer.id", deliveryPoint, "Adres dostawy");
    } else {
      handleAddFilter("orderCustomer.id", "");
    }

    handleAddFilter(
      "stringEqual",
      formReferenceId + formOrderNr + formSBorderNr + formpackageNr,
      stringEqualLabelName
    );

    fetchMethod({ page: 1 });
  }, [
    dateTo,
    dateFrom,
    referenceId,
    serviceBoxOrderNo,
    orderNr,
    packageNr,
    deliveryPoint,
    handleAddFilter,
    fetchMethod,
  ]);

  const unwindByLine = useCallback(
    (inputState: boolean) => {
      setUnwindByLineState(inputState);

      if (inputState) {
        toggleProductColumn(
          [
            "invoiceLines.orderNumber",
            "invoiceLines.serviceBoxOrderNo",
            "packageNumber",
          ],
          [true, true, true]
        );

        handleAddFilter("unwind", "invoiceLines", "Rozgrupuj");
      } else {
        handleAddFilter("unwind", "", "Rozgrupuj");

        turnOffUnwidProcess();
        toggleProductColumn(
          [
            "invoiceLines.orderNumber",
            "invoiceLines.serviceBoxOrderNo",
            "packageNumber",
          ],
          [false, false, false]
        );
      }

      handleSubmit();
    },
    [toggleProductColumn, handleAddFilter, handleSubmit, turnOffUnwidProcess]
  );

  const mapDelivery = (deliveryPoints: any): any[] => {
    return deliveryPoints.content.map((point: any, index: any) => {
      const key = `${point.name}-${index}`;
      return {
        key,
        title: `${point.city || ""} ${point.street || ""} ${point.number || ""}`,
        description: point.name,
        point,
      };
    });
  };

  const formFields: FormField[][] = [
    [
      {
        type: FIELD_TYPE.INPUT,
        name: "referenceId",
        label: "Referencja",
        placeholder: "Referencja",
        value: referenceId,
        onChange: useCallback(
          (_, { value }) => {
            setReferenceId(value.trim());
          },
          [setReferenceId]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateFrom",
        label: "Data od:",
        placeholder: "RRRR-MM-DD",
        id: "initialDate",
        value: dateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateFrom(value);
          },
          [setDateFrom]
        ),
      },
      {
        type: FIELD_TYPE.DATE_PICKER,
        name: "dateTo",
        label: "Data do:",
        placeholder: "RRRR-MM-DD",
        id: "finalDate",
        value: dateTo,
        minDate: dateFrom,
        onChange: useCallback(
          (_, { value }) => {
            setDateTo(value);
          },
          [setDateTo]
        ),
      },
      {
        type: FIELD_TYPE.INPUT,
        name: "orderNr",
        label: "Numer zamówienia",
        placeholder: "Numer zamówienia",
        value: orderNr,
        onChange: useCallback(
          (_, { value }) => {
            setOrderNr(value.trim());
          },
          [setOrderNr]
        ),
      },
      {
        type: FIELD_TYPE.INPUT,
        name: "serviceBoxOrderNo",
        label: "Numer zamówienia Service Box",
        placeholder: "Numer  Service Box",
        value: serviceBoxOrderNo,
        onChange: useCallback(
          (_, { value }) => {
            setServiceBoxOrderNo(value.trim());
          },
          [setServiceBoxOrderNo]
        ),
      },
    ],
    [
      {
        type: FIELD_TYPE.SEARCH,
        name: "deliveryAddress",
        label: "Adres Dostawy",
        placeholder: "Adres Dostawy",
        value: deliveryPoint,
        searchSelect: true,
        uberSearch: {
          debounce: 700,
          endpoint: "/customer-panel/customers/delivery-points",
          mapper: mapDelivery,
          onResultSelect: useCallback(
            (selected: {
              point: {
                rrdi: string;
              };
            }) => {
              setDeliveryPoint(selected.point.rrdi || "");
              return selected.point.rrdi;
            },
            [setDeliveryPoint]
          ),
        },
        onChange: () => {},
      },
      {
        type: FIELD_TYPE.INPUT,
        name: "packageNumber",
        label: "Numer paczki",
        placeholder: "Numer paczki",
        value: packageNr,
        onChange: useCallback(
          (_, { value }) => {
            setPackageNr(value.trim());
          },
          [setPackageNr]
        ),
      },
      {
        type: FIELD_TYPE.CHECKBOX,
        label: "Rozgrupuj po linii",
        name: "unwindByLine",
        value: unwindByLineState,
        onChange: useCallback(
          (_, { checked }) => {
            unwindByLine(checked);
          },
          [unwindByLine]
        ),
      },
    ],
  ];

  useEffect(() => {
    setColumnsFromLocalStorage();
  }, [setColumnsFromLocalStorage]);

  useEffect(() => {
    if (search) {
      const initParams = new URLSearchParams(search);

      initParams.forEach((value, name) => {
        if (value && value !== "null") {
          addUpdateFilterLabel(name, value, value);
        }
      });
    }
  }, [search, addUpdateFilterLabel]);

  return {
    formFields,
    handleSubmit,
  };
};
