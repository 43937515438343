import { Button, Header, Modal } from 'semantic-ui-react';
import { deleteOrderLine } from '~/store/orders/actions';
import { OrderLine } from '~/store/orders/types';

import React, { useMemo } from 'react';

type deleteOrderLineType = typeof deleteOrderLine;

export const useRemoveLineModal = (
  open: boolean,
  closeHandler: () => void,
  loading: boolean,
  orderId: string,
  deleteOrderLine: deleteOrderLineType,
  selectedLine?: OrderLine
): JSX.Element => {
  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="tiny" closeOnDimmerClick={false}>
        <Header icon="trash" content="Jedno usuń" />
        <Modal.Content>Jesteś pewien, że chcesz usunąć wybraną linię zamówienia?</Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Tak"
            icon="checkmark"
            labelPosition="right"
            loading={loading}
            disabled={loading || !selectedLine}
            onClick={() => {
              if (selectedLine) {
                deleteOrderLine(orderId, selectedLine);
              }
            }}
          />
          <Button content="Anuluj" disabled={loading} onClick={closeHandler} />
        </Modal.Actions>
      </Modal>
    ),
    [open, loading, orderId, selectedLine, closeHandler, deleteOrderLine]
  );
};
