import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { BlockedCellProps } from '~/pages/Customers/constants';

const BlockedCell = ({ blocked, paymentCreditBlocked }: BlockedCellProps) => (
  <>
    {blocked && (
      <span style={{ color: 'red' }} key="ab">
        <Icon color="red" name="ban" /> <FormattedMessage id="app.customer.accountBlocked" />
      </span>
    )}
    {blocked && paymentCreditBlocked && <br key="br" />}
    {paymentCreditBlocked && (
      <span style={{ color: 'red' }} key="lb">
        <Icon color="red" name="dollar sign" /> <FormattedMessage id="app.customer.limitBlocked" />
      </span>
    )}
  </>
);

export default BlockedCell;
