import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./TextEditor.scss";
import { IAnnouncementProps } from "./constans";
import { AnnouncementDisplay } from "./AnnouncementDisplay";
import { Button } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";
import { AnnouncementType } from "../constans";
import { AnnouncementImage } from "../AnnouncementImage/AnnouncementImage";
import { Announcement } from "~/store/annouoncements/types";

export const AnnouncementCarousel: React.FC<IAnnouncementProps> = ({
  announcements,
}) => {
  const [announcementsText, setAnnouncementsText] = useState<Announcement[]>(
    []
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [announcementsImage, setAnnouncementsImage] = useState<Announcement[]>(
    []
  );
  const [activeIndexImg, setActiveIndexImg] = useState<number>(0);

  const imgIntervalTimeout = useMemo(() => 10 * 1000, []); // 10 sec interval for img carousel;
  const textIntervalTimeout = useMemo(() => 50 * 1000, []); // 50 sec interval for text carousel;

  useEffect(() => {
    setAnnouncementsText(
      announcements
        .filter((item) => item.type === AnnouncementType.TEXT)
        .map((item: any) => {
          return {
            ...item,
            content: item.content ? JSON.parse(item.content) : [],
          };
        })
    );
    setAnnouncementsImage(
      announcements.filter((item) => item.type === AnnouncementType.IMAGE)
    );
  }, [announcements]);

  const setNextActiveIndex = useCallback(() => {
    if (activeIndex < announcementsText.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  }, [activeIndex, announcementsText.length]);

  const setNextActiveIndexImg = useCallback(() => {
    if (activeIndexImg < announcementsImage.length - 1) {
      setActiveIndexImg(activeIndexImg + 1);
    } else {
      setActiveIndexImg(0);
    }
  }, [activeIndexImg, announcementsImage.length]);

  const setPreviousActiveIndex = useCallback(() => {
    if (activeIndex === 0) {
      setActiveIndex(announcementsText.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  }, [activeIndex, announcementsText.length]);

  const setPreviousActiveIndexImg = useCallback(() => {
    if (activeIndexImg === 0) {
      setActiveIndexImg(announcementsImage.length - 1);
    } else {
      setActiveIndexImg(activeIndexImg - 1);
    }
  }, [activeIndexImg, announcementsImage.length]);

  const countNextIndexImg: number = useMemo(() => {
    if (activeIndexImg === announcementsImage.length - 1) {
      return 0;
    } else {
      return activeIndexImg + 1;
    }
  }, [activeIndexImg, announcementsImage.length]);

  const countPrevIndexImg: number = useMemo(() => {
    if (activeIndexImg === 0) {
      return announcementsImage.length - 1;
    } else {
      return activeIndexImg - 1;
    }
  }, [activeIndexImg, announcementsImage.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextActiveIndexImg();
    }, imgIntervalTimeout);
    return () => clearInterval(interval);
  }, [activeIndexImg, setNextActiveIndexImg, imgIntervalTimeout]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextActiveIndex();
    }, textIntervalTimeout);
    return () => clearInterval(interval);
  }, [activeIndex, setNextActiveIndex, textIntervalTimeout]);

  const NoAnnouncementsInfo = () => (
    <div style={{ width: "100%", textAlign: "center" }}>
      W tej chwili nie ma żadnych dostępnych ogłoszeń.
    </div>
  );

  return (
    <>
      {!announcementsImage.length && !announcementsText.length ? (
        <NoAnnouncementsInfo />
      ) : null}
      {announcementsImage.length ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Button
            style={{
              height: 40,
              opacity: announcementsImage.length > 1 ? 1 : 0,
            }}
            onClick={() => setPreviousActiveIndexImg()}
            icon="chevron left"
          />
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                justifyContent: "space-between",
                height: "35vh",
                width: "100%",
              }}
            >
              <AnnouncementImage
                anouncement={announcementsImage[countPrevIndexImg]}
                announcementsAmount={announcementsImage.length}
                activeIndex={countPrevIndexImg || announcementsImage.length}
              />
              <AnnouncementImage
                anouncement={announcementsImage[activeIndexImg]}
                announcementsAmount={announcementsImage.length}
                activeIndex={activeIndexImg + 1}
                middle={true}
              />
              <AnnouncementImage
                anouncement={announcementsImage[countNextIndexImg]}
                announcementsAmount={announcementsImage.length}
                activeIndex={countNextIndexImg || announcementsImage.length}
              />
            </div>
          }
          <Button
            style={{
              height: 40,
              opacity: announcementsImage.length > 1 ? 1 : 0,
            }}
            onClick={() => setNextActiveIndexImg()}
            icon="chevron right"
          />
        </div>
      ) : null}

      {announcementsText.length ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          <Button
            style={{
              height: 40,
              opacity: announcementsText.length > 1 ? 1 : 0,
            }}
            onClick={() => setPreviousActiveIndex()}
            icon="chevron left"
          />
          {
            <AnnouncementDisplay
              text={announcementsText[activeIndex].content || []}
              editable={false}
              key={announcementsText[activeIndex].id}
            />
          }
          <Button
            style={{
              height: 40,
              opacity: announcementsText.length > 1 ? 1 : 0,
            }}
            onClick={() => setNextActiveIndex()}
            icon="chevron right"
          />
        </div>
      ) : null}
    </>
  );
};
