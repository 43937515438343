import { InputNumberProps, TActionData } from "~/components/InputNumber/InputNumber";
import React from "react";

export const calculateNewSelectionStart = (actionData:  React.MutableRefObject<TActionData | undefined>, newValue: string, precision: number = 0, ): { start: number, end: number } => {
    const { oldValue, selectionStart, key } = actionData.current!

    const hasDecimal = newValue.indexOf(',') !== -1
    const decimalEdit = hasDecimal && selectionStart > oldValue.indexOf(',')

    let start, end

    if (decimalEdit) {
        if (key === 'Backspace') {
            start = selectionStart - 1
        } else if (selectionStart === newValue.length) {
            start = selectionStart - precision
            end = newValue.length
        } else {
            start = selectionStart + 1
        }
    } else if (key === ',' || key === '.') {
        start = newValue.indexOf(',') + 1
    } else {
        const selectionOffset = newValue.length - oldValue.length
        start = selectionStart + selectionOffset + (key === 'Delete' ? 1 : 0)
    }
    return {
        start,
        end: end || start
    }
}

export const deformat = (value: string): number => {
    return Number(value.replace(/\xa0/g, '').replace(/ /g, '').replace(',', '.').replace(/,/g, ''))
}
export function setInputProperties(input: HTMLInputElement, newValue: string, newSelection: { start: number; end: number }) {
    input.value = newValue
    input.selectionStart = newSelection.start
    input.selectionEnd = newSelection.end
}

export function setIfExceedsRange(min: number | undefined, max: number | undefined, newRawValue: number) {
    if ((max || max === 0) && newRawValue > max) {
        newRawValue = max
    } else if ((min || min === 0) && newRawValue < min) {
        newRawValue = min
    }
    return newRawValue;
}

export function isCharBlankOrNonBreakingSpace(chard: string) {
    return [' ', '\xa0'].indexOf(chard) === -1;
}

export function setSelectionStartEnd(input: HTMLInputElement, selectionStart: number) {
    input.selectionStart = selectionStart
    input.selectionEnd = selectionStart
}

export function getInputButtons(increment: (event: any) => void, props: InputNumberProps & { children?: React.ReactNode | undefined }, decrement: (event: any) => void) {
    return (
        <>
            <button onClick={increment} onBlur={props.onBlur && props.onBlur} style={{ top: 0 }}>▲</button>
    <button onClick={decrement} onBlur={props.onBlur && props.onBlur} style={{ bottom: 0 }}>▼</button>
    </>
)
}



