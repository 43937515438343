import { Table } from "semantic-ui-react";
import { TDetailsField } from "~/store/dictionaries/types";
import React from "react";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { DictionariesTableProps } from "~/pages/Administration/lib/Dictionaries/Table/constants";
import TableHeaders from "~/pages/Administration/lib/Dictionaries/Table/TableHeaders";
import TableBody from "~/pages/Administration/lib/Dictionaries/Table/TableBody";

const DictionariesTable = ({
  dictionaries,
  refresh,
  name,
}: DictionariesTableProps) => {
  const filterDictionaryHeader = (field: TDetailsField): boolean => {
    if (dictionaries.details.name === "payment-factor") {
      return field.name !== "label";
    } else if (dictionaries.details.name === "payment-method") {
      return field.name !== "value";
    }
    return true;
  };

  return (
    <Table style={{ whiteSpace: "nowrap", marginTop: 0 }} selectable>
      <TableHeaders filterDictionaryHeader={filterDictionaryHeader} />
      <TableBody
        refresh={refresh}
        name={name}
        filterDictionaryHeader={filterDictionaryHeader}
      />
    </Table>
  );
};

const mapStateToProps = ({ dictionaries }: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(DictionariesTable);
