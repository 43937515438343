import { PaginationMeta, TotalRecords, Paginable } from "~/store/types";
import { SupplierSystem } from "../customer/types";

export type TInvoiceIdNumber = {
  documentMask: string;
  invoiceNo: string;
};
export type OrderCustomer = {
  city: string;
  companyName: string;
  country: string;
  email: string;
  mobilePhone: string;
  name: string;
  officePhone: string;
  postcode: string;
  rrid: string;
  street: string;
  streetNumber: string;
  surname: string;
  taxCode: string;
  paymentCategory: string;
  paymentCreditBlocked: boolean;
  paymentCreditLimit: number;
  blocked: boolean;
  orderType: string;
  warehouseId: string;
  sapClientId: string;
  ddsClientId: string;
  sapDeliveryId: string;
  ddsDeliveryId: string;
  supplierSystem: SupplierSystem[] | null;
  supplierSystemDeliveryId?: string;
  supplierSystemClientId?: string;
  discountDRZPercentage: number | null;
};

export type OrderLine = {
  bonus: any;
  confirmedCrossDock: number;
  confirmedQuantity: number;
  description: string;
  discount: any;
  discountCode: any;
  grossPrice: number;
  lineNo: number;
  netPrice: number;
  netPriceListPrice: number;
  pricePNR: number;
  orderedCrossDock: number;
  packingGtin: string;
  promoCode: any;
  provisionCategory: string;
  provisionId: string;
  quantity: number;
  referenceID: string;
  rejectedQuantity: number;
  rejectionReason: any;
  rejectionReasons: TRejectionReason[];
  awaitingForReplenishment: number;
  taxPercent: number;
  taxValue: number;
  totalTaxValue: number;
  type: TPartType;
  packagingQuantity: number;
  totalNetPrice: number;
  totalGrossPrice: number;
  stock: number;
  replacedLineNo: number;
  provisioningLineNo: number;
  backorderDocuments: string[];
  backorderReplenishmentDocuments: string[];
  gdnDocuments: string[];
  partOrigin: string;
  replacedLineQuantity: number;
  replacedLineReferenceID: string;
  sapInvoices: string[];
  codes?: ProductCodes;
  sapStatus?: string;
  selected?: boolean;
  invoiceIdNumbers: TInvoiceIdNumber[];
  manualOrderDocument: string | null;
  priceReductionDiscountCode?: string;
  stockLevel: number | null;
  packageNumbers?: string[];
  priceDRZ: number | null;
};

export type TOrderImportLine = {
  bonus: number | null;
  codes: ProductCodes | null;
  description: string;
  packagingQuantity: number;
  forcePackagingQuantity: number;
  replacementBaseQuantity: number | null;
  orderPartType: TPartType;
  pnrPrice: number;
  pvpPrice: number;
  priceTax: string;
  quantity: number;
  referenceID: string;
  stock: number;
  sellPrice?: number;
  priceReductionDiscountCode?: string;
  stockLevel: number | null;
  promoCode?: string;
  priceDRZ: number | null;
};

export type ProductCodeField = {
  required: boolean;
  value?: string;
};

export type ProductCodes = {
  vin?: ProductCodeField;
  cleCode?: ProductCodeField;
  transmitterCode?: ProductCodeField;
  wheelCode?: ProductCodeField;
  nreCode?: ProductCodeField;
  tiresCode?: ProductCodeField;
};

export type TProductCodesCreate = {
  vin?: string;
  cleCode?: string;
  transmitterCode?: string;
  wheelCode?: string;
  nreCode?: string;
  tiresCode?: string;
};

export type TRejectionReason = {
  type: string;
  referenceID: string;
  expectedThreshold?: number;
  customerId?: string;
  replacements?: any;
};

export enum OrderStatusEnum {
  NEW = "NEW",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  WAITING_FOR_CUSTOMER_LIMIT = "WAITING_FOR_CUSTOMER_LIMIT",
  WAITING_FOR_MANUAL_ACCEPTANCE = "WAITING_FOR_MANUAL_ACCEPTANCE",
  COMPLETED = "COMPLETED",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}

export enum DELIVERY_TYPE_ENUM {
  NORMAL = "NORMAL",
  WARRANTY = "WARRANTY",
  BONUS = "BONUS",
  SPECIAL = "SPECIAL",
  BONUS_PLUS = "BONUS_PLUS",
}

export enum ORDER_SOURCE_ENUM {
  SERVICE_BOX = "SERVICE_BOX",
  LDC = "LDC",
}

export enum ORDER_TYPE_ENUM {
  WARRANTY = "WARRANTY",
  NORMAL = "NORMAL",
  BONUS = "BONUS",
  SPECIAL = "SPECIAL",
  BONUS_PLUS = "BONUS_PLUS",
}

export interface Order {
  bonusSpecialOrderDiscountLimit?: number;
  prioritized: any;
  author: string;
  id: string;
  comments: string;
  acceptingUser: string;
  serviceNotes: string;
  customer: OrderCustomer;
  deliveryCustomer: OrderCustomer;
  orderDate: string;
  orderNumber: string;
  lines: OrderLine[];
  parts: OrderLine[];
  paymentMethod: string;
  realisations: any[];
  rejectionReason: TRejectionReason[];
  status: OrderStatusEnum;
  type: string;
  deliveryType: DELIVERY_TYPE_ENUM;
  priority: number;
  deliveryDate: string;
  statusDate: string;
  realisationPercent: number;
  linesCount: number;
  priceNetTotal: number;
  priceGrossTotal: number;
  source: ORDER_SOURCE_ENUM;
  sagaiNumber: string;
  sagaiPartsCost: number;
  sagaiWorkCost: number;
  cancelable: boolean;
  anomalies?: TAnomaly[];
  modifiedBySap?: boolean;
  serviceBoxOrderNo?: string;
  backorderIdNumberList?: TBackOrderNumberList[];
  backorderSentTime: string;
  forcedPaymentMethodType?: "DISTRIGO_CREDIT";
  manualOrderIDList: string[] | null;
}

type TBackOrderNumberList = {
  backorderID: string;
  backorderNumber: string;
  batchId?: string;
  backorderSupplier: string | null;
};

type TAnomaly = {
  anomalyID: string;
  anomalyNo: string;
};

export interface OrderStatus {
  status: string;
  onList: boolean;
}

export type TPartType =
  | "NORMAL"
  | "REPLACED"
  | "REPLACEMENT"
  | "PROVISION"
  | "BLOCKED"
  | "REPLACEMENT_ORIGINAL"
  | "REPLACED_TO_ORIGINAL";

export type TPartCreate = {
  referenceID: string;
  netPrice: number;
  quantity: number;
  bonus?: number;
  discountCode?: string;
  codes?: TProductCodesCreate;
  type?: TPartType;
  netPriceListPrice?: number;
  priceBeforeDiscount?: number;
  pricePNR?: number;
  pricePVP?: number;
  promoCode?: string;
};

export type TOrderCreate = {
  bonusSpecialOrderDiscountLimit: number | null;
  id: string;
  customerId?: string;
  deliveryCustomerId?: string;
  comments?: string;
  serviceNotes?: string;
  type?: "NORMAL" | "WARRANTY" | "BONUS" | "SPECIAL" | "BONUS_PLUS";
  deliveryDate?: string;
  bonus?: number;
  promoCode?: string;
  priority?: number;
  parts?: TPartCreate[];
  sagaiNumber?: string;
  sagaiPartsCost?: number;
  sagaiWorkCost?: number;
  paymentMethod?: string;
  forcedPaymentMethodType?: "DISTRIGO_CREDIT";
};

export type TDeliveryType = "STOCK" | "URGENT" | "TURBO_PRO";

export interface OrderBonusImportLine {
  orderID: any;
  orderNumber: string;
}

export type ApiResponse = Record<string, any>;

export interface OrderSelectedPayloadOrderSelectedPayload {
  detail: Order;
  items: [];
}

export enum OrdersActionTypes {
  PLACE_ORDER = "@@orders/PLACE_ORDER",
  PLACE_ORDER_SUCCESS = "@@orders/PLACE_ORDER_SUCCESS",
  CLEAR_CREATED = "@@orders/CLEAR_CREATED",
  FETCH_REQUEST = "@@orders/FETCH_REQUEST",
  FETCH_SEARCH_REQUEST = "@@orders/FETCH_SEARCH_REQUEST",
  FETCH_SUCCESS = "@@orders/FETCH_SUCCESS",
  FETCH_SEARCH_SUCCESS = "@@orders/FETCH_SEARCH_SUCCESS",
  FETCH_ERROR = "@@orders/FETCH_ERROR",
  SELECT_ORDER = "@@orders/SELECT_ORDER",
  SELECT_SPARE_PART = "@@orders/SELECT_SPARE_PART",
  SELECTED = "@@orders/SELECTED",
  CLEAR_SELECTED = "@@orders/CLEAR_SELECTED",
  CHANGE_STATUS_ORDER = "@@orders/CHANGE_STATUS_ORDER",
  CHANGE_STATUS_ORDER_SUCCESS = "@@orders/CHANGE_STATUS_ORDER_SUCCESS",
  APPLY_PROMO = "@@orders/APPLY_PROMO",
  PROMO_APPLIED = "@@orders/PROMO_APPLIED",
  CLEAR_PROMO = "@@orders/CLEAR_PROMO",
  FETCH_LINES = "@@orders/FETCH_LINES",
  FETCH_LINES_SUCCESS = "@@orders/FETCH_LINES_SUCCESS",
  ADD_LINE = "@@orders/ADD_LINE",
  UPDATE_LINE = "@@orders/UPDATE_LINE",
  DELETE_LINE = "@@orders/DELETE_LINE",
  LOAD_CSV = "@@orders/LOAD_CSV",
  LOAD_XLS = "@@orders/LOAD_XLS",
  LOAD_XLS_SPECIAL = "@@orders/LOAD_XLS_SPECIAL",
  LOAD_LINES_LOADED = "@@orders/LOAD_LINES_LOADED",
  LINES_OPERATION_FINISHED = "@@orders/LINES_OPERATION_FINISHED",
  UPLOAD_BONUS_ORDERS = "@@orders/UPLOAD_BONUS_ORDERS",
  UPLOAD_BONUS_ORDERS_SUCCESS = "@@orders/UPLOAD_BONUS_ORDERS_SUCCESS",
  UPLOAD_BONUS_ORDERS_CLEAR = "@@orders/UPLOAD_BONUS_ORDERS_CLEAR",
  UPLOAD_CANCEL_LINES = "@@orders/UPLOAD_CANCEL_LINES",
  UPLOAD_CANCEL_LINES_SUCCESS = "@@orders/UPLOAD_CANCEL_LINES_SUCCESS",
  UPLOAD_CANCEL_LINES_CLEAR = "@@orders/UPLOAD_CANCEL_LINES_CLEAR",
  CANCEL_LINES = "@@orders/CANCEL_LINES",
  CANCEL_LINES_SUCCESS = "@@orders/CANCEL_LINES_SUCCESS",
  CANCEL_LINES_CLEAR = "@@orders/CANCEL_LINES_CLEAR",
  CANCEL_ORDER = "@@orders/CANCEL_ORDER",
  CANCEL_ORDER_SUCCESS = "@@orders/CANCEL_ORDER_SUCCESS",
  SET_TYPE_OF_EXPORT_DATA = "@@invoices/SET_TYPE_OF_EXPORT_DATA",
  OPEN_CHOOSE_EXPORT_DATA_MODAL = "@@invoices/OPEN_CHOOSE_EXPORT_DATA_MODAL",
  SAVE_DATA_FROM_SEARCHFORM = "@@orders/SAVE_DATA_FROM_SEARCHFORM",
  SAVE_DATA_TO_SEARCHFORM = "@@orders/SAVE_DATA_TO_SEARCHFORM",
  SAVE_REFERENCE_ID_SEARCHFORM = "@@orders/SAVE_REFERENCE_ID_SEARCHFORM",
  PRIORITIZE = "@@orders/PRIORITIZE",
  DEPRIORITIZE = "@@orders/DEPRIORITIZE",
  EXPORT_ON_REQUEST = "@@orders/EXPORT_ON_REQUEST",
  EXPORT_ON_REQUEST_SUCCESS = "@@orders/EXPORT_ON_REQUEST_SUCCESS",
  EXPORT_ON_REQUEST_ERROR = "@@orders/EXPORT_ON_REQUEST_ERROR",
}

export interface OrdersState {
  readonly operationSuccess: boolean;
  readonly list: Order[];
  readonly searchList: Order[];
  readonly searchableCustomers: any[];
  readonly meta: PaginationMeta;
  readonly linesMeta: PaginationMeta;
  readonly creating: boolean;
  readonly promoApplied: boolean;
  readonly promoOrder: any;
  readonly parsedLines: any[];
  readonly orderBonusLines: OrderBonusImportLine[];
  readonly totalRecords: TotalRecords;
  readonly loadingOrders: boolean;
  readonly loadingOrder: boolean;
  readonly loadingLines: boolean;
  readonly loadingBonusOrder: boolean;
  readonly loadingCancelLines: boolean;
  readonly loadingCancelLine: boolean;
  readonly selected?: Order;
  readonly selectedLines?: OrderLine[];
  readonly created?: string;
  readonly isChecked?: boolean;
  readonly selectedTypeOfDataToExport?: string;
  readonly isOpenChooseTypeOfExportDataModal?: boolean;
  readonly result?: object;
  readonly ordersReferenceId?: string;
  readonly ordersDateFrom?: Date;
  readonly ordersDateTo?: Date;
  readonly exportOnRequestPending?: boolean;
}

export type ModifyLinePayload = {
  orderId: string;
  line: OrderLine;
};

export type PaginableOrder = Paginable<Order>;

export type PaginableOrderLine = Paginable<OrderLine>;

export type PaginationParams = {
  page: number;
  size: number;
};
