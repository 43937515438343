import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useInvoicesErpColumns = () => {
  const { getDateCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "invoiceNumber",
        label: "Numer faktury",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "deliveryNumber",
        label: "Numer dostawy",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "deliveryDate",
        label: "Data dostawy",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "orderIssuerCustomerId",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "shipToPartyId",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceType",
        label: "Typ dokumentu",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderNumber",
        label: "Zamówienie LDC",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderDate",
        label: "Data zamówienia ERP",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, false),
      },
      {
        name: "orderType",
        label: "Typ Zamówienia ERP",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "salesOrganisation",
        label: "Dostawca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "sapOrderNumber",
        label: "Numer zamówienia SAP",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
        hide: true,
        disableSort: true,
      },
    ],
    [getDateCell, getTextCell]
  );

  return { columnsConfig };
};
