import {
  AnnouncementType,
  CustomerRoles,
} from "~/pages/Dashboard/lib/constans";
import { Paginable, PaginationMeta, TotalRecords } from "../types";

export type ApiResponse = Record<string, any>;

export enum AnnouncementsActionTypes {
  FETCH_ANNOUNCEMENTS = "@@announcements/FETCH_ANNOUNCEMENTS",
  FETCH_ANNOUNCEMENTS_SUCCESS = "@@announcements/FETCH_ANNOUNCEMENTS_SUCCESS",
  FETCH_ANNOUNCEMENT_LIST_ERROR = "@@announcements/FETCH_ANNOUNCEMENT_LIST_ERROR",
  FETCH_ANNOUNCEMENT_LIST = "@@announcements/FETCH_ANNOUNCEMENT_LIST",
  FETCH_ANNOUNCEMENT_LIST_SUCCESS = "@@announcements/FETCH_ANNOUNCEMENT_LIST_SUCCESS",
  FETCH_ANNOUNCEMENTS_ERROR = "@@announcements/FETCH_ANNOUNCEMENTS_ERROR",
  SELECT_ANNOUNCEMENT = "@@announcements/SELECT_ANNOUNCEMENT",
  SELECT_ANNOUNCEMENT_SUCCESS = "@@announcements/SELECT_ANNOUNCEMENT_SUCCESS",
  FETCH_ANNOUNCEMENT_DETAILS = "@@announcements/FETCH_ANNOUNCEMENT_DETAILS",
  FETCH_ANNOUNCEMENT_DETAILS_SUCCESS = "@@announcements/FETCH_ANNOUNCEMENT_DETAILS_SUCCESS",
  UPLOAD_ANNOUNCEMENT_IMAGE = "@@announcements/UPLOAD_ANNOUNCEMENT_IMAGE",
  UPLOAD_ANNOUNCEMENT_START = "@@announcements/UPLOAD_ANNOUNCEMENT_START",
  UPLOAD_ANNOUNCEMENT = "@@announcements/UPLOAD_ANNOUNCEMENT",
  UPLOAD_ANNOUNCEMENT_SUCCESS = "@@announcements/UPLOAD_ANNOUNCEMENT_SUCCESS",
  REMOVE_ANNOUNCEMENT_REFRESH = "@@announcements/REMOVE_ANNOUNCEMENT_REFRESH",
  REMOVE_ANNOUNCEMENT = "@@announcements/REMOVE_ANNOUNCEMENT",
  REMOVE_ANNOUNCEMENT_SUCCESS = "@@announcements/REMOVE_ANNOUNCEMENT_SUCCESS",
  SHOW_ANNOUNCEMENT = "@@announcements/SHOW_ANNOUNCEMENT",
  HIDE_ANNOUNCEMENT = "@@announcements/HIDE_ANNOUNCEMENT",
  EDIT_ANNOUNCEMENT = "@@announcements/EDIT_ANNOUNCEMENT",
  EDIT_ANNOUNCEMENT_SUCCESS = "@@announcements/EDIT_ANNOUNCEMENT_SUCCESS",
  EDIT_ANNOUNCEMENT_REFRESH = "@@announcements/EDIT_ANNOUNCEMENT_REFRESH",
}

export interface AnnouncementsState {
  readonly announcements: Announcement[];
  readonly announcementList: Announcement[];
  readonly announcementDetails: Announcement | undefined;
  readonly loadingAnnouncements: boolean;
  readonly loadingAnnouncementsError?: any;
  readonly loadingAnnouncementList: boolean;
  readonly loadingAnnouncementDetails: boolean;
  readonly loadingAnnouncementAdd: boolean;
  readonly meta: PaginationMeta;
  readonly totalRecords: TotalRecords;
  readonly completeCodedPartSuccess?: boolean;
  readonly selectedAnnouncement?: Announcement;
  readonly created?: string;
  readonly removeSuccess: boolean;
  readonly removeLoading: boolean;
  readonly editLoading: boolean;
  readonly editSuccess: boolean;
}

export type Announcement = {
  content: any[] | null;
  createDate: string;
  deleted: boolean;
  endDate: string;
  id: string;
  image: string | null;
  intendedFor: CustomerRoles[];
  priority: number;
  startDate: string;
  title: string;
  type: AnnouncementType;
  author: string;
  hidden: boolean;
};

export type TNewAnnouncementDTO = {
  hidden: boolean;
  content: string | null;
  endDate: string | undefined;
  image: string | null;
  intendedFor: CustomerRoles[];
  priority: number;
  startDate: string | undefined;
  title: string;
  type: AnnouncementType;
  file?: File;
  imageOriginal?: string;
};

export type PaginableAnnouncements = Paginable<Announcement>;
