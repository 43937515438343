import { Button, ButtonProps } from "semantic-ui-react";
import React, { Fragment } from "react";
import { HeaderPopupBtn } from "~/components/PageHeader/HeaderPopupBtn";
import { HeaderWindowProgressBtn } from "~/components/PageHeader/HeaderWindowProgressBtn";
import RefreshPageButton from "~/components/RefreshPageButton/RefreshPageButton";
import { THeaderButton } from "~/components/PageHeader/lib/types";

export const getHeaderButton = (button: ButtonProps & { popup?: string | JSX.Element; visible?: boolean; window?: number }, index: number, loading: boolean) => {
    delete button.visible
    const buttonElement = <Fragment key={index}><Button key={index} labelPosition={button.icon ? 'left' : undefined}
                                                        style={{ minWidth: 125, }}
                                                        disabled={loading} {...button} /></Fragment>
    if (button.popup) {
        return (
            <HeaderPopupBtn key={index} trigger={buttonElement} button={button}/>
        )
    }
    if (button.window) {
        return (
            <HeaderWindowProgressBtn key={index} buttonElement={buttonElement} button={button}/>
        )
    }
    return buttonElement
}

export const filterVisibleBtn = (button: ButtonProps & { popup?: string | JSX.Element; visible?: boolean; window?: number }) => {
    return button.visible === true || button.visible === undefined;
}

export const getRefreshBtn = (refreshAction: ((...params: any[]) => void) | undefined, loading: boolean): JSX.Element | null => {
    return refreshAction ? <RefreshPageButton key={-1} refreshAction={refreshAction} loading={loading}/> : null;
}

export const getButtons = (buttons: THeaderButton[], loading: boolean, refreshAction: ((...params: any[]) => void) | undefined): (JSX.Element[] | JSX.Element | null)[] => {
    return [
        buttons.filter((button) => filterVisibleBtn(button))
            .map((button, index) => getHeaderButton(button, index, loading)),
        getRefreshBtn(refreshAction, loading)
    ]
}