import { Button, Container, Grid, Header, Message, Modal } from 'semantic-ui-react';
import React, { ReactElement, useState } from 'react';
import CommonLoader from '~/components/Loaders/CommonLoader';

type TProps = {
  loading: boolean;
  triggerButton?: ReactElement;
  list: any[];
};

const HistoryModal: React.FC<TProps> = ({ children, loading, triggerButton, list }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  return (
    <Modal
      size="large"
      trigger={
        triggerButton || <Button style={{ display: 'block', marginTop: '20px' }} color="orange" content="Historia" />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="briefcase" content="Historia" />
      <Modal.Content>
        <Grid style={{ marginBottom: 'unset' }} stretched>
          {list?.length !== 0 ? (
            <div style={{ maxHeight: '300px', width: '100%', overflowY: 'scroll' }}>{children}</div>
          ) : (
            <Message style={{ width: '100%' }}>
              <Container fluid textAlign="center">
                Brak elementów do wyświetlenia.
              </Container>
            </Message>
          )}
        </Grid>
        <CommonLoader loading={loading} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

export default HistoryModal;
