import { Button, Icon, Popup, Table } from "semantic-ui-react"

import TableCellPopup from '~/components/TableCellPopup/TableCellPopup'
import React from 'react'
import TableNoResults from "~/components/TableNoResults/TableNoResults"
import { Replenishment } from "./Replenishments"
import { parseDate } from "~/utils/dateUtils"
import { Link } from "react-router-dom"
import GoodsDispatchNotesDetails from '~/pages/Accounting/Details/GoodsDispatchNotes'
import GoodsReceivedNotesDetails from '~/pages/Accounting/Details/GoodsReceivedNotes'
import { dictionariesClaimStatus } from "./replenishments.conf"
import mapDictionary from "~/components/MapDictionary/mapDictionary"

interface Props {
    replenishments: Replenishment[]
    columns: {
        name: keyof Replenishment
        label: string
        projection: boolean
    }[],
    dictionaries: any
}

class ReplenishmentsList extends React.Component<Props> {

    shouldComponentUpdate(nextProps: Props): boolean {
        return (JSON.stringify(nextProps.columns) !== JSON.stringify(this.props.columns)) ||
            (JSON.stringify(nextProps.replenishments) !== JSON.stringify(this.props.replenishments))
    }

    private getCell(replenishment: Replenishment, name: keyof Replenishment): JSX.Element {
        switch (name) {
            case 'backorders':
                return <Table.Cell key={name}>
                    {/* {replenishment.coveredByBackorders} */}
                    {`${replenishment.awaitingBackorder || 0} / ${replenishment.coveredByBackorders || 0}`}
                    <Popup
                        position="bottom right"
                        size="small"
                        flowing
                        style={{ zIndex: 999 }}
                        content={replenishment.backorders.length === 0 ?
                            'Brak zamówień' :
                            replenishment.backorders.map((backorder) =>
                                <div><Link to={`/backorder/${backorder.backorderID}`}>
                                    {backorder.backorderNumber}
                                </Link></div>
                            )
                        }
                        on='click'
                        trigger={
                            <Icon.Group size='large' style={{ marginLeft: 10, cursor: 'pointer', opacity: !replenishment.backorders.length ? 0.2 : 1 }}>
                                <Icon name='circle outline' fitted />
                                <Icon name='info' size='mini' fitted />
                            </Icon.Group>
                        } />

                </Table.Cell>

            case 'creationDate':
                return <Table.Cell key={name}>{replenishment.creationDate ? parseDate(replenishment.creationDate) : ''}</Table.Cell>
            case 'gdnLines':
                return <Table.Cell key={name}>

                    {`${replenishment.awaitingDispatch || 0} / ${replenishment.dispatched || 0}`}

                    <Popup
                        position="bottom right"
                        size="small"
                        flowing
                        style={{ zIndex: 999 }}
                        content={replenishment.gdnLines.length === 0 ?
                            'Brak WZ' :
                            replenishment.gdnLines.map((gdnLine) =>
                                <GoodsDispatchNotesDetails key={gdnLine.gdnId} noteId={gdnLine.gdnId}>
                                    <Button style={{ display: 'block' }} compact basic>{gdnLine.gdnId}</Button>
                                </GoodsDispatchNotesDetails>
                            )
                        }
                        on='click'
                        trigger={
                            <Icon.Group size='large' style={{ marginLeft: 10, cursor: 'pointer', opacity: !replenishment.gdnLines.length ? 0.2 : 1 }}>
                                <Icon name='circle outline' fitted />
                                <Icon name='info' size='mini' fitted />
                            </Icon.Group>
                        } />

                </Table.Cell>
            case 'grns':
                return <Table.Cell key={name}>
                    {replenishment.coveredByCreatedGrns}
                    <Popup
                        position="bottom right"
                        size="small"
                        flowing
                        style={{ zIndex: 999 }}
                        content={replenishment.grns.length === 0 ?
                            'Brak PZ' :
                            replenishment.grns.map((grnNumber) =>
                                <GoodsReceivedNotesDetails key={grnNumber} noteId={grnNumber}>
                                    <Button style={{ display: 'block' }} compact basic>{grnNumber}</Button>
                                </GoodsReceivedNotesDetails>
                            )
                        }
                        on='click'
                        trigger={
                            <Icon.Group size='large' style={{ marginLeft: 10, cursor: 'pointer', opacity: !replenishment.grns.length ? 0.2 : 1 }}>
                                <Icon name='circle outline' fitted />
                                <Icon name='info' size='mini' fitted />
                            </Icon.Group>
                        } />

                </Table.Cell>
            case 'status':
                return <Table.Cell key={name}>{mapDictionary(replenishment.status, dictionariesClaimStatus, true, true, true)}</Table.Cell>
            case 'orderType':
                return <Table.Cell key={name}>{mapDictionary(replenishment.orderType, this.props.dictionaries["order-delivery-type"], true, true, true)}</Table.Cell>
            case 'priority':
                return <Table.Cell key={name}> {replenishment.priority ? 'Tak' : 'Nie'} </Table.Cell>
            default:
                return <TableCellPopup key={name}>{replenishment[name]}</TableCellPopup>
        }
    }

    render() {
        const { replenishments, columns } = this.props

        if (replenishments.length === 0) {
            return (
                <TableNoResults />
            )
        }

        return (
            replenishments.map(replenishment =>
                <Table.Row key={replenishment.replenishmentID.orderID}>
                    {columns.map(column =>
                        column.projection && this.getCell(replenishment, column.name)
                    )}
                </Table.Row>
            )
        )
    }
}

export default ReplenishmentsList