import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import translations from '~/utils/translations';
import { WZPopupProps } from './constants';
import GoodsDispatchNotesDetails from '~/pages/Accounting/Details/GoodsDispatchNotes';

export const WZPopup = ({ claim }: WZPopupProps) => {
  return (
    <Popup
      position="bottom right"
      size="small"
      flowing
      style={{ zIndex: 999 }}
      content={
        claim.gdnNumbers.length === 0
          ? translations.format('app.no.WZ')
          : claim.gdnNumbers.map((gdnNumber) => (
              <GoodsDispatchNotesDetails noteId={gdnNumber}>
                <Button style={{ display: 'block' }} compact basic>
                  {gdnNumber}
                </Button>
              </GoodsDispatchNotesDetails>
            ))
      }
      on="click"
      trigger={
        <Icon.Group
          size="large"
          style={{
            marginLeft: 10,
            cursor: 'pointer',
            opacity: !claim.gdnNumbers.length ? 0.2 : 1,
          }}
        >
          <Icon name="circle outline" fitted />
          <Icon name="info" size="mini" fitted />
        </Icon.Group>
      }
    />
  );
};
