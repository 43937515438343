import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';

import { DetailCardWarrantyProps } from '~/pages/Orders/Details/lib/constants';
import { OrderStatusEnum } from '~/store/orders/types';

const DetailCardWarranty = ({ order, renderField, isStatus, renderNumber }: DetailCardWarrantyProps) => (
  <DetailCard title="Gwarancja" id="warranty" key="warranty" minHeight={40} width={3} smallWidth={5.33}>
    {renderField('sagaiNumber', {
      label: 'Nr wniosku gwar.',
      readOnly: !isStatus(
        order.status,
        OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
        OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
      ),
    })}
    {renderNumber('sagaiPartsCost', {
      label: 'Koszt części zamiennych',
      readOnly: !isStatus(
        order.status,
        OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
        OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
      ),
    })}
    {renderNumber('sagaiWorkCost', {
      label: 'Koszt robocizny',
      readOnly: !isStatus(
        order.status,
        OrderStatusEnum.WAITING_FOR_CUSTOMER_LIMIT,
        OrderStatusEnum.WAITING_FOR_MANUAL_ACCEPTANCE
      ),
    })}
  </DetailCard>
);

export default DetailCardWarranty;
