import React, { useMemo, useState } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";

type TProps = {};

enum CancelRequestsTabs {
  MonthlyShoppingTasks,
  QuarterlyTurnover,
  QuarterlyBasketTurnover,
}

const Results: React.FC<TProps> = () => {
  const [activeTab, setActiveTab] = useState<CancelRequestsTabs>(
    CancelRequestsTabs.MonthlyShoppingTasks
  );
  const breadcrumbs = useMemo(
    () => [
      {
        text: "Zadania zakupowe miesięczne",
        id: CancelRequestsTabs.MonthlyShoppingTasks,
        clickAction: () =>
          setActiveTab(CancelRequestsTabs.MonthlyShoppingTasks),
      },
      {
        text: "Obrót kwartał",
        id: CancelRequestsTabs.QuarterlyTurnover,
        clickAction: () => setActiveTab(CancelRequestsTabs.QuarterlyTurnover),
      },
      {
        text: "Obrót koszyk kwartał",
        id: CancelRequestsTabs.QuarterlyBasketTurnover,
        clickAction: () =>
          setActiveTab(CancelRequestsTabs.QuarterlyBasketTurnover),
      },
    ],
    []
  );
  return (
    <>
      <PageHeader
        icon="chart line"
        title="Wyniki"
        breadcrumb={breadcrumbs}
        breadCrumbTab={activeTab}
        buttons={[]}
        loading={false}
        refreshAction={() => {
          console.log("not implemented");
        }}
      ></PageHeader>
    </>
  );
};

export default Results;
