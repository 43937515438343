import { AccountingBreadcrumbs } from "../Accounting";

type Props = {
  setActiveTab: (value: React.SetStateAction<AccountingBreadcrumbs>) => void;
};

export const useAccountingBreadcrumbs = (props: Props) => {
  const { setActiveTab } = props;

  const breadcrumbs = [
    {
      text: "Faktury",
      id: AccountingBreadcrumbs.INVOICES,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.INVOICES),
    },
    {
      text: "Korekty faktur",
      id: AccountingBreadcrumbs.CORRECTIONS,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.CORRECTIONS),
    },
    {
      text: "Potwierdzenia RW",
      id: AccountingBreadcrumbs.DEBIT_NOTES,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.DEBIT_NOTES),
    },
    {
      text: "Korekty potwierdzeń RW",
      id: AccountingBreadcrumbs.DEBIT_NOTES_CORRECTIONS,
      clickAction: () =>
        setActiveTab(AccountingBreadcrumbs.DEBIT_NOTES_CORRECTIONS),
    },
    {
      text: "Korekty zbiorcze FV",
      id: AccountingBreadcrumbs.COLLECTIVE_CORRECTIONS_LIST,
      clickAction: () =>
        setActiveTab(AccountingBreadcrumbs.COLLECTIVE_CORRECTIONS_LIST),
    },
    {
      text: "Korekty zbiorcze potwierdzeń RW",
      id: AccountingBreadcrumbs.COLLECTIVE_DEBIT_NOTE_CORRECTIONS,
      clickAction: () =>
        setActiveTab(AccountingBreadcrumbs.COLLECTIVE_DEBIT_NOTE_CORRECTIONS),
    },
    {
      text: "WZ",
      id: AccountingBreadcrumbs.WZ,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.WZ),
    },
    {
      text: "PZ",
      id: AccountingBreadcrumbs.PZ,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.PZ),
    },
    {
      text: "MM",
      id: AccountingBreadcrumbs.MM,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.MM),
    },
    {
      text: "RW",
      id: AccountingBreadcrumbs.RW,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.RW),
    },
    {
      text: "Dokumenty źródłowe",
      id: AccountingBreadcrumbs.SOURCE_DOCUMENTS,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.SOURCE_DOCUMENTS),
    },
    {
      text: "Do zafakturowania",
      id: AccountingBreadcrumbs.ITEMS_TO_INVOICE,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.ITEMS_TO_INVOICE),
    },
    {
      text: "SENT100",
      id: AccountingBreadcrumbs.SENT100,
      clickAction: () => setActiveTab(AccountingBreadcrumbs.SENT100),
    },
  ];

  return breadcrumbs;
};
