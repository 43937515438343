import React, { useCallback, useMemo } from "react";
import "./TextEditor.scss";

import { Editable, withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { ITextDisplayProps } from "./constans";

type TElementProps = {
  attributes: any;
  children: any;
  element: any;
};

type TLeafProps = {
  attributes: any;
  children: any;
  leaf: any;
};

export const AnnouncementDisplay: React.FC<ITextDisplayProps> = ({
  text,
  editable,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const Element = ({ attributes, children, element }: TElementProps) => {
    const style = { textAlign: element.align };
    switch (element.type) {
      case "block-quote":
        return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
        );
      case "bulleted-list":
        return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
        );
      case "heading-one":
        return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
        );
      case "heading-two":
        return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
        );
      case "list-item":
        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
        );
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        );
    }
  };

  const Leaf = ({ attributes, children, leaf }: TLeafProps) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  return (
    <Slate editor={editor} initialValue={text}>
      <Editable
        readOnly={!editable}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        className="text-styling"
      />
    </Slate>
  );
};
