import { Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"

import React from 'react'

import './table-preview-details.scss'

type TProps = {
    path: string
}

const TablePreviewDetails: React.FC<TProps> = ({ path }): JSX.Element => {
    return (
        <Link to={path} className='table-preview-details' title='Podgląd'>
            <Icon name='arrow right' />
        </Link>
    )
}

export default TablePreviewDetails
