import React, { useEffect, useState } from "react";
import { TSearchProps } from "~/components/SmartField/lib/types";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { useMappers } from "~/components/SmartField/hooks/useMappers";
import { Form, FormFieldProps, Search, SearchProps } from "semantic-ui-react";
import { useLabelFormatter } from "~/components/SmartField/hooks/useLabelFormatter";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { FormattedMessage } from "react-intl";
import { getSearchIcon } from "~/components/SmartField/lib/utils";

/**
 * Dropdown/Select component with search query support.
 * Value will be saved automatically after selection.
 */
export const SmartSearch: React.FC<TSearchProps> = (props) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        currentValue,
        loading,
        success,
        error,
        handleEdit,
        handleSave,
        getPopupContent,
        isRequired
    } = useSmartFieldLogic(props);

    const [searchValue, setSearchValue] = useState(currentValue)
    const [editUpdated, setEditUpdated] = useState(false)

    const { loadMapper } = useMappers(props)

    useEffect(() => {
        if (props.readOnly) {
            setSearchValue(loadMapper(props.value))
        }
    }, [props.readOnly, loadMapper, props.value])

    const handleSearchEdit = (ev: any, el: SearchProps): void => {
        props.updateSearch(el.value || "")
        setSearchValue(el.value)
    }

    const handleResultSelect = (ev: any, el: FormFieldProps): void => {
        setSearchValue(el.result.key)
        handleEdit(el.result)
        setEditUpdated(true)
    }

    useEffect(() => {
        if (editUpdated) {
            setEditUpdated(false)
            handleSave()
        }
    }, [editUpdated, handleSave])

    const { formatLabel } = useLabelFormatter(props)

    const hasErrorClass = (!!error ? "error" : "")

    return (
        <SmartPopup
            position="bottom center"
            offset="0, -15px"
            description={getPopupContent()}
        >
            <div className="field super-field">
                {formatLabel()}
                <Search
                    input={{
                        icon: getSearchIcon(error, success, props),
                        fluid: true,
                        style: props.style
                    }}
                    as={Form.Field}
                    open={props.readOnly ? false : undefined}
                    fluid={props.fluidResults}
                    results={props.results}
                    required={isRequired}
                    value={searchValue}
                    onSearchChange={handleSearchEdit}
                    onResultSelect={handleResultSelect}
                    onChange={handleSave}
                    loading={loading}
                    width={props.width}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    noResultsMessage={<FormattedMessage id="app.noResultsMessage"/>}
                    className={"field super-field " + hasErrorClass}
                />
            </div>
        </SmartPopup>
    )
}