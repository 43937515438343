import { CardKey } from '~/pages/Customers/Details/constants/cardKey';
import { Customer } from '~/store/customer/types';

export type ChangePayerCardProps = {
  editMode: boolean;
  id: CardKey;
  customerId: string;
};

export const FORM_INIT_STATE = {
  payer: null,
  validFrom: null,
  creationComment: '',
};

export type FormProps = {
  payer: null | Customer;
  validFrom: null | Date;
  creationComment: string;
};
