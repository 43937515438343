import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';
import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { parseNumber } from '~/utils/parseNumber';
import { CardDetailsBonusAndSpecialLimitsProps } from '~/pages/Orders/lib/Add/BonusAndSpecialLimits/constants';
import { FormattedMessage } from 'react-intl';

const CardDetailsBonusAndSpecialLimits = ({ limit, userName }: CardDetailsBonusAndSpecialLimitsProps) => (
  <DetailCard
    title="app.payer.bonusAndSpecialLimits"
    id="bonusAndSpecialLimits"
    key="bonusAndSpecialLimits"
    minHeight={40}
    width={4}
    smallWidth={5.33}
  >
    <SmartLabel
      labelWidth="10"
      label={
        <FormattedMessage
          defaultMessage="app.bonus-and-special-limit.label"
          id="app.bonus-and-special-limit.label"
          values={{ userName }}
        />
      }
      value={`${parseNumber(limit)} %`}
    />
  </DetailCard>
);

export default CardDetailsBonusAndSpecialLimits;
