export interface IStorage {
    readonly length: number
    clear(): void
    getItem(key: string): string | null
    key(index: number): string | null
    removeItem(key: string): void
    setItem(key: string, data: string): void
    [key: string]: any
    [index: number]: string
}

export class LocalStorageService {
    local: IStorage = localStorage

    get(key: string): any {
        const data = this.local.getItem(key)
        return data ? JSON.parse(data) : null
    }

    set(key: string, data: any): void {
        this.local.setItem(key, JSON.stringify(data))
    }

    clear(key: string): void {
        this.local.removeItem(key)
    }
}

export const localStorageService = new LocalStorageService()