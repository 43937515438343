import { NavLink } from "react-router-dom";
import { Breadcrumb, Divider, Header, Icon } from "semantic-ui-react";

import React, { useMemo } from "react";

import "./PageHeader.scss";
import { TProps } from "~/components/PageHeader/lib/types";
import { getButtons } from "~/components/PageHeader/lib/utils";

const PageHeader: React.FC<TProps> = ({
  icon,
  title,
  breadcrumb,
  buttons,
  breadCrumbTab,
  breadCrumbStyles,
  loading,
  refreshAction,
}) => {
  const breadcrumbRendered = useMemo(
    () =>
      breadcrumb
        .filter(
          (item) =>
            (item.visible === true || item.visible === undefined) && item
        )
        .map((navItem, index) => {
          const props = navItem.link ? { as: NavLink, to: navItem.link } : {};
          const active = index === breadcrumb.length - 1;

          return [
            <Breadcrumb.Section
              {...props}
              key={index * 2}
              style={{ paddingTop: 10 }}
              active={breadCrumbTab == null ? active : breadCrumbTab === index}
              onClick={() =>
                navItem.clickAction ? navItem.clickAction() : null
              }
            >
              {navItem.text}
            </Breadcrumb.Section>,
            !active ? (
              <Breadcrumb.Divider key={index * 2 + 1}>|</Breadcrumb.Divider>
            ) : null,
          ];
        }),
    [breadcrumb, breadCrumbTab]
  );

  const buttonsRendered = useMemo(
    () => [...getButtons(buttons, loading, refreshAction)],
    [buttons, loading, refreshAction]
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h2">
          {typeof icon === "string" ? <Icon name={icon} /> : icon}
          <Header.Content>
            {title}
            <div style={{ display: "flex" }}>
              <Header.Subheader>
                <Breadcrumb className={breadCrumbStyles || ""}>
                  {breadcrumbRendered}
                </Breadcrumb>
              </Header.Subheader>
            </div>
          </Header.Content>
        </Header>
        <div
          className={
            Object.values(
              buttonsRendered && buttonsRendered.length && buttonsRendered
            )[0].length > 4
              ? "RenderedMoreThanThreeButtons"
              : "RenderedButtons"
          }
        >
          {buttonsRendered}
        </div>
      </div>
      <Divider />
    </>
  );
};

export default PageHeader;
