import React, { Fragment, useCallback, useState } from 'react';
import { SyntheticEvent } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Container, Form, Header, Modal } from 'semantic-ui-react';
import { DictionaryItem } from '~/store/dictionaries/types';
import { cancelOrder } from '~/store/orders/actions';

import './CancelOrder.scss';

type TReduxActions = {
  cancelOrder: typeof cancelOrder;
};

type TCancelOrderProps = {
  closeModal: () => void;
  openCancelOrderModal: boolean;
};

const CancelOrder = ({ cancelOrder, closeModal, openCancelOrderModal }: TCancelOrderProps & TReduxActions) => {
  const categoryType: DictionaryItem[] = [
    { key: 'RA', value: 'RA', text: 'RA' },
    { key: 'RI', value: 'RI', text: 'RI' },
    { key: 'ERCS', value: 'ERCS', text: 'ERCS' },
  ];

  const orderType: DictionaryItem[] = [
    { key: 'STOCK', value: 'STOCK', text: 'STOCK' },
    { key: 'URGENT', value: 'URGENT', text: 'URGENT' },
  ];

  const [cancel, setCancel] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState<
    string | number | boolean | (string | number | boolean)[] | undefined
  >('');
  const [orderValue, setOrderValue] = useState<string | number | boolean | (string | number | boolean)[] | undefined>(
    ''
  );
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const handleCategoryChange = useCallback(
    (e: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => {
      setCategoryValue(data);
    },
    []
  );

  const handleOrderChange = useCallback(
    (e: SyntheticEvent, data: string | number | boolean | (string | number | boolean)[] | undefined) => {
      setOrderValue(data);
    },
    []
  );

  const clearCategoryOption = useCallback((e: SyntheticEvent) => {
    setCategoryValue('');
  }, []);

  const clearOrderOption = useCallback((e: SyntheticEvent) => {
    setOrderValue('');
  }, []);

  const handleSubmit = useCallback(() => {
    const cancelData = {
      dateTo,
      categoryValue,
      orderValue,
    };
    cancelOrder(cancelData);
    setCancel(true);
  }, [cancelOrder, dateTo, categoryValue, orderValue]);

  useEffect(() => {
    if (cancel) {
      setOpenConfirmModal(true);
    }
  }, [cancel]);

  if (confirmCancel) {
    return <Redirect to="/orders" />;
  }

  return (
    <Fragment>
      <Modal className="CancelModal" open={openCancelOrderModal} size="large" onClose={closeModal}>
        <Modal.Header>
          <div className="ModalHeader">
            <Header icon="cancel" content="Anuluj zamówienia" />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Container className="Container">
            <Form>
              <div className="ServiceSection">
                <Form.Group widths="equal">
                  <div className="ServiceHeader">Serwisy</div>
                  <div className="CategoryTypeSelect">
                    <Form.Dropdown
                      className="Dropdown"
                      onChange={(e, data) => handleCategoryChange(e, data.value)}
                      options={categoryType}
                      placeholder="Wybierz kategorię serwisu"
                      search
                      selection
                      value={categoryValue}
                    />
                    <Button onClick={(e, data) => clearCategoryOption(data.value)}>Wyczyść</Button>
                  </div>
                </Form.Group>
              </div>
              <div className="OrderTypeSection">
                <Form.Group widths="equal">
                  <div className="OrderTypeHeader">Zamówienia</div>
                  <div className="OrderTypeSelect">
                    <Form.Dropdown
                      className="Dropdown"
                      onChange={(e, data) => handleOrderChange(e, data.value)}
                      options={orderType}
                      placeholder="Wybierz kategorię zamówienia"
                      search
                      selection
                      value={orderValue}
                    />
                    <Button onClick={(e, data) => clearOrderOption(data.value)}>Wyczyść</Button>
                  </div>
                </Form.Group>
              </div>
              <div className="DatePickerSection">
                <Form.Group widths="equal">
                  <div className="DateHeader">Starsze niż</div>
                  <Form.Input
                    autoComplete="off"
                    className="DatePicker"
                    error={
                      !dateTo &&
                      cancel && {
                        content: 'Pole Starsze niż jest wymagane',
                      }
                    }
                    id="dateTo"
                    locale="pl-PL"
                    max={dateTo || new Date().toISOString().split('T')[0]}
                    onChange={(e) => setDateTo(e.target.value)}
                    type="date"
                    value={dateTo}
                  />
                </Form.Group>
              </div>
            </Form>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Anuluj zamówienia"
            onClick={() => {
              setOpenConfirmModal(true);
              setCancel(true);
            }}
          />
          <Button
            content="Anuluj"
            onClick={() => {
              closeModal();
            }}
          />
        </Modal.Actions>
      </Modal>
      {openConfirmModal && dateTo && (
        <Modal
          className="Modal"
          closeOnDimmerClick={false}
          onClose={() => {
            setOpenConfirmModal(false);
            setConfirmCancel(true);
            closeModal();
          }}
          open={openConfirmModal}
          size="small"
        >
          <Modal.Content className="ModalContent">Czy chcesz na pewno anulować zamówienia?</Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="Kontynuuj"
              onClick={() => {
                handleSubmit();
                setOpenConfirmModal(false);
                setCancel(false);
                closeModal();
              }}
            />
            <Button
              content="Anuluj"
              onClick={() => {
                setOpenConfirmModal(false);
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Fragment>
  );
};

const mapDispatchToProps: TReduxActions = {
  cancelOrder,
};

export default connect(null, mapDispatchToProps)(CancelOrder);
