import DetailCard from '~/components/DetailCard/DetailCard';
import { Form, Input } from 'semantic-ui-react';
import { SmartPopup } from '~/components/SmartField/SmartPopup';
import React from 'react';
import { CardDetailsHandlingProps } from '~/pages/Orders/lib/Add/Handling/constants';
import { toastWarn } from '~/utils/toast';

const CardDetailsHandling = ({ shrink, order, summary, updateOrder, serviceNotesError }: CardDetailsHandlingProps) => {
  const setWithValidateDeliveryDate = (date: string): void => {
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const inputDate = new Date(date).setHours(0, 0, 0, 0);

    if (inputDate.valueOf() >= todaysDate.valueOf()) {
      updateOrder('deliveryDate', date);
    } else {
      toastWarn('Nieprawidłowa data', 'Data realizacji nie może być przeszła.');
    }
  };

  return (
    <DetailCard
      title="app.staff"
      id="handling"
      key="handling"
      minHeight={40}
      width={shrink ? 2.5 : 3}
      smallWidth={5.33}
    >
      <Form.Group widths="equal">
        <Form.Field disabled={!order.type} style={{ display: 'none' }}>
          <label>Priorytet</label>
          <Input
            fluid
            type="number"
            min="0"
            readOnly={summary}
            value={order.priority}
            onChange={(e, d) => updateOrder('priority', d.value)}
          />
        </Form.Field>
        <Form.Field disabled={!order.type}>
          <label>Planowana data realizacji</label>
          <Input
            fluid
            type="date"
            readOnly={summary}
            value={order.deliveryDate}
            onChange={(e, d) => setWithValidateDeliveryDate(d.value)}
          />
        </Form.Field>
      </Form.Group>
      <SmartPopup description="Uwagi dotyczące obsługi zamówienia. Maksymalnie 500 znaków">
        <Form.TextArea
          disabled={!order.type}
          label="Notatki"
          rows={2}
          error={serviceNotesError}
          value={order.serviceNotes}
          readOnly={summary}
          onChange={(e, d) => updateOrder('serviceNotes', d.value)}
        />
      </SmartPopup>
    </DetailCard>
  );
};
export default CardDetailsHandling;
