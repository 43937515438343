import { useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useSidebarRolesRights = () => {
  const appContext = useContext(AppContext);

  const isOrderViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW),
    [appContext]
  );

  const isClaimsViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW),
    [appContext]
  );

  const isCustomerViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_CUSTOMER_VIEW
      ),
    [appContext]
  );

  const isWarehouseViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_VIEW
      ),
    [appContext]
  );

  const isAnomalyViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ANOMALY_VIEW
      ),
    [appContext]
  );

  const isBackorderViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_BACKORDER_VIEW
      ),
    [appContext]
  );

  const isAccountingViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_VIEW
      ),
    [appContext]
  );

  const isPromotionViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_PROMOTION_VIEW
      ),
    [appContext]
  );

  const isProductViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_PRODUCT_VIEW
      ),
    [appContext]
  );

  const isReportsViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_REPORTS_VIEW
      ),
    [appContext]
  );

  const isAdministrationViewRole: boolean = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_ADMINISTRATION_VIEW
      ),
    [appContext]
  );

  const menuVisibility: boolean | undefined = useMemo(
    () => appContext?.menuVisibility,
    [appContext]
  );

  return {
    isAccountingViewRole,
    isAdministrationViewRole,
    isAnomalyViewRole,
    isBackorderViewRole,
    isClaimsViewRole,
    isCustomerViewRole,
    isOrderViewRole,
    isProductViewRole,
    isPromotionViewRole,
    isReportsViewRole,
    isWarehouseViewRole,
    menuVisibility,
  };
};
