import XLSX from "xlsx";

const _ = require("lodash");

interface ExportFileObject {
  [key: string]: string | number | boolean | object | any;
}

export function exportFile(
  fileName: string,
  reduceDataToExport: Array<{
    invoiceLines: [];
    correctedInvoiceLines: [{}];
    lines: [{}];
    claimLines: [{}];
    method: string;
    accountingCustomer: ExportFileObject;
    blocked: boolean;
    categoryCompany: boolean;
    customer: ExportFileObject;
    deliveryCustomer: ExportFileObject;
    orderCustomer: ExportFileObject;
    paymentCustomer: ExportFileObject;
    paymentCreditBlocked: boolean;
    paymentCreditLimit: boolean;
    index: number;
    name: string;
    label: string;
    [key: string]: string | number | object | boolean;
  }>,
  columns: any,
  clickedDocumentButton?: boolean,
  clickedDocumentListButton?: boolean
): void {
  let emptyObject: ExportFileObject = {};
  let emptyAcountingCustomerObject: ExportFileObject = {};
  let emptyPaymentCustomerObject: ExportFileObject = {};
  let emptyWholesaleOrderObject: ExportFileObject = {};
  let emptyWholesaleOrderDeliveryObject: ExportFileObject = {};
  let emptyOrderCustomerObject: ExportFileObject = {};
  let emptyInvoiceLinesNumbersObject: ExportFileObject = {};
  let emptyAccountingObjectWithEmptyInvoiceLine: ExportFileObject = {};
  let emptyInvoiceLinesObject: ExportFileObject = {};
  let dataToExport: any[] = [];
  let dataInSheet = undefined || {};
  let resultOfExporting;
  let savedOrderData;
  let savedAccountingData;

  resultOfExporting = reduceDataToExport.flatMap((invoice: any, i: number) => {
    if (
      reduceDataToExport[i].invoiceLines &&
      reduceDataToExport[i].invoiceLines.length &&
      !reduceDataToExport[i]?.correctedInvoiceLines?.length &&
      clickedDocumentListButton
    ) {
      savedAccountingData = reduceDataToExport[i].invoiceLines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.invoiceLines) {
            copy.invoiceLines = copy.invoiceLines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[columns[index].label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "orderNumber",
                "serviceBoxOrderNo",
                "unitPriceNetAfterDiscount",
                "taxRate",
                "referenceId",
                "quantity",
              ].includes(columns[index].name) &&
              copy.invoiceLines
            ) {
              emptyObject[columns[index].label] =
                copy.invoiceLines[columns[index].name];
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["accountingCustomer.id"].includes(columns[index].name) &&
              copy.accountingCustomer
            ) {
              [
                { name: "id", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.accountingCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.accountingCustomer[columnToMap.name];
                  } else {
                    !!copy.accountingCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.accountingCustomer[
                            columnToMap.name.split(".")[0]
                          ][columnToMap.name.split(".")[1]])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia"]) {
              emptyObject["Numer zamówienia"] =
                emptyOrderCustomerObject["Numer zamówienia"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia Service Box"]) {
              emptyObject["Numer zamówienia Service Box"] =
                emptyOrderCustomerObject["Numer zamówienia Service Box"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyInvoiceLinesObject["Dokument źródłowy"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      return savedAccountingData;
    } else if (
      reduceDataToExport[i].invoiceLines &&
      reduceDataToExport[i].invoiceLines.length &&
      !reduceDataToExport[i]?.correctedInvoiceLines?.length &&
      clickedDocumentButton
    ) {
      savedAccountingData = reduceDataToExport[i].invoiceLines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.invoiceLines) {
            copy.invoiceLines = copy.invoiceLines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[columns[index].label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "unitPriceNetAfterDiscount",
                "taxRate",
                "referenceId",
                "quantity",
              ].includes(columns[index].name) &&
              copy.invoiceLines
            ) {
              emptyObject[columns[index].label] =
                copy.invoiceLines[columns[index].name];
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["accountingCustomer.id"].includes(columns[index].name) &&
              copy.accountingCustomer
            ) {
              [
                { name: "id", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.accountingCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.accountingCustomer[columnToMap.name];
                  } else {
                    !!copy.accountingCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.accountingCustomer[
                            columnToMap.name.split(".")[0]
                          ][columnToMap.name.split(".")[1]])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyInvoiceLinesObject["Dokument źródłowy"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            delete emptyObject["Numer zamówienia"];
            delete emptyObject["Numer zamówienia Service Box"];
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      const unique: Array<{}> = [
        ...new Map(
          savedAccountingData.map((item) => [item["Numer"], item])
        ).values(),
      ];
      return unique;
    } else if (
      reduceDataToExport[i].invoiceLines &&
      reduceDataToExport[i].invoiceLines.length &&
      reduceDataToExport[i]?.correctedInvoiceLines?.length &&
      clickedDocumentListButton
    ) {
      savedAccountingData = reduceDataToExport[i].correctedInvoiceLines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.correctedInvoiceLines) {
            copy.correctedInvoiceLines = copy.correctedInvoiceLines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[columns[index].label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "orderNumber",
                "serviceBoxOrderNo",
                "unitPriceNetAfterDiscount",
                "taxRate",
                "referenceId",
                "quantity",
              ].includes(columns[index].name) &&
              copy.correctedInvoiceLines
            ) {
              emptyObject[columns[index].label] =
                copy.correctedInvoiceLines[columns[index].name];
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["accountingCustomer.id"].includes(columns[index].name) &&
              copy.accountingCustomer
            ) {
              [
                { name: "id", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.accountingCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.accountingCustomer[columnToMap.name];
                  } else {
                    !!copy.accountingCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.accountingCustomer[
                            columnToMap.name.split(".")[0]
                          ][columnToMap.name.split(".")[1]])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia"]) {
              emptyObject["Numer zamówienia"] =
                emptyOrderCustomerObject["Numer zamówienia"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia Service Box"]) {
              emptyObject["Numer zamówienia Service Box"] =
                emptyOrderCustomerObject["Numer zamówienia Service Box"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyInvoiceLinesObject["Dokument źródłowy"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      return savedAccountingData;
    } else if (
      reduceDataToExport[i].invoiceLines &&
      reduceDataToExport[i].invoiceLines.length &&
      reduceDataToExport[i]?.correctedInvoiceLines?.length &&
      clickedDocumentButton
    ) {
      savedAccountingData = reduceDataToExport[i].correctedInvoiceLines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.correctedInvoiceLines) {
            copy.correctedInvoiceLines = copy.correctedInvoiceLines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[columns[index].label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "orderNumber",
                "serviceBoxOrderNo",
                "unitPriceNetAfterDiscount",
                "taxRate",
                "referenceId",
                "quantity",
              ].includes(columns[index].name) &&
              copy.correctedInvoiceLines
            ) {
              emptyObject[columns[index].label] =
                copy.correctedInvoiceLines[columns[index].name];
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["accountingCustomer.id"].includes(columns[index].name) &&
              copy.accountingCustomer
            ) {
              [
                { name: "id", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.accountingCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.accountingCustomer[columnToMap.name];
                  } else {
                    !!copy.accountingCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.accountingCustomer[
                            columnToMap.name.split(".")[0]
                          ][columnToMap.name.split(".")[1]])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyInvoiceLinesObject["Dokument źródłowy"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            delete emptyObject["Numer zamówienia"];
            delete emptyObject["Numer zamówienia Service Box"];
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      const unique: Array<{}> = [
        ...new Map(
          savedAccountingData.map((item) => [item["Numer"], item])
        ).values(),
      ];
      return unique;
    } else if (
      reduceDataToExport[i].lines &&
      reduceDataToExport[i].lines.length &&
      !reduceDataToExport[i].method &&
      clickedDocumentListButton
    ) {
      savedOrderData = reduceDataToExport[i].lines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.lines) {
            copy.lines = copy.lines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[column.label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "orderNumber",
                "serviceBoxOrderNo",
                "netPrice",
                "taxPercent",
                "referenceID",
                "quantity",
              ].includes(columns[index].name) &&
              copy.lines
            ) {
              emptyObject[columns[index].label] =
                copy.lines[columns[index].name];
            } else if (
              ["netPriceListPrice"].includes(columns[index].name) &&
              copy.lines &&
              copy.lines.netPrice &&
              copy.lines.quantity
            ) {
              const result = _.cloneDeep(
                copy.lines.netPrice * copy.lines.quantity
              );
              emptyObject["Wartość netto na linii"] = Number(result.toFixed(2));
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["customer.rrid"].includes(columns[index].name) &&
              copy.customer
            ) {
              [
                { name: "rrid", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.customer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.customer[columnToMap.name];
                  } else {
                    !!copy.customer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.customer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (
              ["deliveryCustomer.rrid"].includes(columns[index].name) &&
              copy.deliveryCustomer
            ) {
              [
                { name: "rrid", label: "Odbiorca" },
                {
                  name: "street",
                  label: "Adres odbiorcy",
                },
                { name: "companyName", label: "Nazwa odbiorcy" },
              ].forEach((columnToMap) => {
                if (!!copy.deliveryCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.deliveryCustomer[columnToMap.name];
                  } else {
                    !!copy.deliveryCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.deliveryCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia"]) {
              emptyObject["Numer zamówienia"] =
                emptyOrderCustomerObject["Numer zamówienia"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia Service Box"]) {
              emptyObject["Numer zamówienia Service Box"] =
                emptyOrderCustomerObject["Numer zamówienia Service Box"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      return savedOrderData;
    } else if (
      reduceDataToExport[i].lines &&
      reduceDataToExport[i].lines.length &&
      !reduceDataToExport[i].method &&
      clickedDocumentButton
    ) {
      savedOrderData = reduceDataToExport[i].lines.map(
        (element: object, index: number) => {
          const copy = _.cloneDeep(reduceDataToExport[i]);
          if (copy && copy.lines) {
            copy.lines = copy.lines[index];
          }
          columns.forEach((column, index) => {
            if (!!copy[columns[index].name.split(".")[0]]) {
              if (columns[index].name.split(".").length < 2) {
                emptyObject[column.label] = copy[columns[index].name];
              } else {
                !!copy[columns[index].name.split(".")[1]]
                  ? (emptyObject[columns[index].label] =
                      copy[columns[index].name.split(".")[0]][
                        columns[index].name.split(".")[1]
                      ])
                  : (emptyObject[columns[index].label] = "");
              }
            } else if (
              !copy[columns[index].name.split(".")[0]] &&
              [
                "Kwota VAT",
                "Kwota netto",
                "Kwota brutto",
                "Priorytet",
                "Realizacja",
              ].includes(columns[index].label)
            ) {
              emptyObject[column.label] = 0;
            } else if (
              [
                "orderNumber",
                "serviceBoxOrderNo",
                "netPrice",
                "taxPercent",
                "referenceID",
                "quantity",
              ].includes(columns[index].name) &&
              copy.lines
            ) {
              emptyObject[columns[index].label] =
                copy.lines[columns[index].name];
            } else {
              emptyObject[columns[index].label] = "";
            }
            if (
              ["customer.rrid"].includes(columns[index].name) &&
              copy.customer
            ) {
              [
                { name: "rrid", label: "Płatnik" },
                {
                  name: "taxCode",
                  label: "NIP Płatnika",
                },
              ].forEach((columnToMap) => {
                if (!!copy.customer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyAcountingCustomerObject[columnToMap.label] =
                      copy.customer[columnToMap.name];
                  } else {
                    !!copy.customer[columnToMap.name.split(".")[1]]
                      ? (emptyAcountingCustomerObject[columnToMap.label] =
                          copy.customer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyAcountingCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyAcountingCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyAcountingCustomerObject;
            }
            if (
              ["orderCustomer.id"].includes(columns[index].name) &&
              copy.orderCustomer
            ) {
              [
                { name: "id", label: "Odbiorca" },
                { name: "deliveryType", label: "Dostawa" },
              ].forEach((columnToMap) => {
                if (!!copy.orderCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.orderCustomer[columnToMap.name];
                  } else {
                    !!copy.orderCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.orderCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (
              ["deliveryCustomer.rrid"].includes(columns[index].name) &&
              copy.deliveryCustomer
            ) {
              [
                { name: "rrid", label: "Odbiorca" },
                {
                  name: "street",
                  label: "Adres odbiorcy",
                },
                { name: "companyName", label: "Nazwa odbiorcy" },
              ].forEach((columnToMap) => {
                if (!!copy.deliveryCustomer[columnToMap.name.split(".")[0]]) {
                  if (columnToMap.name.split(".").length < 2) {
                    emptyOrderCustomerObject[columnToMap.label] =
                      copy.deliveryCustomer[columnToMap.name];
                  } else {
                    !!copy.deliveryCustomer[columnToMap.name.split(".")[1]]
                      ? (emptyOrderCustomerObject[columnToMap.label] =
                          copy.deliveryCustomer[columnToMap.name.split(".")[0]][
                            columnToMap.name.split(".")[1]
                          ])
                      : (emptyOrderCustomerObject[columnToMap.label] = "");
                  }
                } else {
                  emptyOrderCustomerObject[columnToMap.label] = "";
                }
              });
              return emptyOrderCustomerObject;
            }
            if (emptyAcountingCustomerObject["Płatnik"]) {
              emptyObject["Płatnik"] = emptyAcountingCustomerObject["Płatnik"];
            }
            if (emptyAcountingCustomerObject["NIP Płatnika"]) {
              emptyObject["NIP Płatnika"] =
                emptyAcountingCustomerObject["NIP Płatnika"];
            }
            if (emptyOrderCustomerObject["Odbiorca"]) {
              emptyObject["Odbiorca"] = emptyOrderCustomerObject["Odbiorca"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia"]) {
              emptyObject["Numer zamówienia"] =
                emptyOrderCustomerObject["Numer zamówienia"];
            }
            if (emptyOrderCustomerObject["Numer zamówienia Service Box"]) {
              emptyObject["Numer zamówienia Service Box"] =
                emptyOrderCustomerObject["Numer zamówienia Service Box"];
            }
            if (emptyOrderCustomerObject["Adres odbiorcy"]) {
              emptyObject[
                "Adres odbiorcy"
              ] = `${emptyOrderCustomerObject["Adres odbiorcy"]}/,${copy.deliveryCustomer.city} ${copy.deliveryCustomer.postcode}`;
              emptyObject["Nazwa odbiorcy"] =
                emptyOrderCustomerObject["Nazwa odbiorcy"];
            }
            if (
              emptyInvoiceLinesNumbersObject["Dokument źródłowy"] ||
              emptyInvoiceLinesObject["Dokument źródłowy"]
            ) {
              emptyObject["Dokument źródłowy"] =
                emptyOrderCustomerObject["Dokument źródłowy"];
            }
            if (emptyOrderCustomerObject["Dostawa"]) {
              emptyObject["Dostawa"] = Object.keys(
                emptyOrderCustomerObject
              ).includes("Dostawa")
                ? emptyOrderCustomerObject["Dostawa"]
                : emptyObject["Dostawa"];
            }
            if (copy.invoiceType !== "ORIGINAL_INVOICE") {
              delete emptyObject["Dokument źródłowy"];
            }
            emptyObject = _.cloneDeep(emptyObject);
          });
          return emptyObject;
        }
      );
      emptyObject = _.cloneDeep(emptyObject);
      const unique: Array<{}> = [
        ...new Map(
          savedOrderData.map((item) => [item["Nr LDC"], item])
        ).values(),
      ];
      return unique;
    } else {
      columns.forEach((column: any, index: number) => {
        if (!!invoice[columns[index].name.split(".")[0]]) {
          if (columns[index].name.split(".").length < 2) {
            emptyObject = _.cloneDeep(emptyObject);
            emptyObject[columns[index].label] = invoice[columns[index].name];
          } else {
            emptyObject[columns[index].label] = "";
          }
        } else if (
          !invoice[columns[index].name.split(".")[0]] &&
          [
            "Blokada",
            "Dostawa",
            "Kwota VAT",
            "Kwota netto",
            "Kwota brutto",
            "Liczba linii",
            "Limit dost.",
            "Priorytet",
            "Realizacja",
            "WMS Potwierdzone",
            "Długość",
            "Szerokość",
            "Wysokość",
            "Limit składowania",
            "Limit",
          ].includes(columns[index].label)
        ) {
          emptyObject[column.label] = 0;
        } else if (
          !invoice[columns[index].name.split(".")[0]] &&
          ["Typ klienta"].includes(columns[index].label)
        ) {
          emptyObject[column.label] = "Osoba";
        } else {
          emptyObject[column.label] = "";
        }
        if (
          ["paymentCustomer.taxCode"].includes(columns[index].name) &&
          invoice.paymentCustomer
        ) {
          emptyObject = _.cloneDeep(emptyObject);
          [{ name: "taxCode", label: "NIP Płatnika" }].forEach(
            (columnToMap) => {
              if (!!invoice.paymentCustomer[columnToMap.name.split(".")[0]]) {
                if (columnToMap.name.split(".").length < 2) {
                  emptyPaymentCustomerObject[columnToMap.label] =
                    invoice.paymentCustomer[columnToMap.name];
                } else {
                  !!invoice.paymentCustomer[columnToMap.name.split(".")[1]]
                    ? (emptyPaymentCustomerObject[columnToMap.label] =
                        invoice.paymentCustomer[columnToMap.name.split(".")[0]][
                          columnToMap.name.split(".")[1]
                        ])
                    : (emptyPaymentCustomerObject[columnToMap.label] = "");
                }
              } else {
                emptyPaymentCustomerObject[columnToMap.label] = "";
              }
            }
          );
          emptyObject = _.cloneDeep(emptyObject);
          return emptyPaymentCustomerObject;
        }
        if (
          ["customer.rrdi"].includes(columns[index].name) &&
          invoice.customer
        ) {
          [{ name: "rrdi", label: "Zamawiający" }].forEach((columnToMap) => {
            if (!!invoice.customer[columnToMap.name.split(".")[0]]) {
              if (columnToMap.name.split(".").length < 2) {
                emptyObject = _.cloneDeep(emptyObject);
                emptyWholesaleOrderObject[columnToMap.label] =
                  invoice.customer[columnToMap.name];
              } else {
                !!invoice.customer[columnToMap.name.split(".")[1]]
                  ? (emptyWholesaleOrderObject[columnToMap.label] =
                      invoice.customer[columnToMap.name.split(".")[0]][
                        columnToMap.name.split(".")[1]
                      ])
                  : (emptyWholesaleOrderObject[columnToMap.label] = "");
              }
            } else {
              emptyWholesaleOrderObject[columnToMap.label] = "";
            }
          });
          emptyObject = _.cloneDeep(emptyObject);
          return emptyWholesaleOrderObject;
        }
        if (
          ["deliveryCustomer.rrdi"].includes(columns[index].name) &&
          invoice.deliveryCustomer
        ) {
          [{ name: "rrdi", label: "Odbiorca" }].forEach((columnToMap) => {
            if (!!invoice.deliveryCustomer[columnToMap.name.split(".")[0]]) {
              if (columnToMap.name.split(".").length < 2) {
                emptyObject = _.cloneDeep(emptyObject);
                emptyWholesaleOrderDeliveryObject[columnToMap.label] =
                  invoice.deliveryCustomer[columnToMap.name];
              } else {
                !!invoice.deliveryCustomer[columnToMap.name.split(".")[1]]
                  ? (emptyWholesaleOrderDeliveryObject[columnToMap.label] =
                      invoice.deliveryCustomer[columnToMap.name.split(".")[0]][
                        columnToMap.name.split(".")[1]
                      ])
                  : (emptyWholesaleOrderDeliveryObject[columnToMap.label] = "");
              }
            } else {
              emptyWholesaleOrderDeliveryObject[columnToMap.label] = "";
            }
          });
          emptyObject = _.cloneDeep(emptyObject);
          return emptyWholesaleOrderDeliveryObject;
        }
        if (
          ["accountingCustomer.id"].includes(columns[index].name) &&
          invoice.accountingCustomer
        ) {
          [
            { name: "id", label: "Płatnik" },
            { name: "taxCode", label: "NIP Płatnika" },
          ].forEach((columnToMap) => {
            if (!!invoice.accountingCustomer[columnToMap.name.split(".")[0]]) {
              if (columnToMap.name.split(".").length < 2) {
                emptyAccountingObjectWithEmptyInvoiceLine[columnToMap.label] =
                  invoice.accountingCustomer[columnToMap.name];
              } else {
                !!invoice.accountingCustomer[columnToMap.name.split(".")[1]]
                  ? (emptyAccountingObjectWithEmptyInvoiceLine[
                      columnToMap.label
                    ] =
                      invoice.accountingCustomer[
                        columnToMap.name.split(".")[0]
                      ][columnToMap.name.split(".")[1]])
                  : (emptyAccountingObjectWithEmptyInvoiceLine[
                      columnToMap.label
                    ] = "");
              }
            } else {
              emptyAccountingObjectWithEmptyInvoiceLine[columnToMap.label] = "";
            }
          });
          emptyObject = _.cloneDeep(emptyObject);
          return emptyAccountingObjectWithEmptyInvoiceLine;
        }
        if (
          ["orderCustomer.id"].includes(columns[index].name) &&
          invoice.orderCustomer
        ) {
          [
            { name: "id", label: "Odbiorca" },
            { name: "deliveryType", label: "Dostawa" },
          ].forEach((columnToMap) => {
            if (!!invoice.orderCustomer[columnToMap.name.split(".")[0]]) {
              if (columnToMap.name.split(".").length < 2) {
                emptyAccountingObjectWithEmptyInvoiceLine[columnToMap.label] =
                  invoice.orderCustomer[columnToMap.name];
              } else {
                !!invoice.orderCustomer[columnToMap.name.split(".")[1]]
                  ? (emptyAccountingObjectWithEmptyInvoiceLine[
                      columnToMap.label
                    ] =
                      invoice.orderCustomer[columnToMap.name.split(".")[0]][
                        columnToMap.name.split(".")[1]
                      ])
                  : (emptyAccountingObjectWithEmptyInvoiceLine[
                      columnToMap.label
                    ] = "");
              }
            } else {
              emptyAccountingObjectWithEmptyInvoiceLine[columnToMap.label] = "";
            }
          });
          emptyObject = _.cloneDeep(emptyObject);
          return emptyAccountingObjectWithEmptyInvoiceLine;
        }
        if (emptyWholesaleOrderObject["Zamawiający"]) {
          emptyObject["Zamawiający"] = emptyWholesaleOrderObject["Zamawiający"];
        }
        if (emptyWholesaleOrderDeliveryObject["Odbiorca"]) {
          emptyObject["Odbiorca"] =
            emptyWholesaleOrderDeliveryObject["Odbiorca"];
        }
        if (emptyPaymentCustomerObject["NIP Płatnika"]) {
          emptyObject["NIP Płatnika"] =
            emptyPaymentCustomerObject["NIP Płatnika"];
        }
        if (emptyObject["Blokada"]) {
          invoice.blocked
            ? (emptyObject["Blokada"] = 1)
            : (emptyObject["Blokada"] = 0);
        }
        if (emptyObject["Typ klienta"]) {
          invoice.categoryCompany
            ? (emptyObject["Typ klienta"] = "Firma")
            : (emptyObject["Typ klienta"] = "Osoba");
        }
        if (emptyObject["Limit dost."]) {
          return invoice.paymentCreditBlocked || !invoice.paymentCreditLimit
            ? (emptyObject["Limit dost."] = 0)
            : emptyObject["Limit dost."];
        }
        if ((invoice.orderType || invoice.blocked) && !invoice.taxType) {
          invoice.orderType
            ? (emptyObject["Dostawa"] = invoice.orderType)
            : (emptyObject["Dostawa"] = 0);
        }
        if (emptyAccountingObjectWithEmptyInvoiceLine["Płatnik"]) {
          emptyObject["Płatnik"] =
            emptyAccountingObjectWithEmptyInvoiceLine["Płatnik"];
        }
        if (emptyAccountingObjectWithEmptyInvoiceLine["NIP Płatnika"]) {
          emptyObject["NIP Płatnika"] =
            emptyAccountingObjectWithEmptyInvoiceLine["NIP Płatnika"];
        }
        if (emptyAccountingObjectWithEmptyInvoiceLine["Odbiorca"]) {
          emptyObject["Odbiorca"] =
            emptyAccountingObjectWithEmptyInvoiceLine["Odbiorca"];
        }
        if (emptyAccountingObjectWithEmptyInvoiceLine["Dokument źródłowy"]) {
          emptyObject["Dokument źródłowy"] =
            emptyAccountingObjectWithEmptyInvoiceLine["Dokument źródłowy"];
        }
        if (emptyAccountingObjectWithEmptyInvoiceLine["Dostawa"]) {
          emptyObject["Dostawa"] = Object.keys(
            emptyAccountingObjectWithEmptyInvoiceLine
          ).includes("Dostawa")
            ? emptyAccountingObjectWithEmptyInvoiceLine["Dostawa"]
            : emptyObject["Dostawa"];
        }
        delete emptyObject["Numer zamówienia"];
        delete emptyObject["Numer zamówienia Service Box"];
        delete emptyObject["Wartość reklamacji"];
        delete emptyObject["Wartość zaakceptowana"];
        emptyObject = _.cloneDeep(emptyObject);
      });
      emptyObject = _.cloneDeep(emptyObject);
      return emptyObject;
    }
  });
  if (resultOfExporting && dataToExport && !dataToExport.length) {
    dataInSheet = XLSX.utils.json_to_sheet(resultOfExporting);
  } else if (dataToExport && dataToExport.length) {
    dataInSheet = XLSX.utils.json_to_sheet(dataToExport);
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, dataInSheet, fileName);
  XLSX.writeFile(wb, fileName + ".xlsx");
}
