import React from "react";
import { ToolbarProps } from "./constans";

import "./TextEditor.scss";
import { useTextEditor } from "./useTextEditor";

const Toolbar: React.FC<ToolbarProps> = ({ editor, addText }) => {
  const { BlockButton, MarkButton } = useTextEditor({ editor, addText });

  return (
    <div className="toolbar">
      <MarkButton format="bold" icon="bold" />
      <MarkButton format="italic" icon="italic" />
      <MarkButton format="underline" icon="underline" />

      <BlockButton format="heading-one" icon="angle double up" />
      <BlockButton format="heading-two" icon="angle up" />
      <BlockButton format="numbered-list" icon="list ol" />
      <BlockButton format="bulleted-list" icon="list ul" />
      <BlockButton format="left" icon="align left" />
      <BlockButton format="center" icon="align center" />
      <BlockButton format="right" icon="align right" />
      <BlockButton format="justify" icon="align justify" />
    </div>
  );
};

export default Toolbar;
