import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import dictionariesStatic from "~/store/dictionaries/static";

export const useInventoryTransferColumns = () => {
  const { getDateCell, getDictionaryCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "number",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "issueDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "dispatchDate",
        label: "Data wysłania",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "deliveryDate",
        label: "Data dostarczenia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "supplierName",
        label: "Dostawca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "recipientName",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "status",
        dictionary: dictionariesStatic.STATUS_TYPE,
        label: "Status",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
        noKey: true,
      },
    ],
    [getDateCell, getDictionaryCell, getTextCell]
  );

  return { columnsConfig };
};
