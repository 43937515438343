import React, { useCallback, useState } from "react";
import { Button, Divider, Grid, Icon } from "semantic-ui-react";
import { useComments } from "../Cards/Comments/hooks/useComments";

type Props = {
  sendMessage: (message: string) => void;
  handleRefresh: () => void;
};

export const CommentsModalInput: React.FC<Props> = ({
  sendMessage,
  handleRefresh,
}) => {
  const { canSendMessages } = useComments();

  const [message, setMessage] = useState<string>("");

  const handleSend = useCallback(() => {
    if (!message.length) return;
    sendMessage(message);
    setMessage("");
  }, [sendMessage, message, setMessage]);

  const onEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && e.shiftKey === false) {
        handleSend();
        e.preventDefault();
      }

      if (
        e.key === "Enter" &&
        e.shiftKey === true &&
        message &&
        message.endsWith("\n")
      ) {
        e.preventDefault();
      }
    },
    [message, handleSend]
  );

  return (
    <>
      <Divider></Divider>
      <Grid>
        <Grid.Row className="right send-container">
          <div className="textarea-wrapper">
            {canSendMessages() ? (
              <div>
                <strong style={{ fontSize: 12 }}>{message.length}</strong>
                <span style={{ fontSize: 10 }}>{" / 512"}</span>
              </div>
            ) : null}
            {canSendMessages() ? (
              <textarea
                id="new-message"
                disabled={!canSendMessages()}
                placeholder="Wpisz komentarz"
                className="new-comment-message"
                value={message}
                onKeyDown={onEnterPress}
                onChange={(e) => setMessage(e.target.value)}
                maxLength={512}
                rows={5}
                style={{ height: "unset" }}
              ></textarea>
            ) : null}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <Button secondary onClick={handleRefresh}>
              <Icon name="refresh" style={{ margin: 0 }} />
            </Button>
            {canSendMessages() ? (
              <Button primary className="send-button" onClick={handleSend}>
                <Icon name="send" style={{ margin: 0 }} />
              </Button>
            ) : null}
          </div>
        </Grid.Row>
      </Grid>
    </>
  );
};
