
import { Replenishment } from "~/pages/Replenishments/Replenishments"

export enum ReplenishmentActionTypes {
    FETCH_REPLENISHMENT = '@@replenishment/FETCH_REPLENISHMENT',
    FETCH_REPLENISHMENT_SUCCESS = '@@replenishment/FETCH_REPLENISHMENT_SUCCESS',
}

export interface ReplenishmentState {
    readonly loading: boolean
    readonly replenishments: Replenishment[]
}
