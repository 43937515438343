import { useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useDashboardRoles = () => {
  const context = useContext(AppContext);

  const userHaveOrderViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW),
    [context]
  );

  const userHaveBackorderViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_BACKORDER_VIEW),
    [context]
  );

  const userHaveAnomalyViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ANOMALY_VIEW),
    [context]
  );

  const userHaveClaimsViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW),
    [context]
  );

  return {
    userHaveOrderViewRole,
    userHaveBackorderViewRole,
    userHaveAnomalyViewRole,
    userHaveClaimsViewRole,
  };
};
