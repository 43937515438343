import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import { fetchSparePartsSearch } from "~/store/spare-parts/actions";
import { fetchOrdersSearch } from "~/store/orders/actions";
import { fetchCustomersSearch } from "~/store/customer/actions";
import { fetchClaimsSearch } from "~/store/claims/actions";
import { fetchBackordersSearch } from "~/store/backorder/actions";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import React, { useEffect, useState, SyntheticEvent } from "react";
import { useCallback } from "react";
import {
  TReduxActions,
  TReduxState,
  TTopBarProps,
} from "~/components/TopSearch/lib/types";
import { getMapSearching } from "~/components/TopSearch/lib/utils";

//TODO [ refactor -> custom hook, read about redux actions in custom hooks]
export const TopSearch: React.FC<TTopBarProps> = ({
  searchingGroup,
  history,
  spareParts,
  orders,
  customers,
  claims,
  backorders,
  fetchSparePartsSearch,
  fetchOrdersSearch,
  fetchCustomersSearch,
  fetchClaimsSearch,
  fetchBackordersSearch,
}) => {
  const [searchingValue, setSearchingValue] = useState<string>();

  const mapSearching = getMapSearching(
    searchingGroup,
    orders,
    spareParts,
    customers,
    claims,
    backorders
  );

  const changeSearchingValue = (event: SyntheticEvent, data: any): void => {
    setSearchingValue(data.value);
    let { value } = data;
    let redirectRoute = "";
    switch (searchingGroup) {
      case "orders":
        redirectRoute = "/order";
        break;
      case "products":
        redirectRoute = "/product";
        break;
      case "customers":
        redirectRoute = "/customer";
        break;
      case "claims":
        value = value.replace(/\//g, "-");
        redirectRoute = "/claim";
        break;
      case "backorders":
        redirectRoute = "/backorder";
        break;
      default:
        break;
    }

    history.push(`${redirectRoute}/${value}`);
  };

  const searchGroup = (event: SyntheticEvent, data: any): void => {
    let filterName = "";
    const { searchQuery } = data;
    switch (searchingGroup) {
      case "orders":
        filterName = "orderNumber";
        break;
      case "products":
        filterName = "referenceId";
        break;
      case "customers":
        filterName = "name";
        break;
      case "claims":
        filterName = "claimNumber";
        break;
      case "backorders":
        filterName = "backorderNumber";
        break;
      default:
        break;
    }

    const filters = [
      { text: searchQuery, value: searchQuery, name: filterName },
    ];

    getData({ filters });
  };

  const getData = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: 1,
        size: 13,
        filters: [],
        sortColumn: null,
        sortDirection: null,
      };
      switch (searchingGroup) {
        case "products":
          fetchSparePartsSearch(Object.assign(paramsObj, params));
          break;
        case "orders":
          fetchOrdersSearch(Object.assign(paramsObj, params));
          break;
        case "customers":
          fetchCustomersSearch(Object.assign(paramsObj, params));
          break;
        case "claims":
          fetchClaimsSearch(Object.assign(paramsObj, params));
          break;
        case "backorders":
          fetchBackordersSearch(Object.assign(paramsObj, params));
          break;
        default:
          break;
      }
    },
    [
      fetchBackordersSearch,
      fetchClaimsSearch,
      fetchCustomersSearch,
      fetchOrdersSearch,
      fetchSparePartsSearch,
      searchingGroup,
    ]
  );

  useEffect(() => {
    if (typeof searchingValue === "string") {
      getData();
    }
  }, [searchingGroup, getData, searchingValue]);

  return (
    <Dropdown
      iconposition="left"
      icon="search"
      placeholder="Szukaj..."
      noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
      fluid
      search
      selection
      className="top-search-header"
      onChange={changeSearchingValue}
      onSearchChange={searchGroup}
      selectOnBlur={false}
      value={searchingValue}
      options={mapSearching()}
      style={{ marginRight: 2, width: "250px" }}
    />
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  spareParts,
  orders,
  customers,
  claims,
  backorders,
}: ApplicationState) => ({
  spareParts: spareParts.searchList,
  orders: orders.searchList,
  customers: customers.searchList,
  claims: claims.searchList,
  backorders: backorders.searchList,
});

const mapDispatchToProps: TReduxActions = {
  fetchSparePartsSearch,
  fetchOrdersSearch,
  fetchCustomersSearch,
  fetchClaimsSearch,
  fetchBackordersSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopSearch));
