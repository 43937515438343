import { Table } from "semantic-ui-react";
import { TTableSortSession } from "~/utils/tableSortSession";

import React from "react";
import TableSortIcon from "../TableSortIcon/TableSortIcon";

export type TProps = {
  name: string;
  label: string | JSX.Element;
  sortColumn?: string | TTableSortSession;
  sortDirection?: "ASC" | "DESC" | TTableSortSession | string;
  onSort: (binding: any, name: string) => void;
  disableSort?: boolean;
};

const TableHeaderSortingCell: React.FC<TProps> = ({
  name,
  sortColumn,
  label,
  sortDirection,
  onSort,
  disableSort,
}): JSX.Element => {
  return !disableSort ? (
    <Table.HeaderCell style={{ cursor: "pointer" }} onClick={onSort}>
      {label}
      {sortColumn === name && <TableSortIcon sortDirection={sortDirection} />}
    </Table.HeaderCell>
  ) : (
    <Table.HeaderCell>{label}</Table.HeaderCell>
  );
};

export default TableHeaderSortingCell;
