import { FormattedMessage } from 'react-intl'
import { Button, Confirm, Icon, Modal } from 'semantic-ui-react'
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic'

import React from 'react'
import './Confirm.scss'

export interface Props {
  modalOpen: boolean
  contentText: string | JSX.Element
  headerText: string
  headerIcon: SemanticICONS
  loading?: boolean
  onConfirmClick: (...params: any) => void
  onCancelClick: () => void
}

const ModalConfirm: React.FC<Props> = ({
  modalOpen,
  contentText,
  headerText,
  headerIcon,
  loading,
  onConfirmClick,
  onCancelClick
}) => {

  return (
    <Confirm className="modal-confirm"
      cancelButton={<Button onClick={onCancelClick} disabled={loading}><FormattedMessage id="app.no" /></Button>}
      confirmButton={<Button onClick={onConfirmClick} loading={loading} disabled={loading} content={<FormattedMessage id="app.yes" />} icon="checkmark" labelPosition="left" />}
      content={<Modal.Content>{typeof contentText === 'string' ? <FormattedMessage id={contentText} /> : contentText}</Modal.Content>}
      header={ 
        <Modal.Header as='h3'>
          <Icon name={headerIcon} />
          <FormattedMessage id={headerText} />
        </Modal.Header>
      }
      open={modalOpen}
      size="tiny"
    />
  )
}

export default ModalConfirm

