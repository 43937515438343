import { Button, Container } from "semantic-ui-react"

import React, { useState } from 'react'

import './previewImage.scss'

type Props = {
    src: string
}

const PreviewImage: React.FC<Props> = ({ src }) => {

    const [showImage, setShowImage] = useState<boolean>(false)

    return (
        <Container className='image-container' textAlign="right" fluid>
            <Button onClick={() => setShowImage(!showImage)} content='Wzorcowy plik importu' labelPosition="right" icon="dropdown" basic onBlur={() => setShowImage(false)} />
            <div className={`preview-image-wrapper${!showImage ? ' hidden' : ''}`} >
                <img alt={src} className='preview-image' src={src} />
            </div>
        </Container>
    )
}

export default PreviewImage