import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import PageHeader from "~/components/PageHeader/PageHeader";
import SmartWrapper from "~/components/SmartField/SmartWrapper";
import {
  AnnouncementType,
  CustomerRoles,
} from "~/pages/Dashboard/lib/constans";
import { ApplicationState } from "~/store";
import AnnouncementInfoCard from "../AnnouncementDetails/lib/AnnouncementInfoCard";
import AnnouncementStatusCard from "../AnnouncementDetails/lib/AnnouncementStatusCard";
import AnnouncementRolesCard from "../AnnouncementDetails/lib/AnnouncementRolesCard";
import AnnouncementContentCard from "../AnnouncementDetails/lib/AnnouncementContentCard";
import {
  uploadAnnouncement,
  uploadAnnouncementStart,
} from "~/store/annouoncements/actions";
import { TNewAnnouncementDTO } from "~/store/annouoncements/types";
import { useHistory } from "react-router-dom";
import { useAnnouncementsValidation } from "../useAnnouncementsValidation";

export type TNewAnnouncement = {
  hidden: boolean;
  content: any[] | null;
  endDate: string | undefined;
  image: string | null;
  intendedFor: CustomerRoles[];
  priority: number;
  startDate: string | undefined;
  title: string;
  type: AnnouncementType;
  file?: File;
};

type PropsFromState = {
  loading: boolean;
  created?: string;
};

type ReduxActions = {
  uploadAnnouncement: typeof uploadAnnouncement;
  uploadAnnouncementStart: typeof uploadAnnouncementStart;
};

type TProps = PropsFromState & ReduxActions;

const AddAnnouncement: React.FC<TProps> = ({
  loading,
  created,
  uploadAnnouncement,
  uploadAnnouncementStart,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (created) {
      history.push(`/announcements/${created}`);
      uploadAnnouncementStart();
    }
  }, [created, uploadAnnouncementStart, history]);

  const [announcementForEdit, setAnnouncementForEdit] =
    useState<TNewAnnouncement>({
      hidden: false,
      content: [],
      endDate: undefined,
      image: null,
      intendedFor: [],
      priority: 1,
      startDate: undefined,
      title: "",
      type: AnnouncementType.TEXT,
    });

  const prepareAnnouncement = useCallback((): TNewAnnouncementDTO => {
    return {
      hidden: announcementForEdit.hidden,
      title: announcementForEdit.title,
      priority: announcementForEdit.priority,
      startDate: announcementForEdit.startDate,
      endDate: announcementForEdit.endDate,
      intendedFor: announcementForEdit.intendedFor,
      type: announcementForEdit.type,
      content:
        announcementForEdit.type === "TEXT"
          ? JSON.stringify(announcementForEdit.content)
          : null,
      image:
        announcementForEdit.type === "IMAGE" ? announcementForEdit.image : null,
      file:
        announcementForEdit.type === "IMAGE"
          ? announcementForEdit.file
          : undefined,
    };
  }, [announcementForEdit]);

  const { validMessage, isValid } = useAnnouncementsValidation({
    announcementForEdit,
  });

  const handleSave = useCallback(() => {
    if (isValid) {
      uploadAnnouncement(prepareAnnouncement());
    }
  }, [isValid, prepareAnnouncement, uploadAnnouncement]);
  return (
    <Fragment>
      <SmartWrapper
        model={announcementForEdit}
        onBeforeSave={(data: any) => {
          setAnnouncementForEdit({ ...announcementForEdit, ...data });
        }}
      >
        <PageHeader
          icon="bullhorn"
          title={"Ogłoszenia"}
          breadcrumb={[
            {
              text: "Lista",
              link: "/administration",
            },
            {
              text: "Dodaj",
            },
          ]}
          buttons={[
            {
              icon: "save",
              content: "Zapisz",
              primary: true,
              onClick: handleSave,
              disabled: !isValid,
              popup: validMessage,
            },
          ]}
          loading={loading}
          refreshAction={undefined}
        />
        <div className="uber-content overflow-visible">
          <Grid stretched>
            {announcementForEdit ? (
              <>
                <AnnouncementInfoCard
                  edit={true}
                  announcement={announcementForEdit}
                  setAnnouncementForEdit={setAnnouncementForEdit}
                />
                <AnnouncementStatusCard
                  edit={true}
                  announcement={announcementForEdit}
                />
                <AnnouncementRolesCard
                  edit={true}
                  announcement={announcementForEdit}
                  setAnnouncementForEdit={setAnnouncementForEdit}
                />
                <AnnouncementContentCard
                  edit={true}
                  announcement={announcementForEdit}
                  setNewAnnouncement={setAnnouncementForEdit}
                />
              </>
            ) : null}
          </Grid>
        </div>
      </SmartWrapper>
    </Fragment>
  );
};
const mapStateToProps = ({ announcements }: ApplicationState) => ({
  loading: announcements.loadingAnnouncementAdd,
  created: announcements.created,
});

const mapDispatchToProps = {
  uploadAnnouncement,
  uploadAnnouncementStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement);
