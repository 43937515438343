import InfoIcon from '~/pages/Orders/Details/lib/InfoIcon';
import { Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { TableCellType } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/constants';

export const ManualOrdersTableCell = ({ column, line, ldcOrderId }: TableCellType) => (
  <Table.Cell key={column.name} textAlign="right">
    {line.manualOrderDocument ? 1 : 0}
    <InfoIcon
      documents={line.manualOrderDocument ? [line.manualOrderDocument] : []}
      clickableItem={(document) => (
        <Button style={{ display: 'block' }} compact basic as={Link} to={`/manual-order/${document}/${ldcOrderId}`}>
          Podgląd zamówienia manualnego
        </Button>
      )}
      noResultsMessage="Brak zamówień manulanych"
    />
  </Table.Cell>
);
