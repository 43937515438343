import React, {
  Fragment,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
} from "~/store/dictionaries/actions";
import {
  exportFP0536ToCsv,
  exportUserListToXls,
  fetchUsers,
} from "~/store/users/actions";
import { useAdministrationRights } from "./hooks/useAdministrationRights";
import { useAdministrationBreadcrubms } from "./hooks/useAdministrationBreadcrumbs";
import {
  BREADCRUMB_ID,
  BREADCRUMB_ID_CLIENT_PANEL,
  TConfirmType,
} from "./constants";
import PageHeader from "~/components/PageHeader/PageHeader";
import { User } from "~/store/users/types";
import { DictionariesState, DictionaryName } from "~/store/dictionaries/types";
import AddUser from "./lib/AddUser";
import { useAdministrationButtons } from "./hooks/useAdministrationButtons";
import { Tab } from "semantic-ui-react";
import UserTableWrapper from "./lib/UserTableWrapper";
import { TColumn } from "~/components/Table/lib/types";
import Dictionaries from "./lib/Dictionaries";
import translations from "~/utils/translations";
import { useUsersColumns } from "./hooks/useUsersColumns";
import AddDictionaryItem from "./lib/Dictionaries/Add";
import Speeder from "./lib/Speeder/Speeder";
import ImportFactorLimits from "./lib/LimitImport/ImportFactorLimits";
import HazardousGoods from "./lib/HazardousGoods/HazardousGoods";
import AnnouncementsManagement from "./lib/Announcements/AnnouncementsManagement";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import ClientPanelUsers from "./lib/ClientPanelUsers/ClientPanelUsers";
import { PaginationMeta, TotalRecords } from "~/store/types";

type TPropsFromState = {
  users: User[];
  loading: boolean;
  downloadFP0536pending: boolean;
  dictionaries: DictionariesState;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
};

type PropsFromRedux = {
  fetchUsers: typeof fetchUsers;
  fetchDictionariesNames: typeof fetchDictionariesNames;
  fetchDictionariesNamesDetails: typeof fetchDictionariesNamesDetails;
  exportUserListToXls: typeof exportUserListToXls;
  exportFP0536ToCsv: typeof exportFP0536ToCsv;
};

type TProps = TPropsFromState & PropsFromRedux;

const Administration: React.FC<TProps> = ({
  loading,
  downloadFP0536pending,
  dictionaries,
  fetchUsers,
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
  exportFP0536ToCsv,
  exportUserListToXls,
  meta,
  users,
}) => {
  const {
    userHaveLdcUiAdministrationViewRole,
    userHaveLdcUiAdministrationUsersRole,
    userHaveLdcUiAdministrationSpeederRole,
    userHaveLdcUiAdministrationImportFactorLimitRole,
    userHaveCustomerPanelUiAdvertisingViewRole,
  } = useAdministrationRights();

  const { isClientPanelUser, isSuperAdmin, isLocalAdmin } =
    useClientPanelRoles();

  const [activeTab, setActiveTab] = useState<
    BREADCRUMB_ID | BREADCRUMB_ID_CLIENT_PANEL
  >(
    !isClientPanelUser
      ? BREADCRUMB_ID.users
      : BREADCRUMB_ID_CLIENT_PANEL.announcements
  );
  const [name, setName] = useState<string>("");
  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const [openAddDictionaryItemModal, setOpenAddDictionaryItemModal] =
    useState<boolean>(false);
  const [columns, setColumns] = useState<TColumn[]>(
    useUsersColumns({ tableKey: "users" }).columnsConfig
  );
  const [confirmType, setConfirmType] = useState<TConfirmType>("");
  const [actionId, setActrionId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [actionUserName, setActionUserName] = useState<string>("");
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

  const downloadFile = useCallback(() => {
    exportUserListToXls("Lista użytkowników");
  }, [exportUserListToXls]);

  const breadcrumbs = useAdministrationBreadcrubms({ setActiveTab });

  useEffect(() => {
    setRefreshTrigger(0);
  }, [activeTab]);

  const createdHandle = useCallback(() => {
    fetchUsers({ page: meta.page, size: meta.size }, isClientPanelUser);
  }, [fetchUsers, meta.page, meta.size, isClientPanelUser]);

  const buttons = useAdministrationButtons({
    activeTab,
    name,
    downloadFile,
    setOpenAddDictionaryItemModal,
    setOpenAddUserModal,
  });

  const refresh = useCallback((): void => {
    if (!isClientPanelUser) {
      switch (activeTab) {
        case BREADCRUMB_ID.users:
          fetchUsers({ size: meta.size, page: 1 }, isClientPanelUser);
          break;
        case BREADCRUMB_ID.dict:
          fetchDictionariesNames();
          name !== "" && fetchDictionariesNamesDetails(name);
          break;
        default:
          break;
      }
    } else {
      setRefreshTrigger((refreshTrigger) => refreshTrigger + 1);
    }
  }, [
    activeTab,
    fetchDictionariesNames,
    fetchDictionariesNamesDetails,
    fetchUsers,
    isClientPanelUser,
    name,
    meta.size,
  ]);

  const renderUsers = useCallback(
    (): JSX.Element | null =>
      !isClientPanelUser ? (
        <UserTableWrapper
          actionId={actionId}
          actionUserName={actionUserName}
          columns={columns}
          confirmType={confirmType}
          modalOpen={modalOpen}
          setColumns={setColumns}
          setModalOpen={setModalOpen}
          setConfirmType={setConfirmType}
          setActrionId={setActrionId}
          setActionUserName={setActionUserName}
          fetchUsers={fetchUsers}
        />
      ) : null,
    [
      actionId,
      actionUserName,
      columns,
      confirmType,
      modalOpen,
      fetchUsers,
      isClientPanelUser,
    ]
  );

  const changeName = useCallback(
    (event: SyntheticEvent, data: any): void => {
      setName(data.value);
      fetchDictionariesNamesDetails(data.value);
    },
    [fetchDictionariesNamesDetails]
  );

  const renderDictionaries = useCallback((): JSX.Element => {
    return (
      <Dictionaries
        changeName={changeName}
        name={name}
        refresh={refresh}
        fetchDictionariesNames={fetchDictionariesNames}
      />
    );
  }, [changeName, name, refresh, fetchDictionariesNames]);

  const renderSpeeder = useCallback((): JSX.Element => {
    return <Speeder />;
  }, []);

  const renderImportFactorLimit = useCallback((): JSX.Element => {
    return <ImportFactorLimits />;
  }, []);

  const renderHazardousGoods = useCallback((): JSX.Element => {
    return (
      <HazardousGoods
        exportFP0536ToCsv={exportFP0536ToCsv}
        loading={downloadFP0536pending}
      />
    );
  }, [exportFP0536ToCsv, downloadFP0536pending]);

  const renderAnnouncementsManagement = useCallback((): JSX.Element => {
    return <AnnouncementsManagement refreshTrigger={refreshTrigger} />;
  }, [refreshTrigger]);

  const renderClientPanelUsers = useCallback((): JSX.Element => {
    return <ClientPanelUsers refreshTrigger={refreshTrigger} />;
  }, [refreshTrigger]);

  const getPanes = useCallback(() => {
    let panes = [
      {
        menuItem: "Użytkownicy",
        render: renderUsers,
        visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
      },
      {
        menuItem: "Słowniki",
        render: renderDictionaries,
        visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
      },
      {
        menuItem: "Speeder",
        render: renderSpeeder,
        visible: !isClientPanelUser && userHaveLdcUiAdministrationSpeederRole,
      },
      {
        menuItem: "Import limitów faktora",
        render: renderImportFactorLimit,
        visible:
          !isClientPanelUser &&
          userHaveLdcUiAdministrationImportFactorLimitRole,
      },
      {
        menuItem: "renderHazardousGoods",
        render: renderHazardousGoods,
        visible: !isClientPanelUser && userHaveLdcUiAdministrationViewRole,
      },
    ];

    const panesClientPanel = [
      {
        menuItem: "Ogłoszenia",
        render: renderAnnouncementsManagement,
        visible:
          isClientPanelUser &&
          isSuperAdmin &&
          userHaveCustomerPanelUiAdvertisingViewRole,
      },
      {
        menuItem: "Użytkownicy Panelu Klienta",
        render: renderClientPanelUsers,
        visible: isClientPanelUser,
      },
    ];

    if (isClientPanelUser) {
      panes = panesClientPanel;
    }

    return panes.map((item) => (item.visible ? item : {})); //.filter((item) => item.visible);
  }, [
    isClientPanelUser,
    isSuperAdmin,
    renderAnnouncementsManagement,
    renderClientPanelUsers,
    renderDictionaries,
    renderHazardousGoods,
    renderUsers,
    renderImportFactorLimit,
    renderSpeeder,
    userHaveLdcUiAdministrationImportFactorLimitRole,
    userHaveLdcUiAdministrationSpeederRole,
    userHaveLdcUiAdministrationViewRole,
    userHaveCustomerPanelUiAdvertisingViewRole,
  ]);

  const changeTab = (event: SyntheticEvent, data: any): void => {
    setActiveTab(data.activeIndex);
  };

  useEffect(() => {
    document.title = translations.format("LDC_ADMINISTRATION");
    if (!isClientPanelUser && (!users || !users.length) && !loading) {
      fetchUsers({ page: 1, size: 15 }, isClientPanelUser);
    }
    if (!isClientPanelUser) {
      setActiveTab(BREADCRUMB_ID.users);
    } else {
      if (isLocalAdmin || !userHaveCustomerPanelUiAdvertisingViewRole) {
        setActiveTab(BREADCRUMB_ID_CLIENT_PANEL.usersClientPanel);
      }
    }
  }, [
    fetchUsers,
    isLocalAdmin,
    isClientPanelUser,
    loading,
    users,
    userHaveCustomerPanelUiAdvertisingViewRole,
  ]);

  return (
    <Fragment>
      <PageHeader
        icon="settings"
        title="Administracja"
        breadcrumb={breadcrumbs}
        buttons={buttons}
        refreshAction={() => refresh()}
        breadCrumbTab={activeTab}
        loading={loading}
      />
      {(userHaveLdcUiAdministrationUsersRole ||
        isLocalAdmin ||
        isSuperAdmin) && (
        <AddUser
          triggerOpen={openAddUserModal}
          createdSuccess={createdHandle}
          handleClose={() => setOpenAddUserModal(false)}
        />
      )}
      <AddDictionaryItem
        triggerOpen={openAddDictionaryItemModal}
        name={name as DictionaryName}
        createdSuccess={refresh}
        details={dictionaries.details}
        handleClose={() => setOpenAddDictionaryItemModal(false)}
      />
      <Tab
        className="hide-tab-menu"
        menu={{ secondary: true, pointing: true }}
        panes={getPanes()}
        onTabChange={changeTab}
        activeIndex={activeTab}
      />
    </Fragment>
  );
};

const mapStateToProps = ({
  users,
  dictionaries,
}: ApplicationState): TPropsFromState => ({
  users: users.list,
  loading: users.loadingUsers,
  downloadFP0536pending: users.downloadFP0536pending,
  dictionaries,
  meta: users.meta,
  totalRecords: users.totalRecords,
});

const mapDispatchToProps: PropsFromRedux = {
  fetchUsers,
  fetchDictionariesNames,
  fetchDictionariesNamesDetails,
  exportUserListToXls,
  exportFP0536ToCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
