export type TTableSortSession = {
    key: string
    value: string
    direction: string
}

const TableSortSession = (() => {

    const getFromLS = (): any => {
        const data = localStorage.getItem('tableSortSession')
        return data ? JSON.parse(data) : []
    }

    let tableSorting: TTableSortSession[] = getFromLS()
  
    const getSorting = (key: string): TTableSortSession | undefined => tableSorting.find(s => s.key === key)
  
    const setSoring = (tsort: TTableSortSession): void => {
        const found = tableSorting.find(s => s.key === tsort.key)
        if(found){
            tableSorting = tableSorting.filter(s => s.key !== tsort.key);
        }
        tableSorting.push(tsort)
        localStorage.setItem('tableSortSession', JSON.stringify(tableSorting))
    }
  
    return {
        getSorting,
        setSoring
    }
  
  })()
  
  export default TableSortSession