import React from "react";
import { TDictionaryLabelProps } from "~/components/MapDictionary/lib/types";
import { NiceLabel } from "~/components/MapDictionary/NiceLabel";
import { Icon } from "semantic-ui-react";
import { DictionaryItem } from "~/store/dictionaries/types";

export const DictionaryLabel: React.FC<TDictionaryLabelProps> = ({
    value,
    dictionary,
    light,
    compact,
    iconOnly,
    noDescription,
    placeholder,
    flexDisplay,
    hideKey,
    regular,
    isDiscountLabel
}) => {
    let found: DictionaryItem | undefined = {
        key: "",
        value: "",
        text: ""
    }
    if(dictionary){
        found = dictionary.find((d: any) => d.value === value)
    }
    if (found) {
        const text = compact ? found.text : found.value
        const description = compact ? undefined : found.text
        return <NiceLabel text={!iconOnly && text} flexDisplay={flexDisplay} description={!noDescription ? description : undefined} light={light} icon={found.icon} hideKey={hideKey} color={found.color}
                          popup={`${found.value} - ${found.text}`} regular={regular} discountValues={{sell: found.sell, buy: found.buy}} isDiscountLabel={isDiscountLabel}/>
    }
    return placeholder ? placeholder : (
                <NiceLabel
                    text={value || '-'}
                    description={value ? <Icon color="grey" name='warning sign' fitted /> : undefined}
                    popup={value ? "Wartość niezgodna ze słownikiem" : "Brak wartości"}
                    light={light}
                    regular={regular}/>
            )
}