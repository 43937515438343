import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Dropdown, DropdownProps, Form, Radio, RadioProps, TextAreaProps } from 'semantic-ui-react';
import dictionariesStatic from '~/store/dictionaries/static';
import { createDistrigoCredit } from '~/store/distrigo-credit/actions';
import { DistrigoLimitRenewalType, LimitRenewalType } from '~/store/distrigo-credit/types';
import { HandleChange } from '~/store/warehouses-in-calendar/types';
import { DictionariesState } from '~/store/dictionaries/types';
import CommonLoader from '~/components/Loaders/CommonLoader';
import { isDatePast } from '~/utils/dateUtils';
import moment from 'moment';

type AddDistrigoCreditProps = {
  editMode: boolean;
  customerId: string;
  dictionaries: DictionariesState;
  loading: boolean;
  created: boolean;
};

const AddDistrigoCredit = ({ editMode, customerId, dictionaries, loading, created }: AddDistrigoCreditProps) => {
  const [amount, setAmount] = useState<number>(0);
  const [validUntil, setValidUntil] = useState<string>('');
  const [renewable, setRenewable] = useState<boolean>(false);
  const [renewalType, setRenewalType] = useState<DistrigoLimitRenewalType>('WEEKLY');
  const [creationComment, setCreationComment] = useState<string>('');
  const [formValid, setFormValid] = useState(false);
  const [paymentDue, setPaymentDue] = useState<string>('');
  const dispatch = useDispatch();

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} />;
  };

  const handlePaymentDue = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    setPaymentDue(data.value as string);
  };

  const handleAmount = (event: SyntheticEvent, data: any): void => {
    setAmount(isNaN(Number.parseFloat(data.value)) ? 0 : Number.parseFloat(data.value));
  };

  const handleValidUntil = (e: SyntheticEvent<Element, Event> | undefined, { value }: HandleChange) => {
    setValidUntil(value);
  };

  const handleComment = (ev: any, props: TextAreaProps) => {
    setCreationComment(String(props.value));
  };

  const handleRenewable = (event: React.FormEvent<HTMLInputElement>, data: RadioProps) => {
    setRenewable(data.checked as boolean);
  };

  const handleRenewableType = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value) {
      setRenewalType(LimitRenewalType[data.value.toString() as DistrigoLimitRenewalType]);
    }
  };

  useEffect(() => {
    if (amount >= 0 && paymentDue && validUntil && creationComment && creationComment !== '') {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [amount, paymentDue, renewalType, renewable, validUntil, creationComment]);

  const handleAdd = (): void => {
    dispatch(
      createDistrigoCredit(customerId, {
        amount: amount,
        creationComment: creationComment,
        renewalType: renewable ? renewalType : 'NONE',
        validUntil: moment(validUntil).format('YYYY-MM-DDT23:59:59'),
        paymentDueId: paymentDue,
      })
    );
  };

  const clearForm = () => {
    setAmount(0);
    setRenewable(false);
    setRenewalType(LimitRenewalType.NONE);
    setCreationComment('');
    setPaymentDue('');
    setValidUntil('');
  };

  useEffect(() => {
    if (created && !loading) {
      clearForm();
    }
    // eslint-disable-next-line
  }, [created, loading]);

  return (
    <>
      <Form.Field required>
        <label>{formatMessage('app.customer.loan.amount')}</label>
        <Form.Input placeholder="0" value={amount} onChange={handleAmount} disabled={!editMode} />
      </Form.Field>

      <Form.Field required>
        <label>{formatMessage('app.customer.loan.availableTo')}</label>
        <SemanticDatepicker
          id="finalDate"
          name="validUntil"
          value={validUntil}
          filterDate={isDatePast}
          placeholder="RRRR-MM-DD"
          locale="pl-PL"
          onChange={handleValidUntil}
          autoComplete="off"
          disabled={!editMode}
        />
      </Form.Field>

      <b>
        W przypadku upłynięcia czasu obowiązywania kredytu przed kolejną datą odnowienia, kredyt przestanie być aktualny
        a odnowienie kredytu nie będzie wykonane.
      </b>
      <br />
      <br />

      <Form.Field>
        <label>{formatMessage('app.customer.loan.renewable')}</label>
        <Radio
          toggle
          type="radio"
          name="renewable"
          checked={renewable}
          onChange={handleRenewable}
          disabled={!editMode}
        />
      </Form.Field>

      <Form.Field>
        <label>{formatMessage('app.customer.loan.period')}</label>
        <Dropdown
          placeholder="Wybierz kategorię"
          fluid
          selection
          value={renewalType}
          onChange={handleRenewableType}
          options={dictionariesStatic.DISTRIGO_LIMIT_RENEWAL_TYPE.filter((type) => type.key !== 'NONE')}
          disabled={!editMode || !renewable}
          noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
        />
      </Form.Field>

      <Form.Field required>
        <label>{formatMessage('app.customer.loan.paymentPeriod')}</label>
        <Dropdown
          placeholder="Wybierz kategorię"
          fluid
          selection
          value={paymentDue}
          onChange={handlePaymentDue}
          options={dictionaries['payment-due']}
          disabled={!editMode}
          noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
        />
      </Form.Field>
      <Form.Field required>
        <label>{formatMessage('app.customer.loan.reasonForGrantingTheLoan')}</label>
        <Form.TextArea
          disabled={!editMode}
          readOnly={false}
          rows={6}
          value={creationComment}
          onChange={handleComment}
        />
      </Form.Field>

      <Button
        icon="add"
        size="medium"
        circular
        basic
        style={{ marginTop: '20px' }}
        className="primary"
        disabled={!editMode || !formValid}
        content="Dodaj"
        onClick={handleAdd}
      />
      <CommonLoader loading={loading} />
    </>
  );
};

export default AddDistrigoCredit;
