import { PaginationMeta, TotalRecords } from "../types";

export enum LibraryActionTypes {
  FETCH_LIBRARY = "@@library/FETCH_LIBRARY",
  FETCH_LIBRARY_SUCCESS = "@@library/FETCH_LIBRARY_SUCCESS",
  FETCH_LIBRARY_ERROR = "@@library/FETCH_LIBRARY_ERROR",
  FETCH_LIBRARY_ITEM = "@@library/FETCH_LIBRARY_ITEM",
  FETCH_LIBRARY_ITEM_SUCCESS = "@@library/FETCH_LIBRARY_ITEM_SUCCESS",
  FETCH_LIBRARY_ITEM_ERROR = "@@library/FETCH_LIBRARY_ITEM_ERROR",
  DELETE_LIBRARY_ITEM = "@@library/DELETE_LIBRARY_ITEM",
  DELETE_LIBRARY_ITEM_SUCCESS = "@@library/DELETE_LIBRARY_ITEM_SUCCESS",
  DELETE_LIBRARY_ITEM_ERROR = "@@library/DELETE_LIBRARY_ITEM_ERROR",
  ADD_LIBRARY_ITEM = "@@library/ADD_LIBRARY_ITEM",
  ADD_LIBRARY_ITEM_SUCCESS = "@@library/ADD_LIBRARY_ITEM_SUCCESS",
  ADD_LIBRARY_ITEM_ERROR = "@@library/ADD_LIBRARY_ITEM_ERROR",
}

export interface LibrarState {
  readonly loading: boolean;
  readonly library: LibraryItem[];
  readonly meta: PaginationMeta;
  readonly totalRecords: TotalRecords;
  readonly downloadItemLoading: boolean;
  readonly deleteItemLoading: boolean;
  readonly addItemLoading: boolean;
}

export type NewLibraryItem = {
  name: string;
  description: string;
  customerClasses: string[];
  file: File;
};

export type LibraryItem = NewLibraryItem & {
  createdAt: string;
  id: string;
  author: string;
};
