import { Table } from "semantic-ui-react";
import UsersList from "~/pages/Administration/List";
import PaginationBar from "~/components/PaginationBar/PaginationBar";
import React from "react";
import {
  ACTION_TYPE_ENUM,
  UserTableProps,
} from "~/pages/Administration/lib/UserTableWrapper/constants";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import { fetchUsers } from "~/store/users/actions";

const UserTable = ({
  users,
  columns,
  setModalOpen,
  setConfirmType,
  setActrionId,
  setActionUserName,
  totalRecords,
  meta,
  fetchUsers,
}: UserTableProps) => {
  const onActivateUser = (id: string, name: string): void => {
    setModalOpen(true);
    setConfirmType(ACTION_TYPE_ENUM.ACTIVATE_USER);
    setActrionId(id);
    setActionUserName(name);
  };

  const onDeactivateUser = (id: string, name: string): void => {
    setModalOpen(true);
    setConfirmType(ACTION_TYPE_ENUM.DEACTIVATE_USER);
    setActrionId(id);
    setActionUserName(name);
  };

  const onRemoveUser = (id: string, name: string): void => {
    setModalOpen(true);
    setConfirmType(ACTION_TYPE_ENUM.REMOVE_USER);
    setActrionId(id);
    setActionUserName(name);
  };

  const pageChange = (page: number) => {
    fetchUsers({ page, size: meta.size });
  };

  const pageSizeChange = (size: number) => {
    fetchUsers({ page: 1, size });
  };

  return (
    <Table style={{ whiteSpace: "nowrap" }} selectable>
      <Table.Header>
        <Table.Row>
          {columns.map(
            (column) =>
              column.projection && (
                <Table.HeaderCell key={column.name}>
                  {column.label}
                </Table.HeaderCell>
              )
          )}
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <UsersList
          users={users}
          columns={columns}
          onActivateUser={onActivateUser}
          onDeactivateUser={onDeactivateUser}
          onRemoveUser={onRemoveUser}
        />
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="100">
            <PaginationBar
              meta={meta}
              onPageChange={pageChange}
              onPageSizeChange={pageSizeChange}
              totalRecords={totalRecords}
              pageSizeVisible={true}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

const mapStateToProps = ({ users }: ApplicationState) => ({
  users: users.list,
  meta: users.meta,
  totalRecords: users.totalRecords,
});

const mapDispatchToProps = {
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
