import React from "react";
import { useCallback, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useCorrectionsCPColumns = () => {
  const {
    getAmountCell,
    getDateCell,
    getDictionaryCell,
    getLinkCell,
    getTextCell,
  } = useCellsRenderer();

  const getAddressCell = useCallback(
    (document: any, column: TColumn): JSX.Element => {
      const { address, streetNumber, postcode, city } = document.orderCustomer;
      return (
        <Table.Cell
          key={column.name}
          width={column.width ? column.width : undefined}
        >
          <span>
            {`${address || ""} ${streetNumber || ""}`} <br />
            <small>{`${postcode || ""} ${city || ""}`}</small>
          </span>
        </Table.Cell>
      );
    },
    []
  );

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "orderCustomer.id",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderCustomer",
        label: "Adres odbiorcy",
        projection: true,
        disableSort: true,
        filteringBlocked: true,
        getCell: (document, column) => getAddressCell(document, column),
      },
      {
        name: "invoiceDocumentMaskToCorrect",
        label: "Do faktury",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "netAmount",
        label: "Kwota netto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "taxAmount",
        label: "Kwota VAT",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "grossAmount",
        label: "Kwota brutto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "accountingCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "packageNumber",
        label: "Numer paczki",
        projection: false,
        hide: true,
        disableSort: true,
        getCell: (document, column) =>
          getTextCell(document, column, document.invoiceLines[0].packageNumber),
      },
      {
        name: "invoiceLines.serviceBoxOrderNo",
        label: "Nr zam Service Box ",
        projection: false,
        hide: true,
        disableSort: true,
        getCell: (document, column) =>
          getTextCell(
            document,
            column,
            document.invoiceLines
              ? document.invoiceLines[0].serviceBoxOrderNo
              : ""
          ),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Nr zamówienia",
        projection: false,
        hide: true,
        disableSort: true,
        getCell: (document, column) =>
          document.invoiceLines[0].orderID
            ? getLinkCell(
                document,
                column,
                `/order/${document.invoiceLines[0].orderID}`,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              )
            : getTextCell(
                document,
                column,
                document.invoiceLines
                  ? document.invoiceLines[0].orderNumber
                  : ""
              ),
      },
    ],
    [
      getAmountCell,
      getDateCell,
      getDictionaryCell,
      getLinkCell,
      getTextCell,
      getAddressCell,
    ]
  );

  return { columnsConfig };
};
