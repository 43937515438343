import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";

export const useCollectiveInvoiceCorrectionsColumns = () => {
  const { getAmountCell, getDateCell, getTextCell } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "createDateTime",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "paymentCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "bonusName",
        label: "Nazwa bonusu",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "totalPriceNet",
        label: "Kwota netto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "totalPriceTax",
        label: "Kwota VAT",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "totalPriceGross",
        label: "Kwota brutto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
    ],
    [getAmountCell, getDateCell, getTextCell]
  );

  return { columnsConfig };
};
