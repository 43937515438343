import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { ApplicationState } from '~/store';
import { downloadProductGroup, saveAdditionalDiscount } from '~/store/customer/actions';
import { Customer } from '~/store/customer/types';
import AdditionalDiscountCodeHistoryModal from '../AdditionalDiscountCodeHistoryModal';
import AdditionalDiscountCodeModal from '../AdditionalDiscountCodeModal';
import './style.scss';
import translations from '~/utils/translations';
import { AppContext } from '~/context/AuthContext';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';

type TReduxState = {
  downloadProductsGroupPending: boolean;
  saveAdditionalDiscountPending: boolean;
};

type TReduxActions = {
  saveAdditionalDiscount: typeof saveAdditionalDiscount;
  downloadProductGroup: typeof downloadProductGroup;
};

interface Props extends TReduxActions, TReduxState {
  customer: Customer;
  editMode: boolean;
}

const AdditionalDiscountCodeCard: React.FC<Props> = ({
  customer,
  editMode,
  downloadProductGroup,
  downloadProductsGroupPending,
  saveAdditionalDiscount,
  saveAdditionalDiscountPending,
}) => {
  const appContext = useContext(AppContext);
  const userHaveLdcCustomerAdditionalDiscountEdit = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_UI_CUSTOMER_ADDITIONAL_DISCOUNT_EDIT),
    [appContext]
  );

  const { renderField, renderToggle } = useRenderingFunctions(
    customer,
    editMode && userHaveLdcCustomerAdditionalDiscountEdit
  );

  const handleExportClick = (groupId: string | undefined) => {
    if (!groupId) return;
    downloadProductGroup(customer.rrdi, groupId);
  };

  return (
    <>
      {customer.discountDRZ && customer.discountDRZ.current ? (
        <>
          {renderToggle('isDiscountDRZActive', {
            label: (
              <>
                <span style={{ lineHeight: '24px' }}>Dodatkowy rabat</span>
                <Popup
                  trigger={
                    <span style={{ position: 'relative', marginLeft: 5 }}>
                      <Icon.Group size="large" style={{ cursor: 'pointer' }}>
                        <Icon name="info circle" fitted />
                      </Icon.Group>
                    </span>
                  }
                >
                  {translations.format('app.customer.additional-discount.description1')}
                </Popup>
              </>
            ),
          })}
          <div
            style={{
              marginBottom: 13,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <label style={{ fontWeight: 700, fontSize: '12px' }}>Grupa produktów</label>
            <Button
              primary
              style={{ width: 100 }}
              onClick={() => handleExportClick(customer.discountDRZ?.current?.id)}
              loading={downloadProductsGroupPending}
              disabled={downloadProductsGroupPending || !customer.discountDRZ || !customer.discountDRZ.current}
            >
              Eksportuj
            </Button>
          </div>

          {renderField('discountDRZ', {
            label: 'Wartość rabatu %',
            type: 'number',
            min: 0,
            readOnly: true,
            loadMapper: (value) => value.current.percentage,
          })}
        </>
      ) : (
        <div style={{ marginBottom: 15 }}>
          <div style={{ marginBottom: 25 }}>{translations.format('app.customer.additional-discount.description1')}</div>
          <div style={{ marginBottom: 25 }}>
            {translations.format('app.customer.additional-discount.description2-1')}
            <strong style={{ fontWeight: 800 }}>{translations.format('app.button.add')}</strong>
            {translations.format('app.customer.additional-discount.description2-2')}
          </div>
        </div>
      )}

      <div style={{ marginBottom: 13, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <AdditionalDiscountCodeHistoryModal
          customer={customer}
          downloadProductGroup={downloadProductGroup}
          downloadProductGroupPending={downloadProductsGroupPending}
          history={customer.discountDRZ?.history}
          triggerButton={
            <Button primary style={{ width: 100 }}>
              {translations.format('app.customer.history')}
            </Button>
          }
        />

        <AdditionalDiscountCodeModal
          customer={customer}
          saveAdditionalDiscountPending={saveAdditionalDiscountPending}
          saveAdditionalDiscount={saveAdditionalDiscount}
          triggerButton={
            <Button primary disabled={!editMode || !userHaveLdcCustomerAdditionalDiscountEdit} style={{ width: 100 }}>
              {translations.format('app.button.add')}
            </Button>
          }
        />
      </div>
      {editMode && !userHaveLdcCustomerAdditionalDiscountEdit && (
        <div style={{ fontStyle: 'italic', textAlign: 'center' }}>
          {translations.format('app.customer.additional-discount.no-role-acces-info')}
        </div>
      )}
    </>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ customers }: ApplicationState) => ({
  downloadProductsGroupPending: customers.downloadProductGroupPending,
  saveAdditionalDiscountPending: customers.saveAdditionalDiscountPending,
});

const mapDispatchToProps: TReduxActions = { downloadProductGroup, saveAdditionalDiscount };

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDiscountCodeCard);
