import { useCallback } from "react";
import { TLine } from "../AddCLaimTypes";
import { ClaimCategory, ClaimGroup } from "../../claimTypes";

type Props = {
  lines: TLine[];
  setLines: (value: React.SetStateAction<TLine[]>) => void;
  group: ClaimGroup;
  category: ClaimCategory;
  type: ClaimGroup;
  description: string;
  invoice: string;
  customer: string;
  vehicleModel: string;
  vin: string;
  bin: string;
  pamir: string;
  order: string;
  delivery: string;
};

export const useAddClaimValidation = ({
  lines,
  setLines,
  group,
  category,
  type,
  description,
  invoice,
  customer,
  vin,
  bin,
  pamir,
  order,
  vehicleModel,
  delivery,
}: Props) => {
  const isInvalidLine = useCallback(
    (line: any): boolean | string => {
      if (group === ClaimGroup.provision) {
        return line.quantity < 0 || (line.selected && !line.quantity);
      }
      if (isNaN(line.quantity) || line.quantity === 0) {
        return true;
      }

      if (category === ClaimCategory.newPartReturn) {
        const packingForceQuantity =
          line.packingForceQuantity === null ? 1 : line.packingForceQuantity;
        if (line.quantity % packingForceQuantity !== 0) {
          return `Możliwość zwrotu tylko w paczkach (${packingForceQuantity} sztuk)`;
        }
      }

      return (
        line.quantityOrgin &&
        group !== ClaimGroup.notRealtedToInvoice &&
        line.quantity > line.quantityOrgin &&
        `Przekroczono dozwoloną ilość ${line.quantityOrgin}`
      );
    },
    [category, group]
  );

  const isInvalidPNRPrice = useCallback(
    (line: any): boolean => {
      return (
        group === ClaimGroup.notRealtedToInvoice &&
        (!line.pnrPrice || line.pnrPrice > line.pvpPrice)
      );
    },
    [group]
  );

  const renderPNRErrorMessage = useCallback((line: any): string => {
    if (!line.pnrPrice) {
      return "Wartość powinna być większa od 0";
    } else if (line.pnrPrice > line.pvpPrice) {
      return "Cena PNR nie może być większa od ceny PVP";
    } else {
      return "";
    }
  }, []);

  const isInvalidPVPPrice = useCallback(
    (line: any): boolean | string => {
      return group === ClaimGroup.notRealtedToInvoice && !line.pvpPrice;
    },
    [group]
  );

  const validLines = useCallback((): boolean => {
    if (lines.filter((item) => item.referenceId?.length).length === 0) {
      return false;
    }
    if (
      group === ClaimGroup.provision &&
      lines.filter((item) => item.selected).length > 1
    ) {
      return false;
    }

    const someInvalid: boolean = lines.some((line) => {
      if (
        line.selected &&
        group === ClaimGroup.notRealtedToInvoice &&
        line.referenceId === ""
      ) {
        return true;
      }
      return !!(line.selected && isInvalidLine(line));
    });

    return someInvalid ? false : lines.some((line) => line.selected);
  }, [lines, group, isInvalidLine]);

  const validPrices = useCallback((): boolean => {
    let resoult = true;
    lines.forEach((line) => {
      const isDFSCategory =
        line.categoryOrigin && ["2", "4", "5"].includes(line.categoryOrigin);
      if (isDFSCategory && (!line.pvpPrice || !line.pnrPrice)) {
        resoult = false;
      }
      if (isDFSCategory && isInvalidPNRPrice(line)) {
        resoult = false;
      }
    });
    return resoult;
  }, [lines, isInvalidPNRPrice]);

  const validClaimesQuantityRealted = useCallback((): boolean => {
    return !!lines.find(
      (line) => line.quantityOrgin && line.quantity > line.quantityOrgin
    );
  }, [lines]);

  const validFormMessage = useCallback((): string | undefined => {
    if (
      group === ClaimGroup.realtedToInvoice ||
      group === ClaimGroup.notRealtedToInvoice
    ) {
      if (category === "") {
        return "Wymagane wybranie kategorii";
      } else if (type === "") {
        return "Wymagane wybranie typu";
      } else if (description === "") {
        return "Opis nie może być pusty";
      } else if (!!lines.find((line) => !line.quantity)) {
        return "Reklamowana ilość powinna być większa od zera";
      }
    }

    switch (group) {
      case ClaimGroup.realtedToInvoice:
        if (invoice === "") {
          return "Wymagane wybranie faktury";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (validClaimesQuantityRealted()) {
          return "Reklamowana ilość jest niewłaściwa";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        }
        break;
      case ClaimGroup.notRealtedToInvoice:
        if (customer === "") {
          return "Wybranie płatnika jest wymagane";
        } else if (delivery === "") {
          return "Wybranie dostawy jest wymagane";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        } else if (!validPrices()) {
          return "Wartości cen PVP/PNR są nieprawidłowe";
        }
        break;
      case "RK3":
        if (order === "") {
          return "Wymagane wybranie powiązanego zamówienia";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === "RK3_Z1" &&
          (vin === "" || vehicleModel === "")
        ) {
          return "Wymagane wypełnienie danych samochodu";
        } else if (!validLines()) {
          return "Przynajmniej jedna linia reklamacji musi być zaznaczona";
        }
        break;
      case ClaimGroup.provision:
        if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          vin.length < 17
        ) {
          return "Numer VIN jest wymagany i musi składać się z 17 znaków";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          !bin.length
        ) {
          return "Numer BIN jest wymagany";
        } else if (
          category === ClaimCategory.tractionBatteryReturn &&
          !pamir.length
        ) {
          return "Numer PAMIR jest wymagany";
        } else if (
          lines.filter((line) => line.selected === true).length === 0
        ) {
          return "Zaznaczenie jednej linii reklamacji jest wymagane";
        } else if (lines.filter((line) => line.selected === true).length > 10) {
          return "Maksymalna ilość reklamacji nie może przekraczać 10 linii";
        }
        break;
      case ClaimGroup.withoutInvoice:
        if (customer === "") {
          return "Wybranie płatnika jest wymagane";
        } else if (delivery === "") {
          return "Wybranie dostawy jest wymagane";
        } else if (category === "") {
          return "Wymagane wybranie kategorii";
        } else if (description === "") {
          return "Opis nie może być pusty";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          vin.length < 17
        ) {
          return "Numer VIN jest wymagany i musi składać się z 17 znaków";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          !bin.length
        ) {
          return "Numer BIN jest wymagany";
        } else if (
          category === ClaimCategory.batteryWithoutInvoice &&
          !pamir.length
        ) {
          return "Numer PAMIR jest wymagany";
        } else if (
          lines.filter((line) => line.selected === true).length === 0
        ) {
          return "Zaznaczenie jednej linii reklamacji jest wymagane";
        } else if (lines.filter((line) => line.selected === true).length > 10) {
          return "Maksymalna ilość reklamacji nie może przekraczać 10 linii";
        }
        break;
      default:
        return "Wymagane wybranie rodzaju składanej reklamacji";
    }
  }, [
    bin.length,
    category,
    customer,
    delivery,
    description,
    group,
    invoice,
    lines,
    order,
    pamir.length,
    type,
    validClaimesQuantityRealted,
    validLines,
    validPrices,
    vehicleModel,
    vin,
  ]);

  const isValidForm = useCallback((): boolean => {
    if (
      type === "" &&
      group !== ClaimGroup.provision &&
      group !== ClaimGroup.withoutInvoice
    ) {
      return false;
    }
    if (group === "") {
      return false;
    } else if (group === ClaimGroup.realtedToInvoice) {
      return invoice === "" || category === "" || description === ""
        ? false
        : validLines();
    } else if (group === ClaimGroup.notRealtedToInvoice) {
      return customer === "" ||
        delivery === "" ||
        category === "" ||
        description === ""
        ? false
        : validLines() && validPrices();
    } else if (group === "RK3") {
      if (order === "" || category === "" || description === "") {
        return false;
      } else if (
        category !== "RK3_Z1" &&
        category !== "RK3_Z2" &&
        type === ""
      ) {
        return false;
      } else if (category === "RK3_Z1") {
        return vin === "" || vehicleModel === "" ? false : validLines();
      } else if (category === "RK3_Z2") {
        return validLines();
      } else {
        return true;
      }
    } else if (group === ClaimGroup.provision) {
      if (
        category === ClaimCategory.tractionBatteryReturn &&
        (vin.length < 17 || !bin.length || !pamir.length)
      ) {
        return false;
      }

      if (
        invoice === "" ||
        category === "" ||
        description === "" ||
        !validLines()
      ) {
        return false;
      } else return true;
    } else if (group === ClaimGroup.withoutInvoice) {
      if (
        delivery === "" ||
        category === "" ||
        description === "" ||
        !validLines()
      ) {
        return false;
      }

      if (
        category === ClaimCategory.batteryWithoutInvoice &&
        (vin.length < 17 || !bin.length || !pamir.length)
      ) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }, [
    bin.length,
    category,
    customer,
    delivery,
    description,
    group,
    invoice,
    order,
    pamir.length,
    type,
    validLines,
    validPrices,
    vehicleModel,
    vin,
  ]);

  const validNumber = useCallback((value: any): boolean => {
    if (isNaN(value)) {
      return false;
    }
    return typeof value === "number";
  }, []);

  return {
    isInvalidLine,
    isInvalidPNRPrice,
    renderPNRErrorMessage,
    isInvalidPVPPrice,
    validFormMessage,
    isValidForm,
    validNumber,
  };
};
