import { SmartLabel } from '~/components/SmartField/SmartLabel';
import { parsePrice } from '~/utils/parsePrice';
import DetailCard from '~/components/DetailCard/DetailCard';
import React from 'react';
import { CardDetailsLimitProps } from '~/pages/Orders/lib/Add/Limit/constants';

const CardDetailsLimit = ({ selectedCustomer, deliveryPoint }: CardDetailsLimitProps) => {
  return (
    <DetailCard title="app.payer.limits" id="limits" key="limits" minHeight={40} width={3} smallWidth={5.33}>
      <SmartLabel
        key="distrigoCredit"
        label="Limit kredytu Distrigo"
        value={parsePrice(
          selectedCustomer && deliveryPoint ? selectedCustomer.detail.paymentDistrigoCredits?.currentLimitTotal : 0
        )}
      />
      {selectedCustomer && deliveryPoint ? (
        <SmartLabel
          key={`factor`}
          label={`Faktor`}
          value={parsePrice(
            selectedCustomer.detail.availableFactorTotalLimit ? selectedCustomer.detail.availableFactorTotalLimit : 0
          )}
        />
      ) : null}
      <SmartLabel
        key="totalLimit"
        label="Suma"
        value={parsePrice(selectedCustomer && deliveryPoint ? selectedCustomer?.detail.currentTotalLimit : 0)}
      />
    </DetailCard>
  );
};

export default CardDetailsLimit;
