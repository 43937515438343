import { action } from "typesafe-actions";
import {
  AvailableRole,
  AvailableRolesInGroups,
  PasswordPolicy,
  User,
  UserBase,
  UsersActionTypes,
} from "./types";
import { Paginable } from "../types";

export const fetchUsers = (params: any, forClientPanel?: boolean) =>
  action(UsersActionTypes.FETCH_USERS_REQUEST, params, forClientPanel);
export const fetchUsersError = () => action(UsersActionTypes.FETCH_USERS_ERROR);

export const fetchUsersSuccess = (users: Paginable<User>) =>
  action(UsersActionTypes.FETCH_USERS_SUCCESS, { users });

export const addUser = (user: UserBase, forClientPanel?: boolean) =>
  action(UsersActionTypes.ADD_USER, user, forClientPanel);

export const addSuccess = () => action(UsersActionTypes.ADD_SUCCESS);

export const clearCreated = () => action(UsersActionTypes.CLEAR_CREATED);

export const enableUser = (
  userId: string,
  enable: boolean,
  forClientPanel?: boolean
) => action(UsersActionTypes.ENABLE_USER, { userId, enable }, forClientPanel);

export const removeUser = (userId: string, forClientPanel?: boolean) =>
  action(UsersActionTypes.REMOVE_USER, userId, forClientPanel);

export const fetchAvailableRoles = (userId: string, forClientPanel?: boolean) =>
  action(UsersActionTypes.FETCH_AVAILABLE_ROLES, userId, forClientPanel);

export const fetchAvailableRolesSuccess = (
  availableRoles: AvailableRolesInGroups
) => action(UsersActionTypes.FETCH_AVAILABLE_ROLES_SUCCESS, { availableRoles });

export const fetchAvailableRolesFailed = () =>
  action(UsersActionTypes.FETCH_AVAILABLE_ROLES_FAILED);

export const fetchUserRoles = (userId: string, forClientPanel?: boolean) =>
  action(UsersActionTypes.FETCH_USER_ROLES, userId, forClientPanel);

export const fetchUserRolesSuccess = (userRoles: AvailableRolesInGroups) =>
  action(UsersActionTypes.FETCH_USER_ROLES_SUCCESS, { userRoles });

export const fetchUserRolesFailed = () =>
  action(UsersActionTypes.FETCH_USER_ROLES_FAILED);

export const changeUserRolesAndLimit = (
  userId: string,
  dataToSend: {
    usersDiscountLimit: number;
    rolesToAdd: AvailableRole[];
    rolesToRemove: AvailableRole[];
  },
  cb: () => void,
  forClientPanel?: boolean
) =>
  action(
    UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT,
    {
      userId,
      dataToSend,
      cb,
    },
    forClientPanel
  );

export const changeUserRolesAndLimitSuccess = () =>
  action(UsersActionTypes.CHANGE_USER_ROLES_AND_LIMIT_SUCCESS);

export const actionSuccess = () => action(UsersActionTypes.ACTION_SUCCESS);

export const fetchPasswordPolicies = (forClientPanel?: boolean) =>
  action(UsersActionTypes.FETCH_PASSWORD_POLICIES, null, forClientPanel);

export const fetchPasswordPoliciesSuccess = (
  passwordPolicies: PasswordPolicy[]
) =>
  action(UsersActionTypes.FETCH_PASSWORD_POLICIES_SUCCESS, {
    passwordPolicies,
  });

export const exportUserListToXls = (fileName: string) =>
  action(UsersActionTypes.EXPORT_USER_LIST_TO_XLS, fileName);

export const exportFP0536ToCsv = (fileName: string) =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV, fileName);

export const exportFP0536ToCsvSuccesss = () =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV_SUCCESS);

export const exportFP0536ToCsvFailed = () =>
  action(UsersActionTypes.DOWNLOAD_FP0536_CSV_FAILED);

export const fetchUserById = (id: string, forClientPanel?: boolean) =>
  action(UsersActionTypes.FETCH_USER_BY_ID, { id }, forClientPanel);

export const fetchUserByIdSuccess = (user: User) =>
  action(UsersActionTypes.FETCH_USER_BY_ID_SUCCESS, {
    user,
  });

export const fetchUserByIdFailed = () =>
  action(UsersActionTypes.FETCH_USER_BY_ID_FAILED);

export const resetUser = () => action(UsersActionTypes.RESET_USER);

export const fetchClientPanelDeliveryPoints = () =>
  action(UsersActionTypes.FETCH_DELIVERY_POINTS);

export const fetchClientPanelDeliveryPointsSuccess = (deliveryPoints: any[]) =>
  action(UsersActionTypes.FETCH_DELIVERY_POINTS_SUCCESS, deliveryPoints);
