import { FormattedMessage } from 'react-intl'
import { Button } from 'semantic-ui-react'

import React from 'react'
import PageHeader from '~/components/PageHeader/PageHeader'
import translations from '~/utils/translations'

const Reports: React.FC = (): JSX.Element => {
    document.title = translations.format("app.reports")
    return <>
        <PageHeader
            icon="chart bar"
            title={<FormattedMessage id="app.reports"/>}
            breadcrumb={[
                { text: "Raporty Power BI" },]}
            buttons={[]}
            loading={false}
        />
        <div style={{ textAlign: 'center', paddingTop: 'calc(50vh - 250px)' }}>
            <Button basic size="massive" as={"a"} href={"https://app.powerbi.com/"} style={{ padding: '50px 80px' }}>
                <svg id="powerbilogo" viewBox="0 0 95.695 71.629"
                     style={{ fill: '#f2c812', maxWidth: 100, display: 'block', margin: '5 auto 20' }}>
                    <path
                        d="M931.981,425.684h-1.93v-3.86h1.93a7.436,7.436,0,0,0,7.427-7.427V374.972a7.436,7.436,0,0,0-7.427-7.428H858.859a7.436,7.436,0,0,0-7.427,7.428V414.4a7.436,7.436,0,0,0,7.427,7.427h1.93v3.86h-1.93A11.3,11.3,0,0,1,847.573,414.4V374.972a11.3,11.3,0,0,1,11.287-11.287h73.121a11.3,11.3,0,0,1,11.287,11.287V414.4a11.3,11.3,0,0,1-11.287,11.287"
                        transform="translate(-847.573 -363.685)"/>
                    <path
                        d="M23.14,49.077h0a5.237,5.237,0,0,1,5.237,5.237V66.391a5.238,5.238,0,0,1-5.238,5.238h0A5.237,5.237,0,0,1,17.9,66.392V54.315a5.238,5.238,0,0,1,5.238-5.238Z"/>
                    <path
                        d="M887.186,435.315a5.239,5.239,0,0,1-5.239-5.238l0-31a5.238,5.238,0,0,1,10.477,0l0,31a5.239,5.239,0,0,1-5.238,5.239"
                        transform="translate(-847.573 -363.685)"/>
                    <path
                        d="M920.13,435.162a5.239,5.239,0,0,1-5.239-5.238l0-43.9a5.238,5.238,0,1,1,10.477,0l0,43.9a5.239,5.239,0,0,1-5.238,5.239"
                        transform="translate(-847.573 -363.685)"/>
                    <path
                        d="M903.658,435.315a5.239,5.239,0,0,1-5.239-5.238l0-23.029a5.238,5.238,0,1,1,10.477,0l0,23.029a5.239,5.239,0,0,1-5.238,5.239"
                        transform="translate(-847.573 -363.685)"/>
                </svg>
                <Button.Content>
                    <div>Raporty dostępne są w usłudze Power BI</div>
                    <div style={{ marginTop: 15, fontSize: '0.6em' }}>Kliknij tutaj, aby kontynuować</div>
                </Button.Content>
            </Button>
        </div>
    </>
}

export default Reports