import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import React from "react";
import { CustomerListDropdownProps } from "~/pages/Customers/constants";
import { useCustomersRolesRights } from "./hooks/useCustomersRights";

const CustomerListDropdown = ({
  customer,
  userHaveLdcUiCustomerEditRole,
}: CustomerListDropdownProps) => {
  const {
    userHaveLdcClaimsViewRole,
    userHaveLdcOrderViewRole,
    userHaveLdcAccountingViewRole,
  } = useCustomersRolesRights();
  return (
    <TableMoreDropdown>
      <Dropdown.Item
        as={Link}
        to={`/customer/${customer.rrdi}`}
        text={<FormattedMessage id="app.dropdown.viewCustomer" />}
      />
      {userHaveLdcUiCustomerEditRole && (
        <Dropdown.Item
          as={Link}
          to={`/customer/${customer.rrdi}/edit`}
          text={<FormattedMessage id="app.dropdown.editCustomer" />}
        />
      )}
      {userHaveLdcUiCustomerEditRole && userHaveLdcOrderViewRole && (
        <Dropdown.Divider />
      )}
      {userHaveLdcOrderViewRole && (
        <>
          <Dropdown.Item
            as={Link}
            to={`/order/create?cId=${customer && customer.rrdi}&dId=${
              customer && customer.clientId !== null ? customer.rrdi : ""
            }`}
            text={<FormattedMessage id="app.dropdown.createOrder" />}
          />
          <Dropdown.Item
            as={Link}
            to={`/orders?customer.rrid=${
              customer && customer.clientId
            }&deliveryCustomer.rrid=${customer && customer.rrdi}`}
            text={<FormattedMessage id="app.dropdown.orderList" />}
          />
        </>
      )}
      {userHaveLdcOrderViewRole && userHaveLdcClaimsViewRole && (
        <Dropdown.Divider />
      )}
      {userHaveLdcClaimsViewRole && (
        <Dropdown.Item
          as={Link}
          to={`/claims?orderCustomerID=${
            customer && customer.clientId !== null ? customer.rrdi : ""
          }&accountingCustomerID=${
            customer.clientId !== null
              ? customer.clientId
              : customer && customer.rrdi
          }`}
          text={<FormattedMessage id="app.dropdown.claimsList" />}
        />
      )}
      {userHaveLdcClaimsViewRole && userHaveLdcAccountingViewRole && (
        <Dropdown.Divider />
      )}
      {userHaveLdcAccountingViewRole && (
        <Dropdown.Item
          as={Link}
          to={`/accounting?accountingCustomer.taxCode=${customer.accountingVatId}`}
          text={<FormattedMessage id="app.dropdown.accountingDocs" />}
        />
      )}
    </TableMoreDropdown>
  );
};

export default CustomerListDropdown;
