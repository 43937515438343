import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import { getInvoicePdf } from "~/store/invoices/actions";

type TProps = {
  invoiceCorrectionNumberList: {
    invoiceCorrectionNumber: string;
    invoiceCorrectionDocumentMask: string;
  }[];
  getInvoicePdf: typeof getInvoicePdf;
};

export const InvoiceCorrectionNumberList: React.FC<TProps> = ({
  invoiceCorrectionNumberList,
  getInvoicePdf,
}) => {
  return (
    <Popup
      position="bottom right"
      size="small"
      flowing
      style={{ zIndex: 999 }}
      content={
        invoiceCorrectionNumberList.length === 0
          ? "Brak korekt"
          : invoiceCorrectionNumberList.map((correction) => (
              <Button
                onClick={() =>
                  getInvoicePdf(
                    correction.invoiceCorrectionNumber,
                    `${correction.invoiceCorrectionDocumentMask}.pdf`
                  )
                }
                style={{ display: "block" }}
                compact
                basic
              >
                {correction.invoiceCorrectionDocumentMask}
              </Button>
            ))
      }
      on="click"
      trigger={
        <span>
          {invoiceCorrectionNumberList.length}
          <Icon.Group
            size="large"
            style={{
              marginLeft: 10,
              cursor: "pointer",
              opacity: !invoiceCorrectionNumberList.length ? 0.2 : 1,
            }}
          >
            <Icon name="circle outline" fitted />
            <Icon name="info" size="mini" fitted />
          </Icon.Group>
        </span>
      }
    />
  );
};
