import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Divider } from "semantic-ui-react";
import {
  DictionariesState,
  DictionaryItem,
  DictionaryName,
} from "~/store/dictionaries/types";
import {
  changeStatusOrder,
  exportOrdersOnRequest,
  fetchOrders,
  openChooseTypeOfExportDataModal,
  saveDataFromSearchForm,
  saveDataToSearchForm,
  saveReferenceIdSearchForm,
  setTypeOfExportData,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  uploadCancelLines,
  uploadCancelLinesClear,
} from "~/store/orders/actions";
import { Order, OrderBonusImportLine } from "~/store/orders/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import { fetchDictionary } from "~/store/dictionaries/actions";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PageHeader from "~/components/PageHeader/PageHeader";
import OrdersList from "./List";
import translations from "~/utils/translations";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { UploadBonusOrdersModal } from "./lib/Modals";
import CancelOrder from "./lib/CancelOrder/CancelOrder";
import AdvancedSearch from "~/components/AdvancedSearch/AdvancedSearch";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useOrdersColumns } from "./hooks/useOrdersColumns";
import { useOrdersRolesRights } from "./hooks/useOrdersRolesRights";
import { useOrdersClientPanelButtons } from "./hooks/useOrdersClientPanelButtons";
import { useOrdersClientPanelAdvancedSearch } from "./hooks/useOrdersClientPanelAdvancedSearch";
import { selectCustomer } from "~/store/customer/actions";
import { CustomerSelected } from "~/store/customer/types";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { useCells } from "~/services/useCells";

interface PropsFromState {
  orders: Order[];
  loading: boolean;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  orderBonusLines: OrderBonusImportLine[];
  loadingBonusOrder: boolean;
  loadingCancelLines: boolean;
  totalRecords: TotalRecords;
  selectedTypeOfDataToExport?: string;
  isOpenChooseTypeOfExportDataModal?: boolean;
  selectedCustomer: CustomerSelected | undefined;
  exportPending: boolean | undefined;
}

interface PropsFromDispatch {
  fetchOrders: typeof fetchOrders;
  changeStatusOrder: typeof changeStatusOrder;
  fetchDictionary: typeof fetchDictionary;
  saveReferenceIdSearchForm: typeof saveReferenceIdSearchForm;
  saveDataFromSearchForm: typeof saveDataFromSearchForm;
  saveDataToSearchForm: typeof saveDataToSearchForm;
  uploadBonusOrders: typeof uploadBonusOrders;
  uploadBonusOrdersClear: typeof uploadBonusOrdersClear;
  uploadCancelLines: typeof uploadCancelLines;
  uploadCancelLinesClear: typeof uploadCancelLinesClear;
  openChooseTypeOfExportDataModal: typeof openChooseTypeOfExportDataModal;
  setTypeOfExportData: typeof setTypeOfExportData;
  selectCustomer: typeof selectCustomer;
  exportOrdersOnRequest: typeof exportOrdersOnRequest;
}

type OrderProps = PropsFromState &
  PropsFromDispatch &
  ConnectedReduxProps &
  RouteComponentProps;

const OrdersClientPanel: React.FC<OrderProps> = ({
  dictionaries,
  loading,
  loadingBonusOrder,
  meta,
  orders,
  orderBonusLines,
  totalRecords,
  selectedCustomer,
  exportPending,
  changeStatusOrder,
  fetchDictionary,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  fetchOrders,
  selectCustomer,
  exportOrdersOnRequest,
}) => {
  const { clientPanelColumnsConfig } = useOrdersColumns();
  const { payerId, hasRole } = useClientPanelRoles();

  const [isOpen, setIsOpen] = useState(false);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [tableKey] = useState<string>("ordersCP");
  const [sortColumn] = useState<any>("orderDate");
  const [sortDirection] = useState<any>("DESC");
  const [openCancelOrderPage, setOpenCancelOrderPage] =
    useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [columns, setColumns] = useState(clientPanelColumnsConfig);

  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const [deliveryAddresses, setDeliveryAddresses] = useState<DictionaryItem[]>(
    []
  );

  const { userHaveLdcUiOrderCloseUnrealizedRole } = useOrdersRolesRights();

  const getOrders = useCallback(
    (items?: Object) => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };

      setParams(paramsObj);

      fetchOrders(Object.assign(paramsObj, items), true);
    },
    [
      filterLabelsRef,
      setParams,
      fetchOrders,
      meta.page,
      meta.size,
      sortColumn,
      sortDirection,
    ]
  );

  const handleExport = useCallback(() => {
    exportOrdersOnRequest(params);
  }, [exportOrdersOnRequest, params]);

  const buttons = useOrdersClientPanelButtons({
    setOpenUploadModal,
    hasRole,
    handleExport,
    exportPending,
  });

  useEffect(() => {
    if (payerId) {
      selectCustomer(payerId, true);
    }
  }, [payerId, selectCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      setDeliveryAddresses([
        { text: "-", value: "", key: "" },
        ...selectedCustomer.deliveryAddresses.map((item) => ({
          value: item.rrdi,
          text:
            item.rrdi +
            " - " +
            (item.company || "") +
            (" " + item.city || "") +
            (" " + item.street || ""),
          key: item.rrdi,
        })),
      ]);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { formFields, handleSubmit } = useOrdersClientPanelAdvancedSearch(
    columns,
    setColumns,
    sortColumn,
    sortDirection,
    getOrders,
    filterLabelsRef,
    tableKey,
    deliveryAddresses
  );

  useEffect(() => {
    document.title = translations.format("app.orders");
    fetchDictionary(
      DictionaryName.orderDeliveryType,
      DictionaryName.paymentMethod,
      DictionaryName.orderType,
      DictionaryName.panelOrderStatus
    );
  }, [fetchDictionary]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  const handleCloseAndClearUploadModal = (): void => {
    setOpenUploadModal(false);
    getOrders(params);
    uploadBonusOrdersClear();
  };

  return (
    <Fragment>
      <PageHeader
        icon="shop"
        title={<FormattedMessage id="app.orders" />}
        breadcrumb={[{ text: <FormattedMessage id="app.list" /> }]}
        buttons={buttons}
        refreshAction={() => {
          getOrders({ ...params, page: 1 });
        }}
        loading={loading}
      />
      <AdvancedSearch
        handleSubmit={handleSubmit}
        formFields={formFields}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></AdvancedSearch>
      <Divider />
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={isOpen}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getOrders}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        <OrdersList
          orders={orders}
          changeStatusOrder={changeStatusOrder}
          columns={columns}
          cancelRole={userHaveLdcUiOrderCloseUnrealizedRole}
        />
      </DataGridTable>

      <UploadBonusOrdersModal
        open={openUploadModal}
        orderBonusLines={orderBonusLines}
        uploadBonusOrders={uploadBonusOrders}
        loading={loadingBonusOrder}
        closeHandler={handleCloseAndClearUploadModal}
      />
      {openCancelOrderPage && (
        <CancelOrder
          openCancelOrderModal={openCancelOrderPage}
          closeModal={() => setOpenCancelOrderPage(false)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  orders,
  customers,
  dictionaries,
}: ApplicationState): PropsFromState => ({
  orders: orders.list,
  orderBonusLines: orders.orderBonusLines,
  meta: orders.meta,
  loading: orders.loadingOrders,
  loadingBonusOrder: orders.loadingBonusOrder,
  loadingCancelLines: orders.loadingCancelLines,
  totalRecords: orders.totalRecords,
  dictionaries,
  selectedTypeOfDataToExport: orders.selectedTypeOfDataToExport,
  isOpenChooseTypeOfExportDataModal: orders.isOpenChooseTypeOfExportDataModal,
  selectedCustomer: customers.selected,
  exportPending: orders.exportOnRequestPending,
});

const mapDispatchToProps: PropsFromDispatch = {
  fetchOrders,
  changeStatusOrder,
  fetchDictionary,
  saveReferenceIdSearchForm,
  saveDataFromSearchForm,
  saveDataToSearchForm,
  uploadBonusOrders,
  uploadBonusOrdersClear,
  uploadCancelLines,
  uploadCancelLinesClear,
  openChooseTypeOfExportDataModal,
  setTypeOfExportData,
  selectCustomer,
  exportOrdersOnRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrdersClientPanel));
