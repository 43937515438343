import React, { useCallback } from "react";
import { Table } from "semantic-ui-react";
import { Anomaly } from "~/store/anomalies/types";
import TableNoResults from "~/components/TableNoResults/TableNoResults";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";
import { TColumn } from "~/components/Table/lib/types";

interface Props {
  anomalies: Anomaly[];
  columns: TColumn[];
}

const AnomaliesList: React.FC<Props> = ({ anomalies, columns }) => {
  const anomaliesListDropdown = useCallback((anomaly: Anomaly): JSX.Element => {
    return <TablePreviewDetails path={`/anomaly/${anomaly.anomalyID}`} />;
  }, []);

  return anomalies && anomalies.length > 0 ? (
    <>
      {anomalies.map((anomaly) => (
        <Table.Row key={anomaly.anomalyID}>
          {columns.map(
            (column) =>
              column.projection &&
              column.getCell &&
              column.getCell(anomaly, column)
          )}
          <Table.Cell className="col-dropdown-menu-sticky">
            {anomaliesListDropdown(anomaly)}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  ) : (
    <TableNoResults />
  );
};

export default AnomaliesList;
