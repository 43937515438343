import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LibraryActionTypes, LibraryItem } from "./types";
import {
  addLibraryItem,
  addLibraryItemError,
  addLibraryItemSuccess,
  deleteLibraryItem,
  deleteLibraryItemError,
  deleteLibraryItemSuccess,
  fetchLibrary,
  fetchLibraryError,
  fetchLibraryItem,
  fetchLibraryItemError,
  fetchLibraryItemSuccess,
  fetchLibrarySuccess,
} from "./actions";
import {
  callApi,
  callApiDownload,
  callApiUploadFileMultipart,
} from "~/utils/api";
import { handleError } from "~/store/error/actions";
import { Paginable } from "../types";
import { toastSuccess } from "~/utils/toast";
import { downloadFile } from "~/utils/downloadFile";

function* handleFetchLibrary(action: ReturnType<typeof fetchLibrary>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      sort = `&sort=${action.payload.sortColumn.replace(".", "_")},${
        action.payload.sortDirection
      }`;
    }
    const library: Paginable<LibraryItem> = yield call(
      callApi,
      "get",
      `/customer-panel/library?page=${action.payload.page - 1}&size=${
        action.payload.size
      }${filters}${sort}`
    );
    yield put(fetchLibrarySuccess(library));
  } catch (error) {
    yield put(fetchLibraryError());
    yield put(
      handleError(error as Error, false, "Nie udało sie pobrać biblioteki")
    );
  }
}

function* handleFetchLibraryItem(action: ReturnType<typeof fetchLibraryItem>) {
  try {
    const res: Response = yield call(
      callApiDownload,
      "get",
      `/customer-panel/library/${action.payload.id}/download`,
      action.payload.fileName
    );

    downloadFile(res, action.payload.fileName);
    yield put(fetchLibraryItemSuccess());
  } catch (error) {
    yield put(fetchLibraryItemError());
    yield put(
      handleError(error as Error, false, "Nie udało sie pobrać pozycji")
    );
  }
}

function* handleDeleteLibraryItem(
  action: ReturnType<typeof deleteLibraryItem>
) {
  try {
    yield call(callApi, "DELETE", `/customer-panel/library/${action.payload}`);
    yield put(deleteLibraryItemSuccess());
  } catch (error) {
    yield put(deleteLibraryItemError());
    yield put(
      handleError(error as Error, false, "Nie udało sie usunąć pozycji")
    );
  }
}

function* handleAddLibraryItem(action: ReturnType<typeof addLibraryItem>) {
  try {
    yield call(
      callApiUploadFileMultipart,
      "/customer-panel/library",
      action.payload.newItem.file,
      "file",
      [
        { name: "description", value: action.payload.newItem.description },
        {
          name: "customerClass",
          value: action.payload.newItem.customerClasses,
        },
      ]
    );
    toastSuccess(
      "Sukces",
      `Plik ${action.payload.newItem.file.name} został dodany.`
    );
    yield put(addLibraryItemSuccess());
  } catch (e) {
    const error = e as Error;
    let errorMessage = error.message || "";
    if (error.message.includes("duplicated file name")) {
      errorMessage = "Plik o tej samej nazwie już istnieje. Zmień nazwę pliku.";
    }
    yield put(addLibraryItemError());
    yield put(
      handleError(
        error as Error,
        false,
        "Nie udało sie zapisać pozycji",
        errorMessage
      )
    );
  }
}

function* watchFetchLibrary() {
  yield takeEvery(LibraryActionTypes.FETCH_LIBRARY, handleFetchLibrary);
}

function* watchFetchLibraryItem() {
  yield takeEvery(
    LibraryActionTypes.FETCH_LIBRARY_ITEM,
    handleFetchLibraryItem
  );
}

function* watchDeleteLibraryItem() {
  yield takeEvery(
    LibraryActionTypes.DELETE_LIBRARY_ITEM,
    handleDeleteLibraryItem
  );
}

function* watchAddLibraryItem() {
  yield takeEvery(LibraryActionTypes.ADD_LIBRARY_ITEM, handleAddLibraryItem);
}

function* librarySaga() {
  yield all([
    fork(watchFetchLibrary),
    fork(watchFetchLibraryItem),
    fork(watchDeleteLibraryItem),
    fork(watchAddLibraryItem),
  ]);
}

export default librarySaga;
