import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useInternalCompsumptionsColumns = () => {
  const { getDateCell, getTextCell, getAmountCell, getDictionaryCell } =
    useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "status",
        label: "Status",
        projection: true,
        dictionaryName: DictionaryName.itemToInvoiceStatus,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "category",
        label: "Kategoria",
        projection: true,
        dictionaryName: DictionaryName.itemToInvoiceCategory,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "createTime",
        label: "Data utworzenia",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "invoiceTime",
        label: "Data zafakturowania",
        projection: true,
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "invoiceDocumentMask",
        label: "Faktura",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "deliveryCustomerId",
        label: "ID punktu dostawy",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "deliveryType",
        label: "Typ dostawy",
        dictionaryName: DictionaryName.orderDeliveryType,
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "orderNumber",
        label: "Numer zamówienia",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "serviceBoxInternalOrderNumber",
        label: "Numer ServiceBox",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "serviceBoxExternalOrderNumber",
        label: "Numer ServiceBox lokalny",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "sapOrderNumber",
        label: "Numer SAP",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "referenceId",
        label: "ID referencji",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "designation",
        label: "Opis",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "quantity",
        label: "Ilość",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "vin",
        label: "Vin",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "taxRate",
        label: "VAT",
        projection: false,
        getCell: (document, column) =>
          getTextCell(document, column, document.taxRate + "%"),
      },
      {
        name: "unitPriceNetBeforeDiscount",
        label: "Cena przed zniżką",
        projection: false,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "unitPriceNetAfterDiscount",
        label: "Cena po zniżce",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "pnrPrice",
        label: "Cena PNR",
        projection: false,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "promoCode",
        label: "Kod promocyjny",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "provisionId",
        label: "ID kaucji",
        projection: false,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceLines.orderNumber",
        label: "Numer zamówienia",
        projection: false,
        hide: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getDateCell, getTextCell, getAmountCell, getDictionaryCell]
  );

  return { columnsConfig };
};
