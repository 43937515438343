import { THeaderButton } from "~/components/PageHeader/lib/types";
import React, { Fragment } from "react";
import { Popup } from "semantic-ui-react";

export type HeaderPopupBtnProps = { trigger: JSX.Element, button: THeaderButton }

export const HeaderPopupBtn: React.FC<HeaderPopupBtnProps> = ({trigger, button}) => {
    return (
        <Fragment>
            <Popup trigger={<div style={{ display: "inline-block" }}>{trigger}</div>} position="bottom right">
                {button.popup}
            </Popup>
        </Fragment>
    );
}