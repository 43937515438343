import React, { Fragment, memo, SyntheticEvent, useCallback, useState } from 'react';
import { connect } from 'react-redux';

import {
    generateLIGFATCQRaport, generateSTKVTEIRaport, generateVENTESQRaport, generateSTKVTHRaport, saveDataFromIntoSpeederDateFrom,
    saveDataToIntoSpeederDateToInput, saveExactDateFromIntoSpeederDateFromInput, saveExactDateToIntoSpeederDateToInput
} from '~/store/speeder/actions';
import { ApplicationState } from '~/store';
import SpeederContent from './SpeederContent/SpeederContent';

interface HandleChange {
    [key: string]: any;
}

interface PropsFromState {
    speederDateFrom?: Date | null,
    speederDateTo?: Date | null,
    speederExactDateFrom?: Date | null,
    speederExactDateTo?: Date | null
}

interface PropsFromDispatch {
    generateLIGFATCQRaport: typeof generateLIGFATCQRaport,
    generateSTKVTEIRaport: typeof generateSTKVTEIRaport,
    generateSTKVTHRaport: typeof generateSTKVTHRaport,
    generateVENTESQRaport: typeof generateVENTESQRaport,
    saveDataFromIntoSpeederDateFrom: typeof saveDataFromIntoSpeederDateFrom,
    saveDataToIntoSpeederDateToInput: typeof saveDataToIntoSpeederDateToInput,
    saveExactDateFromIntoSpeederDateFromInput: typeof saveExactDateFromIntoSpeederDateFromInput,
    saveExactDateToIntoSpeederDateToInput: typeof saveExactDateToIntoSpeederDateToInput,
}

type SpeederProps = PropsFromState & PropsFromDispatch

const Speeder = ({ generateLIGFATCQRaport, generateSTKVTEIRaport, generateSTKVTHRaport, generateVENTESQRaport, saveExactDateFromIntoSpeederDateFromInput, saveExactDateToIntoSpeederDateToInput,
    saveDataFromIntoSpeederDateFrom, saveDataToIntoSpeederDateToInput, speederExactDateFrom, speederExactDateTo,
    speederDateFrom, speederDateTo }: SpeederProps): JSX.Element => {

    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [STKVTEIRaport, setSTKVTEIRaport] = useState<boolean>(false);
    const [STKVTHRaport, setSTKVTHRaport] = useState<boolean>(false);
    const [LIGFATCQRaport, setLIGFATCQRaport] = useState<boolean>(false);
    const [VENTESQRaport, setVENTESQRaport] = useState<boolean>(false);
    const [exactDateFrom, setExactDateFrom] = useState<Date | null>(null);
    const [exactDateTo, setExactDateTo] = useState<Date | null>(null);

    const handleChange = useCallback((e: SyntheticEvent<Element, Event> | undefined, { name, value }: HandleChange) => {
        switch (name) {
            case "dateFrom":
                setDateFrom(value);
                saveDataFromIntoSpeederDateFrom(value);
                break;
            case "dateTo":
                setDateTo(value);
                saveDataToIntoSpeederDateToInput(value);
                break;
            case "exactDateFrom":
                setExactDateFrom(value);
                saveExactDateFromIntoSpeederDateFromInput(value);
                break;
            case "exactDateTo":
                setExactDateTo(value);
                saveExactDateToIntoSpeederDateToInput(value);
                break;
            default:
                break;
        };
    }, [saveDataFromIntoSpeederDateFrom, saveDataToIntoSpeederDateToInput,
        saveExactDateFromIntoSpeederDateFromInput, saveExactDateToIntoSpeederDateToInput]);

    const handleSubmitGenerateSTKVTEIRaport = useCallback(() => { setLIGFATCQRaport(false); setSTKVTEIRaport(true); setSTKVTHRaport(false); setVENTESQRaport(false); generateSTKVTEIRaport() }, [generateSTKVTEIRaport])

    const handleSubmitGenerateSTKVTHRaport = useCallback(() => { setLIGFATCQRaport(false); setSTKVTEIRaport(false); setSTKVTHRaport(true); setVENTESQRaport(false); generateSTKVTHRaport() }, [generateSTKVTHRaport]);

    const handleSubmitGenerateLIGFACTQRaport = useCallback(() => {
        const tzzoffset: number = (new Date()).getTimezoneOffset() * 60000;
        const speederLIGFACTQDateFromWithoutOffset = new Date(Date.parse(String(speederDateFrom)) - tzzoffset);
        if (speederDateTo) {
            speederDateTo.setHours(23);
            speederDateTo.setMinutes(59);
            speederDateTo.setSeconds(59);
        }
        const speederLIGFACTQDateToWithoutOffset = new Date(Date.parse(String(speederDateTo)) - tzzoffset);
        const LIGFACTQData: object = {
            from: speederLIGFACTQDateFromWithoutOffset, to: speederLIGFACTQDateToWithoutOffset
        }
        generateLIGFATCQRaport(LIGFACTQData);
        setLIGFATCQRaport(true); setSTKVTEIRaport(false); setSTKVTHRaport(false); setVENTESQRaport(false);
        setDateFrom(null); setDateTo(null);
        saveDataFromIntoSpeederDateFrom(null); saveDataToIntoSpeederDateToInput(null);
    }, [speederDateFrom, speederDateTo, generateLIGFATCQRaport, saveDataFromIntoSpeederDateFrom, saveDataToIntoSpeederDateToInput]);

    const handleSubmitGenerateVENTESQRaport = useCallback(() => {
        const tzzoffset: number = (new Date()).getTimezoneOffset() * 60000;
        const speederVENTESQDateFromWithoutOffset = speederExactDateFrom ? new Date(Date.parse(String(speederExactDateFrom)) - tzzoffset) : exactDateFrom;
        if (speederExactDateTo) {
            speederExactDateTo.setHours(23);
            speederExactDateTo.setMinutes(59);
            speederExactDateTo.setSeconds(59);
        }
        const speederVENTESQDateToWithoutOffset = speederExactDateTo ? new Date(Date.parse(String(speederExactDateTo)) - tzzoffset) : exactDateTo;
        const VENTESQData: object = {
            from: speederVENTESQDateFromWithoutOffset, to: speederVENTESQDateToWithoutOffset
        }
        generateVENTESQRaport(VENTESQData);
        setLIGFATCQRaport(false); setSTKVTEIRaport(false); setSTKVTHRaport(false); setVENTESQRaport(true);
        setExactDateFrom(null); setExactDateTo(null);
        saveExactDateFromIntoSpeederDateFromInput(null); saveExactDateToIntoSpeederDateToInput(null);
    }, [exactDateFrom, exactDateTo, generateVENTESQRaport, saveExactDateFromIntoSpeederDateFromInput,
        saveExactDateToIntoSpeederDateToInput, speederExactDateFrom, speederExactDateTo]);

    return <Fragment>
        <SpeederContent
            dateFrom={dateFrom}
            dateTo={dateTo}
            exactDateFrom={exactDateFrom}
            exactDateTo={exactDateTo}
            handleChange={handleChange}
            handleSubmitGenerateSTKVTEIRaport={handleSubmitGenerateSTKVTEIRaport}
            handleSubmitGenerateSTKVTHRaport={handleSubmitGenerateSTKVTHRaport}
            handleSubmitGenerateLIGFACTQRaport={handleSubmitGenerateLIGFACTQRaport}
            handleSubmitGenerateVENTESQRaport={handleSubmitGenerateVENTESQRaport}
            LIGFATCQRaport={LIGFATCQRaport}
            STKVTEIRaport={STKVTEIRaport}
            STKVTHRaport={STKVTHRaport}
            VENTESQRaport={VENTESQRaport}
        />
    </Fragment>
};

const mapStateToProps = ({ speeder }: ApplicationState) => ({
    speederDateFrom: speeder.dateFrom,
    speederDateTo: speeder.dateTo,
    speederExactDateFrom: speeder.exactDateFrom,
    speederExactDateTo: speeder.exactDateTo
});

const mapDispatchToProps = {
    generateLIGFATCQRaport, generateSTKVTEIRaport, generateVENTESQRaport, generateSTKVTHRaport, saveDataFromIntoSpeederDateFrom,
    saveDataToIntoSpeederDateToInput, saveExactDateFromIntoSpeederDateFromInput, saveExactDateToIntoSpeederDateToInput
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(Speeder));
