import { connect } from 'react-redux'
import { Button, Header, Modal, Table, Grid, Container } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { selectInvoiceErp } from '~/store/invoices/actions'
import { IInvoiceErp, TCodes } from '~/store/invoices/types'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { parseDateNoTime } from '~/utils/dateUtils'

import React, { useState, useEffect } from 'react'
import DetailCard from '~/components/DetailCard/DetailCard'
import CommonLoader from '~/components/Loaders/CommonLoader'

type TReduxState = {
    invoiceErp?: IInvoiceErp
    loading: boolean
}

type TReduxActions = {
    selectInvoiceErp: typeof selectInvoiceErp
}

type TProps = {
    noteId: string
    triggerOpen?: boolean
    closeHandler: () => void
}

type TInternalConsumptionNotesDetailsProps = TReduxState & TReduxActions & TProps

const InvoiceErpDetails: React.FC<TInternalConsumptionNotesDetailsProps> = ({
    children, noteId, triggerOpen, invoiceErp, loading, selectInvoiceErp, closeHandler
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const { renderLabel } = useRenderingFunctions(invoiceErp, false)

    useEffect(() => {
        if (open) {
            selectInvoiceErp(noteId)
        }
    }, [open, noteId, selectInvoiceErp])

    useEffect(() => {
        if (triggerOpen) {
            setOpen(true)
        }
    }, [triggerOpen])

    const handleCancel = (): void => {
        setOpen(false)
        closeHandler()
    }

    const handleOpen = (): void => {
        setOpen(true)
    }

    const labels: { [key: string]: string } = {
        'vin': "VIN",
    }

    const renderCodes = (codes: TCodes): JSX.Element => {
        if (!codes) {
            return <></>
        }

        const description = Object.entries(codes)
            .filter(([code, entry]) => entry)
            .map(([code, entry]) => <small key={code} style={{ display: 'block' }}>{labels[code]}: {entry}</small>)
        return (
            <div style={{ display: 'inline-block', textAlign: 'left', verticalAlign: 'middle', lineHeight: '1em' }}>
                {description}
            </div>
        )
    }

    const renderLines = (lines: any[]): JSX.Element => {
        return <Table basic>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Zamówiona referencja</Table.HeaderCell>
                    <Table.HeaderCell>Dostarczona referencja</Table.HeaderCell>
                    <Table.HeaderCell>Zamówiona ilość</Table.HeaderCell>
                    <Table.HeaderCell>Dostarczona ilość</Table.HeaderCell>
                    <Table.HeaderCell>Kodowanie</Table.HeaderCell>
                    <Table.HeaderCell>Nr paczki</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {lines.length === 0 && <Table.Row>
                    <Table.Cell colSpan="14">
                        <Container fluid textAlign="center">
                            Brak elementów do wyświetlenia.
                        </Container>
                    </Table.Cell>
                </Table.Row>}
                {lines.map((line, index) =>
                    <Table.Row key={index}>
                        <Table.Cell>{line.orderedPartID}</Table.Cell>
                        <Table.Cell>{line.shippedPartID}</Table.Cell>
                        <Table.Cell>{line.orderedQuantity}</Table.Cell>
                        <Table.Cell>{line.deliveredQuantity}</Table.Cell>
                        <Table.Cell>{renderCodes({ vin: line.vin, cleCode: line.cleCode })}</Table.Cell>
                        <Table.Cell>{line.packageNumber || "-"}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    }

    return (
        <Modal
            size="large"
            trigger={children}
            open={open}
            onOpen={handleOpen}
            onClose={handleCancel}
            closeOnDimmerClick={false}>
            <Header icon='briefcase' content={invoiceErp ? `Zamówienie ERP: ${invoiceErp.invoiceNumber || invoiceErp.deliveryNumber}` : 'Wczytywanie...'} />
            <Modal.Content>
                <Grid style={{ marginBottom: 'unset' }} stretched>
                    <DetailCard title="app.details" id="details" key="details" width={16} smallWidth={16} minHeight={100}>
                        {invoiceErp && <Grid columns={2} divided stretched>
                            <Grid.Row>
                                <Grid.Column >
                                    {renderLabel("Numer faktury", invoiceErp.invoiceNumber || '-')}
                                    {renderLabel("Data wystawienia", invoiceErp.invoiceDate ? parseDateNoTime(invoiceErp.invoiceDate) : '-')}
                                    {renderLabel("Płatnik", invoiceErp.orderIssuerCustomerId ? invoiceErp.orderIssuerCustomerId : '-')}
                                    {renderLabel("Odbiorca", invoiceErp.shipToPartyId ? invoiceErp.shipToPartyId : '-')}
                                    {renderLabel("Dostawca", invoiceErp.salesOrganisation ? invoiceErp.salesOrganisation : '-')}
                                </Grid.Column>
                                <Grid.Column >

                                    {renderLabel("Numer dostawy", invoiceErp.deliveryNumber ? invoiceErp.deliveryNumber : '-')}
                                    {renderLabel("Data dostawy",
                                    (invoiceErp.sapInvoiceLines.length > 0 && invoiceErp.sapInvoiceLines[0].deliveryDate) 
                                    ? parseDateNoTime(invoiceErp.sapInvoiceLines[0].deliveryDate) 
                                    : '-')}
                                    {renderLabel("Typ dokumentu", invoiceErp.invoiceType ? invoiceErp.invoiceType : '-')}
                                    {renderLabel("Zamówienie LDC", invoiceErp.orderNumber ? invoiceErp.orderNumber : '-')}
                                    {renderLabel("Zamówienie ERP", invoiceErp.sapOrderNumber ? invoiceErp.sapOrderNumber : '-')}
                                    {renderLabel("Data Zamówienia ERP", invoiceErp.orderDate ? parseDateNoTime(invoiceErp.orderDate) : '-')}
                                    {renderLabel("Typ Zamówienia ERP", invoiceErp.orderType ? invoiceErp.orderType : '-')}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>}
                    </DetailCard>
                    <DetailCard title="app.list.parts" id="parts" key="parts" width={16} smallWidth={16} minHeight={100}>
                        <div style={invoiceErp && invoiceErp.sapInvoiceLines && invoiceErp.sapInvoiceLines.length > 6 ? { maxHeight: '250px', overflowY: 'scroll' } : {}}>
                            {invoiceErp && renderLines(invoiceErp.sapInvoiceLines || [])}
                        </div>
                    </DetailCard>
                </Grid >
                <CommonLoader loading={loading}/>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Zamknij" onClick={handleCancel} />
            </Modal.Actions>
        </Modal>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ invoices }: ApplicationState) => ({
    invoiceErp: invoices.invoicesErp.selected,
    loading: invoices.invoicesErp.loading
})

const mapDispatchToProps: TReduxActions = { selectInvoiceErp }

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceErpDetails)