import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { TPromotion } from '~/store/promotions/types'
import { REQUIRED } from '~/components/SmartField/lib/validator'

import React from 'react'
import MinimumCondition from './lib/Minimum'
import DetailCard from '~/components/DetailCard/DetailCard'

type TProps = {
    promotion: TPromotion,
    editMode: boolean
}

const CurrencyPromotion: React.FC<TProps> = ({
    promotion,
    editMode
}) => {
    const { renderField } = useRenderingFunctions(promotion as any, editMode)

    const saveMapper = (v: string) => Number(v) <= 0 ? null : Number(v)

    return (
        <>
            <DetailCard id='app.promo.firstThreshold' title='Pierwszy próg' width={5.33} minHeight={0}>
                {renderField("firstDiscount.amountDiscount", { label: "Nowa cena za sztukę (zł)", type: 'number', saveMapper, min: 0, precision: 2, validators: [REQUIRED] })}
                <MinimumCondition promotion={promotion} editMode={editMode} discountGroup="firstDiscount" />
            </DetailCard>
            <DetailCard id='app.promo.secondThreshold' title='Drugi próg' width={5.33} minHeight={0}>
                {renderField("secondDiscount.amountDiscount", { label: "Nowa cena za sztukę (zł)", type: 'number', saveMapper, min: 0, precision: 2 })}
                <MinimumCondition promotion={promotion} editMode={editMode} discountGroup="secondDiscount" />
            </DetailCard>
        </>
    )
}

export default CurrencyPromotion