import React, { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { InputNumberProps, TActionData } from "~/components/InputNumber/InputNumber";
import ReactDOM from "react-dom";
import {
    calculateNewSelectionStart,
    deformat, getInputButtons,
    isCharBlankOrNonBreakingSpace,
    setIfExceedsRange,
    setInputProperties,
    setSelectionStartEnd
} from "~/components/InputNumber/lib/utils";
import { InputOnChangeData } from "semantic-ui-react";

export type InputNumberHookResponse = [
    (node: Element | null) => void,
    (event: KeyboardEvent) => void,
    (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
] & {
    ref: (node: Element | null) => void,
    keyDownHandler: (event: KeyboardEvent) => void,
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
}


export const useInputNumber = (props: PropsWithChildren<InputNumberProps>) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const actionData = useRef<TActionData>()

    const {
        value, onChange = () => {
        }, precision = 0, min, max, step = 1, label, ...bypassProps
    } = props;

    const setRef = useCallback(node => {
        ref.current = node;
    }, []);

    const format = useCallback((value: number): string => {
        const input = ref.current!.getElementsByTagName('input')[0]
        const hasDecimal = input.value.indexOf(',') !== -1
        const minimumFractionDigits = hasDecimal ? precision : 0
        return Number(value).toLocaleString('pl', { minimumFractionDigits, maximumFractionDigits: precision })
    }, [precision])

    useEffect(() => {
        const inputDiv = ref.current!.getElementsByClassName('input')[0]
        let wrapper = inputDiv.getElementsByTagName('span')[0]
        if (!wrapper) {
            wrapper = document.createElement('span')
            inputDiv.appendChild(wrapper)
            ReactDOM.render(getInputButtons(increment, props, decrement), wrapper)
        } else {
            ReactDOM.hydrate(getInputButtons(increment, props, decrement), wrapper)
        }
    })

    useEffect(() => {
        const input = ref.current!.getElementsByTagName('input')[0]
        input.value = value !== undefined ? format(value) : ''
    }, [value, format])


    const increment = (event: any): void => {
        stepChange(event, 1)
    }

    const decrement = (event: any): void => {
        stepChange(event, -1)
    }

    const stepChange = (event: any, sign: 1 | -1): void => {
        const input = ref.current!.getElementsByTagName('input')[0]
        actionData.current = {
            oldValue: input.value,
            selectionStart: 0
        }
        const newValue = format(deformat(input.value) + sign * Number(step))
        changeHandler(event, { value: newValue, ...bypassProps })
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
        const input = ref.current!.getElementsByTagName('input')[0]
        const { oldValue } = actionData.current!
        data.value = data.value.replace(/\./g, ',')

        let newRawValue = setIfExceedsRange(min, max, deformat(data.value));
        if (Number.isNaN(newRawValue)) {
            input.value = oldValue
            return
        }

        const newValue = data.value !== '' ? format(newRawValue) : ''
        const newSelection = calculateNewSelectionStart(actionData, newValue, precision)

        setInputProperties(input, newValue, newSelection);
        onChange(event, { ...props, value: deformat(newValue) })
    }

    const keyDownHandler = (event: KeyboardEvent): void => {
        const input = ref.current!.getElementsByTagName('input')[0]
        const selectionStart = input.selectionStart || 0
        actionData.current = {
            oldValue: input.value,
            selectionStart,
            key: event.key
        }
        switch (event.key) {
            case 'ArrowUp':
                return increment(event)
            case 'ArrowDown':
                return decrement(event)
            case 'Backspace':
                if (!isCharBlankOrNonBreakingSpace(input.value[selectionStart - 1])) {
                    setSelectionStartEnd(input, selectionStart - 1);
                    event.preventDefault()
                }
                return
            case 'Delete':
                if (!isCharBlankOrNonBreakingSpace(input.value[selectionStart])) {
                    setSelectionStartEnd(input, selectionStart + 1);
                    event.preventDefault()
                }
                return
            case '.':
            case ',':
                if (precision === 0) {
                    event.preventDefault()
                }
                return
        }
    }

    const result = [setRef, keyDownHandler, changeHandler] as InputNumberHookResponse;

    result.ref = result[0];
    result.keyDownHandler = result[1];
    result.changeHandler = result[2];
    return result;
}