const _ = require('lodash');

export function reducer(tableData: any, action: any) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (tableData.column === action.column) {
                return {
                    ...tableData,
                    data: tableData.data.slice().reverse(),
                    direction:
                        tableData.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }

            return {
                column: action.column,
                data: _.sortBy(tableData.data, [action.column]),
                direction: 'ascending',
            }
        case 'INIT_TABLE':
            return {
                column: null,
                data: action.data,
                direction:
                    tableData.direction === 'ascending',
                loading: false,
            }
        case 'SELECT_ALL':
            tableData.data.map(data => data.selected = action.selected)
            return {
                column: null,
                data: tableData.data,
                direction:
                    tableData.direction === 'ascending',
                loading: false,
            }
        case 'SELECT':
            tableData.data.map((line) => {
                if (line.lineNumber === action.checkedLine) {
                    line.selected = action.selected;
                }
                return line
            })
            return {
                column: null,
                data: tableData.data,
                direction:
                    tableData.direction === 'ascending',
                loading: false,
            }
        case 'START_SEARCH':
            return {...tableData, loading: true, value: action.query}
        case 'FINISH_SEARCH':
            return {...tableData, loading: false, data: action.results, results: action.results}
        case 'UPDATE_SELECTION':
            return {...tableData, value: action.selection}
        default:
            throw new Error()
    }
}
