import { Button, Header, Icon, Input, Message, Modal, Segment } from 'semantic-ui-react';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TPreImport } from '~/store/customer/types';

type TProps = {
  open: boolean;
  loadingPreImport: boolean;
  loadingImportAdd: boolean;
  loadingImportUpdate: boolean;
  importedAdd: boolean;
  importedUpdate: boolean;
  preImportResults?: TPreImport;
  closeHandler: () => void;
  preImportCustomers: (file: File) => void;
  importCustomersAdd: (file: File) => void;
  importCustomersUpdate: (file: File) => void;
  clearImportCustomers: () => void;
};

export const ImportCustomersModal: React.FC<TProps> = ({
  open,
  preImportResults,
  importedAdd,
  importedUpdate,
  loadingPreImport,
  loadingImportAdd,
  loadingImportUpdate,
  preImportCustomers,
  importCustomersAdd,
  importCustomersUpdate,
  closeHandler,
  clearImportCustomers,
}) => {
  const [file, setFile] = useState<File>();
  const [fileUploaded, setFileUploaded] = useState<string>();

  useEffect(() => {
    if (open) {
      setFile(undefined);
    }
  }, [open]);

  const handleLoad = useCallback((): void => {
    if (file) {
      preImportCustomers(file);
      setFileUploaded(file.name);
    }
  }, [file, preImportCustomers]);

  const handleConfirmAdd = useCallback((): void => {
    if (file) {
      importCustomersAdd(file);
    }
  }, [file, importCustomersAdd]);

  const handleConfirmUpdate = useCallback((): void => {
    if (file) {
      importCustomersUpdate(file);
    }
  }, [file, importCustomersUpdate]);

  const handleFileChange = (ev: any, el: any): void => {
    setFile(ev.target.files[0]);
  };

  const renderImportCustomerText = (count: number): string => (count <= 1 ? 'kontrahenta' : 'kontrahentów');

  const renderMessageText = (imported: boolean, results: boolean, type: 'add' | 'update'): JSX.Element => {
    const typeSuccess = type === 'add' ? 'dodano' : 'zaktualizowano';
    if (!results) {
      return <></>;
    } else if (results && imported) {
      return (
        <p style={{ color: 'green' }}>
          <b>Pomyślnie {typeSuccess} kontrahentów.</b>
        </p>
      );
    } else return <></>;
  };

  const renderUploadFile = useCallback((): JSX.Element => {
    return (
      <>
        <Header icon>
          <Icon name="file archive outline" />
          {file ? file.name : 'Załaduj plik'}
        </Header>
        <Input type="file" onChange={handleFileChange} />
      </>
    );
  }, [file]);

  const renderPreImportsResults = useCallback((): JSX.Element => {
    return (
      <>
        {preImportResults && (
          <>
            <p>
              Plik zawiera liczbę rekordów: <b>{preImportResults.recordsCount}</b>
            </p>
            {!importedAdd && preImportResults.toCreate > 0 ? (
              <Button
                primary
                content={`Dodaj ${preImportResults.toCreate} ${renderImportCustomerText(preImportResults.toCreate)}`}
                loading={loadingImportAdd}
                disabled={loadingImportAdd}
                icon="add"
                labelPosition="left"
                onClick={handleConfirmAdd}
                style={{ marginLeft: '0', maxWidth: '250px' }}
              />
            ) : (
              renderMessageText(importedAdd, preImportResults.toCreate > 0, 'add')
            )}
            {!importedUpdate && preImportResults.toUpdate > 0 ? (
              <Button
                primary
                content={`Zaktualizuj ${preImportResults.toUpdate} ${renderImportCustomerText(preImportResults.toUpdate)}`}
                loading={loadingImportUpdate}
                disabled={loadingImportUpdate}
                icon="edit"
                labelPosition="left"
                onClick={handleConfirmUpdate}
                style={{ marginLeft: '0', maxWidth: '250px', marginTop: '10px' }}
              />
            ) : (
              renderMessageText(importedUpdate, preImportResults.toUpdate > 0, 'update')
            )}
          </>
        )}
      </>
    );
  }, [
    preImportResults,
    handleConfirmAdd,
    handleConfirmUpdate,
    importedAdd,
    importedUpdate,
    loadingImportAdd,
    loadingImportUpdate,
  ]);

  const handleBack = useCallback((): void => {
    clearImportCustomers();
    setFile(undefined);
    setFileUploaded('');
  }, [clearImportCustomers]);

  const handleClose = useCallback((): void => {
    setFile(undefined);
    setFileUploaded('');
    clearImportCustomers();
    closeHandler();
  }, [clearImportCustomers, closeHandler]);

  const renderFileHeader = useCallback(
    (): JSX.Element => (
      <>
        {preImportResults ? (
          <p>
            Sprawdź czy wszystko się zgadza. <br />
          </p>
        ) : (
          <p>
            {' '}
            Załaduj poprawny plik XLSX, XLS, CSV.
            <br />
            <span style={{ color: 'gray', fontSize: '10px' }}>*Maksymalny rozmiar pliku 15MB.</span>
          </p>
        )}
      </>
    ),
    [preImportResults]
  );

  return useMemo(
    () => (
      <Modal open={open} onClose={closeHandler} size="large" closeOnDimmerClick={false}>
        <Header
          icon="file archive"
          className={'collective-corrections-modal-header'}
          content={
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center' }}>
              <p style={{ marginBottom: 0 }}>Importowanie kontrahentów z pliku</p>
              {<p style={{ textAlign: 'right' }}>Krok {!preImportResults ? '1' : '2'}/2</p>}
            </div>
          }
        />
        <Modal.Content>
          <Message size="small">
            <Message.Content>{renderFileHeader()}</Message.Content>
          </Message>
          <Segment placeholder>
            {preImportResults && file && renderPreImportsResults()}
            {!preImportResults && renderUploadFile()}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          {preImportResults && file && <Button content="Wróć" onClick={() => handleBack()} />}
          {!preImportResults && (
            <Button
              primary
              content="Wczytaj"
              icon="upload"
              labelPosition="right"
              loading={loadingPreImport}
              disabled={loadingPreImport || !file || fileUploaded === file.name}
              onClick={handleLoad}
            />
          )}
          <Button content="Zamknij" onClick={() => handleClose()} />
        </Modal.Actions>
      </Modal>
    ),
    [
      open,
      loadingPreImport,
      file,
      preImportResults,
      closeHandler,
      fileUploaded,
      handleBack,
      handleClose,
      handleLoad,
      renderFileHeader,
      renderPreImportsResults,
      renderUploadFile,
    ]
  );
};
