import DetailCard from "~/components/DetailCard/DetailCard";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import React from "react";
import { ApplicationState } from "~/store";
import { connect } from "react-redux";
import {
  DetailCardStatusProps,
  DetailCardTReduxState,
} from "~/pages/Orders/Details/lib/constants";
import { OrderCustomer } from "~/store/orders/types";
import DeliveryAddress from "~/pages/Orders/Details/lib/DeliveryAddress";
import { DictionaryName } from "~/store/dictionaries/types";
import { FormattedMessage } from "react-intl";
import { Divider } from "semantic-ui-react";
import mapDictionary from "~/components/MapDictionary/mapDictionary";

const DetailCardDelivery = ({
  shrink,
  renderLabel,
  order,
  dictionaries,
}: DetailCardStatusProps) => {
  const formatDeliveryAddress = (customer: OrderCustomer): JSX.Element => (
    <DeliveryAddress customer={customer} />
  );

  const formatMessage = (id: string): JSX.Element => (
    <FormattedMessage id={id} />
  );

  return (
    <DetailCard
      title="app.customer.delivery"
      id="client"
      key="client"
      minHeight={40}
      width={shrink ? 3.5 : 4}
      smallWidth={5.33}
    >
      <SmartLabel label="Nazwa" value={order?.deliveryCustomer.companyName} />
      <SmartLabel
        label="Adres"
        value={order && formatDeliveryAddress(order.deliveryCustomer)}
      />
      <SmartLabel label="Płatnik" value={order?.customer.rrid} />
      <SmartLabel
        label="PCD odbiorca"
        value={order?.deliveryCustomer.sapDeliveryId || "n/d"}
      />
      {order?.deliveryCustomer.ddsDeliveryId && (
        <SmartLabel
          label={formatMessage("app.customer.ovDeliveryId")}
          value={order?.deliveryCustomer.ddsDeliveryId}
        />
      )}
      <Divider />
      {order?.deliveryCustomer.supplierSystem &&
        renderLabel(
          formatMessage("app.customer.FLHAP-Payer"),
          order?.deliveryCustomer.supplierSystem[
            order?.deliveryCustomer.supplierSystem?.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            )
          ].clientId
        )}
      {order?.deliveryCustomer.supplierSystem &&
        renderLabel(
          formatMessage("app.customer.FLHAP-Recipient"),
          order?.deliveryCustomer.supplierSystem[
            order?.deliveryCustomer.supplierSystem?.findIndex(
              (sys) => sys.businessSector === "FLHAP"
            )
          ].deliveryId
        )}
      {order?.deliveryCustomer.supplierSystem &&
      order?.deliveryCustomer.supplierSystem.findIndex(
        (sys) => sys.businessSector === "FLHAP"
      ) >= 0 ? (
        <Divider />
      ) : (
        ""
      )}
      {order?.deliveryCustomer.supplierSystem &&
        renderLabel(
          formatMessage("app.customer.CJD-Payer"),
          order?.deliveryCustomer.supplierSystem[
            order?.deliveryCustomer.supplierSystem?.findIndex(
              (sys) => sys.businessSector === "CJD"
            )
          ].clientId
        )}
      {order?.deliveryCustomer.supplierSystem &&
        renderLabel(
          formatMessage("app.customer.CJD-Recipient"),
          order?.deliveryCustomer.supplierSystem[
            order?.deliveryCustomer.supplierSystem?.findIndex(
              (sys) => sys.businessSector === "CJD"
            )
          ].deliveryId
        )}
      {order?.deliveryCustomer.supplierSystem &&
      order?.deliveryCustomer.supplierSystem.findIndex(
        (sys) => sys.businessSector === "CJD"
      ) >= 0 ? (
        <Divider />
      ) : (
        ""
      )}
      {order &&
        renderLabel(
          "Dostawa",
          mapDictionary(
            order.deliveryType,
            dictionaries[DictionaryName.orderDeliveryType] || [],
            true,
            true,
            true
          )
        )}
      {order && order.deliveryCustomer.discountDRZPercentage && (
        <>
          <Divider />
          {renderLabel(
            "Rabat DRZ",
            order.deliveryCustomer.discountDRZPercentage + "%"
          )}
        </>
      )}
    </DetailCard>
  );
};

const mapStateToProps: (state: ApplicationState) => DetailCardTReduxState = ({
  dictionaries,
}: ApplicationState) => ({
  dictionaries,
});

export default connect(mapStateToProps)(DetailCardDelivery);
