import { Reducer } from 'redux'
import { WarehouseActionTypes, WarehousesState } from './types'

export const initialWarehousesState: WarehousesState = {
    loading: false,
    loadingWarehouse: false,
    updatedDayOff: false,
    selectedWarehouse: { title: undefined, name: undefined, daysOff: [] },
    warehousesList: { res: [{ daysOff: [], id: undefined, name: undefined }] }
}

const reducer: Reducer<WarehousesState> = (state = initialWarehousesState, action) => {
    switch (action.type) {
        case WarehouseActionTypes.FETCH_WAREHOUSES: {
            return { ...state, loading: true }
        }
        case WarehouseActionTypes.FETCH_WAREHOUSES_SUCCESS: {
            return { ...state, loading: false, warehousesList: action.payload }
        }
        default:
            break
    }

    return state
}

export { reducer as warehousesReducer }