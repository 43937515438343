import { Form, Grid } from "semantic-ui-react";
import { FormInputNumber } from "~/components/InputNumber/InputNumber";
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { validNumber } from "~/pages/Orders/lib/Add/utility";
import { connect } from "react-redux";
import {
  LimitProps,
  TReduxState,
} from "~/pages/Administration/lib/MenageUserRole/Limit/constants";
import { SmartLabel } from "~/components/SmartField/SmartLabel";
import { AVAILABLE_ROLE_ENUM, AvailableRole } from "~/store/users/types";
import { ApplicationState } from "~/store";
import translations from "~/utils/translations";

const Limit = ({ limit, setLimit, userRoles, user }: LimitProps) => {
  const userLdcUiOrderBonusAndSpecialRole = useMemo(() => {
    if (!userRoles.hasOwnProperty(AVAILABLE_ROLE_ENUM.LDC_ORDER)) {
      return false;
    }

    const foundRole = userRoles[AVAILABLE_ROLE_ENUM.LDC_ORDER].filter(
      (role: AvailableRole) =>
        role.name === AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS ||
        role.name === AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_SPECIAL
    );

    return !!foundRole.length;
  }, [userRoles]);

  useEffect(() => {
    const newLimit = user?.attributes?.bonusSpecialOrderDiscountLimit?.[0] || 0;
    setLimit(newLimit);
  }, [setLimit, user]);

  const changeBonus = useCallback(
    (event: SyntheticEvent, data: any): void => {
      if (validNumber(+data.value)) {
        setLimit(data.value);
      }
    },
    [setLimit]
  );

  return (
    <div className="item">
      <div className="content">
        <div className="header">
          <Form.Field>
            <Grid columns="2">
              <Grid.Row verticalAlign="middle">
                <Grid.Column width="6">
                  <SmartLabel
                    label={translations.format("bonus.and.special.limit.label")}
                    align="left"
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormInputNumber
                    disabled={!userLdcUiOrderBonusAndSpecialRole}
                    min={0}
                    className="super-field"
                    max={100}
                    precision={1}
                    value={limit}
                    style={{
                      width: "60%",
                      opacity: 1,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: -5,
                    }}
                    onChange={changeBonus}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  users,
}: ApplicationState) => {
  return {
    user: users.user,
    userRoles: users.userRoles,
  };
};

export default connect(mapStateToProps)(Limit);
