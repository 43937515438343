import DetailCard from "~/components/DetailCard/DetailCard";
import { parseDate } from "~/utils/dateUtils";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import React from "react";
import { BackOrderNumberListProps } from "~/pages/Orders/Details/lib/constants";

const isDoubleSized = (batchOrdersLength: number): number =>
  batchOrdersLength > 1 ? 8 : 4;

const BackOrderNumberList = ({
  order,
  renderLabel,
  forClientPanel,
}: BackOrderNumberListProps) => (
  <DetailCard
    title="app.batch"
    id="batch"
    key="batch"
    minHeight={40}
    width={
      order && order.backorderIdNumberList
        ? isDoubleSized(order.backorderIdNumberList.length)
        : 4
    }
    smallWidth={5.33}
  >
    <div className="batch-container" style={{ display: "flex" }}>
      {order &&
        order.backorderIdNumberList &&
        order.backorderIdNumberList.map((batch, index) => (
          <div
            key={batch.batchId}
            style={{
              width: order.backorderIdNumberList?.length === 1 ? "100%" : "40%",
              height: !forClientPanel ? 110 : 80,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {renderLabel(index === 0 ? "ID" : "", batch.batchId || "n/d")}
            {renderLabel(
              index === 0 ? "Data" : "",
              parseDate(order.backorderSentTime) || "n/d"
            )}
            {renderLabel(
              index === 0 ? "Dostawca" : "",
              batch.backorderSupplier || "n/d"
            )}
            {!forClientPanel
              ? renderLabel(
                  index === 0 ? "Zamówienie" : "",
                  <Button
                    as={Link}
                    to={`/backorder/${batch.backorderID}`}
                    compact
                    basic
                  >
                    {batch.backorderNumber}
                  </Button>
                )
              : null}
          </div>
        ))}
    </div>
  </DetailCard>
);

export default BackOrderNumberList;
