import { Dropdown } from 'semantic-ui-react'
import { DictionaryItem } from '~/store/dictionaries/types'
import { FormattedMessage } from 'react-intl'

import React, { Component } from 'react'

interface Props {
    column: {
        name: string
        dictionary?: DictionaryItem[]
        undefinedOption?: boolean
        noKey?: boolean
    },
    value: string
    onChangeFilter: (name: string, value: string, text?: string, key?: string) => void
    disabled?: boolean
}

class DropdownFilter extends Component<Props> {

    shouldComponentUpdate(nextProps: Props): boolean {
        if (this.props.column.dictionary && nextProps.column.dictionary) {
            return (this.props.value !== nextProps.value) || (JSON.stringify(this.props.column.dictionary) !== JSON.stringify(nextProps.column.dictionary))
        }
        return (this.props.value !== nextProps.value)
    }

    private changeDropdown(name: string, dictionary: DictionaryItem[], value: string): void {
        const { onChangeFilter } = this.props
        const found = dictionary.find(d => d.value === value)
        if (found) {
            onChangeFilter(name, value, found.text, found.key)
        } else {
            onChangeFilter(name, value)
        }
    }

    render() {
        const { column, value, disabled } = this.props

        const options = (column.dictionary || []).map(item => ({
            ...item,
            text: column.noKey ? item.text : `[${item.value}] ${item.text}`
        }))
        if (column.undefinedOption) {
            options.push({
                key: 'null',
                value: 'null',
                text: 'Nieokreślone'
            })
        }

        return (
            <Dropdown
                disabled={disabled}
                className="dic-select"
                noResultsMessage={<FormattedMessage id="app.noResultsMessage" />}
                clearable
                options={options}
                selectOnBlur={false}
                lazyLoad
                scrolling
                search
                value={value}
                onChange={(e, d: any) => this.changeDropdown(column.name, column.dictionary || [], d.value)}
                placeholder='...'>
            </Dropdown>
        )
    }
}

export default DropdownFilter
