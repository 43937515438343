import { Reducer } from 'redux'
import {PriceListActionTypes, PriceListState} from './types'

export const initialPriceListState: PriceListState = {
    error: null,
    sparePart: null,
    loading: false,
    list: []
}

const reducer: Reducer<PriceListState> = (state = initialPriceListState, action) => {

    switch (action.type) {
        case PriceListActionTypes.FETCH_REQUEST: {
            return { ...state, fetching: true, list: [] }
        }
        case PriceListActionTypes.FETCH_ERROR: {
            return { ...state, loading: false, error: action.payload }
        }
        case PriceListActionTypes.FETCH_SUCCESS: {
            return { ...state, loading: false, list: action.payload }
        }
        case PriceListActionTypes.FETCH_REQUEST_SPARE_PART: {
            return { ...state, fetching: true }
        }
        case PriceListActionTypes.FETCH_ERROR_SPARE_PART: {
            return { ...state, loading: false, error: action.payload }
        }
        case PriceListActionTypes.FETCH_SUCCESS_SPARE_PART: {
            return { ...state, loading: false, sparePart: action.payload }
        }
    }

    return state
}

export {reducer as pricingReducer}
