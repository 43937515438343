import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Form,
  Grid,
  Icon,
  Label,
  Popup,
  Table,
} from "semantic-ui-react";
import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { ApplicationState } from "~/store";
import {
  closeAnomaly,
  rejectRW,
  resolveAnomaly,
  resolveDestroyedParts,
  selectAnomaly,
} from "~/store/anomalies/actions";
import { Anomaly, TPart, TPartDestroyed } from "~/store/anomalies/types";
import { fetchDictionary } from "~/store/dictionaries/actions";
import {
  DictionariesState,
  DictionaryItem,
  DictionaryName,
} from "~/store/dictionaries/types";

import React, {
  Fragment,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DetailCard from "~/components/DetailCard/DetailCard";
import CommonLoader from "~/components/Loaders/CommonLoader";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import PageHeader from "~/components/PageHeader/PageHeader";
import GoodsDispatchNotesDetails from "~/pages/Accounting/Details/GoodsDispatchNotes";
import GoodsReceivedNotesDetails from "~/pages/Accounting/Details/GoodsReceivedNotes";
import InternalConsumptionNotesDetails from "~/pages/Accounting/Details/InternalConsumptionNotes";
import matchAnomalyAction, {
  TActionData,
  TActionType,
} from "../lib/AnomalyMatcher";
import dictioriesStatic from "~/store/dictionaries/static";
import GDNConfirmModal from "./lib/GDNConfirmModal";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContext } from "~/context/AuthContext";
import translations from "~/utils/translations";
import mapDictionary from "~/components/MapDictionary/mapDictionary";

type TReduxState = {
  anomalyIn?: Anomaly;
  loading: boolean;
  dictionaries: DictionariesState;
  loadingDestroyedLines: boolean;
};

type TReduxActions = {
  selectAnomaly: typeof selectAnomaly;
  resolveAnomaly: typeof resolveAnomaly;
  closeAnomaly: typeof closeAnomaly;
  fetchDictionary: typeof fetchDictionary;
  resolveDestroyedParts: typeof resolveDestroyedParts;
  rejectRW: typeof rejectRW;
};

type TRouteParams = RouteComponentProps<{
  anomalyId: string;
}>;

type TAnomalyDetailsProps = TReduxState & TReduxActions & TRouteParams;

const TYPE_GDN = [
  "GDN_SUPPLIER_CLAIM",
  "GDN_CLIENT_ORDER",
  "GDN_CLIENT_CLAIM",
  "GDN_INTERNAL",
];

const AnomalyDetails: React.FC<TAnomalyDetailsProps> = ({
  match,
  anomalyIn,
  loading,
  selectAnomaly,
  resolveAnomaly,
  closeAnomaly,
  fetchDictionary,
  dictionaries,
  resolveDestroyedParts,
  rejectRW,
}) => {
  const [anomaly, setAnomaly] = useState<Anomaly>();
  const [editMode, setEditMode] = useState(false);
  const [request, setRequest] = useState<boolean>(false);
  const [destroyedLines, setDestroyedLines] = useState<TPartDestroyed[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedGDNOption, setSelectedGDNOption] = useState<
    "ADDITION" | "REMOVAL" | ""
  >("");

  const appContext = useContext(AppContext);
  const confirmMessage = useRef<string | JSX.Element>("");
  const actionHandler = useRef<TActionType>();

  useEffect(() => {
    if (!anomaly || anomaly.anomalyID !== match.params.anomalyId) {
      fetchDictionary(DictionaryName.anomalyType, DictionaryName.anomalyStatus);
      selectAnomaly(match.params.anomalyId);
    }
  }, [match.params.anomalyId, anomaly, fetchDictionary, selectAnomaly]);

  if (request && !loading) {
    selectAnomaly(match.params.anomalyId);
    setRequest(false);
  }

  useEffect(() => {
    document.title = translations.format("LDC_ANOMALY")
    anomalyIn &&
      anomalyIn.anomalyStatus &&
      setAnomaly({
        ...anomalyIn,
        affectedParts: {
          ...anomalyIn.affectedParts,
          missingParts: anomalyIn.affectedParts.missingParts.map((part) => ({
            ...part,
            quantityOrgin: part.quantity,
          })),
        },
      });
  }, [anomalyIn]);

  const toggleEditMode = (): void => {
    setEditMode(!editMode);
  };

  const isNoneditableStatus = (): boolean => {
    if (!anomaly) {
      return true;
    }
    return (
      anomaly.anomalyStatus === "RESOLVED" ||
      anomaly.anomalyStatus === "STOCK_EXPIRED" ||
      anomaly.anomalyStatus === "INFORMATIONAL"
    );
  };

  const handleCancelClick = (): void => {
    setModalOpen(false);
  };

  const handleConfirmClick = (): void => {
    if (!anomaly) {
      return;
    }
    if (actionHandler.current === "RESOLVE") {
      resolveAnomaly(anomaly.anomalyID, selectedGDNOption, anomaly.anomalyType);
    } else if (actionHandler.current === "RESOLVE_SELECTED") {
      resolveAnomaly(
        anomaly.anomalyID,
        selectedGDNOption,
        anomaly.anomalyType,
        anomaly.affectedParts.missingParts
      );
    } else if (actionHandler.current === "CLOSE") {
      closeAnomaly(anomaly.anomalyID, anomaly.anomalyType, selectedGDNOption);
    }
    setModalOpen(false);
    setEditMode(false);
    setRequest(true);
  };

  const selectAll = useCallback(
    (event: SyntheticEvent, data: any): void => {
      anomaly &&
        setAnomaly({
          ...anomaly,
          affectedParts: {
            ...anomaly.affectedParts,
            missingParts: anomaly.affectedParts.missingParts.map((part) => ({
              ...part,
              selected: data.checked,
            })),
          },
        });
    },
    [anomaly]
  );

  const selectPart = useCallback(
    (referenceId: string, event: SyntheticEvent, data: any): void => {
      anomaly &&
        setAnomaly({
          ...anomaly,
          affectedParts: {
            ...anomaly.affectedParts,
            missingParts: anomaly.affectedParts.missingParts.map((part) =>
              part.referenceId === referenceId
                ? {
                    ...part,
                    selected: data.checked,
                  }
                : { ...part }
            ),
          },
        });
    },
    [anomaly]
  );

  const changeQuantity = useCallback(
    (referenceId: string, event: SyntheticEvent, data: any): void => {
      anomaly &&
        setAnomaly({
          ...anomaly,
          affectedParts: {
            ...anomaly.affectedParts,
            missingParts: anomaly.affectedParts.missingParts.map((part) =>
              part.referenceId === referenceId
                ? {
                    ...part,
                    quantity: parseInt(data.value),
                    selected: true,
                  }
                : { ...part }
            ),
          },
        });
    },
    [anomaly]
  );

  const foundInvalidParts = useCallback((): boolean => {
    if (!anomaly) {
      return true;
    }
    const found = anomaly.affectedParts.missingParts.find((part) => {
      if (isNaN(part.quantity) || part.quantity === 0) {
        return true;
      }
      return part.quantityOrgin && part.quantity > part.quantityOrgin;
    });

    return !!found;
  }, [anomaly]);

  const isInvalid = (): boolean => {
    if (!anomaly) {
      return true;
    }
    const foundSome = anomaly.affectedParts.missingParts.some(
      (part) => part.selected
    );
    return foundInvalidParts() ? true : !foundSome;
  };

  const validNumber = (value: any): boolean => {
    if (isNaN(value)) {
      return false;
    }
    return typeof value === "number";
  };

  const renderPartsLines = useCallback(
    (parts: TPart[], edit: boolean): JSX.Element => {
      if (parts) {
        const rows = (anomaly ? parts : [])
          .sort((a, b) => (a.referenceId > b.referenceId ? 1 : -1))
          .map((part, index) => {
            return (
              <Table.Row key={index}>
                {edit && (
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={part.selected}
                      disabled={!editMode}
                      onChange={(e, d) => selectPart(part.referenceId, e, d)}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>
                  <Link to={`/product/${part.referenceId}`}>
                    {part.referenceId}
                  </Link>
                </Table.Cell>
                {anomaly?.anomalyType === "STOCKTAKING_GRN" ||
                anomaly?.anomalyType === "STOCKTAKING_ICN" ? null : (
                  <Table.Cell>{part.vin}</Table.Cell>
                )}
                <Table.Cell collapsing={edit}>
                  {edit ? (
                    <Form.Input
                      type="number"
                      min="0"
                      disabled={!editMode}
                      placeholder="..."
                      value={validNumber(part.quantity) ? part.quantity : ""}
                      error={foundInvalidParts()}
                      onChange={(e, d) =>
                        changeQuantity(part.referenceId, e, d)
                      }
                      style={{ width: 300, opacity: 1 }}
                    />
                  ) : (
                    part.quantity
                  )}
                </Table.Cell>
              </Table.Row>
            );
          });

        return (
          <Fragment>
            <Table celled style={{ overflow: "auto" }}>
              <Table.Header>
                <Table.Row>
                  {edit && (
                    <Table.HeaderCell collapsing>
                      <Checkbox disabled={!editMode} onChange={selectAll} />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell>Referencja</Table.HeaderCell>
                  {anomaly?.anomalyType === "STOCKTAKING_GRN" ||
                  anomaly?.anomalyType === "STOCKTAKING_ICN" ? null : (
                    <Table.HeaderCell>VIN</Table.HeaderCell>
                  )}
                  <Table.HeaderCell collapsing={edit}>Ilość</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{rows}</Table.Body>
            </Table>
          </Fragment>
        );
      }
      return <></>;
    },
    [
      anomaly,
      changeQuantity,
      editMode,
      foundInvalidParts,
      selectAll,
      selectPart,
    ]
  );

  const handleDestroyedLineConfirm = useCallback((): void => {
    resolveDestroyedParts(destroyedLines, (anomaly && anomaly.anomalyID) || "");
  }, [anomaly, destroyedLines, resolveDestroyedParts]);

  const handleRWReject = (): void => {
    rejectRW((anomaly && anomaly.anomalyID) || "");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isICNAnomalyStatus = (): boolean =>
    (anomaly && anomaly.anomalyType === "ICN") || false;

  const addDestroyedLine = useCallback((): void => {
    const destroyedLine = {
      lineNumber:
        destroyedLines.length === 0
          ? 1
          : destroyedLines[destroyedLines.length - 1].lineNumber + 1,
      referenceId: "",
      quantity: 1,
      status: "",
    };
    setDestroyedLines((oldArray) => [...oldArray, destroyedLine]);
  }, [destroyedLines]);

  const changeDestroyedLineStatus = useCallback(
    (
      lineNumber: number,
      event: SyntheticEvent,
      data: any,
      isStatusToChange: boolean
    ): void => {
      setDestroyedLines(
        destroyedLines.map((line) => {
          if (line.lineNumber === lineNumber) {
            if (isStatusToChange) {
              const found = destroyedLines
                .filter((f) => f.referenceId === line.referenceId)
                .find((x) => x.status === data.value);
              if (!found) return { ...line, status: data.value };
              else return { ...line };
            } else return { ...line, referenceId: data.value };
          }
          return { ...line };
        })
      );
    },
    [destroyedLines]
  );

  const mapProducts = (products: any[]): DictionaryItem[] => {
    return products.map((product) => ({
      key: product.referenceId,
      text: product.referenceId,
      value: product.referenceId,
    }));
  };

  const removeDestroyedLine = useCallback(
    (lineNumber: number): void => {
      setDestroyedLines(
        destroyedLines
          .filter((line) => line.lineNumber !== lineNumber)
          .map((line, index) => ({
            ...line,
            lineNumber: index + 1,
          }))
      );
    },
    [destroyedLines]
  );

  const changeDestroyedLinesQuantity = useCallback(
    (lineNumber: number, event: SyntheticEvent, data: any): void => {
      setDestroyedLines(
        destroyedLines.map((line) => {
          if (line.lineNumber === lineNumber) {
            return { ...line, quantity: parseInt(data.value) };
          }
          return { ...line };
        })
      );
    },
    [destroyedLines]
  );

  const validForm = useCallback((): boolean => {
    let isValid = true;
    const destroyedParts = anomaly ? anomaly.destroyedParts : [];
    destroyedLines.forEach((element) => {
      const sum = destroyedLines
        .filter((x) => x.referenceId === element.referenceId)
        .map((o) => o.quantity)
        .reduce((a, c) => a + c);
      const foundPart = destroyedParts.find(
        (line) => line.referenceId === element.referenceId
      );

      if (foundPart) isValid = sum === foundPart.quantity;

      if (element.referenceId === "" || element.status === "") isValid = false;

      if (
        destroyedLines.filter(
          (v, i, a) => a.findIndex((t) => t.referenceId === v.referenceId) === i
        ).length !== destroyedParts.length
      )
        isValid = false;
    });

    return isValid;
  }, [anomaly, destroyedLines]);

  const renderPartsLinesICN = useCallback(
    (parts: TPart[]): JSX.Element => {
      const row = destroyedLines.map((line) => {
        return (
          <Table.Row key={line.lineNumber}>
            <Table.Cell>
              <Form.Dropdown
                placeholder="Wybierz produkt"
                search
                upward
                selection
                style={{ width: 200 }}
                value={line.referenceId}
                error={line.referenceId === ""}
                onChange={(e, d) =>
                  changeDestroyedLineStatus(line.lineNumber, e, d, false)
                }
                options={mapProducts(anomaly ? anomaly.destroyedParts : [])}
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Dropdown
                placeholder="Wybierz status"
                search
                upward
                selection
                style={{ width: 200 }}
                value={line.status}
                error={line.status === ""}
                onChange={(e, d) =>
                  changeDestroyedLineStatus(line.lineNumber, e, d, true)
                }
                options={dictioriesStatic.ANOMALY_DESTROYED_STATUSES}
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                type="number"
                min="0"
                placeholder="..."
                value={line.quantity}
                error={foundInvalidParts()}
                onChange={(e, d) =>
                  changeDestroyedLinesQuantity(line.lineNumber, e, d)
                }
                fluid
                style={{ width: 100, opacity: 1 }}
              />
            </Table.Cell>
            <Table.HeaderCell>
              <Popup
                trigger={
                  <Button
                    circular
                    basic
                    icon="remove"
                    onClick={() => removeDestroyedLine(line.lineNumber)}
                  />
                }
                content="Usuń linię"
              />
            </Table.HeaderCell>
          </Table.Row>
        );
      });
      return (
        <>
          <Table celled style={{ overflow: "auto" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Referencja</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Ilość</Table.HeaderCell>
                <Table.HeaderCell>x</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{row}</Table.Body>
          </Table>
          <Label
            pointing="above"
            style={{ lineHeight: "18px", marginBottom: "10px" }}
          >
            Dla wszystkich części musi zostać zmieniony status. Suma liczby
            ilości danej referencji musi być zgodna z jej ilością.
          </Label>
          <div style={{ display: "inline-block", width: "100%" }}>
            <Button
              primary
              floated="right"
              onClick={handleDestroyedLineConfirm}
              style={{
                display: destroyedLines.length === 0 ? "none" : "inline-block",
              }}
              disabled={!validForm()}
              size="small"
            >
              Potwierdź RW
            </Button>
          </div>
          <Popup
            trigger={
              <Button circular basic icon="add" onClick={addDestroyedLine} />
            }
            content="Dodaj linię"
          />
        </>
      );
    },
    [
      addDestroyedLine,
      anomaly,
      changeDestroyedLineStatus,
      changeDestroyedLinesQuantity,
      destroyedLines,
      foundInvalidParts,
      handleDestroyedLineConfirm,
      removeDestroyedLine,
      validForm,
    ]
  );

  const { renderLabel } = useRenderingFunctions(anomaly, editMode);

  const renderAssociatedDocument = useCallback(
    (anomaly: Anomaly): JSX.Element | undefined => {
      const docId = anomaly.associatedDocumentID;
      const docNo = anomaly.associatedDocumentNo;

      if (anomaly.anomalyType.startsWith("GDN")) {
        return renderLabel(
          "Dokument powiązany",
          <GoodsDispatchNotesDetails noteId={docId}>
            <Link to="#">{docNo || docId}</Link>
          </GoodsDispatchNotesDetails>
        );
      }
      if (anomaly.anomalyType.includes("GRN")) {
        return renderLabel(
          "Dokument powiązany",
          <GoodsReceivedNotesDetails noteId={docId}>
            <Link to="#">{docNo || docId}</Link>
          </GoodsReceivedNotesDetails>
        );
      }
      if (anomaly.anomalyType.includes("ICN")) {
        return renderLabel(
          "Dokument powiązany",
          <InternalConsumptionNotesDetails noteId={docId}>
            <Link to="#">{docNo || docId}</Link>
          </InternalConsumptionNotesDetails>
        );
      }

      if (
        anomaly.anomalyType.startsWith("CROSS_DOCK") ||
        anomaly.anomalyType === "GDN_CLIENT_ORDER"
      ) {
        return renderLabel(
          "Zamówienie powiązane",
          <Link to={`/order/${docId}`}>{docNo || docId}</Link>
        );
      }
    },
    [renderLabel]
  );

  const cards = useMemo(
    () =>
      !anomaly
        ? []
        : [
            <DetailCard
              title="app.details"
              id="details"
              key="details"
              minHeight={100}
              width={isICNAnomalyStatus() ? 16 : 8}
              smallWidth={12}
            >
              {renderLabel(
                "Typ",
                mapDictionary(
                  anomaly.anomalyType,
                  dictionaries["anomaly-type"],
                  true, true, true
                )
              )}
              {renderLabel(
                "Status",
                mapDictionary(
                  anomaly.anomalyStatus,
                  dictionaries["anomaly-status"],
                  true, true, true
                )
              )}
              {renderAssociatedDocument(anomaly)}
              {anomaly.orderNo &&
                renderLabel(
                  "Zamówienie powiązane",
                  <Link target="_blank" to={`/order/${anomaly.orderID}`}>
                    {anomaly.orderNo}
                  </Link>
                )}
            </DetailCard>,
            !isICNAnomalyStatus() && anomaly.anomalyStatus !== "RESOLVED" ? (
              <DetailCard
                title="app.discrepancies"
                id="missingParts"
                key="missingParts"
                width={16}
                smallWidth={16}
                minHeight={100}
              >
                {renderPartsLines(anomaly.affectedParts.missingParts, true)}
              </DetailCard>
            ) : null,
            anomaly.anomalyType === "STOCKTAKING_GRN" ||
            anomaly.anomalyType ===
              "STOCKTAKING_ICN" ? null : anomaly.anomalyType === "ICN" &&
              anomaly.anomalyStatus === "RESOLVED" ? (
              <></>
            ) : (
              <DetailCard
                title={
                  !isICNAnomalyStatus()
                    ? "app.state.expected"
                    : "app.anomaly.change.parts.status"
                }
                id="partsExpected"
                key="partsExpected"
                width={8}
                smallWidth={8}
                minHeight={100}
              >
                {isICNAnomalyStatus()
                  ? renderPartsLinesICN(anomaly.affectedParts.partsExpected)
                  : renderPartsLines(
                      anomaly.affectedParts.partsExpected,
                      false
                    )}
              </DetailCard>
            ),
            anomaly.anomalyType === "STOCKTAKING_GRN" ||
            anomaly.anomalyType === "STOCKTAKING_ICN" ? null : (
              <DetailCard
                title={
                  !isICNAnomalyStatus()
                    ? "app.state.real"
                    : "app.anomaly.partsDestructed"
                }
                id="partsReceived"
                key="partsReceived"
                width={8}
                smallWidth={8}
                minHeight={100}
              >
                {renderPartsLines(
                  isICNAnomalyStatus()
                    ? anomaly.destroyedParts
                    : anomaly.affectedParts.partsReceived,
                  false
                )}
              </DetailCard>
            ),
          ],
    [
      anomaly,
      dictionaries,
      isICNAnomalyStatus,
      renderAssociatedDocument,
      renderLabel,
      renderPartsLines,
      renderPartsLinesICN,
    ]
  );

  const cardsRendered: JSX.Element = useMemo(() => {
    return (
      <Grid style={{ marginBottom: "unset" }} stretched>
        {cards}
      </Grid>
    );
  }, [cards]);

  const renderedAnomalyTitle: JSX.Element | string = useMemo(() => {
    if (!anomaly) {
      return "Wczytywanie...";
    }
    return anomaly.anomalyNo;
  }, [anomaly]);

  const renderActionButton = (
    { label, icon, message, type }: TActionData,
    selectionActive?: boolean
  ): JSX.Element => {
    return (
      <Button
        icon
        labelPosition="left"
        basic
        disabled={!editMode || (selectionActive && isInvalid())}
        style={{ marginRight: 10 }}
        onClick={() => {
          confirmMessage.current = message;
          actionHandler.current = type;
          setModalOpen(true);
        }}
      >
        <Icon name={icon} />
        {label}
      </Button>
    );
  };

  const { resolve, resolveSelected, close } = matchAnomalyAction(anomaly);

  const userHaveLdcUiAnomalyProcessRole = useMemo(
    () =>
      appContext?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ANOMALY_PROCESS
      ),
    [appContext]
  );

  return (
    <Fragment>
      <PageHeader
        icon="exclamation circle"
        title={<>Anomalia: {renderedAnomalyTitle}</>}
        breadcrumb={[
          { text: <FormattedMessage id="app.list" />, link: "/anomalies" },
          { text: <FormattedMessage id="app.details" /> },
        ]}
        buttons={[
          {
            icon: editMode ? "lock" : "edit",
            content: editMode ? (
              "Wył. edycję"
            ) : (
              <FormattedMessage id="app.button.edit" />
            ),
            onClick: toggleEditMode,
            primary: true,
            visible: !isNoneditableStatus() && userHaveLdcUiAnomalyProcessRole,
          },
        ]}
        refreshAction={() => {
          selectAnomaly(match.params.anomalyId);
        }}
        loading={loading}
      />
      <Container fluid>
        {resolve && renderActionButton(resolve, false)}
        {resolveSelected && renderActionButton(resolveSelected, true)}
        {close && renderActionButton(close, false)}
        {anomaly &&
          anomaly.anomalyType === "ICN" &&
          anomaly.anomalyStatus === "OPEN" && (
            <Button
              icon
              labelPosition="left"
              basic
              style={{ marginRight: 10 }}
              onClick={handleRWReject}
            >
              <Icon name="close" />
              Odrzuć RW
            </Button>
          )}
      </Container>
      <Divider />
      {cardsRendered}
      {anomaly && TYPE_GDN.includes(anomaly?.anomalyType) ? (
        <GDNConfirmModal
          setSelectedGDNOption={setSelectedGDNOption}
          selectedGDNOption={selectedGDNOption}
          handleCancelClick={handleCancelClick}
          modalOpen={modalOpen}
          onConfirmClick={handleConfirmClick}
        />
      ) : (
        <ModalConfirm
          modalOpen={modalOpen}
          contentText={confirmMessage.current}
          headerIcon="question circle"
          headerText="app.confirmOperation"
          onCancelClick={handleCancelClick}
          onConfirmClick={handleConfirmClick}
        />
      )}
      <CommonLoader loading={loading || !anomaly} />
    </Fragment>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  anomalies,
  dictionaries,
}: ApplicationState) => ({
  anomalyIn: anomalies.selected,
  loading: anomalies.loadingAnomaly,
  loadingDestroyedLines: anomalies.loadingDestoryedParts,
  dictionaries,
});

const mapDispatchToProps: TReduxActions = {
  selectAnomaly,
  resolveAnomaly,
  closeAnomaly,
  fetchDictionary,
  resolveDestroyedParts,
  rejectRW,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnomalyDetails);
