import { handleFileUploadError } from "~/utils/fileUploadErrorHandler";
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { ApplicationState } from "..";
import {
  callApi,
  callApiPatchUploadFile,
  callApiUploadFile,
  callApiWithRetry,
} from "~/utils/api";
import { toastError, toastSuccess, toastWarn } from "~/utils/toast";
import { handleError } from "~/store/error/actions";
import { PaginationMeta } from "~/store/types";
import {
  addOrderLine,
  applyPromoFinished,
  applyPromotion,
  changeStatusOrder,
  deleteOrderLine,
  fetchLinesSuccess,
  fetchOrderLines,
  fetchOrders,
  fetchSearchSuccess,
  fetchSuccess,
  linesOperationFinished,
  loadLinesCsv,
  loadLinesExcel,
  loadLinesLoaded,
  orderSelected,
  placeOrder,
  placeOrderSuccess,
  selectOrder,
  updateOrderLine,
  uploadBonusOrders,
  uploadBonusOrdersSuccess,
  uploadCancelLines,
  uploadCancelLinesSuccess,
  cancelLines,
  cancelLinesSuccess,
  cancelOrder,
  cancelOrderSuccess,
  prioritize,
  deprioritize,
  changeStatusOrderSuccess,
  exportOrdersOnRequest,
  exportOrdersOnRequestSuccess,
  exportOrdersOnRequestError,
} from "./actions";
import {
  Order,
  OrderBonusImportLine,
  OrdersActionTypes,
  PaginableOrderLine,
  TOrderCreate,
  TOrderImportLine,
} from "./types";
import translations from "~/utils/translations";
import flatten from "flat";
import { ApiError } from "../error/types";

function* handleFetchOrders(action: ReturnType<typeof fetchOrders>) {
  try {
    let filters = "";
    let sort = "";
    action.payload.filters.forEach((filter: any) => {
      if (
        action.meta &&
        filter.name === "deliveryType" &&
        filter.value === "URGENT"
      ) {
        filters += `&${filter.name}=URGENT,TURBO_PRO`;
      } else {
        filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
      }
    });

    if (action.payload.sortColumn && action.payload.sortDirection) {
      if (action.payload.sortColumn.includes("supplierSystem")) {
        sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`;
      } else {
        sort = `&sort=${action.payload.sortColumn.replace(".", "_")},${
          action.payload.sortDirection
        }`;
      }
    }

    const orders: Response = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/orders?page=${action.payload.page - 1}&size=${
        action.payload.size
      }${filters}${sort}`
    );

    action.type.toString() === OrdersActionTypes.FETCH_SEARCH_REQUEST
      ? yield put(fetchSearchSuccess(orders))
      : yield put(fetchSuccess(orders));
  } catch (error) {
    yield put(
      handleError(error, false, translations.format("app.error.order.load"))
    );
  }
}

function* handleExportOrdersOnRequest(
  action: ReturnType<typeof exportOrdersOnRequest>
) {
  try {
    let filters = "";

    const payloadFilters: any[] = action.payload.filters;
    payloadFilters.forEach((filter: any, index) => {
      filters += `${index === 0 ? "?" : "&"}${filter.name}=${filter.key ? filter.key : filter.value}`;
    });

    yield call(callApi, "POST", `/customer-panel/orders/export${filters}`);
    yield put(exportOrdersOnRequestSuccess());
    toastSuccess(
      "Złożono prośbę o eksport",
      `Twoja prośba o wyeksportowanie wybranych zamówień została zarejestrowana. Proszę czekać na plik który zostanie wysłany w podanym adresie email w ciągu 24 godzin.`,
      { timeOut: 10000 }
    );
  } catch (error) {
    yield put(exportOrdersOnRequestError());
    yield put(
      handleError(error, false, "Błąd eksportu zamówień", error.message)
    );
  }
}

function* handleSelectOrder(action: ReturnType<typeof selectOrder>) {
  try {
    const order: Order = yield call(
      callApiWithRetry,
      3,
      "get",
      `${action.meta ? "/customer-panel" : ""}/orders/${action.payload}`
    );
    if (!order.parts) {
      order.parts = order.lines;
    }
    yield put(orderSelected(order));
  } catch (error) {
    yield put(
      handleError(error, true, translations.format("app.error.order.select"))
    );
  }
}

function* handlePlaceOrder(action: ReturnType<typeof placeOrder>) {
  try {
    const res: string = yield call(
      callApi,
      "POST",
      `${action.meta ? "/customer-panel" : ""}/orders`,
      action.payload
    );
    yield delay(1000);
    yield put(placeOrderSuccess(res));
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.order.place-order.title"),
        translations.format("app.error.order.place-order")
      )
    );
  }
}

function* handleCancelOrder(action: ReturnType<typeof cancelOrder>) {
  try {
    const res: string = yield call(
      callApi,
      "POST",
      "/orders/cancel",
      action.payload
    );
    yield put(cancelOrderSuccess(res));
    toastSuccess("Anulowanie zamówienia", `Anulowano ${res} zamówień`);
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.order.cancelOrder.title"),
        translations.format("app.error.order.cancelOrder")
      )
    );
  }
}

function* handleChangeStatusOrder(
  action: ReturnType<typeof changeStatusOrder>
) {
  try {
    yield call(callApi, "PUT", `/orders/${action.payload}/status`, {
      status: action.meta.status,
    });
    yield put(changeStatusOrderSuccess(action.payload, action.meta));
    toastSuccess("app.info.common.update", "app.info.order.status-order");
  } catch (e) {
    const error = e as ApiError;
    if (error.message.includes("cannot be canceled. Coded parts:")) {
      const orderNr = error.message.split(" ")[1];
      const codedParts = error.message.split("[")[1].split("]")[0];
      const errorContent = translations.format(
        "app.error.order.status-order.manual-parts-confirmed",
        { orderNr, codedParts }
      );
      yield toastError("app.error.order.status-order", errorContent);
    } else {
      yield toastError("app.error.order.status-order", error.message);
    }
  }
}

function* handleApplyPromotion(action: ReturnType<typeof applyPromotion>) {
  try {
    const res: TOrderCreate = yield call(
      callApi,
      "POST",
      `${action.meta ? "/customer-panel" : ""}/calculator/order`,
      action.payload
    );
    yield put(applyPromoFinished(res));
  } catch (error) {
    yield put(handleError(error as Error, false, "Błąd zastosowania promocji"));
  }
}

function* handleFetchOrderLines(action: ReturnType<typeof fetchOrderLines>) {
  try {
    let filters = "";
    if (action.payload.filters) {
      action.payload.filters.forEach((filter: any) => {
        filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`;
      });
    }
    const res: PaginableOrderLine = yield call(
      callApi,
      "get",
      `${action.meta ? "/customer-panel" : ""}/orders/${action.payload.orderId}/lines?page=${
        action.payload.params.page - 1
      }&size=${action.payload.params.size}${filters}`
    );
    yield put(fetchLinesSuccess(res));
  } catch (error) {
    yield put(handleError(error, false, "Bład pobierania linii"));
  }
}

function* handleAddOrderLine(action: ReturnType<typeof addOrderLine>) {
  try {
    const part: TOrderImportLine[] = yield call(
      callApi,
      "get",
      `/spare-parts-price-list?query=${action.payload.line.referenceID}`
    );
    yield call(callApi, "post", `/orders/${action.payload.orderId}/lines`, {
      referenceID: action.payload.line.referenceID,
      netPriceListPrice: part[0].pvpPrice,
      pricePNR: part[0].pnrPrice,
      netPrice: part[0].pnrPrice,
      quantity: 1,
    });
    const meta: PaginationMeta = yield select(
      (state: ApplicationState) => state.backorders.linesMeta
    );
    const page = Math.floor((meta.total || 1) / meta.size) + 1;
    yield put(linesOperationFinished(true));
    yield put(
      fetchOrderLines(action.payload.orderId, { size: meta.size, page })
    );
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield put(handleError(error, false, "Błąd przy dodaniu nowej linii"));
  }
}

function* handleUpdateOrderLine(action: ReturnType<typeof updateOrderLine>) {
  try {
    yield call(
      callApi,
      "PATCH",
      `/orders/${action.payload.orderId}/lines/${action.payload.line.lineNo}`,
      flatten(action.payload.line)
    );
    const meta: PaginationMeta = yield select(
      (state: ApplicationState) => state.backorders.linesMeta
    );
    const page = Math.floor((meta.total || 1) / meta.size) + 1;
    yield put(linesOperationFinished(true));
    yield put(
      fetchOrderLines(action.payload.orderId, { size: meta.size, page })
    );
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield put(handleError(error, false, "Błąd przy aktualizacji linii"));
  }
}

function* handleDeleteOrderLine(action: ReturnType<typeof deleteOrderLine>) {
  try {
    yield call(
      callApi,
      "delete",
      `/orders/${action.payload.orderId}/lines/${action.payload.line.lineNo}`
    );
    const meta: PaginationMeta = yield select(
      (state: ApplicationState) => state.backorders.linesMeta
    );
    const page = Math.floor((meta.total || 1) / meta.size) + 1;
    yield put(linesOperationFinished(true));
    yield put(
      fetchOrderLines(action.payload.orderId, { size: meta.size, page })
    );
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield put(handleError(error, false, "Błąd przy usuwaniu linii"));
  }
}

function* handleUploadLinesExcel(action: ReturnType<typeof loadLinesExcel>) {
  try {
    const parsedLines: any[] = yield call(
      callApiUploadFile,
      `${action.meta ? "/customer-panel" : ""}/orders/import${action.meta ? "/lines" : ""}`,
      action.payload
    );
    yield put(loadLinesLoaded(parsedLines));
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield handleFileUploadError(error);
  }
}

function* handleUploadLinesExcelSpecial(
  action: ReturnType<typeof loadLinesExcel>
) {
  try {
    const parsedLines: any[] = yield call(
      callApiUploadFile,
      `/orders/import-special`,
      action.payload
    );
    yield put(loadLinesLoaded(parsedLines));
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield handleFileUploadError(error);
  }
}

function* handleUploadLinesCsv(action: ReturnType<typeof loadLinesCsv>) {
  try {
    const parsedLines: any[] = yield call(
      callApi,
      "post",
      `/orders/import/text`,
      { text: action.payload }
    );
    yield put(loadLinesLoaded(parsedLines));
  } catch (error) {
    yield put(linesOperationFinished(false));
    yield handleFileUploadError(error);
  }
}

function* handleUploadBonusOrders(
  action: ReturnType<typeof uploadBonusOrders>
) {
  try {
    const res: OrderBonusImportLine[] = yield call(
      callApiUploadFile,
      action.meta ? "/customer-panel/orders/import" : `/orders/bonus/import/`,
      action.payload,
      "file"
    );
    toastSuccess(
      "Pomyślnie wgrano plik",
      "Dane zostały poprawnie zaimportowane"
    );
    yield put(uploadBonusOrdersSuccess(res));
  } catch (e) {
    const error = e as ApiError;
    yield handleFileUploadError(error);
  }
}

function* handleUploadCancelLines(
  action: ReturnType<typeof uploadCancelLines>
) {
  try {
    const status: number = yield call(
      callApiPatchUploadFile,
      `/orders/lines/cancel`,
      action.payload,
      "file"
    );
    toastSuccess(
      "Pomyślnie wgrano plik",
      "Dane zostały poprawnie zaimportowane"
    );
    yield put(uploadCancelLinesSuccess(status));
  } catch (error) {
    yield handleFileUploadError(error);
  }
}

function* handleCancelLines(action: ReturnType<typeof cancelLines>) {
  let correctList: string[] = [];
  let wrongList: string[] = [];
  try {
    const status: number[] = yield call(
      callApi,
      "PATCH",
      `/orders/${action.payload.orderId}/lines/cancel`,
      action.payload.lines
    );
    status.map((res) => {
      if (Object.values(res)[0] === true) {
        correctList.push(Object.keys(res)[0]);
      } else if (Object.values(res)[0] === false) {
        wrongList.push(Object.keys(res)[0]);
      }
      return res;
    });
    if (wrongList.length === 0) {
      toastSuccess(
        "Pomyślnie anulowano linie",
        "Linie zostały poprawnie anulowane: " + correctList.toString()
      );
      yield put(cancelLinesSuccess(status));
    } else {
      if (correctList.length > 0) {
        toastSuccess(
          "Pomyślnie anulowano linie",
          "Linie zostały poprawnie anulowane: " + correctList.toString()
        );
      }
      wrongList.map((id) => {
        toastWarn(
          "Bład przy anulowaniu linii",
          "Nie udało się anulować linii: " + id
        );
        return wrongList;
      });
      yield put(cancelLinesSuccess(status));
    }
  } catch (error) {
    yield put(handleError(error, false, "Bład przy anulowaniu lini"));
  }
}

function* handlePrioritize(action: ReturnType<typeof prioritize>) {
  try {
    yield call(callApi, "PUT", `/orders/${action.payload.orderID}/prioritize`);
    yield put(selectOrder(action.payload.orderID));
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.order.cancelOrder.title"),
        translations.format("app.error.order.cancelOrder")
      )
    );
  }
}

function* handleDeprioritize(action: ReturnType<typeof deprioritize>) {
  try {
    yield call(
      callApi,
      "PUT",
      `/orders/${action.payload.orderID}/deprioritize`
    );
    yield put(selectOrder(action.payload.orderID));
  } catch (error) {
    yield put(
      handleError(
        error,
        false,
        translations.format("app.error.order.cancelOrder.title"),
        translations.format("app.error.order.cancelOrder")
      )
    );
  }
}

function* watchFetchOrders() {
  yield takeEvery(OrdersActionTypes.FETCH_REQUEST, handleFetchOrders);
}

function* watchFetchSearchOrders() {
  yield takeEvery(OrdersActionTypes.FETCH_SEARCH_REQUEST, handleFetchOrders);
}

function* watchSelectOrder() {
  yield takeEvery(OrdersActionTypes.SELECT_ORDER, handleSelectOrder);
}

function* watchPlaceOrder() {
  yield takeEvery(OrdersActionTypes.PLACE_ORDER, handlePlaceOrder);
}

function* watchCancelOrder() {
  yield takeEvery(OrdersActionTypes.CANCEL_ORDER, handleCancelOrder);
}

function* watchChangeStatusOrder() {
  yield takeEvery(
    OrdersActionTypes.CHANGE_STATUS_ORDER,
    handleChangeStatusOrder
  );
}

function* watchApplyPromotion() {
  yield takeEvery(OrdersActionTypes.APPLY_PROMO, handleApplyPromotion);
}

function* watchFetchOrderLines() {
  yield takeEvery(OrdersActionTypes.FETCH_LINES, handleFetchOrderLines);
}

function* watchAddOrderLine() {
  yield takeEvery(OrdersActionTypes.ADD_LINE, handleAddOrderLine);
}

function* watchUpdateOrderLine() {
  yield takeEvery(OrdersActionTypes.UPDATE_LINE, handleUpdateOrderLine);
}

function* watchDeleteOrderLine() {
  yield takeEvery(OrdersActionTypes.DELETE_LINE, handleDeleteOrderLine);
}

function* watchUploadLinesCsv() {
  yield takeEvery(OrdersActionTypes.LOAD_CSV, handleUploadLinesCsv);
}

function* watchUploadLinesExcel() {
  yield takeEvery(OrdersActionTypes.LOAD_XLS, handleUploadLinesExcel);
}

function* watchUploadLinesExcelSpecial() {
  yield takeEvery(
    OrdersActionTypes.LOAD_XLS_SPECIAL,
    handleUploadLinesExcelSpecial
  );
}

function* watchUploadBonusOrders() {
  yield takeEvery(
    OrdersActionTypes.UPLOAD_BONUS_ORDERS,
    handleUploadBonusOrders
  );
}

function* watchCancelLines() {
  yield takeEvery(OrdersActionTypes.CANCEL_LINES, handleCancelLines);
}

function* watchUploadCancelLines() {
  yield takeEvery(
    OrdersActionTypes.UPLOAD_CANCEL_LINES,
    handleUploadCancelLines
  );
}

function* watchPrioritize() {
  yield takeEvery(OrdersActionTypes.PRIORITIZE, handlePrioritize);
}

function* watchDeprioritize() {
  yield takeEvery(OrdersActionTypes.DEPRIORITIZE, handleDeprioritize);
}

function* watchExport() {
  yield takeEvery(
    OrdersActionTypes.EXPORT_ON_REQUEST,
    handleExportOrdersOnRequest
  );
}

function* ordersSaga() {
  yield all([
    fork(watchFetchOrders),
    fork(watchPlaceOrder),
    fork(watchCancelOrder),
    fork(watchSelectOrder),
    fork(watchChangeStatusOrder),
    fork(watchFetchSearchOrders),
    fork(watchApplyPromotion),
    fork(watchFetchOrderLines),
    fork(watchAddOrderLine),
    fork(watchUpdateOrderLine),
    fork(watchDeleteOrderLine),
    fork(watchUploadLinesCsv),
    fork(watchUploadLinesExcel),
    fork(watchUploadBonusOrders),
    fork(watchUploadCancelLines),
    fork(watchCancelLines),
    fork(watchUploadLinesExcelSpecial),
    fork(watchPrioritize),
    fork(watchDeprioritize),
    fork(watchExport),
  ]);
}

export default ordersSaga;
