import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  exportDocumentOnRequest,
  fetchCollectiveCorrections,
  getCollectiveCorrectionPdf,
} from "~/store/invoices/actions";
import { Table } from "semantic-ui-react";
import { ICollectiveCorrectionsList } from "~/store/invoices/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionariesState } from "~/store/dictionaries/types";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import IconHover from "~/components/IconHover/IconHover";
import { useCollectiveInvoiceCorrectionsColumns } from "./useCollectiveInvoiceCorrectionsCPColumns";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useCells } from "~/services/useCells";

type TComponentProps = {
  refreshTrigger: number;
  exportTrigger: number;
};

type TReduxState = {
  debitNotesCorrections: ICollectiveCorrectionsList[];
  loading: boolean;
  totalRecords: TotalRecords;
  meta: PaginationMeta;
  dictionaries: DictionariesState;
  collectiveCorrections?: any[];
};

interface TReduxActions {
  fetchCollectiveCorrections: typeof fetchCollectiveCorrections;
  getCollectiveCorrectionPdf: typeof getCollectiveCorrectionPdf;
  exportDocumentOnRequest: typeof exportDocumentOnRequest;
}

type DebitNotesCorrectionsProps = TComponentProps & TReduxState & TReduxActions;

const CollectiveInvoiceCorrectionsCP: React.FC<DebitNotesCorrectionsProps> = ({
  debitNotesCorrections,
  loading,
  meta,
  totalRecords,
  dictionaries,
  refreshTrigger,
  exportTrigger,
  exportDocumentOnRequest,
  fetchCollectiveCorrections,
  getCollectiveCorrectionPdf,
}) => {
  const { columnsConfig } = useCollectiveInvoiceCorrectionsColumns();
  const [tableKey] = useState<string>(
    "accounting/collective-corrections/collective-invoice-corrections-CP"
  );
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("createDateTime");
  const [sortDirection] = useState<any>("DESC");
  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const [params, setParams] = useState<{
    filters: TTableFilterSession[];
    [key: string]: any;
  }>({ filters: [] });

  const getDebitNotesCorretions = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchCollectiveCorrections(Object.assign(paramsObj, params), true);
    },
    [
      fetchCollectiveCorrections,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    filterLabelsRef.current = params.filters;
  }, [params]);

  const { completeColumnDictionaries } = useCells({ tableKey, dictionaries });

  useEffect(() => {
    setColumns((items) => completeColumnDictionaries(items));
  }, [dictionaries, completeColumnDictionaries]);

  useEffect(() => {
    if (refreshTrigger) {
      getDebitNotesCorretions({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getDebitNotesCorretions]);

  useEffect(() => {
    if (exportTrigger) {
      exportDocumentOnRequest(
        "/customer-panel/accounting/collective-invoice-corrections/export",
        {
          ...params,
        }
      );
    }
    // eslint-disable-next-line
  }, [exportTrigger, exportDocumentOnRequest]);

  return (
    <Fragment>
      <DataGridTable
        columns={columns}
        isAdvancedSearchOpen={false}
        meta={meta}
        loading={loading}
        totalRecords={totalRecords}
        fetchMethod={getDebitNotesCorretions}
        initSortColumn={sortColumn}
        initSortDirection={sortDirection}
        tableKey={tableKey}
        dictionaries={dictionaries}
        setColumns={setColumns}
      >
        {debitNotesCorrections.map(
          (document: ICollectiveCorrectionsList, index: number) => (
            <Table.Row disabled={!!document.cancellationReason} key={index}>
              {columns &&
                columns.length &&
                columns.map(
                  (column) =>
                    column.getCell &&
                    column.projection &&
                    column.getCell(document, column)
                )}
              <Table.Cell className="col-dropdown-menu-sticky">
                <IconHover
                  name="download"
                  title="Pobierz"
                  onClick={() =>
                    getCollectiveCorrectionPdf(
                      document.id,
                      `${document.documentMask}.pdf`,
                      true
                    )
                  }
                />
              </Table.Cell>
            </Table.Row>
          )
        )}
      </DataGridTable>
    </Fragment>
  );
};

const mapStateToProps = ({
  invoices,
  dictionaries,
}: ApplicationState): TReduxState => ({
  debitNotesCorrections: invoices.collectiveCorrectionsList.list,
  loading: invoices.loadingCollectiveCorretions,
  totalRecords: invoices.collectiveCorrectionsList.totalRecords,
  meta: invoices.collectiveCorrectionsList.meta,
  dictionaries: dictionaries,
  collectiveCorrections: invoices.collectiveCorrections,
});

const mapDispatchToProps: TReduxActions = {
  fetchCollectiveCorrections,
  getCollectiveCorrectionPdf,
  exportDocumentOnRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectiveInvoiceCorrectionsCP);
