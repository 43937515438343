import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { Accordion, Divider, Form, Icon, Table } from "semantic-ui-react";
import { localStorageService } from "~/services/LocalStorage";
import { ApplicationState, ConnectedReduxProps } from "~/store";
import {
  fetchStockProducts,
  removeStockLine,
  updateMinMaxStockItem,
} from "~/store/distrigo-warehouses/actions";
import { StockWarehouse } from "~/store/distrigo-warehouses/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { DictionaryItem } from "~/store/dictionaries/types";

import React, { Fragment, PureComponent, SyntheticEvent } from "react";
import ColumnToggler from "~/components/ColumnToggler/ColumnToggler";
import CommonLoader from "~/components/Loaders/CommonLoader";
import PageHeader from "~/components/PageHeader/PageHeader";
import FilterLabels from "~/components/TableFilter/lib/FilterLabels";
import TableFilter, { IRefObject } from "~/components/TableFilter/TableFilter";
import AddSparePartStock from "./lib/Add/Add";
import RejectToSupplier from "./lib/RejectToSupplier";
import UploadStockXls from "./lib/Upload";
import StockProductsList from "./List";
import TableSortSession, { TTableSortSession } from "~/utils/tableSortSession";
import TableFilterSession from "~/utils/tableFilterSession";
import TableHeaderSortingCell from "~/components/TableHeaderSortingCell/TableHeaderSortingCell";
import TablePaginationFooter from "~/components/TablePaginationFooter/TablePaginationFooter";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import InternalConsumption from "./lib/InternalConsumption";
import MoveWare from "./lib/MoveWare/MoveWare";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";
import { AppContext } from "~/context/AuthContext";
import translations from "~/utils/translations";

type TRouteParams = RouteComponentProps<{
  id: string;
}>;

interface PropsFromState {
  loading: boolean;
  stocksWarehouse: StockWarehouse[];
  meta: PaginationMeta;
  totalRecords: TotalRecords;
}

interface PropsFromDispatch {
  fetchStockProducts: typeof fetchStockProducts;
  updateMinMaxStockItem: typeof updateMinMaxStockItem;
  removeStockLine: typeof removeStockLine;
}

type AppProps = PropsFromState &
  PropsFromDispatch &
  ConnectedReduxProps &
  TRouteParams;

interface State {
  filterLabels: any[];
  columns: {
    name: string;
    label: string;
    projection: boolean;
    dictionary?: DictionaryItem[];
    editable?: boolean;
    type?: string;
    noKey?: boolean;
  }[];
  sortColumn: string | undefined | TTableSortSession;
  sortDirection: "ASC" | "DESC" | undefined | TTableSortSession;
  editMode: boolean;
  selection: StockWarehouse[];
  addSparePartVisible: boolean;
  openAddModal: boolean;
  openUploadModal: boolean;
  openRejectModal: boolean;
  openInternalConsumptionModal: boolean;
  openCollectiveTaskModal: boolean;
  openMoveWareModal: boolean;
  activeIndex: number;
  value: string;
  dateFrom?: Date;
  dateTo?: Date;
}

interface storageStocksWarehouseColumns {
  name: string;
  shouldDisplay: boolean;
}

interface HandleChange {
  [key: string]: string | any;
}

class StocksWarehouse extends PureComponent<AppProps, State> {
  private stockableDictionary: DictionaryItem[] = [
    { key: "true", value: "true", text: "Składowane" },
    { key: "false", value: "false", text: "Nieskładowane" },
  ];

  private getTableSortSession = (
    table: boolean,
    name?: string
  ): undefined | TTableSortSession => {
    if (table) {
      return TableSortSession.getSorting("stockProducts");
    } else {
      //@ts-ignore: no-undefined-result
      return TableSortSession.getSorting("stockProducts")[name];
    }
  };

  private getTableFilterSession = (): any => {
    return TableFilterSession.getFilters("stockProducts");
  };

  state: State = {
    filterLabels:
      [{ name: "stockable", value: true, text: "Składowane" }] ||
      this.getTableFilterSession(),
    sortColumn: this.getTableSortSession(true)
      ? this.getTableSortSession(false, "value")
      : undefined,
    sortDirection: TableSortSession.getSorting("stockProducts")
      ? this.getTableSortSession(false, "direction")
      : undefined,
    columns: [
      {
        name: "referenceWarehouseId.referenceId",
        label: "ID produktu",
        projection: true,
      },
      { name: "designationPolish", label: "Nazwa produktu", projection: true },
      {
        name: "updateTime",
        label: "Aktualizacja",
        projection: true,
        type: "date",
      },
      {
        name: "stockable",
        label: "Składowane",
        dictionary: this.stockableDictionary,
        noKey: true,
        projection: true,
      },
      { name: "stockMin", label: "Składowanie min", projection: true },
      { name: "stockMax", label: "Składowanie max", projection: true },
      { name: "available", label: "Dostępne", projection: true },
      { name: "reserved", label: "Zarezerwowane", projection: true },
      { name: "blockedByAnomaly", label: "Zablokowane", projection: true },
      { name: "inTransport", label: "W transporcie", projection: true },
      { name: "awaitingForRemoval", label: "Do zniszczenia", projection: true },
      {
        name: "qualityControl",
        label: "Towar niepełnowartościowy",
        projection: true,
      },
      { name: "toReturn", label: "Do zwrotu", projection: true },
      { name: "total", label: "Do waloryzacji", projection: true },
      {
        name: "replenishmentRequested",
        label: "SAP - Zamówione",
        projection: true,
      },
      {
        name: "replenishmentConfirmed",
        label: "SAP - Do przyjęcia",
        projection: true,
      },
    ],
    editMode: false,
    selection: [],
    addSparePartVisible: false,
    openAddModal: false,
    openUploadModal: false,
    openRejectModal: false,
    openInternalConsumptionModal: false,
    openCollectiveTaskModal: false,
    openMoveWareModal: false,
    activeIndex: 1,
    value: "",
    dateFrom: undefined,
    dateTo: undefined,
  };

  private tableFilter = React.createRef<IRefObject>();

  componentDidMount(): void {
    document.title = translations.format("app.stock-products");
    if (this.props.location.search) {
      new URLSearchParams(this.props.location.search).forEach((value, name) => {
        if (value && value !== "null") {
          this.addUpdateFilterLabel(name, value, value);
        }
      });
    }
    this.getStocksWarehouse();
    this.setColumnsFromLocalStorage();
  }

  componentDidUpdate(prevProps: AppProps, prevState: State): void {
    if (
      JSON.stringify(this.state.filterLabels) !==
      JSON.stringify(prevState.filterLabels)
    ) {
      this.getStocksWarehouse({ page: 1 });
    }

    if (
      this.state.sortColumn !== prevState.sortColumn ||
      this.state.sortDirection !== prevState.sortDirection
    ) {
      this.getStocksWarehouse({
        sortColumn: this.state.sortColumn,
        sortDirection: this.state.sortDirection,
        page: 1,
      });
    }
  }

  private getStocksWarehouse(params?: Object): void {
    const { meta } = this.props;
    const paramsObj = {
      page: meta.page,
      size: meta.size,
      filters: this.state.filterLabels,
      sortColumn: this.state.sortColumn,
      sortDirection: this.state.sortDirection,
      id: this.props.match.params.id,
    };
    this.props.fetchStockProducts(Object.assign(paramsObj, params));
  }

  private handleSort(name: string): void {
    if (this.state.sortColumn !== name) {
      this.setState({
        sortColumn: name,
        sortDirection: "ASC",
      });
    } else {
      this.setState((state) => ({
        sortDirection: state.sortDirection === "ASC" ? "DESC" : "ASC",
        sortColumn: name,
      }));
    }
    TableSortSession.setSoring({
      key: "stockProducts",
      value: name,
      direction: this.state.sortDirection === "ASC" ? "DESC" : "ASC",
    });
  }

  private setColumnsFromLocalStorage(): void {
    const columnsLS: storageStocksWarehouseColumns[] = localStorageService.get(
      "stocksWarehouseColumns"
    );

    if (columnsLS) {
      let { columns } = this.state;
      for (const columnFilter of columnsLS) {
        columns = columns.map((column) =>
          column.name === columnFilter.name
            ? { ...column, projection: columnFilter.shouldDisplay }
            : { ...column }
        );
      }
      this.setState({ columns });
    }
  }

  private toggleDistrigoWarehouseColumn = (
    name: string,
    checked: boolean
  ): void => {
    this.setState((state) => ({
      columns: state.columns.map((column) => {
        return column.name === name
          ? { ...column, projection: checked }
          : { ...column };
      }),
    }));
  };

  private removeFilterLabel(name: string): void {
    this.setState((state) => ({
      filterLabels: state.filterLabels.filter((label) => label.name !== name),
    }));
    TableFilterSession.clearFilter({ name, table: "stockProducts", value: "" });
    if (this.tableFilter.current) {
      this.tableFilter.current.clearFilter(name);
    }
  }

  private clearAllFilter(): void {
    this.setState({ filterLabels: [] });
    if (this.tableFilter.current) {
      this.tableFilter.current.clearAllFilter();
    }
    TableFilterSession.clearFilter(undefined, "stockProducts");
  }

  private addUpdateFilterLabel(
    name: string,
    value: string,
    text?: string,
    key?: string
  ): void {
    this.setState((state) => {
      const found = state.filterLabels.find((label) => label.name === name);
      return {
        filterLabels: found
          ? state.filterLabels.map((label) =>
              label.name === name ? { name, value, text, key } : { ...label }
            )
          : [...state.filterLabels, { name, value, text, key }],
      };
    });
    if (name && value) {
      TableFilterSession.setFilters({
        name,
        value,
        text,
        key,
        table: "stockProducts",
      });
    }
  }

  private pageChange = (page: number): void => {
    this.getStocksWarehouse({ page });
  };

  private pageSizeChange = (size: number): void => {
    this.getStocksWarehouse({ size });
  };

  private handleAddFilter = (
    name: string,
    value: string,
    text?: string,
    key?: string
  ): void => {
    let checkIsInFilterTable = false;
    this.state.filterLabels.forEach((filter) => {
      if (filter.value === value && filter.name === name) {
        checkIsInFilterTable = true;
      }
    });
    if (!checkIsInFilterTable) {
      value !== ""
        ? this.addUpdateFilterLabel(name, value, text, key)
        : this.removeFilterLabel(name);
    }
  };

  private toggleEditMode = (): void =>
    this.setState({ editMode: !this.state.editMode });

  private handleOpenAddModal = (): void => {
    this.setState({ ...this.state, openAddModal: true }, () => {
      this.setState({ ...this.state, openAddModal: false });
    });
  };

  private handleOpenCreateReceiptOfWareView = (): void => {
    this.props.history.push(
      `/stock-product/${this.props.match.params.id}/create-receipt-of-ware`
    );
  };

  private handleOpenMoveWareModal = (): void => {
    this.setState({ openMoveWareModal: true });
  };

  private closeMoveWareModal = () => {
    this.setState({ openMoveWareModal: false });
  };

  private handleOpenUploadModal = (): void => {
    this.setState({ ...this.state, openUploadModal: true }, () => {
      this.setState({ ...this.state, openUploadModal: false });
    });
  };

  private handleOpenRejectModal = (): void => {
    this.setState({ ...this.state, openRejectModal: true }, () => {
      this.setState({ ...this.state, openRejectModal: false });
    });
  };

  private handleInternalConsumption = (): void => {
    this.setState({ ...this.state, openInternalConsumptionModal: true }, () => {
      this.setState({ ...this.state, openInternalConsumptionModal: false });
    });
  };

  private changeSelection = (item: StockWarehouse): void => {
    if (
      !this.state.selection.find(
        (selected) =>
          selected.referenceWarehouseId.referenceId ===
          item.referenceWarehouseId.referenceId
      )
    ) {
      this.setState({
        ...this.state,
        selection: [...this.state.selection, item],
      });
    } else {
      this.setState({
        ...this.state,
        selection: this.state.selection.filter(
          (selected) =>
            selected.referenceWarehouseId.referenceId !==
            item.referenceWarehouseId.referenceId
        ),
      });
    }
  };

  private editModeButtonVisible = (): boolean => {
    const { columns } = this.state;
    const stockMin = columns.find((x) => x.name === "stockMin");
    const stockMax = columns.find((x) => x.name === "stockMax");

    if (stockMin && stockMax) {
      return stockMin.projection || stockMax.projection;
    }
    return false;
  };

  private handleUploadCreateSparePartStock = (): void => {
    this.getStocksWarehouse();
    this.setState({ selection: [] });
  };

  private handleAccordionClick = (e: any, titleProps: { index: any }) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (
    e: SyntheticEvent<Element, Event> | undefined,
    { name, value }: HandleChange
  ) => {
    this.setState<never>({ [name]: value });
  };

  handleSubmit = () => {
    const { dateFrom, dateTo } = this.state;
    let formDateTo: string | Date;
    let formDateFrom: string;
    let formDateBetween: string;
    const tzzoffset: number = new Date().getTimezoneOffset() * 60000;
    if (dateTo && dateFrom) {
      formDateTo = new Date(Number(dateTo) - tzzoffset);
      formDateTo.setUTCHours(23, 59, 59, 0);
      formDateBetween =
        new Date(Number(dateFrom) - tzzoffset).toISOString().substring(0, 19) +
        "::" +
        formDateTo.toISOString().substring(0, 19);
      this.handleAddFilter("updateTime", formDateBetween, "Data od do");
    } else if (dateTo) {
      formDateTo = new Date(Number(dateTo) - tzzoffset);
      formDateTo.setUTCHours(23, 59, 59, 0);
      formDateTo = "::" + formDateTo.toISOString().substring(0, 19);
      this.handleAddFilter("updateTime", formDateTo, "Data do");
    } else if (dateFrom) {
      formDateFrom =
        new Date(Number(dateFrom) - tzzoffset).toISOString().substring(0, 19) +
        "::";
      this.handleAddFilter("updateTime", formDateFrom, "Data od");
    } else {
      this.handleAddFilter("updateTime", "");
    }
  };

  render() {
    const { loading, stocksWarehouse, meta, match, totalRecords } = this.props;
    const {
      editMode,
      selection,
      openAddModal,
      openUploadModal,
      openRejectModal,
      openMoveWareModal,
      openInternalConsumptionModal,
    } = this.state;
    const { activeIndex } = this.state;
    const calcHeight = "calc(91vh - 150px)";
    const calcHeightWithOpenAccordion = "calc(70vh - 150px)";

    if (this.props.location.search) {
      return <Redirect to={`/stock-products/PL9999P`} />;
    }

    const userHaveLdcUiWarehouseViewRole =
      this.context?.keycloak?.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_WAREHOUSE_EDIT
      );

    return (
      <Fragment>
        <MoveWare triggerOpen={openMoveWareModal} />
        <AddSparePartStock
          createdSuccess={this.handleUploadCreateSparePartStock}
          warehouseId={match.params.id}
          triggerOpen={openAddModal}
        />
        <UploadStockXls
          createdSuccess={this.handleUploadCreateSparePartStock}
          warehouseId={match.params.id}
          triggerOpen={openUploadModal}
        />
        <RejectToSupplier
          createdSuccess={this.handleUploadCreateSparePartStock}
          warehouseId={match.params.id}
          selection={selection}
          triggerOpen={openRejectModal}
        />
        <InternalConsumption
          createdSuccess={this.handleUploadCreateSparePartStock}
          selection={selection}
          triggerOpen={openInternalConsumptionModal}
        />
        <PageHeader
          icon="warehouse"
          title={<>Magazyn: {match.params.id}</>}
          breadcrumb={[
            {
              text: <FormattedMessage id="app.list" />,
              link: "/distrigo-warehouses",
            },
            { text: <FormattedMessage id="app.details" /> },
          ]}
          buttons={[
            {
              icon: "add",
              content: "Przyjęcie",
              primary: true,
              onClick: this.handleOpenCreateReceiptOfWareView,
              visible: userHaveLdcUiWarehouseViewRole,
            },
            {
              icon: "add",
              content: "Przesuń",
              primary: true,
              onClick: this.handleOpenMoveWareModal,
              visible: userHaveLdcUiWarehouseViewRole,
            },
            {
              icon: "add",
              content: "Dodaj",
              primary: true,
              onClick: this.handleOpenAddModal,
              visible: userHaveLdcUiWarehouseViewRole,
            },
            {
              icon: "upload",
              content: "Import",
              primary: true,
              onClick: this.handleOpenUploadModal,
              visible: userHaveLdcUiWarehouseViewRole,
            },
            {
              icon: editMode ? "lock" : "edit",
              content: editMode ? (
                "Wył. edycję"
              ) : (
                <FormattedMessage id="app.button.edit" />
              ),
              onClick: this.toggleEditMode,
              primary: true,
              visible:
                this.editModeButtonVisible() && userHaveLdcUiWarehouseViewRole,
            },
            {
              icon: { name: "reply", flipped: "vertically" },
              content: "Zwrot",
              secondary: true,
              visible: userHaveLdcUiWarehouseViewRole,
              onClick: this.handleOpenRejectModal,
              disabled: selection.length === 0,
              popup:
                selection.length === 0
                  ? "Zaznacz elementy do zwrotu."
                  : undefined,
            },
            {
              icon: "briefcase",
              content: "Rozchód wewnętrzny",
              secondary: true,
              onClick: this.handleInternalConsumption,
              disabled: selection.length === 0,
              visible: userHaveLdcUiWarehouseViewRole,
              popup:
                selection.length === 0 ? "Zaznacz elementy do RW." : undefined,
            },
          ]}
          refreshAction={() => this.getStocksWarehouse()}
          loading={loading}
        />
        <Accordion key="invoices-ac" styled style={{ width: "100%" }}>
          <Accordion.Title
            key="invoices-ac0"
            active={this.state.activeIndex === 0}
            index={0}
            onClick={this.handleAccordionClick}
          >
            <Icon name="dropdown" />
            Wyszukiwanie zaawansowane
          </Accordion.Title>
          <Accordion.Content active={this.state.activeIndex === 0}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group unstackable={false} widths={5}>
                <SemanticDatepicker
                  label="Data od:"
                  id="initialDate"
                  name="dateFrom"
                  value={this.state.dateFrom}
                  placeholder="RRRR-MM-DD"
                  locale="pl-PL"
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                <SemanticDatepicker
                  label="Data do:"
                  id="finalDate"
                  minDate={this.state.dateFrom}
                  name="dateTo"
                  value={this.state.dateTo}
                  placeholder="RRRR-MM-DD"
                  locale="pl-PL"
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group>
                <Form.Button icon="search" content="Szukaj" />
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Accordion>
        <Divider />
        <div
          className="uber-table"
          style={{
            height:
              activeIndex === 0 ? calcHeightWithOpenAccordion : calcHeight,
          }}
        >
          <FilterLabels
            filterLabels={this.state.filterLabels}
            removeFilterLabel={this.removeFilterLabel.bind(this)}
            clearAllFilter={this.clearAllFilter.bind(this)}
          />
          <div className="docked">
            <ColumnToggler
              onToggle={this.toggleDistrigoWarehouseColumn}
              columns={this.state.columns}
              storageKey={"stocksWarehouse"}
            />
          </div>
          <Table style={{ whiteSpace: "nowrap" }} selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {this.state.columns.map(
                  (column) =>
                    column.projection && (
                      <Fragment key={column.name}>
                        <TableHeaderSortingCell
                          name={column.name}
                          label={column.label}
                          onSort={this.handleSort.bind(this, column.name)}
                          sortColumn={this.state.sortColumn}
                          sortDirection={this.state.sortDirection}
                        />
                      </Fragment>
                    )
                )}
                <Table.HeaderCell collapsing />
              </Table.Row>
              <TableFilter
                ref={this.tableFilter}
                initValues={this.state.filterLabels}
                columns={this.state.columns}
                onAddFilter={this.handleAddFilter}
                icon="left"
              />
            </Table.Header>
            <Table.Body>
              <StockProductsList
                stocksWarehouse={stocksWarehouse}
                editMode={this.state.editMode}
                columns={this.state.columns}
                selection={selection}
                updateSelection={this.changeSelection}
                updateMinMaxStock={this.props.updateMinMaxStockItem}
                removeStockLine={this.props.removeStockLine}
              />
            </Table.Body>
            <TablePaginationFooter
              meta={meta}
              totalRecords={totalRecords}
              onPageChange={this.pageChange}
              onPageSizeChange={this.pageSizeChange}
            />
          </Table>
        </div>
        <CommonLoader loading={loading} />
        {openMoveWareModal && (
          <MoveWare
            closeMoveWareModal={this.closeMoveWareModal}
            openMoveWareModal={openMoveWareModal}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ distrigoWarehouses }: ApplicationState) => ({
  stocksWarehouse: distrigoWarehouses.stockItemsList,
  meta: distrigoWarehouses.meta,
  loading: distrigoWarehouses.loadingStockItems,
  totalRecords: distrigoWarehouses.totalRecords,
});

const mapDispatchToProps = {
  fetchStockProducts,
  updateMinMaxStockItem,
  removeStockLine,
};

StocksWarehouse.contextType = AppContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StocksWarehouse));
