import React from 'react';
import { useBackordersRolesRights } from './useBackordersRolesRights';
import { FormattedMessage } from 'react-intl';

type Props = {
  exportPercent: number;
  disableButton: boolean;
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDownloadModal: () => Promise<void>;
};

export const useBackordersButtons = ({
  disableButton,
  exportPercent,
  handleOpenDownloadModal,
  setOpenCreateModal,
}: Props) => {
  const { userHaveLdcUiBackorderProcessRole } = useBackordersRolesRights();

  const downloadButton = {
    icon: 'download',
    content: 'Eksport zamówień',
    primary: true,
    onClick: () => handleOpenDownloadModal(),
    window: exportPercent,
    disabled: disableButton,
  };

  const addButton = {
    icon: 'add',
    content: <FormattedMessage id="app.button.add" />,
    primary: true,
    onClick: () => setOpenCreateModal(true),
    visible: userHaveLdcUiBackorderProcessRole,
  };

  return [downloadButton, addButton];
};
