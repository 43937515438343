export enum PROMO_IMPORT_CODE_ERROR {
    PROMO_FIELD_EXCEEDING_CONSTRAINTS = "PROMO_FIELD_EXCEEDING_CONSTRAINTS",
    PROMO_HIERARCHY_NOT_FOUND = "PROMO_HIERARCHY_NOT_FOUND",
    PROMO_FILE_PARSE_ERROR = "PROMO_FILE_PARSE_ERROR",
    PROMO_MANDATORY_FIELD_NULL = "PROMO_MANDATORY_FIELD_NULL",
    IMPORTED_PROMO_COLLISION = "IMPORTED_PROMO_COLLISION",
    IMPORTED_PROMO_ACCEPTANCE_ERROR = "IMPORTED_PROMO_ACCEPTANCE_ERROR",
    PROMO_COLLISION = "PROMO_COLLISION",
    ENUM_IN_FILE_PARSE_ERROR = "ENUM_IN_FILE_PARSE_ERROR",
    FILE_PARSE_ERROR = "FILE_PARSE_ERROR"
}

export const formatPromoImportErrorMessage = (additionalData: any, errorCode: string): string => {
    if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.PROMO_FIELD_EXCEEDING_CONSTRAINTS)) {
        return `Wprowadzona wartość ${additionalData.value} w kolumnie ${additionalData.fieldName} nie mieści się w akceptowalnym zakresie, min - ${additionalData.min}, max - ${additionalData.max}`
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.PROMO_HIERARCHY_NOT_FOUND)) {
        return `${additionalData.nodeType} o wartości ${additionalData.value} nie istnieje`
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.PROMO_FILE_PARSE_ERROR)) {
        return `System nie jest w stanie przeprocesować wartości w kolumnie ${additionalData}`
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.PROMO_MANDATORY_FIELD_NULL)) {
        return `Wartości w kolumnie ${additionalData} są obowiązkowe`
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.IMPORTED_PROMO_COLLISION)) {
        return "Wprowadzony plik zawiera przynajmniej dwie promocje, których grupy ze sobą kolidują"
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.IMPORTED_PROMO_ACCEPTANCE_ERROR)) {
        return ActivatePromoAcceptanceErrorsMap[additionalData]
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.PROMO_COLLISION)) {
        return "W systemie istnieją już aktywowane promocje z tym samym priorytetem na tę samą grupę produktów"
    } else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.ENUM_IN_FILE_PARSE_ERROR)) {
        return `Wprowadzona wartość trybu promocji: ${additionalData.badValue} jest niepoprawna. Możliwe wartości to: ${additionalData.possibleValues[0]}, ${additionalData.possibleValues[1]}`
    }else if (isErrorCodeOfType(errorCode, PROMO_IMPORT_CODE_ERROR.FILE_PARSE_ERROR)) {
        return "Wybrany typ promocji jest nieodpowiedni w stosunku do importowanych promocji. W pliku może znajdować się błąd."
    }
    return "Wystąpił nieznany błąd"
}

export const isErrorCodeOfType = (errorCode: string, type: PROMO_IMPORT_CODE_ERROR) => {
    return PROMO_IMPORT_CODE_ERROR[errorCode as keyof typeof PROMO_IMPORT_CODE_ERROR] === type
}

export const ActivatePromoAcceptanceErrorsMap: { [key: string]: string } = {
    "PROMO_INSUFFICIENT_DISCOUNTS_DATA": "Zdefiniowane upusty nie są wystarczające do utworzenia promocji",
    "PROMO_INSUFFICIENT_RULES_DATA": "Zdefiniowane grupy produktów nie są wystarczające do utworzenia promocji",
    "PROMO_INVALID_DATES": "Daty są nieprawidłowe",
    "PROMO_REQUIRED_DATA_NOT_PROVIDED": "Wymagane dane nie zostały uzupełnione",
    "PROMO_CARDINALITY_MISSING": "Liczność grup nie została wprowadzona"
}
