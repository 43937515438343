import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  Modal,
} from "semantic-ui-react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import CommonLoader from "~/components/Loaders/CommonLoader";
import { connect } from "react-redux";
import { ApplicationState } from "~/store";
import {
  fetchClassesAndBrands,
  selectCustomer,
  updateCustomer,
} from "~/store/customer/actions";
import {
  ClassAndBrands,
  ClassAndBrandsExpanded,
  Customer,
  CustomerClasses,
} from "~/store/customer/types";
import translations from "~/utils/translations";

import './style.scss';

type TReduxState = {
  classesAndBrands?: ClassAndBrands[];
  updateCustomerPending: boolean;
};

type TReduxActions = {
  fetchClassesAndBrands: typeof fetchClassesAndBrands;
  updateCustomer: typeof updateCustomer;
  selectCustomer: typeof selectCustomer;
};

type TProps = TReduxState &
  TReduxActions & {
    loading: boolean;
    triggerButton?: ReactElement;
    customer: Customer | undefined;
  };

const ClassAndBrandsModal: React.FC<TProps> = ({
  loading,
  triggerButton,
  fetchClassesAndBrands,
  classesAndBrands,
  customer,
  updateCustomer,
  updateCustomerPending,
  selectCustomer,
}) => {
  const [brands, setBrands] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [classes, setClasses] = useState<ClassAndBrandsExpanded[]>();
  const [validateErrorClasses, setValidateEroorClasses] =
    useState<boolean>(false);
  const [validateErrorBrands, setValidateEroorBrands] =
    useState<boolean>(false);
  const [validateErrorCodes, setValidateEroorCodes] = useState<boolean>(false);

  const mapBrands = (
    classesToMap: {
      checked: boolean;
      brands: {
        name: string;
        checked: boolean;
        code: string;
        brandRequired: boolean;
      }[];
      name: CustomerClasses;
      brandRequired: boolean;
    }[]
  ) => {
    const newBrands: any[] = [];
    classesToMap.forEach((customerClass) => {
      if (customerClass.checked) {
        newBrands.push(customerClass.brands.map((brand) => ({ ...brand })));
      }
    });
    const flatBrands = newBrands.flat();
    const ids = flatBrands.map(({ name }) => name);
    const filtered = flatBrands.filter(
      ({ name }, index) => !ids.includes(name, index + 1)
    );
    setBrands(filtered);
  };

  const mapClasses = useCallback(
    (data: ClassAndBrands[] | undefined) => {
      if (!data || !data.length || !customer) return;
      const { customerClasses, customerBrands } = customer;
      const dataToReturn = data.map((item) => ({
        ...item,
        checked: customerClasses
          ? !!customerClasses.find((classItem) => classItem === item.name)
          : false,
        brands: item.brands.map((brand: any) => ({
          name: brand,
          checked: !!customerBrands?.find(
            (customerBrand) => customerBrand.brand === brand
          ),
          code: customerBrands
            ? customerBrands.find((brandItem) => brandItem.brand === brand)
                ?.code || ""
            : "",
          brandRequired:
            item.name === CustomerClasses.AUTHORIZED_SERVICE
              ? false
              : item.brandRequired,
        })),
      }));
      mapBrands(dataToReturn);
      setClasses(dataToReturn);
    },
    [customer]
  );

  useEffect(() => {
    if (open && !classesAndBrands) {
      fetchClassesAndBrands();
    }
  }, [open, fetchClassesAndBrands, classesAndBrands]);

  useEffect(() => {
    if (open && classesAndBrands) {
      mapClasses(classesAndBrands);
    }
  }, [open, classesAndBrands, mapClasses]);

  const handleCancel = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
    selectCustomer(customer!.rrdi);
  };

  const handleSave = (): void => {
    const data = prepareData();
    const isValid = validate();
    if (!customer || !data || !isValid) return;
    updateCustomer({ ...data, rrdi: customer.rrdi });
    handleCancel();
  };

  const resetErrors = (errorToReset: "classes" | "brands" | "codes"): void => {
    if (errorToReset && errorToReset === "classes") {
      setValidateEroorClasses(false);
      setValidateEroorBrands(false);
      setValidateEroorCodes(false);
    }
    if (errorToReset && errorToReset === "brands") {
      setValidateEroorBrands(false);
      setValidateEroorCodes(false);
    }
    if (errorToReset && errorToReset === "codes") {
      setValidateEroorCodes(false);
    }
  };

  const classClickHandle = (className: string): void => {
    if (!classes || !customer) return;
    const { customerBrands } = customer;
    resetErrors("classes");
    const index = classes.findIndex((item) => item.name === className);
    const updatedClasses = [...classes];
    updatedClasses[index].checked = !updatedClasses[index].checked;
    setClasses(updatedClasses);
    const newBrands: any[] = [];

    updatedClasses.forEach((customerClass) => {
      if (customerClass.checked) {
        newBrands.push(
          customerClass.brands.map((brand) => ({
            ...brand,
            checked: !!customerBrands?.find(
              (customerBrand) => customerBrand.brand === brand.name
            ),
            code: customerBrands?.find(
              (customerBrand) => customerBrand.brand === brand.name
            )?.code,
          }))
        );
      }
    });
    const flatBrands = newBrands.flat();
    const ids = flatBrands.map(({ name }) => name);
    const filtered = flatBrands.filter(
      ({ name }, index) => !ids.includes(name, index + 1)
    );
    setBrands(filtered);
  };

  const brandClickHandle = (brandName: string, event?: any): void => {
    if (!event || (event && event.target.type === "text")) return;
    const index = brands.findIndex((item) => item.name === brandName);
    const updatedBrands = [...brands];
    updatedBrands[index].checked = !updatedBrands[index].checked;
    setBrands(updatedBrands);
    resetErrors("brands");
  };

  const prepareData = () => {
    if (!classes) return;
    const classesToSend = classes
      .filter((customerClass) => customerClass.checked)
      .map((customerClass) => customerClass.name);
    const brandsToSend = brands
      .filter((brand) => brand.checked || brand.brandRequired)
      .map((brand) => ({ brand: brand.name, code: brand.code }));
    return {
      customerClasses: classesToSend,
      customerBrands: brandsToSend,
    };
  };

  const codeChange = (value: string, index: number) => {
    setBrands(
      brands.map((brandItem, i) =>
        i === index ? { ...brandItem, code: value } : brandItem
      )
    );
    resetErrors("codes");
  };

  const brandsToValidate = (): string[] => {
    if (!classesAndBrands) return [];
    return classesAndBrands
      .find((item) => item.name === CustomerClasses.AUTHORIZED_SERVICE)!
      .brands.map((item) => item);
  };

  const validate = (): boolean => {
    const validateBrandCodes = (): boolean => {
      if (
        brands.filter(
          (item) => (item.checked || item.brandRequired) && !item.code
        )?.length > 0
      ) {
        setValidateEroorCodes(true);
        return false;
      } else {
        return true;
      }
    };

    if (!classes?.find((item) => item.checked)) {
      setValidateEroorClasses(true);
      return false;
    }

    if (
      classes?.find(
        (item) =>
          item.name === CustomerClasses.AUTHORIZED_SERVICE && item.checked
      )
    ) {
      if (
        !brands.filter(
          (item) =>
            !!brandsToValidate().find(
              (brand) => brand === item.name && item.checked
            )
        ).length
      ) {
        setValidateEroorBrands(true);
        return false;
      } else {
        return validateBrandCodes();
      }
    } else {
      return validateBrandCodes();
    }
  };

  const brandButtonStyleClasses = (brandName: string): string => {
    if (brandsToValidate().find((item) => item === brandName)) {
      return `radio-button${validateErrorBrands ? " validate-error" : ""}`;
    } else {
      return "radio-button";
    }
  };

  return (
    <Modal
      size="small"
      trigger={
        triggerButton || (
          <Button
            style={{ position: "absolute", bottom: -40, right: 0 }}
            color="orange"
            content="Edytuj"
          />
        )
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleCancel}
      closeOnDimmerClick={false}
    >
      <Header icon="edit" content="Edytuj klasy i marki" />
      <Modal.Content scrolling style={{ maxHeight: "calc(80vh - 3em)" }}>
        <div className="validate-error-container">
          <h5>Klasy:</h5>
          <span
            className="validate-error-message"
            style={{ display: validateErrorClasses ? "inline" : "none" }}
          >
            {validateErrorClasses &&
              "Przynajmniej jedna z wyróżnionych klas musi być zaznaczona"}
          </span>
        </div>
        <Grid style={{ marginBottom: "unset" }} stretched>
          {classes &&
            classes.map((customerClass) => (
              <Button
                key={customerClass.name}
                fluid
                basic={true}
                onClick={() => classClickHandle(customerClass.name)}
                className={`radio-button ${
                  validateErrorClasses ? "validate-error" : ""
                }`}
              >
                <Form.Radio
                  label={translations.format(
                    `app.customer-class.${customerClass.name}`
                  )}
                  checked={customerClass.checked}
                  value={customerClass.name}
                />
              </Button>
            ))}
        </Grid>
        <Divider></Divider>
        <div className="validate-error-container">
          <h5>Marki:</h5>
          <span
            className="validate-error-message"
            style={{
              display:
                validateErrorBrands || validateErrorCodes ? "inline" : "none",
            }}
          >
            {validateErrorBrands &&
              "Przynajmniej jedna z wyróżnionych marek musi być zaznaczona"}
            {validateErrorCodes &&
              "Zaznaczone pozycje muszą mieć uzupełniony kod marki"}
          </span>
        </div>
        <Grid
          style={{
            marginBottom: "unset",
            minHeight: 265,
            alignContent: "flex-start",
          }}
          columns={2}
        >
          {brands.map((brand, index) => (
            <Button
              key={brand.name}
              fluid
              basic={true}
              onClick={(event: any) => brandClickHandle(brand.name, event)}
              className={brandButtonStyleClasses(brand.name)}
            >
              <Form.Radio
                label={translations.format(`app.customer-brand.${brand.name}`)}
                checked={brand.brandRequired || brand.checked}
                value={brand.name}
                disabled={brand.brandRequired}
                onClick={() => brandClickHandle(brand.name)}
              />
              <Input
                placeholder="kod marki"
                required={true}
                style={{ width: "70%" }}
                key={brand.name}
                value={brand.code}
                onChange={(e, data) => codeChange(data.value, index)}
                error={
                  validateErrorCodes &&
                  (brand.checked || brand.brandRequired) &&
                  !brand.code
                }
              />
            </Button>
          ))}
        </Grid>
        <CommonLoader loading={updateCustomerPending || loading} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Zapisz"
          primary
          onClick={() => {
            handleSave();
          }}
        />
        <Button content="Zamknij" onClick={handleCancel} />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: (state: ApplicationState) => TReduxState = ({
  customers,
}: ApplicationState) => {
  return {
    classesAndBrands: customers.classesAndBrands,
    updateCustomerPending: customers.updateCustomerPending,
  };
};

const mapDispatchToProps: TReduxActions = {
  fetchClassesAndBrands,
  updateCustomer,
  selectCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassAndBrandsModal);
