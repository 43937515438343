import { useRef, useState } from 'react'
import { TSelection, TNode, SelectionPropagation, SelectionChangeFn } from '../lib/types'

const useSelection = (initial: TSelection[], onSelectionChange: SelectionChangeFn) => {

    const [selection, setSelection] = useState<TSelection[]>(initial)
    const cache = useRef<TSelection[]>(initial)

    const toSelection = (node: TNode, parents: TNode[]): TSelection => [...parents.map(parent => parent.id), node.id]

    const findSelectionIndex = (list: TSelection[], node: TSelection): number => {
        return list.findIndex((item) => {
            return item.length === node.length && String(item) === String(node)
        })
    }

    const isChecked = (node: TNode, parents: TNode[]): boolean => findSelectionIndex(cache.current, toSelection(node, parents)) !== -1

    const select = (node: TNode, parents: TNode[]): void => {
        if (isIndeterminate(node, parents)) {
            const selectionNode = toSelection(node, parents)
            const toDeselect = selection
                .filter(item => item.length > selectionNode.length)
                .filter(item => String(item).startsWith(String(selectionNode)))
            toDeselect.forEach((item) => {
                cache.current.splice(findSelectionIndex(cache.current, item), 1)
            })
        }
        if (!isChecked(node, parents)) {
            cache.current.push(toSelection(node, parents))
        }
    }

    const selectMany = (nodes: { node: TNode, parents: TNode[] }[]): void => {
        nodes.forEach(node => {
            select(node.node, node.parents)
        })
    }

    const deselect = (node: TNode, parents: TNode[]): void => {
        if (isChecked(node, parents)) {
            cache.current.splice(findSelectionIndex(cache.current, toSelection(node, parents)), 1)
        }
    }

    const deselectMany = (nodes: { node: TNode, parents: TNode[] }[]): void => {
        nodes.forEach(node => {
            deselect(node.node, node.parents)
        })
    }

    const isIndeterminate = (node: TNode, parents: TNode[]): boolean => {
        const selectionNode = toSelection(node, parents)
        const foundIndex = selection
            .filter(item => item.length > selectionNode.length)
            .findIndex(item => String(item).startsWith(String(selectionNode)))
        return foundIndex !== -1
    }

    const storeChanges = (callback: (nodes: TSelection[]) => void): void => {
        setSelection([...cache.current])
        callback([...cache.current])
    }

    const reinit = (nodes: TSelection[]): void => {
        cache.current = nodes
        setSelection(nodes)
    }

    const handleSelectionChange: SelectionPropagation = (deselected?: TNode): void => {
        if (deselected) {
            deselect(deselected, [])
        }
        storeChanges(onSelectionChange)
    }

    return {
        selection,
        reinit,
        isChecked,
        isIndeterminate,
        select,
        selectMany,
        deselect,
        deselectMany,
        storeChanges,
        handleSelectionChange
    }
}

export default useSelection
