import { Paginable, PaginationMeta, TotalRecords } from '~/store/types'

export type TPromotion = {
    code: string
    status: TPromotionStatus
    dateFrom: string
    dateTo: string
    type: TPromotionType
    priority: number
    ruleSet: TPromotionRule
    isolatedCollection: boolean
    isolatedResultCollection: boolean
    bonusASO: boolean
    description?: string
    firstDiscount?: TDiscount
    secondDiscount?: TDiscount
    associativeDiscountGroup?: TAssociativeDiscountGroup
    associativeDiscountMode?: TAssociativeDiscountMode
    resultSet?: TPromotionRule
    promoID?: string
}

export type TPromotionStatus = 'DRAFT' | 'ACTIVE' | 'CLOSED' | 'AWAITING_ACTIVATION'

export type TPromotionType = 'CASCADE' | 'ACTUAL' | 'CURRENCY' | 'ASSOCIATIVE' | 'GROUP'

export type TDiscountType = 'PERCENTAGE' | 'PRICING' | 'PERCENTAGE_AND_PRICING'

export type TAssociativeDiscountGroup = 'ALL' | 'GROUP_1' | 'GROUP_2'

export type TAssociativeDiscountMode = 'CASCADE' | 'ACTUAL'

export type TPromotionRule = {
    referenceRule: TReferenceRule
    customerRuleList: TCustomerRule[]
}

export type TCustomerRule = {
    rrdiList: string[] | null
    classList: string[] | null
    brandList: string[] | null
}

export type TReferenceRule = {
    cardinality?: number
    maxCardinality?: number
    references: TReferenceRuleData[]
}

export type TDiscount = {
    amountDiscount: number
    multiple: number
    percentageDiscount: number
    priceThreshold: number
    quantityThreshold: number
    type: TDiscountType
}

export type TValidationResult = {
    promoId: string
    name: string
    priority: number
    description: string
    overlaps: TReferenceRuleData[]
}[]

export type TReferenceRuleData = {
    index: string
    segment?: string
    slot?: string
    family?: string
    referenceId?: string
    type: TProductGroup
}

export type TPromotionActivateCollisionInfo = {
    timestamp: string
    code: TPromoCollision | string,
    message: string,
    additionalData?: AdditionalData[]
}

export type TPromotionActivateErrorResponse = {
    timestamp: string,
    code: string
    message: string
    additionalData: string
}

export type AdditionalData = {
    code: string,
    priority: number,
    collision: TCollision
}

export type TCollision = {
    index: string,
    segment?: string
    slot?: string
    family?: string
    referenceId?: string
    type: TProductGroup
}

export type TProductGroup = "INDEX" | "SEGMENT" | "SLOT" | "FAMILY" | "PRODUCT"

export type TPromoCollision = "PROMO_COLLISION" | "REQUIRED_PROVISION_NOT_PROVIDED"

export enum PromotionsActionTypes {
    FETCH_REQUEST = '@@promotions/FETCH_REQUEST',
    FETCH_SUCCESS = '@@promotions/FETCH_SUCCESS',
    FILTER_PROMOTIONS = '@@promotions/FILTER_PROMOTIONS',
    SELECT_PROMOTION = '@@promotions/SELECT_PROMOTION',
    SELECTED = '@@promotions/SELECTED',
    CLEAR_SELECTED = '@@promotions/CLEAR_SELECTED',
    DELETE_PROMOTION = '@@promotions/DELETE_PROMOTION',
    DELETED_SUCCESS = '@@promotions/DELETED_SUCCESS',
    CREATE_PROMOTION = '@@promotions/CREATE_PROMOTION',
    CREATE_SUCCESS = '@@promotions/CREATE_SUCCESS',
    UPDATE_PROMO = '@@promotions/UPDATE_PROMO',
    UPDATE_PROMO_SUCCESS = '@@promotions/UPDATE_PROMO_SUCCESS',
    CLONE_PROMOTION = '@@promotions/CLONE_PROMOTION',
    CLONE_SUCCESS = '@@promotions/CLONE_SUCCESS',
    VALIDATE_PROMO = '@@promotions/VALIDATE_PROMO',
    VALIDATE_FINISHED = '@@promotions/VALIDATE_FINISHED',
    IMPORT_REFERENCES = '@@promotions/IMPORT_REFERENCES',
    IMPORT_FINISHED = '@@promotions/IMPORT_FINISHED',
    IMPORT_PROMOTION = '@@promotions/IMPORT_PROMOTION',
    ACTIVATE_PROMOTION = '@@promotions/ACTIVATE_PROMOTION',
    ACTIVATE_PROMOTION_SUCCESS = '@@promotions/ACTIVATE_PROMOTION_SUCCESS',
    ACTIVATE_PROMOTION_COLLISION = '@@promotions/ACTIVATE_PROMOTION_COLLISION',
    ACTIVATE_PROMOTION_COLLISION_VIEW_CLOSED = '@@promotions/ACTIVATE_PROMOTION_COLLISION_VIEW_CLOSED',
    CLOSE_PROMOTION = '@@promotions/CLOSE_PROMOTION',
    CLOSE_PROMOTION_SUCCESS = '@@promotions/CLOSE_PROMOTION_SUCCESS',
    REDIRECT_TO_PROMO_LIST_VIEW = '@@promotions/REDIRECT_TO_PROMO_LIST_VIEW',
    REDIRECT_TO_PROMO_LIST_VIEW_FINISHED = '@@promotions/REDIRECT_TO_PROMO_LIST_VIEW_FINISHED',
    ACTIVATE_PROMOTION_FAILURE = '@@promotion/ACTIVATE_PROMOTION_FAILURE'
}

export interface PromotionsState {
    readonly creating: boolean
    readonly deleting: boolean
    readonly validating: boolean
    readonly importing: boolean
    readonly list: TPromotion[]
    readonly validationResult: AdditionalData[]
    readonly meta: PaginationMeta
    readonly totalRecords: TotalRecords
    readonly loading: boolean
    readonly selected?: TPromotion
    readonly created?: string
    readonly deleted?: boolean
    readonly imported?: TReferenceRuleData[]
    readonly importedList: any[]
    readonly collisionInfo?: TPromotionActivateCollisionInfo
    readonly activating: boolean
    readonly activationFailed: boolean
    readonly closing: boolean
    readonly redirectedToPromoViewList: boolean
}

export type PaginablePromotion = Paginable<TPromotion>
