import ConfirmModal from '~/pages/Orders/lib/Add/Type/ConfirmModal';
import ForcedPaymentMethodTypeField from '~/pages/Orders/lib/Add/Type/ForcedPaymentMethodTypeField';
import DetailCard from '~/components/DetailCard/DetailCard';
import React, { useContext, useMemo } from 'react';
import { DetailCardTypeProps } from '~/pages/Orders/lib/Add/Type/constants';
import { DictionaryName } from '~/store/dictionaries/types';
import { DELIVERY_TYPE_ENUM } from '~/store/orders/types';
import { AppContext } from '~/context/AuthContext';
import { AVAILABLE_ROLE_ENUM } from '~/store/users/types';

const DetailCardType = ({
  shrink,
  dictionaries,
  summary,
  selectedCustomer,
  deliveryPoint,
  updateOrder,
  setForced,
  openConfirmModal,
  discardChangeOrder,
  confirmChangeOrder,
  currentOrder,
  order,
}: DetailCardTypeProps) => {
  const appContext = useContext(AppContext);

  const userHaveLdcUiOrderNormalRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_NORMAL),
    [appContext]
  );

  const userHaveLdcUiOrderWarrantyRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_WARRANTY),
    [appContext]
  );

  const userHaveLdcUiOrderBonusRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS),
    [appContext]
  );

  const userHaveLdcUiOrderBonusPlusRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_BONUS_PLUS),
    [appContext]
  );

  const userHaveLdcUiOrderSpecialRole = useMemo(
    () => appContext?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_UI_SPECIAL),
    [appContext]
  );

  const orderType = useMemo(() => {
    let types = dictionaries[DictionaryName.orderType];

    if (!userHaveLdcUiOrderNormalRole) {
      types = types.filter((type) => type.value !== DELIVERY_TYPE_ENUM.NORMAL);
    }

    if (!userHaveLdcUiOrderWarrantyRole) {
      types = types.filter((type) => type.value !== DELIVERY_TYPE_ENUM.WARRANTY);
    }

    if (!userHaveLdcUiOrderBonusRole) {
      types = types.filter((type) => type.value !== DELIVERY_TYPE_ENUM.BONUS);
    }

    if (!userHaveLdcUiOrderSpecialRole) {
      types = types.filter((type) => type.value !== DELIVERY_TYPE_ENUM.SPECIAL);
    }

    if (!userHaveLdcUiOrderBonusPlusRole) {
      types = types.filter((type) => type.value !== DELIVERY_TYPE_ENUM.BONUS_PLUS);
    }
    return types;
  }, [
    userHaveLdcUiOrderSpecialRole,
    userHaveLdcUiOrderBonusRole,
    userHaveLdcUiOrderNormalRole,
    userHaveLdcUiOrderWarrantyRole,
    userHaveLdcUiOrderBonusPlusRole,
    dictionaries,
  ]);

  return (
    <DetailCard
      title={shrink ? 'app.type' : 'app.order.type'}
      id="type"
      key="type"
      minHeight={40}
      width={shrink ? 2.5 : 3}
      smallWidth={5.33}
    >
      {orderType
        .sort((item1, item2) => Number(item1.key) - Number(item2.key))
        .map((typeDict) => (
          <ConfirmModal
            key={typeDict.key}
            summary={summary}
            openConfirmModal={openConfirmModal}
            typeDict={typeDict}
            order={order}
            updateOrder={updateOrder}
            discardChangeOrder={discardChangeOrder}
            confirmChangeOrder={confirmChangeOrder}
            currentOrder={currentOrder}
          />
        ))}
      <ForcedPaymentMethodTypeField
        summary={summary}
        setForced={setForced}
        updateOrder={updateOrder}
        deliveryPoint={deliveryPoint}
        selectedCustomer={selectedCustomer}
      />
    </DetailCard>
  );
};

export default DetailCardType;
