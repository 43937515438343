import DetailCard from "~/components/DetailCard/DetailCard";
import React, { useCallback } from "react";

import { useRenderingFunctions } from "~/components/SmartField/hooks/useRenderingFunctions";
import { Announcement } from "~/store/annouoncements/types";
import { CustomerRoles } from "~/pages/Dashboard/lib/constans";
import { TNewAnnouncement } from "../../AddAnnouncement/AddAnnouncement";

type TAnnouncementRolesCard = {
  announcement: Announcement | TNewAnnouncement;
  edit: boolean;
  setAnnouncementForEdit: React.Dispatch<React.SetStateAction<any>>;
  forLibrary?: boolean;
};

const AnnouncementRolesCard: React.FC<TAnnouncementRolesCard> = ({
  announcement,
  edit,
  setAnnouncementForEdit,
  forLibrary,
}) => {
  const { renderToggle } = useRenderingFunctions(announcement, edit);

  const saveMapper = useCallback(
    (value: any, role: CustomerRoles) => {
      let rolesToSave = [...announcement.intendedFor];
      if (!value) {
        rolesToSave = rolesToSave.filter((item) => item !== role);
      } else {
        rolesToSave.push(role);
      }
      setAnnouncementForEdit(
        (item: any) => item && { ...item, intendedFor: rolesToSave }
      );
      return rolesToSave;
    },
    [announcement.intendedFor, setAnnouncementForEdit]
  );

  return (
    <DetailCard
      title="app.intendedFor"
      id="intendedFor"
      key="intendedFor"
      width={!forLibrary ? 4 : 16}
      smallWidth={5.33}
      style={{ zIndex: 30 }}
    >
      {renderToggle("intendedFor", {
        label: "Serwis autoryzowany",
        noSuccesInfo: true,
        loadMapper: (value) => value.includes(CustomerRoles.AUTHORIZED_SERVICE),
        saveMapper: (value) =>
          saveMapper(value, CustomerRoles.AUTHORIZED_SERVICE),
      })}
      {renderToggle("intendedFor", {
        label: "Serwis niezależny",
        noSuccesInfo: true,
        loadMapper: (value) =>
          value.includes(CustomerRoles.INDEPENDENT_SERVICE),
        saveMapper: (value) =>
          saveMapper(value, CustomerRoles.INDEPENDENT_SERVICE),
      })}
      {renderToggle("intendedFor", {
        label: "ERCS",
        noSuccesInfo: true,
        loadMapper: (value) =>
          value.includes(CustomerRoles.INDEPENDENT_SERVICE_ERCS),
        saveMapper: (value) =>
          saveMapper(value, CustomerRoles.INDEPENDENT_SERVICE_ERCS),
      })}
      {renderToggle("intendedFor", {
        label: "Distrigo Relay",
        noSuccesInfo: true,
        loadMapper: (value) => value.includes(CustomerRoles.DISTRIGO_RELAY),
        saveMapper: (value) => saveMapper(value, CustomerRoles.DISTRIGO_RELAY),
      })}
      {renderToggle("intendedFor", {
        label: "Distrigo Market",
        noSuccesInfo: true,
        loadMapper: (value) => value.includes(CustomerRoles.DISTRIGO_MARKET),
        saveMapper: (value) => saveMapper(value, CustomerRoles.DISTRIGO_MARKET),
      })}
      {renderToggle("intendedFor", {
        label: "Distrigo HUB",
        noSuccesInfo: true,
        loadMapper: (value) => value.includes(CustomerRoles.DISTRIGO_HUB),
        saveMapper: (value) => saveMapper(value, CustomerRoles.DISTRIGO_HUB),
      })}
      {renderToggle("intendedFor", {
        label: "Inne",
        noSuccesInfo: true,
        loadMapper: (value) => value.includes(CustomerRoles.OTHER),
        saveMapper: (value) => saveMapper(value, CustomerRoles.OTHER),
      })}
    </DetailCard>
  );
};

export default AnnouncementRolesCard;
