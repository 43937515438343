import { useContext, useMemo } from "react";
import { AppContext } from "~/context/AuthContext";
import { AVAILABLE_ROLE_ENUM } from "~/store/users/types";

export const useCustomersRolesRights = () => {
  const context = useContext(AppContext);

  const userHaveLdcUiCustomerEditRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_UI_CUSTOMER_EDIT
      ),
    [context]
  );

  const userHaveLdcOrderViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_ORDER_VIEW),
    [context]
  );

  const userHaveLdcClaimsViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(AVAILABLE_ROLE_ENUM.LDC_CLAIMS_VIEW),
    [context]
  );

  const userHaveLdcAccountingViewRole = useMemo(
    (): boolean =>
      context?.keycloak.hasResourceRole(
        AVAILABLE_ROLE_ENUM.LDC_ACCOUNTING_VIEW
      ),
    [context]
  );

  return {
    userHaveLdcUiCustomerEditRole,
    userHaveLdcOrderViewRole,
    userHaveLdcClaimsViewRole,
    userHaveLdcAccountingViewRole,
  };
};
