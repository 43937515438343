import { handleFileUploadError } from '~/utils/fileUploadErrorHandler'
import { DistrigoWarehouse, DistrigoWarehousesActionTypes, PaginableStocksWarehouse, StockDetails, StockWarehouse } from './types'
import { put, call, takeEvery, fork, all } from 'redux-saga/effects'
import {
    fetchDistrigoWarehouses, fetchSuccess, selectDistrigoStocksWarehouse, distrigoWarehouseSelected,
    addSparePartStockSuccess, addSparePartConflict, uploadStockFileExcel, uploadStockFileExcelSuccess,
    rejectToSupplier, rejectSuccess, fetchStockProducts, fetchStockProductsSuccess, importReferences, addWareLine,
    addWareLineSuccess, importFinished, catchErrorOfImportingReference, catchErrorOfDuplicateReference, internalConsumption, internalConsumptionSuccess, confirmMovingWares, fetchStockDetails, fetchStockDetailsSuccess
} from "./actions"
import { toastError, toastSuccess } from '~/utils/toast'
import { callApi, callApiUploadFile } from '~/utils/api'
import { handleError } from '../error/actions'
import { toastWarn } from '~/utils/toast'

import translations from '~/utils/translations'

function* handleFetchDistrigoWarehouses(action: ReturnType<typeof fetchDistrigoWarehouses>) {
    try {
        const distrigoWarehouses: DistrigoWarehouse[] = yield call(callApi, 'get', `/warehouses`)
        yield put(fetchSuccess(distrigoWarehouses))
    } catch (error) {
        yield put(handleError(error, false, translations.format("app.error.distrigo-warehouses.load")))
    }
}

function* handleFetchStockDetails(action: ReturnType<typeof fetchStockDetails>) {
    try {
        const distrigoWarehouse: StockDetails = yield call(callApi, 'get', `/distribution-platforms/warehouse/${action.payload.id}`)
        yield put(fetchStockDetailsSuccess(distrigoWarehouse))
    } catch (error) {
        yield put(handleError(error, false, translations.format("app.error.distrigo-warehouses.load")))
    }
}

function* handleSelectDistrigoWarehouse(action: ReturnType<typeof selectDistrigoStocksWarehouse>) {
    try {
        let filters = ''
        let sort = ''

        action.payload.filters.forEach((filter: any) => {
            filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
        })

        if (action.payload.sortColumn && action.payload.sortDirection) {
            sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`
        }

        const distrigoWarehouse: PaginableStocksWarehouse = yield call(callApi, 'get', `/stock/${action.payload.id}?page=${action.payload.page - 1}&size=${action.payload.size}${filters}${sort}`)

        yield put(distrigoWarehouseSelected(distrigoWarehouse))
    } catch (error) {
        yield put(handleError(error, true, translations.format("app.error.distrigo-warehouses.select-distrigo-warehouse")))
    }
}

function* handleConfirmMoveWares(action: ReturnType<typeof confirmMovingWares>) {
    try {
        const mappedParts = action.payload.parts.map(item => ({
            partID: item.partID,
            quantity: item.quantity
        }));
        const confirmedWare = {
            supplierID: String(action.payload.supplierID).substring(0,7),
            recipientID: String(action.payload.recipientID).substring(0,7),
            parts: mappedParts,
            additionalInfo: ""
        }
        const response: PaginableStocksWarehouse = yield call(callApi, 'post', `/inventory-transfer/create`, confirmedWare)

        yield put(distrigoWarehouseSelected(response))
        toastSuccess("Sukces", "Przesunięcie części do innego magazynu zakończone powodzeniem")
    } catch (error) {
        toastError("Błąd", "Przesunięcie części do innego magazynu zakończone niepowodzeniem")
    }
}


function* handleFetchStockProducts(action: ReturnType<typeof fetchStockProducts>) {
    try {
        let filters = ''
        let sort = ''

        action.payload.filters.forEach((filter: any) => {
            filters += `&${filter.name}=${filter.key ? filter.key : filter.value}`
        })

        if (action.payload.sortColumn && action.payload.sortDirection) {
            sort = `&sort=${action.payload.sortColumn},${action.payload.sortDirection}`
        }

        const { id, page, size } = action.payload
        const distrigoWarehouse: StockWarehouse[] = yield call(callApi, 'get', `/stock/${id}?page=${page - 1}&size=${size}${filters}${sort}`)

        yield put(fetchStockProductsSuccess(distrigoWarehouse))
    } catch (error) {
        yield put(handleError(error, true, translations.format("app.error.distrigo-warehouses.select-distrigo-warehouse")))
    }
}

function* handleAddSparePartStock(action: any) {
    try {

        const { referenceWarehouseId } = action.payload

        const sparePartStock = {
            ...action.payload,
            warehouseId: referenceWarehouseId.warehouseId
        }

        delete sparePartStock.referenceWarehouseId

        const res: StockWarehouse = yield call(callApi, 'post', `/stock/${referenceWarehouseId.referenceId}`, sparePartStock)
        yield put(addSparePartStockSuccess(res.id))
    } catch (error) {
        if (error.status === 409 || error.status === 0) {
            toastWarn('Dodanie części niemożliwe', 'Istnieje już taka część. Edytuj istniejącą.')
            yield put(addSparePartConflict())
        }
        else {
            yield put(handleError(error, false, "Wystąpił błąd podczas dodawania części."))
        }
    }
}

function* handleAddWareLine(action: ReturnType<typeof addWareLine>) {
    try {
        const { payload } = action;
        const { line } = payload
        const mappedArrayOfReceptionGoodDtoList = line.parts.map((item, index) => ({
            referenceId: line.parts[index].referenceID,
            quantity: line.parts[index].quantity,
            price: line.parts[index].price,
            supplier: line.parts[index].supplier
        }));
        const tzzoffset: number = (new Date()).getTimezoneOffset() * 60000;
        const res: string = yield call(callApi, 'post', `/stock/external-reception`, {
            invoiceDate: new Date(Number(line.invoiceDate) - tzzoffset).toISOString(),
            invoiceNumber: line.invoiceNumber,
            warehouseID: line.warehouse,
            returnPointId: line.rrdi,
            receptionGoodDtoList: mappedArrayOfReceptionGoodDtoList
        })
        yield put(addWareLineSuccess(res))
        toastSuccess("Pomyślnie dodano nowy towar", "Dane został poprawnie wysłane");
    } catch (error) {
        if (error.message) {
            yield put(catchErrorOfDuplicateReference(error.message))
            toastWarn('Konflikt', `Zostały wysłane zduplikowane referencje: ${error.message.substring(49)}`)
        } else {
            yield put(handleError(error, false, 'Błąd przy dodaniu nowej linii'));
        }
    }
}

function* handleUploadFromCSV(action: ReturnType<typeof importReferences>) {
    try {
        const res: { referenceId?: string; quantity?: number; price?: number } = yield call(callApiUploadFile, `/stock/external-reception/import`, action.payload)
        yield put(importFinished(res));
    } catch (error) {
        //todo backend returns error even if product in file exists
        if (error.message) {
            yield put(catchErrorOfImportingReference(error.message))
            toastWarn('Konflikt', `Zostały zaimportowane zduplikowane elementy: ${error.message.substring(34)}`)
        } else {
            yield handleFileUploadError(error)
        }
    }
}

function* handleUploadStockExcel(action: ReturnType<typeof uploadStockFileExcel>) {
    try {
        yield call(callApiUploadFile, `/stock/${action.payload.warehouseID}/import`, action.payload.excelFile)
        yield put(uploadStockFileExcelSuccess())
        toastSuccess('Pomyślnie wgrano plik', 'Dane zostały poprawnie zaimportowane')

    } catch (error) {
        //todo backend returns error even if product in file exists
        if (error.status === 400) {
            yield put(uploadStockFileExcelSuccess())
            toastSuccess('Pomyślnie wgrano plik', 'Dane zostały poprawnie zaimportowane')
        }
        else {
            yield handleFileUploadError(error)
        }
    }
}

function* handleRejectToSupplier(action: ReturnType<typeof rejectToSupplier>) {
    try {
        yield call(callApi, 'post', '/goods-dispatch-notes/reject-to-supplier', action.payload)
        yield put(rejectSuccess())
    } catch (error) {
        yield put(handleError(error, false, "Błąd zwracania części"))
    }
}

function* handleInternalConsumption({payload: {warehouseID, parts}}: ReturnType<typeof internalConsumption>) {
    try {
        yield call(callApi, 'post', `/internal-consumptions/${warehouseID}/create`, parts)
        yield put(internalConsumptionSuccess())
    } catch (error) {
        yield put(handleError(error, false, "Błąd zwracania części"))
    }
}

function* watchFetchDistrigoWarehousesRequest() {
    yield takeEvery(DistrigoWarehousesActionTypes.FETCH_REQUEST, handleFetchDistrigoWarehouses)
}

function* watchFetchStockDetails() {
    yield takeEvery(DistrigoWarehousesActionTypes.FETCH_STOCK_DETAILS, handleFetchStockDetails)
}

function* watchSelectDistrigoWarehouse() {
    yield takeEvery(DistrigoWarehousesActionTypes.SELECT, handleSelectDistrigoWarehouse)
}

function* watchAddSparePartStock() {
    yield takeEvery(DistrigoWarehousesActionTypes.ADD_SPARE_PART_STOCK, handleAddSparePartStock)
}

function* watchUploadStockExcel() {
    yield takeEvery(DistrigoWarehousesActionTypes.UPLOAD_XLS, handleUploadStockExcel)
}

function* watchRejectToSupplier() {
    yield takeEvery(DistrigoWarehousesActionTypes.REJECT_TO_SUPPLIER, handleRejectToSupplier)
}

function* watchInternalConsumption() {
    yield takeEvery(DistrigoWarehousesActionTypes.INTERNAL_CONSUMPTION, handleInternalConsumption)
}

function* watchFetchStockProducts() {
    yield takeEvery(DistrigoWarehousesActionTypes.FETCH_STOCK_PRODUCTS_REQUEST, handleFetchStockProducts)
}

function* watchCreateReceiptOfWare() {
    yield takeEvery(DistrigoWarehousesActionTypes.CREATE_WARE, handleAddWareLine)
}

function* watchImportFromCSV() {
    yield takeEvery(DistrigoWarehousesActionTypes.IMPORT_REFERENCES, handleUploadFromCSV)
}

function* watchMovePartsToAnotherWare() {
    yield takeEvery(DistrigoWarehousesActionTypes.CONFIRM_MOVING_WARES, handleConfirmMoveWares)
}

function* distrigoWarehousesSaga() {
    yield all([
        fork(watchFetchDistrigoWarehousesRequest),
        fork(watchSelectDistrigoWarehouse),
        fork(watchAddSparePartStock),
        fork(watchUploadStockExcel),
        fork(watchRejectToSupplier),
        fork(watchInternalConsumption),
        fork(watchFetchStockProducts),
        fork(watchCreateReceiptOfWare),
        fork(watchImportFromCSV),
        fork(watchMovePartsToAnotherWare),
        fork(watchFetchStockDetails),
    ])
}

export default distrigoWarehousesSaga
