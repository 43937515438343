import { action } from 'typesafe-actions'
import { TNodeData, HierarchyActionTypes, TGroup } from './types'

export const fetchSegments = (index: TNodeData, fetcher: (items: TNodeData[]) => void) => action(HierarchyActionTypes.FETCH_SEGMENTS, { index, fetcher })
export const fetchSlots = (segment: TNodeData, fetcher: (items: TNodeData[]) => void) => action(HierarchyActionTypes.FETCH_SLOTS, { segment, fetcher })
export const fetchFamilies = (slot: TNodeData, fetcher: (items: TNodeData[]) => void) => action(HierarchyActionTypes.FETCH_FAMILIES, { slot, fetcher })
export const fetchSpareParts = (family: TNodeData, fetcher: (items: TNodeData[]) => void) => action(HierarchyActionTypes.FETCH_REFERENCES, { family, fetcher })
export const fetchSuccess = (parent: TNodeData, fetched: TNodeData[]) => action(HierarchyActionTypes.FETCH_SUCCESS, { parent, fetched })

export const advancedSearch = (param: TGroup, key: string, associativeDiscountGroup?: string) => action(HierarchyActionTypes.ADVANCED_SEARCH_FETCH, { param, key, associativeDiscountGroup })
export const advancedSearchGroup1Success = (data: any) => action(HierarchyActionTypes.ADVANCED_SEARCH_FETCH_GROUP_1_SUCCESS, data)
export const advancedSearchGroup2Success = (data: any) => action(HierarchyActionTypes.ADVANCED_SEARCH_FETCH_GROUP_2_SUCCESS, data)
