import { Reducer } from 'redux'
import { ErrorActionTypes } from '~/store/error/types'
import { PaginablePromotion, PromotionsActionTypes, PromotionsState } from './types'

export const initialPromotionsState: PromotionsState = {
    loading: false,
    creating: false,
    deleting: false,
    validating: false,
    importing: false,
    list: [],
    selected: undefined,
    validationResult: [],
    meta: {
        page: 1,
        size: 15,
        pages: 1
    },
    totalRecords: {
        total: 0,
        filtered: 0
    },
    importedList: [],
    collisionInfo: undefined,
    activating: false,
    activationFailed: false,
    closing: false,
    redirectedToPromoViewList: false
}

const reducer: Reducer<PromotionsState> = (state = initialPromotionsState, action): PromotionsState => {
    switch (action.type) {
        case PromotionsActionTypes.FETCH_REQUEST: {
            return { ...state, loading: true }
        }
        case PromotionsActionTypes.FETCH_SUCCESS: {
            const payload = action.payload as PaginablePromotion
            return {
                ...state,
                loading: false,
                deleted: undefined,
                list: payload.content,
                meta: { page: payload.number + 1, pages: payload.totalPages, size: payload.size },
                totalRecords: { total: payload.totalElements, filtered: payload.filteredElements }
            }
        }
        case PromotionsActionTypes.FILTER_PROMOTIONS: {
            return { ...state, loading: true, list: [] }
        }
        case PromotionsActionTypes.SELECT_PROMOTION: {
            return { ...state, loading: true, selected: undefined, created: undefined, validationResult: [] }
        }
        case PromotionsActionTypes.SELECTED: {
            return { ...state, loading: false, selected: action.payload, created: undefined, imported: undefined }
        }
        case PromotionsActionTypes.CLEAR_SELECTED: {
            return { ...state, selected: undefined }
        }
        case PromotionsActionTypes.CREATE_PROMOTION: {
            return { ...state, creating: true }
        }
        case PromotionsActionTypes.CREATE_SUCCESS: {
            return { ...state, creating: false, created: action.payload }
        }
        case PromotionsActionTypes.ACTIVATE_PROMOTION: {
            return { ...state, activating: true }
        }
        case PromotionsActionTypes.ACTIVATE_PROMOTION_SUCCESS: {
            return { ...state, activating: false, activationFailed: false, collisionInfo: undefined }
        }
        case PromotionsActionTypes.ACTIVATE_PROMOTION_COLLISION: {
            return {...state, activating: false, activationFailed: true, collisionInfo: action.payload.promoCollision }
        }
        case PromotionsActionTypes.ACTIVATE_PROMOTION_FAILURE: {
            return {...state, activating: false, activationFailed: true }
        }
        case PromotionsActionTypes.ACTIVATE_PROMOTION_COLLISION_VIEW_CLOSED: {
            return { ...state, activating: false, activationFailed: false }
        }
        case PromotionsActionTypes.CLONE_PROMOTION: {
            return { ...state, creating: true }
        }
        case PromotionsActionTypes.CLONE_SUCCESS: {
            return { ...state, creating: false, created: action.payload }
        }
        case PromotionsActionTypes.DELETE_PROMOTION: {
            return { ...state, deleting: true }
        }
        case PromotionsActionTypes.DELETED_SUCCESS: {
            return { ...state, deleting: false, deleted: true }
        }
        case PromotionsActionTypes.VALIDATE_PROMO: {
            return { ...state, validating: true }
        }
        case PromotionsActionTypes.VALIDATE_FINISHED: {
            return { ...state, validating: false, validationResult: action.payload }
        }
        case PromotionsActionTypes.IMPORT_REFERENCES: {
            return { ...state, importing: true, imported: [] }
        }
        case PromotionsActionTypes.IMPORT_PROMOTION: {
            return { ...state, importing: true, importedList: [], collisionInfo: undefined }
        }
        case PromotionsActionTypes.IMPORT_FINISHED: {
            return { ...state, importing: false, importedList: action.payload }
        }
        case PromotionsActionTypes.CLOSE_PROMOTION: {
            return { ...state, closing: true }
        }
        case PromotionsActionTypes.CLOSE_PROMOTION_SUCCESS: {
            return { ...state, closing: false, validationResult: [] }
        }
        case PromotionsActionTypes.REDIRECT_TO_PROMO_LIST_VIEW: {
            return { ...state, redirectedToPromoViewList: true }
        }
        case PromotionsActionTypes.REDIRECT_TO_PROMO_LIST_VIEW_FINISHED: {
            return { ...state, redirectedToPromoViewList: false }
        }
        case ErrorActionTypes.HANDLE_ERROR: {
            return {
                ...state,
                creating: false,
                loading: false,
                validating: false,
                deleting: false,
                importing: false,
                validationResult: [],
                activating: false,
                closing: false
            }
        }
        default: {
            return state
        }
    }
}

export { reducer as promotionsReducer }
