import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import { DictionaryItem, DictionaryName } from '~/store/dictionaries/types'
import { fetchNotifications, makeAllRead, makeRead } from '~/store/notifications/actions'
import { Notification } from '~/store/notifications/types'
import { PaginationMeta, TotalRecords } from '~/store/types'
import { localStorageService } from '~/services/LocalStorage'
import { ApplicationState, ConnectedReduxProps } from '~/store'
import { RouteComponentProps } from 'react-router-dom'

import React, { Fragment, PureComponent } from 'react'
import ColumnToggler from '~/components/ColumnToggler/ColumnToggler'
import CommonLoader from '~/components/Loaders/CommonLoader'
import PageHeader from '~/components/PageHeader/PageHeader'
import FilterLabels from '~/components/TableFilter/lib/FilterLabels'
import TableFilter, { IRefObject } from '~/components/TableFilter/TableFilter'
import dictionariesStatic from '~/store/dictionaries/static'
import NotificationsList from './List'
import TableSortSession, { TTableSortSession } from '~/utils/tableSortSession'
import TableFilterSession from '~/utils/tableFilterSession'
import TableHeaderSortingCell from '~/components/TableHeaderSortingCell/TableHeaderSortingCell'
import TablePaginationFooter from '~/components/TablePaginationFooter/TablePaginationFooter'
import translations from '~/utils/translations'

interface TReduxState {
	loading: boolean,
	loadingNotification: boolean
	notifications: Notification[]
	meta: PaginationMeta
	totalRecords: TotalRecords
}

interface TReduxActions {
	fetchNotifications: typeof fetchNotifications
	makeAllRead: typeof makeAllRead
	makeRead: typeof makeRead
}

type TNotifications = TReduxState & ConnectedReduxProps & TReduxActions & RouteComponentProps

interface State {
	filterLabels: any[]
	columns: {
		name: string
		label: string
		dictionaryName?: DictionaryName
		dictionary?: DictionaryItem[]
		projection: boolean
		type?: string
	}[]
	sortColumn: string | undefined | TTableSortSession
	sortDirection: 'ASC' | 'DESC' | undefined | TTableSortSession
	notificationsToRead: { page: number, id: string }[]
	topCheckboxAllByPage: number[]
	firstLoad: boolean
}

interface storageNotificationsColumns {
	name: string
	shouldDisplay: boolean
}

class Notifications extends PureComponent<TNotifications, State> {

	private getTableSortSession = (table: boolean, name?: string): undefined | TTableSortSession => {
		if (table) {
			return TableSortSession.getSorting('notifications')
		}
		else {
			//@ts-ignore: no-undefined-result
			return TableSortSession.getSorting('notifications')[name]
		}
	}

	private getTableFilterSession = (): any => {
		const filters = TableFilterSession.getFilters('notifications')
		if (!this.props.location.state && !filters.length) {
			filters.push({
				"name": "type",
				"value": "ERROR",
				"text": "Błąd",
				"key": "ERROR",
				"table": 'notifications'
			})
		}
		return filters
	}

	state: State = {
		filterLabels: this.getTableFilterSession(),
		sortColumn: this.getTableSortSession(true) ? this.getTableSortSession(false, 'value') : 'time',
		sortDirection: TableSortSession.getSorting('notifications') ? this.getTableSortSession(false, 'direction') : undefined,
		columns: [
			{ name: 'type', dictionary: dictionariesStatic.NOTIFICATION_TYPE, label: 'Typ', projection: true },
			{ name: 'name', dictionary: dictionariesStatic.NOTIFICATION_NAME, label: 'Tytuł', projection: true },
			{ name: 'time', label: 'Data', projection: true, type: 'date' },
			{ name: 'dateFrom', label: "Zakres od", projection: true, type: 'date' },
			{ name: 'dateTo', label: "Zakres do", projection: true, type: 'date' },
		],
		notificationsToRead: [],
		topCheckboxAllByPage: [],
		firstLoad: true
	}

	private tableFilter = React.createRef<IRefObject>()

	componentDidMount(): void {
		document.title = translations.format("app.notifications")
		this.getNotifications({ page: 1, sort: 'time' })
		this.setColumnsFromLocalStorage()
	}

	componentDidUpdate(prevProps: TNotifications, prevState: State): void {
		if (!this.props.location.state && this.state.firstLoad) {
			this.setState({firstLoad: false})
			this.getNotifications(undefined, true)
		}
		if (JSON.stringify(prevProps.notifications) !== JSON.stringify(this.props.notifications)) {
			if (this.props.location.state) {
				this.setState({ filterLabels: [{ ...this.props.location.state.notifications }] })
				const { value, text, key } = this.props.location.state.notifications
				if (value === '') {
					this.tableFilter.current && this.tableFilter.current.clearMultipleFilter(['dateFrom', 'dateTo', 'time'])
				}
				this.tableFilter.current && this.tableFilter.current.changeTableFilter('name', value, text, key)

			}
		}

		this.clearTimeFilters(prevState)

		if (JSON.stringify(this.state.filterLabels) !== JSON.stringify(prevState.filterLabels)) {
			this.getNotifications({ page: 1 })
		}

		if (this.state.sortColumn !== prevState.sortColumn || this.state.sortDirection !== prevState.sortDirection) {
			this.getNotifications({ sortColumn: this.state.sortColumn, sortDirection: this.state.sortDirection, page: 1 })
		}

		if (this.state.topCheckboxAllByPage.includes(this.props.meta.page) && (prevProps.meta.page !== this.props.meta.page)) {
			const filteredToRead = this.props.notifications.filter(x => !x.read).map((notification) => { return { id: notification.id, page: this.props.meta.page } });
			this.setState({
				notificationsToRead: [...this.state.notificationsToRead, ...filteredToRead.filter(val => !this.state.notificationsToRead.find(x => x.id === val.id && x.page === this.props.meta.page))],
			})
		}
	}

	private clearTimeFilters(prevState: State): void {
		const prevStateTime = prevState.filterLabels.find(x => x.name === 'name')
		const stateTime = this.state.filterLabels.find(x => x.name === 'name')

		if (prevStateTime && stateTime) {
			if ((JSON.stringify(prevStateTime.key) !== JSON.stringify(stateTime.key))) {
				this.tableFilter.current && this.tableFilter.current.clearMultipleFilter(['dateFrom', 'dateTo', 'time'])
			}
		}
	}

	private getNotifications(params?: Object, first?: boolean): void {
		const { meta } = this.props
		const paramsObj = {
			page: meta.page,
			size: meta.size,
			filters: this.state.filterLabels,
			sortColumn: this.state.sortColumn,
			sortDirection: this.state.sortDirection
		}
		if (first && !this.state.filterLabels.length) {
			const errorFilter = {
				name: "type",
				value: "ERROR",
				text: "Błąd",
				key: "ERROR"
			}
			this.addUpdateFilterLabel(errorFilter.name, errorFilter.value, errorFilter.text, errorFilter.key)
			this.setState({filterLabels: [errorFilter]})
			paramsObj.filters = [errorFilter]
		}

		this.props.fetchNotifications(Object.assign(paramsObj, params))
	}

	private setColumnsFromLocalStorage(): void {
		const columnsLS: storageNotificationsColumns[] = localStorageService.get('storageNotificationsColumns')

		if (columnsLS) {
			let { columns } = this.state
			for (const columnFilter of columnsLS) {
				columns = columns.map(column => column.name === columnFilter.name ? { ...column, projection: columnFilter.shouldDisplay } : { ...column })
			}
			this.setState({ columns })
		}
	}

	private handleSort(name: string): void {
		if (this.state.sortColumn !== name) {
			this.setState({
				sortColumn: name,
				sortDirection: 'ASC'
			})
		} else {
			this.setState(state => ({
				sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC',
				sortColumn: name
			}))
		}
		TableSortSession.setSoring({ key: 'notifications', value: name, direction: this.state.sortDirection === 'ASC' ? 'DESC' : 'ASC' })
	}

	private removeFilterLabel(name: string): void {
		this.clearNotificationsFilterState()
		this.setState(state => ({
			filterLabels: state.filterLabels.filter(label => label.name !== name)
		}))
		TableFilterSession.clearFilter({ name, table: 'notifications', value: '' })
		if (this.tableFilter.current) {
			this.tableFilter.current.clearFilter(name)
		}
	}

	private addUpdateFilterLabel(name: string, value: string, text?: string, key?: string): void {
		this.setState(state => {
			const found = state.filterLabels.find(label => label.name === name)
			return {
				filterLabels: found ? state.filterLabels.map(label => label.name === name ? { name, value, text, key } : { ...label }) :
					[...state.filterLabels, { name, value, text, key }]
			}
		})
		if ((name && value) && (name !== 'dateFrom' && name !== 'dateTo' && name !== 'time')) {
			TableFilterSession.setFilters({ name, value, text, key, table: 'notifications' })
		}
	}

	private clearAllFilter(): void {
		this.setState({ filterLabels: [] })
		this.clearNotificationsFilterState()
		if (this.tableFilter.current) {
			this.tableFilter.current.clearAllFilter()
		}
		TableFilterSession.clearFilter(undefined, 'notifications')
	}

	private handleAddFilter = (name: string, value: string, text?: string, key?: string): void => {
		this.clearNotificationsFilterState()
		let checkIsInFilterTable = false;
		this.state.filterLabels.forEach(filter => {
			if (filter.value === value && filter.name === name) {
				checkIsInFilterTable = true;
			}
		});
		if (!checkIsInFilterTable) {
			value !== '' ?
				this.addUpdateFilterLabel(name, value, text, key) :
				this.removeFilterLabel(name)
		}
	}

	private toggleDistrigoWarehouseColumn = (name: string, checked: boolean): void => {
		this.setState(state => ({
			columns: state.columns.map(column => {
				return column.name === name ? { ...column, projection: checked } : { ...column }
			})
		}))
	}

	private clearNotificationsFilterState(): void {
		if (this.props.location.state) {
			this.props.history.replace({
				pathname: '/notifications',
				state: null
			});
		}
	}

	private pageChange = (page: number): void => {
		this.getNotifications({ page })
	}

	private pageSizeChange = (size: number): void => {
		this.getNotifications({ size })
	}

	private toggleSingleToRead = (id: string): void => {
		const { page } = this.props.meta
		this.setState((prevState) => ({
			notificationsToRead: prevState.notificationsToRead.find(x => x.id === id) ? this.state.notificationsToRead.filter(i => i.id !== id) : [...this.state.notificationsToRead, { id, page }],
		}))
		if (this.state.notificationsToRead.find(x => x.id === id)) {
			this.setState({
				topCheckboxAllByPage: this.state.topCheckboxAllByPage.filter(x => x !== page) || [...this.state.topCheckboxAllByPage],
			})
		}
	}

	private toggleAllToRead = (): void => {
		const { page } = this.props.meta
		const notificationsToRead = this.props.notifications.filter(x => !x.read).map((notification) => { return { id: notification.id, page } })
		this.setState((prevState) => ({
			topCheckboxAllByPage: !prevState.topCheckboxAllByPage.includes(page) ? [...prevState.topCheckboxAllByPage, page] : prevState.topCheckboxAllByPage.filter(x => x !== page),
			notificationsToRead: !prevState.topCheckboxAllByPage.includes(page) ? [...prevState.notificationsToRead, ...notificationsToRead] : [...prevState.notificationsToRead.filter(x => x.page !== page)]
		}))
	}

	private handleMakeAllRead = (): void => {
		this.props.makeAllRead(this.state.notificationsToRead.map(x => x.id))
		this.setState({
			topCheckboxAllByPage: [],
			notificationsToRead: []
		})
	}

	private handleMakeRead = (id: string): void => {
		this.props.makeRead(id)
		if (this.state.notificationsToRead.find(x => x.id === id)) {
			this.toggleSingleToRead(id)
		}
	}

	static getDerivedStateFromProps(nextProps: any, prevState: State) {
		return ({
			columns: prevState.columns.map((column: any) => {
				return column.dictionaryName ? { ...column, dictionary: nextProps.dictionaries[column.dictionaryName] } : { ...column }
			})
		})
	}

	render() {
		const { notifications, meta, loading, loadingNotification, totalRecords } = this.props
		const { notificationsToRead } = this.state

		return (
			<Fragment>
				<PageHeader
					icon='bell'
					title='Powiadomienia'
					breadcrumb={[]}
					buttons={[
						{
							icon: 'bell slash',
							content: notificationsToRead.length > 0 ? 'Oznacz zaznaczone jako przeczytane' : 'Oznacz wszystkie jako przeczytane',
							labelPosition: 'left',
							onClick: () => this.handleMakeAllRead(),
							primary: true,
						}
					]}
					refreshAction={() => { this.getNotifications() }}
					loading={loading}
				/>
				<div className="uber-table">
					<FilterLabels
						filterLabels={this.state.filterLabels}
						removeFilterLabel={this.removeFilterLabel.bind(this)}
						clearAllFilter={this.clearAllFilter.bind(this)} />
					<div className="docked">
						<ColumnToggler onToggle={this.toggleDistrigoWarehouseColumn} columns={this.state.columns} storageKey={'distrigoWarehouses'} />
					</div>
					<Table style={{ whiteSpace: 'nowrap', marginTop: 0 }} selectable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell collapsing />
								{this.state.columns.map(column =>
									column.projection &&
									<Fragment key={column.name}>
										<TableHeaderSortingCell
											name={column.name}
											label={column.label}
											onSort={this.handleSort.bind(this, column.name)}
											sortColumn={this.state.sortColumn}
											sortDirection={this.state.sortDirection}
										/>
									</Fragment>
								)}
								<Table.HeaderCell collapsing />
							</Table.Row>
							<TableFilter
								checkboxes
								checkboxesDisabled={this.props.notifications.some(x => !x.read)}
								checkboxChecked={this.state.topCheckboxAllByPage.some(x => x === this.props.meta.page)}
								checkboxesSelectAll={() => this.toggleAllToRead()} ref={this.tableFilter}
								initValues={this.state.filterLabels}
								columns={this.state.columns}
								onAddFilter={this.handleAddFilter} icon='right'
							/>
						</Table.Header>
						<Table.Body>
							<NotificationsList
								checkedToRead={notificationsToRead.map(x => x.id)}
								toggleNotificationsToRead={this.toggleSingleToRead}
								notifications={notifications}
								readMessage={this.handleMakeRead}
								columns={this.state.columns}
							/>
						</Table.Body>
						<TablePaginationFooter
							meta={meta}
							totalRecords={totalRecords}
							onPageChange={this.pageChange}
							onPageSizeChange={this.pageSizeChange}
						/>
					</Table>
				</div>
				<CommonLoader loading={loading || loadingNotification } />
			</Fragment>
		)
	}
}

const mapStateToProps = ({ notifications }: ApplicationState) => ({
	notifications: notifications.list,
	meta: notifications.meta,
	loading: notifications.loadingNotifications,
	loadingNotification: notifications.loadingNotification,
	totalRecords: notifications.totalRecords,
	lasts: notifications.lasts,
})

const mapDispatchToProps = {
	fetchNotifications,
	makeAllRead,
	makeRead
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications)
