import { Icon } from "semantic-ui-react";
import { TTableSortSession } from "~/utils/tableSortSession";

import React from "react";

type TProps = {
  sortDirection?: "ASC" | "DESC" | TTableSortSession | string;
};

const TableSortIcon: React.FC<TProps> = ({ sortDirection }): JSX.Element => (
  <Icon
    className="sort-icon"
    title="Sortuj"
    name={sortDirection === "ASC" ? "arrow up" : "arrow down"}
  />
);

export default TableSortIcon;
