import { Button, ButtonProps } from 'semantic-ui-react'
import React, { useContext } from 'react'
import { FormContext } from "~/components/ModelForm/lib/form.config";


export const SubmitButton: React.FC<ButtonProps> = (props) => {
    const { submit } = useContext(FormContext)
    return <Button {...props} onClick={submit}>{props.children}</Button>
}

