import React, { useEffect } from "react";
import { THiddenProps } from "~/components/SmartField/lib/types";
import { useSmartFieldLogic } from "~/components/SmartField/hooks/useSmartFieldLogic";
import { SmartPopup } from "~/components/SmartField/SmartPopup";
import { getHiddenIcon } from "~/components/SmartField/lib/utils";

export const SmartHidden: React.FC<THiddenProps> = (props) => {

    const {
        modified,
        loading,
        success,
        error,
        handleEdit,
        handleSave,
        getPopupContent
    } = useSmartFieldLogic(props);

    useEffect(() => {
        props.stateChange && props.stateChange(modified, loading, success, error)
        // eslint-disable-next-line
    }, [modified, loading, success, error])

    useEffect(() => {
        handleEdit(props.editValue)
        // eslint-disable-next-line
    }, [props.editValue])

    useEffect(() => {
        if (props.blur) {
            handleSave()
        }
        // eslint-disable-next-line
    }, [props.blur])

    return (
        props.renderIcon ?
            <SmartPopup
                mouseEnterDelay={error ? 0 : 300}
                verticalOffset={0}
                description={getPopupContent()}>
                {getHiddenIcon(loading, error, modified, success)}
            </SmartPopup>
            : null
    )
}