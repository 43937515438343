import { Grid } from 'semantic-ui-react';
import React, { useCallback, useMemo } from 'react';
import DetailCardStatus from '~/pages/Orders/Details/lib/DetailCardStatus';
import DetailCardDelivery from '~/pages/Orders/Details/lib/DetailCardDelivery';
import DetailCardAnnotations from '~/pages/Orders/Details/lib/DetailCardAnnotations';
import DetailCardService from '~/pages/Orders/Details/lib/DetailCardService';
import DetailCardWarranty from '~/pages/Orders/Details/lib/DetailCardWarranty';
import DetailCard from '~/components/DetailCard/DetailCard';
import DetailCardLines from '~/pages/Orders/Details/lib/DetailCardLines';
import BackOrderNumberList from '~/pages/Orders/Details/lib/BackOrderNumberList';
import AnomaliesList from '~/pages/Orders/Details/lib/AnomaliesList';
import { deprioritize, prioritize } from '~/store/orders/actions';
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions';
import { useDispatch } from 'react-redux';
import { CardsProps } from '~/pages/Orders/Details/lib/constants';
import { ORDER_TYPE_ENUM } from '~/store/orders/types';
import CardDetailsBonusAndSpecialLimits from '~/pages/Orders/lib/Add/BonusAndSpecialLimits';

const Cards = ({ editMode, order }: CardsProps) => {
  const dispatch = useDispatch();

  const { renderField, renderNumber, renderTextArea, renderLabel } = useRenderingFunctions(order, editMode);

  const handlePrioritetUpdate = useMemo(
    () => (e: any, data: any) => {
      if (order) {
        if (data.value === true) {
          dispatch(prioritize(order.id));
        }

        if (data.value === false) {
          dispatch(deprioritize(order.id));
        }
      }
    },
    [order, dispatch]
  );

  const isStatus = (value: string, ...statuses: string[]): boolean => {
    return statuses.indexOf(value) !== -1;
  };

  const anomaliesList = useCallback((): JSX.Element => <AnomaliesList order={order} />, [order]);

  const shrink: boolean = order ? order.type === ORDER_TYPE_ENUM.WARRANTY : false;

  const renderBackOrderNumberList = useCallback(
    (): JSX.Element => <BackOrderNumberList key="batch" shrink={shrink} renderLabel={renderLabel} order={order} />,
    [order, renderLabel, shrink]
  );

  const cards = useMemo(
    () =>
      !order
        ? []
        : [
            <DetailCardStatus key="status" order={order} renderLabel={renderLabel} shrink={shrink} />,
            <DetailCardDelivery key="delivery" order={order} renderLabel={renderLabel} shrink={shrink} />,
            <DetailCardAnnotations key="annotations" shrink={shrink} renderTextArea={renderTextArea} />,
            <DetailCardService
              key="service"
              renderTextArea={renderTextArea}
              shrink={shrink}
              renderLabel={renderLabel}
              editMode={editMode}
              handlePrioritetUpdate={handlePrioritetUpdate}
              isStatus={isStatus}
              renderField={renderField}
              order={order}
            />,
            order.backorderIdNumberList && order.backorderIdNumberList.length > 0 && renderBackOrderNumberList(),
            order.type === ORDER_TYPE_ENUM.WARRANTY && (
              <DetailCardWarranty
                key="warranty"
                order={order}
                renderField={renderField}
                isStatus={isStatus}
                renderNumber={renderNumber}
              />
            ),
            order.anomalies && (
              <DetailCard
                title="Anomalie"
                titleFormat
                id="anomally"
                key="anomally"
                minHeight={40}
                width={shrink ? 2.75 : 4}
                smallWidth={5.33}
              >
                {anomaliesList()}
              </DetailCard>
            ),
            (order.type === ORDER_TYPE_ENUM.BONUS || order.type === ORDER_TYPE_ENUM.SPECIAL) && (
              <CardDetailsBonusAndSpecialLimits
                limit={order?.bonusSpecialOrderDiscountLimit || 0}
                userName={order?.author}
              />
            ),
            <DetailCardLines key="lines" order={order} isStatus={isStatus} editMode={editMode} />,
          ],
    [
      anomaliesList,
      editMode,
      order,
      renderBackOrderNumberList,
      renderField,
      renderLabel,
      renderNumber,
      renderTextArea,
      shrink,
      handlePrioritetUpdate,
    ]
  );

  return (
    <div className="uber-content overflow-visible">
      <Grid stretched>{cards}</Grid>
    </div>
  );
};

export default Cards;
