import { connect } from 'react-redux'
import { Button, Container, Form, Grid, Icon, Label, Popup } from 'semantic-ui-react'
import { SmartHidden } from '~/components/SmartField/SmartHidden'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { REQUIRED } from '~/components/SmartField/lib/validator'
import { LoadProviderFn, SelectionChangeFn, TNode, TSelection } from '~/components/Tree/lib/types'
import { ApplicationState } from '~/store'
import { fetchDictionary } from '~/store/dictionaries/actions'
import { advancedSearch, fetchFamilies, fetchSegments, fetchSlots, fetchSpareParts } from '~/store/hierarchy/actions'
import { TGroup, TNodeData } from '~/store/hierarchy/types'
import { validatePromotion } from '~/store/promotions/actions'
import { AdditionalData, TPromotion, TReferenceRule, TReferenceRuleData } from '~/store/promotions/types'
import { callApi } from '~/utils/api'
import { toastInfo } from '~/utils/toast'
import { usePromoCollisionModal } from './Modals'

import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import ImportReferences from './ImportReferences'
import Tree from '~/components/Tree/Tree'
import { DictionaryName } from '~/store/dictionaries/types'

type TReduxState = {
    indexes: TNodeData[]
    promotion: TPromotion
    validating: boolean
    validationResult: AdditionalData[]
}

type TReduxActions = {
    fetchSegments: typeof fetchSegments
    fetchSlots: typeof fetchSlots
    fetchFamilies: typeof fetchFamilies
    fetchSpareParts: typeof fetchSpareParts
    fetchDictionary: typeof fetchDictionary
    validatePromotion: typeof validatePromotion
    advancedSearch: typeof advancedSearch
}

type TProps = TReduxState & TReduxActions & {
    editMode: boolean
    referenceRule?: TReferenceRule
    modelPropertyName: 'ruleSet.referenceRule' | 'resultSet.referenceRule' | 'additionalOrderDiscount' | undefined
    description: string
    cardinalityControl?: boolean
    cardinalityControlRange?: boolean
    isolatedCollectionControl?: boolean
    isolatedResultCollectionControl?: boolean
    associativeDiscountGroup?: string
    advancedSearchResponseGroup1?: any[]
    advancedSearchResponseGroup2?: any[]
    onSelectionChange?: any
}

const options = [
    { text: 'Indeks', value: 'index' },
    { text: 'Segment', value: 'segment' },
    { text: 'Slot', value: 'slot' },
    { text: 'Rodzina', value: 'family' },
    { text: 'Produkt', value: 'product' },
]

interface HandleChange {
    [key: string]: string | any;
}

const mapSelectionToReferenceRule = (nodes: TSelection[]): TReferenceRuleData[] => {
    const indexList: TReferenceRuleData[] = nodes.filter(node => node.length === 1).map(node => ({
        index: node[0],
        type: "INDEX"
    }))
    const segmentList: TReferenceRuleData[] = nodes.filter(node => node.length === 2).map(node => ({
        index: node[0],
        segment: node[1],
        type: "SEGMENT"
    }))
    const slotList: TReferenceRuleData[] = nodes.filter(node => node.length === 3).map(node => ({
        index: node[0],
        segment: node[1],
        slot: node[2],
        type: "SLOT"
    }))
    const familyList: TReferenceRuleData[] = nodes.filter(node => node.length === 4).map(node => ({
        index: node[0],
        segment: node[1],
        slot: node[2],
        family: node[3],
        type: "FAMILY"
    }))
    const referenceList: TReferenceRuleData[] = nodes.filter(node => node.length === 5).map(node => ({
        index: node[0],
        segment: node[1],
        slot: node[2],
        family: node[3],
        referenceId: node[4],
        type: "PRODUCT"
    }))

    return [...indexList, ...segmentList, ...slotList, ...familyList, ...referenceList]
}

const mapReferenceRuleToSelection = (rule?: TReferenceRule): TSelection[] => {
    if (!rule || !rule.references) {
        return []
    }
    const selection: TSelection[] = []
    rule.references.length !== 0 && rule.references.forEach((item) => item.type === "INDEX" && selection.push([item.index]))
    rule.references.length !== 0 && rule.references.forEach((item) => item.type === "SEGMENT" && selection.push([item.index, item.segment] as TSelection))
    rule.references.length !== 0 && rule.references.forEach((item) => item.type === "SLOT" && selection.push([item.index, item.segment, item.slot] as TSelection))
    rule.references.length !== 0 && rule.references.forEach((item) => item.type === "FAMILY" && selection.push([item.index, item.segment, item.slot, item.family] as TSelection))
    rule.references.length !== 0 && rule.references.forEach((item) => item.type === "PRODUCT" && selection.push([item.index, item.segment, item.slot, item.family, item.referenceId] as TSelection))
    return selection
}

const ProductsTree: React.FC<TProps> = ({
    editMode,
    referenceRule,
    modelPropertyName,
    description,
    cardinalityControl = false,
    cardinalityControlRange = false,
    isolatedCollectionControl = false,
    isolatedResultCollectionControl = false,
    indexes,
    promotion,
    validating,
    validationResult,
    associativeDiscountGroup,
    advancedSearchResponseGroup1,
    advancedSearchResponseGroup2,
    fetchSegments,
    fetchSlots,
    fetchFamilies,
    fetchSpareParts,
    fetchDictionary,
    validatePromotion,
    advancedSearch,
    onSelectionChange
}) => {
    const selectedInit = mapReferenceRuleToSelection(referenceRule)

    const advancedSearchResponse = associativeDiscountGroup === 'GROUP_1' ? advancedSearchResponseGroup1 : advancedSearchResponseGroup2

    const validationEnabled = modelPropertyName === 'ruleSet.referenceRule'
    const hasCollision = validationResult.length > 0

    const [selected, setSelected] = useState<TSelection[]>(selectedInit)
    const [openModal, setOpenModal] = useState(false)
    const [openImportModal, setOpenImportModal] = useState(false)
    const [triggerSave, setTriggerSave] = useState(false)
    const [filterName, setFilterName] = useState<any>('product')
    const [filterValue, setFilterValue] = useState<any>('')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const promoCollisionModal = validationEnabled && usePromoCollisionModal(openModal, validationResult, () => { setOpenModal(false) })

    const { renderField, renderToggle } = useRenderingFunctions(promotion as any, editMode)

    useEffect(() => {
        advancedSearch(filterName, filterValue, associativeDiscountGroup)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchDictionary(DictionaryName.sparePartHierarchyIndex)
    }, [fetchDictionary])

    useEffect(() => {
        if (openImportModal) {
            setOpenImportModal(false)
        }
    }, [openImportModal])

    const handleChangeSmart = useCallback((modified: boolean) => {
        setTriggerSave(modified)
    },[]);

    useEffect(() => {
        if (triggerSave) {
            setTriggerSave(false)
        }
    }, [triggerSave, handleChangeSmart])


    const validateReferenceSet = (referenceRuleData: TReferenceRuleData[]): void => {
        if (!editMode) return
        const data: TPromotion = {
            ...promotion,
            ruleSet: {
                ...promotion.ruleSet,
                referenceRule: {
                    references: [...referenceRuleData],
                    cardinality: promotion?.ruleSet?.referenceRule?.cardinality,
                    maxCardinality: promotion?.ruleSet?.referenceRule?.maxCardinality
                }
            }
        }
        validatePromotion(data)
    }

    useEffect(() => {
        setSelected(selectedInit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceRule])

    const selectionChangeHandler: SelectionChangeFn = (nodes: TSelection[]) => {
        setSelected(nodes)
    }

    useEffect(() => {
        const referenceRuleUpdated = mapSelectionToReferenceRule(selected)
        if (validationEnabled && (promotion.status === "DRAFT" || promotion.status === "CLOSED")) {
            validateReferenceSet(referenceRuleUpdated)
        }
        setTriggerSave(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, promotion])

    useEffect(() => {
        if (selected && onSelectionChange) {
            onSelectionChange(mapSelectionToReferenceRule(selected))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    const loadProvider: LoadProviderFn = (parent) => {
        return new Promise((resolve: (nodes: TNode[]) => void, reject) => {
            const fetcher: (items: TNodeData[]) => void = (items) => {
                resolve(items)
            }
            switch (parent.group as TGroup) {
                case 'INDEX':
                    fetchSegments(parent as TNodeData, fetcher)
                    break
                case 'SEGMENT':
                    fetchSlots(parent as TNodeData, fetcher)
                    break
                case 'SLOT':
                    fetchFamilies(parent as TNodeData, fetcher)
                    break
                case 'FAMILY':
                    fetchSpareParts(parent as TNodeData, fetcher)
                    break
            }
        })
    }

    const productsTreeRendered: JSX.Element = (
        <Tree
            initialSelection={selected}
            advancedSearchResponse={advancedSearchResponse || []}
            rootNodes={indexes}
            loadProvider={loadProvider}
            onSelectionChange={selectionChangeHandler}
            editable={editMode}
            sortFn={(a, b) => {
                return a.id > b.id ? 1 : -1
            }}
        />
    )

    const removeSelection = (toRemove: TSelection): void => {
        setSelected(selected.filter((node) => node !== toRemove))
    }

    const handleImportReferences = (data: TReferenceRuleData[]): void => {
        if (promotion && promotion.ruleSet) {
            const newSelection = mapReferenceRuleToSelection({
                ...promotion.ruleSet.referenceRule,
                references: data
            })
            setSelected(newSelection)
        } else {
            const newSelection = mapReferenceRuleToSelection({
                references: data
            })
            setSelected(newSelection)
        }
    }

    const renderSelected = (depth: number): JSX.Element[] | JSX.Element => {
        const rendered = selected.filter(node => node.length === depth)
            .map((node, index) => (
                <Label key={index} style={{ margin: 1 }} title={node.join(' > ')}>
                    {node[depth - 1]}
                    {editMode && <Icon name="delete" onClick={() => { removeSelection(node) }} />}
                </Label>
            ))
        return rendered.length > 0 ? rendered : <em>Nie wybrano</em>
    }

    const handleChange = (e: SyntheticEvent<Element, Event> | undefined, { name, value }: HandleChange) => {
        setFilterValue(value)
        advancedSearch(name, value, associativeDiscountGroup)
    }

    const handleSpecificEdit = (e: SyntheticEvent<Element, Event> | undefined, { value }: any) => {
        setFilterName(value)
        setFilterValue('');
        advancedSearch(value, '', associativeDiscountGroup)
    }

    const isStatusDraftOrClosed = useCallback((): boolean => {
        return (!!promotion && promotion.status === 'DRAFT') || (!!promotion && promotion.status === 'CLOSED')
    }, [promotion]);

    return (
        <Grid columns={2} divided>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Grid columns={4}>
                        <Grid.Row>
                            <Form.Select
                                fluid
                                value={filterName}
                                options={options}
                                onChange={handleSpecificEdit}
                            />
                            <Grid.Column width={8}>
                                <Form.Input
                                    name={filterName}
                                    value={filterValue}
                                    onChange={handleChange}
                                />
                                {filterValue !== '' && advancedSearchResponse?.length === 0 ?
                                    <p className='text-red'>*Brak wyników dla tego wyszukania</p>
                                    :
                                    ''}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {productsTreeRendered}
                    <span style={{ position: 'absolute', top: 0, right: 10 }}>
                        <SmartHidden name={modelPropertyName + ".references"}
                                     value={String(selectedInit)}
                                     editValue={String(selected)}
                                     saveMapper={() => mapSelectionToReferenceRule(selected)}
                                     blur={triggerSave}
                                     renderIcon={true}
                                     stateChange={handleChangeSmart}/>
                    </span>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {validationEnabled && <>
                                {promoCollisionModal}
                                <Popup trigger={
                                    <Button basic compact size="small"
                                            content={hasCollision ? "Kolizja hierarchii" : "Hierarchie unikalne"}
                                            icon={"clone outline"} primary={hasCollision} labelPosition="left"
                                            disabled={validating || !isStatusDraftOrClosed()} onClick={() => {
                                        setOpenModal(true)
                                    }}/>
                                } content="app.promo-remove-promo-template-question" />
                            </>}
                            <Button basic compact content="Wyczyść" disabled={!editMode} icon="eraser" labelPosition="left" onClick={() => { setSelected([]) }} />
                            <ImportReferences triggerOpen={openImportModal} importSuccess={handleImportReferences} overwriteWarn={selected.length > 0} />
                            <Button basic compact content="Import" disabled={!editMode} icon="upload" labelPosition="left" onClick={() => { setOpenImportModal(true) }} />
                            <Button basic compact circular floated="right" icon="refresh" title="Przelicz hierarchie" onClick={() => {
                                // TODO remove this debug button
                                callApi('POST', '/category-hierarchy/calc').then((response) => { toastInfo("OK", "Odświeżone") })
                            }} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Label style={{ lineHeight: '18px', marginBottom: 10 }}>
                        {description}
                    </Label>
                    {cardinalityControl && renderField(modelPropertyName + '.cardinality', {
                        label: "Liczność grupy",
                        description: "Określa liczbę sztuk produktów z zaznaczonej grupy, która musi się znaleźć w zamówieniu, aby promocja została zastosowana.",
                        type: 'number',
                        validators: [REQUIRED],
                        min: 1
                    })}
                    <Form.Group widths="equal">
                        {cardinalityControlRange && renderField(modelPropertyName + '.cardinality', {
                            label: "Minimalna liczność grupy",
                            description: "Określa minimalna liczbę sztuk produktów z zaznaczonej grupy, która musi się znaleźć w zamówieniu, aby promocja została zastosowana.",
                            type: 'number',
                            validators: [REQUIRED],
                            min: 1
                        })}
                        {cardinalityControlRange && renderField(modelPropertyName + '.maxCardinality', {
                            label: "Maksymalna liczność grupy",
                            description: "Określa maksymalna liczbę sztuk produktów z zaznaczonej grupy, która musi się znaleźć w zamówieniu, aby promocja została zastosowana.",
                            type: 'number',
                            validators: [REQUIRED],
                            min: 1
                        })}
                    </Form.Group>
                    {isolatedCollectionControl && renderToggle("isolatedCollection", {
                        label: "Zbiór izolowany",
                        description: "Określa czy warunki zdefiniowane dla poszczególnych progów dotyczą wszystkich produktów znajdujących się w koszyku czy każdy produkt będzie rozpatrywany osobno.",
                    })}
                    {isolatedResultCollectionControl && renderToggle("isolatedResultCollection", {
                        label: "Zbiór izolowany",
                        description: "Określa czy warunki zdefiniowane dla poszczególnych progów dotyczą wszystkich produktów znajdujących się w koszyku czy każdy produkt będzie rozpatrywany osobno.",
                    })}
                    <Container style={{ marginBottom: 5 }}>
                        <b>Indeksy</b>
                        <br />
                        {renderSelected(1)}
                    </Container>
                    <Container style={{ marginBottom: 5 }}>
                        <b>Segmenty</b>
                        <br />
                        {renderSelected(2)}
                    </Container>
                    <Container style={{ marginBottom: 5 }}>
                        <b>Sloty</b>
                        <br />
                        {renderSelected(3)}
                    </Container>
                    <Container style={{ marginBottom: 5 }}>
                        <b>Rodziny</b>
                        <br />
                        {renderSelected(4)}
                    </Container>
                    <Container style={{ marginBottom: 5 }}>
                        <b>Produkty</b>
                        <br />
                        {renderSelected(5)}
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ promotions, dictionaries, hierarchy }: ApplicationState) => {
    return {
        indexes: dictionaries["spare-part-hierarchy-index"].map((index): TNodeData => ({
            group: 'INDEX',
            id: index.value,
            label: index.text,
            leaf: false
        })),
        promotion: promotions.selected || {} as TPromotion,
        validating: promotions.validating,
        validationResult: promotions.validationResult,
        advancedSearchResponseGroup1: hierarchy.advancedSearchResponseGroup1,
        advancedSearchResponseGroup2: hierarchy.advancedSearchResponseGroup2
    }
}

const mapDispatchToProps: TReduxActions = {
    fetchSegments,
    fetchSlots,
    fetchFamilies,
    fetchSpareParts,
    fetchDictionary,
    validatePromotion,
    advancedSearch
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsTree)
