import React from 'react';
import { Table } from 'semantic-ui-react';
import { ReasonForRejectionTableCellProps } from '~/pages/Orders/lib/Add/Table/TableBody/constants';

const ReasonForRejectionTableCell = ({ line }: ReasonForRejectionTableCellProps) => (
  <Table.Cell>
    {line.orderLineType === 'REPLACED' && <span style={{ color: 'red' }}>Zamienność</span>}
    {line.orderLineType === 'REPLACED_TO_ORIGINAL' && <span style={{ color: 'red' }}>Zamiana na poprzednik</span>}
  </Table.Cell>
);

export default ReasonForRejectionTableCell;
