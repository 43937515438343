import DetailCard from "~/components/DetailCard/DetailCard";
import { Button, Divider, Form, Input } from "semantic-ui-react";
import React, { useState } from "react";
import {
  ChangePayerCardProps,
  FORM_INIT_STATE,
  FormProps,
} from "~/pages/Customers/Details/lib/card/ChangePayerCard/constants";
import { FormattedMessage } from "react-intl";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { isDatePastOrCurrent } from "~/utils/dateUtils";
import { useDispatch } from "react-redux";
import { addNewPayer } from "~/store/customer/actions";
import moment from "moment";
import UberSearch from "~/components/UberSearch/UberSearch";
import { Customer } from "~/store/customer/types";
import { SmartLabel } from "~/components/SmartField/SmartLabel";

export const ChangePayerCard = ({
  editMode,
  id,
  customerId,
}: ChangePayerCardProps) => {
  const [form, setForm] = useState<FormProps>(FORM_INIT_STATE);

  const dispatch = useDispatch();

  const formatMessage = (id: string) => {
    return <FormattedMessage id={id} defaultMessage={id} />;
  };

  const validateForm = () => {
    return form.payer && form.validFrom && form.creationComment;
  };

  const updateForm = ({ name, value }: { name: string; value: any }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleConfirm = () => {
    dispatch(
      addNewPayer(
        customerId,
        {
          validFrom: moment(form.validFrom).format("YYYY-MM-DDT00:00:00"),
          currentAccountingCustomerID: form.payer?.rrdi,
          creationComment: form.creationComment,
        },
        () => setForm(FORM_INIT_STATE)
      )
    );
  };

  type Payer = {
    displayAddress: string;
    displayName: string;
    rrdi: string;
    informationAddressStreet: string;
    informationAddressNumber: string;
    informationAddressZipcode: string;
    informationAddressCity: string;
    name: string;
  };

  const mapDelivery = (payers: Payer[]): any[] => {
    return payers.map((payer, index) => {
      const key = `${payer.displayName}-${index}`;
      return {
        key,
        title: payer.displayName,
        description: payer.displayAddress,
        payer: { ...payer },
      };
    });
  };

  const formatAddress = (payer: Customer): JSX.Element => {
    return (
      <>
        {payer.informationAddressStreet} {payer.informationAddressNumber}
        <br />
        {payer.informationAddressZipcode} {payer.informationAddressCity}
      </>
    );
  };

  return (
    <DetailCard title="app.customer.change.payer.card.title" id={id} key={id}>
      <Form.Field required>
        <label>
          {formatMessage("app.customer.change.payer.card.currentPayer")}
        </label>
        <UberSearch
          placeholder="Wyszukaj..."
          fluid
          endpoint="/customers/payers"
          onResultSelect={(selected: any) => {
            if (selected.payer) {
              updateForm({
                name: "payer",
                value: selected.payer,
              });
            }

            return "";
          }}
          mapper={(response) => mapDelivery(response)}
          debounce={700}
        />
      </Form.Field>
      {!!form.payer && (
        <>
          <SmartLabel
            label={formatMessage("app.changePayer.name")}
            value={form.payer.name}
          />
          <SmartLabel
            label={formatMessage("app.changePayer.address")}
            value={formatAddress(form.payer)}
          />
          <SmartLabel
            label={formatMessage("app.changePayer.payer")}
            value={form.payer.rrdi}
          />
          <Divider />
        </>
      )}
      <Form.Field required>
        <label>
          {formatMessage(
            "app.customer.change.payer.card.effectiveDateOfTheChange"
          )}
        </label>
        <SemanticDatepicker
          id="validFrom"
          filterDate={isDatePastOrCurrent}
          name="validFrom"
          value={form.validFrom}
          placeholder="RRRR-MM-DD"
          locale="pl-PL"
          onChange={(e, data) =>
            updateForm({ name: data.name, value: data.value })
          }
          autoComplete="off"
          disabled={!editMode}
        />
      </Form.Field>
      <Form.Field required>
        <label>{formatMessage("app.customer.change.payer.card.reason")}</label>
        <Input
          type="text"
          disabled={!editMode}
          name="creationComment"
          value={form.creationComment}
          onChange={(e) =>
            updateForm({ name: e.target.name, value: e.target.value })
          }
        />
      </Form.Field>
      <Button
        icon="add"
        size="medium"
        circular
        basic
        style={{ marginTop: "20px" }}
        className="primary"
        disabled={!editMode || !validateForm()}
        content={formatMessage("app.customer.change.payer.card.confirm")}
        type="submit"
        onClick={handleConfirm}
      />
    </DetailCard>
  );
};
