import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Divider, Dropdown, Table } from "semantic-ui-react";
import { DataGridTable } from "~/components/Table/DataGridTable";
import { TColumn } from "~/components/Table/lib/types";
import { ApplicationState } from "~/store";
import {
  removeAnnouncement,
  fetchAnnouncementList,
  removeAnnouncementRefresh,
  showAnnouncement,
  hideAnnouncement,
} from "~/store/annouoncements/actions";
import { Announcement } from "~/store/annouoncements/types";
import { PaginationMeta, TotalRecords } from "~/store/types";
import { TTableFilterSession } from "~/utils/tableFilterSession";
import { useAnnouncementsColumns } from "./useAnnouncementsColumns";
import TableMoreDropdown from "~/components/TableMoreDropdown/TableMoreDropdown";
import ModalConfirm from "~/components/Modals/ModalConfirm";
import { Link } from "react-router-dom";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { useAdministrationRights } from "../../hooks/useAdministrationRights";
import TablePreviewDetails from "~/components/TablePreviewDetails/TablePreviewDetails";

type TComponentProps = {
  refreshTrigger: number;
};

type TStateProps = {
  announcementList: Announcement[];
  meta: PaginationMeta;
  loading: boolean;
  totalRecords: TotalRecords;
  removeLoading: boolean;
  removeSuccess: boolean;
};

type TReduxActions = {
  fetchAnnouncementList: typeof fetchAnnouncementList;
  removeAnnouncement: typeof removeAnnouncement;
  removeAnnouncementRefresh: typeof removeAnnouncementRefresh;
  showAnnouncement: typeof showAnnouncement;
  hideAnnouncement: typeof hideAnnouncement;
};

type TProps = TComponentProps & TStateProps & TReduxActions;

const AnnouncementsManagement: React.FC<TProps> = ({
  meta,
  loading,
  totalRecords,
  announcementList,
  refreshTrigger,
  removeLoading,
  removeSuccess,
  fetchAnnouncementList,
  removeAnnouncement,
  removeAnnouncementRefresh,
  showAnnouncement,
  hideAnnouncement,
}) => {
  const [tableKey] = useState<string>("announcements");
  const { columnsConfig } = useAnnouncementsColumns({ tableKey });

  const [columns, setColumns] = useState<TColumn[]>(columnsConfig);
  const filterLabelsRef = useRef<TTableFilterSession[]>([]);
  const [sortColumn] = useState<any>("createDate");
  const [sortDirection] = useState<any>("DESC");
  const [params, setParams] = useState<Object>({});
  const [openModal, setOpenModal] = useState<string>();

  const { isSuperAdmin } = useClientPanelRoles();
  const { userHaveCustomerPanelUiAdvertisingEditRole } =
    useAdministrationRights();

  const getAnnouncementList = useCallback(
    (params?: Object): void => {
      const paramsObj = {
        page: meta.page,
        size: meta.size,
        filters: filterLabelsRef.current,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      };
      setParams(paramsObj);
      fetchAnnouncementList(Object.assign(paramsObj, params));
    },
    [
      fetchAnnouncementList,
      setParams,
      meta.page,
      meta.size,
      filterLabelsRef,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    if (refreshTrigger) {
      getAnnouncementList({ ...params, page: 1 });
    }
    // eslint-disable-next-line
  }, [refreshTrigger, getAnnouncementList]);

  const handleConfirm = useCallback(() => {
    if (openModal) {
      removeAnnouncement(openModal);
    }
    setOpenModal(undefined);
  }, [openModal, removeAnnouncement]);

  const handleShow = useCallback(
    (id: string) => {
      showAnnouncement(id);
    },
    [showAnnouncement]
  );

  const handleHide = useCallback(
    (id: string) => {
      hideAnnouncement(id);
    },
    [hideAnnouncement]
  );

  useEffect(() => {
    if (removeSuccess) {
      setOpenModal(undefined);
      getAnnouncementList({ ...params, page: 1 });
      removeAnnouncementRefresh();
    }
  }, [removeSuccess, params, getAnnouncementList, removeAnnouncementRefresh]);

  const dropdown = useCallback(
    (announcement: Announcement) => {
      return userHaveCustomerPanelUiAdvertisingEditRole ? (
        <TableMoreDropdown>
          <Dropdown.Item
            as={Link}
            icon="chevron right"
            to={`/announcements/${announcement.id}`}
            text="Szczegóły"
          />
          <Divider />
          {announcement.hidden ? (
            <Dropdown.Item
              text="Pokaż"
              icon="eye"
              style={{ color: "green" }}
              onClick={() => handleShow(announcement.id)}
            />
          ) : (
            <Dropdown.Item
              text="Ukryj"
              icon="eye slash"
              style={{ color: "red" }}
              onClick={() => handleHide(announcement.id)}
            />
          )}
          <Divider />
          <Dropdown.Item
            text="Usuń"
            icon="trash"
            onClick={() => setOpenModal(announcement.id)}
          />
          <ModalConfirm
            modalOpen={!!openModal}
            headerIcon="trash alternate"
            headerText="app.announcement-remove"
            contentText="app.announcement-remove.question"
            onCancelClick={() => setOpenModal(undefined)}
            onConfirmClick={handleConfirm}
            loading={removeLoading}
          />
        </TableMoreDropdown>
      ) : (
        <TablePreviewDetails path={`/announcements/${announcement.id}`} />
      );
    },
    [
      openModal,
      handleConfirm,
      removeLoading,
      handleShow,
      handleHide,
      userHaveCustomerPanelUiAdvertisingEditRole,
    ]
  );

  return isSuperAdmin ? (
    <DataGridTable
      columns={columns}
      isAdvancedSearchOpen={false}
      meta={meta}
      loading={loading}
      totalRecords={totalRecords}
      fetchMethod={getAnnouncementList}
      initSortColumn={sortColumn}
      initSortDirection={sortDirection}
      tableKey={tableKey}
      dictionaries={undefined}
      setColumns={setColumns}
      noSearch
    >
      {announcementList.map((document: Announcement, index: number) => (
        <Table.Row key={index}>
          {columns &&
            columns.length &&
            columns.map(
              (column) =>
                column.getCell &&
                column.projection &&
                column.getCell(document, column)
            )}
          <Table.Cell className="col-dropdown-menu-sticky">
            {dropdown(document)}
          </Table.Cell>
        </Table.Row>
      ))}
    </DataGridTable>
  ) : null;
};

const mapStateToProps = ({ announcements }: ApplicationState) => ({
  announcementList: announcements.announcementList,
  meta: announcements.meta,
  loading: announcements.loadingAnnouncementList,
  totalRecords: announcements.totalRecords,
  removeLoading: announcements.removeLoading,
  removeSuccess: announcements.removeSuccess,
});

const mapDispatchToProps = {
  fetchAnnouncementList,
  removeAnnouncement,
  removeAnnouncementRefresh,
  showAnnouncement,
  hideAnnouncement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementsManagement);
