import { Reducer } from "redux";
import { LibrarState, LibraryActionTypes, LibraryItem } from "./types";
import { Paginable } from "../types";

export const initialVersionState: LibrarState = {
  loading: false,
  library: [],
  meta: {
    page: 1,
    pages: 1,
    size: 15,
    total: 0,
  },
  totalRecords: {
    total: 0,
    filtered: 0,
  },
  downloadItemLoading: false,
  deleteItemLoading: false,
  addItemLoading: false,
};

const reducer: Reducer<LibrarState> = (state = initialVersionState, action) => {
  switch (action.type) {
    case LibraryActionTypes.FETCH_LIBRARY: {
      return { ...state, loading: true };
    }
    case LibraryActionTypes.FETCH_LIBRARY_SUCCESS: {
      const payload = action.payload.library as Paginable<LibraryItem>;
      return {
        ...state,
        loading: false,
        library: payload.content,
        meta: {
          ...state.meta,
          page: payload.number + 1,
          pages: payload.totalPages,
          size: payload.size,
        },
        totalRecords: {
          total: payload.totalElements,
          filtered: payload.filteredElements,
        },
      };
    }
    case LibraryActionTypes.FETCH_LIBRARY_ERROR: {
      return { ...state, loading: false };
    }
    case LibraryActionTypes.FETCH_LIBRARY_ITEM: {
      return { ...state, downloadItemLoading: true };
    }
    case LibraryActionTypes.FETCH_LIBRARY_ITEM_SUCCESS:
    case LibraryActionTypes.FETCH_LIBRARY_ITEM_ERROR: {
      return { ...state, downloadItemLoading: false };
    }
    case LibraryActionTypes.DELETE_LIBRARY_ITEM: {
      return { ...state, deleteItemLoading: true };
    }
    case LibraryActionTypes.DELETE_LIBRARY_ITEM_SUCCESS:
    case LibraryActionTypes.DELETE_LIBRARY_ITEM_ERROR: {
      return { ...state, deleteItemLoading: false };
    }
    case LibraryActionTypes.ADD_LIBRARY_ITEM: {
      return { ...state, addItemLoading: true };
    }
    case LibraryActionTypes.ADD_LIBRARY_ITEM_SUCCESS:
    case LibraryActionTypes.ADD_LIBRARY_ITEM_ERROR: {
      return { ...state, addItemLoading: false };
    }
  }

  return state;
};

export { reducer as libraryReducer };
