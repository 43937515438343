import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useAnomaliesColumns = () => {
  const { getTextCell, getDictionaryCell, getDateCell } = useCellsRenderer();
  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "anomalyNo",
        label: "Anomalia",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "associatedDocumentNo",
        label: "Dokument",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "anomalyType",
        dictionaryName: DictionaryName.anomalyType,
        label: "Typ",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "anomalyStatus",
        dictionaryName: DictionaryName.anomalyStatus,
        label: "Status",
        projection: true,
        noKey: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "date",
        label: "Data",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "orderNo",
        label: "Zamówienie powiązane",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
    ],
    [getDateCell, getDictionaryCell, getTextCell]
  );

  return { columnsConfig };
};
