import React from "react";
import "./dashboard.scss";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import DashboardClientPanel from "./DashboardClientPanel";
import DashboardDistrigo from "./DashboardDistrigo";

export const Dashboard: React.FC = () => {
  const { isClientPanelUser } = useClientPanelRoles();

  return (
    <>
      {isClientPanelUser === false ? <DashboardDistrigo /> : null}

      {isClientPanelUser === true ? <DashboardClientPanel /> : null}
    </>
  );
};
