import { Form, Label } from 'semantic-ui-react'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { REQUIRED } from '~/components/SmartField/lib/validator'
import { TPromotion } from '~/store/promotions/types'

import React from 'react'
import DetailCard from '~/components/DetailCard/DetailCard'
import dictionariesStatic from '~/store/dictionaries/static'

type TProps = {
    promotion: TPromotion
    editMode: boolean
}

const AssociativePromotion: React.FC<TProps> = ({
    promotion,
    editMode
}) => {
    const { renderField, renderSelect } = useRenderingFunctions(promotion as any, editMode)

    const saveMapper = (v: string) => Number(v) <= 0 ? null : Number(v)

    const applyDescription = {
        'ALL': 'Upust zostanie zastosowany dla produktów wchodzących w skład którejkolwiek z wyżej zdefiniowanych grup.',
        'GROUP_1': 'Upust zostanie zastosowany dla referencji zdefiniowanych w pierwszej grupie produktów.',
        'GROUP_2': 'Upust zostanie zastosowany dla referencji zdefiniowanych w drugiej grupie produktów.',
    }

    return (
        <DetailCard id='discount' title='Upust' width={5.33} minHeight={0}>
            {renderSelect("associativeDiscountGroup", dictionariesStatic.PROMO_ASSOCIATIVE_DISCOUNT_GROUP, { label: "Grupa upustowa", validators: [REQUIRED] })}
            <Form.Group widths="equal">
                {renderSelect("associativeDiscountMode", dictionariesStatic.PROMO_ASSOCIATIVE_DISCOUNT_MODE, { label: "Tryb promocji", validators: [REQUIRED] })}
                {renderField("firstDiscount.percentageDiscount", { label: "Wysokość rabatu (%)", type: 'number', saveMapper, min: 0, precision: 2, validators: [REQUIRED] })}
            </Form.Group>
            {promotion.associativeDiscountGroup && <Label style={{ lineHeight: '18px' }}>{applyDescription[promotion.associativeDiscountGroup]}</Label>}
        </DetailCard>
    )
}

export default AssociativePromotion