import { PaginationMeta, TotalRecords, Paginable } from "~/store/types";
import { SupplierSystem } from "../customer/types";
import {
  ClaimCategory,
  CostCoveringGroup,
} from "~/pages/Claims/lib/claimTypes";

export interface TClaimLine {
  /**
   * amount accepted general
   */
  acceptedQuantity: number;
  /**
   * amount accepted by Distrigo
   */
  acceptedQuantityByDistrigo: number;
  /**
   * amount accepted by Transport
   */
  acceptedQuantityByTransport: number;
  /**
   * amount accepted by Warehouse
   */
  acceptedQuantityByWarehouse: number;
  requestReturnQuantity: number;
  rejectQuantity: number;
  decisionAcceptedQuantity: number;
  decisionAcceptedWithoutCorrectionQuantity: number;
  decisionToDestroyQuantity: number;
  decisionToDestroyWithoutCorrectionQuantity: number;
  decisionRejectedToClientQuantity: number;
  billedQuantity: number;
  claimedQuantity: number;
  lineNumber: number;
  referenceId: string;
  referenceName: string;
  status: string;
  statusDate: string;
  statusNotes: string;
  unitValue: number;
  wmsComment: string;
  orderNumber: string;
  toReceiveQuantity: number | null;
  receivedQuantityFullValue: number | null;
  receivedQuantityDefective: number | null;
  costCoveredBy: string[];
  selectedCostGroup: CostCoveringGroup;
  [key: string]: any;
}

export type TClaimAttachment = {
  author: string;
  contentType: string;
  originalFileName: string;
  storeTimestamp: string;
  storedFileName: string;
};

export type TClaimTransitionLine = {
  acceptanceQuantity: {
    value: number;
  };
  lineNumber: number;
  statusNotes: string;
};

export type TClaimTransition = {
  action:
    | "VERIFICATION_ACCEPTED"
    | "VERIFICATION_REQUEST_RETURN"
    | "DECISION_ACCEPTED"
    | "DECISION_REJECTED"
    | "RETURN_TO_SUPPLIER"
    | "MARK_CLOSED_WITH_TRANSPORTATION_FEE"
    | "MARK_CLOSED"
    | "REFUND";
  lines: TClaimTransitionLine[];
  statusNotes: string;
};

export enum CLAIM_STATUS_ENUM {
  CLOSED = "CLOSED",
  AWAITING_PARTIAL_REFUND = "AWAITING_PARTIAL_REFUND",
  AWAITING_REFUND = "AWAITING_REFUND",
  AWAITING_RECEPTION = "AWAITING_RECEPTION",
  VERIFICATION = "VERIFICATION",
  AWAITING_DECISION = "AWAITING_DECISION",
  ACCEPTED = "ACCEPTED",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  SEND_TO_SUPPLIER = "SEND_TO_SUPPLIER",
  AWAITING_INVOICE_CONFIRMATION = "AWAITING_INVOICE_CONFIRMATION",
  INVOICE_CONFIRMED = "INVOICE_CONFIRMED",
}

export type TClaimHistory = {
  status: string;
  timestamp: string;
  note: string | undefined;
};

export type TClaimUser = "DISTRIGO_BOK" | "WAREHOUSE" | "TRANSPORT";

export type TClaimMessege = {
  author: string;
  content: string;
  timestamp: string;
  userGroups: TClaimUser[];
};

export interface IClaim extends ApiResponse {
  accountingCustomerID: string;
  attachments: TClaimAttachment[];
  claimCategory: ClaimCategory;
  claimGroup: string;
  claimLines: TClaimLine[];
  claimNumber: string;
  claimType: string;
  createDate: string;
  description: string;
  invoiceNumber: string;
  orderCustomerID: string;
  orderNumber: string;
  rentalCar: boolean;
  status: string;
  statuses: TClaimHistory[];
  statusDate: string;
  statusNotes: any;
  vehicleModel: string;
  vin?: string;
  bin: string;
  pamir?: string;
  grnNumber: string;
  gdnNumber: string;
  wmsReturnCode: string;
  invoiceCorrectionNumber: string;
  invoiceCorrectionNumberList:
    | {
        invoiceCorrectionNumber: string;
        invoiceCorrectionDocumentMask: string;
      }[]
    | null;
  invoiceDocumentMask: string;
  orderId: string;
  origin?: string;
  invoiceId: string;
  invoiceCorrectionId: string;
  supplierSystem: SupplierSystem[] | null;
  backInTheBox?: string;
  customInvoiceNumber?: string | null;
  innerMessages: TClaimMessege[] | null;
  clientMessages: TClaimMessege[] | null;
  messagesAvailable: boolean;
  cspsClaimNumber: string | null;
  sapPlpr: string | null;
  ticketPowerLine: string | null;
  ticketPamir: string | null;
  ticketCrm: string | null;
  chatTargetGroups: string[];
  warehouseStatus: string | null;
  warehouseStatusNotes: string | null;
  warehouseStatusUpdatedAt: string | null;
  warehouseStatusUpdatedBy: string | null;
  transportStatus: string | null;
  transportStatusNotes: string | null;
  transportStatusUpdatedAt: string | null;
  transportStatusUpdatedBy: string | null;
  readStatus: boolean | null;
  costCoveredBy?: string[];
  invoiceCorrectionNotes?: string;
}

export type TClaimLineCreate = {
  lineNumber: number;
  claimedQuantity: number;
  referenceID?: string;
};

export interface IClaimCreate {
  processAutomatically?: boolean;
  claimGroup: string;
  claimCategory: string;
  description: string;
  claimLines: TClaimLineCreate[];
  claimType?: string | null;
  orderNumber?: string;
  orderCustomerID?: string;
  accountingCustomerID?: string;
  vin?: string;
  vehicleModel?: string;
  rentalCar?: boolean;
  invoiceNumber?: string;
  chatTargetGroups?: string[] | undefined;
}

export type CollectiveTaskData = {
  orderCustomerID: string;
  accountingCustomerID: string;
  claimCategory: string;
  claimType: string;
  description: string;
};

export type CollectiveTaskUploaded = {
  claimsCreated: string[];
  errors: any[];
};

export type CostCoveringProcessingLine = {
  lineNumber: number;
  costCoveringGroup: CostCoveringGroup;
  quantity: number;
  returnQuantity: number;
};

export type ApiResponse = Record<string, any>;

export enum ClaimsActionTypes {
  GET_CLAIM_LABEL = "@@claims/GET_CLAIM_LABEL",
  GET_CLAIM_PDF = "@@claims/GET_CLAIM_PDF",
  FETCH_CLAIMS = "@@claims/FETCH_CLAIMS",
  FETCH_CLAIMS_SEARCH = "@@claims/FETCH_CLAIMS_SEARCH",
  FETCH_CLAIMS_SUCCESS = "@@claims/FETCH_CLAIMS_SUCCESS",
  FETCH_CLAIMS_SEARCH_SUCCESS = "@@claims/FETCH_CLAIMS_SEARCH_SUCCESS",
  CREATE_CLAIM = "@@claims/CREATE_CLAIM",
  CREATE_CLAIM_SUCCESS = "@@claims/CREATE_CLAIM_SUCCESS",
  CLEAR_CREATED = "@@claims/CLEAR_CREATED",
  SELECT_CLAIM = "@@claims/SELECT_CLAIM",
  SELECTED_CLAIM = "@@claims/SELECTED_CLAIM",
  CLEAR_SELECTED_CLAIM = "@@claims/CLEAR_SELECTED_CLAIM",
  GET_ATTACHMENT = "@@claims/GET_ATTACHMENT",
  ADD_ATTACHMENT = "@@claims/ADD_ATTACHMENT",
  ADD_ATTACHMENT_SUCCESS = "@@claims/ADD_ATTACHMENT_SUCCESS",
  CLEAR_ATTACHMENT = "@@claims/CLEAR_ATTACHMENT",
  CREATE_CLAIM_TRANSITION = "@@claims/CREATE_CLAIM_TRANSITION",
  CREATE_CLAIM_TRANSITION_SUCCESS = "@@claims/CREATE_CLAIM_TRANSITION_SUCCESS",
  CLEAR_TRANSITION_CLAIM = "@@claims/CLEAR_TRANSITION_CLAIM",
  UPLOAD_COLLECTIVE_TASK = "@@claims/UPLOAD_COLLECTIVE_TASK",
  UPLOAD_COLLECTIVE_TASK_SUCCESS = "@@claims/UPLOAD_COLLECTIVE_TASK_SUCCESS",
  CLEAR_COLLECTIVE_DATA = "@@claims/CLEAR_COLLECTIVE_DATA",
  FETCH_BACK_BOX_REPORT = "@@claims/FETCH_BACK_BOX_REPORT",
  FETCH_BACK_BOX_REPORT_SUCCESS = "@@claims/FETCH_BACK_BOX_REPORT_SUCCESS",
  FETCH_CHAT_TARGET_GROUPS = "@@claims/FETCH_CHAT_TARGET_GROUPS",
  FETCH_CHAT_TARGET_GROUPS_SUCCESS = "@@claims/FETCH_CHAT_TARGET_GROUPS_SUCCESS",
  REFRESH_CLAIM = "@@claims/REFRESH_CLAIM",
  REFRESH_CLAIM_SUCCESS = "@@claims/REFRESH_CLAIM_SUCCESS",
  SEND_INNER_MESSAGE = "@@claims/SEND_INNER_MESSAGE",
  SEND_INNER_MESSAGE_SUCCESS = "@@claims/SEND_INNER_MESSAGE_SUCCESS",
  INNER_TRANSITION_PROCESSING = "@@claims/INNER_TRANSITION_PROCESSING",
  INNER_TRANSITION_PROCESSING_SUCCESS = "@@claims/INNER_TRANSITION_PROCESSING_SUCCESS",
  SET_AS_UNREAD = "@@claims/SET_AS_UNREAD",
  SET_AS_UNREAD_SUCCESS = "@@claims/SET_AS_UNREAD_SUCCESS",
  COST_COVERING_GROUP_PROCESS = "@@claims/COST_COVERING_GROUP_PROCESS",
  COST_COVERING_GROUP_PROCESS_SUCCESS = "@@claims/COST_COVERING_GROUP_PROCESS_SUCCESS",
  CLONE_CLAIM = "@@claims/CLONE_CLAIM",
}

export interface ClaimsState {
  readonly list: IClaim[];
  readonly searchList: IClaim[];
  readonly meta: PaginationMeta;
  readonly uploadingAttachment: boolean;
  readonly uploadedAttachment: boolean;
  readonly transitionChanged: boolean;
  readonly totalRecords: TotalRecords;
  readonly loadingClaims: boolean;
  readonly loadingClaim: boolean;
  readonly uploadingCollectiveTask: boolean;
  readonly uploadedCollectiveTask: boolean;
  readonly collectiveTaskUploadedData: CollectiveTaskUploaded;
  readonly selected?: IClaim;
  readonly created?: boolean;
  readonly creating?: boolean;
  readonly fetchBackInTheBoxReportPending: boolean;
  readonly chatTargetGroups: string[] | undefined;
  readonly chatTargetGroupsLoading: boolean;
  readonly innerMessageSending: boolean;
  readonly innerTransitionPending: boolean;
}

export type PaginableClaim = Paginable<IClaim>;
