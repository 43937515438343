import { connect } from 'react-redux'
import { Button, Header, Icon, Input, Modal, Segment } from 'semantic-ui-react'
import { ApplicationState } from '~/store'
import { confirmFinishingOfImport, importReferences, stopConfirmFinishingOfImport } from '~/store/distrigo-warehouses/actions'

import React, { useCallback, useMemo, useState } from 'react'
import ExampleCSV from '~/assets/images/examples/import-reference-from-csv.png'
import PreviewImage from '~/components/PreviewImage/PreviewImage'
import ModalMessageHeader from '~/components/Modals/ModalMessageHeader'
import { TImportedFile } from '~/store/distrigo-warehouses/types'
import { toastSuccess } from '~/utils/toast'

type TReduxActions = {
    confirmFinishingOfImport: typeof confirmFinishingOfImport,
    importReferences: typeof importReferences,
    stopConfirmFinishingOfImport: typeof stopConfirmFinishingOfImport
}

type TReduxState = {
    error?: string,
    imported?: TImportedFile[],
    importing: boolean
}

type TProps = TReduxActions & TReduxState & {
    closeModal: () => void,
    importSuccess: () => void,
    triggerOpen: boolean
}

const ImportReferenceFromCSV: React.FC<TProps> = ({
    closeModal, confirmFinishingOfImport, error, imported, importing, importSuccess, importReferences, stopConfirmFinishingOfImport, triggerOpen
}) => {
    const [file, setFile] = useState<File>()

    const handleClose = useCallback((): void => {
        if (!importing || error) {
            closeModal()
        }
    }, [closeModal, error, importing]);

    const handleFileChange = useCallback((ev: any, el: any): void => {
        setFile(ev.target.files[0]);
        confirmFinishingOfImport(true);
        importReferences(ev.target.files[0]);
    }, [confirmFinishingOfImport, importReferences]);

    const handleLoad = useCallback((): void => {
        if (!importing && imported) {
            importSuccess();
            handleClose();
            stopConfirmFinishingOfImport(false)
            toastSuccess('Pomyślnie wgrano plik', 'Dane zostały poprawnie zaimportowane')
        }
    }, [importing, imported, handleClose, importSuccess, stopConfirmFinishingOfImport]);

    return useMemo(() => (
        <Modal
            open={triggerOpen}
            size='small'
            closeOnDimmerClick={false}
        >
            <Header icon="file archive" content="Importowanie referencji" />
            <Modal.Content>
                <ModalMessageHeader format />
                <PreviewImage src={ExampleCSV} />
                <Segment placeholder>
                    <Header icon>
                        <Icon name="file archive outline" />
                        {file ? file.name : "Załaduj plik"}
                    </Header>
                    <Input type="file" onChange={handleFileChange} />
                </Segment>

            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Wczytaj" icon="upload" labelPosition="right" loading={importing} disabled={importing || !file || !!error} onClick={handleLoad} />
                <Button content="Zamknij" disabled={importing && !file} onClick={handleClose} />
            </Modal.Actions>
        </Modal>
    ), [error, file, handleClose, handleFileChange, handleLoad, importing, triggerOpen])
}

const mapStateToProps: (state: ApplicationState) => TReduxState = ({ distrigoWarehouses }: ApplicationState) => ({
    error: distrigoWarehouses.error,
    imported: distrigoWarehouses.imported,
    importing: distrigoWarehouses.importing,
})

const mapDispatchToProps: TReduxActions = {
    confirmFinishingOfImport, importReferences, stopConfirmFinishingOfImport
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportReferenceFromCSV)
