import React, { useCallback } from "react";
import { useClaimsRolesRights } from "~/pages/Claims/hooks/useClaimsRights";
import { CLAIM_STATUS_ENUM, IClaim } from "~/store/claims/types";
import { allowedClaimCategories } from "~/pages/Claims/lib/claimCategoryDefinitions.conf";

type Props = {
  claim: IClaim | undefined;
  setOpenAddAttachment: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTransition: React.Dispatch<React.SetStateAction<boolean>>;
  setCommentsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAddCorrectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAttachments: React.Dispatch<React.SetStateAction<boolean>>;
  setClaimCloning: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useClaimsDetailsButtons = ({
  claim,
  setOpenAddAttachment,
  setOpenHistory,
  setOpenTransition,
  setCommentsModalOpen,
  setOpenAttachments,
  setClaimCloning,
  setAddCorrectionModalOpen,
}: Props) => {
  const {
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  } = useClaimsRolesRights();

  const isProcesButtonVisible = useCallback((): boolean => {
    if (!claim) return false;

    if (
      userHaveLdcUiTransportClaimsProcessRole ||
      userHaveLdcUiWarehouseClaimsProcessRole
    ) {
      return !!(claim.status === CLAIM_STATUS_ENUM.VERIFICATION);
    } else {
      return !!(
        userHaveLdcUiClaimsProcessRole &&
        claim &&
        !userHaveLdcUiTransportClaimsProcessRole &&
        !userHaveLdcUiWarehouseClaimsProcessRole &&
        !(
          claim.status === CLAIM_STATUS_ENUM.CLOSED ||
          claim.status === CLAIM_STATUS_ENUM.AWAITING_PARTIAL_REFUND ||
          claim.status === CLAIM_STATUS_ENUM.AWAITING_REFUND ||
          claim.status === CLAIM_STATUS_ENUM.AWAITING_RECEPTION
        )
      );
    }
  }, [
    claim,
    userHaveLdcUiClaimsProcessRole,
    userHaveLdcUiTransportClaimsProcessRole,
    userHaveLdcUiWarehouseClaimsProcessRole,
  ]);

  const cloneClaim = {
    icon: "clone",
    content: "Klonuj",
    onClick: () => setClaimCloning((value) => !value),
    visible:
      userHaveLdcUiClaimsProcessRole &&
      claim &&
      claim.status === "CLOSED" &&
      (claim.claimCategory === "RK1_ZNRE" ||
        claim.claimCategory === "RK1_ZRDP" ||
        claim.claimCategory === "RK1_ZRVE"),
  };

  const addCorrection = {
    icon: "add",
    content: "Korekta faktury",
    onClick: () => setAddCorrectionModalOpen(true),
    primary: true,
    visible: !!(claim && claim.invoiceNumber) && userHaveLdcUiClaimsProcessRole,
  };

  const addAttachmentButton = {
    icon: "file archive outline",
    content: "Dodaj załącznik",
    primary: true,
    onClick: () => setOpenAddAttachment(true),
  };

  const attachmentsButtonOrNull =
    claim && allowedClaimCategories.COMMENTS.includes(claim.claimCategory)
      ? {
          icon: "table",
          content: claim.attachments.length
            ? `Załączniki (${claim.attachments.length})`
            : "Załączniki",
          primary: true,
          onClick: () => setOpenAttachments(true),
        }
      : null;

  const historyButton = {
    icon: "history",
    content: "Historia",
    primary: true,
    onClick: () => setOpenHistory(true),
    visible: claim && claim.statuses.length > 0,
  };

  const processButton = {
    icon: "edit",
    content: "Procesuj",
    onClick: () => setOpenTransition(true),
    primary: true,
    visible: isProcesButtonVisible(),
  };

  const commentsButtonOrNull =
    claim && allowedClaimCategories.COMMENTS.includes(claim.claimCategory)
      ? {
          icon: "comments",
          content: "Czat",
          onClick: () => {
            setCommentsModalOpen(true);
          },
          visible: claim?.messagesAvailable,
          primary: true,
        }
      : null;

  return [
    cloneClaim,
    addCorrection,
    addAttachmentButton,
    attachmentsButtonOrNull,
    commentsButtonOrNull,
    historyButton,
    processButton,
  ].filter((button) => button !== null);
};
