import { LENGTH, MAX, SmartValidator } from "~/components/SmartField/lib/validator";


export const fields: { [code: string]: { label: string, validators: SmartValidator[] } } = {
    vin: {
        label: "VIN", validators: [LENGTH(17)]
    },
    cleCode: {
        label: "CLE code", validators: [MAX(10)]
    },
    transmitterCode: {
        label: "Transmitter code (Émetteur)", validators: [MAX(10)]
    },
    wheelCode: {
        label: "Wheel code (Roue)", validators: [LENGTH(4)]
    },
    nreCode: {
        label: "NRE code", validators: [MAX(20)]
    },
    tiresCode: {
        label: "Tires code (Pneumatique)", validators: [/* TODO regexp validator */]
    }
}