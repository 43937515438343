import React from "react";
import { FormattedMessage } from "react-intl";
import { BREADCRUMB_ID, BREADCRUMB_ID_CLIENT_PANEL } from "../constants";
import { useAdministrationRights } from "./useAdministrationRights";
import { useHistory } from "react-router-dom";
import { useClientPanelRoles } from "~/services/useClientPanelRoles";
import { DictionaryName } from "~/store/dictionaries/types";

type TProps = {
  activeTab: BREADCRUMB_ID | BREADCRUMB_ID_CLIENT_PANEL;
  name: string;
  downloadFile: () => void;
  setOpenAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddDictionaryItemModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useAdministrationButtons = ({
  activeTab,
  name,
  downloadFile,
  setOpenAddUserModal,
  setOpenAddDictionaryItemModal,
}: TProps) => {
  const {
    userHaveLdcUiAdministrationDictRole,
    userHaveCustomerPanelUiAdvertisingEditRole,
  } = useAdministrationRights();
  const { isClientPanelUser, isSuperAdmin } = useClientPanelRoles();

  const openModals = (modal: string): void => {
    switch (modal) {
      case "user":
        setOpenAddUserModal((state) => !state);
        break;
      case "dictionary":
        setOpenAddDictionaryItemModal((state) => !state);
        break;
      default:
        break;
    }
  };

  const history = useHistory();

  const openCreatePage = (): void => {
    history.push("/announcement/new");
  };

  const downloadFileButton = {
    icon: "download",
    content: <FormattedMessage id="app.button.exportData" />,
    onClick: () => downloadFile(),
    primary: true,
    visible: !isClientPanelUser && activeTab === BREADCRUMB_ID.users,
  };
  const addUserButton = {
    icon: "add",
    content: <FormattedMessage id="app.button.add" />,
    onClick: () => openModals("user"),
    primary: true,
    visible: !isClientPanelUser && activeTab === BREADCRUMB_ID.users,
  };
  const addDictionaryButton = {
    icon: "add",
    content: <FormattedMessage id="app.button.add" />,
    onClick: () => openModals("dictionary"),
    primary: true,
    visible:
      !isClientPanelUser &&
      activeTab === BREADCRUMB_ID.dict &&
      userHaveLdcUiAdministrationDictRole,
    disabled: name === "" || name === DictionaryName.contact,
  };
  const addAnnouncementButton = {
    icon: "add",
    content: <FormattedMessage id="app.button.add" />,
    onClick: () => openCreatePage(),
    primary: true,
    visible:
      isClientPanelUser &&
      isSuperAdmin &&
      userHaveCustomerPanelUiAdvertisingEditRole &&
      activeTab === BREADCRUMB_ID_CLIENT_PANEL.announcements,
  };
  const addClientPanelUserButton = {
    icon: "add",
    content: <FormattedMessage id="app.button.add" />,
    onClick: () => setOpenAddUserModal(true),
    primary: true,
    visible:
      isClientPanelUser &&
      activeTab === BREADCRUMB_ID_CLIENT_PANEL.usersClientPanel,
  };

  return [
    downloadFileButton,
    addUserButton,
    addDictionaryButton,
    addAnnouncementButton,
    addClientPanelUserButton,
  ];
};
