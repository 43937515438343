export type TRequired = {
    required: boolean
}

export type TCodes = {
    cleCode?: TRequired | null
    nreCode?: TRequired | null
    tiresCode?: TRequired | null
    transmitterCode?: TRequired | null
    vin?: TRequired | null
    wheelCode?: TRequired | null
}

export interface SparePartPricing extends ApiResponse {
    description: string
    orderPartType: string
    packingQuantity: number
    pnrPrice: number
    provision: SparePartPricing
    pvpPrice: number
    pvnPrice: number
    referenceId: string
    replacements: { quantity: number, sparePart: SparePartPricing }[]
    stock: number
    priceTax: string
    codes?: TCodes
}

export type ApiResponse = Record<string, any>

export enum PriceListActionTypes {
    FETCH_REQUEST = '@@pricelist/FETCH_REQUEST',
    FETCH_SUCCESS = '@@pricelist/FETCH_SUCCESS',
    FETCH_ERROR = '@@pricelist/FETCH_ERROR',
    FETCH_REQUEST_SPARE_PART = '@@pricelist/FETCH_REQUEST_SPARE_PART',
    FETCH_SUCCESS_SPARE_PART = '@@pricelist/FETCH_SUCCESS_SPARE_PART',
    FETCH_ERROR_SPARE_PART = '@@pricelist/FETCH_ERROR_SPARE_PART',
}

export interface PriceListState {
    readonly loading: boolean,
    readonly list: SparePartPricing[],
    readonly sparePart: SparePartPricing,
    readonly error?: string
}
