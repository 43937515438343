export enum CustomerRoles {
  AUTHORIZED_SERVICE = "AUTHORIZED_SERVICE",
  INDEPENDENT_SERVICE = "INDEPENDENT_SERVICE",
  DISTRIGO_RELAY = "DISTRIGO_RELAY",
  DISTRIGO_HUB = "DISTRIGO_HUB",
  OTHER = "OTHER",
  INDEPENDENT_SERVICE_ERCS = "INDEPENDENT_SERVICE_ERCS",
  DISTRIGO_MARKET = "DISTRIGO_MARKET",
}

export enum AnnouncementType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
}
