import { useMemo } from "react";
import { TColumn } from "~/components/Table/lib/types";
import { useCellsRenderer } from "~/services/useCellRenderer";
import { DictionaryName } from "~/store/dictionaries/types";

export const useDebitNotesCorrectionsColumns = () => {
  const {
    getAmountCell,
    getDateCell,
    getDictionaryCell,
    getLinkCell,
    getTextCell,
  } = useCellsRenderer();

  const columnsConfig: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
        getCell: (document, column) => getDateCell(document, column, true),
      },
      {
        name: "accountingCustomer.id",
        label: "Płatnik",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.id",
        label: "Odbiorca",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.orderCustomer.id}`
          ),
      },
      {
        name: "orderCustomer.ddsDeliveryId",
        label: "OV odbiorca",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "orderCustomer.deliveryType",
        label: "Do faktury",
        projection: true,
        getCell: (document, column) => getTextCell(document, column),
      },
      {
        name: "netAmount",
        label: "Kwota netto",
        projection: true,
        priceColumn: true,
        getCell: (document, column) => getAmountCell(document, column),
      },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
        getCell: (document, column) => getDictionaryCell(document, column),
      },
      {
        name: "accountingCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
        getCell: (document, column) =>
          getLinkCell(
            document,
            column,
            `/customer/${document.accountingCustomer.id}`
          ),
      },
    ],
    [getAmountCell, getDateCell, getDictionaryCell, getLinkCell, getTextCell]
  );

  const columnsDebitNotesCorrectionsWithInvoiceLine: TColumn[] = useMemo(
    () => [
      {
        name: "documentMask",
        label: "Numer",
        projection: true,
      },
      {
        name: "invoiceDate",
        label: "Data wystawienia",
        projection: true,
        type: "date",
      },
      {
        name: "accountingCustomer.id",
        label: "Płatnik",
        projection: true,
      },
      {
        name: "orderCustomer.id",
        label: "Odbiorca",
        projection: true,
      },
      {
        name: "invoiceDocumentMaskToCorrect",
        label: "Do faktury",
        projection: true,
      },
      {
        name: "netAmount",
        label: "Kwota netto",
        projection: true,
      },
      {
        name: "taxAmount",
        label: "Kwota VAT",
        projection: true,
        hide: true,
      },
      {
        name: "paymentMethodType",
        dictionaryName: DictionaryName.paymentMethod,
        label: "Metoda płatności",
        projection: true,
      },
      {
        name: "accountingCustomer.taxCode",
        label: "NIP płatnika",
        projection: true,
      },
      {
        name: "serviceBoxOrderNo",
        label: "Numer zamówienia Service Box",
        projection: false,
        hide: true,
        disableSort: true,
      },
      {
        name: "orderNumber",
        label: "Numer zamówienia",
        projection: false,
        hide: true,
      },
      {
        name: "unitPriceNetAfterDiscount",
        label: "Cena za sztukę",
        projection: false,
        hide: true,
      },
      {
        name: "taxRate",
        label: "Podatek VAT za sztukę",
        projection: false,
        hide: true,
      },
      {
        name: "referenceId",
        label: "Numer referencyjny",
        projection: false,
        hide: true,
      },
      {
        name: "quantity",
        label: "Ilośc na linii",
        projection: false,
        hide: true,
        disableSort: true,
      },
      {
        name: "taxRate",
        label: "Podatek VAT za sztukę",
        projection: false,
        hide: true,
        disableSort: true,
      },
    ],
    []
  );

  return { columnsConfig, columnsDebitNotesCorrectionsWithInvoiceLine };
};
