import React, { useEffect } from "react";
import PageHeader from "~/components/PageHeader/PageHeader";

import translations from "~/utils/translations";
import ContactTile from "./ContactTile";

const Contact: React.FC = () => {
  useEffect(() => {
    document.title = translations.format("LDC_CONTACT");
  }, []);

  return (
    <>
      <PageHeader
        icon="phone"
        title="Kontakt"
        breadcrumb={[]}
        buttons={[]}
        loading={false}
      />
      <ContactTile />
    </>
  );
};

export default Contact;
