import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '~/utils/api'
import { handleError } from '~/store/error/actions'
import { createDistrigoCredit, createDistrigoCreditSuccess, fetchDistrigoCredit, fetchDistrigoCreditSuccess } from './actions'
import { CustomerDistrigoCredit, CustomerPaymentDistrigoCredit, DistrigoCreditActionTypes } from './types'
import { toastSuccess } from "~/utils/toast";


function* handleFetchDistrigoCredit(action: ReturnType<typeof fetchDistrigoCredit>) {
    try {
        const { customerId } = action.payload
        const response: CustomerDistrigoCredit = yield call(callApi, 'get', `/customers/${customerId}/distrigo-credit`)
        yield put(fetchDistrigoCreditSuccess(response))
    } catch (error) {
        yield put(handleError(error, false))
    }
}


function* handleCreateDistrigoCredit(action: ReturnType<typeof createDistrigoCredit>) {
    try {
        const { customerPaymentFactor, customerId } = action.payload

        const distrigoCredit: CustomerPaymentDistrigoCredit = yield call(callApi, 'post', `/customers/${customerId}/distrigo-credit`, customerPaymentFactor)
        yield toastSuccess("app.info.distrigoCredit.create.success", "app.info.common.success")
        yield put(createDistrigoCreditSuccess(distrigoCredit))
    } catch (error) {
        yield put(handleError(error, false, "Wystąpił błąd", error.message ? error.message : "Nie udało się utworzyć kredytu Distrigo"))
    }
}

function* watchFetchDistrigoCreditRequest() {
    yield takeEvery(DistrigoCreditActionTypes.FETCH_DISTRIGO_CREDIT, handleFetchDistrigoCredit)
}

function* watchCreateDistrigoCredit() {
    yield takeEvery(DistrigoCreditActionTypes.CREATE_DISTRIGO_CREDIT, handleCreateDistrigoCredit)
}

function* distrigoCreditSaga() {
    yield all([
        fork(watchFetchDistrigoCreditRequest),
        fork(watchCreateDistrigoCredit),
    ])
}

export default distrigoCreditSaga
