import { Checkbox, Table } from 'semantic-ui-react';
import React from 'react';
import { OrderLine } from '~/store/orders/types';
import { SelectedTableCellProps } from '~/pages/Orders/Details/lib/Lines/Item/TableCell/SelectedTableCell/constants';

export const SelectedTableCell = ({ column, line, orderStatus, selectLine }: SelectedTableCellProps) => {
  const isValidCheckbox = (line: OrderLine): boolean =>
    line.type === 'REPLACED' ||
    line.type === 'REPLACED_TO_ORIGINAL' ||
    line.type === 'PROVISION' ||
    line.rejectionReason !== null ||
    (line.rejectionReasons !== null && line.rejectionReasons.length > 0) ||
    line.sapStatus === 'PROCESSED_BY_SAP' ||
    line.sapStatus === 'REJECTED' ||
    line.sapStatus === 'REPLACED_BY_SAP' ||
    line.sapStatus === 'ADDED_BY_SAP' ||
    orderStatus === 'CANCELLED' ||
    line.quantity === line.confirmedQuantity - line.rejectedQuantity;

  return (
    <Table.Cell key={`${column.name}-${line.rejectionReasons}-${line.rejectionReason}`}>
      <Checkbox
        checked={line.selected}
        onChange={(e, d) => selectLine(line.referenceID, e, d)}
        defaultIndeterminate={isValidCheckbox(line)}
        disabled={isValidCheckbox(line)}
      />
    </Table.Cell>
  );
};
