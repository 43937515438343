import { Icon, Table, Checkbox, CheckboxProps } from 'semantic-ui-react'
import { useRenderingFunctions } from '~/components/SmartField/hooks/useRenderingFunctions'
import { MIN_VALUE, NUMBER_VALUES, MAX_VALUE } from '~/components/SmartField/lib/validator'
import { StockWarehouse } from '~/store/distrigo-warehouses/types'
import { parseDate } from '~/utils/dateUtils'

import React from 'react'
import SmartWrapper from '~/components/SmartField/SmartWrapper'
import TableNoResults from '~/components/TableNoResults/TableNoResults'
import { Link } from 'react-router-dom'

interface Props {
    stocksWarehouse: StockWarehouse[]
    columns: any[]
    editMode: boolean
    selection: StockWarehouse[]
    updateSelection: (stockItem: StockWarehouse) => void
    updateMinMaxStock: (...params: any) => void
    removeStockLine: (stockLine: StockWarehouse, code: number) => void
}

type AppProps = Props

class StockProductsList extends React.Component<AppProps> {

    private getCell(stockWarehouse: StockWarehouse, name: string): JSX.Element {
        const { editMode } = this.props
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { renderNumber } = useRenderingFunctions(stockWarehouse, editMode)
        const stockWarehouseCol = (stockWarehouse as any)[name]
        if (name.includes('.')) {
            const deepRefs = name.split('.')
            if (deepRefs[1] === 'referenceId') {
                return <Table.Cell key={name}>
                    <Link to={`/product/${stockWarehouse.referenceWarehouseId.referenceId}`}>
                        {stockWarehouse.referenceWarehouseId.referenceId}
                    </Link>
                </Table.Cell>
            }
            return <Table.Cell key={name}>{(stockWarehouse as any)[deepRefs[0]][deepRefs[1]]}</Table.Cell>
        }
        if (name === 'updateTime') {
            return <Table.Cell key={name}>{parseDate(stockWarehouse.updateTime)}</Table.Cell>
        }
        if (name === 'packingIsSent100') {
            return <Table.Cell key={name}><Icon name={stockWarehouse.packingIsSent100 ? "check" : "close"} /></Table.Cell>
        }
        if (name === 'toReturn') {
            return <Table.Cell key={name}>{stockWarehouse.toReturn || "0"}</Table.Cell>
        }
        return <Table.Cell key={name}>
            {
                (name === "stockMin" || name === "stockMax")
                    ?
                    <SmartWrapper customErrorHandler={() => this.props.removeStockLine(stockWarehouse, 404)} endpoint={`/stock/${stockWarehouse.referenceWarehouseId.referenceId}`} method="PUT" model={{ stockMin: this.stockMinMax('stockMin', stockWarehouse), stockMax: this.stockMinMax('stockMax', stockWarehouse), warehouseId: stockWarehouse.referenceWarehouseId.warehouseId, thresholdStop: stockWarehouse.thresholdStop }}>
                        {renderNumber(name, { readOnly: !editMode, saveMapper: value => this.mapToStock(value, name, stockWarehouse), validators: [name === 'stockMin' ? MAX_VALUE(stockWarehouse.stockMax) : MIN_VALUE(stockWarehouse.stockMin), NUMBER_VALUES] })}
                    </SmartWrapper>
                    :
                    typeof stockWarehouseCol === 'boolean' ?
                        <Icon name={stockWarehouseCol ? "check" : "close"} />
                        :
                        stockWarehouseCol
            }
        </Table.Cell>
    }

    private static replenishmentsLink(stockItem: StockWarehouse): JSX.Element {
        return <Link to={`/replenishments/${stockItem.referenceWarehouseId.warehouseId}/${stockItem.referenceWarehouseId.referenceId}`} className='table-preview-details' title='Podgląd'>
            <Icon name='arrow right' />
        </Link>
    }


    private stockMinMax = (name: 'stockMin' | 'stockMax', stock: StockWarehouse): number => {
        const { stocksWarehouse } = this.props
        if (stocksWarehouse.length > 0) {
            const changedStockValueID = stocksWarehouse.findIndex(x => x.referenceWarehouseId.referenceId === stock.referenceWarehouseId.referenceId);
            return stocksWarehouse[changedStockValueID][name]
        }
        return stock[name]
    }

    private mapToStock(value: number, name: string, stockWarehouse: StockWarehouse): number {
        const { stocksWarehouse } = this.props
        const changedStockValueID = stocksWarehouse.findIndex(stock => stock.referenceWarehouseId.referenceId === stockWarehouse.referenceWarehouseId.referenceId);
        (stocksWarehouse as any)[changedStockValueID][name] = value
        const { stockMin, stockMax } = stockWarehouse
        this.props.updateMinMaxStock(stockWarehouse.referenceWarehouseId.referenceId, stockMin, stockMax)
        return value
    }

    render() {
        const { stocksWarehouse, columns, selection } = this.props

        if (stocksWarehouse.length === 0) {
            return (
                <TableNoResults />
            )
        }

        return (
            stocksWarehouse.map(stockItem =>
                <Table.Row key={stockItem.referenceWarehouseId.referenceId}>
                    <Table.Cell key={stockItem.id} collapsing ><Checkbox
                        checked={!!selection.find(selected => selected.referenceWarehouseId.referenceId === stockItem.referenceWarehouseId.referenceId)}
                        onClick={(ev, props: CheckboxProps) => {
                            this.props.updateSelection(stockItem)
                        }} /></Table.Cell>
                    {columns.map(column =>
                        column.projection && this.getCell(stockItem, column.name)
                    )}
                    <Table.Cell className="col-dropdown-menu-sticky">{StockProductsList.replenishmentsLink(stockItem)}</Table.Cell>
                </Table.Row>
            )
        )
    }
}

export default StockProductsList