import { action } from 'typesafe-actions'
import { ApiError } from '../error/types'
import { DistrigoWarehouse, DistrigoWarehousesActionTypes, NewReceiptWareLine, PaginableStocksWarehouse, StockDetails, StockWarehouse, TImportedFile, TInternalConsumptionPayload, TRejectToSupplier, TWareLine } from './types'

export const fetchDistrigoWarehouses = () => action(DistrigoWarehousesActionTypes.FETCH_REQUEST)
export const fetchStockProducts = (params: any) => action(DistrigoWarehousesActionTypes.FETCH_STOCK_PRODUCTS_REQUEST, params)
export const fetchStockDetails = (id: string) => action(DistrigoWarehousesActionTypes.FETCH_STOCK_DETAILS, { id })
export const fetchStockDetailsSuccess = (stockDetails: StockDetails) => action(DistrigoWarehousesActionTypes.FETCH_STOCK_DETAILS_SUCCESS,  stockDetails )

export const fetchSuccess = (distrigoWarehouses: DistrigoWarehouse[]) => action(DistrigoWarehousesActionTypes.FETCH_SUCCESS, { distrigoWarehouses })
export const fetchStockProductsSuccess = (stockItemsList: StockWarehouse[]) => action(DistrigoWarehousesActionTypes.FETCH_STOCK_PRODUCTS_SUCCESS, { stockItemsList })

export const selectDistrigoStocksWarehouse = (params: any) => action(DistrigoWarehousesActionTypes.SELECT, params)
export const distrigoWarehouseSelected = (stocksWarehouse: PaginableStocksWarehouse) => action(DistrigoWarehousesActionTypes.SELECTED, stocksWarehouse)

export const addSparePartStock = (sparePartStock: StockWarehouse) => action(DistrigoWarehousesActionTypes.ADD_SPARE_PART_STOCK, sparePartStock)
export const addSparePartStockSuccess = (rrdi: string) => action(DistrigoWarehousesActionTypes.ADD_SPARE_PART_STOCK_SUCCESS, rrdi)

export const uploadStockFileExcel = (excelFile: File, warehouseID: string) => action(DistrigoWarehousesActionTypes.UPLOAD_XLS, { excelFile, warehouseID })
export const uploadStockFileExcelSuccess = () => action(DistrigoWarehousesActionTypes.UPLOAD_XLS_SUCCESS)
export const clearUpload = () => action(DistrigoWarehousesActionTypes.CLEAR_UPLOAD)

export const addSparePartConflict = () => action(DistrigoWarehousesActionTypes.ADD_SPARE_PART_CONFLICT)
export const clearCreated = () => action(DistrigoWarehousesActionTypes.CLEAR_CREATED)

export const addWareLine = (line: NewReceiptWareLine) => action(DistrigoWarehousesActionTypes.CREATE_WARE, { line })
export const addWareLineSuccess = (wareId: string) => action(DistrigoWarehousesActionTypes.CREATE_WARE_SUCCESS, wareId)
export const catchErrorOfDuplicateReference = (error: ApiError | null) => action(DistrigoWarehousesActionTypes.ERROR_OF_DUPLICATE_REFERENCE, error)
export const linesOperationFinished = (success: boolean) => action<DistrigoWarehousesActionTypes, boolean>(DistrigoWarehousesActionTypes.LINES_OPERATION_FINISHED, success)
export const rejectToSupplier = (parts: TRejectToSupplier) => action(DistrigoWarehousesActionTypes.REJECT_TO_SUPPLIER, parts)
export const internalConsumption = (parts: TInternalConsumptionPayload, warehouseID: string) => action(DistrigoWarehousesActionTypes.INTERNAL_CONSUMPTION, { parts, warehouseID })
export const rejectSuccess = () => action(DistrigoWarehousesActionTypes.REJECT_SUCCESS)
export const internalConsumptionSuccess = () => action(DistrigoWarehousesActionTypes.INTERNAL_CONSUMPTION_SUCCESS)

export const removeStockLine = (stockLine: StockWarehouse, code: number) => action(DistrigoWarehousesActionTypes.HANDLE_REMOVE_STOCK_LINE, { stockLine, code })

export const updateMinMaxStockItem = (id: StockWarehouse, min: number, max: number) => action(DistrigoWarehousesActionTypes.UPDATE_MIN_MAX_STOCK_ITEM, { id, min, max })

export const importReferences = (file: File) => action(DistrigoWarehousesActionTypes.IMPORT_REFERENCES, file)
export const importFinished = (res: { referenceId?: string, quantity?: number, price?: number }) => action(DistrigoWarehousesActionTypes.IMPORT_FINISHED, res)
export const catchErrorOfImportingReference = (error: ApiError | null) => action(DistrigoWarehousesActionTypes.ERROR_OF_IMPORTING_REFERENCE, error)
export const confirmFinishingOfImport = (finishedImportingToLine: boolean) => action(DistrigoWarehousesActionTypes.CONFIRM_FINISHING_OF_IMPORT, finishedImportingToLine)
export const stopConfirmFinishingOfImport = (finishedImportingToLine: boolean) => action(DistrigoWarehousesActionTypes.STOP_CONFIRM_FINISHING_OF_IMPORT, finishedImportingToLine)
export const clearArrayOFInmportedFile = (imported?: TImportedFile[]) => action(DistrigoWarehousesActionTypes.CLEAR_IMPORTED_ARRAY, imported)
export const confirmMovingWares = (res: { supplierID?: string, recipientID?: string, parts: TWareLine[], additionalInfo?: string }) => action(DistrigoWarehousesActionTypes.CONFIRM_MOVING_WARES, res)