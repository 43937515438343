import { useMemo } from "react";
import { SparePartCP } from "~/store/spare-parts/types";

type Props = {
  handleExport: () => void;
  handleRemoveAll: () => void;
  spareParts: SparePartCP[];
};

export const useSparePartsButtonsClientPanel = ({
  handleExport,
  handleRemoveAll,
  spareParts,
}: Props) => {
  const downloadIsActive = useMemo(() => !!spareParts.length, [spareParts]);
  const downloadButton = useMemo(
    () => ({
      icon: "download",
      content: "Eksport produktów",
      primary: true,
      onClick: () => handleExport(),
      disabled: !downloadIsActive,
    }),
    [downloadIsActive, handleExport]
  );
  const clearAll = useMemo(
    () => ({
      icon: "cancel",
      content: "Wyczyść listę",
      primary: false,
      onClick: () => handleRemoveAll(),
      disabled: !downloadIsActive,
    }),
    [downloadIsActive, handleRemoveAll]
  );

  return [clearAll, downloadButton];
};
